import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import HistorialPagosTable from "./HistorialPagosTable";
import ComprobantePago from "./ComprobantePago";
import HeaderData from "./HeaderData";
import saldoAzul from "../assets/img/saldo-azul.svg";
import {
  SingleOption,
  customStyles,
  parseDate,
  customStylesPedidos,
} from "./utils/utils";
import { getFilter } from "./utils/get";
import {
  displayLoader,
  hideLoader,
  showGreyBackground,
  hideGreyBackground,
} from "./utils/windows";
import { getAllPeriodos, getUpdateDate } from "./utils/monthNames.js";
import ExportExcel from "./ExportExcel";
import Terminos from "./Terminos/Terminos";
import PrintComponent from "./PrintComponent";

const HistorialPagos = (props) => {
  const [products, setProducts] = useState([]);
  const [productsLoaded, setProductsLoaded] = useState(false);
  const [bancosFilter, setBancosFilter] = useState([]);
  const [tipoOperacionFilter, setTipoOperacionFilter] = useState([]);
  const [estadoFilter, setEstadoFilter] = useState([]);

  const [clientName, setClientName] = useState("");
  const [updateDate, setUpdateDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [zone, setZone] = useState("");
  const [overdueAmount, setOverdueAmount] = useState("");
  const [token, setToken] = useState();
  const [filterText, setFilterText] = useState("");
  const [allPeriodos, setAllPeriodos] = useState([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectedComprobante, setSelectedComprobante] = useState(null);
  const [address, setAddress] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const tableRef = useRef(null);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [selectedTipoOperacion, setSelectedTipoOperacion] = useState([]);
  const [selectedBanco, setSelectedBanco] = useState([]);
  const [propuestaPago, setPropuestaPago] = useState("");
  const [selectedPropuestaPago, setSelectedPropuestaPago] = useState("Todos");
  const [monto, setMonto] = useState("");
  const [selectedMonto, setSelectedMonto] = useState("Todos");
  const [selectedEstado, setSelectedEstado] = useState([]);

  const [isArcoprime, setIsArcoprime] = useState(false);
  const [selectedEdsArcoprime, setSelectedEdsArcoprime] = useState();
  const [allEdsArcoprime, setAllEdsArcoprime] = useState([]);
  //const twoAgo = new Date();
  //twoAgo.setDate(twoAgo.getDate() - 2);

  const labels = [
    ["Fecha Emisión", "FECHA"],
    ["Tipo Operación", "TIPO"],
    ["Banco", "BANCO"],
    ["Nº Propuesta de Pago", "GRUPO_PPTA"],
    ["Monto", "MONTO"],
    ["Estado", "ESTADO"],
  ];

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (props.token && props.rutConcesionario) {
        setToken(props.token);
        if (props.rutConcesionario === "77215640-5") {
          setIsArcoprime(true);
          getFiliales();
        } else {
          getHistorial(null);
        }
      }
    } else {
      if (props.token) {
        setToken(props.token);
        if (props.rutConcesionario === "77215640-5" /* || true */) {
          setIsArcoprime(true);
          getFiliales();
        } else {
          getHistorial(null);
        }
      }
    }
  }, [props.token, props.rutConcesionario]);

  useEffect(() => {
    if(props.cabecera!==""){
      setClientName(props.cabecera.DATOS_CLIENTE.NOMBRE);
      setZone(props.cabecera.DATOS_EDS[0].ZONA);
    }
  }, [props.cabecera]);

  useEffect(() => {
    const allPeriodos = getAllPeriodos();
    setAllPeriodos(allPeriodos);
    setSelectedPeriodo(allPeriodos[0]);
  }, []);

  useEffect(() => {
    if (selectedEdsArcoprime) {
      getHistorial(selectedPeriodo.value);
    }
  }, [selectedEdsArcoprime]);

  async function getHistorial(newPeriodo) {
    let url;

    if (isArcoprime) {
      url =
        process.env.REACT_APP_BASE_API +
        "/cuenta-corriente/obtener-historial-pagos?id_eds=" +
        selectedEdsArcoprime.value;
    } else {
      url =
        process.env.REACT_APP_BASE_API +
        "/cuenta-corriente/obtener-historial-pagos?rut_concesionario=" +
        props.rutConcesionario;
    }

    if (newPeriodo) {
      url += "&periodo=" + newPeriodo;
    }

    let result = await props.callServiceHandler(url, "GET")
    if(result !== "error"){
      const data = result.data;
      setProducts(data["PROPUESTAS_PAGO"]);
      setAddress(data["DIRECCION"]);
      getFilter(data["PROPUESTAS_PAGO"], "BANCO", setBancosFilter);
      getFilter(data["PROPUESTAS_PAGO"], "TIPO", setTipoOperacionFilter);
      getFilter(data["PROPUESTAS_PAGO"], "ESTADO", setEstadoFilter);

      const date = new Date();

      const day =
        parseInt(date.getDate()) > 9
          ? date.getDate()
          : "0" + date.getDate();
      let month = parseInt(date.getMonth() + 1);
      month = month > 9 ? month : "0" + month.toString();

      getUpdateDate(setUpdateDate);

      setDueDate(day + "/" + month + "/" + date.getFullYear());
      setOverdueAmount(data.MONTO_ATRASADO);
      setProductsLoaded(true);
    }
  }

  function changeSelectedPeriodo(e) {
    setSelectedPeriodo(e);
    const date = e.value.split("%3B");
    setSelectedMonth(date[0]);
    setSelectedYear(date[1]);
    getHistorial(e.value);
    resetFilters();
  }

  function setNumber(num) {
    if (!isNaN(parseInt(num))) {
      let newNum = parseInt(num);

      if (num.length > 1 && num.slice(-1) === "-") newNum = -newNum;

      return newNum;
    }
    return 0;
  }

  const resetFilters = () => {
    setStartDate(new Date());
    setEndDate(null);
    setTipoOperacionFilter([]);
    setSelectedTipoOperacion([]);
    setSelectedBanco([]);
    setBancosFilter([]);
    setPropuestaPago("");
    setSelectedPropuestaPago("Todos");
    setMonto("");
    setSelectedMonto("Todos");
    setEstadoFilter([]);
    setSelectedEstado([]);
  };

  const goBack = () => {
    setSelectedComprobante(null);
  };

  async function getFiliales() {
    const url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/listar-filiales?rut_concesionario=77215640-5";

    let result = await props.callServiceHandler(url, "GET")
    if(result !== "error"){
      const data = result.data;
      const eeddss = [];

      data.FILIALES.forEach(function (eds) {
        let ide = eds.ID_EDS;
        while (ide.charAt(0) === "0") {
          ide = ide.substring(1);
        }
        const newEds = {
          value: eds.ID_EDS,
          label: ide + " - " + eds.DIRECCION,
        };
        eeddss.push(newEds);
      });

      setAllEdsArcoprime(eeddss);
    }
  }

  async function changeEdsArcoprime(e) {
    setSelectedEdsArcoprime(e);
  }

  return (
    <div className="resumen">
      <div className="title-container">
        <div className="title">
          <div className="view-title">Cuenta corriente</div>
          <div className="view-title-separator">|</div>
          <div className="view-subtitle">Historial de pagos</div>
        </div>
        {selectedComprobante ? (
          <>
            <div className="volver-arrow" onClick={() => goBack()}>
              <img></img>
              <p className="volver-text">Volver</p>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <hr className="title-separator-line" />

      <HeaderData
        rutConcesionario={props.rutConcesionario}
        token={props.token}
        actualizacion={updateDate}
        cabecera={props.cabecera}
        actualizable={true}
        updateFunction={()=>getHistorial(selectedPeriodo.value)}
        callServiceHandler={props.callServiceHandler}
      />

      <hr />

      {!selectedComprobante && (
        <>
          <div className="historial-options">
            <div className="filters-container">
              {isArcoprime && (
                <>
                  <div className="filter">
                    <div className="filter-title">ESTACIÓN DE SERVICIO*</div>
                    <div className="filter-input">
                      <Select
                        className="eds-select"
                        value={selectedEdsArcoprime}
                        onChange={(e) => changeEdsArcoprime(e)}
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        options={allEdsArcoprime}
                        components={{
                          SingleOption,
                          IndicatorSeparator: () => null,
                        }}
                        isClearable={false}
                        autosize={false}
                        placeholder="Seleccionar"
                        styles={customStylesPedidos}
                        width="150px"
                        containerWidth="140px"
                        widthmenu="200px"
                      />
                    </div>
                  </div>
                </>
              )}
              <div className={`filter ${isArcoprime ? "mleft" : ""}`}>
                <div className="filter-title">PERÍODO</div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedPeriodo}
                    onChange={(e) => changeSelectedPeriodo(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allPeriodos}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    styles={customStyles}
                  />
                </div>
              </div>

              <div className="filter">
                <div className="filter-input">
                  <input
                    type="search"
                    className="search"
                    value={filterText}
                    placeholder="Buscar..."
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="actions">
              <PrintComponent 
                reff={tableRef}
                classes=""
                pageStyle="@page { size: 370mm 300mm; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }"
                isROImpersonate={props.isROImpersonate}
              />

              <ExportExcel
                data={filteredProducts}
                labels={labels}
                isROImpersonate={props.isROImpersonate}
              />
            </div>
          </div>
          {token && (
            <HistorialPagosTable
              products={products}
              setProducts={setProducts}
              bancosFilter={bancosFilter}
              tipoOperacionFilter={tipoOperacionFilter}
              estadoFilter={estadoFilter}
              filterText={filterText}
              setSelectedComprobante={setSelectedComprobante}
              selectedYear={selectedYear}
              selectedMonth={selectedMonth}
              isLiberarPagos={false}
              filteredProducts={filteredProducts}
              setFilteredProducts={setFilteredProducts}
              productsLoaded={productsLoaded}
              tableRef={tableRef}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              selectedTipoOperacion={selectedTipoOperacion}
              setSelectedTipoOperacion={setSelectedTipoOperacion}
              selectedBanco={selectedBanco}
              setSelectedBanco={setSelectedBanco}
              propuestaPago={propuestaPago}
              setPropuestaPago={setPropuestaPago}
              selectedPropuestaPago={selectedPropuestaPago}
              setSelectedPropuestaPago={setSelectedPropuestaPago}
              monto={monto}
              setMonto={setMonto}
              selectedMonto={selectedMonto}
              setSelectedMonto={setSelectedMonto}
              selectedEstado={selectedEstado}
              setSelectedEstado={setSelectedEstado}
            />
          )}
        </>
      )}
      {selectedComprobante && (
        <ComprobantePago
          selectedComprobante={selectedComprobante}
          setSelectedComprobante={setSelectedComprobante}
          address={address}
          rut={props.rutConcesionario}
          clientName={clientName}
          isROImpersonate={props.isROImpersonate}
        />
      )}
    </div>
  );
};

export default HistorialPagos;
