import React, { useEffect } from 'react';

const OverflowedText = (
  {
    children,
    widthContainer
  }
) => {

  useEffect(() => {
    // console.log("cjhill nam", typeof children.props.children)
    // console.log("cjhill",typeof children)
  }, [children])
  

  function verificarTamanio(texto, maxsize, fuente = "12px Quicksand") {
    // Crear un elemento de lienzo en memoria
    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    
    // Establecer la fuente del texto
    context.font = fuente;

    if (typeof texto === 'string') {
      texto = texto
    }else{
      texto = texto.props.children
    }
    
    // Medir el ancho del texto
    var ancho = context.measureText(texto).width;
    
    // Devolver el ancho del texto en píxeles
  
    // console.log(ancho)
    if (ancho>maxsize) {
      // console.log("La palabra " + texto + " es mayor a " + maxsize)
      // console.log(`el ${texto} es mayor al ${maxsize}`)
      return true
    }else{
      // console.log("La palabra " + texto + " es menos a " + maxsize)
      // console.log(`el ${texto} es MENOR al ${maxsize}`)
      return false
    }
    // return ancho;
  }

  let maxWidth = widthContainer - 20

  return (
    <div 
      className='overflowed-container'
      style={
        {
          width: `${maxWidth}px`
        }
      }
    >
      <div className={`overflowed ${verificarTamanio(children, maxWidth)?"overflowed_on": ""}`}>
        {children}
        <div className="extra">
          {children}
        </div>
      </div>
    </div>
  );
}

export default OverflowedText;