import React, { useContext, useState } from "react";
import Modal from "react-modal";
import { ViewPortContext } from "../../App";
import { displayLoader } from "../utils/windows";
import CustomModal from "../CustomModal";
import { getBancoName, getBancoCode } from "../utils/utils";

export const BankChooser = ({
  setStep,
  confirmAbono,
  bank,
  convenio,
  bankName,
  setBank,
  setBankName,
  setBankConvenio,
  closeStepper,
  closeWithParameter,
  banksList
}) => {
  const [displayModal, setDisplayModal] = useState(false);
  //const { bottomCoordinate } = useContext(ViewPortContext);
  const bottomCoordinate = 0;

  function openModal() {
    setDisplayModal(true);
  }

  function closeModal() {
    setDisplayModal(false);
  }

  function confirm() {
    closeModal();
    confirmAbono(bankName);
    displayLoader();
  }

  function changeBank(code, name, convenio) {
    setBank(code);
    setBankName(name);
    setBankConvenio(convenio);
  }


  return (
    <div className="abonar-en-banco__step-content">
      <table className="abonar-en-banco__table">
        <thead>
          <tr>
            <th>Banco</th>
            <th>Selección</th>
          </tr>
        </thead>
        <tr>
          <th colSpan="3" />
        </tr>
        <tbody>
          {banksList.map((bankData, i)=>{
            const isLast = banksList.length - 1 === i;
            return (
              <>
                <tr key={i}>
                  <td className="bank-name-cell">{getBancoName(bankData.CLAVE_BANCO)}</td>
                  <td>
                    <label class="radio-button-container">
                      <input
                        type="radio"
                        name="bank"
                        value={getBancoCode(bankData.CLAVE_BANCO)}
                        checked={bank === getBancoCode(bankData.CLAVE_BANCO) && convenio === bankData.CONVENIO}
                        onChange={
                          (e) => changeBank(getBancoCode(bankData.CLAVE_BANCO), getBancoName(bankData.CLAVE_BANCO), bankData.CONVENIO)
                        }
                      />
                      <span class="checkmark"></span>
                    </label>
                  </td>
                </tr>
                {!isLast && <tr className="data separator">
                  <td className="trseparator align-left" colSpan="5">
                      <div className="line-sep"></div>
                  </td>
                </tr>}
              </>
            )
          })} 
        </tbody>
      </table>
      <hr />
      <div
        className="abonar-en-banco__step-actions"
        style={{ bottom: bottomCoordinate }}
      >
        <button
          className="main-button main-button-red hallow"
          onClick={() =>
            closeWithParameter ? closeStepper(1) : closeStepper()
          }
        >
          Volver
        </button>
        <button
          className="main-button main-button-red"
          onClick={openModal}
          disabled={bank === undefined}
        >
          Pagar
        </button>
      </div>

      <CustomModal
        displayModal={displayModal}
        closeModal={closeModal}
        acceptFunc={confirm}
        acceptText='Confirmar'
        modalTitle='Importante'
        modalText='No cierre ninguna ventana MIENTRAS OPERA CON SU BANCO. Al término del pago, utilice siempre el botón Salir o Finalizar para terminar la transacción.'
        modalIcon='error'
      />
    </div>
  );
};
