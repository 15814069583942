import React, {useState} from "react";
import { getTanquesSifoneadosWithLocalNumber } from '../utils/tanquesSifoneados';
import { get3DecimalsFloatWithDelimiter } from '../utils/utils.js'
import errorIcon from "../../assets/img/atencion_rojo.svg";

export const IngresoMediciones = ({ setStep, selectedEds, configuracion, mediciones, setMediciones, fechaMedicion, horaMedicion }) => {
  const [errores, setErrores] = useState([])
  
  function medicionesChange(value, key){
    value = get3DecimalsFloatWithDelimiter(value)

    if(value !== false)
      setMediciones({...mediciones, [key]: value})
  }

  function checkStep(){
    const tmpErrores = []
    let error1 = false, error2 = false
    configuracion.TANQUES.forEach((tanque) => {
      if(mediciones[tanque.ID]){
        const volumen = parseInt(mediciones[tanque.ID]) || 0
        const capacidad = tanque.CAPACIDAD
        if (volumen > capacidad) error2 = true
      }
      else {
        error1 = true
      }
    })
    
    if(error1)
      tmpErrores.push("Ingresa todos los datos.")
    if(error2)
      tmpErrores.push("No es posible ingresar una medición con un volumen mayor a la capacidad del tanque.")

    setErrores(tmpErrores)
    if(tmpErrores.length === 0)
      setStep(2)
  }

  return (
    <div className="fluctuaciones-ingreso-mediciones-small-section">
      <p className="flutuaciones-section-title">INGRESO DE MEDICIONES</p>

      <table className="flutuaciones-table tanks-table">
        <thead>
          <tr>
            <th>Tanque</th>
            <th className="no-padding-left-th">Material</th>
            <th className="measurement-column">
              Medición física/ telemedición (Lts)
            </th>
          </tr>
        </thead>
        <tbody>
          { !selectedEds &&
            <>
              <tr>
                <td className="horizontal-separator" colSpan={3} />
              </tr>
              <tr>
                <td colspan="3" 
                  className="cell-border-top-left 
                    cell-border-top-right 
                    cell-border-bottom-left 
                    cell-border-bottom-right"
                >
                  Seleccione una Estación de Servicio para ver la información.
                </td>
              </tr>
            </>
          }
          { selectedEds && configuracion && configuracion.TANQUES.map((tanque, key) =>
            getTanquesSifoneadosWithLocalNumber(tanque, mediciones, key, medicionesChange, configuracion.TANQUES)
          )}
        </tbody>
      </table>

      {
        errores.map((error) =>
        <div className="table-bottom-error">
          <img src={errorIcon} alt="error" />
          <p>{error}</p>
        </div>
      )}

      <div className="fluctuaciones-ingreso-mediciones-actions">
        <button className="main-button hallow" onClick={() => setMediciones({})}>
          Limpiar
        </button>
        <button className="main-button" onClick={ checkStep } disabled={!selectedEds || !fechaMedicion || !horaMedicion}>
          Confirmar
        </button>
      </div>
    </div>
  );
};
