import React, { useState } from "react";
import SelectedTransactions from "../StepperComponents/SelectedTransactions";
import MandatosChooser from "../StepperComponents/MandatosChooser";
import Comprobante from "../StepperComponents/Comprobante";
import { Stepper } from "../StepperComponents/Stepper";

const ChequeEnLinea = ({
  selectedTransactions,
  totalSaldo,
  mandatosActivos,
  rutConcesionario,
  token,
  clientName,
  clientAddress,
  setDisplayChequeEnLinea,
  closeStepper,
  totalAPagar,
  isArcoprime,
  selectedEdsArcoprime,
  setSelectedTransactions,
  isROImpersonate,
  callServiceHandler,
  displayCustomError
}) => {
  const [step, setStep] = useState(1);
  const [mandato, setMandato] = useState();
  const [propuesta, setPropuesta] = useState();
  const [error, setError] = useState(false);
  const [bancoName, setBancoName] = useState('');
  const [cuenta, setCuenta] = useState('');
  const [ fechaPago, setFechaPago ] = useState('');
  const [totalReal, setTotalReal] = useState('');
  const [transaccionesPropuesta, setTransaccionesPropuesta] = useState([]);

  return (
    <div className="abonar-en-banco">
      <div className="abonar-en-banco__content">
        <Stepper
          step={step}
          error={error}
          totalSteps="3"
          title="Cheque en Línea"
          step1Title="Confirmar"
          step2Title="Pagar"
          step3Title="Finalizar"
        />
        {step === 1 && (
          <SelectedTransactions
            setStep={setStep}
            selectedTransactions={selectedTransactions}
            totalSaldo={totalSaldo}
            isCompensar={false}
            rutConcesionario={rutConcesionario}
            setPropuesta={setPropuesta}
            token={token}
            setError={setError}
            closeStepper={closeStepper}
            callServiceHandler={callServiceHandler}
            displayCustomError={displayCustomError}
          />
        )}
        {step === 2 && (
          <MandatosChooser
            setStep={setStep}
            selectedTransactions={selectedTransactions}
            totalSaldo={totalSaldo}
            mandatosActivos={mandatosActivos}
            rutConcesionario={rutConcesionario}
            token={token}
            mandato={mandato}
            setMandato={setMandato}
            setPropuesta={setPropuesta}
            setError={setError}
            bancoName={bancoName}
            cuenta={cuenta}
            setBancoName={setBancoName}
            setCuenta={setCuenta}
            totalAPagar={totalAPagar}
            setFechaPago={setFechaPago}
            isArcoprime={isArcoprime}
            selectedEdsArcoprime={selectedEdsArcoprime}
            setDisplayChequeEnLinea={setDisplayChequeEnLinea}
            setTotalReal={setTotalReal}
            setTransaccionesPropuesta={setTransaccionesPropuesta}
            callServiceHandler={callServiceHandler}
            displayCustomError={displayCustomError}
          />
        )}
        {step === 3 && (
          <Comprobante
            error={error}
            selectedTransactions={transaccionesPropuesta}
            rutConcesionario={rutConcesionario}
            totalSaldo={totalSaldo}
            banco={mandatosActivos[mandato].BANCO}
            clientName={clientName}
            clientAddress={clientAddress}
            propuesta={propuesta}
            closeStepper={closeStepper}
            title="Comprobante Cheque en Línea"
            bancoName={bancoName}
            cuenta={cuenta}
            totalAPagar={totalReal}
            fechaPago={fechaPago}
            setSelectedTransactions={setSelectedTransactions}
            isROImpersonate={isROImpersonate}
          />
        )}
      </div>
    </div>
  );
};

export default ChequeEnLinea;
