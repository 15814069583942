import { format } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ViewPortContext } from "../../App";
import { MobileFiltersChoice } from "../MobileFilters/MobileFiltersChoice";
import {
  MobileFiltersOpenedChoice,
  MobileFiltersOpenedChoiceOption,
} from "../MobileFilters/MobileFiltersOpenedChoice";
import { spaceAboveIframe } from "../utils/utils";
import { scrollTo } from "../utils/windows";
import DatePicker from "react-datepicker";

const DirectSelectView = ({
  list,
  selectItem,
  closeView,
  selectOptionLabel = (item) => item.label,
}) => {
  return list.map((item, index) => (
    <MobileFiltersOpenedChoiceOption
      name={selectOptionLabel(item)}
      key={`${item.value || item.ID} - ${index}`}
      onClick={() => {
        selectItem(item);
        closeView();
      }}
    />
  ));
};

const DateSelectView = ({ selectItem, closeView, value, minDate }) => {
  return (
    <MobileFiltersOpenedChoiceOption noButton>
      <DatePicker
        locale="es"
        selected={value}
        onChange={(e) => {
          selectItem(e);
          closeView();
        }}
        shouldCloseOnSelect={true}
        minDate={minDate}
        dateFormat="dd/MM/yyyy"
        className="pedidos-datepicker"
        placeholderText="Seleccionar"
      />
    </MobileFiltersOpenedChoiceOption>
  );
};

const FormularioPedidosMobile = ({
  idPedido,
  selectedEds,
  tipoPedido,
  planta,
  camion,
  disabledForm,
  disabledDatePickers,
  date,
  horario,
  contacto,
  eds,
  allPagadores,
  selectedPagador,
  setSelectedPagador,
  isArcoprime,
  tiposPedidos,
  camiones,
  horarios,
  contactos,
  selectEds,
  selectTipoPedido,
  selectCamion,
  selectDate,
  selectHorario,
  selectContacto,
  minDate,
}) => {
  const [openedSelectModal, setOpenedSelectModal] = useState("");
  const { viewPortHeight, iframeHeight } = useContext(ViewPortContext);
  const lastOpenedPosition = useRef(0);

  const fields = {}

  if(isArcoprime){
    fields.pagador = {
      key: "pagador",
      type: "direct",
      label: "ID DE PAGADOR",
      disabled: idPedido,
      list: allPagadores,
      value: selectedPagador?.label,
      select: setSelectedPagador
    }
  }

  
  fields.eds = {
    key: "eds",
    type: "direct",
    label: "ESTACIÓN DE SERVICIO",
    disabled: idPedido,
    list: eds,
    value: selectedEds?.label,
    select: selectEds,
  }

  fields.tiposPedidos = {
    key: "tiposPedidos",
    type: "direct",
    label: "TIPO DE PEDIDO",
    disabled: idPedido,
    list: tiposPedidos,
    value: tipoPedido?.label,
    select: selectTipoPedido,
  }
  
  fields.planta = {
    key: "planta",
    label: "PLANTA",
    disabled: true,
    value: planta,
  }
  
  fields.separator = {
    key: "separator",
    type: "separator",
  }
  
  fields.camion = {
    type: "direct",
    key: "camion",
    label: "TIPO DE CAMIÓN",
    disabled: (disabledForm || (idPedido && tipoPedido.value === "emergencia") || tipoPedido.value === "preprograma"),
    list: camiones,
    value: camion?.NOMBRE,
    select: selectCamion,
    selectOptionLabel: (item) => item.NOMBRE,
  }
  
  fields.date = {
    type: "date",
    key: "date",
    label: "FECHA PREFERENTE",
    disabled: disabledDatePickers,
    value: date ? format(date, "dd/MM/yyyy") : "",
    select: selectDate,
    selectedDate: date,
  }

  fields.horario = {
    type: "direct",
    key: "horario",
    label: "VENTANA DE TIEMPO",
    disabled: disabledDatePickers,
    list: horarios,
    value: horario?.HORARIO,
    select: selectHorario,
    selectOptionLabel: (item) => item.HORARIO,
  }

  fields.contacto = {
    type: "direct",
    key: "contacto",
    label: "CONTACTO",
    disabled: disabledForm,
    list: contactos,
    value: contacto ? `${contacto.NOMBRE} ${contacto.APELLIDO}` : "",
    select: selectContacto,
    selectOptionLabel: (item) => `${item.NOMBRE} ${item.APELLIDO}`,
  }

  const closeSelectionView = () => setOpenedSelectModal("");
  const selectedView = fields[openedSelectModal];

  useEffect(() => {
    if (selectedView) {
      scrollTo(0, 0);
    } 
/*
    else {
      if (lastOpenedPosition.current > 0) {
        setTimeout(() => {
          scrollTo(0, lastOpenedPosition.current - spaceAboveIframe);
          lastOpenedPosition.current = 0;
        }, 0);
      } else {
        lastOpenedPosition.current = 0;
      }
    }
    */
  }, [selectedView]);

  return selectedView ? (
    <div
      className="mobile-select__opened-view"
      style={{
        height: Math.min(iframeHeight, viewPortHeight - spaceAboveIframe),
      }}
    >
      <MobileFiltersOpenedChoice
        itemTitle={selectedView.label}
        closeView={closeSelectionView}
      >
        {selectedView.type === "direct" && (
          <DirectSelectView
            list={selectedView.list}
            selectItem={selectedView.select}
            closeView={closeSelectionView}
            selectOptionLabel={selectedView.selectOptionLabel}
          />
        )}
        {selectedView.type === "date" && (
          <DateSelectView
            selectItem={selectedView.select}
            closeView={closeSelectionView}
            minDate={minDate}
            value={selectedView.selectedDate}
          />
        )}
      </MobileFiltersOpenedChoice>
    </div>
  ) : (
    <>
      <div className="formulario-pedidos-mobile-first-form">
        {Object.values(fields).map(({ label, key, value, disabled, type }) =>
          type === "separator" ? (
            <hr className="push-up" key={key} />
          ) : (
            <MobileFiltersChoice
              key={key}
              itemName={label}
              disabled={disabled}
              onClick={(e) => {
                lastOpenedPosition.current = e.clientY;
                setOpenedSelectModal(key);
              }}
              value={value}
            />
          )
        )}

        <hr />
      </div>
    </>
  );
};

export default FormularioPedidosMobile;
