import React, { useState, useEffect } from "react";
import HeaderData from "../HeaderData";
import Select from "react-select";
import { SingleOption, 
  customStyles, 
  setMaterialesYTanques, 
  parseDate, 
  getAproximate3FloatString, 
  getNumberFromLocalString,
  getLocaleStringFloat } from "../utils/utils.js";

import { getEdsPedidosActivos, getConfiguracion } from "../utils/get";

import {
  displayLoader,
  hideLoader,
  showGreyBackground, 
  hideGreyBackground, 
  parentRedirect,
  scrollTo
} from "../utils/windows"

import { getUpdateDate } from "../utils/monthNames";
import { CopecStepper } from "../CopecStepper";
import DatePicker from "react-datepicker";
import { IngresoMediciones } from "./IngresoMediciones";
import { AsignacionEntradas } from "./AsignacionEntradas";
import { ConfirmacionSalidas } from "./ConfirmacionSalidas";
import { CalibracionesYMovBodega } from "./CalibracionesYMovBodega";
import { ConfirmacionFluctuaciones } from "./ConfirmacionFluctuaciones";
import Timepicker from "../Timepicker";
import CustomModal from '../CustomModal'

// const stepNames=[
//   "Ingreso Mediciones",
//   "Asignación de entradas a tanques",
//   "Confirmación de salidas",
//   "Calibraciones y movimientos de bodega",
//   "Confirmación de fluctuaciones"
// ]
const stepNames=[
  "Cálculo de Fluctuaciones",
  "Cálculo de Fluctuaciones",
  "Cálculo de Fluctuaciones",
  "Cálculo de Fluctuaciones",
  "Cálculo de Fluctuaciones"
]

export const FluctuacionesIngresoMediciones = ({ token, rutConcesionario, viewPortHeight, callServiceHandler }) => {
  const [step, setStep] = useState(1)
  const [stepLoaded, setStepLoaded] = useState([])
  const [updateDate, setUpdateDate] = useState('')
  const [allEds, setAllEds] = useState([])
  const [selectedEds, setSelectedEds] = useState()
  const [displayPeriodosModal, setDisplayPeriodosModal] = useState(false)
  const [displayHoraModal, setDisplayHoraModal] = useState(false)
  const [displayModal, setDisplayModal] = useState(false)
  const [modalTitle, setModalTitle] = useState("Períodos sin validar")
  const [modalText, setModalText] = useState("Existen periodos sin validar para la EDS selecionada. No se pueden ingresar nuevos datos hasta validar los anteriores.")
  const [fechaMedicion, setFechaMedicion] = useState()
  const [horaMedicion, setHoraMedicion] = useState()
  const [configuracion, setConfiguracion] = useState()
  const [mediciones, setMediciones] = useState({})
  const [entradas, setEntradas] = useState([])
  const [salidas, setSalidas] = useState({})
  const [calibracionesEntradas, setCalibracionesEntradas] = useState({})
  const [calibracionesSalidas, setCalibracionesSalidas] = useState({})
  const [calibracionesRecomendadas, setCalibracionesRecomendadas] = useState({})
  const [materiales, setMateriales] = useState([])
  const [tanques, setTanques] = useState([])
  const [movimientosBodega, setMovimientosBodega] = useState([])
  const [movimientosRecomendados, setMovimientosRecomendados] = useState([])
  const [revision, setRevision] = useState({})
  const [openEntradas, setOpenEntradas] = useState(false)
  const [openSalidas, setOpenSalidas] = useState(false)
  const [pedidos, setPedidos] = useState([])
  const [openPedidos, setOpenPedidos] = useState(false)
  const [ultimaFluctuacion, setUltimaFluctuacion] = useState()
  const [lastUrlPedidos, setLastUrlPedidos] = useState("")
  const [ventasCamiones, setVentasCamiones] = useState({})
  const [ventasTanques, setVentasTanques] = useState({})
  const [lastUrlVentasCamiones, setLastUrlVentasCamiones] = useState("")
  const [lastUrlVentasTanques, setLastUrlVentasTanques] = useState("")
  const [stepToClear, setStepToClear] = useState()
  const nowDate = new Date();
  const [ultimaFluctuacionDate, setUltimaFluctuacionDate] = useState()
  const [telemedicion, setTelemedicion] = useState({});
  const [filteredTanques, setFilteredTanques] = useState({})

  useEffect(() => {
    getUpdateDate(setUpdateDate)
  }, []);

  useEffect(() => {
    if(allEds.length === 1)
      setSelectedEds(allEds[0])
  }, [allEds]);

  useEffect(() => {
    if(configuracion){
      setMaterialesYTanques(configuracion.TANQUES, setTanques, setMateriales)
    }
  }, [configuracion])

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (token && rutConcesionario) {
        getEdsPedidosActivos(callServiceHandler, rutConcesionario, setAllEds);
      }
    } else {
      if (token) {
        getEdsPedidosActivos(callServiceHandler, rutConcesionario, setAllEds);
      }
    }
  }, [token, rutConcesionario]);

  useEffect(() => {
    if(selectedEds){
      getConfiguracion(callServiceHandler, selectedEds.value, setConfiguracion)
      getUltimaFluctuacion()
    }
  }, [selectedEds]);

  useEffect(() => {
    async function fetchTelemedicion(){
      let fechaDesde = ultimaFluctuacion?.FECHA_HASTA? `${ultimaFluctuacion.FECHA_HASTA}`: '-';
      let horaDesde = ultimaFluctuacion?.HORA_DESDE? `${ultimaFluctuacion.HORA_DESDE}`: '-';

      let url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/telemediciones?ID_EDS=" 
      + selectedEds.value +"&FECHA_HASTA=" + parseDate(fechaMedicion)+"&HORA_HASTA="+horaMedicion.concat(":00")

      if(fechaDesde!=="-"){
        url += "&FECHA_DESDE=" + fechaDesde+"&HORA_DESDE=" + horaDesde;
      }

      let telemedicion = {}

      let response = await callServiceHandler(url, "GET")
      if(response !== "error"){
        telemedicion = response.data.TELEMEDICIONES;
        setTelemedicion(telemedicion);
      }

      let tmpMediciones = {};

      for (const tanque of configuracion.TANQUES) {
        if(tanque.ID in telemedicion){
          tmpMediciones[tanque.ID] = getAproximate3FloatString(telemedicion[tanque.ID]);
        }
      }
      setMediciones(tmpMediciones);
    }

    if (fechaMedicion && horaMedicion) {
      reset()
      const tmpStepLoaded = []
      tmpStepLoaded[1] = true
      setStepLoaded(tmpStepLoaded)
      fetchTelemedicion()
    }

  }, [fechaMedicion, horaMedicion]);

  useEffect(() => {
    async function fetchEntradasRecomendadas() {
      let fechaDesde = ultimaFluctuacion?.FECHA_HASTA? `${ultimaFluctuacion.FECHA_HASTA}`: '-'
      if (fechaDesde === "-"){ 
        let d = new Date(fechaMedicion.getTime())
        d.setDate(d.getDate()-5);
        fechaDesde = `${d.getDate().toString().padStart(2, "0")}/`
        + `${(d.getMonth() + 1).toString().padStart(2, "0")}/`
        + `${d.getFullYear().toString()}`
      }
      let fechaHasta = `${fechaMedicion.getDate().toString().padStart(2, "0")}/`
        + `${(fechaMedicion.getMonth() + 1).toString().padStart(2, "0")}/`
        + `${fechaMedicion.getFullYear().toString()}`
      const url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/recomendar-entradas?ID_EDS=" 
        + selectedEds.value + "&FECHA_DESDE=" + fechaDesde + "&FECHA_HASTA=" + fechaHasta
      if (lastUrlPedidos !== url) {
        setLastUrlPedidos(url)

        const oldPedidos = Object.fromEntries(pedidos.map(
          (pedido) => [pedido.ID_PEDIDO, pedido]
        ))
        // seteamos horas para comparar despues
        let horaDesde = ultimaFluctuacion?.HORA_HASTA? `${ultimaFluctuacion.HORA_HASTA}`: '00:00:00'
        horaDesde = horaDesde.substring(0, 5)
        let parts = fechaDesde.split("/")
        fechaDesde = `${parts[2]}-${parts[1]}-${parts[0]}`
        parts = fechaHasta.split("/")
        fechaHasta = `${parts[2]}-${parts[1]}-${parts[0]}`

        let response = await callServiceHandler(url, "GET")
        if(response !== "error"){
          setPedidos(response.data?.pedidos.map((pedido) => {
            let newPedido = {...pedido}
            if (pedido.ID_PEDIDO in oldPedidos)  {
              newPedido = oldPedidos[pedido.ID_PEDIDO]
            }
            else {
              newPedido.DESCARGAS = newPedido.DESCARGAS.map((descarga) => {
                const tmpDescarga = descarga
                tmpDescarga.tanquesOptions = tanques.filter((t) => 
                  (t.idMaterial === descarga.material && t.tipo !== 'camion')
                )
                if (tmpDescarga.tanquesOptions.length === 1) tmpDescarga.tanque = tmpDescarga.tanquesOptions[0]
                return descarga
              })
              
              
              if ((newPedido.FECHA < fechaHasta || (newPedido.FECHA === fechaHasta && newPedido.HORA <= horaMedicion))
                && (newPedido.FECHA > fechaDesde || (newPedido.FECHA === fechaDesde && newPedido.HORA >= horaDesde))) {
                newPedido.checked = true
              }
            }
            return newPedido
          }))
        }
      }
    }
    if (fechaMedicion && step === 2 && !stepLoaded[2]) {
      const tmpStepLoaded = [...stepLoaded]
      tmpStepLoaded[2] = true
      setStepLoaded(tmpStepLoaded)
      fetchEntradasRecomendadas()
    }
  }, [fechaMedicion, step])

  useEffect(() => {
    async function fetchVentasRecomendadas() {
      displayLoader();
      // fecha y hora
      const horaDesde = ultimaFluctuacion?.HORA_HASTA? `${ultimaFluctuacion.HORA_HASTA}`: '00:00:00'
      let fechaDesde = ultimaFluctuacion?.FECHA_HASTA? `${ultimaFluctuacion.FECHA_HASTA}`: '-'
      if (fechaDesde === "-"){ 
        let d = new Date(fechaMedicion.getTime())
        d.setDate(d.getDate()-5);
        fechaDesde = `${d.getDate().toString().padStart(2, "0")}/`
        + `${(d.getMonth() + 1).toString().padStart(2, "0")}/`
        + `${d.getFullYear().toString()}`
      }
      let fechaHasta = `${fechaMedicion.getDate().toString().padStart(2, "0")}/`
        + `${(fechaMedicion.getMonth() + 1).toString().padStart(2, "0")}/`
        + `${fechaMedicion.getFullYear().toString()}`
      // ventas tanques, calibraciones y mov_bodega 
      const query_params = "?ID_EDS=" + selectedEds.value + "&fecha_inicio=" + fechaDesde + "&fecha_fin=" + fechaHasta 
        + "&hora_inicio=" + horaDesde + "&hora_fin=" + horaMedicion + ":00"
      let url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/recomendar-salidas-tanques" + query_params
      if (lastUrlVentasTanques !== url) {
        setLastUrlVentasTanques(url)

        let tmpVentasTanques = {}
        let tmpMovimientosBodega = []
        let tmpCalibraciones = {}

        let response = await callServiceHandler(url, "GET")
        if(response !== "error"){
          tmpVentasTanques = response.data.VENTAS
          tmpMovimientosBodega = response.data.MOVIMIENTOS_BODEGA
          tmpMovimientosBodega = tmpMovimientosBodega.map((mov) => {
            const tanqueOrigen = tanques.find(t => t.value === mov.tank_group)
            return {
              material: mov.material,
              tanqueOrigen: tanqueOrigen,
              tanqueDestino: '',
              volumen: mov.volume
            }
          });
          tmpCalibraciones = response.data.CALIBRACIONES
          for (const tanque of configuracion.TANQUES) {
            if (tanque.TIPO.toLowerCase() === "tanque" 
              && (!(tanque.ID in tmpVentasTanques) || (tanque.ID in tmpVentasTanques && tmpVentasTanques[tanque.ID] === 0)))
            {
              tmpVentasTanques[tanque.ID] = '0'
            }
            else{
              tmpVentasTanques[tanque.ID] = getLocaleStringFloat(tmpVentasTanques[tanque.ID])
            }
          }
        }

        setVentasTanques(tmpVentasTanques)
        setMovimientosRecomendados(tmpMovimientosBodega)
        setCalibracionesRecomendadas(tmpCalibraciones)
      }
      // Ventas camiones
      url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/recomendar-salidas-camiones" + query_params
        if (lastUrlVentasCamiones !== url) {
          setLastUrlVentasCamiones(url)

          let tmpVentasCamiones = {}
          let response = await callServiceHandler(url, "GET")
          if(response !== "error"){
            tmpVentasCamiones = response.data.VENTAS_CAMIONES
          }

          for (const tanque of configuracion.TANQUES) {
            if (tanque.TIPO.toLowerCase() === "camion" 
              && (!(tanque.ID in tmpVentasCamiones) || (tanque.ID in tmpVentasCamiones && tmpVentasCamiones[tanque.ID] === 0)))
            {
              tmpVentasCamiones[tanque.ID] = '0'
            }
          }
          setVentasCamiones(tmpVentasCamiones)
        }
    }
    if (fechaMedicion && step === 3 && !stepLoaded[3]) {
      const tmpStepLoaded = [...stepLoaded]
      tmpStepLoaded[3] = true
      setStepLoaded(tmpStepLoaded)
      fetchVentasRecomendadas()
    }
  }, [fechaMedicion, step])

  useEffect(() => {
    const tmpSalidas = {...ventasTanques, ...ventasCamiones};
    Object.keys(tmpSalidas).forEach(function(key) {
      tmpSalidas[key] = getAproximate3FloatString(tmpSalidas[key])
    });
    setSalidas(tmpSalidas)

  }, [ventasCamiones, ventasTanques])

  useEffect(() => {
    let tmpEntradas = {}
    let tmpSalidas = {}
    for (const key in calibracionesRecomendadas) {
      tmpEntradas[key] = getAproximate3FloatString(calibracionesRecomendadas[key]["entrada"])
      tmpSalidas[key] = getAproximate3FloatString(calibracionesRecomendadas[key]["salida"])      
    }
    setCalibracionesEntradas(tmpEntradas)
    setCalibracionesSalidas(tmpSalidas)
  }, [calibracionesRecomendadas])

  useEffect(() => {
    const tmpMovimientosBodega = []
    const tempTanques = {}

    movimientosRecomendados.forEach(function(mov, key){
      mov.volumen = getAproximate3FloatString(mov.volumen)
      tmpMovimientosBodega.push(mov)
      const newTanques = tanques.filter(t => t.idMaterial === mov.material.value)
      tempTanques[key] = newTanques
    })

    setMovimientosBodega(tmpMovimientosBodega)
    setFilteredTanques(tempTanques)
  }, [movimientosRecomendados]);

  function reset(){
    setMediciones({})
    setEntradas([])
    setSalidas({})
    setCalibracionesEntradas({})
    setCalibracionesSalidas({})
    setCalibracionesRecomendadas({})
    setMovimientosBodega([])
    setMovimientosRecomendados([])
    setRevision({})
    setOpenEntradas(false)
    setOpenSalidas(false)
    setPedidos([])
    setOpenPedidos(false)
    setLastUrlPedidos("")
    setVentasCamiones({})
    setVentasTanques({})
    setLastUrlVentasCamiones("")
    setLastUrlVentasTanques("")
    setStepToClear()
    setTelemedicion({});
    setFilteredTanques({})
  }

  function openCloseModals(isOpen, modalFunc){
    modalFunc(isOpen)
    if(isOpen){
      showGreyBackground()
      scrollTo(0, 50)
    }
    else{
      hideGreyBackground()
    }
  }

  function closeModal(){
    setDisplayModal(false)
    setDisplayPeriodosModal(false)
    setDisplayHoraModal(false)
  }

  function resetForm(){
    setStep(1)
    getConfiguracion(callServiceHandler, selectedEds.value, setConfiguracion)
  }

  function clearTableAlert(stepNum){
    setModalTitle("¿Desea Limpiar la tabla?")
    setModalText("Se borraran todos los datos ingresados.")
    setDisplayModal(true)
    setStepToClear(stepNum)
  }

  function clearTable(){
    setDisplayModal(false)
    if(stepToClear === 1)
      setMediciones({})
  }

  function acceptFunc(){}

  async function revisarFluctuacion(){      
    const data = {
      ID_EDS: selectedEds.value,
      FECHA_MEDICION: parseDate(fechaMedicion),
      HORA_MEDICION: horaMedicion,
      OBSERVACIONES: ''
    }

    const tmpMediciones = []

    configuracion.TANQUES.forEach(function(tanque){
      if(mediciones[tanque.ID]){
        const newMedicion = {
          ID_TANQUE: tanque.ID,
          ID_MATERIAL: tanque.ID_MATERIAL,
          VOLUMEN: getNumberFromLocalString(mediciones[tanque.ID])
        }
        tmpMediciones.push(newMedicion)
      }
    })

    data.MEDICIONES = tmpMediciones

    const tmpEntradas = []
    entradas.forEach(function(entrada){
      const newEntrada = {
        ID_TANQUES: entrada.tanque.value.toString().split(','),
        ID_MATERIAL: entrada.material.value,
        VOLUMEN: getNumberFromLocalString(entrada.volumen)
      }
      tmpEntradas.push(newEntrada)
    })
    pedidos.forEach((pedido) => {
      if (pedido.checked) {
        pedido.DESCARGAS.forEach( (descarga) => {
          const newEntrada = {
            ID_TANQUES: descarga.tanque.value.toString().split(','),
            ID_MATERIAL: descarga.material,
            VOLUMEN: descarga.volumen
          }
          tmpEntradas.push(newEntrada)
        })
      }
    })

    data.ENTRADAS = tmpEntradas

    const tmpCalibraciones = []
    const tmpSalidas = []

    tanques.forEach(function(tanque) {
      const tanqueIds = tanque.value.toString().split(',')
      let tmpSalidasValue = 0, tmpCalibracionesSalidas = 0, tmpCalibracionesEntradas = 0

      tanqueIds.forEach(function(t){
        tmpCalibracionesSalidas += getNumberFromLocalString(calibracionesSalidas[t])
        tmpCalibracionesEntradas += getNumberFromLocalString(calibracionesEntradas[t])
        tmpSalidasValue += getNumberFromLocalString(salidas[t])
      })

      const newCalibracion = {
        ID_TANQUES: tanque.value.toString().split(","),
        ID_MATERIAL: tanque.idMaterial,
        SALIDA: tmpCalibracionesSalidas,
        ENTRADA: tmpCalibracionesEntradas
      }

      tmpCalibraciones.push(newCalibracion)

      const newSalida = {
        ID_TANQUES: tanque.value.toString().split(","),
        ID_MATERIAL: tanque.idMaterial,
        VOLUMEN: tmpSalidasValue
      }

      tmpSalidas.push(newSalida)
    })

    data.CALIBRACIONES = tmpCalibraciones
    data.SALIDAS = tmpSalidas

    const tmpMovimientos = []
    movimientosBodega.forEach(function(movimiento){
      const newMovimiento = {
        ID_TANQUES_ORIGEN: movimiento.tanqueOrigen.value.toString().split(','),
        ID_TANQUES_DESTINO: movimiento.tanqueDestino.value.toString().split(','),
        ID_MATERIAL: movimiento.material.value,
        VOLUMEN: getNumberFromLocalString(movimiento.volumen)
      }
      tmpMovimientos.push(newMovimiento)
    })

    data.MOVIMIENTOS_BODEGA = tmpMovimientos
    data.TELEMEDICIONES = telemedicion;

    const tmpVentasRecomendadas = {...ventasTanques, ...ventasCamiones}
    data.VENTAS_RECOMENDADAS = tmpVentasRecomendadas
    data.MOVIMIENTOS_BODEGA_RECOMENDADOS = movimientosRecomendados
    data.CALIBRACIONES_RECOMENDADAS = calibracionesRecomendadas

    const url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/revisar-fluctuacion"
    let result = await callServiceHandler(url, "POST", JSON.stringify(data))
    if(result !== "error"){
      setRevision(result.data)
      setStep(5)
    }
  }

  async function getUltimaFluctuacion(){
    displayLoader()
    const url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/ultima-fluctuacion?ID_EDS=" + selectedEds.value

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      if (result.data) {
        setUltimaFluctuacion(result.data)
        const startDateArr = result.data.FECHA_HASTA.split('/')
        const startHourArr = result.data.HORA_HASTA.split(':')
        const start_date = new Date(startDateArr[2], parseInt(startDateArr[1]) - 1, startDateArr[0], startHourArr[0], startHourArr[1])
        setUltimaFluctuacionDate(start_date);

        if(result.data.VALIDO!=="1"){
          setDisplayPeriodosModal(true);
        }
      }
      else {
        setUltimaFluctuacion({MEDICIONES: []})
      }
    }
  }

  function irAHistorial(){
    parentRedirect(process.env.REACT_APP_HISTORIAL_FLUCTUACIONES_PATH);
  }

  return (
    <div className="page fluctuaciones-ingreso-mediciones">
      <div className="view-title">Fluctuaciones</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">{stepNames[step - 1]}</div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandler={callServiceHandler}
      />
      <hr />
      <div className="page-options">
        <div className="filters-container">
          <div className="filter">
            <div className="filter-title required-field">ESTACIÓN DE SERVICIO</div>
            <div className="filter-input">
              <Select
                className="eds-select"
                value={selectedEds}
                onChange={(e) => setSelectedEds(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allEds}
                components={{ SingleOption, IndicatorSeparator: () => null }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccionar"
                styles={customStyles}
                isDisabled={step !== 1}
              />
            </div>
          </div>
        </div>

        <CopecStepper
          steps={[
            "Ingreso Mediciones",
            "Asignación Entradas",
            "Confirmación Salidas",
            "Calibraciones y Mov. Bodega",
            "Confirmación Fluctuaciones",
          ]}
          activeStep={step}
          className="fluctuaciones-ingreso-mediciones-stepper"
        />
      </div>
      <div className="page-options second-row-options">
        <div className="filters-container">
          <div className="filter">
            <div className="filter-title">MEDICIÓN ANTERIOR</div>
            <div className="filter-input">
              <input
                type="text"
                className="filter-input-element"
                disabled
                value={ultimaFluctuacion?.FECHA_HASTA? `${ultimaFluctuacion.FECHA_HASTA} ${ultimaFluctuacion.HORA_HASTA}`: ' - '}
              />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title required-field">FECHA MEDICIÓN</div>
            <div className="filter-input">
              <DatePicker 
                locale="es"
                selected={fechaMedicion}
                onChange={(e) => setFechaMedicion(e)}
                shouldCloseOnSelect = {true}
                dateFormat = "dd/MM/yyyy"
                maxDate = {new Date()}
                minDate={ultimaFluctuacionDate?ultimaFluctuacionDate:null}
                placeholderText = "Seleccionar"
                disabled={step !== 1}
              />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title required-field">HORA MEDICIÓN</div>
            <div className="filter-input">
              <Timepicker 
                onSelect={setHoraMedicion}
                disabled={step !== 1 || typeof fechaMedicion === "undefined"}
                placeholder={typeof fechaMedicion === "undefined"?"Seleccionar Fecha":"Seleccionar"}
                fechaMedicion={fechaMedicion}
                ultimaFluctuacionDate={ultimaFluctuacionDate}
                validate={true}
                setHoraMedicion={setHoraMedicion}
              />
            </div>
          </div>
        </div>
        {step === 2 && (
          <div className="actions">
            <button className="main-button" onClick={() => openCloseModals(true, setOpenPedidos)}>Seleccionar Pedidos</button>
          </div>
        )}
        {step === 5 && (
          <div className="actions">
            <button className="main-button" onClick={() => openCloseModals(true, setOpenEntradas)}>Ver entradas</button>
            <button className="main-button" onClick={() => openCloseModals(true, setOpenSalidas)}>Ver salidas</button>
          </div>
        )}
      </div>

      {step === 1 && 
        <IngresoMediciones 
          setStep={setStep} 
          selectedEds={selectedEds} 
          configuracion={configuracion} 
          mediciones={mediciones}
          setMediciones={setMediciones}
          fechaMedicion={fechaMedicion}
          horaMedicion={horaMedicion}
      />}
      {step === 2 && 
        <AsignacionEntradas
          entradas={entradas}
          setEntradas={setEntradas}
          setStep={setStep} 
          tanques={tanques}
          ultimaFluctuacion={ultimaFluctuacion}
          fechaMedicion={fechaMedicion}
          horaMedicion={horaMedicion}
          openPedidos={openPedidos}
          setOpenPedidos={setOpenPedidos}
          openCloseModals={openCloseModals}
          pedidos={pedidos}
          setPedidos={setPedidos}
          ultimaFluctuacionDate={ultimaFluctuacionDate}
          viewPortHeight={viewPortHeight}
      />}
      {step === 3 && 
        <ConfirmacionSalidas 
          setStep={setStep} 
          configuracion={configuracion}
          salidas={salidas}
          setSalidas={setSalidas}
      />}
      {step === 4 && 
        <CalibracionesYMovBodega
          revisarFluctuacion={revisarFluctuacion}
          setStep={setStep} 
          configuracion={configuracion}
          calibracionesEntradas={calibracionesEntradas}
          setCalibracionesEntradas={setCalibracionesEntradas}
          calibracionesSalidas={calibracionesSalidas}
          setCalibracionesSalidas={setCalibracionesSalidas}
          materiales={materiales}
          tanques={tanques}
          movimientosBodega={movimientosBodega}
          setMovimientosBodega={setMovimientosBodega}
          filteredTanques={filteredTanques}
          setFilteredTanques={setFilteredTanques}
      />}
      {step === 5 && 
        <ConfirmacionFluctuaciones 
          token={token}
          setStep={setStep}
          resetForm={resetForm}
          configuracion={configuracion}
          tanques={tanques}
          revision={revision}
          openEntradas={openEntradas}
          setOpenEntradas={setOpenEntradas}
          openSalidas={openSalidas}
          setOpenSalidas={setOpenSalidas}
          openCloseModals={openCloseModals}
          ultimaFluctuacion={ultimaFluctuacion}
          viewPortHeight={viewPortHeight}
          callServiceHandler={callServiceHandler}
      />}

      <CustomModal
        displayModal = { displayPeriodosModal }
        closeModal = { irAHistorial }
        acceptFunc = { irAHistorial }
        acceptText = "Ir a Validar"
        modalTitle = "Períodos sin validar"
        modalText = "Existen periodos sin validar para la EDS selecionada. No se pueden ingresar nuevos datos hasta validar los anteriores."
        modalIcon = "error"
      />

      <CustomModal
        displayModal = { displayModal }
        closeModal = { closeModal }
        acceptFunc = { clearTable }
        cancelFunc = { closeModal }
        modalTitle = { modalTitle }
        modalText = { modalText }
        modalIcon = "error"
      />

      <CustomModal
        displayModal = { displayHoraModal }
        closeModal = { closeModal }
        acceptFunc = { closeModal }
        acceptText = "Aceptar"
        modalTitle = "Hora Máxima"
        modalText = "No se puede seleccionar una hora superior a la hora actual."
        modalIcon = "error"
      />

    </div>
  );
};
