import React from "react";
import Select from "react-select";
import { useEffect } from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import CustomModal from "../../CustomModal";
import TimePickerLibrary from "../../TimePickerLibrary";
import { parseDate, parseDateAmerican, toDateFromAmerican } from "../../utils/utils";
import { SingleOption, customStylesTctTae } from "../../utils/utils.js";

const ConfirmarGuiasManualesDetalle = ({
  callServiceHandler,
  setDisplayGuiaManualDetalle,
  guiaManualNumero,
  instalacion,
  clienteNombre,
  clienteDireccion,
  clienteComuna,
  clienteRut,
  clienteGiro,
  clienteTarjeta,
  clienteCodigo,
  clienteSucursal,
  ventaNumeroGuia,
  ventaFecha,
  ventaHora,
  ventaCodigoAutorizacion,
  ventaResponsableGuia,
  ventaTransaccionNumero,
  codigoProducto,
  codigoSubProducto,
  tableProducto,
  tableMonto,
  tablePrecio,
  tableUnidad,
  flete,
  selectedProduct,
  nombrePortal,
  rutPortal,
  getGuiasManuales,
  selectedEds,
  setData,
  token
}) => {

  const [fechaUsuario, setFechaUsuario] = useState();
  const [hour, setHour] = useState("0:00");
  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [displayWarningModal, setDisplayWarningModal] = useState(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  // const [totalGeneral, setTotalGeneral] = useState([]);
  const [productoTable, setProductoTable] = useState();
  const [precioTable, setPrecioTable] = useState(100);
  const [unidadesTable, setUnidadesTable] = useState();
  const [montoTable, setMontoTable] = useState();
  const [montoMaximo, setMontoMaximo] = useState(500);

  const [montoError, setMontoError] = useState(false);
  const [isFieldsComplete, setIsFieldsComplete] = useState(false);

  const [allTiposEntrega, setAllTiposEntrega] = useState();
  const [selectedTipoEntrega, setSelectedTipoEntrega] = useState();

  const [errorExist, setErrorExist] = useState(false);
  const [mensajeError, setMensajeError] = useState();

  const [ordenCompra, setOrdenCompra] = useState("12345678");
  // const [ordenCompra, setOrdenCompra] = useState();
  const [montoOrdenCompra, setMontoOrdenCompra] = useState("324");
  // const [montoOrdenCompra, setMontoOrdenCompra] = useState();

  useEffect(() => {
    if (tableProducto) {
      setProductoTable(tableProducto)
    }
  }, [tableProducto]);

  useEffect(() => {
    if (tableMonto) {
      setMontoTable(tableMonto)
    }
  }, [tableMonto]);

  useEffect(() => {
    if (tablePrecio) {
      setPrecioTable(tablePrecio)
    }
  }, [tablePrecio]);

  useEffect(() => {
    if (tableUnidad) {
      setUnidadesTable(tableUnidad)
    }
  }, [tableUnidad]);

  useEffect(() => {
    if (ventaHora) {
      setHour(ventaHora)
    }
  }, [ventaHora]);

  useEffect(() => {
    if (fechaUsuario) {
      console.log(fechaUsuario)
      console.log(parseDateAmerican(fechaUsuario))
    };
  }, [fechaUsuario]);

  useEffect(() => {
    if (ventaFecha) {
      console.log("fecha americana", ventaFecha)
      console.log("fecha nueva", toDateFromAmerican(ventaFecha))
      setFechaUsuario(toDateFromAmerican(ventaFecha))
    }
  }, [ventaFecha]);

  const openWarningModal = () => {
    setDisplayWarningModal(true)
  }

  const closeWarningModal = () => {
    setDisplayWarningModal(false)
  }

  const openDeleteModal = () => {
    anularGuiaManual()
    
  }

  const closeDeleteModal = () => {
    setDisplayDeleteModal(false)
    setDisplayGuiaManualDetalle(false)
  }

  const openConfirmModal = () => {
    setDisplayConfirmModal(true)
    // let eds = parseInt(selectedEds.value)
    // getGuiasManuales(eds, selectedProduct.value)

  }

  const closeConfirmModal = () => {
    let eds = parseInt(selectedEds.value)

    getGuiasManuales(eds, selectedProduct.value)
    setDisplayConfirmModal(false)
    setDisplayGuiaManualDetalle(false)
  }

  const confirmarIngreso = () =>{
    setErrorExist(false)
    autorizarGuiaManual()
    // openConfirmModal()
  }

  const getTotalProducto = (objeto) => {
    let total = {
      controlador: objeto.venta_propia.controlador + objeto.producto_copec.controlador,
      documentados: objeto.venta_propia.documentados + objeto.producto_copec.documentados,
      confirmados: objeto.venta_propia.confirmados + objeto.producto_copec.confirmados,
    }


    return total
  }

  const getTotalGeneral = (array, atributo) => {
    let contador = 0;
  
    array.forEach(e => {
      contador = contador + e.venta_propia[atributo] + e.producto_copec[atributo];
    });


    return contador;
  }

  // const getMontoLitros = (e) => {
  //   if (e.target.name === "litros-table") {
  //     setMontoTable(litrosTable*precioTable)
  //   }else{
  //     setLitrosTable(montoTable/precioTable)
  //   }
  // }

  useEffect(() => {
    getTiposEntrega()
  }, []);

  function changeTipoEntrega(e) {
    if (e !== selectedTipoEntrega) {
      setSelectedTipoEntrega(e);
    }
  }

  const getTiposEntrega = async () => {
    const url =
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-tipos-entrega";

    let response = await callServiceHandler(url, "GET");

    console.log("tipos entrega", response.data)

    let tipos = response.data.tipos_entrega.map(tipo=>{
      return(
        {
          value:tipo.cod_entrega,
          label:tipo.descripcion
        }
      )
    })

    setAllTiposEntrega(tipos)

    console.log(tipos)

  }

  useEffect(() => {
    if (fechaUsuario) {
      console.log("fecha en formulario", fechaUsuario)
    }
  }, [fechaUsuario]);

  const autorizarGuiaManual = async () => {

    let new_hora = hour
    if (hour.length <6) {
      new_hora = `${new_hora}:00`
    }

    console.log("hora modif", new_hora)

    let body = {
      codigo_cliente: clienteCodigo,
      sucursal_cliente: clienteSucursal,
      numero_tarjeta: clienteTarjeta,
      numero_transaccion: ventaTransaccionNumero,
      fecha_transaccion: ventaFecha,
      fecha_usuario: parseDateAmerican(fechaUsuario),
      hora_transaccion: new_hora,
      precio: precioTable,
      unidad: unidadesTable,
      monto: (montoTable + flete),
      cod_producto: codigoProducto,
      cod_subproducto: codigoSubProducto
    }

    if (selectedTipoEntrega) {
      body={...body, tipo_entrega: selectedTipoEntrega.value}
    }

    

    console.log(body)

    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/confirmar-guia";
    

    let response = await callServiceHandler(url, "POST", JSON.stringify(body) );
    // let response = await callServiceHandler(url, "GET");

    console.log("confirmar", response)

    if (response.data.error === "") {
      let eds = parseInt(selectedEds.value)
      // getGuiasManuales(eds, selectedProduct.value)
      openConfirmModal()
    }else{
      setErrorExist(true)
      setMensajeError(response.data.mensaje)
    }

  }

  const anularGuiaManual = async () => {

    let body = {
      nombre_portal : nombrePortal,
      rut_portal : rutPortal,
      numero_tarjeta: clienteTarjeta,
      codigo_cliente: clienteCodigo,
      numero_transaccion: ventaTransaccionNumero,
    }

    console.log(body)

    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/anular-guia";
    

    let response = await callServiceHandler(url, "POST", JSON.stringify(body) );
    // let response = await callServiceHandler(url, "GET");

    console.log("anular", response)

    if (response.data.error === "") {
      setDisplayWarningModal(false)
      setDisplayDeleteModal(true)
      let eds = parseInt(selectedEds.value)
      getGuiasManuales(eds, selectedProduct.value)
    }else{
      setErrorExist(true)
      setMensajeError(response.data.mensaje)
    }


  }

  const getMontoLitros = (e, existMontoMaximo=false, montoMaximo) => {
    let unidadesTranform = unidadesTable.replace(",", ".")
    if (existMontoMaximo) {
      if (e.target.name === "litros-table") {
        if (unidadesTranform*precioTable > montoMaximo) {
          console.log("ERROR")
          setMontoTable(unidadesTranform*precioTable)
          setMontoError(true)
        }else{
          setMontoTable(unidadesTranform*precioTable)
          setMontoError(false)
        }
      }else{
        if (montoTable>montoMaximo) {
          console.log("ERROR")
          setMontoError(true)
          setUnidadesTable(montoTable/precioTable)
        }else{
          setUnidadesTable(montoTable/precioTable)
          setMontoError(false)
        }
      }
    }else{
      if (e.target.name === "litros-table") {
        setMontoTable(unidadesTranform*precioTable)
        setMontoError(false)
      }else{
        setUnidadesTable(montoTable/precioTable)
        setMontoError(false)
      }
    }
  }

  const handlePressOnKey = (e) => {
    console.log(e.key)
    if (e.key === "Enter") {
      getMontoLitros(e, false, montoMaximo)
    }
  }

  useEffect(() => {
    if (fechaUsuario) {
      console.log("fecha",{fecha:fechaUsuario})
    }
  }, [fechaUsuario]);



  return (
    <div className="confirmar-guias-manuales-detalle">
      <div className="numero-guia-container">
        <div className="numero-guia">
          <h1 className="title">Detalle Guía Manual</h1>
          <p className="subtitle">N° {ventaNumeroGuia}</p>
        </div>
        <button className="main-button thin hallow" onClick={() => setDisplayGuiaManualDetalle(false)}>Volver</button>
      </div>
      <div className="estacion-de-servicio">
        <div className="filters-container">
          <div className="filter">
            <div className="filter-title">INSTALACION</div>
            <div className="filter-input">
              <input
                className="disabled-input"
                type="text"
                value={instalacion}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="separator">
        <div className="text">DATOS DEL CLIENTE</div>
        <div className="line"></div>
      </div>
      <div className="datos-cliente-industrial">
        <div className="filters-container first-row">
          <div className="filter">
            <div className="filter-title">NOMBRE</div>
            <div className="filter-input">
              <input
                className="disabled-input nombre-input"
                type="text"
                value={clienteNombre}
              />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title">DIRECCIÓN</div>
            <div className="filter-input direccion-input">
              <input
                className="disabled-input"
                type="text"
                value={clienteDireccion}
              />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title">COMUNA</div>
            <div className="filter-input comuna-input">
              <input className="disabled-input" type="text" value={clienteComuna} />
            </div>
          </div>
        </div>
        <div className="filters-container second-row">
          <div className="filter">
            <div className="filter-title">RUT</div>
            <div className="filter-input">
              <input
                className="disabled-input rut-input"
                type="text"
                value={clienteRut}
              />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title">GIRO COMERCIAL</div>
            <div className="filter-input giro-input">
              <input className="disabled-input" type="text" value={clienteGiro} />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title">TARJETA</div>
            <div className="filter-input tarjeta-input">
              <input
                className="disabled-input"
                type="text"
                value={clienteTarjeta}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="separator">
        <div className="text">DATOS DE VENTA</div>
        <div className="line"></div>
      </div>
      <div className="datos-venta">
        <div className="filters-container first-row">
          <div className="filter">
            <div className="filter-title">N° DE GUÍA</div>
            <div className="filter-input">
              <input
                className="disabled-input numero-guia-input"
                type="text"
                value={ventaNumeroGuia}
              />
            </div>
          </div>
          <div className="filter marg-32">
            <div className="filter-title">FECHA DE VENTA</div>
            <div className="filter-input">
              <ReactDatePicker
                locale="es"
                selected={fechaUsuario}
                onChange={(e) => setFechaUsuario(e)}
                shouldCloseOnSelect={true}
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                placeholderText="Seleccionar"
              /* disabled={step !== 1} */
              />
            </div>
          </div>
          <div className="filter marg-64">
            <div className="filter-title">HORA DE VENTA</div>
            <div className="filter-input time-input">
              <TimePickerLibrary
                initialHour={hour}
                onSelect={(e) => setHour(e)}
              />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title">CÓDIGO DE AUTORIZACIÓN</div>
            <div className="filter-input">
              <input className="disabled-input codigo-input" type="text" value={ventaCodigoAutorizacion} />
            </div>
          </div>
          {/* <div className="filter">
            <div className="filter-title">ORDEN DE COMPRA</div>
            <div className="filter-input">
              <input className="disabled-input orden-input" type="text" value={"0"} />
            </div>
          </div> */}
        </div>
        <div className="filters-container second-row">
          
          <div className="filter">
            <div className="filter-title">RESPONSABLE G.M.</div>
            <div className="filter-input">
              <input className="disabled-input responsable-input" type="text" value={ventaResponsableGuia} />
            </div>
          </div>
          {
            ordenCompra &&
            <div className="filter">
              <div className="filter-title">ORDEN DE COMPRA</div>
              <div className="filter-input">
                <input className="disabled-input responsable-input" type="text" value={ordenCompra} />
              </div>
            </div>
          }
          {
            montoOrdenCompra &&
            <div className="filter">
              <div className="filter-title">MONTO</div>
              <div className="filter-input">
                <input className="disabled-input responsable-input" type="text" value={montoOrdenCompra} />
              </div>
            </div>
          }
          {/* {
          productoTable === "Diesel" && selectedProduct.value === "TAE" &&
            <div className="filter">
              <div className="filter-title">
                TIPO DE ENTREGA
              </div>
              <div className="filter-input">
                <Select
                  className="tipo-de-entrega-selector"
                  value={selectedTipoEntrega}
                  onChange={(e) => changeTipoEntrega(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allTiposEntrega}
                  components={{
                    SingleOption,
                    IndicatorSeparator: () => null,
                  }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesTctTae}
                  width="416px"
                />
              </div>
            </div>
          } */}
        </div>
      </div>
      <div className="table-prueba-container">
        <table >
          <thead>
            <tr>
              <th>Código</th>
              <th>Producto</th>
              <th>Litros</th>
              <th>Precio</th>
              <th>Monto</th>
            </tr>
          </thead>
          <tr className="separator-table-row">
            <th colSpan="100%" />
          </tr>
          <tbody>
            <tr>
              <td>
                  {codigoSubProducto}
              </td>
              <td>
                <div className="filters-container">
                  <div className="filter">
                    <div className="filter-input">
                      <input type="text" className='disabled-input w247' placeholder={productoTable}/>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="filters-container">
                  <div className="filter">
                    <div className="filter-input">
                      <input 
                        type="text" 
                        name="litros-table" 
                        onChange={e=>setUnidadesTable(e.target.value)}
                        onBlur={e=>getMontoLitros(e, false, montoMaximo)}
                        onKeyDown={e=>handlePressOnKey(e)}
                        value={unidadesTable}
                        className='enabled-input w112'
                      />
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="filters-container">
                  <div className="filter">
                    <div className="filter-input">
                      <input 
                        type="text" 
                        className='disabled-input w112' 
                        placeholder={precioTable}
                      />
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="filters-container">
                  <div className="filter">
                    <div className="filter-input">
                      <input 
                        type="text" 
                        name="monto-table"
                        onChange={e=>setMontoTable(e.target.value)}
                        value={montoTable}
                        onKeyDown={e=>handlePressOnKey(e)}
                        onBlur={e=>getMontoLitros(e, false, montoMaximo)}
                        // className='enabled-input w112 monto-error'
                        className={`enabled-input w112 ${montoError?"monto-error":""}`}
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          {
            flete > 0 &&
            <>
              <tr className="separator-table-row">
                <th colSpan="100%" />
              </tr>
              <tbody>
                <tr>
                  <td colSpan={2} className="despacho">
                      Cargo por despacho extraordinario
                  </td>
                  <td></td>
                  <td></td>
                  <td>{flete}</td>
                </tr>
              </tbody> 
            </>
          }
          <tr className="separator-table-row">
            <th colSpan="100%" />
          </tr>
          <tbody>
            <tr>
              <td>
                <div className="content w105">
                  Total Guía Manual
                </div>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td>{montoTable + flete}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {
        montoError &&
          <div className="important-message error">
            <span className="error-icon">!</span>
            <span className="text">Monto solicitado excede al máximo permitido para la tarjeta</span>
          </div>
      }
      {/* <div className="separator"> */}
      <div className={`separator ${errorExist?"separator-error":""}`}>
        <div className="line"></div>
      </div>
      {
        errorExist &&
          <div className="important-message error">
            <span className="error-icon">!</span>
            <span className="text">Error: {mensajeError}</span>
          </div>
      }
      <div className="button-container">
        <button className='main-button thin main-button-red hallow' onClick={openWarningModal}>Eliminar Autorización</button>
        <button disabled={errorExist || montoError} className='main-button thin main-button-red' onClick={confirmarIngreso}>Confirmar Ingreso</button>
      </div>

      <CustomModal
        displayModal={displayWarningModal}
        closeModal={closeWarningModal}
        acceptFunc={openDeleteModal}
        cancelFunc={closeWarningModal}
        acceptText="Aceptar"
        modalTitle="¡Importante!"
        modalText="¿Seguro desea eliminar la Autorización?"
        modalIcon="error"
      />

      <CustomModal
        displayModal={displayDeleteModal}
        closeModal={closeDeleteModal}
        acceptFunc={closeDeleteModal}
        acceptText="Aceptar"
        modalTitle="Éxito"
        modalText="Guía Manual eliminada."
        modalIcon="success"
      />

      <CustomModal
        displayModal={displayConfirmModal}
        closeModal={closeConfirmModal}
        acceptFunc={closeConfirmModal}
        acceptText="Aceptar"
        modalTitle="Éxito"
        modalText="La Guía Manual ha sido confirmada exitosamente"
        modalIcon="success"
      />

    </div>
  );
};

export default ConfirmarGuiasManualesDetalle;
