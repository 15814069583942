import React, { useEffect, useState } from "react";
import onClickOutside from "react-onclickoutside";
import {
  getLocaleStringNumber,
} from "../utils/utils";

const RecalculoTooltip = (props) => {
  const detalle = props.detalleR;
  const reposicion = props.reposicion;
  const cons = props.cons;
  const totalAPagar = props.totalAPagar;
  const precioPorLitro = props.precioPorLitro;

  RecalculoTooltip["handleClickOutside_" + props.name] = () => {
    clickOutside();
  };

  const clickOutside = () => {
    props.setDocumentoRKey("");
  };

  const getLitrosProductoR = (producto) => {
    let litros = 0;
    reposicion.forEach((forma) => {
      if (forma[producto] > 0) {
        litros += forma[producto];
      }
    });
    return litros;
  };

  const getLitrosProductoC = (producto) => {
    let litros = 0;
    cons.forEach((forma) => {
      if (forma[producto] > 0) {
        litros += forma[producto];
      }
    });
    return litros;
  };

  return (
    <div className={`documento-info recalculo${props.hidden ? " hidden" : ""}`}>
      <div className="title">DETALLE REPOSICIONES</div>
      <div className="content">
        <table>
          <tr className="headers">
            <th>Forma&nbsp;Comercialización</th>
            <th className="align-right">93</th>
            <th className="align-right">95</th>
            <th className="align-right">97</th>
            <th className="align-right">D</th>
            <th className="align-right">K</th>
            <th className="align-right">Total</th>
          </tr>
          {reposicion.map((forma, key) =>
            forma.total > 0 ? (
              <tr key={key} className="data">
                <td className="align-left">{forma.name}</td>
                <td className="align-right">
                  {getLocaleStringNumber(forma.g93)}
                </td>
                <td className="align-right">
                  {getLocaleStringNumber(forma.g95)}
                </td>
                <td className="align-right">
                  {getLocaleStringNumber(forma.g97)}
                </td>
                <td className="align-right">
                  {getLocaleStringNumber(forma.pd)}
                </td>
                <td className="align-right">
                  {getLocaleStringNumber(forma.k)}
                </td>
                <td className="align-right">
                  {getLocaleStringNumber(forma.total)}
                </td>
              </tr>
            ) : (
              <></>
            )
          )}
          <tr className="separator-table-row">
            <th colSpan="7" />
          </tr>
          <tr className="data subtotal">
              <td className="align-left">
                <b>Volumen&nbsp;productos&nbsp;Copec&nbsp;(Lts)</b>
              </td>
              <td className="align-right">{getLocaleStringNumber(getLitrosProductoR("g93"))}</td>
              <td className="align-right">{getLocaleStringNumber(getLitrosProductoR("g95"))}</td>
              <td className="align-right">{getLocaleStringNumber(getLitrosProductoR("g97"))}</td>
              <td className="align-right">{getLocaleStringNumber(getLitrosProductoR("pd"))}</td>
              <td className="align-right">{getLocaleStringNumber(getLitrosProductoR("k"))}</td>
              <td className="align-right">{getLocaleStringNumber(getLitrosProductoR("total"))}</td>
            </tr>
            <tr className="separator-table-row">
            <th colSpan="7" />
          </tr>
            {cons.map((forma, key) =>
              forma.total > 0 ? (
                <tr key={key} className="data">
                  <td className="align-left">{forma.name}</td>
                  <td className="align-right">{getLocaleStringNumber(forma.g93)}</td>
                  <td className="align-right">{getLocaleStringNumber(forma.g95)}</td>
                  <td className="align-right">{getLocaleStringNumber(forma.g97)}</td>
                  <td className="align-right">{getLocaleStringNumber(forma.pd)}</td>
                  <td className="align-right">{getLocaleStringNumber(forma.k)}</td>
                  <td className="align-right">{getLocaleStringNumber(forma.total)}</td>
                </tr>
              ) : (
                <></>
              )
            )}
            <tr className="separator-table-row">
            <th colSpan="7" />
          </tr>
          <tr className="data subtotal">
              <td className="align-left">
                <b>Total&nbsp;volumen&nbsp;entregado&nbsp;(Lts)</b>
              </td>
              <td className="align-right">
                {getLocaleStringNumber(getLitrosProductoR("g93") + getLitrosProductoC("g93"))}
              </td>
              <td className="align-right">
                {getLocaleStringNumber(getLitrosProductoR("g95") + getLitrosProductoC("g95"))}
              </td>
              <td className="align-right">
                {getLocaleStringNumber(getLitrosProductoR("g97") + getLitrosProductoC("g97"))}
              </td>
              <td className="align-right">
                {getLocaleStringNumber(getLitrosProductoR("pd") + getLitrosProductoC("pd"))}
              </td>
              <td className="align-right">
                {getLocaleStringNumber(getLitrosProductoR("k") + getLitrosProductoC("k"))}
              </td>
              <td className="align-right">
                {getLocaleStringNumber(getLitrosProductoR("total") + getLitrosProductoC("total"))}
              </td>
            </tr>
            <tr className="separator-table-row">
            <th colSpan="7" />
          </tr>
            <tr className="data">
              <td className="align-left">Precio&nbsp;por&nbsp;litro</td>
              <td className="align-right">{precioPorLitro["g93"]==="-"?"-":`$ ${getLocaleStringNumber(parseInt(precioPorLitro["g93"]))}`}</td>
              <td className="align-right">{precioPorLitro["g95"]==="-"?"-":`$ ${getLocaleStringNumber(parseInt(precioPorLitro["g95"]))}`}</td>
              <td className="align-right">{precioPorLitro["g97"]==="-"?"-":`$ ${getLocaleStringNumber(parseInt(precioPorLitro["g97"]))}`}</td>
              <td className="align-right">{precioPorLitro["pd"]==="-"?"-":`$ ${getLocaleStringNumber(parseInt(precioPorLitro["pd"]))}`}</td>
              <td className="align-right">{precioPorLitro["k"]==="-"?"-":`$ ${getLocaleStringNumber(parseInt(precioPorLitro["k"]))}`}</td>
              <td className="align-right"></td>
            </tr>
            <tr className="separator-table-row">
            <th colSpan="7" />
          </tr>
            <tr className="data subtotal">
              <td className="align-left"><b>Total&nbsp;a&nbsp;pagar</b></td>
              <td className="align-right"> {`$ ${getLocaleStringNumber(parseInt(totalAPagar["g93"]))}`} </td>
              <td className="align-right">{`$ ${getLocaleStringNumber(parseInt(totalAPagar["g95"]))}`}</td>
              <td className="align-right">{`$ ${getLocaleStringNumber(parseInt(totalAPagar["g97"]))}`}</td>
              <td className="align-right">{`$ ${getLocaleStringNumber(parseInt(totalAPagar["pd"]))}`}</td>
              <td className="align-right">{`$ ${getLocaleStringNumber(parseInt(totalAPagar["k"]))}`}</td>
              <td className="align-right"><b>{`$ ${getLocaleStringNumber(parseInt(totalAPagar["total"]))}`}</b></td>
            </tr>
          
        </table>
      </div>
      <div className="footer">
        {/* <span className="important">*</span>
        <span>Observaciones: aquí pueden ir las observaciones</span> */}
      </div>
    </div>
  );
};

RecalculoTooltip.prototype = {};

const clickOutsideConfig = {
  handleClickOutside: ({ props }) =>
    RecalculoTooltip["handleClickOutside_" + props.name],
};

export default onClickOutside(RecalculoTooltip, clickOutsideConfig);
