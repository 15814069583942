import carrotDown from "../../assets/img/caret-down-blue.svg";

export const MobileFiltersChoice = ({ itemName, value, onClick, disabled }) => (
  <div className={`mobile-filters__choice${value ? " selected" : ""}`}>
    <p className="mobile-filters__choice-name">{itemName}</p>
    <button
      className="mobile-filters__choice-trigger"
      onClick={onClick}
      disabled={disabled}
    >
      <span>{value || "Seleccionar"}</span>
      {!disabled && <img src={carrotDown} alt="" />}
    </button>
  </div>
);
