// REACT
import React, { useState, useEffect } from "react"
import Select from "react-select";

// COMPONENTES
import HeaderData from "../../HeaderData"
import SolicitudAutorizacionGuiaManual from "./SolicitudAutorizacionGuiaManual";
import DetalleTarjeta from "./DetalleTarjeta";
import DatosDepartamentoModal from "./DatosDepartamentoModal";
import CustomHighlighter from "../../CustomHighlighter";

// UTILS
import { getUpdateDate, validarRut } from "../../utils/monthNames"
import { getPaginationItems, setInstalalaciones } from "../../utils/get"
import { SingleOption, customStylesTctTae, orderArray } from "../../utils/utils.js";
import { hideGreyBackground, showGreyBackground } from "../../utils/windows";

// ASSETS
import Flecha_up from "../../../assets/img/Flecha_up.svg";
import Flecha_down from "../../../assets/img/Flecha_down.svg";
import Candado from "../../../assets/img/Candado.svg";
import OverflowedText from "../../OverflowedText";
import Pagination from "react-js-pagination";
import CustomModal from "../../CustomModal";

const AutorizarConsumo = ({token, rutConcesionario, callServiceHandler, cabecera, instalaciones,isROImpersonate}) => {

  const [updateDate, setUpdateDate] = useState('')
  const [selectedEds, setSelectedEds] = useState()
  const [allEds, setAllEds] = useState([]);
  const [rutCliente, setRutCliente] = useState();
  const [codigoVerificador, setCodigoVerificador] = useState();
  const [selected_TCT_TAE_Product, setSelected_TCT_TAE_Product] = useState();
  const [isRutGiven, setIsRutGiven] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [displayMainTable, setDisplayMainTable] = useState(false);
  const [displayConsultTable, setDisplayConsultTable] = useState(false);
  const [displayConsultarConsumo, setDisplayConsultarConsumo] = useState(true);
  const [displaySolicitudAutorizacion, setDisplaySolicitudAutorizacion] = useState(false);
  const [displayDetalleTarjeta, setDisplayDetalleTarjeta] = useState(false);
  const [displayDatosDepartamentoModal, setDisplayDatosDepartamentoModal] = useState(false);
  const [distanceToTop, setdistanceToTop] = useState(410);
  const [productoTarjetaCode, setProductoTarjetaCode] = useState();
  const [productoTarjeta, setProductoTarjeta] = useState();

  const [mainTableData, setMainTableData] = useState();
  const [consultTableData, setConsultTableData] = useState();

  const [displayRUTErroneoMessage, setDisplayRUTErroneoMessage] = useState(false);
  const [displayUsuarioNoEncontradoMessage, setDisplayUsuarioNoEncontradoMessage] = useState(false);

  const [cliente, setCliente] = useState("");
  const [clientes, setClientes] = useState();

  const [rutVerificado, setRutVerificado] = useState();

  const [filteredData, setFilteredData] = useState();

  const [tarjetas, setTarjetas] = useState();

  const [numeroTarjeta, setNumeroTarjeta] = useState();

  const [nombreRut, setNombreRut] = useState();

  const [codigoCliente, setCodigoCliente] = useState();

  const [maxGuia, setMaxGuia] = useState();

  const [tarjetasExist, setTarjetasExist] = useState(false);

  const [activePage, setActivePage] = useState(1)
  const [paginatedData, setPaginatedData] = useState([])

  const [allTCT_TAE_Products, setAllTCT_TAE_Products] = useState([]);

  const [departamento, setDepartamento] = useState();

  const [asignacionTarjeta, setAsignacionTarjeta] = useState();

  const [fechaVencimiento, setFechaVencimiento] = useState();

  const [displayDatosFaltantes, setDisplayDatosFaltantes] = useState(false);

  const [errorOrdenCompra, setErrorOrdenCompra] = useState(false);

  const [ordenesCompra, setOrdenesCompra] = useState();

  const [displayErrorOrdenCompraModal, setDisplayErrorOrdenCompraModal] = useState(false);

  useEffect(() => {
    setActivePage(1)
    if(filteredData?.length <= 15)
    setPaginatedData(filteredData)
    else
    setPaginatedData(filteredData?.slice(0, 15))
    setActivePage(1)
    console.log("fd",filteredData)
  }, [filteredData])

  function handlePageChange(pageNumber){
    getPaginationItems(tarjetas, pageNumber, setPaginatedData, setActivePage);
  }


  useEffect(() => {
      filterr();
      console.log("text filtr", filterText)
  }, [filterText]);

useEffect(() => {
  if (tarjetas) {
    console.log("tarjjjj", tarjetas)
  }
}, [tarjetas]);

const filterr = () => {
  let dataArr
  if ((filterText !== "")) {
    let filterTextLower = filterText.toLowerCase();
    filterTextLower = filterTextLower.replaceAll(".", "");
    dataArr = tarjetas.filter(data => {
      return (
        // selected_TCT_TAE_Product?.value === "TAE"?data.asignacion_tarjeta.toLowerCase().includes(filterTextLower):data.patente.toLowerCase().includes(filterTextLower)||
        data.patente_asignacion.toLowerCase().includes(filterTextLower) ||
        data.numero_tarjeta.toLowerCase().includes(filterTextLower) ||
        data.departamento.toLowerCase().includes(filterTextLower) ||
        data.producto.toLowerCase().includes(filterTextLower) ||
        // data.tipo_producto.toLowerCase().includes(filterTextLower) ||
        data.fecha_vencimiento.toLowerCase().includes(filterTextLower) ||
        data.max_guia.toLowerCase().includes(filterTextLower)
      )
    })
    console.log("algo se escribe")
    setFilteredData(dataArr)
  } else {
    console.log("nada se escribe")
    setFilteredData(tarjetas)
  }

}

  useEffect(() => {
    getUpdateDate(setUpdateDate)
  }, [])

  useEffect(() => {
    if (selected_TCT_TAE_Product?.value === "TAE") {
      if (codigoCliente && selectedEds && rutCliente && token) {
        getOrdenCompra()
      }
    }
  }, [selected_TCT_TAE_Product, codigoCliente, token]);

  useEffect(() => {
    if (instalaciones !== "") {
      setInstalalaciones(instalaciones, setAllEds);
    }
  }, [instalaciones]);

  useEffect(() => {
    if (allEds.length === 1){
      setSelectedEds(allEds[0]);
    }
  }, [allEds]);

  useEffect(() => {
    if (mainTableData) {
      console.log("main datita",mainTableData)
    }
  }, [mainTableData]);

  useEffect(() => {
    if (selectedEds && rutCliente?.length >= 6 && codigoVerificador?.length === 1 && rutVerificado && cliente.length > 0 && token) {
      setIsRutGiven(true)
    }
  }, [selectedEds, rutCliente, rutVerificado, cliente, token]);

  useEffect(() => {
    if (rutVerificado === true) {
      setDisplayRUTErroneoMessage(false)
      setTarjetasExist(false)
    }else if(rutVerificado === false){
      setDisplayRUTErroneoMessage(true)
      setIsRutGiven(false)
      setDisplayConsultTable(false)
      setDisplayMainTable(false)
      setTarjetasExist(false)
    }
  }, [rutVerificado]);

  useEffect(() => {
    if (clientes) {
      if (clientes.length > 1) {
        setMainTableData(clientes)
        setDisplayMainTable(true)
        setDisplayConsultTable(false)
        setTarjetasExist(false)
      }else if (clientes.length === 1){
        getTarjetas(clientes[0]?.codigo_cliente, selected_TCT_TAE_Product?.value)
        setCodigoCliente(clientes[0]?.codigo_cliente)
        setDisplayMainTable(false)
        setDisplayConsultTable(false)
        setDisplayConsultTable(true)
      }else{
        setDisplayMainTable(true)
      } 
    }
  }, [selected_TCT_TAE_Product]);

  useEffect(() => {
    if (filteredData) {
      console.log("la filtrada es :", filteredData)
    }
  }, [filteredData]);
  

  function changeEds(e) {
    if (e !== selectedEds) {
      setSelectedEds(e);
    }
  }

  function change_TCT_TAE_Product(e) {
    if (e !== selected_TCT_TAE_Product) {
      console.log(e)
      setSelected_TCT_TAE_Product(e);
      // getClientes(rutCliente, e.value)
      setFilteredData([])
    }
  }

  const showConsultTable = (codigo) => {
    getTarjetas(codigo, selected_TCT_TAE_Product?.value)
    setCodigoCliente(codigo)
    setDisplayMainTable(false)
    setDisplayConsultTable(true)
    setTarjetasExist(false)
  }

  const showSolicitudAutorizacionConsumo = (producto_tarjeta_code, numero_tarjeta, producto_tarjeta, max_guia) =>{
    setProductoTarjetaCode(producto_tarjeta_code)
    setNumeroTarjeta(numero_tarjeta)
    setProductoTarjeta(producto_tarjeta)
    setMaxGuia(max_guia)
    setDisplayConsultarConsumo(false)
    setDisplaySolicitudAutorizacion(true)
  }
  
  const showDetalleTarjeta = (numero_tarjeta, departamento, producto, asignacion_tarjeta, fecha_vencimiento, max_guia) =>{
    setDisplayConsultarConsumo(false)
    setDisplayDetalleTarjeta(true)
    setNumeroTarjeta(numero_tarjeta)
    setDepartamento(departamento)
    setProductoTarjeta(producto)
    setAsignacionTarjeta(asignacion_tarjeta)
    setFechaVencimiento(fecha_vencimiento)
    setMaxGuia(max_guia)
  }

  function showDatosDepartamentoModal() {
    showGreyBackground();
    setDisplayDatosDepartamentoModal(true);
  }

  function closeDatosDepartamentoModal() {
    setDisplayDatosDepartamentoModal(false);
    hideGreyBackground();
  }

  const orderTable = (filterkey, order, parse) => {
    const arr = orderArray(filterkey, filteredData, order, parse);
    setFilteredData(arr);
  };

  const getCliente = async (rut) => {

    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-cliente?RUT=" + rut;

    let response = await callServiceHandler(url, "GET");

    console.log("1 cliente", response.data)

    // if(response.data.cliente.length>0){
    //   setDisplayUsuarioNoEncontradoMessage(false)
    //   // setCliente(response.data.cliente)
    // }else{
    //   setDisplayUsuarioNoEncontradoMessage(true)
    // }
  }

  const getOrdenCompra = async () => {

    let eds = parseInt(selectedEds.value).toString();


    const url =
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-ordenes-compra?RUTCLIENTE="+rutCliente
    +"&CODCLIENTE="+codigoCliente
    +"&IDEDS="+eds

    let response = await callServiceHandler(url, "GET");

    console.log("ORDEN DE COMPRA", response.data)
    console.log("CLIENTE FISCAL", response.data.cliente_fiscal)
    console.log("ORDENES FISCAL", response.data.ordenes)

    
    if (response.data.cliente_fiscal === "X") {
      // setErrorOrdenCompra(false)
      setDisplayErrorOrdenCompraModal(false)

      if (response.data.ordenes.length >= 1) {
        let newOrdenes =  response.data.ordenes.map(orden => {
          return ({
            value: orden.numero_orden, label: orden.numero_orden, monto: orden.monto
          })
        })
        console.log("LAS NUEVAS ORDENES",newOrdenes)
        setOrdenesCompra(newOrdenes)
      }else{
        // setErrorOrdenCompra(true)
        setDisplayErrorOrdenCompraModal(true)
        setDisplayConsultTable(false)
        if (clientes.length > 1) {
          setDisplayMainTable(true)
        }
      }
    }else{
      // setErrorOrdenCompra(false)
      setDisplayErrorOrdenCompraModal(false)
    }
  }

  const getTarjetas = async (codigo, tct_tae_producto) => {
    codigo = codigo.split("-")[0]

    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-tarjetas-cliente?CODCLIENTE=" + codigo +
    "&PRODUCTO=" +tct_tae_producto;

    let response = await callServiceHandler(url, "GET");

    console.log("tarjetas", response.data)

    if (response.data.tarjetas.length > 0) {
      setFilteredData(response.data.tarjetas)
      setTarjetas(response.data.tarjetas)
    }else{
      setFilteredData(response.data.tarjetas)
      setTarjetas(response.data.tarjetas)
      setTarjetasExist(true)
    }

  }

  const getClientes = async (rutCliente) => {
    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-clientes-autorizacion?RUT=" + rutCliente;

    let response = await callServiceHandler(url, "GET");

    console.log("clientes", response.data)

    let productosTCT_TAE = response.data.tipos_de_producto.map(prod=>{
      return({
        value: prod,
        label: prod
      })
    })

    console.log("transfor", productosTCT_TAE)

    if(response.data.nombre_rut.length>0){
      setDisplayUsuarioNoEncontradoMessage(false)
      setCliente(response.data.nombre_rut)
      setClientes(response.data.clientes)
      setAllTCT_TAE_Products(productosTCT_TAE)
    }else{
      setDisplayUsuarioNoEncontradoMessage(true)
    }
  }

  useEffect(() => {
    if (allTCT_TAE_Products) {
      if (allTCT_TAE_Products.length < 2) {
        setSelected_TCT_TAE_Product(allTCT_TAE_Products[0])
      }
    }
  }, [allTCT_TAE_Products]);

  useEffect(() => {
    if (token) {
      getRegiones()
    }
  }, [token]);

  const getRegiones = async () => {
    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-regiones"

    let response = await callServiceHandler(url, "GET");

    console.log("regiones", response.data.regiones)
    let regiones = response.data.regiones.map(region=>{
      return({value:region.cod_region,label:region.region})
    })
    console.log("new regiones", regiones)
  }


  const handleOnKeyDown = (e) =>{
    console.log(typeof e.key)
    if(e.key === "Enter"){
      console.log("se hizo enter")
      setDisplayMainTable(false)
      setDisplayConsultTable(false)
      setTarjetasExist(false)
      setSelected_TCT_TAE_Product()
      // setCliente()
      setClientes([])
      console.log("rutcliente enter", rutCliente)
      console.log("codigoVerificador enter", codigoVerificador)
      // setTarjetas()
      if (rutCliente && codigoVerificador) {
        setDisplayDatosFaltantes(false)
        setRutVerificado(validarRut(rutCliente, codigoVerificador))
      }else{
        console.log("faltan datos")
        setDisplayDatosFaltantes(true)
      }
      // getCliente(rutCliente)
      // getClientes(rutCliente)
    }
  }
  
  const handleOnBlur = (e) =>{
    // console.log(e.key)
    setDisplayMainTable(false)
    setDisplayConsultTable(false)
    setTarjetasExist(false)
    setSelected_TCT_TAE_Product()
    // setCliente()
    setClientes()
    // setTarjetas()
    if (rutCliente && codigoVerificador) {
        setDisplayDatosFaltantes(false)
        setRutVerificado(validarRut(rutCliente, codigoVerificador))
    }else{
      console.log("faltan datos")
      setDisplayDatosFaltantes(true)

    }
    // getCliente(rutCliente)
    // getClientes(rutCliente)
  }

  useEffect(() => {
    if (rutVerificado) {
      getClientes(rutCliente)
    }
  }, [rutVerificado]);

  useEffect(() => {
    if (cabecera) {
      console.log("cabecera", cabecera)
      setNombreRut(cabecera.DATOS_CLIENTE.NOMBRE)      
    }
  }, [cabecera]);

  function transformarAnioMes(fecha) {
    const mes = fecha.slice(2, 4);
    const año = fecha.slice(0, 2);
    return mes + '/' + año;
  }

  const closeErrorOrdenCompraModal = () => {
    if (clientes.length > 1) {
      setDisplayErrorOrdenCompraModal(false)
      setDisplayConsultTable(false)
    }else{
      setRutCliente("")
      setCodigoVerificador("")
      // setSelected_TCT_TAE_Product(null)
      setDisplayConsultTable(false)
      setDisplayErrorOrdenCompraModal(false)
      setIsRutGiven(false)
    }
  }

  
  // error cuando no hay datos: El cliente no tiene tarjetas para el filtro de búsqueda ingresado

  return (
    <div className="consultar-autorizar-consumo-container">
      <div className="view-title">TCT TAE</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Autorización de consumos</div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandler={callServiceHandler}
        cabecera={cabecera}
        actualizable={true}
      />
      <hr />
      {
        displayConsultarConsumo &&
          <div className="consultar-consumo-container">
            <div className="filters-container main-filters-table-container">
              <div className="filter">
                <div className="filter-title required-field">
                  ESTACIÓN DE SERVICIO
                </div>
                <div className="filter-input eds-selector">
                  <Select
                    className="eds-select"
                    value={selectedEds}
                    onChange={(e) => changeEds(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allEds}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesTctTae}
                  />
                </div>
              </div>
              <div className="filter">
                <div className="filter-title required-field">
                  RUT CLIENTE
                </div>
                <div className="filter-input rut-cliente"> 
                  <input
                    type="number"
                    maxLength={8}
                    className="rut-cliente-codigo"
                    id="rut-cliente-codigo"
                    value={rutCliente}
                    onChange={(e) => setRutCliente(e.target.value)}
                  ></input>
                  <input
                    type="text"
                    maxLength={1}
                    className="rut-cliente-verificador"
                    id="rut-cliente-verificador"
                    value={codigoVerificador}
                    onChange={(e) => setCodigoVerificador(e.target.value)}
                    onBlur={e=>handleOnBlur(e)}
                    // onBlur={e=>setRutVerificado(validarRut(rutCliente, codigoVerificador))}
                    onKeyDown={e=>handleOnKeyDown(e)}
                  ></input>
                </div>
              </div>
              {
                isRutGiven &&
                <>
                  <div className='filter'>
                    <div>
                      <div className="filter-title">
                        NOMBRE CLIENTE
                      </div>
                      <div className='filter-input nombre-cliente-disabled-input'>
                        <input className='disabled-input' disabled={true} type="text" value={cliente} />
                      </div>
                    </div>
                  </div>
                  <div className="filter">
                    <div>
                      <div className="filter-title required-field">
                        FORMA COMER.
                      </div>
                      <div className="filter-input tct-tae-producto-select">
                        {
                          allTCT_TAE_Products &&
                            allTCT_TAE_Products.length > 1 
                            ?
                              <Select
                                className="tct-tae-producto-select"
                                value={selected_TCT_TAE_Product}
                                onChange={(e) => change_TCT_TAE_Product(e)}
                                closeMenuOnSelect={true}
                                hideSelectedOptions={false}
                                options={allTCT_TAE_Products}
                                components={{
                                  SingleOption,
                                  IndicatorSeparator: () => null,
                                }}
                                isClearable={false}
                                autosize={false}
                                placeholder="Seleccionar"
                                styles={customStylesTctTae}
                                width="100px"
                              />
                            :
                              selected_TCT_TAE_Product &&
                                <input className='disabled-input tct-tae-producto-select' disabled={true} type="text" value={selected_TCT_TAE_Product?.label} />
                        }
                      </div>
                    </div>
                  </div>
                </>
              }
              {
                displayConsultTable &&
                  <div className="filter">
                    <div>
                      <div className="filter-input">
                        <input
                          type="search"
                          className="search"
                          value={filterText}
                          placeholder="Buscar..."
                          onChange={(e) => setFilterText(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
              }
            </div>
            {
              displayRUTErroneoMessage &&
                <div className="important-message error">
                  <span className="error-icon">!</span>
                  <span className="text">Rut del cliente con digito verificador erroneo, ingrese un rut valido</span>
                </div>
            }
            {
              displayUsuarioNoEncontradoMessage &&
                <div className="important-message error">
                  <span className="error-icon">!</span>
                  <span className="text">Cliente no existe</span>
                </div>
            }
            {
              displayDatosFaltantes &&
                <div className="important-message error">
                  <span className="error-icon">!</span>
                  <span className="text">Debe ingresar rut y codigo verificador</span>
                </div>
            }
            {
              errorOrdenCompra &&
                <div className="important-message error">
                  <span className="error-icon">!</span>
                  <span className="text">El cliente no cuenta con Orden de compra</span>
                </div>
            }
            {
              displayMainTable && rutVerificado &&
                <div className="consultar-autorizar-consumo-container-main-table-container">
                  <table className="consultar-autorizar-consumo-container-main-table">
                    <tr>
                      <th>
                        <div className="th-data width-172">
                          <div className="th-text">
                            Código cliente
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="th-data width-172">
                          <div className="th-text">
                            Razón social
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="th-data width-172">
                          <div className="th-text">
                            Tipo Tarjeta
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="th-data width-172">
                          <div className="th-text">
                            Estado
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="th-data width-172">
                          <div className="th-text">
                            Tipo de Bloqueo
                          </div>
                        </div>
                      </th>
                      <th>
                        <div className="th-data width-172">
                          <div className="th-text two-lines">
                            Fecha
                            <br/>
                            Creación cliente
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr className="table-separator-row-border-top">
                      <td colSpan="100%" />
                    </tr>
                    <tbody>
                      {
                        clientes &&
                          clientes.length > 0
                          ?
                            clientes.map(data=>{
                              return(
                                <tr className="data">
                                  <td className="align-left">{data.codigo_cliente}</td>
                                  <td className="align-left table-link" onClick={e=>showConsultTable(data.codigo_cliente)}>{data.razon_social}</td>
                                  <td className="align-left">{selected_TCT_TAE_Product.value}</td>
                                  <td className="align-left table-link">{data.estado}</td>
                                  <td className="align-left">{data.motivo_bloqueo}</td>
                                  <td className="align-left">{data.fecha_creacion_cliente}</td>
                                </tr>
                              )
                            })
                          :
                            <>
                              <tr className="data">
                                <td colSpan={100}>No hay datos</td>
                              </tr>
                              <tr className="data">
                                <td colSpan={100}></td>
                              </tr>
                            </>
                      }
                    </tbody>
                  </table>
                </div>
            }
            {
              displayConsultTable &&
                <div className="consultar-autorizar-consumo-container-consult-table-container">
                  <table className="consultar-autorizar-consumo-container-consult-table">
                  <tr>
                    <th>
                      <div className="th-data width-100">
                        {
                          selected_TCT_TAE_Product?.value === "TAE" ?
                            <div className="th-text two-lines">
                              Asignación 
                              <br/>
                              Tarjeta
                            </div>
                          :
                            <div className="th-text">
                              Patente
                            </div>
                        }
                      </div>
                    </th>
                    <th>
                      <div className="th-data width-136">
                        <div className="th-text">N° de Tarjeta</div>
                      </div>
                    </th>
                    <th>
                      <div className="th-data width-327">
                        <div className="th-text">
                          Departamento
                          <div className="arrows">
                            <img
                              onClick={() => orderTable("departamento", "asc", false)}
                              src={Flecha_up}
                            ></img>
                            <img
                              onClick={() => orderTable("departamento", "desc", false)}
                              src={Flecha_down}
                            ></img>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="th-data width-100">
                        <div className="th-text">Producto</div>
                      </div>
                    </th>
                    <th>
                      <div className="th-data width-100">
                        <div className="th-text two-lines">
                          Tipo de 
                          <br/>
                          Producto
                          <div className="arrows mtop">
                            <img
                              onClick={() => orderTable("tipo_producto", "asc", false)}
                              src={Flecha_up}
                            ></img>
                            <img
                              onClick={() => orderTable("tipo_producto", "desc", false)}
                              src={Flecha_down}
                            ></img>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="th-data width-100">
                        <div className="th-text two-lines">
                          Fecha 
                          <br/>
                          Vencimiento
                          <div className="arrows mtop">
                            <img
                              onClick={() => orderTable("fecha_vencimiento", "asc", false)}
                              src={Flecha_up}
                            ></img>
                            <img
                              onClick={() => orderTable("fecha_vencimiento", "desc", false)}
                              src={Flecha_down}
                            ></img>
                          </div>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="th-data width-100">
                        <div className="th-text">
                          Max. Guía
                          <div className="arrows">
                            <img
                              onClick={() => orderTable("max_guia", "asc", false)}
                              src={Flecha_up}
                            ></img>
                            <img
                              onClick={() => orderTable("max_guia", "desc", false)}
                              src={Flecha_down}
                            ></img>
                          </div> 
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="th-data width-53">
                        <div className="th-text">
                          G.M.
                          <div className="arrows">
                            <img
                              onClick={() => orderTable("guia_manual", "asc", false)}
                              src={Flecha_up}
                            ></img>
                            <img
                              onClick={() => orderTable("guia_manual", "desc", false)}
                              src={Flecha_down}
                            ></img>
                          </div>
                        </div>
                      </div>
                    </th>
                  </tr>
                  <tr className="table-separator-row-border-top">
                    <td colSpan="100%" />
                  </tr>
                  <tbody>
                    {
                      paginatedData &&
                      !tarjetasExist 
                        ?
                          paginatedData.map(data => {
                            return(
                              <tr className="data">
                                <td className="align-left">
                                  <OverflowedText widthContainer={100}>
                                    <CustomHighlighter text={filterText}>
                                      {data.patente_asignacion}
                                    </CustomHighlighter>
                                  </OverflowedText>
                                </td>
                                <td className="align-left table-link" onClick={e=>showDetalleTarjeta(data.numero_tarjeta, data.departamento, data.producto, data.patente_asignacion, data.fecha_vencimiento, data.max_guia)}>
                                  <CustomHighlighter text={filterText}>
                                    {data.numero_tarjeta}
                                  </CustomHighlighter>
                                </td>
                                <td className="align-left table-link" onClick={showDatosDepartamentoModal}>
                                  <CustomHighlighter text={filterText}>
                                    {data.departamento}
                                  </CustomHighlighter>
                                </td>
                                <td className="align-left">
                                  <OverflowedText widthContainer={100}>
                                    <CustomHighlighter text={filterText}> 
                                            {data.producto}
                                    </CustomHighlighter>
                                  </OverflowedText>
                                </td>
                                <td className="align-left">
                                  {/* <CustomHighlighter text={filterText}> */}
                                    -
                                  {/* </CustomHighlighter> */}
                                </td>
                                <td className="align-left">
                                  <CustomHighlighter text={filterText}>
                                    {transformarAnioMes(data.fecha_vencimiento)}
                                  </CustomHighlighter>
                                </td>
                                <td className="align-left">
                                  {/* <CustomHighlighter text={filterText}> */}
                                    {data.max_guia < 999999999 ? data.max_guia : "Sin límite"}
                                  {/* </CustomHighlighter> */}
                                </td>
                                <td className="align-center table-link" onClick={e=>showSolicitudAutorizacionConsumo(data.producto_tarjeta_code, data.numero_tarjeta, data.producto, data.max_guia)}>{data.gm ? <img src={Candado} /> : ""}</td>
                              </tr>
                            )
                          })
                        :
                        <>
                          <tr className="data">
                            <td colSpan={100}>El cliente no tiene tarjetas para el filtro de búsqueda ingresado</td>
                          </tr>
                          <tr className="data">
                            <td colSpan={100}></td>
                          </tr>
                        </>
                    }
                  </tbody>
                  </table>
                  <div className="pagination-container">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={15}
                      totalItemsCount={filteredData?.length}
                      pageRangeDisplayed={5}
                      onChange={e => handlePageChange(e)}
                      hideFirstLastPages={true}
                      linkClassPrev='prev'
                      linkClassNext='next'
                      prevPageText=''
                      nextPageText=''
                    />
                  </div>
                </div>
            }
          </div>
      }
      {
        displaySolicitudAutorizacion &&
          <SolicitudAutorizacionGuiaManual 
            token={token} 
            selectedEds={selectedEds}
            setDisplayConsultarConsumo={setDisplayConsultarConsumo} 
            setDisplaySolicitudAutorizacion={setDisplaySolicitudAutorizacion} 
            selected_TCT_TAE_Product={selected_TCT_TAE_Product} 
            callServiceHandler={callServiceHandler} 
            productoTarjetaCode={productoTarjetaCode} 
            productoTarjeta={productoTarjeta}
            numeroTarjeta={numeroTarjeta} 
            rutConcesionario={rutConcesionario}
            cliente={cliente}
            maxGuia={maxGuia}
            nombreRut={nombreRut}
            rutCliente={rutCliente}
            codigoVerificador={codigoVerificador}
            codigoCliente={codigoCliente}
            ordenesCompra={ordenesCompra}
          />
      }
      {
        displayDetalleTarjeta &&
          <DetalleTarjeta 
            setDisplayConsultarConsumo={setDisplayConsultarConsumo} setDisplayDetalleTarjeta={setDisplayDetalleTarjeta}
            numeroTarjeta={numeroTarjeta}
            departamento={departamento}
            productoTarjeta={productoTarjeta}
            asignacionTarjeta={asignacionTarjeta}
            fechaVencimiento={fechaVencimiento}
            maxGuia={maxGuia}
          />
      }
      <DatosDepartamentoModal 
        displayDatosDepartamentoModal={displayDatosDepartamentoModal}
        closeDatosDepartamentoModal={closeDatosDepartamentoModal}
        distanceToTop={distanceToTop}
      />

      <CustomModal
        displayModal = { displayErrorOrdenCompraModal }
        closeModal = { closeErrorOrdenCompraModal }
        acceptFunc = { closeErrorOrdenCompraModal }
        acceptText= "Aceptar"
        modalTitle = "Error"
        modalText = {`
        El cliente no cuenta con Orden de compra 
        `}
        modalIcon = "error"
      />
    </div>
  );
}

export default AutorizarConsumo;