import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import {
  SingleOption,
  customStylesPedidos,
  findValidCamion,
  parseBudat,
  getLocaleStringNumber
} from "../utils/utils";
import { displayLoader, hideLoader } from "../utils/windows";
import { allMateriales } from "../utils/materiales";
import { MobileSelect } from "../MobileSelect";
import ResumenRechazoPedido from "./ResumenRechazoPedido.js";
import EstadoCrediticio from "./EstadoCrediticio";

const RechazarPedido = ({
  selectedPedido,
  selectedEds,
  setDisplayRechazarPedido,
  token,
  rutConcesionario,
  setCurrentPage,
  setPedidosAux,
  pagador,
  isROImpersonate,
  callServiceHandler
}) => {
  const [selectedRechazo, setSelectedRechazo] = useState();
  const [detalles, setDetalles] = useState([]);
  const [razonesRechazo, setRazonesRechazo] = useState([]);
  const [displayResumenRechazar, setDisplayResumenRechazar] = useState(false);
  const [clientName, setClientName ] = useState('');
  const [openMobileFilter, setOpenMobileFilter] = useState(false);

  const [ camion, setCamion ] = useState();
  const [ camiones, setCamiones ] = useState([])
  const [ defaultCamion, setDefaultCamion ] = useState();
  const [ compartimientos, setCompartimientos ] = useState([]);
  const [ compartimientosPedido, setCompartimientosPedido ] = useState();
  const [ materiales, setMateriales ] = useState([]);
  const [ planta, setPlanta ] = useState()
  const [ plantas, setPlantas ] = useState([])
  const isDesktopRef = useRef(window.innerWidth > process.env.REACT_APP_MOBILE_BREAK_POINT)

  useEffect(() => {
    if (selectedPedido) {
      const arr93 = [];
      const arr95 = [];
      const arr97 = [];
      const arrPd = [];
      const arrK = [];

      if (selectedPedido.DETALLE) {
        const detalle = selectedPedido.DETALLE;
        detalle.forEach((item) => {
          if (allMateriales[item.ID_MATERIAL].class === "sp93") {
            arr93.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "sp95") {
            arr95.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "sp97") {
            arr97.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "pd") {
            arrPd.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "k") {
            arrK.push(item);
          }
        });
      }

      const detalleAux = [];
      if (getLitros(arr97) > 0) {
        detalleAux.push({
          nombre: "Gasolina SP 97 Octanos Premium NU 1203",
          litros: getLitros(arr97),
        });
      }
      if (getLitros(arr95) > 0) {
        detalleAux.push({
          nombre: "Gasolina SP 95 Octanos NU 1203",
          litros: getLitros(arr95),
        });
      }
      if (getLitros(arr93) > 0) {
        detalleAux.push({
          nombre: "Gasolina SP 93 Octanos NU 1203",
          litros: getLitros(arr93),
        });
      }
      if (getLitros(arrPd) > 0) {
        detalleAux.push({
          nombre: "Petroleo Diesel Ultra A1 NU 1202",
          litros: getLitros(arrPd),
        });
      }
      if (getLitros(arrK) > 0) {
        detalleAux.push({ nombre: "Kerosene", litros: getLitros(arrK) });
      }
      setDetalles(detalleAux);
    }
  }, [selectedPedido]);

  useEffect(() => {
    if (selectedPedido){
      displayLoader();
      obtenerRazones();
      obtenerCompartimientos();
      getCabecera();
    }
    
  }, [selectedPedido]);

  useEffect(() => {
    if(pagador)
      getPlantas()
  }, [pagador])

  useEffect(() => {
    if(plantas.length > 0){
      const found = plantas.find((p) => p.ID_EDS === selectedEds.value)
      setPlanta(found)
    }
  }, [plantas])

  useEffect(() => {
    if(planta){
      getTipoCamion();
      getMateriales();    
    }
  }, [planta])

  useEffect(() => {
    if(compartimientosPedido && camiones.length > 0 && defaultCamion && materiales.length > 0){
      findValidCamion(defaultCamion, camiones, compartimientosPedido, materiales, setCamion, setCompartimientos);
    }
  }, [compartimientosPedido, camiones, defaultCamion, materiales])

  async function getTipoCamion(){
    displayLoader()
    const url = process.env.REACT_APP_PEDIDOS_API + 
      "/pedidos/tipo-camion?id_planta=" + planta.CODIGO_PLANTA + "&id_eds=" + selectedEds.value

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      if(result.data.ESTADO === 0){
        setCamiones(result.data.CAMIONES)
        const initialCamion = result.data.CAMIONES.find(e => e.ID === result.data.CAMION)
        setDefaultCamion(initialCamion)
      }
    }
  }

  async function getMateriales(){
    const url = process.env.REACT_APP_PEDIDOS_API + 
      "/pedidos/listar-materiales?id_eds=" + selectedEds.value

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      let newMateriales = result.data.MATERIALES.filter((mat) => allMateriales[mat.ID_MATERIAL])
      newMateriales.push({'NOMBRE': 'Vacío', 'ID_MATERIAL': 'empty'})
      setMateriales(newMateriales)
    }
  }

  async function getPlantas(){
    const url = process.env.REACT_APP_PEDIDOS_API + 
      "/pedidos/obtener-instalaciones-cliente?id_pagador=" + pagador

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      setPlantas(result.data['INSTALACIONES'])
    }
  }

  async function getCabecera(token) {
    const url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/datos-cabecera?rut_concesionario=" +
      rutConcesionario;

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      const data = result.data;
      setClientName(data.DATOS_CLIENTE.NOMBRE);
    }
  }

  async function obtenerRazones() {
    let url =
      process.env.REACT_APP_PEDIDOS_API + "/pedidos/obtener-motivos-rechazo"; 

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      const razones = result.data.MOTIVOS;
      const selectRazones = [];
      razones.map((motivo) =>
        selectRazones.push({
          label: motivo.ID_MOTIVO + " - " + motivo.MOTIVO,
          value: motivo.ID_MOTIVO,
        })
      );
      setRazonesRechazo(selectRazones);
    }
  }

  function obtenerCompartimientos (){
    
    const comps = selectedPedido.DETALLE.filter(obj => {
      return obj.CATEGORIA_ITEM === 'ZCRM'
    })
    setCompartimientosPedido(comps);
    console.log(comps);
  
  }

  const getLitros = (array) => {
    let litros = 0;
    array.forEach((pedido) => {
      if (pedido.CATEGORIA_ITEM !== "ZCRM") {
        litros += pedido.CANTIDAD_SOLICITADA;
      }
    });
    return litros;
  };

  const displayResumen = () => {
    if(selectedRechazo){
      setDisplayResumenRechazar(true)
    }
  }

  return (
    <>
      {displayResumenRechazar && (
        <ResumenRechazoPedido
          selectedPedido={selectedPedido}
          selectedEds={selectedEds}
          setDisplayRechazarPedido={setDisplayRechazarPedido}
          setDisplayResumenRechazar={setDisplayResumenRechazar}
          detalles={detalles}
          selectedRechazo = {selectedRechazo}
          razonesRechazo = {razonesRechazo} 
          token={token}
          clientName={clientName}
          camion={camion}
          setCurrentPage={setCurrentPage}
          setPedidosAux={setPedidosAux}
          isROImpersonate={isROImpersonate}
          callServiceHandler={callServiceHandler}
        />
      )}

      {!displayResumenRechazar && (
        <>
          <div className="rechazar-pedido">
            <div className="rechazar-sidebar">
              <div className="item">
                <div className="title">ESTACIÓN DE SERVICIO</div>
                <div className="value force-one-liner">{selectedEds.label}</div>
              </div>
              <div className="item">
                <div className="title">TIPO DE PEDIDO</div>
                <div className="value force-one-liner">{selectedPedido.TIPO_PEDIDO}</div>
              </div>
              <div className="item">
                <div className="title">TIPO DE CAMIÓN</div>
                <div className="value force-one-liner">{camion?camion.NOMBRE:'-'}</div>
              </div>
              <div className="item">
                <div className="title">FECHA PREFERENTE</div>
                <div className="value force-one-liner">
                  {parseBudat(selectedPedido.FECHA_PREFERENTE_ENTREGA)}
                </div>
              </div>
              <div className="item">
                <div className="title">VENTANA SOLICITADA</div>
                <div className="value force-one-liner">{selectedPedido.VENTANA}</div>
              </div>
            </div>
            <div className="main">
              <div className="productos">
                <div className="header">
                  <div className="title">Mix de productos</div>
                  <div className="liters">Litros</div>
                </div>
                <div className="items">
                  {detalles.map((d, key) => (
                    <>
                      <div className="producto">
                        <div className="title">{d.nombre}</div>
                        <div className="liters">{getLocaleStringNumber(d.litros)}</div>
                      </div>
                      {key == detalles.length - 1 ? (
                        <></>
                      ) : (
                        <hr className="separator"></hr>
                      )}
                    </>
                  ))}
                </div>
                <div className="total">
                  <div className="title">Total</div>
                  <div className="liters">
                    {getLocaleStringNumber(selectedPedido.CANTIDAD_TOTAL)}
                  </div>
                </div>
              </div>
              { isDesktopRef.current &&
                <div className="motivo-rechazo-container">
                  <div className="item">
                    <div className="title mtop">MOTIVO DEL RECHAZO</div>
                  </div>
                  <Select
                    className="eds-select"
                    value={selectedRechazo}
                    onChange={(e) => setSelectedRechazo(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={razonesRechazo}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesPedidos}
                    width="640px"
                    widthmenu="640px"
                  />
                  { selectedPedido.DINAMO === 'X' &&
                    <EstadoCrediticio 
                      token={token}
                      pagador={pagador}
                      callServiceHandler={callServiceHandler}
                    />
                  }
                </div>
              }

              { !isDesktopRef.current &&
                <>
                  <MobileSelect
                    options={razonesRechazo}
                    selectItem={setSelectedRechazo}
                    label="MOTIVO DEL RECHAZO"
                    selectedItem={selectedRechazo}
                    onOpen={setOpenMobileFilter}
                    scrollToTop
                  />
                  { selectedPedido.DINAMO === 'X' &&
                    <EstadoCrediticio 
                      token={token}
                      pagador={pagador}
                      callServiceHandler={callServiceHandler}
                    />
                  }
                </>
              }

              <hr className="mtop"></hr>
              <div className="actions">
                <button
                  onClick={() => setDisplayRechazarPedido(false)}
                  className="actions-buttons hallow"
                >
                  Cancelar
                </button>
                <button onClick={() => displayResumen()} className={`actions-buttons ${selectedRechazo?'':'disabled'}`}>Rechazar</button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RechazarPedido;
