import React, { useState, useEffect } from "react";
import { InputWithClearBtn } from "../InputWithClearBtn";

import { SingleOption, 
  customStyles, 
  get3DecimalsFloatWithDelimiter, 
  getNumberFromLocalString,
  getLocaleStringFloat, 
  getLocaleStringNumber} from "../utils/utils.js";

import { getMaterialClass, 
  getMaterialMinititle, 
  getMaterialTitleCierre
} from "../utils/materiales"

import remove from "../../assets/img/remove.svg";
import errorIcon from "../../assets/img/atencion_rojo.svg";
import Select from "react-select";

const commonSelectProps = {
  className: "table-select",
  closeMenuOnSelect: true,
  hideSelectedOptions: false,
  options: [],
  components: { SingleOption, IndicatorSeparator: () => null },
  isClearable: false,
  autosize: false,
  placeholder: "Seleccionar",
  styles: customStyles,
};

export const CalibracionesYMovBodega = ({ 
  revisarFluctuacion, 
  setStep, 
  configuracion, 
  calibracionesEntradas, 
  setCalibracionesEntradas, 
  calibracionesSalidas, 
  setCalibracionesSalidas,
  materiales,
  materialesTanques,
  tanques,
  movimientosBodega,
  setMovimientosBodega,
  filteredTanques,
  setFilteredTanques,
  filteredDestinoTanques,
  setFilteredDestinoTanques,
  fechaCierre,
  pagador,
  selectedEds,
  callServiceHandler,
  setMovimientosRecomendados,
  disableCalibraciones,
  isROImpersonate,
  selectedCierre,
  selectedEstadoCierre,
  getTanquesDestinoRecomendados,
  permiteTanqueCamion
}) => {
  const [errores, setErrores] = useState([])

  function agregarMovimientoBodega(){
    const newMovimiento = {
      id:'',
      material: '',
      tanqueOrigen: '',
      tanqueDestino: '',
      volumen: 0,
      nuevo: true
    }
    setMovimientosBodega([...movimientosBodega, newMovimiento])
    setMovimientosRecomendados([...movimientosBodega, newMovimiento])
  }

  function movimientoBodegaChange(value, key, name){
    setErrores([])
    if(name === 'volumen'){
      value = get3DecimalsFloatWithDelimiter(value)

      if(value === false){
        return
      }
    }

    const temp = [...movimientosBodega]
    const tempDestino = [...movimientosBodega]

    if (name == "tanqueOrigen") {
      if (temp[key]["tanqueDestino"] === value) {
        temp[key]["tanqueDestino"] = temp[key][name]
      }

      if (tempDestino[key]["tanqueDestino"] === value) {
        tempDestino[key]["tanqueDestino"] = tempDestino[key][name]
      }
    }
    
    temp[key][name] = value;
    tempDestino[key][name] = value;

    if(name === 'material'){
      temp[key].tanqueOrigen = ''
      temp[key].tanqueDestino = ''
      tempDestino[key].tanqueOrigen = ''
      tempDestino[key].tanqueDestino = ''

      const newTanques = tanques.filter(t => t.idMaterial === value.value && t.tipo === 'camion')
      const tempTanques = {...filteredTanques}

      tempTanques[key] = newTanques
      if(tempTanques[key].length === 1){
        temp[key].tanqueOrigen = tempTanques[key][0]
      }

      const destinoTanques = tanques.filter(t => t.idMaterial === value.value && t.tipo === 'tanque')
      const tempDestinoTanques = {...filteredTanques}
      tempDestinoTanques[key] = destinoTanques
      if(tempDestinoTanques[key].length === 1){
        temp[key].tanqueDestino = tempDestinoTanques[key][0]
      }

      setFilteredTanques(tempTanques)
      setFilteredDestinoTanques(tempDestinoTanques);
    }
    setMovimientosBodega(temp)    
  }

  function eliminarMovimientoBodega(key){
    const temp = [...movimientosBodega]
    temp.splice(key, 1)
    setMovimientosBodega(temp)
    setErrores([])
  }

  function medicionesChange(value, key, type){
    if((value.charAt(0) === "0" || value.charAt(0) === 0) && value.length > 1){
      if(value.charAt(1) !== ',')
        value = value.substring(1)
    }

    const tmpValue = get3DecimalsFloatWithDelimiter(value)
    if(tmpValue !== false){
      if(type === 'entradas')
        setCalibracionesEntradas({...calibracionesEntradas, [key]: value})
      else
        setCalibracionesSalidas({...calibracionesSalidas, [key]: value})
    }
    setErrores([]);
  }

  function entradasFocusOut(value, key){
    value = get3DecimalsFloatWithDelimiter(value)

    if(value !== false)
      setCalibracionesEntradas({...calibracionesEntradas, [key]: value})
  }

/*
  function medicionesChange(value, key, type){
    if(isNaN(value))
      return

    if(type === 'entradas')
      setCalibracionesEntradas({...calibracionesEntradas, [key]: value})

    else
      setCalibracionesSalidas({...calibracionesSalidas, [key]: value})
  }
*/

  function getTotal(arr, number){
    let total = 0

    Object.keys(arr).forEach(function(key) {
      total += getNumberFromLocalString(arr[key])
    });
    return total.toFixed(2)
  }

  async function checkStep(){
    const tmpErrores = []
    let error1 = false, error2 = false
    movimientosBodega.forEach((movimiento) => {
      if(movimiento.material && movimiento.tanqueDestino && movimiento.tanqueOrigen){
        if(movimiento.volumen){
          const volumen = parseInt(movimiento.volumen) || 0
          if (volumen > movimiento.tanqueOrigen.capacidad || volumen > movimiento.tanqueDestino.capacidad) {
            error2 = true
          }
        }
      }
      else {
        error1 = true
      }
    })

    movimientosBodega.forEach((movimiento) => {
        if(movimiento.nuevo && (parseInt(movimiento.volumen)===0 || !movimiento.volumen)) error1 = true
    })
    
    if(error1)
      tmpErrores.push("Ingresa todos los datos.")
    if(error2)
      tmpErrores.push("No es posible ingresar una salida con un volumen mayor a la capacidad del tanque.")

    setErrores(tmpErrores)
    if(tmpErrores.length === 0){

        const data={
          ID_EDS:selectedEds.value,
          FECHA:fechaCierre,
          PASO:3,
          ID_PAGADOR:pagador,
          calibracionesEntradas:calibracionesEntradas,
          calibracionesSalidas:calibracionesSalidas,
          movimientosBodega:movimientosBodega
        }
        if(isROImpersonate) data.VISUALIZAR="SI";
  
        const url =
          process.env.REACT_APP_FLUCTUACIONES_API +
          "/fluctuaciones/cierre-diario-ingresar-paso";
  
        let response = await callServiceHandler(url,"POST",JSON.stringify(data));
        if(response!=="error"){
          setStep(4)
        }

    }
  }

  function getTanquesOrigen(key){
    let tmpTanques
    if(permiteTanqueCamion)
      tmpTanques = filteredTanques[key]?.filter(t => t.label!=="TAE_RE") || []
    else
      tmpTanques = filteredTanques[key]?.filter(t => t.tipo === 'camion' && t.label!=="TAE_RE") || []
    return tmpTanques
  }

  function getTanquesDestino(key){
    let tmpTanques
    if(permiteTanqueCamion)
      tmpTanques = filteredTanques[key]?.filter(t => t.value !== movimientosBodega[key].tanqueOrigen?.value ) || []
    else
      tmpTanques = filteredTanques[key]?.filter(t => (t.value !== movimientosBodega[key].tanqueOrigen?.value && t.tipo === 'tanque')  ) || []
    return tmpTanques
  }

  function getTanquesCalibracion(key){
    const tanque = configuracion.TANQUES[key]
    if(parseInt(tanque.ID) > parseInt(tanque.SIFON_1) || parseInt(tanque.ID) > parseInt(tanque.SIFON_2))
      return

    //NO MOSTRAR CAMIONES EN CALIBRACIONES
    if (tanque.TIPO==="camion")
      return

    let totalSifones = 1, diferencia_s0 = 0, diferencia_s1 = 0, diferencia_s2 = 0, sifonCamion
  
    if(tanque.TIPO === 'camion'){
      let isOld = false
      configuracion.TANQUES.map((t, newKey) => {
        if(t.ID.split('-')[0] === tanque.ID.split('-')[0]){
          if(newKey < key)
            isOld = true
          else if(newKey > key)
            sifonCamion = t
        }
      })
      if(isOld)
        return
    }

    let totalEntradas = getNumberFromLocalString(calibracionesEntradas[tanque.ID]) || 0
    let totalSalidas = getNumberFromLocalString(calibracionesSalidas[tanque.ID]) || 0
    diferencia_s0 = totalEntradas - totalSalidas
    diferencia_s0 = getLocaleStringFloat(diferencia_s0)

    if(sifonCamion){
      totalEntradas += getNumberFromLocalString(calibracionesEntradas[sifonCamion.ID]) || 0
      totalSalidas += getNumberFromLocalString(calibracionesSalidas[sifonCamion.ID]) || 0
      diferencia_s1 = (getNumberFromLocalString(calibracionesEntradas[sifonCamion.ID]) || 0) - (getNumberFromLocalString(calibracionesSalidas[sifonCamion.ID]) || 0)
    }

    if(tanque.SIFON_1 !== ''){
      totalSifones++
      totalEntradas += getNumberFromLocalString(calibracionesEntradas[tanque.SIFON_1]) || 0
      totalSalidas += getNumberFromLocalString(calibracionesSalidas[tanque.SIFON_1]) || 0
      diferencia_s1 = (getNumberFromLocalString(calibracionesEntradas[tanque.SIFON_1]) || 0) - (getNumberFromLocalString(calibracionesSalidas[tanque.SIFON_1]) || 0)
    }

    if(tanque.SIFON_2 !== ''){
      totalSifones++
      totalEntradas += getNumberFromLocalString(calibracionesEntradas[tanque.SIFON_2]) || 0
      totalSalidas += getNumberFromLocalString(calibracionesSalidas[tanque.SIFON_2]) || 0
      diferencia_s2 = (getNumberFromLocalString(calibracionesEntradas[tanque.SIFON_2]) || 0) - (getNumberFromLocalString(calibracionesSalidas[tanque.SIFON_2]) || 0)
    }

    diferencia_s1 = getLocaleStringFloat(diferencia_s1)
    diferencia_s2 = getLocaleStringFloat(diferencia_s2)

    let totalDiferencia = totalEntradas - totalSalidas
    totalDiferencia = getLocaleStringFloat(totalDiferencia)

    return <>
      <tr>
        <td className="horizontal-separator" colSpan={5} />
      </tr>

      <tr className={totalSifones > 1? 'only-row-span-cell-border': ''}>
        { sifonCamion &&
          <td rowSpan="2" className='fluctuaciones-column-with-border cell-border-top-left capitalize'>
            Camion<br /><span className="uppercase">{tanque.ID.split('-')[0]}</span>
          </td>
        }
        { !sifonCamion &&
          <td className={`tanque-name-column cell-border-top-left capitalize ${totalSifones === 1? 'cell-border-bottom-left': ''}`}>
            {tanque.TIPO} {tanque.TIPO === 'camion'? <br />: ''}<span className="uppercase">{tanque.ID.split('-')[0]}</span>
          </td>
        }
        <td rowSpan={totalSifones} className="container-cell calibraciones-material-cell">
          <div className="container-cell-content">
            <div className={`small-camion-compartment ${getMaterialClass(tanque.ID_MATERIAL)} cont2`}>
              <p className="small-camion-compartment__fuel-type-number">
                {getMaterialMinititle(tanque.ID_MATERIAL)}
              </p>
            </div>
            <span>{getMaterialTitleCierre(tanque.ID_MATERIAL)}</span>
          </div>
        </td>
        <td>
          <InputWithClearBtn onlyText={true} className="table-input" type="text" onChange={(e) => medicionesChange(e.target.value, tanque.ID, 'salidas')} value={calibracionesSalidas[tanque.ID] || 0} />
        </td>
        <td>
          <input 
            className="table-input calibraciones-table-input" 
            type="text" 
            onChange={(e) => medicionesChange(e.target.value, tanque.ID, 'entradas')} 
            value={calibracionesEntradas[tanque.ID] || 0} 
            onBlur={(e) => entradasFocusOut(e.target.value, tanque.ID)}
          />
        </td>
        <td className={`cell-border-top-right align-right ${(totalSifones === 1 && !sifonCamion)? 'cell-border-bottom-right':''}`}>
          <div className="diferencia-row">{diferencia_s0}</div>
        </td>
      </tr>
      { sifonCamion &&
        <>
          <tr>
            <td className="container-cell no-padding-left">
              <div className="container-cell-content">
                <div className={`small-camion-compartment ${getMaterialClass(sifonCamion.ID_MATERIAL)} cont2`}>
                  <p className="small-camion-compartment__fuel-type-number">
                    {getMaterialMinititle(sifonCamion.ID_MATERIAL)}
                  </p>
                </div>
                <span>{sifonCamion.MATERIAL}</span>
              </div>
            </td>
            <td>
              <InputWithClearBtn onlyText={true} className="table-input" type="text" onChange={(e) => medicionesChange(e.target.value, sifonCamion.ID, 'salidas')} value={calibracionesSalidas[sifonCamion.ID] || 0} />
            </td>
            <td>
              <input 
                className="table-input calibraciones-table-input" 
                type="text" 
                onChange={(e) => medicionesChange(e.target.value, sifonCamion.ID, 'entradas')} 
                value={calibracionesEntradas[sifonCamion.ID] || 0} 
                onBlur={(e) => entradasFocusOut(e.target.value, sifonCamion.ID)}
              />
            </td>
            <td className="align-right">
              <div className="diferencia-row">{diferencia_s1}</div>
            </td>
          </tr>
          <tr className='row-with-border sifon-camiones-separator'>
            <td /><td /><td /><td /><td />
          </tr>
        </>
      }

      { totalSifones > 1 &&
        <>
          <tr className={totalSifones === 2? 'row-with-border' : ''}>
            <td className="tank-name-cell">Tanque {tanque.SIFON_1}</td>
            <td>
              <InputWithClearBtn onlyText={true} className="table-input" type="text" onChange={(e) => medicionesChange(e.target.value, tanque.SIFON_1, 'salidas')} value={calibracionesSalidas[tanque.SIFON_1] || 0} />
            </td>
            <td>
              <input 
                className="table-input calibraciones-table-input" 
                type="text" 
                onChange={(e) => medicionesChange(e.target.value, tanque.SIFON_1, 'entradas')} 
                value={calibracionesEntradas[tanque.SIFON_1] || 0} 
                onBlur={(e) => entradasFocusOut(e.target.value, tanque.SIFON_1)}
              />
            </td>
            <td className="align-right">
              <div className="diferencia-row">{diferencia_s1}</div>
            </td>
          </tr>
        
          { totalSifones === 3 &&
            <tr className="row-with-border">
              <td className="tank-name-cell">Tanque {tanque.SIFON_2}</td>
              <td>
                <InputWithClearBtn onlyText={true} className="table-input" type="text" onChange={(e) => medicionesChange(e.target.value, tanque.SIFON_2, 'salidas')} value={calibracionesSalidas[tanque.SIFON_2] || 0} />
              </td>
              <td>
                <input 
                  className="table-input 
                  calibraciones-table-input" 
                  type="text" 
                  onChange={(e) => medicionesChange(e.target.value, tanque.SIFON_2, 'entradas')} 
                  value={calibracionesEntradas[tanque.SIFON_2] || 0}
                  onBlur={(e) => entradasFocusOut(e.target.value, tanque.SIFON_2)}
                />
              </td>
              <td className="align-right">
                <div className="diferencia-row">{diferencia_s2}</div>
              </td>
            </tr>
          }
        </>
      }

      { (sifonCamion || totalSifones > 1) &&
        <tr className="bold-row">
          <td className="cell-border-bottom-left" colSpan={2}>
            { sifonCamion &&
              <>Total Camion <span className="uppercase">{tanque.ID.split('-')[0]}</span></>
            }
            { totalSifones > 1 &&
              <>Total tanques {`${tanque.ID}${totalSifones > 1? ` - ${tanque.SIFON_1}`: ''}${totalSifones > 2? ` - ${tanque.SIFON_2}`: ''}`}</>
            }
          </td>
          <td>
            <div className="pill">
              {totalSalidas}
            </div>
          </td>
          <td>
            <div className="pill">
              {totalEntradas}
            </div>
          </td>
          <td className="cell-border-bottom-right align-right">
            <div className="pill">
              {totalDiferencia}
            </div>
          </td>
        </tr>
      }

    </>
  }

  function getTanquesDestinoNew(key){
    const tmpTanques = filteredDestinoTanques[key]?.filter(t => t.value !== movimientosBodega[key].tanqueOrigen.value) || []
    return tmpTanques;
  }

  function getTotalDiferencia(){
    return (getTotal(calibracionesEntradas) - getTotal(calibracionesSalidas));
  }

  function showAgregarButton () {
      return configuracion.TANQUES.some(tanque=>tanque.TIPO==="camion")
  }

  return (
    <>
      <div className="fluctuaciones-ingreso-mediciones-medium-section">
        <p className="flutuaciones-section-title">CALIBRACIONES</p>
        <table className="flutuaciones-table tanks-table calibrations-table">
          <thead>
            <tr>
              <th>Tanque</th>
              <th className="no-padding-left-th">Material</th>
              <th className='align-right'>Salida Litros</th>
              <th className='align-right'>Entrada Litros</th>
              <th className='align-right'>Diferencia</th>
            </tr>
          </thead>
          <tbody>
            {/* Horizontal  separator between head and body*/}

            { configuracion && configuracion.TANQUES.map((tanque, key) =>
              getTanquesCalibracion(key)        
            )}
            <tr>
              <td className="horizontal-separator" colSpan={5} />
            </tr>
            <tr className="bold-row">
              <td
                className="cell-border-top-left cell-border-bottom-left"
                colSpan={2}
              >
                TOTAL GENERAL
              </td>
              <td>
                <div className="pill">{getLocaleStringFloat(getTotal(calibracionesSalidas))}</div>
              </td>
              <td>
                <div className="pill">{getLocaleStringFloat(getTotal(calibracionesEntradas))}</div>
              </td>
              <td className="cell-border-top-right cell-border-bottom-right align-right">
                <div className="pill">
                  {getLocaleStringFloat(getTotal(calibracionesEntradas) - getTotal(calibracionesSalidas))}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="important-message">
          <span className="title">Importante:&nbsp;</span>
          <span>
          La diferencia total de calibraciones debe ser cero
          </span>
        </div>
        <p className="flutuaciones-section-title push-down">
          MOVIMIENTOS DE BODEGA
        </p>
        <table className="flutuaciones-table cellar-movement-table cierre-diario-mov-bodega-table">
          <thead>
            <tr>
              <th className="mov-bodega-padding-left-th-first">ID Mov.</th>
              <th className="mov-bodega-padding-left-th">Material</th>
              <th className="mov-bodega-padding-left-th">Tanque origen</th>
              <th className="mov-bodega-padding-left-th">Salida litros</th>
              <th className="mov-bodega-padding-left-th">Tanque destino</th>
            </tr>
          </thead>
          <tbody>
            {/* Horizontal separator between head and body */}
            <tr>
              <td className="horizontal-separator" colSpan={4} />
            </tr>
            {movimientosBodega.map((mov, key) =>
              <tr className="row-with-border">
                <td>
                  <div className="text-input">
                    {/* 1234567890 */}
                    {mov.id?mov.id:"123456"}
                  </div>
                </td>
                <td className="container-cell">
                  {mov.nuevo?
                    <Select
                        className="eds-select cd-mb-select"
                        value={movimientosBodega[key].material}
                        onChange={(e) => movimientoBodegaChange(e, key, 'material')}
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        options={materialesTanques}
                        components={{ SingleOption, IndicatorSeparator: () => null }}
                        isClearable={false}
                        autosize={false}
                        placeholder="Seleccionar"
                        styles={customStyles}
                        width={"153px"}
                    />:
                    <div className="container-cell-content bodega">
                        <div className={`small-camion-compartment ${getMaterialClass(mov.material.value)} cont2`}>
                        <p className="small-camion-compartment__fuel-type-number">
                            {getMaterialMinititle(mov.material.value)}
                        </p>
                        </div>
                        <span>{getMaterialTitleCierre(mov.material.value)}</span>
                    </div>
                  }
                    
                </td>
                <td>
                    {mov.nuevo?
                      <Select
                        className="eds-select cd-mb-select"
                        value={movimientosBodega[key].tanqueOrigen}
                        onChange={(e) => movimientoBodegaChange(e, key, 'tanqueOrigen')}
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        options={getTanquesOrigen(key)}
                        components={{ SingleOption, IndicatorSeparator: () => null }}
                        isClearable={false}
                        autosize={false}
                        placeholder="Seleccionar"
                        styles={customStyles}
                        disabled={true}
                        width={"153px"}
                      />
                      :
                      <div className="text-input">
                        Tanque {mov.tanqueOrigen?.label}
                      </div>
                    }
                    
                  {/*  */}
                </td>
                <td>
                    {mov.nuevo?<>
                        <InputWithClearBtn type="text" onChange={(e) => movimientoBodegaChange(e.target.value, key, 'volumen')} value={movimientosBodega[key].volumen} />
                       {/*  <button className="remove-row-btn">
                            <img src={remove} alt="remove" onClick={() => eliminarMovimientoBodega(key)} />
                        </button> */}
                    </>:<>
                    <div className="text-input">
                        {getLocaleStringNumber(mov.volumen)}
                    </div>
                    </>}
                </td>
                <td>
                  {mov.nuevo?<>
                    <Select
                    className="eds-select cd-mb-select-last"
                    value={movimientosBodega[key].tanqueDestino}
                    onChange={(e) => movimientoBodegaChange(e, key, 'tanqueDestino')}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={getTanquesDestino(key)}
                    components={{ SingleOption, IndicatorSeparator: () => null }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStyles}
                    width={mov.nuevo? '132px': '153px'}
                  />
                  </>:<>
                  <Select
                    className="eds-select cd-mb-select-last"
                    value={movimientosBodega[key].tanqueDestino}
                    onChange={(e) => movimientoBodegaChange(e, key, 'tanqueDestino')}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={getTanquesDestinoRecomendados(key)}
                    components={{ SingleOption, IndicatorSeparator: () => null }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStyles}
                    width={mov.nuevo? '132px': '153px'}
                  />
                  </>}
                  {mov.nuevo &&
                    <button className="remove-row-btn">
                      <img src={remove} alt="remove" onClick={() => eliminarMovimientoBodega(key)} />
                    </button>
                  }
                </td>                
              </tr>
            )}
            {showAgregarButton() && 
              <tr>
                <td
                  className="cell-border-bottom-left cell-border-bottom-right"
                  colSpan={5}
                >
                  <button className="main-button add-new-row" onClick={agregarMovimientoBodega}>Agregar</button>
                </td>
              </tr>
            }
            {!showAgregarButton() && 
              <tr className="data">
                <td className="align-left" colSpan="100%">
                  No se pueden agregar movimientos de bodega pues la EDS no cuenta con camión propio.
                </td>
              </tr>
            } 
          </tbody>
        </table>
        {
          errores.map((error) =>
          <div className="table-bottom-error">
            <img src={errorIcon} alt="error" />
            <p>{error}</p>
          </div>
        )}
        <div className="fluctuaciones-ingreso-mediciones-actions">
          <button className="main-button hallow" onClick={() => setStep(2)}>
            Volver
          </button>
          <button className={`main-button${getTotalDiferencia()!==0?" disabled":""}`} onClick={checkStep} disabled={getTotalDiferencia() !== 0 || disableCalibraciones || selectedEstadoCierre==="CERRADO"}>
            Confirmar
          </button>
        </div>
      </div>
    </>
  );
};
