import React, { useState, useEffect } from "react";
import SelectedTransactions from "../StepperComponents/SelectedTransactions";
import { BankChooser } from "../StepperComponents/BankChooser";
import Comprobante from "../StepperComponents/Comprobante";
import { Stepper } from "../StepperComponents/Stepper";
import {
  hideLoader,
  openWindowWithPost
} from "../utils/windows";
import { displayLoader } from "../utils/windows";
import CustomModal from "../CustomModal";
import { useHistory } from "react-router-dom";
const PagarEnBanco = ({
  selectedTransactions,
  totalSaldo,
  mandatosActivos,
  rutConcesionario,
  token,
  clientName,
  clientAddress,
  pagador,
  setDisplayChequeEnLinea,
  closeStepper,
  isCompensar,
  totalSteps,
  isArcoprime,
  selectedEdsArcoprime,
  totalAPagar,
  setDisplayPagarEnBanco,
  isROImpersonate,
  callServiceHandler,
  isErrorAlertOpen,
  displayCustomError,
  banksList,
  banksOptionsMessage
}) => {
  const [step, setStep] = useState(1);
  const [mandato, setMandato] = useState(0);
  const [propuesta, setPropuesta] = useState();
  const [bank, setBank] = useState();
  const [convenio, setBankConvenio] = useState();
  const [bankName, setBankName] = useState("");
  const [error, setError] = useState(false);
  const [displayModalFinal, setDisplayModalFinal] = useState(false);
  const [willGoBack, setWillGoBack] = useState(false);
  const history = useHistory();
  const [displayModalLiberacion, setDisplayModalLiberacion] = useState(false);

  useEffect(() => {
    if(willGoBack && !isErrorAlertOpen){
      setDisplayPagarEnBanco(false)
    }
  }, [isErrorAlertOpen, willGoBack])

  async function crearPropuestaPago() {
    displayLoader();

    let viaPago = "";

    if (bank === "001") {
      viaPago = "-";
    }
    if (bank === "002") {
      viaPago = "/";
    }
    if (bank === "003") {
      viaPago = "*";
    }
    if (bank === "004") {
      viaPago = "9";
    }
    if (bank === "005") {
      viaPago = "&";
    }

    const documentos = [];

    selectedTransactions.forEach(function (transaction) {
      const newDoc = {
        DOCSAP: transaction.DOCSAP,
        POSICION: transaction.POSICION,
        EJERCICIO: transaction.EJERCICIO,
        NUMERO: transaction.NUMERO,
      };
      documentos.push(newDoc);
    });

    const debugData={
        selectedTransactions: selectedTransactions,
        totalSaldo: totalSaldo,
        rutConcesionario: rutConcesionario,
        banco: bankName,
        bancoId: bank,
        clientName: clientName,
        clientAddress: clientAddress,
        title: "Comprobante de Pago",
        tipo: "pago",
        isArcoprime: isArcoprime,
        selectedEdsArcoprime: selectedEdsArcoprime,
        totalAPagar: totalAPagar
    }

    let data = {};

    if (isArcoprime) {
      data = {
        id_eds: selectedEdsArcoprime.value,
        DOCUMENTOS: documentos,
        via_pago: viaPago,
        calculo:debugData
      };
    } else {
      data = {
        rut_concesionario: rutConcesionario,
        DOCUMENTOS: documentos,
        via_pago: viaPago,
        calculo:debugData
      };
    }

    let url_liberacion = process.env.REACT_APP_BASE_API + '/cuenta-corriente/obtener-pagos-inconclusos?rut_concesionario=' + rutConcesionario;

    if(isArcoprime){
      url_liberacion += "&id_eds="+selectedEdsArcoprime.value
    }

    let result_liberacion = await callServiceHandler(url_liberacion,"GET")

    if(result_liberacion!=="error"){
      const data = result_liberacion.data
      const documentos_por_liberar = data['PROPUESTAS_PAGO'].filter((mov)=>mov.TIPO!="Abono")
      if(documentos_por_liberar.length>0){
        setDisplayModalLiberacion(true)
        return
      }
    }

    const url = process.env.REACT_APP_BASE_API + "/cuenta-corriente/crear-propuesta-pago";

    let result = await callServiceHandler(url, "POST", JSON.stringify(data))
    if(result !== "error"){
      if (result.data.GRUPO_PPTA !== "") {
        setPropuesta(result.data.GRUPO_PPTA);

        const url = process.env.REACT_APP_BASE_API +
          "/cuenta-corriente/obtener-historial-pagos?rut_concesionario=" + rutConcesionario +
          "&grupo_ppta=" + result.data.GRUPO_PPTA;

        const infoPago = await callServiceHandler(url, "GET")

        let url2 =
          process.env.REACT_APP_BASE_API + "/cuenta-corriente/obtener-url-banco?"
          + "rut_concesionario=" + rutConcesionario
          + "&monto=" + infoPago.data.TOTAL
          + "&banco=" + bank
          + "&grupo_ppta=" + result.data.GRUPO_PPTA

        let result2 = await callServiceHandler(url2, "GET")
        if(result2 !== "error"){
          if (result2.data.url_method === "GET") {
            window.open(result2.data.url + "&origen=1");
          } else {
            const data = {};
            data['xml'] = result2.data.parameter;
            const idUsuario = isArcoprime? selectedEdsArcoprime.value:pagador;
            openWindowWithPost(result2.data.url, result2.data.xml, rutConcesionario, idUsuario, result.data.GRUPO_PPTA, bank);
          }
        }

        saveData(result.data.GRUPO_PPTA, infoPago.data.TOTAL, infoPago.data.PROPUESTAS_PAGO[0].DOCUMENTOS);
        setDisplayModalFinal(true);
      } else {
        displayCustomError("Error", "Se ha producido un error: " + result.data.MENSAJE, 'error')
        setDisplayPagarEnBanco(false);
      }
    }
    else{
      setTimeout(setWillGoBack(true), 100)
    }
  }

  const saveData = (propuesta, totalReal, transacciones) => {
    const data = {
      propuesta: propuesta,
      selectedTransactions: transacciones,
      totalSaldo: totalSaldo,
      rutConcesionario: rutConcesionario,
      banco: bankName,
      bancoId: bank,
      clientName: clientName,
      clientAddress: clientAddress,
      title: "Comprobante de Pago",
      tipo: "pago",
      isArcoprime: isArcoprime,
      selectedEdsArcoprime: selectedEdsArcoprime,
      totalAPagar: totalReal
    };

    window.localStorage.setItem(propuesta, JSON.stringify(data));
  };

  function closeModal() {
    setDisplayModalFinal(false);
    setDisplayModalLiberacion(false);
    history.go(0);
  }

  return (
    <div className="abonar-en-banco">
      <div className="abonar-en-banco__content">
        <Stepper
          step={step}
          error={error}
          totalSteps="3"
          title="Pagar en banco"
          step1Title="Confirmar"
          step2Title="Pagar"
          step3Title="Finalizar"
        />
        {step === 1 && (
          <SelectedTransactions
            setStep={setStep}
            selectedTransactions={selectedTransactions}
            totalSaldo={totalSaldo}
            isCompensar={isCompensar}
            rutConcesionario={rutConcesionario}
            setPropuesta={setPropuesta}
            token={token}
            setError={setError}
            closeStepper={closeStepper}
            callServiceHandler={callServiceHandler}
            displayCustomError={displayCustomError}
          />
        )}
        {step === 2 && (
          <BankChooser
            setStep={setStep}
            confirmAbono={crearPropuestaPago}
            bank={bank}
            convenio={convenio}
            setBank={setBank}
            setBankName={setBankName}
            setBankConvenio={setBankConvenio}
            closeStepper={setStep}
            closeWithParameter={true}
            banksList={banksList}
            banksOptionsMessage={banksOptionsMessage}
          />
        )}
        {step === 3 && (
          <Comprobante
            error={error}
            selectedTransactions={selectedTransactions}
            rutConcesionario={rutConcesionario}
            totalSaldo={totalSaldo}
            banco={mandatosActivos[mandato].BANCO}
            clientName={clientName}
            clientAddress={clientAddress}
            propuesta={propuesta}
            closeStepper={closeStepper}
            title="Comprobante de Pago"
            isROImpersonate={isROImpersonate}
          />
        )}
      </div>
      
      <CustomModal
        displayModal={displayModalFinal}
        closeModal={closeModal}
        acceptFunc={closeModal}
        modalTitle='Redireccionando'
        modalText='Ha sido redirigido al medio de pago seleccionado. Si ya termino de pagar, por favor revise en Historial de Pagos.'
        modalIcon='success'
      />
      <CustomModal
        displayModal={displayModalLiberacion}
        closeModal={closeModal}
        acceptFunc={closeModal}
        modalTitle='Error'
        modalText='No es posible procesar el pago pues existen documentos pendientes por liberar.'
        modalIcon='importante'
      />
    </div>
  );
};

export default PagarEnBanco;
