import React from "react";
import Flecha_down from "../../../assets/img/Flecha_down.svg";
import Flecha_up from "../../../assets/img/Flecha_up.svg";
import LineChart from "../../Charts/LineCharts";
import ThreeAxisLineChart from "../../Charts/ThreeAxisLineChart";
import { getAlertIconVentas } from "../../utils/get";
import { getTanquesSifoneadosFluctuacionesCierreMensual } from "../../utils/tanquesSifoneados";
import { getLocaleStringRoundedInt, parseDate, parseDateString } from "../../utils/utils";
import PrintFileHeader from "./PrintFileHeader";
import check_green from "../../../assets/img/Check_green.svg";
import check_yellow from "../../../assets/img/check_yellow.svg";
import check_red from "../../../assets/img/atencion_rojo.svg";

const HistorialFluctuacionesTable = ({
  tanqueData,
  tanquesData,
  tableRef,
  total,
  displayTable,
  cabecera,
  selectedEds,
  selectedPeriodo,
  rutConcesionario
}) => {
  const orderTable = (filterkey, order, parse) => {
    //const arr = orderArray(filterkey, detalle, order, parse);
    //props.setDetalle(arr);
  };

  const data = {
    tanques: [
      {
        tanque: "Tanque 1",
        material: "GAS 93",
        medicion_inicial:123,
        cierres: [
          { fecha: "1/12/2022", entradas: 1234, salidas: 3456, medicion: 6543 },
          { fecha: "2/12/2022", entradas: 1234, salidas: 3456, medicion: 6543 },
        ],
      },
      {
        tanque: "Tanque 2",
        material: "GAS 97",
        medicion_inicial:456,
        cierres: [
          { fecha: "1/12/2022", entradas: 1234, salidas: 3456, medicion: 6543 },
          { fecha: "2/12/2022", entradas: 1234, salidas: 3456, medicion: 6543 },
        ],
      }
    ],
  };

  function getAlertaTooltipText(color){
    if(color === "ROJO")
      return "Fuera de Rango"

    else if(color === "AMARILLA")
      return "Dentro del Rango"
  
    else if(color === "VERDE")
      return "Sin Fluctuación"
  }

  function getAlertaImg(color){
    if(color === "ROJO")
      return <img src={check_red} />
  
    else if(color === "AMARILLA")
      return <img src={check_yellow} />
  
    else if(color === "VERDE")
      return <img src={check_green} />
  }

  return (
    <div className="historial-fluctuaciones-table-container" ref={tableRef}>
      <PrintFileHeader 
        cabecera={cabecera} 
        selectedEds={selectedEds} 
        selectedPeriodo={selectedPeriodo} 
        tanqueData={tanqueData} 
        rutConcesionario={rutConcesionario} 
      />
      {displayTable && 
        <div className="historial-fluctuaciones-table-media-fix-container">
          <table className="historial-fluctuaciones-table historial-fluctuaciones-table-media-fix">
            <tr>
              <th>
                <div className="th-data">
                  <div className="th-text">
                    Fecha
                    <div className="arrows">
                      <img
                        onClick={() => orderTable("FECHA", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("FECHA", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data">
                  <div className="th-text">
                    Entradas (lts)
                    <div className="arrows">
                      <img
                        onClick={() => orderTable("ENTRADAS", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("ENTRADAS", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data">
                  <div className="th-text">
                    Calibraciones
                    <div className="arrows">
                      <img
                        onClick={() => orderTable("CALIBRACIONES", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("CALIBRACIONES", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data">
                  <div className="th-text">
                    Mov. de<br />Bodega
                    <div className="arrows mtop">
                      <img
                        onClick={() => orderTable("CALIBRACIONES", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("CALIBRACIONES", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data">
                  <div className="th-text">
                    Salidas (lts)
                    <div className="arrows">
                      <img
                        onClick={() => orderTable("SALIDAS", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("SALIDAS", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data">
                  <div className="th-text">
                    Salida Acum.<br />(lts)
                    <div className="arrows">
                      <img
                        onClick={() => orderTable("SALIDA_ACUMULADA", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() =>
                          orderTable("SALIDA_ACUMULADA", "desc", false)
                        }
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data">
                  <div className="th-text">
                    Inventario Teórico (lts)
                    <div className="arrows mtop">
                      <img
                        onClick={() =>
                          orderTable("INVENTARIO_TEORICO", "asc", false)
                        }
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() =>
                          orderTable("INVENTARIO_TEORICO", "desc", false)
                        }
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data">
                  <div className="th-text">
                    Med. Física Proyect. (lts)
                    <div className="arrows mtop">
                      <img
                        onClick={() => orderTable("MEDICION_FISICA", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("MEDICION_FISICA", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data">
                  <div className="th-text">
                    Fluctuación Diaria (lts)
                    <div className="arrows mtop">
                      <img
                        onClick={() =>
                          orderTable("FLUCTUACION_DIARIA", "asc", false)
                        }
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() =>
                          orderTable("FLUCTUACION_DIARIA", "desc", false)
                        }
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data">
                  <div className="th-text">
                    Fluctuación<br />Acum (lts)
                    <div className="arrows mtop">
                      <img
                        onClick={() =>
                          orderTable("FLUCTUACION_ACUMULADA", "asc", false)
                        }
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() =>
                          orderTable("FLUCTUACION_ACUMULADA", "desc", false)
                        }
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data">
                  <div className="th-text">
                    %&nbsp;Fluctuación Acum
                    <div className="arrows mtop">
                      <img
                        onClick={() =>
                          orderTable(
                            "FLUCTUACION_ACUMULADA_PORCENTAJE",
                            "asc",
                            false
                          )
                        }
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() =>
                          orderTable(
                            "FLUCTUACION_ACUMULADA_PORCENTAJE",
                            "desc",
                            false
                          )
                        }
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data validado">
                  <div className="th-text">
                    Validado
                    <div className="arrows">
                      <img
                        onClick={() => orderTable("VALIDADO", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("VALIDADO", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
            <tr className="table-separator-row-border-top">
              <td />
            </tr>

          
              {tanquesData?<>

              {tanquesData.length>0?<>
                <tbody>
                {tanqueData?.cierres.map((cierre,key)=>(
                  <tr key={key} className="data">
                    <td className="align-right">{parseDateString(cierre.fecha)}</td>
                    <td className="align-right">{getLocaleStringRoundedInt(cierre.entradas)}</td>
                    <td className="align-right">{getLocaleStringRoundedInt(cierre.calibraciones)}</td>
                    <td className="align-right">{getLocaleStringRoundedInt(cierre.movimientos_bodega)}</td>
                    <td className="align-right">{getLocaleStringRoundedInt(cierre.salidas)}</td>
                    <td className="align-right">{getLocaleStringRoundedInt(cierre.salida_acumulada_lts)}</td>
                    <td className="align-right">{getLocaleStringRoundedInt(cierre.inventario_teorico)}</td>
                    <td className="align-right">{getLocaleStringRoundedInt(cierre.medicion_fisica_proyectada)}</td>
                    <td className="align-right">{getLocaleStringRoundedInt(cierre.fluctuacion_diaria_lts)}</td>
                    <td className="align-right">{getLocaleStringRoundedInt(cierre.fluctuacion_acumulada_lts)}</td>
                    <td className="align-right">{cierre.fluctuacion_porcentaje.toString().replace(".", ',')}</td>
                    <td className={`alert-container-cierre-diario align-right cell-border-top-right`}>
                      {getAlertaImg(cierre.validado)}
                      <div className="alert-tooltip confirmacion">
                        <div className="content">
                          {getAlertaTooltipText(cierre.validado)}
                        </div>
                      </div>
                    </td>
                    {/* <td className="align-right">

                      {getAlertIconVentas(cierre.validado)}
                      <div className="alert-tooltip confirmacion">
                        <div className="content">
                          {getAlertaTooltipText(cierre.validado)}
                        </div>
                      </div>
                    </td> */}
                  </tr>
                ))}
                </tbody>
                <tr className="table-separator-row-border-top">
                  <td />
                </tr>
                <tbody>
                  <tr className="data">
                    <td className="align-right">Total</td>
                    <td className="align-right">{getLocaleStringRoundedInt(total?.TOTAL_ENTRADAS)}</td>
                    <td className="align-right">{getLocaleStringRoundedInt(total?.TOTAL_CALIBRACIONES)}</td>
                    <td className="align-right">{getLocaleStringRoundedInt(total?.TOTAL_MOV_BODEGA)}</td>
                    <td className="align-right">{getLocaleStringRoundedInt(total?.TOTAL_SALIDAS)}</td>
                    <td className="align-left" colSpan="100%"></td>
                  </tr>
                </tbody>  
              </>:<>
                <tbody>
                  <tr className="data">
                    <td className="align-left" colSpan="100%">
                      No se han encontrado tanques.
                    </td>
                  </tr>
                </tbody>
              </>}
                
              </>:<>
                <tbody>
                  <tr className="data">
                    <td className="align-left" colSpan="100%">
                      Seleccione una Estación de Servicio y un Periodo para ver la información.
                    </td>
                  </tr>
                </tbody>
              </>}
          </table>
        </div>
      }      
    </div>
  );
};

export default HistorialFluctuacionesTable;
