import React, { useState, useEffect } from "react"
import HeaderData from "../HeaderData"
import HeaderBox from "../HeaderBox"
import saldoAzulIcon from "../../assets/img/saldo-azul.svg"
import imprimir from "../../assets/img/imprimir.svg"
import descargar from "../../assets/img/descargar.svg"
import { getUpdateDate } from "../utils/monthNames"
import BluemaxDetail from "./BluemaxDetail"
import BluemaxPrincipal from "./BluemaxPrincipal"
import LubricantesBluemax from "./LubricantesBluemax"

const Bluemax = ({token, rutConcesionario, callServiceHandler, cabecera, instalaciones,isROImpersonate}) => {
  const [updateDate, setUpdateDate] = useState('')
  const [displayDetail, setDisplayDetail] = useState(false)

  useEffect(() => {
    getUpdateDate(setUpdateDate)
  }, [])

  function showDetail(detail){
    setDisplayDetail(true)
  }

  return (
    <div className="tct-tae bluemax">
      <div className="view-title">TCT TAE</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Bluemax</div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandler={callServiceHandler}
        cabecera={cabecera}
        actualizable={true}
      />
      {displayDetail && <BluemaxDetail isROImpersonate={isROImpersonate} />}
      {/* {!displayDetail && <BluemaxPrincipal showDetail={showDetail} />} */}
      {!displayDetail && <LubricantesBluemax token={token} rutConcesionario={rutConcesionario} callServiceHandler={callServiceHandler} cabecera={cabecera} showDetail={showDetail} instalaciones={instalaciones} />}
    </div>
  )
};

export default Bluemax
