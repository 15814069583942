import React, { useContext, useEffect, useRef, useState } from "react";
import { ViewPortContext } from "../../App";
import { MobileFiltersChoice } from "../MobileFilters/MobileFiltersChoice";
import {
  MobileFiltersOpenedChoice,
  MobileFiltersOpenedChoiceOption,
} from "../MobileFilters/MobileFiltersOpenedChoice";
import {
  getLocaleStringNumber,
  spaceAboveIframe,
} from "../utils/utils";
import { scrollTo } from "../utils/windows";
import { allMateriales } from "../utils/materiales";
import CarrotDown from "../../assets/img/caret-down-blue.svg";
import { getVolumenText } from "../CustomSelect";

const digitsCheck = new RegExp("\\d");

const CamionMobile = ({
  camion,
  compartimientos,
  materiales,
  materialesNoEmpty,
  setMaterial,
}) => {
  const [openedSelectModal, setOpenedSelectModal] = useState("");
  const { viewPortHeight, iframeHeight } = useContext(ViewPortContext);
  const selectionViewOpened = openedSelectModal !== "";
  const closeSelectionView = () => setOpenedSelectModal("");
  const selectedComportment = compartimientos[openedSelectModal];
  const lastOpenedPosition = useRef(0);

  const getComportmentLabel = (number, maxVolume, long) =>
    `${long ? "COMPARTIMENTO" : "COMP."} ${number} (${maxVolume / 1000} M3)`;
  const selectedMaterial =
    openedSelectModal === 0 ? materialesNoEmpty : materiales;

  useEffect(() => {
    if (selectionViewOpened) {
      scrollTo(0, 0);
      document.querySelector(
        ".formulario-pedidos-mobile-first-form"
      ).style.display = "none";
    } else {
      document.querySelector(
        ".formulario-pedidos-mobile-first-form"
      ).style.display = "";
      if (lastOpenedPosition.current > 0) {
        setTimeout(() => {
          scrollTo(0, lastOpenedPosition.current - spaceAboveIframe);
          lastOpenedPosition.current = 0;
        }, 0);
      } else {
        lastOpenedPosition.current = 0;
      }
    }
  }, [selectionViewOpened]);

  return selectionViewOpened ? (
    <div
      className="mobile-select__opened-view"
      style={{
        height: Math.min(iframeHeight, viewPortHeight - spaceAboveIframe),
      }}
    >
      <MobileFiltersOpenedChoice
        itemTitle={getComportmentLabel(
          selectedComportment.NUMERO,
          selectedComportment.VOLUMEN_MAXIMO,
          true
        )}
        closeView={closeSelectionView}
      >
        {selectedMaterial.map((material) => {
          const hasOptions =
            selectedComportment.VOLUMEN_MINIMO > 0 && selectedComportment.VOLUMEN_MINIMO!==selectedComportment.VOLUMEN_MAXIMO &&
            material.ID_MATERIAL !== "empty";

          return (
            <MobileFiltersOpenedChoiceOption noButton>
              <div className="camion-mobile__expanding-options-section">
                <label
                  className="camion-mobile__options-title"
                  htmlFor={material.ID_MATERIAL}
                  onClick={
                    !hasOptions
                      ? () => {
                          setMaterial(
                            material,
                            openedSelectModal,
                            selectedComportment.VOLUMEN_MAXIMO
                          );
                          closeSelectionView();
                        }
                      : undefined
                  }
                >
                  <span>{material.NOMBRE.toLowerCase()}</span>
                  {hasOptions && <img src={CarrotDown} alt="Expand" />}
                </label>
                {hasOptions && (
                  <>
                    <input
                      type="radio"
                      name={selectedComportment.NUMERO}
                      id={material.ID_MATERIAL}
                      className="camion-mobile__options-input"
                    />
                    <div className="camion-mobile__options-list">
                      <button
                        className="camion-mobile__option-item"
                        onClick={() => {
                          setMaterial(
                            material,
                            openedSelectModal,
                            selectedComportment.VOLUMEN_MINIMO
                          );
                          closeSelectionView();
                        }}
                      >
                        {getVolumenText(selectedComportment.VOLUMEN_MINIMO)}
                      </button>
                      <button
                        className="camion-mobile__option-item"
                        onClick={() => {
                          setMaterial(
                            material,
                            openedSelectModal,
                            selectedComportment.VOLUMEN_MAXIMO
                          );
                          closeSelectionView();
                        }}
                      >
                        {getVolumenText(selectedComportment.VOLUMEN_MAXIMO)}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </MobileFiltersOpenedChoiceOption>
          );
        })}
      </MobileFiltersOpenedChoice>
    </div>
  ) : (
    <>
      <div className="camion camion-mobile">
        <div className={`container cont${camion.NUMERO_COMPARTIMIENTOS}`}>
          <p className="camion-mobile__title">
            <strong>
              CAMIÓN {camion.VOLUMEN_MAXIMO.toLocaleString("es")} L
            </strong>{" "}
            | {camion.NOMBRE}
          </p>
          {compartimientos.map(
            ({ NUMERO, VOLUMEN_MAXIMO, material, volumen }, key) => {
              const materialDetail = allMateriales[material.ID_MATERIAL];
              const title = digitsCheck.test(materialDetail.title)
                ? parseInt(materialDetail.title)
                : materialDetail.title;
              return (
                <div className="camion-mobile__compartment" key={NUMERO}>
                  <div className="camion-mobile__compartment-info">
                    <div
                      className={`compartimiento camion-mobile__compartment-fuel-type ${materialDetail.class}`}
                    >
                      <p className="camion-mobile__compartment-fuel-type-number">
                        {title === 'Vacío'? '': title}
                      </p>
                    </div>
                    <div className="camion-mobile__compartment-fuel-volume">
                      {`${getLocaleStringNumber(volumen)} L`}
                    </div>
                  </div>
                  <div className="camion-mobile__compartment-field">
                    <MobileFiltersChoice
                      itemName={getComportmentLabel(NUMERO, VOLUMEN_MAXIMO)}
                      onClick={(e) => {
                        lastOpenedPosition.current = e.clientY;
                        setOpenedSelectModal(key);
                      }}
                      value={material.NOMBRE.toLowerCase()}
                    />
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </>
  );
};

export default CamionMobile;
