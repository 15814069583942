import React, { useState, useEffect }from "react";
import { SingleOption, customStyles } from "../utils/utils.js";
import Select from "react-select";

export const ConfiguracionesViews = ({allEds, selectedEds, setSelectedEds, nuevaConfig, isTelesoporte, configuraciones}) => {
  const [detailView, setDetailView] = useState(-1)
  const [fechaHasta, setFechaHasta] = useState('')

  useEffect(() => {
    if(configuraciones[detailView]){
      if(configuraciones[detailView].metadata.FECHA_F && configuraciones[detailView].metadata.HORA_F)
        setFechaHasta(configuraciones[detailView].metadata.FECHA_F + " - " + configuraciones[detailView].metadata.HORA_F)
      else
        setFechaHasta("Actual")
    }
  }, [detailView])

  return (
    <div className="configuracion-tanques">
      { detailView === -1 &&
        <>
          <div className="page-options">
            <div className="filters-container">
              <div className="filter">
                <div className="filter-title">ESTACIÓN DE SERVICIO</div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedEds}
                    onChange={(e) => setSelectedEds(e)}
                    options={allEds}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    components={{ SingleOption, IndicatorSeparator: () => null }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStyles}
                  />
                </div>
              </div>
            </div>
            { selectedEds && isTelesoporte &&
              <button className="main-button" onClick={nuevaConfig}>Nueva Configuración</button>
            }
          </div>
          <table className="flutuaciones-table configurations-table">
            <thead>
              <tr>
                <th>Correlativo Configuración</th>
                <th>Fecha inicial</th>
                <th>Hora inicial</th>
                <th>Fecha final</th>
                <th>Hora final</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="horizontal-separator" colSpan={6} />
              </tr>
              { !selectedEds &&
                <tr>
                  <td colSpan={6} className="full-width">
                    Seleccione una Estación de Servicio para ver la información.
                  </td>
                </tr>
              }
              { selectedEds &&
                configuraciones.map((config, key) =>
                  <tr className={key < configuraciones.length - 1? "row-with-border": ""}>
                    <td className={key === 0? "cell-border-top-left": ""}>{configuraciones.length - key}</td>
                    <td>{config.metadata.FECHA_I}</td>
                    <td>{config.metadata.HORA_I}</td>
                    <td>{config.metadata.FECHA_F || 'Indefinida'}</td>
                    <td>{config.metadata.HORA_F || 'Indefinida'}</td>
                    <td className={key === 0? "cell-border-top-right": ""}>
                      <button className="link" onClick={() => setDetailView(key)}>Ver</button>
                    </td>
                  </tr>
                )
              }
            </tbody>
          </table>
        </>
      }
      { detailView > -1 &&
        <>
          <div className="page-options disabled-inputs-view">
            <div className="filters-container">
              <div className="filter">
                <div className="filter-title">ESTACIÓN DE SERVICIO</div>
                <div className="filter-input">
                  <input type="text" className="filter-input-element" value={selectedEds.value} disabled />
                </div>
              </div>
              <div className="filter">
                <div className="filter-title">DESDE</div>
                <div className="filter-input">
                  <input 
                    type="text" 
                    className="filter-input-element" 
                    value={`${configuraciones[detailView].metadata.FECHA_I} - ${configuraciones[detailView].metadata.HORA_I}`} 
                    disabled 
                  />
                </div>
              </div>
              <div className="filter">
                <div className="filter-title">HASTA</div>
                <div className="filter-input">
                  <input 
                    type="text" 
                    className="filter-input-element" 
                    value={fechaHasta} 
                    disabled 
                  />
                </div>
              </div>
            </div>
          </div>

          <p className="flutuaciones-section-title">Configuración {detailView + 1}</p>
          <table className="flutuaciones-table new-configuration-table new-configuration-table-details">
            <thead>
              <tr>
                <th>Tipo</th>
                <th>ID</th>
                <th>Material</th>
                <th>Capacidad (Lts)</th>
                <th colSpan={3}>Sifoneado con</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="horizontal-separator" colSpan={5} />
              </tr>
              {
                configuraciones[detailView].metadata.TANQUES.map((tanque, key) =>
                <tr className={key < configuraciones[detailView].metadata.TANQUES.length - 1? 'row-with-border': 'row-without-border'}>
                  {tanque.DESHABILITADO==="NO" || tanque.TIPO==="camion"?
                  <>
                    <td className={`${key === 0? 'cell-border-top-left': ''} ${key === configuraciones[detailView].metadata.TANQUES.length-1? 'cell-border-bottom-left': ''}`}>
                      {tanque.TIPO}
                    </td>
                    <td>
                      {tanque.ID}
                    </td>
                    <td className="cell-with-large-select">
                      {tanque.MATERIAL}
                    </td>
                    <td>
                      {tanque.CAPACIDAD}
                    </td>
                    <td className="cell-with-small-select">
                      {tanque.SIFON_1 || '-'}
                    </td>
                    <td className="cell-with-small-select">
                      {tanque.SIFON_2 || '-'}
                    </td>
                    <td 
                      className={`cell-with-small-select 
                        ${key === 0? 'cell-border-top-right': ''} 
                        ${key === configuraciones[detailView].metadata.TANQUES.length-1? 'cell-border-bottom-right': ''}`
                    }>
                      {tanque.SIFON_3 || '-'}
                    </td>
                  </>
                  :
                  <>
                    <td colSpan="100%">El tanque {tanque.ID} está deshabilitado en la EDS. </td>
                  </>
                  }
                </tr>
              )}
            </tbody>
          </table>
          <hr />
          <button className="main-button thin" onClick={() => setDetailView(-1)}>Volver</button>
        </>
      }
    </div>
  );
};
