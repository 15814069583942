import React, { useRef, useState, useEffect } from "react";
import PrintComponent from "./PrintComponent";
import HeaderData from "./HeaderData";
import ExportExcel from "./ExportExcel";
import closeIcon from "../assets/img/cross-icon.svg";
import Select from "react-select";
import { SingleOption, customStyles } from "./utils/utils.js";
import { getEdsPedidosActivos, getPeriodosEdsActivos } from "./utils/get";
import { getAllPeriodos, getUpdateDate } from './utils/monthNames';
import Modal from "react-modal";
import {
  showGreyBackground,
  hideGreyBackground,
  displayLoader,
  hideLoader,
  scrollTo
} from "./utils/windows";

const labels = [
  ["Fecha", "FECHA"],
  ["Hora", "HORA"],
  ["Tanque(s)", "ID_TANQUES"],
  ["Tipo Modificación", "TIPO_MODIFICACION"],
  ["Valor Propuesto", "VALOR_ORIGINAL"],
  ["Valor Nuevo", "VALOR_NUEVO"],
  ["Usuario", "USER"]
];

const customModalStyles = {
  content: {
    top: "100px",
    width: "490px",
    padding: "0",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translateX(-50%)",
    borderRadius: 9,
  },
};

export const HistorialDeModificaciones = ({ rutConcesionario, token, isROImpersonate, callServiceHandler }) => {
  const tableRef = useRef();
  const [optionsEds, setOptionsEds] = useState([]);
  const [eds, setEds] = useState("");
  const [historial, setHistorial] = useState([]);
  const [resumen, setResumen] = useState([]);
  const [periodo, setPeriodo] = useState("");
  const [modalOpen, setModalOpen] = useState(false)
  const [updateDate, setUpdateDate] = useState('')
  
  const periodos = getAllPeriodos()

  useEffect(() => {
    getUpdateDate(setUpdateDate)
  }, [])

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (token && rutConcesionario) {
        getEdsPedidosActivos(callServiceHandler, rutConcesionario, setOptionsEds)
      }
    }
    else if(token){
      getEdsPedidosActivos(callServiceHandler, rutConcesionario, setOptionsEds)
    }
  }, [rutConcesionario, token]);

  useEffect(() => {
    if(eds && periodo && token)
      getHistorialModificaciones()
  }, [eds, periodo, token]);

  async function getHistorialModificaciones() {
    const url = process.env.REACT_APP_FLUCTUACIONES_API + 
      "/fluctuaciones/historial-modificaciones?ID_EDS=" + eds.value + 
      "&periodo=" + periodo.value

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      const tmpResumen = []
      const tmpHistorial = []
      result.data.forEach(function(mod, key){
        tmpHistorial.push(mod.modificacion)
        const tipoPedidoFound = tmpResumen.find((t) => t.tipo === mod.modificacion.TIPO_MODIFICACION)
        if(tipoPedidoFound){
          tipoPedidoFound.count++
        }
        else{
          tmpResumen.push({
            tipo: mod.modificacion.TIPO_MODIFICACION,
            count: 1
          })
        }
      })
      setResumen(tmpResumen)
      setHistorial(tmpHistorial)
    }
    else{
      setHistorial([])
      setResumen([])
    }
  }

  function openModal(){
    setModalOpen(true);
    scrollTo(0, 200)
    showGreyBackground()
  }

  function closeModal(){
    setModalOpen(false)
    hideGreyBackground()
  }


  function getResumenClassName(key, side){
    let classes = ''
    if(key === 0)
      classes += 'cell-border-top-' + side
    if(key === resumen.length - 1)
      classes += ' cell-border-bottom-' + side

    return classes
  }

  return (
    <div className="page ">
      <div className="view-title">Fluctuaciones</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Historial de modificaciones</div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandler={callServiceHandler}
      />
      <hr />
      <div className="historial-de-modificaciones">
        <div className="page-options">
          <div className="filters-container">
            <div className="filter">
              <div className="filter-title required-field">ESTACIÓN DE SERVICIO</div>
              <div className="filter-input">
                <Select
                  className="eds-select"
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={optionsEds}
                  components={{ SingleOption, IndicatorSeparator: () => null }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStyles}
                  onChange={(val) => setEds(val)}
                />
              </div>
            </div>

            <div className="filter">
              <div className="filter-title required-field">PERIODO</div>
              <div className="filter-input">
                <Select
                  className="eds-select"
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  value={periodo}
                  onChange={(e) => setPeriodo(e)}
                  options={periodos}
                  components={{ SingleOption, IndicatorSeparator: () => null }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStyles}
                />
              </div>
            </div>
            <button className="main-button main-button-small" onClick={openModal}>Ver Resumen</button>
          </div>

          <div className="actions">
            <PrintComponent 
              reff={tableRef}
              classes=""
              isROImpersonate={isROImpersonate}
            />
          
            <ExportExcel
              data={historial}
              labels={labels}
              isROImpersonate={isROImpersonate}
            />
          </div>
        </div>
        <table ref={tableRef} className="flutuaciones-table">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Tanque(s)</th>
              <th>Tipo de Modificación</th>
              <th>Valor Original/Propuesto</th>
              <th>Valor Nuevo</th>
              <th>Usuario</th>
            </tr>
          </thead>
          <tbody>
            {/* Horizontal  separator between head and body*/}
            <tr>
              <td className="horizontal-separator" colSpan={7} />
            </tr>
            { historial.map((mod, key) =>
              <tr className={key < historial.length - 1? "row-with-border": ""}>
                <td className={key === 0? "cell-border-top-left": ""}>{mod.FECHA}</td>
                <td>{mod.HORA}</td>
                <td>{mod.ID_TANQUES.join('-')}</td>
                <td>{mod.TIPO_MODIFICACION}</td>
                <td>{mod.VALOR_ORIGINAL}</td>
                <td>{mod.VALOR_NUEVO}</td>
                <td className={key === 0? "cell-border-top-right": ""}>{mod.USER}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        style={customModalStyles}
      >
        <div className="hdm-resumen">
          <img alt="imgResumen" className="close-modal" onClick={closeModal} src={closeIcon} />
          <div className="modal-info">
            <p className="flutuaciones-modal__title">
              <strong>Resumen Historial de modificaciones</strong>
            </p>
            <p className="flutuaciones-modal__description">
              EDS: {eds.value} - Periodo: {periodo.label}
            </p>
            <table
              ref={tableRef}
              className="flutuaciones-table hdm-resumen__table"
            >
              <thead>
                <tr>
                  <th>Tipo de Modificación </th>
                  <th>Modificación</th>
                </tr>
              </thead>
              <tbody>
                {/* Horizontal  separator between head and body*/}
                <tr>
                  <td className="horizontal-separator" colSpan={2} />
                </tr>
                { resumen.map((res, key) =>
                  <tr className={key < resumen.length - 1? "row-with-border": ""}>
                    <td className={getResumenClassName(key, 'left')}>{res.tipo}</td>
                    <td className={getResumenClassName(key, 'right')}>{res.count}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
};
