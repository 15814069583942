import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { parseBudat, getLocaleStringNumber } from "../utils/utils.js";
import { scrollTo } from "../utils/windows";
import { docModalStylesNew } from "../utils/styles.js";
import closeIcon from "../../assets/img/cross-icon.svg";

const FechaModal = (props) => {
  const displayModal = props.displayModal;
  const closeModal = props.closeModal;
  const distanceToTop = props.distanceToTop;
  const pedido = props.pedido;

  useEffect(() => {
    if (displayModal) {
      scrollTo(0, distanceToTop);
    }
  }, [displayModal]);

  const showContent = () => {
    return (
      <>
        <div className={`documento-info-modal`}>
          <div className="title">Detalle</div>
          <div className="subtitle">
            {pedido.ID_PEDIDO} - {pedido.TIPO_PEDIDO}
          </div>
          <div className="content">
            <table>
              <thead>
                <tr>
                  <th>Programación</th>
                  <th className="align-right">Fecha - Hora</th>
                </tr>
              </thead>
              <tr className="separator-table-row">
                <th colSpan="100%" />
              </tr>
              <tbody>
                <tr className="data">
                  <td className="align-left">Inicio de carga planificada</td>
                  <td className="align-right">{parseBudat(pedido.FECHA_ESTIMADA_CARGA)} - {pedido.HORA_ESTIMADA_CARGA} </td>
                </tr>
                <tr>
                  <td colSpan="100%" className="tdsep">
                    <hr></hr>
                  </td>
                </tr>
                <tr className="data">
                  <td className="align-left">Fecha de llegada programada</td>
                  <td className="align-right">{parseBudat(pedido.FECHA_LLEGADA_PROGRAMADA)} - {pedido.HORA_LLEGADA_PROGRAMADA}</td>
                </tr>
                <></>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <Modal
        isOpen={displayModal}
        onRequestClose={closeModal}
        style={docModalStylesNew(distanceToTop - 100)}
      >
        <button className="modal__close-btn" onClick={closeModal}>
          <img src={closeIcon} alt="close modal" />
        </button>
        {showContent()}
      </Modal>
    </div>
  );
};

export default FechaModal;
