import React, { useEffect, useState } from 'react';
import HeaderData from '../../HeaderData';
import Select from "react-select";
import { getPaginationItems, setInstalalaciones } from '../../utils/get';
import { getPeriodosSinceYearAndMonth, getUpdateDate } from '../../utils/monthNames';
import { SingleOption, customStylesTctTae, getExcelWithXlsx2, orderArray } from "../../utils/utils.js";
import Descarga from "../../../assets/img/descargar.svg"
import Imprimir from "../../../assets/img/imprimir.svg"
import Flecha_up from "../../../assets/img/Flecha_up.svg";
import Flecha_down from "../../../assets/img/Flecha_down.svg";
import redArrow from "../../../assets/img/cargo.svg";
import greenArrow from "../../../assets/img/abono.svg";
import drop from "../../../assets/img/COMBUSTIBLE.svg";
import TRXBoxData from '../../TRXBoxData';
import Pagination from 'react-js-pagination';

const TrxProducto = ({
  token,
  rutConcesionario,
  callServiceHandler,
  cabecera,
  instalaciones,
  isROImpersonate,
}) => {

  const [updateDate, setUpdateDate] = useState("");
  const [selectedEds, setSelectedEds] = useState();
  const [allEds, setAllEds] = useState([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState()
  const [allPeriodos, setAllPeriodos] = useState([]);
  const [anioActivacion, setAnioActivacion] = useState(2023);
  const [mesActivacion, setMesActivacion] = useState(3);
  const [selectedProducto, setSelectedProducto] = useState();

  const [transaccionesForExcel, setTransaccionesForExcel] = useState();
  const [labels, setLabels] = useState();

  const [activePage, setActivePage] = useState(1)
  const [paginatedData, setPaginatedData] = useState([])

  const [filteredData, setFilteredData] = useState();

  let productValue = {
    diesel: "diesel",
    bluemax: "bluemax",
    lubricante: "lubricante"
  }

  useEffect(() => {
    setFilteredData(diesel_info)
  }, []);

  useEffect(() => {
    setActivePage(1)
    if(filteredData?.length <= 15)
    setPaginatedData(filteredData)
    else
    setPaginatedData(filteredData?.slice(0, 15))
    setActivePage(1)
    console.log("fd",filteredData)
  }, [filteredData])

  function handlePageChange(pageNumber){
    getPaginationItems(diesel_info, pageNumber, setPaginatedData, setActivePage);
  }


  useEffect(() => {
    getUpdateDate(setUpdateDate);
  }, []);

  useEffect(() => {
    if (instalaciones !== "") {
      setInstalalaciones(instalaciones, setAllEds);
    }
  }, [instalaciones]);

  useEffect(() => {
    if (allEds.length === 1){
      setSelectedEds(allEds[0]);
    }
  }, [allEds]);

  useEffect(() => {
    if (anioActivacion && mesActivacion) {
      const newPeriodos = getPeriodosSinceYearAndMonth(anioActivacion, 
        mesActivacion);
      setAllPeriodos(newPeriodos);
      setSelectedPeriodo(newPeriodos[0]);
    }
  }, [anioActivacion, mesActivacion]);

  useEffect(() => {
    if (selectedProducto?.value === productValue.bluemax || selectedProducto?.value === productValue.lubricante) {
      setLabels({
        A: "Código Producto",
        B: "Descripción",
        C: "Saldo Incial",
        D: "Despacho",
        E: "Ventas",
        F: "Saldo Final",
        G: "Stock Asignado"
      })
      setTransaccionesForExcel(lub_blue_max_info.map(info=>{
        return(
          {
            A: info.codigo_prod,
            B: info.descripcion,
            C: info.saldo_inicial,
            D: info.despacho,
            E: info.ventas,
            F: info.saldo_final,
            G: info.stock
          }
        )
      }))
    }else{
      setLabels({
        A: "Fecha",
        B: "Número de Guía",
        C: "Despacho",
        D: "Ventas",
        E: "Saldo",
      })
      setTransaccionesForExcel(diesel_info.map(info=>{
        return(
          {
            A: info.fecha,
            B: info.nro_guia,
            C: info.despacho,
            D: info.ventas,
            E: info.saldo,
          }
        )
      }))
    }
  }, [selectedProducto]);

  function changeEds(e) {
    if (e !== selectedEds) {
      setSelectedEds(e);
    }
  }

  function changeSelectedPeriodo(e) {
    console.log(e)
    setSelectedPeriodo(e);
  }

  function changeProducto(e) {
    if (e !== selectedProducto) {
      setSelectedProducto(e);
    }
  }

  const bluemaxarray = [1]
  // const bluemaxarray = [1, 2]

  const orderTable = (filterkey, order, parse) => {
    const arr = orderArray(filterkey, filteredData, order, parse);
    setFilteredData(arr);
  };

  const lub_blue_max_info = [
    {
      codigo_prod: "00002",
      descripcion: "DVAC 1440/1",
      saldo_inicial: "7 Un.",
      despacho: "0 Un",
      ventas: "0 Un",
      saldo_final: "7 Un.",
      stock: "36 Un"
    },
    {
      codigo_prod: "00002",
      descripcion: "DVAC 1440/4",
      saldo_inicial: "5 Un.",
      despacho: "0 Un",
      ventas: "0 Un",
      saldo_final: "5 Un.",
      stock: "12 Un"
    },
    {
      codigo_prod: "00002",
      descripcion: "BLUEDEF/2,5",
      saldo_inicial: "-2 Un.",
      despacho: "0 Un",
      ventas: "0 Un",
      saldo_final: "-2 Un.",
      stock: "0 Un"
    },
    {
      codigo_prod: "00002",
      descripcion: "DVAC 1440/19",
      saldo_inicial: "20 Un.",
      despacho: "0 Un",
      ventas: "0 Un",
      saldo_final: "20 Un.",
      stock: "3 Un"
    },
    {
      codigo_prod: "00002",
      descripcion: "BLUEDEF/55",
      saldo_inicial: "0 Un.",
      despacho: "0 Un",
      ventas: "0 Un",
      saldo_final: "0 Un.",
      stock: "0 Un"
    },
  ]

  const diesel_info = [
    {
      fecha: "01/03/2023",
      nro_guia: "16999692",
      despacho: "200,00",
      ventas: "0,00",
      saldo: "0,00"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "16999784",
      despacho: "12.100,00",
      ventas: "0,00",
      saldo: "114.306,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "16999965",
      despacho: "25.000,00",
      ventas: "0,00",
      saldo: "151.406,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "",
      despacho: "0,00",
      ventas: "54.523,37",
      saldo: "139.306,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "17000167",
      despacho: "32.000,00",
      ventas: "0,00",
      saldo: "96.882,90"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "16999965",
      despacho: "25.000,00",
      ventas: "0,00",
      saldo: "151.406,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "",
      despacho: "0,00",
      ventas: "54.523,37",
      saldo: "139.306,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "17000167",
      despacho: "32.000,00",
      ventas: "0,00",
      saldo: "96.882,90"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "16999965",
      despacho: "25.000,00",
      ventas: "0,00",
      saldo: "151.406,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "",
      despacho: "0,00",
      ventas: "54.523,37",
      saldo: "139.306,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "17000167",
      despacho: "32.000,00",
      ventas: "0,00",
      saldo: "96.882,90"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "16999965",
      despacho: "25.000,00",
      ventas: "0,00",
      saldo: "151.406,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "",
      despacho: "0,00",
      ventas: "54.523,37",
      saldo: "139.306,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "17000167",
      despacho: "32.000,00",
      ventas: "0,00",
      saldo: "96.882,90"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "16999965",
      despacho: "25.000,00",
      ventas: "0,00",
      saldo: "151.406,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "",
      despacho: "0,00",
      ventas: "54.523,37",
      saldo: "139.306,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "17000167",
      despacho: "32.000,00",
      ventas: "0,00",
      saldo: "96.882,90"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "16999965",
      despacho: "25.000,00",
      ventas: "0,00",
      saldo: "151.406,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "",
      despacho: "0,00",
      ventas: "54.523,37",
      saldo: "139.306,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "17000167",
      despacho: "32.000,00",
      ventas: "0,00",
      saldo: "96.882,90"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "16999965",
      despacho: "25.000,00",
      ventas: "0,00",
      saldo: "151.406,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "",
      despacho: "0,00",
      ventas: "54.523,37",
      saldo: "139.306,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "17000167",
      despacho: "32.000,00",
      ventas: "0,00",
      saldo: "96.882,90"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "16999965",
      despacho: "25.000,00",
      ventas: "0,00",
      saldo: "151.406,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "",
      despacho: "0,00",
      ventas: "54.523,37",
      saldo: "139.306,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "17000167",
      despacho: "32.000,00",
      ventas: "0,00",
      saldo: "96.882,90"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "16999965",
      despacho: "25.000,00",
      ventas: "0,00",
      saldo: "151.406,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "",
      despacho: "0,00",
      ventas: "54.523,37",
      saldo: "139.306,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "17000167",
      despacho: "32.000,00",
      ventas: "0,00",
      saldo: "96.882,90"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "16999965",
      despacho: "25.000,00",
      ventas: "0,00",
      saldo: "151.406,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "",
      despacho: "0,00",
      ventas: "54.523,37",
      saldo: "139.306,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "17000167",
      despacho: "32.000,00",
      ventas: "0,00",
      saldo: "96.882,90"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "16999965",
      despacho: "25.000,00",
      ventas: "0,00",
      saldo: "151.406,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "",
      despacho: "0,00",
      ventas: "54.523,37",
      saldo: "139.306,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "17000167",
      despacho: "32.000,00",
      ventas: "0,00",
      saldo: "96.882,90"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "16999965",
      despacho: "25.000,00",
      ventas: "0,00",
      saldo: "151.406,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "",
      despacho: "0,00",
      ventas: "54.523,37",
      saldo: "139.306,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "17000167",
      despacho: "32.000,00",
      ventas: "0,00",
      saldo: "96.882,90"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "16999965",
      despacho: "25.000,00",
      ventas: "0,00",
      saldo: "151.406,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "",
      despacho: "0,00",
      ventas: "54.523,37",
      saldo: "139.306,27"
    },
    {
      fecha: "01/03/2023",
      nro_guia: "17000167",
      despacho: "32.000,00",
      ventas: "0,00",
      saldo: "96.882,90"
    },
  ]

  const infoForExcel = [
    {
      nombre: "Detalles",
      labels: labels,
      transacciones: transaccionesForExcel
    }
  ]

  const handleDownload = () => {
    getExcelWithXlsx2(infoForExcel, "detalle.xlsx")
  }

  return (
    <div className='transacciones-producto-container'>
      <div className="view-title">TCT TAE</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Transacciones por Producto</div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandler={callServiceHandler}
        cabecera={cabecera}
        actualizable={true}
      />
      {
        selectedProducto?.value === productValue.diesel &&
          <div className="cards-container">
            <TRXBoxData
              icon={redArrow}
              row1Title={"Total Despacho"}
              row2Amount={"60.000"}
            />
            <TRXBoxData
              icon={greenArrow}
              row1Title={"Total Despacho"}
              row2Amount={"60.000"}
            />
            <TRXBoxData
              icon={drop}
              row1Title={"Total Despacho"}
              row2Amount={"60.000"}
            />
            <TRXBoxData
              icon={greenArrow}
              row1Title={"Total Despacho"}
              row2Amount={"60.000"}
            />
          </div>
      }
      <hr/>
      <div className={`main-filters-container ${selectedProducto?.value === productValue.diesel? "w-1312" : "w-1088"}`}>
        <div className="filters-container">
          <div className="filter">
            <div className="filter-title required-field">
              ESTACIÓN DE SERVICIO
            </div>
            <div className="filter-input eds-select-container">
              <Select
                className="estacion-servicio-select"
                value={selectedEds}
                onChange={(e) => changeEds(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allEds}
                components={{
                  SingleOption,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccionar"
                styles={customStylesTctTae}
                width="192px"
              />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title required-field">
              PERIODO
            </div>
            <div className="filter-input periodo-select-container">
              <Select
                className="periodo-select"
                value={selectedPeriodo}
                onChange={(e) => changeSelectedPeriodo(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allPeriodos}
                components={{ SingleOption, IndicatorSeparator: () => null }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccionar"
                styles={customStylesTctTae}
                width="192px"
              />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title required-field">
              PRODUCTO
            </div>
            <div className="filter-input producto-select-container">
              <Select
                className="producto-select"
                value={selectedProducto}
                onChange={(e) => changeProducto(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={[
                  {
                    value: "diesel",
                    label: "Diesel"
                  },
                  {
                    value: "bluemax",
                    label: "Bluemax"
                  },
                  {
                    value: "lubricante",
                    label: "Lubricante"
                  },
                ]}
                components={{ SingleOption, IndicatorSeparator: () => null }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccionar"
                width="192px"
                styles={customStylesTctTae}
              />
            </div>
          </div>
          {
            selectedProducto?.value === productValue.diesel &&
              <>
                <div className="filter">
                  <div className="filter-title">
                    STOCK ASIGNADO
                  </div>
                  <div className="filter-input">
                    <input className='disabled-input stock-asignado-input' placeholder='50.000' type="text" />
                  </div>
                </div>
                <div className="filter">
                  <div className="filter-title">
                    TOTAL TCT
                  </div>
                  <div className="filter-input">
                    <input className='disabled-input total-tct-input' placeholder='9.597,74' type="text" />
                  </div>
                </div>
                <div className="filter">
                  <div className="filter-title">
                    TOTAL TAE
                  </div>
                  <div className="filter-input">
                    <input className='disabled-input total-tct-input' placeholder='422.340,00' type="text" />
                  </div>
                </div>
              </>
          }
        </div>
        {
          selectedProducto &&
            <div className="actions-button-container">
              {
                ((selectedProducto?.value === productValue.bluemax && bluemaxarray.length > 1) || selectedProducto?.value === productValue.lubricante) && 
                  <div className="imprimir-container alert-container-cierre-diario">
                    <img src={Imprimir}/>
                    <div className="alert-tooltip">
                      <div className="content">
                        Imprimir
                      </div>
                    </div>
                  </div>
              }
              <div onClick={handleDownload} className="descargar-container alert-container-cierre-diario">
                <img src={Descarga}/>
                <div className="alert-tooltip">
                  <div className="content">
                    Descargar detalle
                  </div>
                </div>
              </div>
            </div>
        }
      </div>
      {
        selectedProducto?.value === productValue.diesel &&
          <div className="diesel-table-container">
            <table className="diesel-table">
              <tr>
                <th>
                  <div className="th-data width-253">
                    <div className="th-text">
                      Fecha
                      <div className="arrows">
                        <img
                          onClick={() => orderTable("fecha", "asc", false)}
                          src={Flecha_up}
                        ></img>
                        <img
                          onClick={() => orderTable("fecha", "desc", false)}
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-253">
                    <div className="th-text">
                      N° de Guía
                      <div className="arrows">
                        <img
                          onClick={() => orderTable("nro_guia", "asc", false)}
                          src={Flecha_up}
                        ></img>
                        <img
                          onClick={() => orderTable("nro_guia", "desc", false)}
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-253">
                    <div className="th-text">
                      Despacho (Lts)
                      <div className="arrows">
                        <img
                          onClick={() => orderTable("despacho", "asc", false)}
                          src={Flecha_up}
                        ></img>
                        <img
                          onClick={() => orderTable("despacho", "desc", false)}
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-253">
                    <div className="th-text">
                      Ventas
                      <div className="arrows">
                        <img
                          onClick={() => orderTable("ventas", "asc", false)}
                          src={Flecha_up}
                        ></img>
                        <img
                          onClick={() => orderTable("ventas", "desc", false)}
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-253">
                    <div className="th-text">
                      Saldo (Lts)
                      <div className="arrows">
                        <img
                          onClick={() => orderTable("saldo", "asc", false)}
                          src={Flecha_up}
                        ></img>
                        <img
                          onClick={() => orderTable("saldo", "desc", false)}
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
              <tr className="table-separator-row-border-top">
                <td colSpan="100%" />
              </tr>
              <tbody>
                {
                  paginatedData &&
                    paginatedData.map(info=>{
                      return(
                        <tr className="data">
                          <td className="align-left">{info.fecha}</td>
                          <td className="align-left">{info.nro_guia}</td>
                          <td className="align-left">{info.despacho}</td>
                          <td className="align-left">{info.ventas}</td>
                          <td className="align-left">{info.saldo}</td>
                        </tr>
                      )
                    })
                }
                {/* <tr className="data">
                  <td className="align-left">01/03/2023</td>
                  <td className="align-left">16999692</td>
                  <td className="align-left">200,00</td>
                  <td className="align-left">0,00</td>
                  <td className="align-left">114.106,27</td>
                </tr>
                <tr className="data">
                  <td className="align-left">01/03/2023</td>
                  <td className="align-left">16999784</td>
                  <td className="align-left">12.100,00</td>
                  <td className="align-left">0,00</td>
                  <td className="align-left">114.306,27</td>
                </tr> */}
              </tbody>
            </table>
            <div className="pagination-container">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={15}
                totalItemsCount={diesel_info?.length}
                pageRangeDisplayed={5}
                onChange={e => handlePageChange(e)}
                hideFirstLastPages={true}
                linkClassPrev='prev'
                linkClassNext='next'
                prevPageText=''
                nextPageText=''
              />
            </div>
          </div>
      }
      {
        selectedProducto && selectedProducto?.value !== productValue.diesel &&
          <div className="lubricante-bluemax-table-container">
            <table className="lubricante-bluemax-table">
              <tr>
                <th>
                  <div className="th-data width-110">
                    <div className="th-text two-lines">
                      Código
                      <br/>
                      Producto
                      <div className="arrows mtop">
                        <img
                          src={Flecha_up}
                        ></img>
                        <img
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-364">
                    <div className="th-text">
                      Descripción
                      <div className="arrows">
                        <img
                          src={Flecha_up}
                        ></img>
                        <img
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-110">
                    <div className="th-text">
                      Saldo Inicial
                      <div className="arrows">
                        <img
                          src={Flecha_up}
                        ></img>
                        <img
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-110">
                    <div className="th-text">
                      Despacho
                      <div className="arrows">
                        <img
                          src={Flecha_up}
                        ></img>
                        <img
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-110">
                    <div className="th-text">
                      Ventas
                      <div className="arrows">
                        <img
                          src={Flecha_up}
                        ></img>
                        <img
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-110">
                    <div className="th-text">
                      Saldo Final
                      <div className="arrows">
                        <img
                          src={Flecha_up}
                        ></img>
                        <img
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-110">
                    <div className="th-text two-lines">
                      Stock
                      <br/>
                      Asignado
                      <div className="arrows mtop">
                        <img
                          src={Flecha_up}
                        ></img>
                        <img
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th>
              </tr>
              <tr className="table-separator-row-border-top">
                <td colSpan="100%" />
              </tr>
              <tbody>
                {
                  lub_blue_max_info &&
                    lub_blue_max_info.map(info=>{
                      return(
                        <tr className="data">
                          <td className="align-left">{info.codigo_prod}</td>
                          <td className={`align-left ${bluemaxarray.length > 1 && "table-link"}`}>{info.descripcion}</td>
                          <td className="align-left">{info.saldo_inicial}</td>
                          <td className="align-left">{info.despacho}</td>
                          <td className="align-left">{info.ventas}</td>
                          <td className="align-left">{info.saldo_final}</td>
                          <td className="align-left">{info.stock}</td>
                        </tr>
                      )
                    })
                }
                {/* <tr className="data">
                  <td className="align-left">00002</td>
                  <td className={`align-left ${bluemaxarray.length > 1 && "table-link"}`}>DVAC 1440/1</td>
                  <td className="align-left">7 Un.</td>
                  <td className="align-left">0 Un.</td>
                  <td className="align-left">0 Un.</td>
                  <td className="align-left">7 Un.</td>
                  <td className="align-left">36 Un.</td>
                </tr>
                <tr className="data">
                  <td className="align-left">00002</td>
                  <td className={`align-left ${bluemaxarray.length > 1 && "table-link"}`}>DVAC 1440/4</td>
                  <td className="align-left">5 Un.</td>
                  <td className="align-left">0 Un.</td>
                  <td className="align-left">0 Un.</td>
                  <td className="align-left">5 Un.</td>
                  <td className="align-left">12 Un.</td>
                </tr> */}
              </tbody>
            </table>
          </div>
      }
    </div>
  );
}

export default TrxProducto;
