import React from "react";
import { Chart } from "react-google-charts";
import greybullet from "../../assets/img/grey-bullet.svg";
import greenbullet from "../../assets/img/green-bullet.svg";
import redbullet from "../../assets/img/red-bullet.svg";
import yellowbullet from "../../assets/img/yellow-bullet.svg";
import bluebullet from "../../assets/img/blue-bullet.svg";
import warningOn from "../../assets/img/Warning_on.svg";
import warningOff from "../../assets/img/Warning_off.svg";
import { getLocaleStringNumber } from "../utils/utils";
import EstadoCrediticio from "./EstadoCrediticio";

const CreditoCard = (props) => {
  const estadoCrediticio = props.estadoCrediticio;

  return (
    <div className="estado-crediticio">
      <div className="info1">
        <div className="numbers">
            <div className="credito">
                <div className="titlec"> <img src={greenbullet}></img>Crédito disponible</div>
                <div className="montoc">{`$ ${getLocaleStringNumber(estadoCrediticio.CREDITO_DISPONIBLE)}`}</div>
            </div>
          <div className="partida">
            <div className="title">
               Abonos
            </div>
            <div className="monto">{`$ ${getLocaleStringNumber(estadoCrediticio.ABONOS)}`}</div>
          </div>
          <div className="partida">
            <div className="title">Límite de crédito</div>
            <div className="monto">{`$ ${getLocaleStringNumber(estadoCrediticio.LIMITE_CREDITO)}`}</div>
          </div>
        <hr className="sep3"></hr>
          <div className="partida">
            <div className="title">Total crédito asig.</div>
            <div className="monto"><b>{`$ ${getLocaleStringNumber(estadoCrediticio.TOTAL_CREDITO)}`}</b></div>
          </div>
        </div>
        <div className="chart">
          <Chart
            width={"100px"}
            height={"100px"}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
              ["Partida", "Valor"],
              ["Partidas Vencidas", estadoCrediticio.PARTIDAS_VENCIDAS<0?0:estadoCrediticio.PARTIDAS_VENCIDAS],
              ["Partidas Abiertas", estadoCrediticio.PARTIDAS_ABIERTAS<0?0:estadoCrediticio.PARTIDAS_ABIERTAS],
              ["Partidas en Curso", estadoCrediticio.PARTIDAS_EN_CURSO<0?0:estadoCrediticio.PARTIDAS_EN_CURSO],
              ["Stock Consignado", estadoCrediticio.STOCK_CONSIGNADO<0?0:estadoCrediticio.STOCK_CONSIGNADO],
              ["Credito Disponible", estadoCrediticio.CREDITO_DISPONIBLE<0?0:estadoCrediticio.CREDITO_DISPONIBLE]
            ]}
            options={{
              legend: "none",
              is3D: false,
              pieHole: 0.68,
              tooltip: {
                trigger: "none",
              },
              pieSliceBorderColor: "transparent",
              pieSliceText: "none",
              chartArea: { width: "85%", height: "85%" },
              slices: {
                0: { color: '#ea212e' },
                1: { color: '#ffb906' },
                2: { color: '#0e69af' },
                3: { color: '#7c97ab' },
                4: { color: '#49d78e' },
              }
            }}
            rootProps={{ "data-testid": "3" }}
          />
        </div>
      </div>
      <div className="info2">
        <div className="numbers">
          <div className="partida">
            <div className="title">
              <img src={redbullet}></img> Partidas Vencidas
            </div>
            <div className="monto">{`$ ${getLocaleStringNumber(estadoCrediticio.PARTIDAS_VENCIDAS)}`}</div>
          </div>
          <div className="partida">
            <div className="title"><img src={yellowbullet}></img>Partidas No Venc.</div>
            <div className="monto">{`$ ${getLocaleStringNumber(estadoCrediticio.PARTIDAS_ABIERTAS)}`}</div>
          </div>
          <div className="partida">
            <div className="title"><img src={bluebullet}></img>Pedidos en Curso</div>
            <div className="monto">{`$ ${getLocaleStringNumber(estadoCrediticio.PARTIDAS_EN_CURSO)}`}</div>
          </div>
          <div className="partida">
            <div className="title"><img src={greybullet}></img>Inventario Consig.</div>
            <div className="monto">{`$ ${getLocaleStringNumber(estadoCrediticio.STOCK_CONSIGNADO)}`}</div>
          </div>
          <hr className="sep5"></hr>
          <div className="partida">
            <div className="title">Crédito Comprometido</div>
            <div className="monto"><b>{`$ ${getLocaleStringNumber(estadoCrediticio.CREDITO_COMPROMETIDO)}`}</b></div>
          </div>

        </div>
        <div className="warning">
            <img src={estadoCrediticio.CHEQUES_RECHAZADOS==="Si"?warningOn:warningOff}></img>
            <p>{estadoCrediticio.CHEQUES_RECHAZADOS==="Si"?"Cheques Rechazados":<br></br>}</p>
        </div>
      </div>
    </div>
  );
};

export default CreditoCard;
