import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { scrollTo } from "../utils/windows";
import { docModalStylesNew } from "../utils/styles.js";
import closeIcon from "../../assets/img/cross-icon.svg";

const MantenimientoModal = (props) => {
  const displayModal = props.displayModal;
  const closeModal = props.closeModal;
  const distanceToTop = props.distanceToTop;
  const orden = props.orden;
  const tipo = props.tipo;
  const openFile = props.openFile;
  const [errorMsg, setErrorMsg] = useState(false); 

  useEffect(() => {
    if (displayModal) {
      scrollTo(0, distanceToTop)
    }
  }, [displayModal]);

  async function openFileOrDisplayError(o, key) {
    const error = await openFile(o);
    if (error) {
      setErrorMsg(key);
    }
  }

  return (
    <div>
      <Modal
        isOpen={displayModal}
        onRequestClose={closeModal}
        style={docModalStylesNew(distanceToTop - 100)}
      >
        <button className="modal__close-btn" onClick={closeModal}>
          <img src={closeIcon} alt="close modal" />
        </button>
        <div className={`documento-info-modal mantenimiento`}>
          <div className="title">Adjuntos</div>
          <div className="subtitle">
            {tipo==="orden" && <>Nº de Orden {orden.NUMERO_ORDEN?.substring(4)}</>}
            {tipo==="aviso" && <>Nº de Aviso {orden.NUMERO_AVISO?.substring(4)}</> }
          </div>
          <div className="content">
            <table>
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th className="align-left">Nombre</th>
                </tr>
              </thead>
              <tr className="separator-table-row">
                <th colSpan="100%" />
              </tr>
              <tbody>
                {orden.ADJUNTOS?.map((o, key) => {
                  return (
                    <>
                      <tr className="data" key={key}>
                        <td className="align-left">{o.tipo == "O" ? "Orden" : "Aviso"}</td>
                        <td className="align-left">
                          <a className="cursor-pointer" onClick={()=>openFileOrDisplayError(o, key)}>{o.nombre}</a>
                          {errorMsg === key && <div className="single-option-label warning-message">Archivo adjunto no encontrado</div>}
                        </td>
                      </tr>
                      {orden.ADJUNTOS.length-1!==key && <>
                        <tr>
                        <td colSpan="100%" className="tdsep">
                        <hr></hr>
                        </td>
                      </tr>
                      </>}
                    </>
                  );
                })}
              </tbody>
              <tr className="separator-table-row">
                <th colSpan="100%" />
              </tr>
             
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MantenimientoModal;
