// REACT
import React, { useEffect } from 'react';
import { useState } from 'react';
import Select from "react-select";

// COMPONENTES
import CustomModal from '../../CustomModal.js';

// UTILS
import { SingleOption, customStylesTctTae } from "../../utils/utils.js";

// ASSETS
import greenCheck from "../../../assets/img/Check_green.svg"

const SolicitudAutorizacionGuiaManual = (
  {
    nombreRut,
    rutConcesionario,
    token,
    selectedEds,
    setDisplayConsultarConsumo, 
    setDisplaySolicitudAutorizacion,
    selected_TCT_TAE_Product,
    callServiceHandler,
    productoTarjetaCode,
    productoTarjeta,
    numeroTarjeta,
    codigoCliente,
    cliente,
    maxGuia,
    rutCliente,
    codigoVerificador,
    ordenesCompra
  }
) => {

  const [direccion, setDireccion] = useState("");
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedComuna, setSelectedComuna] = useState();
  const [selectedMotivo, setSelectedMotivo] = useState();
  const [selectedProductoAutorizacion, setSelectedProductoAutorizacion] = useState();
  const [selectedTipoDeVenta, setSelectedTipoDeVenta] = useState();
  const [selectedTableProducto, setSelectedTableProducto] = useState();
  const [selectedOrdenDeCompra, setSelectedOrdenDeCompra] = useState();
  const [displaySuccessModal, setDisplaySuccessModal] = useState(false);
  const [isAutorizada, setIsAutorizada] = useState(false);

  const [precioTable, setPrecioTable] = useState(1);
  const [litrosTable, setLitrosTable] = useState(null);
  const [montoTable, setMontoTable] = useState(0);
  const [montoMaximo, setMontoMaximo] = useState();

  const [allRegiones, setAllRegiones] = useState([]);
  const [allComunas, setAllComunas] = useState([]);
  const [allMotivos, setAllMotivos] = useState([]);
  const [allProductos, setAllProductos] = useState([]);
  const [allTableProductos, setAllTableProductos] = useState([]);

  const [montoError, setMontoError] = useState(false);

  const [terminal, setTerminal] = useState();

  const [isNotFilled, setIsNotFilled] = useState(true);

  const [codigoAutorizacion, setCodigoAutorizacion] = useState();
  const [mensajeRespuesta, setMensajeRespuesta] = useState();
  const [numeroGuia, setNumeroGuia] = useState();

  const [flete, setFlete] = useState(0);

  const [montoTotal, setMontoTotal] = useState(0);

  const [fleteExist, setFleteExist] = useState(false);

  const [autorizadaError, setAutorizadaError] = useState(false);

  const [subproductos, setSubproductos] = useState();

  const [codigoTable, setCodigoTable] = useState("--");

  const [selectedOrdenCompra, setSelectedOrdenCompra] = useState();

  useEffect(() => {
    if (maxGuia >= 999999999) {
      setMontoMaximo("Sin Límites")
    }else{
      setMontoMaximo(maxGuia)
    }
  }, [maxGuia]);

  useEffect(() => {
    if (productoTarjetaCode && token) {
      getProductos(productoTarjetaCode)
    }
  }, [productoTarjetaCode, token, selected_TCT_TAE_Product]);

  useEffect(() => {
    if (token) {
      getRegiones()
    }
  }, [token]);

  useEffect(() => {
    if (selected_TCT_TAE_Product) {
      getMotivos()
    }
  }, [selected_TCT_TAE_Product]);

  useEffect(() => {
    if (selectedProductoAutorizacion?.value === productosValues.bluemax && selected_TCT_TAE_Product?.value === "TAE") {
        setSelectedTipoDeVenta({value:"envasado", label: "Envasado"})
    }
  }, [selectedProductoAutorizacion]);

  useEffect(() => {
    getTiposEntrega()
  }, []);

  const getTiposEntrega = async () => {
    const url =
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-tipos-entrega";

    let response = await callServiceHandler(url, "GET");

    console.log("tipos entrega", response.data)

  }

  const getSubProductos = async (productoCode) =>{
    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-subproductos?CODPRODUCTO="+ productoCode
    +"&IDEDS=" + parseInt(selectedEds.value);

    let response = await callServiceHandler(url, "GET");
    
    console.log("subprod",response.data.subproductos)

    let subproductos = response.data.subproductos.map(subproducto=>{
      return({value:subproducto.codigo, label:subproducto.descripcion, precio:subproducto.precio, unidad: subproducto.unidad})
    })

    console.log("new productos", subproductos)

    
    
    if(productoCode === "005" && selected_TCT_TAE_Product.value === "TAE"){
      subproductos = subproductos.filter(prod =>prod.unidad !== "LTS")
      console.log("el new prod de tipo de venta", subproductos)
      setAllTableProductos(subproductos)
    }
    
    if (subproductos.length === 1) {
      setSelectedTableProducto(subproductos[0])
      setPrecioTable(subproductos[0].precio)
      setCodigoTable(subproductos[0].value)
    }

    setSubproductos(subproductos)

    setAllTableProductos(subproductos)
    // if (condition) {
      
    // }
  }

  const getProductos = async (productoTarjetaCode) =>{
    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-productos?PRODUCTOTARJETA="+ productoTarjetaCode
    +"&PRODUCTO="+selected_TCT_TAE_Product?.value;

    let response = await callServiceHandler(url, "GET");
    
    console.log("productosss",response.data.productos)

    let productos = response.data.productos.map(producto=>{
      return({value:producto.cod_producto, label:(producto.producto === "AdBlue"?"Bluemax":producto.producto)})
    })

    console.log("new productos", productos)

    setAllProductos(productos)
  }

  const getMotivos = async () => {
    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-motivos?TIPOPRODUCTO="+ selected_TCT_TAE_Product?.value; 

    let response = await callServiceHandler(url, "GET");

    console.log("motivos", response.data.motivos)

    let motivos = response.data.motivos.map(motivo=>{
      return({value:motivo.cod_motivo, label:motivo.motivo})
    })

    console.log("new motivos", motivos)

    setAllMotivos(motivos)
    
  }

  const getRegiones = async () => {
    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-regiones"

    let response = await callServiceHandler(url, "GET");

    console.log("regiones", response.data.regiones)
    let regiones = response.data.regiones.map(region=>{
      return({value:region.cod_region,label:region.region})
    })
    console.log("new regiones", regiones)

    setAllRegiones(regiones)
  }

  const getComunas = async (cod_region) => {
    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-comunas?CODREGION="+cod_region;

    let response = await callServiceHandler(url, "GET");

    console.log("comunas", response.data.comunas)

    console.log("comunas", response.data.comunas)
    let comunas = response.data.comunas.map(comuna=>{
      return({value:comuna.cod_comuna,label:comuna.comuna})
    })
    console.log("new comunas", comunas)

    setAllComunas(comunas)
    
  }


  const getOrdenCompra = async () => {

    let eds = parseInt(selectedEds.value).toString();


    const url =
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-ordenes-compra?RUTCLIENTE="+rutCliente
    +"&CODCLIENTE="+codigoCliente
    +"&IDEDS="+eds

    let response = await callServiceHandler(url, "GET");

    console.log("ORDEN DE COMPRA", response.data)

  }

  const getFlete = async () => {

    let eds = parseInt(selectedEds.value).toString();

    const url =
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-flete?RUT="+rutCliente
    +"&IDEDS="+ eds;

    let response = await callServiceHandler(url, "GET");
    
    console.log("flete", response.data)

    if (response.data.flete > 0) {
      setFleteExist(true)
      setFlete(response.data.flete)
    }else{
      setFlete(response.data.flete)
    }

  }

  useEffect(() => {
    if (rutCliente && selectedEds && token) {
      getFlete()
      getOrdenCompra()
    }
  }, [rutCliente,selectedEds, token]);

  useEffect(() => {
    setMontoTotal(montoTable+flete)
  }, [flete, montoTable]);

  useEffect(() => {
    if (montoTotal) {
      console.log("monto tot", montoTotal)
    }
  }, [montoTotal]);

  useEffect(() => {
    if (montoTable) {
      console.log("monto tab", montoTable)
    }
  }, [montoTable]);

  useEffect(() => {
    setFleteExist(false)
    setSelectedTableProducto("")
  }, [selectedProductoAutorizacion]);

  useEffect(() => {
    if (ordenesCompra) {
      if (ordenesCompra.length === 1) {
        setSelectedOrdenCompra(ordenesCompra[0])
      }
    }
  }, [ordenesCompra]);

  let productosValues = {
    diesel: "001",
    lubricantes: "003",
    bluemax: "005"
  }

  let tipoVentaValues = {
    granel: "granel",
    envasado: "envasado",
  }

  function changeRegion(e) {
    if (e !== selectedRegion) {
      setSelectedRegion(e);
      getComunas(e.value)
    }
  }
  
  function changeComuna(e) {
    if (e !== selectedComuna) {
      setSelectedComuna(e);
    }
  }
  
  function changeMotivo(e) {
    if (e !== selectedMotivo) {
      setSelectedMotivo(e);
    }
  }
  
  function changeProductoAutorizacion(e) {
    if (e !== selectedProductoAutorizacion) {
      setPrecioTable(1)
      setCodigoTable("--")
      setLitrosTable()
      setMontoTable(0)
      setSelectedProductoAutorizacion(e);
      getSubProductos(e.value)
    }
  }

  useEffect(() => {
    setPrecioTable()
    setCodigoTable("--")
    if (selectedTipoDeVenta && subproductos) {
      let newSubProductos
      console.log("prod tip de venta", subproductos)
      newSubProductos = subproductos.filter(prod => selectedTipoDeVenta.value === "envasado"?prod.unidad !== "LTS":prod.unidad ==="LTS")
      console.log("el new prod de tipo de venta", newSubProductos)
      console.log("subproductos actualizados", newSubProductos)
      setAllTableProductos(newSubProductos)
      setPrecioTable(newSubProductos[0].precio)
      setCodigoTable(newSubProductos[0].value)
      setSelectedTableProducto(newSubProductos[0])
    }
  }, [selectedTipoDeVenta]);

  
  function changeTipoDeVenta(e) {
    if (e !== selectedTipoDeVenta) {
      setSelectedTipoDeVenta(e);
    }
  }
  
  function changeTableProductoSelect(e) {
    if (e !== selectedTableProducto) {
      setPrecioTable(1)
      setCodigoTable("--")
      setLitrosTable()
      setMontoTable(0)
      setSelectedTableProducto(e);
      setPrecioTable(e.precio)
      setCodigoTable(e.value)
    }
  }

  function changeOrdenDeCompra(e) {
    if (e !== selectedOrdenDeCompra) {
      setSelectedOrdenDeCompra(e);
    }
  }

  function changeOrdenCompra(e) {
    if (e !== selectedOrdenCompra) {
      setSelectedOrdenCompra(e);
    }
  }

  const openSuccessModal = () => {
    setDisplaySuccessModal(true)
  }

  const closeSuccessModal = () => {
    setDisplaySuccessModal(false)
    setIsAutorizada(true)
  }

  const showConsultTable = () =>{
    setDisplaySolicitudAutorizacion(false)
    setDisplayConsultarConsumo(true)
  }

  const autorizar = async () => {

    let body

    if (selected_TCT_TAE_Product.value === "TAE") {
      body = {
        id_eds: parseInt(selectedEds.value).toString(),
        codigo_cliente: parseInt(codigoCliente.split('-')[0]).toString(),
        numero_tarjeta: numeroTarjeta,
        cod_producto: selectedProductoAutorizacion.value,
        precio: parseInt(precioTable),
        rut:rutConcesionario, 
        nombre_rut: nombreRut,
        direccion: direccion,
        cod_comuna: selectedComuna.value,
        cod_motivo: selectedMotivo.value,
        // monto: parseInt(montoTable),
        monto: Math.ceil(montoTotal),
        // monto: selectedTableProducto.value === "granel" || selectedProductoAutorizacion.value === "001" ? montoTable.toFixed(2):parseInt(montoTable),
        // monto: selectedProductoAutorizacion.value === "001" ? parseFloat(montoTable).toFixed(2):parseInt(montoTable),
        // unidad: parseInt(litrosTable),
        unidad: (selectedProductoAutorizacion?.value === "001" || selectedTipoDeVenta?.value === "granel") ? parseFloat(litrosTable.replace(",", ".")).toFixed(2):parseInt(litrosTable),
        cod_subproducto: selectedTableProducto.value,
        flete: parseInt(flete),
        orden_compra: selectedOrdenCompra ? selectedOrdenCompra.value : ""
      }
    }else{
      body = {
        id_eds: parseInt(selectedEds.value).toString(),
        codigo_cliente: parseInt(codigoCliente.split('-')[0]).toString(),
        numero_tarjeta: numeroTarjeta,
        cod_producto: selectedProductoAutorizacion.value,
        precio: parseInt(precioTable),
        rut:rutConcesionario, 
        nombre_rut: nombreRut,
        direccion: "",
        cod_comuna: "",
        cod_motivo: selectedMotivo.value,
        monto: Math.ceil(montoTotal),
        // unidad: parseInt(litrosTable),
        unidad: (selectedProductoAutorizacion?.value === "001" || selectedTipoDeVenta?.value === "granel") ? parseFloat(litrosTable.replace(",", ".")).toFixed(2):parseInt(litrosTable),
        cod_subproducto: selectedTableProducto.value,
        flete: parseInt(flete)
      }
    }
    console.log(body)

    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/autorizar-consumo";
    

    let response = await callServiceHandler(url, "POST", JSON.stringify(body));

    console.log("autorizar", response)

    if (response.data.error === "") {
      setAutorizadaError(false)
      setCodigoAutorizacion(response.data.codigo_autorizacion)
      setMensajeRespuesta(response.data.mensaje)
      setNumeroGuia(response.data.numero_guia)
      
      openSuccessModal()
    }else{
      // openErrorModal()
      setAutorizadaError(true)
      setMensajeRespuesta(response.data.mensaje)
    }


  }
  

  

  const getMontoLitros = (e, existMontoMaximo=false, montoMaximo) => {
    let unidadesTranform = litrosTable.replace(",", ".")
    if (existMontoMaximo) {
      if (e.target.name === "litros-table") {
        if (unidadesTranform*precioTable > montoMaximo) {
          console.log("ERROR")
          setMontoTable(unidadesTranform*precioTable)
          setMontoError(true)
        }else{
          setMontoTable(unidadesTranform*precioTable)
          setMontoError(false)
        }
      }else{
        if (montoTable>montoMaximo) {
          console.log("ERROR")
          setMontoError(true)
          setLitrosTable(montoTable/precioTable)
        }else{
          setLitrosTable(montoTable/precioTable)
          setMontoError(false)
        }
      }
    }else{
      if (e.target.name === "litros-table") {
        setMontoTable(unidadesTranform*precioTable)
        setMontoError(false)
      }else{
        setLitrosTable(montoTable/precioTable)
        setMontoError(false)
      }
    }
  }

  const handlePressOnKey = (e) => {
    // console.log(e.key)
    if (e.key === "Enter") {
      if (selected_TCT_TAE_Product?.value==="TAE") {
        getMontoLitros(e, false)
      }else{
        getMontoLitros(e, true, montoMaximo)
      }
    }
  }

  const handleOnBlur = (e) => {
    if (selected_TCT_TAE_Product?.value==="TAE") {
      getMontoLitros(e, false)
    }else{
      getMontoLitros(e, true, montoMaximo)
    }
  }
  
  useEffect(() => {
    console.log(selectedTipoDeVenta)
  }, [selectedTipoDeVenta]);

  useEffect(() => {
    if (selected_TCT_TAE_Product?.value === "TAE") {
      if (direccion && selectedRegion && selectedComuna && selectedMotivo && selectedProductoAutorizacion && litrosTable && montoTable && direccion !== "" && litrosTable !== "" && montoTable !== 0) {
        setIsNotFilled(false)
        if (ordenesCompra.length >=1 && selectedOrdenCompra) {
          setIsNotFilled(false)
        }else{
          setIsNotFilled(true)
        }
      }else{
        setIsNotFilled(true)
      }
    }else{
      if (selectedMotivo && selectedProductoAutorizacion && litrosTable && montoTable && litrosTable !== "" && montoTable !== 0 && terminal && terminal !== "") {
        setIsNotFilled(false)
      }else{
        setIsNotFilled(true)
      }
    }
  }, [
      direccion,
      selectedRegion,
      selectedComuna,
      selectedMotivo, 
      selectedProductoAutorizacion,
      litrosTable,
      montoTable,
      terminal,
      selectedOrdenCompra
  ]);

    

    const cleanFields = () => {
      setIsNotFilled(true)
      setDireccion("")
      setSelectedRegion(null)
      setSelectedComuna(null)
      setSelectedMotivo(null)
      setSelectedProductoAutorizacion(null)
      setSelectedTipoDeVenta(null)
      setSelectedTableProducto(null)
      setSelectedOrdenDeCompra(null)
      setLitrosTable("")
      setMontoTable(0)
      setTerminal("")
      setMontoTotal(0)
      setFlete(0)
      setFleteExist(false)
      setAllComunas([])
      setSelectedOrdenCompra(null)
    }


  return (
    <div className='solicitud-autorizacion-guia-manual-container'>
      <div className="title-container">
        <div className="title">
          <div className="main-title">
            Solicitud de Autorización para Guía Manual
          </div>
          <div className="sub-title">
            {/* ABASTIBLE S.A. - 91.806.000-6 */}
            {cliente} - {rutCliente}-{codigoVerificador}
          </div>
        </div>
        <button className='main-button hallow thin' onClick={showConsultTable}>Volver</button>
      </div>
      <div className="responsable-container">
        <div className="filters-container">
          <div className='filter'>
            <div>
              <div className="filter-title">
                RESPONSABLE G.M.
              </div>
              <div className='filter-input nombre-cliente-disabled-input'>
                <input className='disabled-input responsable-gm-input' disabled="true" type="text" placeholder={`${rutConcesionario} - ${nombreRut}`} />
                {/* <input className='disabled-input' disabled="true" type="text" placeholder={"11.111.111 - k   CONAN MOTTI"} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="solicitud-separator">
        <div class="text">DATOS CLIENTE</div>
        <div class="line"></div>
      </div>
      <div className="datos-cliente-container">
        <div className="filters-container">
          <div className="filter">
            <div className="filter-title">
              CÓDIGO CLIENTE
            </div>
            <div className="filter-input">
              <input className='disabled-input cliente-input' disabled="true" type="text" placeholder={`${codigoCliente} ${cliente}`} />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title">
              TARJETA
            </div>
            <div className="filter-input">
              <input className='disabled-input' disabled="true" type="text" placeholder={numeroTarjeta} />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title">
              PRODUCTOS
            </div>
            <div className="filter-input">
              <input className='disabled-input' disabled="true" type="text" placeholder={productoTarjeta} />
            </div>
          </div>
        </div>
      </div>
      <div className="solicitud-separator">
        <div class="text">DATOS VENTA</div>
        <div class="line"></div>
      </div>
      <div className="datos-venta-container">
        <div className="first-row">
          <div className="filters-container">
            <div className="filter">
              <div className="filter-title">
                MONTO MÁXIMO GM
              </div>
              <div className="filter-input">
                <input className='disabled-input' disabled="true" type="text" placeholder={montoMaximo}/>
              </div>
            </div>
            <div className="filter">
              <div className="filter-title">
                FECHA AUTORIZACIÓN
              </div>
              <div className="filter-input">
                <input className='disabled-input' disabled="true" type="text" placeholder={"02/03/2023"}/>
              </div>
            </div>
            <div className="filter">
              <div className="filter-title">
                ESTACIÓN DE SERVICIO
              </div>
              <div className="filter-input">
                <input className='disabled-input estacion-de-servicio-input' disabled="true" type="text" placeholder={selectedEds.label}/>
              </div>
            </div>
            <div className="filter">
              <div className="filter-title required-field">
                TERMINAL
              </div>
              <div className="filter-input">
                {/* <input className='disabled-input terminal-input' type="text" placeholder={"1"}/> */}
                <input 
                  value={terminal}
                  onChange={e=>setTerminal(e.target.value)}
                  className={`${selected_TCT_TAE_Product?.value === "TAE" ? "disabled-input" : "enabled-input"} terminal-input`} 
                  type="text" 
                  placeholder={selected_TCT_TAE_Product?.value === "TAE" ? "1" : ""}
                  disabled={selected_TCT_TAE_Product?.value === "TAE"}
                />
              </div>
            </div>
          </div>
        </div>
        {
          selected_TCT_TAE_Product?.value === "TAE" &&
            <div className="second-row">
              <div className="filters-container">
                <div className="filter">
                  <div className="filter-title required-field">
                    DIRECCIÓN
                  </div>
                  <div className="filter-input">
                    <input 
                      // className='enabled-input direccion-input'  
                      className={`${isAutorizada?"disabled-input":"enabled-input"} direccion-input`}  
                      type="text"
                      value={direccion}
                      onChange={e=>setDireccion(e.target.value)}
                    />
                  </div>
                </div>
                <div className="filter">
                  <div className="filter-title required-field">
                    REGIÓN
                  </div>
                  <div className="filter-input region-select selector">
                    {
                      isAutorizada ?
                        <input 
                          className={`${isAutorizada?"disabled-input":"enabled-input"} region-select`}  
                          type="text"
                          value={selectedRegion?.label}
                        />
                      :
                        <Select
                          className="region-select"
                          value={selectedRegion}
                          onChange={(e) => changeRegion(e)}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          options={allRegiones}
                          components={{
                            SingleOption,
                            IndicatorSeparator: () => null,
                          }}
                          isClearable={false}
                          autosize={false}
                          placeholder="Seleccionar"
                          styles={customStylesTctTae}
                          width="192px"
                        />
                    }
                  </div>
                </div>
                <div className="filter">
                  <div className="filter-title required-field">
                    COMUNA
                  </div>
                  <div className="filter-input selector">
                    {
                      isAutorizada ? 
                        <input 
                          className={`${isAutorizada?"disabled-input":"enabled-input"} region-select`}  
                          type="text"
                          value={selectedComuna?.label}
                        />
                      :
                        <Select
                          className="comuna-select"
                          value={selectedComuna}
                          onChange={(e) => changeComuna(e)}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          options={allComunas}
                          components={{
                            SingleOption,
                            IndicatorSeparator: () => null,
                          }}
                          isClearable={false}
                          autosize={false}
                          placeholder="Seleccionar"
                          styles={customStylesTctTae}
                          width="192px"
                        />
                    }
                  </div>
                </div>
              </div>
            </div>
        }
        <div className="third-row">
          <div className="filters-container">
            <div className="filter">
              <div className="filter-title required-field">
                MOTIVO
              </div>
              <div className="input-filter motivo-select selector">
                {
                  isAutorizada ? 
                    <input 
                      className={`${isAutorizada?"disabled-input":"enabled-input"} motivo-select`}  
                      type="text"
                      value={selectedMotivo?.label}
                    />
                  :
                    <Select
                      className="motivo-select"
                      value={selectedMotivo}
                      onChange={(e) => changeMotivo(e)}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      options={allMotivos}
                      components={{
                        SingleOption,
                        IndicatorSeparator: () => null,
                      }}
                      isClearable={false}
                      autosize={false}
                      placeholder="Seleccionar"
                      styles={customStylesTctTae}
                      width="192px"
                    />
                }
              </div>
            </div>
            <div className="filter">
              <div className="filter-title required-field">
                PRODUCTO
              </div>
              <div className="input-filter producto-select selector">
                {
                  isAutorizada?
                    <input 
                      className={`${isAutorizada?"disabled-input":"enabled-input"} producto-select`}  
                      type="text"
                      value={selectedProductoAutorizacion?.label}
                    />
                  :
                    <Select
                      className="producto-select"
                      value={selectedProductoAutorizacion}
                      onChange={(e) => changeProductoAutorizacion(e)}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      options={allProductos}
                      components={{
                        SingleOption,
                        IndicatorSeparator: () => null,
                      }}
                      isClearable={false}
                      autosize={false}
                      placeholder="Seleccionar"
                      styles={customStylesTctTae}
                      width="192px"
                    />
                }
              </div>
            </div>
            {
              ordenesCompra &&
                <div className="filter">
                  <div className="filter-title required-field">
                    ORDEN DE COMPRA
                  </div>
                  <div className="input-filter producto-select selector">
                    {
                      ordenesCompra &&
                        isAutorizada?
                          <input 
                            className={`${isAutorizada?"disabled-input":"enabled-input"} producto-select`}  
                            type="text"
                            value={selectedOrdenCompra?.label}
                          />
                        :
                          ordenesCompra.length === 1 ?
                            <input 
                              type="text"
                              disabled="true"
                              placeholder={selectedOrdenCompra?.label}
                              className='disabled-input'
                            />
                          :
                          <Select
                            className="producto-select"
                            value={selectedOrdenCompra}
                            onChange={(e) => changeOrdenCompra(e)}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            options={ordenesCompra}
                            components={{
                              SingleOption,
                              IndicatorSeparator: () => null,
                            }}
                            isClearable={false}
                            autosize={false}
                            placeholder="Seleccionar"
                            styles={customStylesTctTae}
                            width={"192px"}
                          />
                    }
                  </div>
                </div>
            }
            {
              ordenesCompra &&
                <div className="filter">
                  <div className="filter-title">
                    MONTO
                  </div>
                  <div className="input-filter monto">
                    <input 
                      type="text"
                      disabled="true"
                      placeholder={selectedOrdenCompra?selectedOrdenCompra.monto: ""}
                      className='disabled-input'
                    />
                  </div>
                </div>
            }
            {
              selectedProductoAutorizacion?.value === productosValues.bluemax &&
                <div className="filter">
                  <div className="filter-title required-field">
                    TIPO DE VENTA
                  </div>
                  <div className="input-filter selector">
                    {
                      selected_TCT_TAE_Product?.value === "TAE" ?
                        <input className='disabled-input' type="text" value={selectedTipoDeVenta?.label}/>
                      :
                        isAutorizada?
                          <input 
                            className={`${isAutorizada?"disabled-input":"enabled-input"} producto-select`}  
                            type="text"
                            value={selectedTipoDeVenta?.label}
                          />
                        :
                          <Select
                            className="producto-select"
                            value={selectedTipoDeVenta}
                            onChange={(e) => changeTipoDeVenta(e)}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            options={[
                              {
                                value: "granel",
                                label: "Granel",
                              },
                              {
                                value: "envasado",
                                label: "Envasado",
                              }
                            ]}
                            components={{
                              SingleOption,
                              IndicatorSeparator: () => null,
                            }}
                            isClearable={false}
                            autosize={false}
                            placeholder="Seleccionar"
                            styles={customStylesTctTae}
                          />
                    }
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
      {
        // (selectedTipoDeVenta && selectedProductoAutorizacion?.value === "bluemax" ) || (selectedProductoAutorizacion && selectedProductoAutorizacion?.value !== "bluemax") &&
        (selectedTipoDeVenta || (selectedProductoAutorizacion && selectedProductoAutorizacion?.value !== productosValues.bluemax)) &&
          <div className="table-prueba-container">
            <table >
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Producto</th>
                  <th>{selectedProductoAutorizacion?.value === productosValues.diesel || selectedTipoDeVenta?.value === tipoVentaValues.granel?"Litros":"Unidades"}</th>
                  <th>Precio</th>
                  <th>Monto</th>
                </tr>
              </thead>
              <tr className="separator-table-row">
                <th colSpan="100%" />
              </tr>
              <tbody>
                <tr>
                  <td>
                      {selectedTableProducto?.value}
                  </td>
                  <td>
                    <div className="filters-container">
                      <div className="filter">
                        {
                          // selectedProductoAutorizacion?.value === productosValues.diesel || selectedTipoDeVenta?.value === tipoVentaValues.granel ?
                          allTableProductos?.length === 1 ?
                            <div className="filter-input">
                              <input type="text" className='disabled-input w247' value={allTableProductos[0]?.label}/>
                            </div>
                          :
                            isAutorizada?
                              <input 
                                className={`${isAutorizada?"disabled-input":"enabled-input"} table-producto-select`}  
                                type="text"
                                value={selectedTableProducto?.label}
                              />
                            :
                              <div className="input-filter selector table-producto-select">
                                <Select
                                  className="table-producto-select"
                                  value={selectedTableProducto}
                                  onChange={(e) => changeTableProductoSelect(e)}
                                  closeMenuOnSelect={true}
                                  hideSelectedOptions={false}
                                  options={allTableProductos}
                                  components={{
                                    SingleOption,
                                    IndicatorSeparator: () => null,
                                  }}
                                  isClearable={false}
                                  autosize={false}
                                  placeholder="Seleccionar"
                                  styles={customStylesTctTae}
                                />
                              </div>
                            
                        }
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="filters-container">
                      <div className="filter">
                        <div className="filter-input">
                          {/* <input 
                            type="text"
                            className={`${selectedProductoAutorizacion?.value === "lubricantes" || selectedTipoDeVenta?.value === "envasado" ? "disabled-input" : "enabled-input"} w112`} 
                          /> */}
                          {
                            isAutorizada?
                              <input 
                                className={`${isAutorizada?"disabled-input":"enabled-input"} w112`}  
                                type="text"
                                value={litrosTable}
                              />
                            :
                              <input 
                                type="text" 
                                name="litros-table" 
                                className='enabled-input w112' 
                                value={litrosTable}
                                onChange={e=>setLitrosTable(e.target.value)}
                                onKeyDown={e=>handlePressOnKey(e)}
                                onBlur={e=>handleOnBlur(e)}
                                placeholder='0'
                              /> 
                          }
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="filters-container">
                      <div className="filter">
                        <div className="filter-input">
                          {
                            isAutorizada?
                              <input 
                                className={`${isAutorizada?"disabled-input":"enabled-input"} w112`}  
                                type="text"
                                value={precioTable}
                              />
                            :
                              <input 
                                type="text" 
                                className={`${selectedProductoAutorizacion?.value === productosValues.lubricantes || selectedTipoDeVenta?.value === tipoVentaValues.envasado ? "disabled-input" : "enabled-input"} w112`}
                                // className='disabled-input w112' 
                                value={precioTable}
                                onChange={e=>setPrecioTable(e.target.value)}
                              />
                          }
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="filters-container">
                      <div className="filter">
                        <div className="filter-input">
                          {
                            isAutorizada?
                              <input 
                                className={`${isAutorizada?"disabled-input":"enabled-input"} w112`}  
                                type="text"
                                value={selectedProductoAutorizacion.value === "001" ? Math.round(montoTable):parseInt(montoTable)}
                              />
                            :
                              <input type="text" 
                                name='monto-table'
                                value={selectedProductoAutorizacion.value === "001" ? Math.round(montoTable):parseInt(montoTable)}
                                className={`${selected_TCT_TAE_Product?.value === "TCT" && selectedProductoAutorizacion?.value === productosValues.lubricantes ? "disabled-input" : "enabled-input"}
                                ${montoError?"monto-error":""} w112`}
                                onChange={e=>setMontoTable(e.target.value)}
                                onKeyDown={e=>handlePressOnKey(e)}
                                onBlur={e=>handleOnBlur(e)}
                              />
                          }
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              {
                flete > 0 &&
                  <>
                    <tr className="separator-table-row">
                      <th colSpan="100%" />
                    </tr>
                    <tbody>
                      <tr>
                        <td colSpan={2} className='despacho'>
                            Cargo por despacho extraordinario
                        </td>
                        <td></td>
                        <td></td>
                        <td>{flete}</td>
                      </tr>
                    </tbody>                  
                  </>
              }
              <tr className="separator-table-row">
                <th colSpan="100%" />
              </tr>
              <tbody>
                <tr>
                  <td>
                    <div className="content w105">
                      Total Guía Manual
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{selectedProductoAutorizacion.value === "001" ? Math.round(montoTotal):parseInt(montoTotal)}</td>
                </tr>
              </tbody>
            </table>
          </div>
      }

      {
        montoError &&
          <div className="important-message error">
            <span className="error-icon">!</span>
            <span className="text">Monto solicitado excede al máximo permitido para la tarjeta</span>
          </div>
      }
      
      {
        autorizadaError &&
          <div className="important-message error">
            <span className="error-icon">!</span>
            <span className="text">Error: {mensajeRespuesta}</span>
          </div>
      }

      {
        selected_TCT_TAE_Product?.value === "TAE" &&
          <div className="carta-compromiso-container">
            <p>
              <span className='importante'>Importante:</span> Para ver esta carta de compromiso enviada hacer <span className='click'>click aquí</span>
            </p>
          </div>
      }
      {
        isAutorizada &&
          <div className="important-message success">
            <span className="success-icon"><img src={greenCheck}/></span>
            <span className="text">El número de guía es: {numeroGuia} y el código de autorización otorgado es: {parseInt(codigoAutorizacion)}</span>
          </div>
      }
      {
        (selectedTipoDeVenta || (selectedProductoAutorizacion && selectedProductoAutorizacion?.value !== productosValues.bluemax)) &&
        <>
          <hr/>
          <div className="actions-buttons-container">
            {
              !isAutorizada ?
                <>
                  <button onClick={cleanFields} className='main-button thin main-button-red hallow'>
                    Limpiar
                  </button>
                  <button className='main-button thin main-button-red' onClick={autorizar}
                  disabled={isNotFilled}>
                    Obtener Autorización
                  </button>
                </>
              :
                <button className='main-button thin main-button-red' onClick={showConsultTable}>Aceptar</button> 

            }
          </div>
        </>
      }
      <CustomModal
        displayModal = { displaySuccessModal }
        closeModal = { closeSuccessModal }
        acceptFunc = { closeSuccessModal }
        acceptText= "Aceptar"
        modalTitle = "Operación exitosa"
        modalText = {`
        El número de guía es: ${parseInt(numeroGuia)} y el código de autorización otorgado es: ${parseInt(codigoAutorizacion)}
        `}
        modalIcon = "success"
      />
    </div>
  );
}

export default SolicitudAutorizacionGuiaManual;
