import React, { useRef, useEffect, useState } from "react";
import descargar from "../assets/img/descargar.svg";
import logo from "../assets/img/logo-comprobante.svg";
import timbre from "../assets/img/timbre-comprobante.svg";
import { parseBudat, getLocaleStringNumber, parseDate, getBancoName } from "./utils/utils.js";
import { scrollTo } from "./utils/windows.js";
import html2pdf from 'html2pdf.js'
import PrintComponent from "./PrintComponent";
import CustomModal from "./CustomModal";

const ref = React.createRef();

const ComprobantePago = ({ address, rut, clientName, selectedComprobante, setSelectedComprobante, isROImpersonate }) => {
  const [displayModal, setDisplayModal] = useState(false)

  useEffect(() => {
    scrollTo(0, 330)
  }, [])

  const title = () => {
    if(selectedComprobante.TIPO === "Compensación"){
      return "Comprobante de Compensación"
    }
    if(selectedComprobante.TIPO === "Abono en Línea"){
      if(selectedComprobante.ESTADO==="Creada"){
        return "Propuesta de Pago"
      }else{
        return "Comprobante de Abono"
      }
    }
    if(selectedComprobante.TIPO === "Pago en Línea"){
      if(selectedComprobante.ESTADO==="Creada"){
        return "Propuesta de Pago"
      }else{
        return "Comprobante de Pago"
      }
    }
    if(selectedComprobante.TIPO === "Pago Cheque en Línea"){
      if(selectedComprobante.ESTADO==="Por Confirmar"){
        return "Comprobante Cheque en Línea"
      }else{
        return "Comprobante Pago Cheque en Línea"
      }
    }
  }

  function closeModal(){
    setDisplayModal(false)
  }

  function printPdf(){
    const element = document.getElementById('comprobante-pago-id')
    html2pdf(element, {filename: 'comprobante.pdf', margin: 10})
  }

  return (
    <>
      <div className="comprobante-container" ref={ref} id="comprobante-pago-id">
        <div className="comprobante">
          <div className="logo">
            <img src={logo} />
          </div>
          <div className="comprobante-title">
            {title()}
          </div>
          <hr />
          <div className="header-data">
            <div className="data">
              <span className="title">Cliente:&nbsp;</span>
              <span>
                {rut}&nbsp;{clientName}
              </span>
            </div>
            <div className="data">
              <span className="title">Dirección:&nbsp;</span>
              <span className="data">{address}</span>
            </div>
            <div className="data">
              <span className="title">Nº de Transacción:&nbsp;</span>
              <span className="data">{selectedComprobante["GRUPO_PPTA"]}</span>
            </div>
            <div className="data">
              <span className="title">Fecha:&nbsp;</span>
              <span className="data">{parseBudat(selectedComprobante["FECHA"])} - {selectedComprobante["HORA"]}</span>
            </div>
          </div>

          <table>
            <thead>
              <tr>
                {selectedComprobante.TIPO === "Abono en Línea"?<><th>
                  Fecha
                  <br />
                  Abono
                </th></>:<><th>
                  Fecha
                  <br />
                  Vencimiento
                </th></>}
                {selectedComprobante.TIPO === "Abono en Línea"?<><th>
                  Línea
                  <br />
                  Producto
                </th></>:<><th>
                  Tipo de
                  <br />
                  Documento
                </th></>}
                {selectedComprobante.TIPO === "Abono en Línea"?<></>:<th>Nº Documento</th>}
                <th className="align-right">Valor</th>
              </tr>
            </thead>
            <tbody>
              {selectedComprobante["DOCUMENTOS"].map((doc, key) => (
                <tr key={key}>
                  <td>{selectedComprobante.TIPO === "Abono en Línea"?parseBudat(selectedComprobante.FECHA):parseBudat(doc.FECHA_VENCIMIENTO)}</td>
                  <td>{doc.PRODUCTO}</td>
                  {selectedComprobante.TIPO === "Abono en Línea"?<></>:<td>{doc.NUMERO_DOCUMENTO}</td>}
                  <td className="align-right">
                    ${" "}
                    {parseInt(doc.CARGO) === 0
                      ? "-"+getLocaleStringNumber(parseInt(doc.ABONO))
                      : getLocaleStringNumber(parseInt(doc.CARGO))}
                  </td>
                </tr>
              ))}
              <tr className="total-saldo">
                <td colSpan={`${selectedComprobante.TIPO === "Abono en Línea"?"2":"3"}`}>
                  {selectedComprobante.TIPO === "Compensación" &&
                    "Saldo compensación"}
                  {selectedComprobante.TIPO !== "Compensación" && (
                    <>
                      Total Pago: {getBancoName(selectedComprobante.BANCO)} {selectedComprobante.TIPO==="Pago Cheque en Línea"?" - Nº Cuenta "+selectedComprobante.ZCUENTA : ''}
                    </>
                  )}
                </td>
                <td colSpan="1" className="align-right">
                  $ {getLocaleStringNumber(parseInt(selectedComprobante.MONTO))}
                </td>
              </tr>
              {(selectedComprobante.TIPO==="Pago Cheque en Línea" && (selectedComprobante.ESTADO==="Por Confirmar"))?<>
                <tr className="total-saldo">
                  <td colSpan="3">
                    Sujeto a confirmación del Banco
                  </td>
                </tr>
                </>:<></>}
            </tbody>
          </table>

          {selectedComprobante.ESTADO==="Por Confirmar" || title()==="Propuesta de Pago"?
          <></>:<>
          <div className="bottom-logo">
            <img src={timbre} />
          </div>
          </>}
          
        </div>
      </div>
      <div className="comprobante-container nomargintop">
        <div className="bottom-actions">
          <button
            className="back-button"
            onClick={() => setSelectedComprobante(null)}
          >
            Volver
          </button>
          <PrintComponent 
            reff={ref}
            classes="comprobante-action cursor-pointer"
            isROImpersonate={isROImpersonate}
          />

          {isROImpersonate &&
            <img onClick={() => setDisplayModal(true)} className="comprobante-action cursor-pointer" src={descargar} />
          }

          {!isROImpersonate &&
            <img onClick={printPdf} className="comprobante-action cursor-pointer" src={descargar} />
          }
          
        </div>
      </div>
      <CustomModal
        displayModal={displayModal}
        closeModal={closeModal}
        acceptFunc={closeModal}
        modalTitle='Importante'
        modalText='Por seguridad no estás autorizado para descargar y/o imprimir información del Módulo Cuenta Corriente'
        modalIcon='importante'
      />
    </>
  );
};

export default ComprobantePago;
