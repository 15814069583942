import React, {useState,useEffect} from "react";
import CustomModal from "./CustomModal";
import imprimir from "../assets/img/imprimir.svg";
import ReactToPrint from "react-to-print";

const PrintComponent = ({ reff, classes, pageStyle=null, isROImpersonate, isHidden,requestCounter }) => {
  const [displayModal, setDisplayModal] = useState(false)

  function closeModal(){
    setDisplayModal(false)
  }

  function showIsROAlert(){
    setDisplayModal(true)
  }

  useEffect(() => {
    if(requestCounter>0 && isHidden){
      const boton = document.getElementById("print-button");
      boton.click();
    }
  }, [requestCounter]);

  return (
    <>
      {isROImpersonate &&
        <img src={imprimir} onClick={showIsROAlert} />
      }
      {!isROImpersonate &&
        <ReactToPrint
          trigger={() => (
            <a id="print-button" className={`${isHidden?"hide":""}`}>
              <img src={imprimir} className={classes} />
            </a>
          )}
          content={() => reff.current}
          pageStyle={pageStyle || "@page { size: auto; margin: 5mm; } @media print { @page{ padding: 10mm !important; } body { -webkit-print-color-adjust: exact; padding: 0px !important; } }"}
        />
      }
      <CustomModal
        displayModal={displayModal}
        closeModal={closeModal}
        acceptFunc={closeModal}
        modalTitle='Importante'
        modalText='Por seguridad no estás autorizado para descargar y/o imprimir información del Módulo Cuenta Corriente'
        modalIcon='importante'
      />
    </>
  );
};
export default PrintComponent
