import React, { useState, useEffect } from "react";
import closeIcon from "../../assets/img/cross-icon.svg";
import Modal from "react-modal";
import CustomModal from "../CustomModal"
import { getTanquesSifoneadosConfirmacionCierreDiario, getResumenTanquesCierreDiario } from '../utils/tanquesSifoneados';
import { hideGreyBackground, parentRedirect } from '../utils/windows'
import { getLocaleStringNumber, parseBudat } from "../utils/utils";
import { getAlertIconVentas, getMaterialClassVentas, getMaterialIconNameVentas } from "../utils/get";

export const ConfirmacionFluctuaciones = ({
  token,
  setStep,
  resetForm,
  configuracion,
  tanques,
  entradasSalidas,
  openEntradas,
  setOpenEntradas,
  openSalidas,
  setOpenSalidas,
  openCloseModals,
  viewPortHeight,
  callServiceHandler,
  fluctuaciones,
  selectedEds,
  pagador,
  fechaCierre,
  setFechaCierre,
  getResumenCierre,
  selectedEstadoCierre,
  disableFluctuaciones,
  isROImpersonate,
  ventas,
  selectedCierre
}) => {
  const [observaciones, setObservaciones] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalText, setModalText] = useState('')
  const [modalIcon, setModalIcon] = useState('success')
  const [customModalStyles, setCustomModalStyles] = useState({})
  const [selector, setSelector] = useState("litros");


  useEffect(() => {
    setCustomModalStyles({
      content: {
        top: "20px",
        padding: "0",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        boxSizing: "border-box",
        transform: "translateX(-50%)",
        maxHeight: (viewPortHeight - 150) + 'px',
        borderRadius: 9
      }
    })

  }, [])

  function closeSuccessModal() {
    hideGreyBackground()
    setShowSuccess(false)
    if (modalIcon === 'success')
      setFechaCierre();
    getResumenCierre()
    setStep(1);
    //parentRedirect(process.env.REACT_APP_HISTORIAL_FLUCTUACIONES_PATH)
    //resetForm()
  }

  async function insertFluctuacion() {

      const data={
        ID_EDS:selectedEds.value,
        FECHA:fechaCierre,
        PASO:5,
        ID_PAGADOR:pagador,
        FLUCTUACIONES:fluctuaciones,
        OBSERVACIONES:observaciones
      }
      if(isROImpersonate) data.VISUALIZAR="SI";
  
      const url =
        process.env.REACT_APP_FLUCTUACIONES_API +
        "/fluctuaciones/cierre-diario-ingresar-paso";

      let response = await callServiceHandler(url, "POST", JSON.stringify(data));
      if(response!=="error"){
        if(isROImpersonate){
          setFechaCierre();
          getResumenCierre();
          setStep(1);
        }else{
          setShowSuccess(true)
          setModalTitle("Operacion exitosa")
          setModalText(`Ha concluido de forma exitosa el cierre de operaciones diario. EDS ${parseInt(selectedEds.value)} - Fecha ${parseBudat(fechaCierre)}`)
          setModalIcon("success")
        }
      }
  }

  function goBackFunction(){
    setStep(4)
   /*  if(selectedEstadoCierre==="CERRADO"){
      if(isROImpersonate){
        setStep(4)
      }else{
        setStep(1);
        setFechaCierre();
      }
    }else{
      setStep(4)
    } */
  }

  function changeSelector() {
    const tselector = document.getElementById("table-selector");
    if(tselector.checked){
      tselector.checked = false;
      setSelector("litros");
    }else{
      tselector.checked = true;
      setSelector("pesos");
    }
  }

  function getMaterialTipo(material){
    if(parseInt(selectedEds.value)===60348){
      return "concesionado"
    }
    if(parseInt(selectedEds.value)===60403)
      if(material.material==="KEROSENE"){
        return "concesionado"
      }else{
        return "consignado"
      }
    return ""
  }

  function checkRegularizadas(){
    const rojas = ventas?.detalle.filter((trx)=>trx.venta_propia_alerta==="ROJA" || trx.producto_copec_alerta==="ROJA");
    if(rojas?.length>0){
      return false
    }else{
      return true;
    }
  }

  function regularizar(){
    if(!checkRegularizadas()){
      irARegularizacion()
    }
  }

  function irARegularizacion(){
    const url = process.env.REACT_APP_REGULARIZACION_PATH+"?eds="+selectedEds.value+"&fecha="+fechaCierre;
    parentRedirect(url);
  }

  return (
    <div className="fluctuaciones-ingreso-mediciones-big-section-cierre-diario">
      <p className="flutuaciones-section-title">
        CONFIRMACIÓN DE FLUCTUACIONES
      </p>
      <table className="flutuaciones-table tanks-table confirmation-table">
        <thead>
          <tr>
            <th className="cierre-diario-confirmacion-th-tanque">Tanque</th>
            <th className="cierre-diario-confirmacion-th-material">Material</th>
            <th className="cierre-diario-confirmacion-th no-padding">Medición inicial</th>
            <th className="cierre-diario-confirmacion-th">Entradas</th>
            <th className="cierre-diario-confirmacion-th">Salidas</th>
            <th className="cierre-diario-confirmacion-th">Salida<br />Acumulada</th>
            <th className="cierre-diario-confirmacion-th">Inventario<br />Teórico</th>
            <th className="cierre-diario-confirmacion-th">Med. Física<br />Proyectada</th>
            <th className="cierre-diario-confirmacion-th">Fluctuación<br />Diaria Lts.</th>
            <th className="cierre-diario-confirmacion-th">Fluctuación<br />Acumu. (lts)</th>
            <th className="cierre-diario-confirmacion-th">% Fluctuación <br /> Acumulada</th>
            <th className="cierre-diario-confirmacion-th-alerta">Alerta</th>
          </tr>
        </thead>
        <tbody>
          {fluctuaciones && fluctuaciones.map((tanque, key) =>
            getTanquesSifoneadosConfirmacionCierreDiario(tanque, key)
          )}
        </tbody>
      </table>
      {/* <hr className="table-separator"/>
      <p className="flutuaciones-section-title">
        VENTAS
      </p>
      {
        ventas &&
        <div className="ventas-container ventas-in-fluctuaciones">
          <table className="ventas">
            <div className="table-header">
              <div className="legend">
                <div className="material">
                  <div className="rect"></div>
                  <div className="text">Material consignado</div>
                </div>
                <div className="material">
                  <div className="rect concesionado"></div>
                  <div className="text">Material concesionado</div>
                </div>
              </div>{" "}
              <div className="selector">
                <div className="text">LITROS</div>
                <div className="switch" onClick={() => changeSelector()}>
                  <input type="checkbox" id="table-selector" />
                  <span className="slider round"></span>
                </div>
                <div className="text">PESOS</div>
              </div>
            </div>
            <thead>
              <tr>
                <th>Material</th>
                <th className="align-left"></th>
                <th className="align-right">Controlador</th>
                <th className="align-right">Documentados</th>
                <th className="align-right">Confirmados</th>
                <th className="align-right">
                  Documentados <br />- Controlador
                </th>
                <th className="align-right">
                  Confirmados <br />- Documentados
                </th>
                <th className="align-left">Alertas</th>
                <th className="cierre-diario-ventas-last-column"></th>
              </tr>
            </thead>
            <tr className="separator-table-row">
              <th colSpan="100%" />
            </tr>
            {ventas?.detalle.map((material) => {
              return (<>
                <tbody>
                  <tr className="data">
                    <td rowSpan={3} className="container-cell align-left">
                      <div className="container-cell-content">
                        <div className={`type ${getMaterialTipo(material)}`}></div>
                        <div className="material">
                          <div className={`small-camion-compartment ${getMaterialClassVentas(material.material)} cont2`}>
                            <p className="small-camion-compartment__fuel-type-number">
                              {getMaterialIconNameVentas(material.material)}
                            </p>
                          </div>
                          <span>{material.material}</span>
                        </div>
                      </div>
                    </td>
                    <td className="align-left small-padding-left">Venta Propia</td>
                    <td className="align-right">{getLocaleStringNumber(Math.round(material.venta_propia[selector].controlador))}</td>
                    <td className="align-right">{getLocaleStringNumber(Math.round(material.venta_propia[selector].documentados))}</td>
                    <td className="align-right">{getLocaleStringNumber(Math.round(material.venta_propia[selector].confirmados))}</td>
                    <td className="align-right">{getLocaleStringNumber(Math.round(material.venta_propia[selector].documentados_menos_controlador))}</td>
                    <td className="align-right">{getLocaleStringNumber(Math.round(material.venta_propia[selector].confirmados_menos_documentados))}</td>
                    <td className="align-right alert-container-cierre-diario">{getAlertIconVentas(material.venta_propia_alerta)}
                      <div className="alert-tooltip">
                        <div className="content">
                          {material.venta_propia_alerta_desc}
                        </div>
                      </div>
                    </td>
                    <td className="cierre-diario-ventas-last-column"></td>
                  </tr>
                  <tr className="data">
                    <td className="align-left small-padding-left">Producto Copec</td>
                    <td className="align-right">{getLocaleStringNumber(Math.round(material.producto_copec[selector].controlador))}</td>
                    <td className="align-right">{getLocaleStringNumber(Math.round(material.producto_copec[selector].documentados))}</td>
                    <td className="align-right">{getLocaleStringNumber(Math.round(material.producto_copec[selector].confirmados))}</td>
                    <td className="align-right">{getLocaleStringNumber(Math.round(material.producto_copec[selector].documentados_menos_controlador))}</td>
                    <td className="align-right">{getLocaleStringNumber(Math.round(material.producto_copec[selector].confirmados_menos_documentados))}</td>
                    <td className="align-right alert-container-cierre-diario">{getAlertIconVentas(material.producto_copec_alerta)}
                      <div className="alert-tooltip">
                        <div className="content">
                          {material.producto_copec_alerta_desc}
                        </div>
                      </div>
                    </td>
                    <td className="cierre-diario-ventas-last-column"></td>
                  </tr>
                  <tr className="data">
                    <td className="align-left small-padding-left">Total</td>
                    <td className="align-right no-mr"><div className="pill">{getLocaleStringNumber(Math.round(material.total[selector].controlador))}</div></td>
                    <td className="align-right no-mr"><div className="pill">{getLocaleStringNumber(Math.round(material.total[selector].documentados))}</div></td>
                    <td className="align-right no-mr"><div className="pill">{getLocaleStringNumber(Math.round(material.total[selector].confirmados))}</div></td>
                    <td className="align-right no-mr"><div className="pill">{getLocaleStringNumber(Math.round(material.total[selector].documentados_menos_controlador))}</div></td>
                    <td className="align-right no-mr"><div className="pill">{getLocaleStringNumber(Math.round(material.total[selector].confirmados_menos_documentados))}</div></td>
                    <td className="align-right">&nbsp;</td>
                    <td className="cierre-diario-ventas-last-column"></td>
                  </tr>
                </tbody>
                <tr className="separator-table-row">
                  <th colSpan="100%" />
                </tr>
              </>)
            })}
            <tbody>
              <tr className="data">
                <td className="align-left total">
                  TOTAL
                </td>
                <td className="align-left"></td>
                <td className="align-right">{getLocaleStringNumber(Math.round(ventas?.total[selector].controlador))}</td>
                <td className="align-right">{getLocaleStringNumber(Math.round(ventas?.total[selector].documentados))}</td>
                <td className="align-right">{getLocaleStringNumber(Math.round(ventas?.total[selector].confirmados))}</td>
                <td className="align-right">{getLocaleStringNumber(Math.round(ventas?.total[selector].documentados_menos_controlador))}</td>
                <td className="align-right">{getLocaleStringNumber(Math.round(ventas?.total[selector].confirmados_menos_documentados))}</td>
                <td className="align-right"></td>
                <td className="cierre-diario-ventas-last-column"></td>
              </tr>
            </tbody>

          </table>
        </div>
      } */}

      <p className="flutuaciones-section-title push-down">OBSERVACIONES</p>
      <textarea
        value={observaciones}
        rows="6"
        className="flutuaciones-textarea"
        maxLength="280"
        onChange={(e) =>
          setObservaciones(e.target.value)
        }
      />
      <p className="flutuaciones-textarea-count">{observaciones.length}/280</p>
      <div className="fluctuaciones-ingreso-mediciones-actions">
        <button className="main-button hallow red" onClick={() => goBackFunction()}>
          Volver
        </button>
        <button className={`main-button ${selectedEstadoCierre === "CERRADO" || disableFluctuaciones ? "disabled" : "red"}`} onClick={insertFluctuacion} disabled={selectedEstadoCierre === "CERRADO" || disableFluctuaciones}>
          Confirmar
        </button>
      </div>

      <Modal isOpen={openEntradas} onRequestClose={() => openCloseModals(false, setOpenEntradas)} style={customModalStyles}>
        <div className="flutuaciones-modal fim-modal">
          <img
            className="close-modal"
            onClick={() => openCloseModals(false, setOpenEntradas)}
            src={closeIcon}
            alt="Close"
          />
          <div className="modal-info">
            <p className="flutuaciones-modal__title">
              <strong>Entradas</strong>
            </p>
          </div>
          <table className="flutuaciones-table tanks-table confirmation-table fim-table">
            <thead>
              <tr>
                <th>Tanque</th>
                <th>Material</th>
                <th>Camión<br />desde planta</th>
                <th>Pedidos Normalizados</th>
                <th>Calibraciones</th>
                <th>Movimientos<br />Bodega</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {entradasSalidas && entradasSalidas.map((material, key) =>
                getResumenTanquesCierreDiario(material, 'entradas')
              )}
            </tbody>
          </table>
        </div>
      </Modal>

      <Modal isOpen={openSalidas} onRequestClose={() => openCloseModals(false, setOpenSalidas)} style={customModalStyles}>
        <div className="flutuaciones-modal fim-modal">
          <img
            className="close-modal"
            onClick={() => openCloseModals(false, setOpenSalidas)}
            src={closeIcon}
            alt="Close"
          />
          <div className="modal-info">
            <p className="flutuaciones-modal__title">
              <strong>Salidas</strong>
            </p>
          </div>
          <table className="flutuaciones-table tanks-table confirmation-table fim-table">
            <thead>
              <tr>
                <th>Tanque</th>
                <th>Material</th>
                <th>Ventas</th>
                <th>Ventas regularizadas</th>
                <th>Calibraciones</th>
                <th>Movimientos<br />de Bodega</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {entradasSalidas && entradasSalidas.map((material, key) =>
                getResumenTanquesCierreDiario(material, 'salidas')
              )}
            </tbody>
          </table>
        </div>
      </Modal>

      <CustomModal
        displayModal={showSuccess}
        closeModal={closeSuccessModal}
        acceptFunc={closeSuccessModal}
        modalTitle={modalTitle}
        modalText={modalText}
        modalIcon={modalIcon}
      />

    </div>
  );
};
