import React, { useState, useEffect } from "react";
import {
  orderArray,
  customStyles,
  Option,
  checkSelectedOptions,
  customFilter,
  parseBudat,
  getLocaleStringNumber
} from "../utils/utils.js";
import Flecha_down from "../../assets/img/Flecha_down.svg";
import Flecha_up from "../../assets/img/Flecha_up.svg";
import NumericSelect from "../NumericSelect";
import Select, { components } from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import CustomHighlighter from "../CustomHighlighter";

const HistorialPedidosTable = (props) => {
  const selectedYear = props.selectedYear;
  const selectedMonth = props.selectedMonth;
  const [daysInMonth, setDaysInMonth] = useState(28);
  const pedidos = props.pedidos;
  const filteredPedidos = props.filteredPedidos;
  const setFilteredPedidos = props.setFilteredPedidos;
  const selectedPeriodo=props.selectedPeriodo;
  const setSelectedPedido = props.setSelectedPedido;
  const setDisplayDetallePedido = props.setDisplayDetallePedido;

  //Filtros de tabla
  const allEstados = props.allEstados;
  const estadoFilter = props.estadoFilter;
  const setEstadoFilter = props.setEstadoFilter;
  const allPlantas = props.allPlantas;
  const plantaFilter = props.plantaFilter;
  const setPlantaFilter = props.setPlantaFilter;
  const allTipos = props.allTipos;
  const tipoFilter = props.tipoFilter;
  const setTipoFilter = props.setTipoFilter;
  const allHoras = props.allHoras;
  const horaFilter = props.horaFilter;
  const setHoraFilter = props.setHoraFilter;
  const filterText = props.filterText;
  const entregaStartDate = props.entregaStartDate;
  const setEntregaStartDate = props.setEntregaStartDate;
  const entregaEndDate = props.entregaEndDate;
  const setEntregaEndDate = props.setEntregaEndDate;
  const numeroPedido = props.numeroPedido;
  const setNumeroPedido = props.setNumeroPedido;
  const selectedNumeroPedido = props.selectedNumeroPedido;
  const setSelectedNumeroPedido = props.setSelectedNumeroPedido;
  const volumen = props.volumen;
  const setVolumen = props.setVolumen;
  const selectedVolumen= props.selectedVolumen;
  const setSelectedVolumen = props.setSelectedVolumen;
  const getLitros = props.getLitros;
  const tableRef = props.tableRef;
  const selectedEds = props.selectedEds;
  const pedidosLoaded = props.pedidosLoaded;
  const isDinamo = props.isDinamo;

  //filtros dinamo
  const volumenPlanificado = props.volumenPlanificado ;
  const setVolumenPlanificado  = props.setVolumenPlanificado ;
  const selectedVolumenPlanificado = props.selectedVolumenPlanificado ;
  const setSelectedVolumenPlanificado  = props.setSelectedVolumenPlanificado ;
  const volumenEntregado = props.volumenEntregado ;
  const setVolumenEntregado  = props.setVolumenEntregado ;
  const selectedVolumenEntregado = props.selectedVolumenEntregado ;
  const setSelectedVolumenEntregado  = props.setSelectedVolumenEntregado ;


  const onEntregaDateChange = (dates) => {
    const [start, end] = dates;
    setEntregaStartDate(start);
    setEntregaEndDate(end);
  };

  const orderTable = (filterkey, order, parse) => {
    const arr = orderArray(filterkey, pedidos, order, parse);
    props.setPedidos(arr);
  };

  useEffect(() => {
    setFilteredPedidos(pedidos);
  }, [pedidos]);

  useEffect(() => {
    filter();
  }, [
    estadoFilter,
    plantaFilter,
    tipoFilter,
    horaFilter,
    entregaEndDate,
    numeroPedido,
    selectedNumeroPedido,
    volumen,
    selectedVolumen,
    filterText,
    volumenPlanificado,
    selectedVolumenPlanificado,
    volumenEntregado,
    selectedVolumenEntregado
  ]);

  function filter() {
    let pedidosArr = [];
    checkSelectedOptions(estadoFilter, setEstadoFilter, allEstados);
    checkSelectedOptions(plantaFilter, setPlantaFilter, allPlantas);
    checkSelectedOptions(tipoFilter, setTipoFilter, allTipos);
    checkSelectedOptions(horaFilter, setHoraFilter, allHoras);

    pedidos.forEach((pedido) => {
      let found = true;

      if (estadoFilter.length > 0) {
        if (!estadoFilter.some((e) => e.value === pedido.ESTADO)) found = false;
      }

      if (plantaFilter.length > 0) {
        if (!plantaFilter.some((e) => e.value === pedido.PLANTA)) found = false;
      }

      if (tipoFilter.length > 0) {
        if (!tipoFilter.some((e) => e.value === pedido.TIPO_PEDIDO))
          found = false;
      }

      if (horaFilter.length > 0) {
        if (!horaFilter.some((e) => e.value === pedido.HORARIO)) found = false;
      }

      if (entregaEndDate) {
        const dateArr = pedido.FECHA_PREFERENTE_ENTREGA.split("-");
        const date = new Date(
          dateArr[0],
          dateArr[1] - 1,
          dateArr[2],
          0,
          0,
          0,
          0
        );

        if (date < entregaStartDate || date > entregaEndDate) found = false;
      }

      if (found) pedidosArr.push(pedido);
    });

    if (selectedNumeroPedido !== "Todos" && numeroPedido !== "")
      pedidosArr = customFilter(
        pedidosArr,
        numeroPedido,
        selectedNumeroPedido,
        "ID_PEDIDO"
      );

    if (selectedVolumen !== "Todos" && volumen !== "")
      pedidosArr = customFilter(
        pedidosArr,
        volumen,
        selectedVolumen,
        "VOLUMEN"
      );

      if (selectedVolumenPlanificado !== "Todos" && volumenPlanificado !== "")
      pedidosArr = customFilter(
        pedidosArr,
        volumenPlanificado,
        selectedVolumenPlanificado,
        "VOLUMEN_PLANIFICADO"
      );

      if (selectedVolumenEntregado !== "Todos" && volumenEntregado !== "")
      pedidosArr = customFilter(
        pedidosArr,
        volumenEntregado,
        selectedVolumenEntregado,
        "VOLUMEN_ENTREGADO"
      );

    if (filterText !== "") {
      let filterTextLower = filterText.toLowerCase();
      filterTextLower = filterTextLower.replaceAll(".", "");

      pedidosArr = pedidosArr.filter((pedido) => {
        return (
          (pedido.ID_PEDIDO &&
            pedido.ID_PEDIDO.toLowerCase().includes(filterTextLower)) ||
          (pedido.PLANTA &&
            pedido.PLANTA.toString().toLowerCase().includes(filterTextLower)) ||
          (pedido.TIPO_PEDIDO &&
            pedido.TIPO_PEDIDO.toString()
              .toLowerCase()
              .includes(filterTextLower)) ||
          (pedido.FECHA_PREFERENTE_ENTREGA &&
            pedido.FECHA_PREFERENTE_ENTREGA.toString()
              .toLowerCase()
              .includes(filterTextLower)) ||
          (pedido.HORARIO &&
            pedido.HORARIO.toString()
              .toLowerCase()
              .includes(filterTextLower)) ||
          (pedido.ESTADO &&
            pedido.ESTADO.toString().toLowerCase().includes(filterTextLower)) ||
          (pedido.VOLUMEN &&
            pedido.VOLUMEN.toString().toLowerCase().includes(filterTextLower)) ||
          (pedido.VOLUMEN_PLANIFICADO &&
            pedido.VOLUMEN_PLANIFICADO.toString().toLowerCase().includes(filterTextLower)) ||
          (pedido.VOLUMEN_ENTREGADO &&
            pedido.VOLUMEN_ENTREGADO.toString().toLowerCase().includes(filterTextLower))
        );
      });
    }

    setFilteredPedidos(pedidosArr);
  }

  const selectPedido = (pedido) => {
    setSelectedPedido(pedido);
    setDisplayDetallePedido(true);
  }

  return (
    <div className="pedidos-table-container" ref={tableRef}>
      <table className={`pedidos-table pedidos-table-fix ${isDinamo?"dinamo":""}`}>
        <tr>
          <th>
            <div className="th-data">
              <div className="th-text">
                Nº de pedido
                <div className="arrows">
                  <img
                    onClick={() => orderTable("ID_PEDIDO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("ID_PEDIDO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Planta
                <div className="arrows">
                  <img
                    onClick={() => orderTable("PLANTA", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("PLANTA", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Tipo de Pedido
                <div className="arrows">
                  <img
                    onClick={() => orderTable("TIPO_PEDIDO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("TIPO_PEDIDO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Fecha Preferente Entrega
                <div className={`arrows ${isDinamo?"mtop":""}`}>
                  <img
                    onClick={() => orderTable("FECHA_PREFERENTE_ENTREGA", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("FECHA_PREFERENTE_ENTREGA", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Ventana Solicitada
                <div className="arrows">
                  <img
                    onClick={() => orderTable("HORARIO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("HORARIO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Estado
                <div className="arrows">
                  <img
                    onClick={() => orderTable("ESTADO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("ESTADO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Volumen Solicitado&nbsp;(L)
                <div className={`arrows ${isDinamo?"mtop":""}`}>
                  <img
                    onClick={() => orderTable("VOLUMEN", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("VOLUMEN", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          {isDinamo && <>
          <th>
            <div className="th-data">
              <div className="th-text">
                Volumen Planificado (L)
                <div className="arrows mtop">
                  <img
                    onClick={() => orderTable("VOLUMEN_PLANIFICADO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("VOLUMEN_PLANIFICADO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Volumen Entregado (L)
                <div className="arrows mtop">
                  <img
                    onClick={() => orderTable("VOLUMEN_ENTREGADO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("VOLUMEN_ENTREGADO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          </>}
        </tr>
        <tr className="table-separator-row-border-top"><td /></tr>
        <tr className="filters-row">
          <td>
            <div
              className={`filter-content no-padding-left${
                numeroPedido === "" || selectedNumeroPedido === "Todos"
                  ? ""
                  : " active"
              }`}
            >
              <NumericSelect
                name="ns-numero-pedido"
                value={numeroPedido}
                setValue={setNumeroPedido}
                selected={selectedNumeroPedido}
                setSelected={setSelectedNumeroPedido}
              />
            </div>
          </td>
          <td>
            <div className="filter-content">
              <Select
                className="table-select"
                value={plantaFilter}
                onChange={(e) => setPlantaFilter(e)}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                options={allPlantas}
                autocomplete="off"
                isSearchable={false}
                components={{
                  Option,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                isMulti
                placeholder="Todos"
                styles={customStyles}
                width={`${isDinamo?"136px":"178px"}`}
                border={`${
                  plantaFilter.length > 0 ? "solid 1px #6cbfff" : "none"
                }`}
              />
            </div>
          </td>
          <td>
            <div className="filter-content">
              <Select
                className="table-select"
                value={tipoFilter}
                onChange={(e) => setTipoFilter(e)}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                options={allTipos}
                autocomplete="off"
                isSearchable={false}
                components={{
                  Option,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                isMulti
                placeholder="Todos"
                styles={customStyles}
                width={`${isDinamo?"136px":"178px"}`}
                border={`${
                  tipoFilter.length > 0 ? "solid 1px #6cbfff" : "none"
                }`}
              />
            </div>
          </td>
          <td>
            <div className="filter-content">
              <label onClick={(e) => e.preventDefault()}>
                <DatePicker
                  locale="es"
                  selected={entregaEndDate}
                  onChange={onEntregaDateChange}
                  startDate={entregaStartDate}
                  endDate={entregaEndDate}
                  shouldCloseOnSelect={false}
                  minDate={new Date(selectedYear, selectedMonth - 1, 1)}
                  maxDate={
                    new Date(selectedYear, selectedMonth - 1, daysInMonth)
                  }
                  showDisabledMonthNavigation
                  dateFormat="dd/MM/yyyy"
                  selectsRange
                  className={entregaEndDate ? "active" : ""}
                  openToDate={entregaStartDate}
                />
              </label>
            </div>
          </td>
          <td>
            <div className="filter-content">
              <Select
                className="table-select"
                value={horaFilter}
                onChange={(e) => setHoraFilter(e)}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                options={allHoras}
                autocomplete="off"
                isSearchable={false}
                components={{
                  Option,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                isMulti
                placeholder="Todos"
                styles={customStyles}
                width={`${isDinamo?"136px":"178px"}`}
                border={`${
                  horaFilter.length > 0 ? "solid 1px #6cbfff" : "none"
                }`}
              />
            </div>
          </td>
          <td>
            <div className="filter-content">
              <Select
                className="table-select"
                value={estadoFilter}
                onChange={(e) => setEstadoFilter(e)}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                options={allEstados}
                autocomplete="off"
                isSearchable={false}
                components={{
                  Option,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                isMulti
                placeholder="Todos"
                styles={customStyles}
                width={`${isDinamo?"136px":"178px"}`}
                border={`${
                  estadoFilter.length > 0 ? "solid 1px #6cbfff" : "none"
                }`}
              />
            </div>
          </td>
          <td>
            <div
              className={`filter-content${
                volumen === "" || selectedVolumen === "Todos" ? "" : " active"
              }`}
            >
              <NumericSelect
                name="ns-volumen"
                value={volumen}
                setValue={setVolumen}
                selected={selectedVolumen}
                setSelected={setSelectedVolumen}
              />
            </div>
          </td>
          {isDinamo && <>
            <td>
            <div
              className={`filter-content${
                volumenPlanificado === "" || selectedVolumenPlanificado === "Todos" ? "" : " active"
              }`}
            >
              <NumericSelect
                name="ns-volumen-planificado"
                value={volumenPlanificado}
                setValue={setVolumenPlanificado}
                selected={selectedVolumenPlanificado}
                setSelected={setSelectedVolumenPlanificado}
              />
            </div>
          </td>
          <td>
            <div
              className={`filter-content${
                volumenEntregado === "" || selectedVolumenEntregado === "Todos" ? "" : " active"
              }`}
            >
              <NumericSelect
                name="ns-volumen-entregado"
                value={volumenEntregado}
                setValue={setVolumenEntregado}
                selected={selectedVolumenEntregado}
                setSelected={setSelectedVolumenEntregado}
              />
            </div>
          </td>
          
          </>}
        </tr>
        <tbody>
          {pedidos.length === 0 ? (
            <>
              <tr className="data">
                <td className="align-left" colSpan="100%">
                  {pedidosLoaded?"No hay pedidos que mostrar":"Seleccione una Estación de Servicio para ver la información."}
                </td>
              </tr>
              <tr className="data">
                <td className="align-left" colSpan="100%">
                  &nbsp;
                </td>
              </tr>
            </>
          ) : (
            filteredPedidos.map((p, key) => getLitros(p).isFiltrado? getLitros(p).litros>0?(
              <tr key={key} className="data">
                <td className="align-left">
                  <a className="underline cursor-pointer" onClick={()=>selectPedido(p)} >
                  <CustomHighlighter text={filterText}>
                    {p.ID_PEDIDO}
                  </CustomHighlighter>
                  </a>
                </td>
                <td className="align-left logistico">
                  <CustomHighlighter text={filterText}>
                    {p.PLANTA}
                  </CustomHighlighter>
                  {p.PEDIDO_LOGISTIC!=="" && isDinamo && <div className="mensaje">Creado por planta</div>}
                </td>
                <td className="align-left">
                  {/* <CustomHighlighter text={filterText}>
                    {p.TIPO_PEDIDO}
                  </CustomHighlighter> */}
                  <div 
                      className={`table-data-ellipsis 
                        pedidos-activos-table-tipo-pedido 
                        ${p.TIPO_PEDIDO.toLowerCase().includes('emergencia')? 'pedidos-activos-table-tipo-pedido-emergencia': ''}
                        ${p.TIPO_PEDIDO.toLowerCase().includes('preprograma')? 'pedidos-activos-table-tipo-pedido-preprograma': ''}
                        ${p.TIPO_PEDIDO.toLowerCase().includes('cambio')? 'pedidos-activos-table-tipo-pedido-cambio-fecha': ''}
                      `}
                    ><CustomHighlighter text={filterText}>{p.TIPO_PEDIDO.toUpperCase()}</CustomHighlighter>
                  </div>
                </td>
                <td className="align-left">
                  <CustomHighlighter text={filterText}>
                    {parseBudat(p.FECHA_PREFERENTE_ENTREGA)}
                  </CustomHighlighter>
                </td>
                <td className="align-left">
                  <CustomHighlighter text={filterText}>
                    {p.HORARIO}
                  </CustomHighlighter>
                </td>
                <td className="align-left">
                  <CustomHighlighter text={filterText}>
                    {p.ESTADO}
                  </CustomHighlighter>
                </td>
                <td className="align-left">
                  <CustomHighlighter text={filterText}>
                    {getLocaleStringNumber(getLitros(p).litros)}
                  </CustomHighlighter>
                </td>
                {isDinamo && <>
                  <td className="align-left">
                    <CustomHighlighter text={filterText}>
                      {getLocaleStringNumber(getLitros(p).planificado)}
                    </CustomHighlighter>
                  </td>
                  <td className="align-left">
                    <CustomHighlighter text={filterText}>
                      {getLocaleStringNumber(getLitros(p).entregado)}
                    </CustomHighlighter>
                  </td>
                </>}
              </tr>
            ):(<></>):(<tr key={key} className="data">
            <td className="align-left">
              <a className="underline cursor-pointer" onClick={()=>selectPedido(p)} >
              <CustomHighlighter text={filterText}>
                {p.ID_PEDIDO}
              </CustomHighlighter>
              </a>
            </td>
            <td className="align-left logistico">
              <CustomHighlighter text={filterText}>
                {p.PLANTA}
              </CustomHighlighter>
              {p.PEDIDO_LOGISTIC!=="" && isDinamo &&  <div className="mensaje">Creado por planta</div>}
            </td>
            <td className="align-left">
              {/* <CustomHighlighter text={filterText}>
                {p.TIPO_PEDIDO}
              </CustomHighlighter> */}
              <div 
                className={`table-data-ellipsis 
                  pedidos-activos-table-tipo-pedido 
                  ${p.TIPO_PEDIDO.toLowerCase().includes('emergencia')? 'pedidos-activos-table-tipo-pedido-emergencia': ''}
                  ${p.TIPO_PEDIDO.toLowerCase().includes('preprograma')? 'pedidos-activos-table-tipo-pedido-preprograma': ''}
                  ${p.TIPO_PEDIDO.toLowerCase().includes('cambio')? 'pedidos-activos-table-tipo-pedido-cambio-fecha': ''}
                `}
                ><CustomHighlighter text={filterText}>{p.TIPO_PEDIDO.toUpperCase()}</CustomHighlighter>
              </div>
            </td>
            <td className="align-left">
              <CustomHighlighter text={filterText}>
                {/* {parseBudat(p.FECHA_REAL_CLIENTE)} */}
                {parseBudat(p.FECHA_PREFERENTE_ENTREGA)}
              </CustomHighlighter>
            </td>
            <td className="align-left">
              <CustomHighlighter text={filterText}>
               {/*  {p.HORA_REAL_CLIENTE} */}
                {p.HORARIO}
              </CustomHighlighter>
            </td>
            <td className="align-left">
              <CustomHighlighter text={filterText}>
                {p.ESTADO}
              </CustomHighlighter>
            </td>
            <td className="align-left">
              <CustomHighlighter text={filterText}>
              {getLocaleStringNumber(p.VOLUMEN)}
              </CustomHighlighter>
            </td>
            {isDinamo && <>
              <td className="align-left">
                <CustomHighlighter text={filterText}>
                  {getLocaleStringNumber(p.VOLUMEN_PLANIFICADO)}
                </CustomHighlighter>
              </td>
              <td className="align-left">
                <CustomHighlighter text={filterText}>
                  {getLocaleStringNumber(p.VOLUMEN_ENTREGADO)}
                </CustomHighlighter>
              </td>
            </>}
          </tr>))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default HistorialPedidosTable;
