import React, { useState, useEffect } from "react"
import { useParams } from 'react-router-dom'
import HeaderData from "../HeaderData"
import HeaderBox from "../HeaderBox"
import saldoAzulIcon from "../../assets/img/saldo-azul.svg"
import imprimir from "../../assets/img/imprimir.svg"
import descargar from "../../assets/img/descargar.svg"
import { getUpdateDate } from "../utils/monthNames"
import { getExcelWithOneSheet, getExcelWithXlsx2 } from "../utils/utils"
import PrintComponent from "../PrintComponent"
import { useRef } from "react"
import CustomHighlighter from "../CustomHighlighter"

const BluemaxDetail = ({ isROImpersonate }) => {
  const info = [
    {
      tipo_tarjeta: "TCT",
      fecha: "01/08/2022",
      hora: "52.2",
      guia: "35.85",
      volumen: "64.5",
      saldo: "54.5"
    },
    {
      tipo_tarjeta: "TCT",
      fecha: "04/08/2022",
      hora: "15:35",
      guia: "45.15",
      volumen: "44.46",
      saldo: "14.46"
    },
    {
      tipo_tarjeta: "TCT",
      fecha: "20/08/2022",
      hora: "15:40",
      guia: "54.75",
      volumen: "82.7",
      saldo: "32.7"
    },
  ]

  let transaccionesForExcel = info.map(info=>{
    return(
      {
        A:info.tipo_tarjeta,
        B:info.fecha,
        C:info.hora,
        D:info.guia,
        E:info.volumen,
        F:info.saldo,
      }
    )
  })

  const infoForExcel = [
    {
      nombre: "Detalles",
      labels: {
        A: "Tipo de tarjeta",
        B: "Fecha",
        C: "Hora",
        D: "Guia",
        E: "Volumen",
        F: "Saldo",
      },
      transacciones: transaccionesForExcel
    }
  ]

  const [filterText, setFilterText] = useState('')
  const [data, setData] = useState();
  const [filteredData, setFilteredData] = useState();
  const tableRef = useRef(null);

  //const { type } = useParams()

  useEffect(() => {
    setData(info)
    setFilteredData(info)
  }, []);

  useEffect(() => {
    filterr();
  }, [filterText]);

  const filterr = () => {
    let dataArr
    if ((filterText !== "")) {
      let filterTextLower = filterText.toLowerCase();
      filterTextLower = filterTextLower.replaceAll(".", "");
      dataArr = data.filter(data => {
        return (
          data.tipo_tarjeta.toLowerCase().includes(filterTextLower) ||
          data.fecha.toLowerCase().includes(filterTextLower) ||
          data.hora.toLowerCase().includes(filterTextLower) ||
          data.guia.toLowerCase().includes(filterTextLower) ||
          data.volumen.toLowerCase().includes(filterTextLower) ||
          data.saldo.toLowerCase().includes(filterTextLower)
        )
      })
      setFilteredData(dataArr)
    } else {
      setFilteredData(info)
    }

  }

  const handleDownload = () => {
    getExcelWithXlsx2(infoForExcel, "detalle.xlsx")
  }



  return (<>
    <div className="header-box-multiple-container cartolaboxes">
      <HeaderBox
        monthInfo="Total Volumen mes"
        month=""
        amount="21.187,93 Lts"
        icon={saldoAzulIcon}
        color="blue"
        isFirst={true}
        addMoneySymbol={false}
      />

      <HeaderBox
        monthInfo="Total Venta"
        month=""
        amount="6.748,07"
        icon={saldoAzulIcon}
        color="blue"
      />
    </div>
    <hr />
    <div className="tct-tae-table-container bluemax">
      <div className="tct-tae-table-title">
        Detalle Agosto 2022
      </div>

      <div className="filters-container">
        <div className="filter">
          <div className="filter-input">
            <input
              type="search"
              className="search"
              value={filterText}
              placeholder="Buscar..."
              onChange={(e) => setFilterText(e.target.value)}
            />
          </div>
        </div>
        <div className="actions-container">
          <button className="icon-btn alert-container-tct-tae align-right cell-border-top-right" onClick={handleDownload}>
            <img src={descargar} />
            <div className="alert-tooltip confirmacion">
              <div className="content">
                Descargar
              </div>
            </div>
          </button>
          <button className="icon-btn left-button alert-container-tct-tae">
            <PrintComponent
              reff={tableRef}
              classes=""
              isROImpersonate={isROImpersonate}
            />
            <div className="alert-tooltip confirmacion">
              <div className="content">
                Imprimir
              </div>
            </div>
          </button>
        </div>
      </div>

      <table className="tct-tae-table" ref={tableRef}>
        <tr>
          <th>
            <div className="th-data width-115">
              <div className="th-text">Tipo Tarjeta</div>
            </div>
          </th>
          <th>
            <div className="th-data width-115">
              <div className="th-text">Fecha</div>
            </div>
          </th>
          <th>
            <div className="th-data width-115">
              <div className="th-text">Hora</div>
            </div>
          </th>
          <th>
            <div className="th-data width-115">
              <div className="th-text">N° Guía</div>
            </div>
          </th>
          <th>
            <div className="th-data width-174">
              <div className="th-text">Volumen (Lts)</div>
            </div>
          </th>
          <th>
            <div className="th-data width-174">
              <div className="th-text">Saldo (Lts)</div>
            </div>
          </th>
        </tr>
        <tr className="table-separator-row-border-top">
          <td colSpan="100%" />
        </tr>
        <tbody>
          {
            filteredData &&
            filteredData.map(data => {
              return (
                <tr className="data">
                  <td className="align-left">
                    <CustomHighlighter text={filterText}>
                      {data.tipo_tarjeta}
                    </CustomHighlighter>
                  </td>
                  <td className="align-left">
                    <CustomHighlighter text={filterText}>
                      {data.fecha}
                    </CustomHighlighter>
                  </td>
                  <td className="align-left">
                    <CustomHighlighter text={filterText}>
                      {data.hora}
                    </CustomHighlighter>
                  </td>
                  <td className="align-left">
                    <CustomHighlighter text={filterText}>
                      {data.guia}
                    </CustomHighlighter>
                  </td>
                  <td className="align-left">
                    <CustomHighlighter text={filterText}>
                      {data.volumen}
                    </CustomHighlighter>
                  </td>
                  <td className="align-left">
                    <CustomHighlighter text={filterText}>
                      {data.saldo}
                    </CustomHighlighter>
                  </td>
                </tr>
              )
            })
          }
          {/* <tr className="data">
            <td className="align-left">TCT</td>
            <td className="align-left">01/08/2022</td>
            <td className="align-left">13:01</td>
            <td className="align-left">621213610</td>
            <td className="align-left">32,71</td>
            <td className="align-left">-32,71</td>
          </tr>
          <tr className="data">
            <td className="align-left">TCT</td>
            <td className="align-left">01/08/2022</td>
            <td className="align-left">13:01</td>
            <td className="align-left">621213610</td>
            <td className="align-left">32,71</td>
            <td className="align-left">-32,71</td>
          </tr> */}
        </tbody>
      </table>

    </div>
  </>)
};

export default BluemaxDetail
