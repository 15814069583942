import { InputWithClearBtn } from "../InputWithClearBtn";
import Select from "react-select";
import { customStylesCierreDiario } from "./styles";
import EditarIcon from "../../assets/img/EDITAR.svg";
import VerIcon from "../../assets/img/VER.svg"

import { getAproximate3FloatString, 
  getNumberFromLocalString, 
  getLocaleStringFloat,
  SingleOption,
  getLocaleStringNumber,
  getLocaleStringRoundedInt
} from "./utils"

import { getMaterialClass, 
  getMaterialTitleCierre, 
  getMaterialMinititle,
  getMaterialClassFromTitleCierre,
  getMaterialMinititleFromTitleCierre
} from "./materiales"

import check_green from "../../assets/img/Check_green.svg";
import check_yellow from "../../assets/img/check_yellow.svg";
import check_red from "../../assets/img/atencion_rojo.svg";

import Timepicker from "../Timepicker"
import { getAlertIconVentas } from "./get";
import TimePickerLibrary from "../TimePickerLibrary";

function getAproximate3FloatNumber(value){
  if(!value || value === '0')
    return 0

  let floatNumber = parseFloat(value.toFixed(3))
  return floatNumber
}

export function getTanquesSifoneadosWithLocalNumber(tanque, mediciones, key, setFunction, allTanques = null){
  let sifonCamion
  if(parseInt(tanque.ID) > parseInt(tanque.SIFON_1) || parseInt(tanque.ID) > parseInt(tanque.SIFON_2))
    return
  
  if(allTanques && tanque.TIPO === 'camion'){
    let isOld = false
    allTanques.map((t, newKey) => {
      if(t.ID.split('-')[0] === tanque.ID.split('-')[0]){
        if(newKey < key)
          isOld = true
        else if(newKey > key)
          sifonCamion = t
      }
    })
    if(isOld)
      return
  }

  let totalSifones = 1, total = getNumberFromLocalString(mediciones[tanque.ID]) || 0

  if(tanque.SIFON_1 !== ''){
    totalSifones++
    total += getNumberFromLocalString(mediciones[tanque.SIFON_1]) || 0
  }

  if(tanque.SIFON_2 !== ''){
    totalSifones++
    total += getNumberFromLocalString(mediciones[tanque.SIFON_2]) || 0
  }

  if(sifonCamion){
    total += getNumberFromLocalString(mediciones[sifonCamion.ID]) || 0
  }

  total = getLocaleStringFloat(total)

  return <>
    <tr>
      <td className="horizontal-separator" colSpan={3} />
    </tr>
    <tr className={totalSifones > 1? 'only-row-span-cell-border': ''}>
      { sifonCamion &&
        <td rowSpan="2" className='fluctuaciones-column-with-border cell-border-top-left capitalize'>
          Camion<br /><span className="uppercase">{tanque.ID.split('-')[0]}</span>
        </td>
      }
      { !sifonCamion &&
        <td className={`tanque-name-column cell-border-top-left capitalize ${totalSifones === 1? 'cell-border-bottom-left': ''}`}>
          {tanque.TIPO} {tanque.TIPO === 'camion'? <br />: ''}<span className="uppercase">{tanque.ID.split('-')[0]}</span>
        </td>
      }
      <td rowSpan={totalSifones} className="container-cell">
        <div className="container-cell-content">
          <div className={`small-camion-compartment ${getMaterialClass(tanque.ID_MATERIAL)} cont2`}>
            <p className="small-camion-compartment__fuel-type-number">
              {getMaterialMinititle(tanque.ID_MATERIAL)}
            </p>
          </div>
          <span>{tanque.MATERIAL}</span>
        </div>
      </td>
      <td className={`cell-border-top-right ${(totalSifones === 1 && !sifonCamion)? 'cell-border-bottom-right':''}`}>
        <InputWithClearBtn className="table-input" type="text" onChange={(e) => setFunction(e.target.value, tanque.ID)} value={mediciones[tanque.ID]} />
      </td>
    </tr>
    { sifonCamion &&
      <>
        <tr>
          <td className="container-cell no-padding-left">
            <div className="container-cell-content">
              <div className={`small-camion-compartment ${getMaterialClass(sifonCamion.ID_MATERIAL)} cont2`}>
                <p className="small-camion-compartment__fuel-type-number">
                  {getMaterialMinititle(sifonCamion.ID_MATERIAL)}
                </p>
              </div>
              <span>{sifonCamion.MATERIAL}</span>
            </div>
          </td>
          <td>
            <InputWithClearBtn className="table-input" type="text" onChange={(e) => setFunction(e.target.value, sifonCamion.ID)} value={mediciones[sifonCamion.ID]} />
          </td>
        </tr>
        <tr className='row-with-border sifon-camiones-separator'>
          <td /><td /><td />
        </tr>
      </>
    }
    { totalSifones > 1 &&
      <>
        <tr className={totalSifones === 2? 'row-with-border' : ''}>
          <td className="tank-name-cell">Tanque {tanque.SIFON_1}</td>
          <td>
            <InputWithClearBtn className="table-input" type="text" onChange={(e) => setFunction(e.target.value, tanque.SIFON_1)} value={mediciones[tanque.SIFON_1]} />
          </td>
        </tr>
      
        { totalSifones === 3 &&
          <tr className="row-with-border">
            <td className="tank-name-cell">Tanque {tanque.SIFON_2}</td>
            <td>
              <InputWithClearBtn className="table-input" type="text" onChange={(e) => setFunction(e.target.value, tanque.SIFON_2)} value={mediciones[tanque.SIFON_2]} />
            </td>
          </tr>
        }
      </>
    }
    { (sifonCamion || totalSifones > 1) &&
      <tr className="bold-row">
        <td className="cell-border-bottom-left" colSpan={2}>
          { sifonCamion &&
            <>Total Camion <span className="uppercase">{tanque.ID.split('-')[0]}</span></>
          }
          { totalSifones > 1 &&
            <>Total tanques {`${tanque.ID}${totalSifones > 1? ` - ${tanque.SIFON_1}`: ''}${totalSifones > 2? ` - ${tanque.SIFON_2}`: ''}`}</>
          }
        </td>
        <td className="cell-border-bottom-right right-aligned-cell ">
          {total}
        </td>
      </tr>
    }
  </>
}

function getTotalSumSifones(tanque, name, mediciones){
  let found = false

  let sum = 0

  if(mediciones[tanque.ID][name] !== null && mediciones[tanque.ID][name] !== undefined){
    found = true
    sum += parseFloat(mediciones[tanque.ID][name])
  }

  if(tanque.SIFON_1 !== "" && mediciones[tanque.SIFON_1][name] !== null && mediciones[tanque.SIFON_1][name] !== undefined){
    found = true
    sum += parseFloat(mediciones[tanque.SIFON_1][name])
  }

  if(tanque.SIFON_2 !== "" && mediciones[tanque.SIFON_2][name] !== null && mediciones[tanque.SIFON_2][name] !== undefined){
    found = true
    sum += parseFloat(mediciones[tanque.SIFON_2][name])
  }

  if(!found)
    return ''

  return getLocaleStringFloat(sum)
}

export function getTanquesSifoneadosWithLocalNumberCierreDiario(tanque, mediciones, key, setFunction, allTanques = null){
  let sifonCamion
  if(parseInt(tanque.ID) > parseInt(tanque.SIFON_1) || parseInt(tanque.ID) > parseInt(tanque.SIFON_2))
    return

  //BORRADO PORQUE SE REPETIA UN MATERIAL EN UN CAMION
  /* if(allTanques && tanque.TIPO === 'camion'){
    let isOld = false
    allTanques.map((t, newKey) => {
      if(t.ID.split('-')[0] === tanque.ID.split('-')[0]){
        if(newKey < key)
          isOld = true
        else if(newKey > key)
          sifonCamion = t
      }
    })
    if(isOld)
      return
  } */

  let totalSifones = 1, total = getNumberFromLocalString(mediciones[tanque.ID]?.medicion) || 0

  if(tanque.SIFON_1 !== ''){
    totalSifones++
    total += getNumberFromLocalString(mediciones[tanque.SIFON_1]?.medicion) || 0
  }

  if(tanque.SIFON_2 !== ''){
    totalSifones++
    total += getNumberFromLocalString(mediciones[tanque.SIFON_2]?.medicion) || 0
  }

  if(sifonCamion){
    total += getNumberFromLocalString(mediciones[sifonCamion.ID]?.medicion) || 0
  }

  total = getLocaleStringFloat(total)

  return <>
    <tr>
      <td className="horizontal-separator" colSpan={8} />
    </tr>
    <tr className={totalSifones > 1? 'only-row-span-cell-border': ''}>
      { sifonCamion &&
        <td rowSpan="2" className='fluctuaciones-column-with-border cell-border-top-left capitalize'>
          <div className="cierre-diario-table-tanque-name">
            Camion<br /><span className="uppercase">{tanque.ID}</span>
          </div>
        </td>
      }
      { !sifonCamion &&
        <td className={`tanque-name-column cell-border-top-left capitalize ${totalSifones === 1? 'cell-border-bottom-left': ''}`}>
          <div className="cierre-diario-table-tanque-name">
            {tanque.TIPO} {tanque.TIPO === 'camion'? <br />: ''}<span className="uppercase">{tanque.ID}</span>
          </div>
        </td>
      }
      <td rowSpan={totalSifones} className="container-cell">
        <div className="container-cell-content">
          <div className={`small-camion-compartment ${getMaterialClass(tanque.ID_MATERIAL)} cont2`}>
            <p className="small-camion-compartment__fuel-type-number">
              {getMaterialMinititle(tanque.ID_MATERIAL)}
            </p>
          </div>
          <span>{getMaterialTitleCierre(tanque.ID_MATERIAL)}</span>
        </div>
      </td>
      <td>
        <TimePickerLibrary 
          initialHour={mediciones[tanque.ID].hora} 
          onSelect={(e) => setFunction('hora', e, tanque.ID, tanque.ID_MATERIAL)}
          disabled={tanque.TIPO === 'camion'? true: false}
        />
        {/* <Timepicker
          value={mediciones[tanque.ID].hora}
          onSelect={(e) => setFunction('hora', e, tanque.ID, tanque.ID_MATERIAL)}
          className="cierre-diario-timepicker display-on-top-picker"
        /> */}
      </td>
      <td className="cierre-diario-ingreso-mediciones-input">
        <InputWithClearBtn 
          className="table-input" 
          type="text" 
          onChange={(e) => setFunction('medicion', e.target.value, tanque.ID)} 
          value={mediciones[tanque.ID]?.medicion}
        />
      </td>
      <td>
        <div className="cierre-diario-ingreso-mediciones-data">{ getLocaleStringFloat(mediciones[tanque.ID]?.entrada_pos_medicion) }</div>
      </td>
      <td className={`cell-border-top-right ${(totalSifones === 1 && !sifonCamion)? 'cell-border-bottom-right':''}`}>
        <div className="cierre-diario-ingreso-mediciones-data">{ getLocaleStringFloat(mediciones[tanque.ID]?.salida_pos_medicion) }</div>
      </td>
      <td className="cierre-diario-table-grey-border"></td>
      <td className={`cell-border-top-left cell-border-top-right ${(totalSifones === 1 && !sifonCamion)? 'cell-border-bottom-right cell-border-bottom-left':''}`}>
        <div className="cierre-diario-ingreso-mediciones-data">{ getLocaleStringFloat(mediciones[tanque.ID]?.total) }</div>
      </td>
    </tr>
    { sifonCamion &&
      <>
        <tr>
          <td className="container-cell no-padding-left">
            <div className="container-cell-content">
              <div className={`small-camion-compartment ${getMaterialClass(sifonCamion.ID_MATERIAL)} cont2`}>
                <p className="small-camion-compartment__fuel-type-number">
                  {getMaterialMinititle(sifonCamion.ID_MATERIAL)}
                </p>
              </div>
              <span>{getMaterialTitleCierre(sifonCamion.ID_MATERIAL)}</span>
            </div>
          </td>

          <td>
            <TimePickerLibrary 
              initialHour={mediciones[sifonCamion.ID].hora} 
              onSelect={(e) => setFunction('hora', e, sifonCamion.ID)}
              disabled={true}
            />
            {/* <Timepicker 
              value={mediciones[sifonCamion.ID].hora}
              onSelect={(e) => setFunction('hora', e, sifonCamion.ID)}
              className="cierre-diario-timepicker display-on-top-picker"
            /> */}
          </td>
          <td>
            <InputWithClearBtn 
              className="table-input" 
              type="text" 
              onChange={(e) => setFunction('medicion', e.target.value, sifonCamion.ID)} 
              value={mediciones[sifonCamion.ID]?.medicion} 
            />
          </td>
          <td>
            <div className="cierre-diario-ingreso-mediciones-data">
              {getLocaleStringFloat(mediciones[sifonCamion.ID]?.entrada_pos_medicion)}
            </div>
          </td>
          <td>
            <div className="cierre-diario-ingreso-mediciones-data">
              {getLocaleStringFloat(mediciones[sifonCamion.ID]?.salida_pos_medicion)}
            </div>
          </td>
          <td className="cierre-diario-table-grey-border"></td>
          <td>
            <div className="cierre-diario-ingreso-mediciones-data">
              {getLocaleStringFloat(mediciones[sifonCamion.ID]?.total)}
            </div>
          </td>
        </tr>
        <tr className='row-with-border sifon-camiones-separator'>
          <td /><td /><td /><td /><td /><td /><td /><td />
        </tr>
      </>
    }
    { totalSifones > 1 &&
      <>
        <tr className={totalSifones === 2? 'row-with-border' : ''}>
          <td className="tank-name-cell">Tanque {tanque.SIFON_1}</td>

          <td>
            <TimePickerLibrary 
              initialHour={mediciones[tanque.SIFON_1].hora} 
              onSelect={(e) => setFunction('hora', e, tanque.SIFON_1)}
              disabled={tanque.TIPO === 'camion'? true: false}
            />
            {/* <Timepicker 
              value={mediciones[tanque.SIFON_1].hora}
              onSelect={(e) => setFunction('hora', e, tanque.SIFON_1)}
              className="cierre-diario-timepicker display-on-top-picker"
            /> */}
          </td>
          <td>
            <InputWithClearBtn className="table-input" type="text" onChange={(e) => setFunction('medicion', e.target.value, tanque.SIFON_1)} value={mediciones[tanque.SIFON_1]?.medicion} />
          </td>
          <td>
            <div className="cierre-diario-ingreso-mediciones-data">
              {getLocaleStringFloat(mediciones[tanque.SIFON_1]?.entrada_pos_medicion)}
            </div>
          </td>
          <td>
            <div className="cierre-diario-ingreso-mediciones-data">
              {getLocaleStringFloat(mediciones[tanque.SIFON_1]?.salida_pos_medicion)}
            </div>
          </td>
          <td className="cierre-diario-table-grey-border"></td>
          <td>
            <div className="cierre-diario-ingreso-mediciones-data">
              {getLocaleStringFloat(mediciones[tanque.SIFON_1]?.total)}
            </div>
          </td>
        </tr>
      
        { totalSifones === 3 &&
          <tr className="row-with-border">
            <td className="tank-name-cell">Tanque {tanque.SIFON_2}</td>
            <td>
              <TimePickerLibrary 
                initialHour={mediciones[tanque.SIFON_2].hora} 
                onSelect={(e) => setFunction('hora', e, tanque.SIFON_2)}
                disabled={tanque.TIPO === 'camion'? true: false}
              />
              {/* <Timepicker
                value={mediciones[tanque.SIFON_2].hora}
                onSelect={(e) => setFunction('hora', e, tanque.SIFON_2)}
                className="cierre-diario-timepicker display-on-top-picker"
              /> */}
            </td>
            <td>
              <InputWithClearBtn className="table-input" type="text" onChange={(e) => setFunction('medicion', e.target.value, tanque.SIFON_2)} value={mediciones[tanque.SIFON_2]?.medicion} />
            </td>
            <td>
              <div className="cierre-diario-ingreso-mediciones-data">
                {getLocaleStringFloat(mediciones[tanque.SIFON_2]?.entrada_pos_medicion)}
              </div>
            </td>
            <td>
              <div className="cierre-diario-ingreso-mediciones-data">
                {getLocaleStringFloat(mediciones[tanque.SIFON_2]?.salida_pos_medicion)}
              </div>
            </td>
            <td className="cierre-diario-table-grey-border"></td>
            <td>
              <div className="cierre-diario-ingreso-mediciones-data">
                {getLocaleStringFloat(mediciones[tanque.SIFON_2]?.total)}
              </div>
            </td>
          </tr>
        }
      </>
    }
    { (sifonCamion || totalSifones > 1) &&
      <tr className="bold-row">
        <td className="cell-border-bottom-left" colSpan={2}>
          { sifonCamion &&
            <>Total Camion <span className="uppercase">{tanque.ID.split('-')[0]}</span></>
          }
          { totalSifones > 1 &&
            <>Total tanques {`${tanque.ID}${totalSifones > 1? ` - ${tanque.SIFON_1}`: ''}${totalSifones > 2? ` - ${tanque.SIFON_2}`: ''}`}</>
          }
        </td>
        <td></td>
        <td></td>
        <td>
          <div className="cierre-diario-ingreso-mediciones-data-total">
            {getTotalSumSifones(tanque, 'entrada_pos_medicion', mediciones)}
          </div>
        </td>
        <td className="cell-border-bottom-right">
          <div className="cierre-diario-ingreso-mediciones-data-total">
            {getTotalSumSifones(tanque, 'salida_pos_medicion', mediciones)}
          </div>
        </td>
        <td className="cierre-diario-table-grey-border"></td>
        <td className="cell-border-bottom-left cell-border-bottom-right">
          <div className="cierre-diario-ingreso-mediciones-data-total">
            {getTotalSumSifones(tanque, 'total', mediciones)}
          </div>
        </td>

      </tr>
    }
  </>
}

export function getTanquesSifoneadosConfirmacion(tanque, key, resumenes, mediciones, medicionesAnterior, allTanques = null){
  const resumen = resumenes.find(t => t.ID_TANQUES.includes(tanque.ID))
  
  const medicion = mediciones.find(m => m.ID_TANQUE === tanque.ID)
  let medicionAnterior = medicionesAnterior.find(m => m.ID_TANQUE === tanque.ID)
  if (!medicionAnterior) medicionAnterior = {VOLUMEN: 0}

  let medicion1 = 0, medicion2 = 0, medicionAnterior1 = 0, medicionAnterior2 = 0

  if(parseInt(tanque.ID) > parseInt(tanque.SIFON_1) || parseInt(tanque.ID) > parseInt(tanque.SIFON_2))
    return

  let sifonCamion, resumenSifonCamion
  
  if(allTanques && tanque.TIPO === 'camion'){
    let isOld = false
    allTanques.map((t, newKey) => {
      if(t.ID.split('-')[0] === tanque.ID.split('-')[0]){
        if(newKey < key)
          isOld = true
        else if(newKey > key){
          sifonCamion = t
          resumenSifonCamion = resumenes.find(t => t.ID_TANQUES.includes(sifonCamion.ID))
        }
      }
    })
    if(isOld)
      return
  }

  if(sifonCamion){
    medicion1 = mediciones.find(m => m.ID_TANQUE === sifonCamion.ID)
    medicionAnterior1 = medicionesAnterior.find(m => m.ID_TANQUE === sifonCamion.ID)
    if (!medicionAnterior1) medicionAnterior1 = {VOLUMEN: 0}
  }

  let totalSifones = 1
  if(tanque.SIFON_1 !== ''){
    totalSifones++
    medicion1 = mediciones.find(m => m.ID_TANQUE === tanque.SIFON_1)
    medicionAnterior1 = medicionesAnterior.find(m => m.ID_TANQUE === tanque.SIFON_1)
    if (!medicionAnterior1) medicionAnterior1 = {VOLUMEN: 0}
  }

  if(tanque.SIFON_2 !== ''){
    totalSifones++
    medicion2 = mediciones.find(m => m.ID_TANQUE === tanque.SIFON_2)
    medicionAnterior2 = medicionesAnterior.find(m => m.ID_TANQUE === tanque.SIFON_2)
    if (!medicionAnterior2) medicionAnterior2 = {VOLUMEN: 0}
  }

  //
  
  const totalMedicionesAnterior = getAproximate3FloatString((getAproximate3FloatNumber(medicionAnterior.VOLUMEN) + getAproximate3FloatNumber(medicionAnterior1.VOLUMEN) + getAproximate3FloatNumber(medicionAnterior2.VOLUMEN)))

  const entradas = getAproximate3FloatString(resumen.ENTRADA / totalSifones)
  const volumenMedicion = getAproximate3FloatString(resumen.VOLUMEN_MEDIDO / totalSifones)
  const volumenTeorico = getAproximate3FloatString(resumen.VOLUMEN_TEORICO / totalSifones)
  const salidas = getAproximate3FloatString(resumen.SALIDA / totalSifones)
  const fluctuacion = getAproximate3FloatString(resumen.FLUCTUACION / totalSifones)

  const totalEntrada = getAproximate3FloatString(resumen.ENTRADA + (resumenSifonCamion?.ENTRADA || 0))
  const totalSalida = getAproximate3FloatString(resumen.SALIDA + (resumenSifonCamion?.SALIDA || 0))
  const totalVolumenTeorico = getAproximate3FloatString(resumen.VOLUMEN_TEORICO + (resumenSifonCamion?.VOLUMEN_TEORICO || 0))
  const totalVolumenMedido = getAproximate3FloatString(resumen.VOLUMEN_MEDIDO + (resumenSifonCamion?.VOLUMEN_MEDIDO || 0))
  const totalFluctuacion = getAproximate3FloatString(resumen.FLUCTUACION + (resumenSifonCamion?.FLUCTUACION || 0))

  return <>
    <tr>
      <td className="horizontal-separator" colSpan={3} />
    </tr>

    <tr className={totalSifones > 1? 'only-row-span-cell-border': ''}>
      { sifonCamion &&
        <td rowSpan="2" className='fluctuaciones-column-with-border cell-border-top-left capitalize'>
          Camion<br /><span className="uppercase">{tanque.ID.split('-')[0]}</span>
        </td>
      }
      { !sifonCamion &&
        <td className={`tanque-name-column cell-border-top-left capitalize ${totalSifones === 1? 'cell-border-bottom-left': ''}`}>
          {tanque.TIPO} {tanque.TIPO === 'camion'? <br />: ''}<span className="uppercase">{tanque.ID.split('-')[0]}</span>
        </td>
      }
      <td rowSpan={totalSifones} className="container-cell">
        <div className="container-cell-content">
          <div className={`small-camion-compartment ${getMaterialClass(tanque.ID_MATERIAL)} cont2`}>
            <p className="small-camion-compartment__fuel-type-number">
              {getMaterialMinititle(tanque.ID_MATERIAL)}
            </p>
          </div>
          <span>{tanque.MATERIAL}</span>
        </div>
      </td>
      <td>
        {medicionAnterior && getAproximate3FloatString(medicionAnterior.VOLUMEN)}
      </td>
      <td>
        {entradas}
      </td>
      <td>
        {salidas}
      </td>
      <td>
        {volumenTeorico}
      </td>
      <td>
        {volumenMedicion}
      </td>
      <td className={`cell-border-top-right ${(totalSifones === 1 && !sifonCamion)? 'cell-border-bottom-right':''}`}>
        {fluctuacion}
      </td>
    </tr>

    { sifonCamion &&
      <>
        <tr>
          <td className="container-cell no-padding-left">
            <div className="container-cell-content">
              <div className={`small-camion-compartment ${getMaterialClass(sifonCamion.ID_MATERIAL)} cont2`}>
                <p className="small-camion-compartment__fuel-type-number">
                  {getMaterialMinititle(sifonCamion.ID_MATERIAL)}
                </p>
              </div>
              <span>{sifonCamion.MATERIAL}</span>
            </div>
          </td>
          <td>
            {medicionAnterior1 && getAproximate3FloatString(medicionAnterior1.VOLUMEN)}
          </td>
          <td>
            {getAproximate3FloatString(resumenSifonCamion.ENTRADA)}
          </td>
          <td>
            {getAproximate3FloatString(resumenSifonCamion.SALIDA)}
          </td>
          <td>
            {getAproximate3FloatString(resumenSifonCamion.VOLUMEN_TEORICO)}
          </td>
          <td>
            {getAproximate3FloatString(resumenSifonCamion.VOLUMEN_MEDIDO)}
          </td>
          <td>
            {getAproximate3FloatString(resumenSifonCamion.FLUCTUACION)}
          </td>
        </tr>
        <tr className='row-with-border sifon-camiones-separator'>
          <td /><td /><td /><td /><td /><td /><td /><td />
        </tr>
      </>
    }

    { totalSifones > 1 &&
      <>
        <tr className={totalSifones === 2? 'row-with-border' : ''}>
          <td className="tank-name-cell">Tanque {tanque.SIFON_1}</td>
          <td>
            {medicionAnterior1 && getAproximate3FloatString(medicionAnterior1.VOLUMEN)}
          </td>
          <td>
            {entradas}
          </td>
          <td>
            {salidas}
          </td>
          <td>
            {volumenTeorico}
          </td>
          <td>
            {volumenMedicion}
          </td>
          <td>
            {fluctuacion}
          </td>
        </tr>
      
        { totalSifones === 3 &&
          <tr className="row-with-border">
            <td className="tank-name-cell">Tanque {tanque.SIFON_2}</td>
            <td>
              {medicionAnterior2 && getAproximate3FloatString(medicionAnterior2.VOLUMEN)}
            </td>
            <td>
              {entradas}
            </td>
            <td>
              {salidas}
            </td>
            <td>
              {volumenTeorico}
            </td>
            <td>
              {volumenMedicion}
            </td>
            <td>
              {fluctuacion}
            </td>
          </tr>
        }
      </>
    }

    { (sifonCamion || totalSifones > 1) &&
      <tr className="bold-row">
        <td className="cell-border-bottom-left" colSpan={2}>
          { sifonCamion &&
            <>Total Camion <span className="uppercase">{tanque.ID.split('-')[0]}</span></>
          }
          { totalSifones > 1 &&
            <>Total tanques {`${tanque.ID}${totalSifones > 1? ` - ${tanque.SIFON_1}`: ''}${totalSifones > 2? ` - ${tanque.SIFON_2}`: ''}`}</>
          }
        </td>
        <td>
          {totalMedicionesAnterior}
        </td>
        <td>
          {totalEntrada}
        </td>
        <td>
          {totalSalida}
        </td>
        <td>
          {totalVolumenTeorico}
        </td>
        <td>
          {totalVolumenMedido}
        </td>
        <td className="cell-border-bottom-right">
          {totalFluctuacion}
        </td>
      </tr>
    }
  </>
}

function getAlertaImg(color){
  if(color === "ROJA")
    return <img src={check_red} />

  else if(color === "AMARILLA")
    return <img src={check_yellow} />

  else if(color === "VERDE")
    return <img src={check_green} />
}

function getAlertaTooltipText(color){
  if(color === "ROJA")
    return "Fuera de Rango"

  else if(color === "AMARILLA")
    return "Dentro del Rango"

  else if(color === "VERDE")
    return "Sin Fluctuación"
}

function getMiddleTableForConfirmacionCierreDetail(tdClassName, isTotal, data){

  return <td className={tdClassName}>
    {isTotal &&
      <div className="cierre-diario-confirmacion-data-total">
        {data}
      </div>
    }
    {!isTotal &&
      <>{data}</>
    }
  </td>
}

function getMiddleTableForConfirmacionCierre(data, isTotal){
  const classNameTd = isTotal? "": "cierre-diario-align-right"
  let porcentaje = parseFloat(data.fluctuacion_porcentaje)
  porcentaje = porcentaje.toString().replace(".", ',') + '%'

  return <>
    {getMiddleTableForConfirmacionCierreDetail(classNameTd, isTotal, getLocaleStringNumber(data.medicion_inicial))}
    {getMiddleTableForConfirmacionCierreDetail(classNameTd, isTotal, getLocaleStringNumber(data.entradas))}
    {getMiddleTableForConfirmacionCierreDetail(classNameTd, isTotal, getLocaleStringNumber(data.salidas))}
    {getMiddleTableForConfirmacionCierreDetail(classNameTd, isTotal, getLocaleStringNumber(data.salida_acumulada_lts))}
    {getMiddleTableForConfirmacionCierreDetail(classNameTd, isTotal, getLocaleStringNumber(data.inventario_teorico))}
    {getMiddleTableForConfirmacionCierreDetail(classNameTd, isTotal, getLocaleStringNumber(data.medicion_fisica_proyectada))}
    {getMiddleTableForConfirmacionCierreDetail(classNameTd, isTotal, getLocaleStringNumber(data.fluctuacion_diaria_lts))}
    {getMiddleTableForConfirmacionCierreDetail(classNameTd, isTotal, getLocaleStringNumber(data.fluctuacion_acumulada_lts))}
    {getMiddleTableForConfirmacionCierreDetail(classNameTd, isTotal, porcentaje)}
    {!isTotal &&
      <td className={`alert-container-cierre-diario align-right cell-border-top-right ${(data.totalSifones === 1)? 'cell-border-bottom-right':''}`}>
        {getAlertaImg(data.alerta)}
        <div className="alert-tooltip confirmacion">
          <div className="content">
            {getAlertaTooltipText(data.alerta)}
          </div>
        </div>
      </td>
    }
  </>
}

export function getTanquesSifoneadosFluctuacionesCierreMensual(tanques,key,openJustificacion, estadoCierre){
  const totalSifones = tanques.tanques.length > 0? tanques.tanques.length: 1
  const tanque1 = tanques.tanques.length > 0? tanques.tanques[0]: tanques.total
  const tanque2 = tanques.tanques.length > 1? tanques.tanques[1]: null
  const tanque3 = tanques.tanques.length > 2? tanques.tanques[2]: null

  console.log(tanque1)

  console.log("total",totalSifones)

  return <>
    <tr className={key%2===0?"odd":"even"}>
      <td className="def-tanque-container" rowSpan={totalSifones}>
        <div className={`type nomarginright ${tanques.form_com.toLowerCase()} ${"tanques"+totalSifones}`}></div>             
      </td>
      <td className="def-tanque-container" >
        <div className="def-tanque">
          <div className="top-row">{tanque1.tanque}</div>
          <div className="bottom-row">{tanques.material==="KEROSENE"?"KERO":tanques.material}</div>
        </div>              
      </td>
      {getMiddleTableFluctuacionCierreMensual(tanque1, tanques, openJustificacion, estadoCierre)}
    </tr> 
    {totalSifones>1 && <>

      {tanques.tanques.slice(1).map((tanque,index)=>{
        return(<>
          <tr className={key%2===0?"odd":"even"}>
              <td className="def-tanque-container">
                <div className="def-tanque">
                  <div className="top-row">{tanque.tanque}</div>
                  <div className="bottom-row">{tanques.material==="KEROSENE"?"KERO":tanques.material}</div>
                </div>              
              </td>
              {getMiddleTableFluctuacionCierreMensual(tanque, tanques, openJustificacion, estadoCierre)}
            </tr>
        </>)
      })}

      

      {/* {totalSifones === 2 && <>

          <tr className={key%2===0?"odd":"even"}>
              <td className="def-tanque-container">
                <div className="def-tanque">
                  <div className="top-row">{tanque2.tanque}</div>
                  <div className="bottom-row">{tanques.material==="KEROSENE"?"KERO":tanques.material}</div>
                </div>              
              </td>
              {getMiddleTableFluctuacionCierreMensual(tanque2, tanques, openJustificacion, estadoCierre)}
            </tr>
      </>}

      {totalSifones === 3 && <>

      <tr className={key%2===0?"odd":"even"}>
          <td className="def-tanque-container">
            <div className="def-tanque">
              <div className="top-row">{tanque3.tanque}</div>
              <div className="bottom-row">{tanques.material==="KEROSENE"?"KERO":tanques.material}</div>
            </div>              
          </td>
          {getMiddleTableFluctuacionCierreMensual(tanque3, tanques, openJustificacion, estadoCierre)}
        </tr>
      </>} */}            

    </>}

    {totalSifones>1 && <>
      <tr className={key%2===0?"odd total-row":"even total-row"}>
        <td colSpan={2} className="total-text-sifones">
          Total         
        </td>
        <td className="total-data-container"><div className="total-data">{getLocaleStringRoundedInt(tanques.total.medicion_inicial)}</div></td>
        <td className="total-data-container"><div className="total-data">{getLocaleStringRoundedInt(tanques.total.entradas)}</div></td>
        <td className="total-data-container"><div className="total-data">{getLocaleStringRoundedInt(tanques.total.salidas)}</div></td>
        <td className="total-data-container"><div className="total-data">
          {
          estadoCierre === "ABIERTO" || estadoCierre === "REABIERTO" || estadoCierre === "EN REVISIÓN" ?
            "-":getLocaleStringRoundedInt(tanques.total.fluctuacion_documentada)
          }
        </div></td>
        <td className="total-data-container"><div className="total-data">{getLocaleStringRoundedInt(tanques.total.inventario_teorico)}</div></td>
        <td className="total-data-container"><div className="total-data">{getLocaleStringRoundedInt(tanques.total.medicion_fisica_proyectada)}</div></td>
        <td className="total-data-container"><div className="total-data">{getLocaleStringRoundedInt(tanques.total.fluctuacion_calculada_lts)}</div></td>
        <td className="total-data-container"><div className="total-data">{tanques.total.fluctuacion_calculada_porcentaje.toString().replace(".", ',')}</div></td>
        <td className="total-data-container"><div className="total-data">{getLocaleStringRoundedInt(tanques.total.fluctuacion_confirmada_lts)}</div></td>
        <td className="total-data-container"><div className="total-data">{tanques.total.fluctuacion_confirmada_porcentaje.toString().replace(".", ',') }</div></td>
        <td className="align-center alerta-container"> </td>
        <td className="align-center">-</td>
      </tr>
    </>}
    
  </>


}

export function getMiddleTableFluctuacionCierreMensual(tanque, material, openJustificacion, estadoCierre){
  return <>
  <td>{getLocaleStringRoundedInt(tanque.medicion_inicial)}</td>
      <td>{getLocaleStringRoundedInt(tanque.entradas)}</td>
      <td>{getLocaleStringRoundedInt(tanque.salidas)}</td>
      <td>
        {
          estadoCierre === "ABIERTO" || estadoCierre === "REABIERTO" || estadoCierre === "EN REVISIÓN" ?
            "-" : getLocaleStringRoundedInt(tanque.fluctuacion_documentada)
        }
      </td>
      <td>{getLocaleStringRoundedInt(tanque.inventario_teorico)}</td>
      <td>{getLocaleStringRoundedInt(tanque.medicion_fisica_proyectada)}</td>
      <td>{getLocaleStringRoundedInt(tanque.fluctuacion_calculada_lts)}</td>
      <td>{tanque.fluctuacion_calculada_porcentaje.toString().replace(".", ',') }</td>
      <td>{getLocaleStringRoundedInt(tanque.fluctuacion_confirmada_lts)}</td>
      <td>{tanque.fluctuacion_confirmada_porcentaje.toString().replace(".", ',') }</td>
      <td className="align-center alerta-container alert-container-cierre-mensual">{getAlertIconVentas(tanque.alerta)}
        <div className="alert-tooltip">
          <div className="content">
            {
              tanque.alerta === "VERDE"&& "alerta verde"
            }
            {
              tanque.alerta === "AMARILLA"&& "alerta amarilla"
            }
            {
              tanque.alerta === "ROJA"&& "alerta roja"
            }
          </div>
      </div>
      </td>
      <td className="align-center">
        {
          tanque.justificacion === "INGRESAR" &&
            <img className="cierre-mensual-justificar-icon" onClick={()=>openJustificacion(tanque, material, "editar")} src={EditarIcon} />
        }
        {
          tanque.justificacion === "VER" &&
            <img className="cierre-mensual-justificar-icon" onClick={()=>openJustificacion(tanque, material, "ver")} src={VerIcon} />
        }
        {
          tanque.justificacion === "" &&
            "-"
        }
      </td>
  </>
}

export function getTanquesSifoneadosConfirmacionCierreDiario(tanques, key){  
  const totalSifones = tanques.tanques.length > 0? tanques.tanques.length: 1
  const tanque1 = tanques.tanques.length > 0? tanques.tanques[0]: tanques.total
  /* const tanque2 = tanques.tanques.length > 1? tanques.tanques[1]: null
  const tanque3 = tanques.tanques.length > 2? tanques.tanques[2]: null
  const tanque4 = tanques.tanques.length > 3? tanques.tanques[3]: null */

  return <>
    <tr>
      <td className="horizontal-separator" colSpan={3} />
    </tr>

    <tr className={totalSifones > 1? 'only-row-span-cell-border': ''}>
      <td className={`tanque-name-column cell-border-top-left capitalize ${totalSifones === 1? 'cell-border-bottom-left': ''}`}>
        <span>{tanque1.tanque}</span>
      </td>
      <td rowSpan={totalSifones} className="container-cell">
        <div className="container-cell-content">
          <div className={`small-camion-compartment ${getMaterialClassFromTitleCierre(tanques.material)} cont2`}>
            <p className={`small-camion-compartment__fuel-type-number${["GLP","GNC"].includes(getMaterialMinititleFromTitleCierre(tanques.material))?" gas":""}`}>
              {getMaterialMinititleFromTitleCierre(tanques.material)}
            </p>
          </div>
          <span>{tanques.material.toUpperCase()}</span>
        </div>
      </td>
      {getMiddleTableForConfirmacionCierre(tanque1, false)}
    </tr>

    { totalSifones > 1 &&
      <>
      {tanques.tanques.slice(1).map((tanque,index)=>{
        return(<>
          <tr className={tanques.tanques.slice(1).length === index+1? 'row-with-border' : ''}>
            <td className="tank-name-cell">{tanque.tanque}</td>
            {getMiddleTableForConfirmacionCierre(tanque, false)}
          </tr>
        </>)
      })}
        {/* <tr className={totalSifones === 2? 'row-with-border' : ''}>
          <td className="tank-name-cell">{tanque2.tanque}</td>
          {getMiddleTableForConfirmacionCierre(tanque2, false)}
        </tr>
      
        { totalSifones >= 3 &&
          <tr className={totalSifones === 3? 'row-with-border' : ''}>
            <td className="tank-name-cell">{tanque3.tanque}</td>
            {getMiddleTableForConfirmacionCierre(tanque3, false)}
          </tr>
        }
        { totalSifones >= 4 &&
          <tr className="row-with-border">
            <td className="tank-name-cell">{tanque4.tanque}</td>
            {getMiddleTableForConfirmacionCierre(tanque4, false)}
          </tr>
        } */}
      </>
    }

    { totalSifones > 1 &&
      <tr className="bold-row">
        <td className="cell-border-bottom-left" colSpan={2}>
          <>Total {tanques.total.tanque}</>  
        </td>
        {getMiddleTableForConfirmacionCierre(tanques.total, true)}
        <td className="cell-border-bottom-right"></td>
      </tr>
    }
  </>
}

function getRowForResumenTanquesCierreDiario(tanque, key, tanquesLength, type){
  if(key === 0)
    return

  const isLast = tanquesLength -1 === key? true: false
  return(
    <tr className={isLast? 'row-with-border' : ''}>
      <td className="tank-name-cell">{tanque.name}</td>
      <td>{getAproximate3FloatString(tanque.data.pedidos_ventas)}</td>
      <td>
        {
          type === "entradas"?
          getAproximate3FloatString(tanque.data.pedidos_normalizados)
          :
          getAproximate3FloatString(tanque.data.ventas_regularizadas)
        }
      </td>
      <td>{getAproximate3FloatString(tanque.data.calibraciones)}</td>
      <td>{getAproximate3FloatString(tanque.data.movimientos_bodega)}</td>
      <td>{getAproximate3FloatString(tanque.data.total)}</td>
    </tr>
  )
}

export function getResumenTanquesCierreDiario(material, type){
  const tanque = [], key = 0, resumenes = [], allTanques = null
  console.log("llega material y type::")
  console.log(material)
  console.log(type)
  const tanques = []
  let totalTanquesTitle = "Total tanques"
  
  const totales = {
    calibraciones: 0,
    movimientos_bodega: 0,
    pedidos_ventas: 0,
    pedidos_normalizados: 0,
    ventas_regularizadas: 0,
    total: 0
  }

  material.tanques.forEach(function(tanque){
    const newTanque = {
      name: tanque.tanque,
      data: {
        calibraciones: tanque[type].calibraciones,
        movimientos_bodega: tanque[type].movimientos_bodega,
        pedidos_ventas: type === 'entradas'? tanque.entradas.pedidos: tanque.salidas.ventas,
        pedidos_normalizados: type === 'entradas'? tanque.entradas.pedidos_normalizados: null,
        ventas_regularizadas: type === 'salidas'? tanque.salidas.ventas_regularizadas: null,
        total: tanque[type].total
      }
    }
    totales.calibraciones += newTanque.data.calibraciones
    totales.movimientos_bodega += newTanque.data.movimientos_bodega
    totales.pedidos_ventas += newTanque.data.pedidos_ventas
    totales.pedidos_normalizados += newTanque.data.pedidos_normalizados
    totales.ventas_regularizadas += newTanque.data.ventas_regularizadas
    totales.total += newTanque.data.total

    tanques.push(newTanque)
    totalTanquesTitle += ' ' + tanque.tanque.split(" ")[1]
  })

  let totalSifones = tanques.length

  return <>
    <tr>
      <td className="horizontal-separator" colSpan={6} />
    </tr>
    <tr className={totalSifones > 1? 'only-row-span-cell-border': ''}>  
      <td className={`tanque-name-column cell-border-top-left ${totalSifones === 1? 'cell-border-bottom-left': ''}`}>
        {tanques[0].name}
      </td>
      <td rowSpan={totalSifones}>{material.material}</td>
      <td>{getAproximate3FloatString(tanques[0].data.pedidos_ventas)}</td>
      <td>
        {
          type === "entradas"?
          getAproximate3FloatString(tanques[0].data.pedidos_normalizados)
          :
          getAproximate3FloatString(tanques[0].data.ventas_regularizadas)
        }
      </td>
      <td>{getAproximate3FloatString(tanques[0].data.calibraciones)}</td>
      <td>{getAproximate3FloatString(tanques[0].data.movimientos_bodega)}</td>
      <td className={`cell-border-top-right ${totalSifones === 1? 'cell-border-bottom-right':''}`}>
        {getAproximate3FloatString(tanques[0].data.total)}
      </td>
    </tr>

    { totalSifones > 1 && tanques.map((tanque, key) => 
      getRowForResumenTanquesCierreDiario(tanque, key, tanques.length, type)
    )}

    { totalSifones > 1 &&
      <tr className="bold-row">
        <td className="cell-border-bottom-left" colSpan={2}>
          Total {material.total.tanque}
        </td>
        <td>{totales.pedidos_ventas}</td>
        <td>
          {
            type==="entradas"?
            totales.pedidos_normalizados
            :
            totales.ventas_regularizadas
          }
        </td>
        <td>{totales.calibraciones}</td>
        <td>{totales.movimientos_bodega}</td>
        <td className="cell-border-bottom-right">{totales.total}</td>
      </tr>
    }
  </>
}

export function getResumenTanques(tanque, key, resumenes, type, allTanques = null){
  const resumen = resumenes.find(t => t.ID_TANQUES.includes(tanque.ID))
  
  if(parseInt(tanque.ID) > parseInt(tanque.SIFON_1) || parseInt(tanque.ID) > parseInt(tanque.SIFON_2))
    return

  let sifonCamion, resumenSifonCamion
  
  if(allTanques && tanque.TIPO === 'camion'){
    let isOld = false
    allTanques.map((t, newKey) => {
      if(t.ID.split('-')[0] === tanque.ID.split('-')[0]){
        if(newKey < key)
          isOld = true
        else if(newKey > key){
          sifonCamion = t
          resumenSifonCamion = resumenes.find(t => t.ID_TANQUES.includes(sifonCamion.ID))
        }
      }
    })
    if(isOld)
      return
  }

  let totalSifones = 1

  if(tanque.SIFON_1 !== ''){
    totalSifones++
  }

  if(tanque.SIFON_2 !== ''){
    totalSifones++
  }

  const total = (type === 'entradas'? resumen.ENTRADA: resumen.SALIDA)

  const entradaSalidaTotal = (type === 'entradas'? resumen.ENTRADA_PLANTA: resumen.SALIDA_VENTAS)
  const movimientosBodegaTotal = (type === 'entradas'? resumen.ENTRADA_MOVIMIENTOS_BODEGA: resumen.SALIDA_MOVIMIENTOS_BODEGA)
  const calibracionesTotal = (type === 'entradas'? resumen.ENTRADA_CALIBRACIONES: resumen.SALIDA_CALIBRACIONES)

  const entradaSalida = getAproximate3FloatString(entradaSalidaTotal / totalSifones)
  const movimientosBodega = getAproximate3FloatString(movimientosBodegaTotal / totalSifones)
  const calibraciones = getAproximate3FloatString(calibracionesTotal / totalSifones)
  const subTotal = getAproximate3FloatString(total / totalSifones)

  const entradaSalidaSifonCamion = (type === 'entradas'? (resumenSifonCamion?.ENTRADA_PLANTA || 0): (resumenSifonCamion?.SALIDA_VENTAS || 0))
  const movimientosBodegaSifonCamion = (type === 'entradas'? (resumenSifonCamion?.ENTRADA_MOVIMIENTOS_BODEGA || 0): (resumenSifonCamion?.SALIDA_MOVIMIENTOS_BODEGA || 0))
  const calibracionesSifonCamion = (type === 'entradas'? (resumenSifonCamion?.ENTRADA_CALIBRACIONES || 0): (resumenSifonCamion?.SALIDA_CALIBRACIONES || 0))
  const totalSifonCamion = (type === 'entradas'? (resumenSifonCamion?.ENTRADA || 0): (resumenSifonCamion?.SALIDA || 0))

  return <>
    <tr>
      <td className="horizontal-separator" colSpan={6} />
    </tr>
    <tr className={totalSifones > 1? 'only-row-span-cell-border': ''}>
      { sifonCamion &&
        <td rowSpan="2" className='fluctuaciones-column-with-border cell-border-top-left'>
          <span className="uppercase">{tanque.ID.split('-')[0]}</span>
        </td>
      }
      { !sifonCamion &&
        <td className={`tanque-name-column cell-border-top-left ${totalSifones === 1? 'cell-border-bottom-left': ''}`}>
          {tanque.TIPO === "tanque"? "Tanque ": ""}<span className="uppercase">{tanque.ID.split('-')[0]}</span>
        </td>
      }
      <td rowSpan={totalSifones}>{tanque.MATERIAL}</td>
      <td>{entradaSalida}</td>
      <td>{calibraciones}</td>
      <td>{movimientosBodega}</td>
      <td className={`cell-border-top-right ${(totalSifones === 1 && !sifonCamion)? 'cell-border-bottom-right':''}`}>
        {subTotal}
      </td>
    </tr>

    { sifonCamion &&
      <>
        <tr>
          <td>{sifonCamion.MATERIAL}</td>
          <td>{entradaSalidaSifonCamion}</td>
          <td>{calibracionesSifonCamion}</td>
          <td>{movimientosBodegaSifonCamion}</td>
          <td>
            {totalSifonCamion}
          </td>
        </tr>
        <tr className='row-with-border sifon-camiones-separator'>
          <td /><td /><td /><td /><td /><td />
        </tr>
      </>
    }

    { totalSifones > 1 &&
      <>
        <tr className={totalSifones === 2? 'row-with-border' : ''}>
          <td className="tank-name-cell">Tanque {tanque.SIFON_1}</td>
          <td>{entradaSalida}</td>
          <td>{calibraciones}</td>
          <td>{movimientosBodega}</td>
          <td>{subTotal}</td>
        </tr>
        { totalSifones === 3 &&
          <tr className="row-with-border">
            <td className="tank-name-cell">Tanque {tanque.SIFON_2}</td>
            <td>{entradaSalida}</td>
            <td>{calibraciones}</td>
            <td>{movimientosBodega}</td>
            <td>{subTotal}</td>
          </tr>
        }
      </>
    }

    { (sifonCamion || totalSifones > 1) &&
      <tr className="bold-row">
        <td className="cell-border-bottom-left" colSpan={2}>
          { sifonCamion &&
            <>Total Camion <span className="uppercase">{tanque.ID.split('-')[0]}</span></>
          }
          { totalSifones > 1 &&
            <>Total tanques {`${tanque.ID}${totalSifones > 1? ` - ${tanque.SIFON_1}`: ''}${totalSifones > 2? ` - ${tanque.SIFON_2}`: ''}`}</>
          }
        </td>

        <td>{getAproximate3FloatString(entradaSalidaTotal + entradaSalidaSifonCamion)}</td>
        <td>{getAproximate3FloatString(calibracionesTotal + calibracionesSifonCamion)}</td>
        <td>{getAproximate3FloatString(movimientosBodegaTotal + movimientosBodegaSifonCamion)}</td>
        <td className="cell-border-bottom-right">{getAproximate3FloatString(total + totalSifonCamion)}</td>
      </tr>
    }

  </>
}
