import React, { useState, useEffect, useRef } from "react";
import Pdf from "react-to-pdf";
import PrintComponent from "../PrintComponent";
import descargar from "../../assets/img/descargar.svg";
import { getLocaleStringNumber, parseBudat, getForma } from "../utils/utils.js";
import { allMateriales } from "../utils/materiales";

const DetallePedido = (props) => {
  const selectedPedido = props.selectedPedido;
  const setDisplayDetallePedido = props.setDisplayDetallePedido;
  const selectedEds = props.selectedEds;
  const isDinamo = props.isDinamo;

  const [reposicion, setReposicion] = useState([]);
  const [cons, setCons] = useState([]);
  const [totalAPagar, setTotalAPagar] = useState({});
  const [precioPorLitro, setPrecioPorLitro] = useState({});

  const ref = useRef(null);

  useEffect(() => {
    const arr93 = [];
    const arr95 = [];
    const arr97 = [];
    const arrPd = [];
    const arrK = [];


    if (selectedPedido.DETALLE) {
      const detalle = selectedPedido.DETALLE;
      detalle.forEach((item) => {
        if (allMateriales[item.ID_MATERIAL].class === "sp93") {
          arr93.push(item);
        }
        if (allMateriales[item.ID_MATERIAL].class === "sp95") {
          arr95.push(item);
        }
        if (allMateriales[item.ID_MATERIAL].class === "sp97") {
          arr97.push(item);
        }
        if (allMateriales[item.ID_MATERIAL].class === "pd") {
          arrPd.push(item);
        }
        if (allMateriales[item.ID_MATERIAL].class === "k") {
          arrK.push(item);
        }
      });
    }

    const tctAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZTCT",
      "Reposición TCT"
    );
    const taeAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZTAE",
      "Reposición TAE"
    );
    const cuponAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZCUP",
      "Reposición Cupón Electrónico"
    );
    const ffaaAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZFFA",
      "Reposición FF.AA."
    );
    const muevoAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZCEM",
      "Reposición Muevo Empresa"
    );

    const astorageAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZPAS",
      "Almacenamiento Storage"
    );
    const storageAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZPCS",
      "Storage"
    );

    const consigAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZRCO",
      "Consignación"
    );

    //solo dinamo
    const consigAuxD = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZC07",
      "Consignación"
    );

    const conceAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZFES",
      "Concesión"
    );

    //solo dinamo
    const conceAuxD = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZC08",
      "Concesión"
    );

    const constAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZCSC",
      "Constitución"
    );

    const repos = [
      tctAux,
      taeAux,
      cuponAux,
      ffaaAux,
      muevoAux,
      astorageAux,
      storageAux,
    ];
    const con = [consigAux, conceAux, constAux, consigAuxD, conceAuxD];

    setReposicion(repos);
    setCons(con);
    setTotalAPagar({
      g93: getTotal(arr93),
      g95: getTotal(arr95),
      g97: getTotal(arr97),
      pd: getTotal(arrPd),
      k: getTotal(arrK),
      total:
        getTotal(arr93) +
        getTotal(arr95) +
        getTotal(arr97) +
        getTotal(arrPd) +
        getTotal(arrK),
    });

    let precio93 = "-";
    let precio95 = "-";
    let precio97 = "-";
    let precioPd = "-";
    let precioK = "-";

    if (parseInt(getTotal(arr93)) > 0) {
      arr93.forEach((p) => {
        if (
          p.ITEM_CATEG === "ZFES" ||
          p.ITEM_CATEG === "ZRCO" ||
          p.ITEM_CATEG === "ZC08"
        ) {
          if(isDinamo){
            console.log(p.PRECIO_MATERIAL_ENTREGADO);
            precio93 = p.PRECIO_MATERIAL_ENTREGADO;
          }else{
            console.log(p.PRECIO_MATERIAL_ENTREGADO);
            precio93 = p.PRECIO_MATERIAL;
          }
          
        }
      });
    }

    if (parseInt(getTotal(arr95)) > 0) {
      arr95.forEach((p) => {
        if (
          p.ITEM_CATEG === "ZFES" ||
          p.ITEM_CATEG === "ZRCO" ||
          p.ITEM_CATEG === "ZC08"
        ) {
          if(isDinamo){
            precio95 = p.PRECIO_MATERIAL_ENTREGADO;
          }else{
            precio95 = p.PRECIO_MATERIAL;
          }
          
        }
      });
    }

    if (parseInt(getTotal(arr97)) > 0) {
      arr97.forEach((p) => {
        if (
          p.ITEM_CATEG === "ZFES" ||
          p.ITEM_CATEG === "ZRCO" ||
          p.ITEM_CATEG === "ZC08"
        ) {
          if(isDinamo){
            precio97 = p.PRECIO_MATERIAL_ENTREGADO;
          }else{
            precio97 = p.PRECIO_MATERIAL;
          }
          
        }
      });
    }

    if (parseInt(getTotal(arrPd)) > 0) {
      arrPd.forEach((p) => {
        if (
          p.ITEM_CATEG === "ZFES" ||
          p.ITEM_CATEG === "ZRCO" ||
          p.ITEM_CATEG === "ZC08"
        ) {
          if(isDinamo){
            precioPd = p.PRECIO_MATERIAL_ENTREGADO;
          }else{
            precioPd = p.PRECIO_MATERIAL;
          }
          
        }
      });
    }

    if (parseInt(getTotal(arrK)) > 0) {
      arrK.forEach((p) => {
        if (
          p.ITEM_CATEG === "ZFES" ||
          p.ITEM_CATEG === "ZRCO" ||
          p.ITEM_CATEG === "ZC08"
        ) {
          if(isDinamo){
            precioK = p.PRECIO_MATERIAL_ENTREGADO;
          }else{
            precioK = p.PRECIO_MATERIAL;
          }
         
        }
      });
    }

    setPrecioPorLitro({
      g93: precio93,
      g95: precio95,
      g97: precio97,
      pd: precioPd,
      k: precioK,
    });
  }, [selectedPedido]);

  const getLitros = (array, forma) => {
    let litros = 0;
    array.forEach((pedido) => {
      if (pedido.ITEM_CATEG === forma) {
        if(isDinamo){
          litros += pedido.CANTIDAD_ENTREGADA;
        }else{
          litros += pedido.CANTIDAD_SOLICITADA;
        }
        
      }
    });
    return litros;
  };

  const getLitrosProductoR = (producto) => {
    let litros = 0;
    reposicion.forEach((forma) => {
      if (forma[producto] > 0) {
        litros += forma[producto];
      }
    });
    return litros;
  };

  const getLitrosProductoC = (producto) => {
    let litros = 0;
    cons.forEach((forma) => {
      if (forma[producto] > 0) {
        litros += forma[producto];
      }
    });
    return litros;
  };

  const getTotal = (arreglo) => {
    let total = 0;

    arreglo.forEach((p) => {
      if (
        p.ITEM_CATEG === "ZFES" ||
        p.ITEM_CATEG === "ZRCO" ||
        p.ITEM_CATEG === "ZC08"
      ) {
        if(isDinamo){
          total += p.PRECIO_TOTAL_MATERIAL_ENTREGADO;
        }else{
          total += p.PRECIO_TOTAL_MATERIAL;
        }
        
      }
    });
    return total;
  };

  const productos = [
    {
      forma: "Reposición TCT",
      g93: "8000",
      g95: "10000",
      g97: "1000",
      pd: "2000",
      k: "1000",
      total: "40000",
    },
    {
      forma: "Reposición TAE",
      g93: "8000",
      g95: "10000",
      g97: "1000",
      pd: "2000",
      k: "1000",
      total: "40000",
    },
    {
      forma: "Reposición Cupón Electrónico",
      g93: "8000",
      g95: "10000",
      g97: "1000",
      pd: "2000",
      k: "1000",
      total: "40000",
    },
    {
      forma: "Reposición FF.AA.",
      g93: "8000",
      g95: "10000",
      g97: "1000",
      pd: "2000",
      k: "1000",
      total: "40000",
    },
    {
      forma: "Reposición Muevo Empresa",
      g93: "8000",
      g95: "10000",
      g97: "1000",
      pd: "2000",
      k: "1000",
      total: "40000",
    },
  ];

  const consignacion = [
    {
      forma: "Consignacion",
      g93: "8000",
      g95: "10000",
      g97: "1000",
      pd: "2000",
      k: "1000",
      total: "40000",
    },
    {
      forma: "Concesion",
      g93: "8000",
      g95: "10000",
      g97: "1000",
      pd: "2000",
      k: "1000",
      total: "40000",
    },
  ];

  return (
    <>
      <div className="detalle-pedido" ref={ref}>
        <h1>Detalle pedido Nº {selectedPedido.ID_PEDIDO} </h1>
        <h2>Volumen entregado</h2>
        <hr className="title-separator"></hr>

        <div className="info col4">
          <div className="item">
            <div className="title">ESTACIÓN DE SERVICIO</div>
            <div className="value overflow">{selectedEds.label}</div>
          </div>
          <div className="item">
            <div className="title">PLANTA</div>
            <div className="value overflow"> {selectedPedido.PLANTA} </div>
          </div>
          <div className="item">
            <div className="title">TIPO DE PEDIDO</div>
            <div className="value">{selectedPedido.TIPO_PEDIDO}</div>
          </div>
          <div className="item">
            <div className="title">ESTADO</div>
            <div className="value overflow">{selectedPedido.ESTADO}</div>
          </div>
        </div>
        <div className="info col4">
          <div className="item">
            <div className="title">FECHA PREFERENTE ENTREGA</div>
            <div className="value overflow">
              {parseBudat(selectedPedido.FECHA_PREFERENTE_ENTREGA)}
            </div>
          </div>
          <div className="item">
            <div className="title">VENTANA SOLICITADA</div>
            <div className="value">{selectedPedido.HORARIO}</div>
          </div>
          <div className="item">
            <div className="title">FECHA LLEGADA REAL CLIENTE</div>
            <div className="value">
              {parseBudat(selectedPedido.FECHA_REAL_CLIENTE)}
            </div>
          </div>
          <div className="item">
            <div className="title">HORA LLEGADA REAL CLIENTE</div>
            <div className="value overflow">
              {selectedPedido.HORA_REAL_CLIENTE}
            </div>
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <th>Forma&nbsp;Comercialización</th>
              <th className="align-right">G93RM</th>
              <th className="align-right">G95RM</th>
              <th className="align-right">G97RM</th>
              <th className="align-right">PDUA1</th>
              <th className="align-right">Kerosene</th>
              <th className="align-right">Total</th>
            </tr>
          </thead>
          <tr className="separator-table-row">
            <th colSpan="4" />
          </tr>
          {!isDinamo && (
            <>
              <tbody>
                {reposicion
                  .filter((forma) => forma.total > 0)
                  .map((forma, key) => (
                    <>
                      <tr key={key} className="data">
                        <td className="align-left">{forma.name}</td>
                        <td className="align-right">
                          {getLocaleStringNumber(forma.g93)}
                        </td>
                        <td className="align-right">
                          {getLocaleStringNumber(forma.g95)}
                        </td>
                        <td className="align-right">
                          {getLocaleStringNumber(forma.g97)}
                        </td>
                        <td className="align-right">
                          {getLocaleStringNumber(forma.pd)}
                        </td>
                        <td className="align-right">
                          {getLocaleStringNumber(forma.k)}
                        </td>
                        <td className="align-right">
                          {getLocaleStringNumber(forma.total)}
                        </td>
                      </tr>
                      {reposicion.filter((forma) => forma.total > 0).length -
                        1 !==
                        key && (
                        <>
                          <tr>
                            <td colSpan="100%" className="tdsep">
                              <hr></hr>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  ))}
              </tbody>
              <tr className="separator-table-row">
                <th colSpan="4" />
              </tr>
              <tbody>
                <tr className="data subtotal">
                  <td className="align-right">
                    Volumen&nbsp;productos&nbsp;Copec&nbsp;(Lts)
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(getLitrosProductoR("g93"))}
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(getLitrosProductoR("g95"))}
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(getLitrosProductoR("g97"))}
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(getLitrosProductoR("pd"))}
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(getLitrosProductoR("k"))}
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(getLitrosProductoR("total"))}
                  </td>
                </tr>
              </tbody>
              <tr className="separator-table-row">
                <th colSpan="4" />
              </tr>
            </>
          )}

          <tbody>
            {cons
              .filter((forma) => forma.total > 0)
              .map((forma, key) => (
                <>
                  <tr key={key} className="data">
                    <td className="align-left">{forma.name}</td>
                    <td className="align-right">
                      {getLocaleStringNumber(forma.g93)}
                    </td>
                    <td className="align-right">
                      {getLocaleStringNumber(forma.g95)}
                    </td>
                    <td className="align-right">
                      {getLocaleStringNumber(forma.g97)}
                    </td>
                    <td className="align-right">
                      {getLocaleStringNumber(forma.pd)}
                    </td>
                    <td className="align-right">
                      {getLocaleStringNumber(forma.k)}
                    </td>
                    <td className="align-right">
                      {getLocaleStringNumber(forma.total)}
                    </td>
                  </tr>
                  {cons.filter((forma) => forma.total > 0).length - 1 !==
                    key && (
                    <>
                      <tr>
                        <td colSpan="100%" className="tdsep">
                          <hr></hr>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              ))}
          </tbody>
          <tr className="separator-table-row">
            <th colSpan="4" />
          </tr>
          <tbody>
            <tr className="data subtotal">
              <td className="align-left">
                Total&nbsp;volumen&nbsp;entregado&nbsp;(Lts)
              </td>
              <td className="align-right">
                {getLocaleStringNumber(
                  getLitrosProductoR("g93") + getLitrosProductoC("g93")
                )}
              </td>
              <td className="align-right">
                {getLocaleStringNumber(
                  getLitrosProductoR("g95") + getLitrosProductoC("g95")
                )}
              </td>
              <td className="align-right">
                {getLocaleStringNumber(
                  getLitrosProductoR("g97") + getLitrosProductoC("g97")
                )}
              </td>
              <td className="align-right">
                {getLocaleStringNumber(
                  getLitrosProductoR("pd") + getLitrosProductoC("pd")
                )}
              </td>
              <td className="align-right">
                {getLocaleStringNumber(
                  getLitrosProductoR("k") + getLitrosProductoC("k")
                )}
              </td>
              <td className="align-right">
                {getLocaleStringNumber(
                  getLitrosProductoR("total") + getLitrosProductoC("total")
                )}
              </td>
            </tr>
          </tbody>
          <tr className="separator-table-row">
            <th colSpan="4" />
          </tr>
          <tbody>
            <tr className="data">
              <td className="align-left">Precio&nbsp;por&nbsp;litro</td>
              <td className="align-right">
                {precioPorLitro["g93"] === "-"
                  ? "-"
                  : `$ ${getLocaleStringNumber(
                      parseInt(precioPorLitro["g93"])
                    )}`}
              </td>
              <td className="align-right">
                {precioPorLitro["g95"] === "-"
                  ? "-"
                  : `$ ${getLocaleStringNumber(
                      parseInt(precioPorLitro["g95"])
                    )}`}
              </td>
              <td className="align-right">
                {precioPorLitro["g97"] === "-"
                  ? "-"
                  : `$ ${getLocaleStringNumber(
                      parseInt(precioPorLitro["g97"])
                    )}`}
              </td>
              <td className="align-right">
                {precioPorLitro["pd"] === "-"
                  ? "-"
                  : `$ ${getLocaleStringNumber(
                      parseInt(precioPorLitro["pd"])
                    )}`}
              </td>
              <td className="align-right">
                {precioPorLitro["k"] === "-"
                  ? "-"
                  : `$ ${getLocaleStringNumber(parseInt(precioPorLitro["k"]))}`}
              </td>
              <td className="align-right"></td>
            </tr>
          </tbody>
          <tr className="separator-table-row">
            <th colSpan="4" />
          </tr>
          <tbody>
            <tr className="data subtotal">
              <td className="align-left">Total&nbsp;a&nbsp;pagar</td>
              <td className="align-right">
                {" "}
                {`$ ${getLocaleStringNumber(
                  parseInt(totalAPagar["g93"]),true
                )}`}{" "}
              </td>
              <td className="align-right">{`$ ${getLocaleStringNumber(
                parseInt(totalAPagar["g95"]),true
              )}`}</td>
              <td className="align-right">{`$ ${getLocaleStringNumber(
                parseInt(totalAPagar["g97"]),true
              )}`}</td>
              <td className="align-right">{`$ ${getLocaleStringNumber(
                parseInt(totalAPagar["pd"]),true
              )}`}</td>
              <td className="align-right">{`$ ${getLocaleStringNumber(
                parseInt(totalAPagar["k"]),true
              )}`}</td>
              <td className="align-right">{`$ ${getLocaleStringNumber(
                parseInt(totalAPagar["total"]),true
              )}`}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="detalle-pedido">
        <hr></hr>
        <div className="actions">
          <button
            className="actions-buttons"
            onClick={() => setDisplayDetallePedido(false)}
          >
            Volver
          </button>
          <PrintComponent 
            reff={ref}
            classes="print-button cursor-pointer"
            pageStyle="@page { size: 320mm 250mm; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }"
            isROImpersonate={props.isROImpersonate}
          />

          <Pdf
            targetRef={ref}
            filename="comprobante.pdf"
            x={0.3}
            y={0.3}
            scale={0.8}
          >
            {({ toPdf }) => (
              <img
                onClick={toPdf}
                className="print-button cursor-pointer"
                src={descargar}
              />
            )}
          </Pdf>
        </div>
      </div>
    </>
  );
};

export default DetallePedido;
