import React, { useState, useEffect } from "react";
import file from "../assets/img/file.svg";
import download from "../assets/img/descargar-arrow-white.svg";
import { displayLoader, hideLoader } from "./utils/windows";
import CustomModal from "./CustomModal";

export const ConfiguracionAlarmas = ({ token, callServiceHandler }) => {
  const [alarmasFile, setAlarmasFile] = useState();
  const [displayModal, setDisplayModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const [modalIcon, setModalIcon] = useState("");
  const hiddenFileInput = React.useRef(null);
  const [fechaUltima, setFechaUltima] = useState();
  const [jsonData, setJsonData] = useState();

  useEffect(() => {
    if(token){
      getConfiguracion();
    }
  }, [token]);

  const handleUploadCvClick = (event) => {
    hiddenFileInput.current.click();
  };

  async function getConfiguracion() {
    displayLoader();
    const url =
      process.env.REACT_APP_FLUCTUACIONES_API +
      "/fluctuaciones/get-configuracion-alarmas";

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      setFechaUltima(result.data.FECHA);
      setJsonData(result.data);
    }
  }

  async function upload() {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      let json;

      try {
        json = JSON.parse(text);
      } catch (e) {
        setModalTitle("Error");
        setModalText("El archivo no contiene un JSON válido.");
        setModalIcon("error");
        setDisplayModal(true);
        return;
      }

      displayLoader();
      const url =
        process.env.REACT_APP_FLUCTUACIONES_API +
        "/fluctuaciones/modificar-configuracion-alarmas";

      let result = await callServiceHandler(url, "POST", JSON.stringify(json))
      if(result !== "error"){
        if (result.data.ESTADO && result.data.ESTADO === 200) {
          setAlarmasFile();
          setModalTitle("Configuración guardada");
          setModalText(
            "La nueva configuración de alarmas se guardo correctamente."
          );
          setModalIcon("success");
          setDisplayModal(true);
        } else {
          setAlarmasFile();
          setModalTitle("Hubo un problema");
          setModalText(result.data.MENSAJE ? result.data.MENSAJE : "");
          setModalIcon("error");
          setDisplayModal(true);
        }

      }
    };
    reader.readAsText(alarmasFile);
  }

  function closeModal() {
    setDisplayModal(false);
  }

  async function downloadJson () {
    if(jsonData){
      const fileName = "Archivo de Configuracion - "+fechaUltima;
      const json = JSON.stringify(jsonData);
      const blob = new Blob([json],{type:'application/json'});
      const href = await URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = fileName + ".json";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    
  }

  return (
    <div className="page">
      <div className="view-title">Fluctuaciones</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Configuración de alertas</div>
      <hr className="title-separator-line" />
      <div className="configuracion-de-alertas">
        <div className="configuracion-de-alertas__section">
          <p className="configuracion-de-alertas__section__title">
            Última actualización
          </p>
          <input type="text" className="disabled-input " disabled value={fechaUltima?fechaUltima:"-"} />
        </div>
        <div className="configuracion-de-alertas__section">
          <p className="configuracion-de-alertas__section__title">
            Configuración actual
          </p>
          <button className="main-button" disabled={jsonData?false:true} onClick={()=>downloadJson()} >
            <img src={download} alt="" />
            Descargar Archivo
          </button>
        </div>
        <div className="configuracion-de-alertas__section">
          <p className="configuracion-de-alertas__section__title">
            Nueva Configuración
          </p>
          <button className="main-button hallow" onClick={handleUploadCvClick}>
            <img src={file} alt="" />
            Adjuntar archivo
          </button>

          <input
            className="hidden-file-input"
            type="file"
            accept=".json"
            id="alarmas-file"
            ref={hiddenFileInput}
            onChange={(e) => {
              setAlarmasFile(e.target.files[0]);
            }}
          />
        </div>
        <div className="configuracion-de-alertas__section">
          {alarmasFile && (
            <p className="configuracion-de-alertas__section__title">
              {alarmasFile.name}
            </p>
          )}
          <button
            className="main-button"
            onClick={upload}
            disabled={!alarmasFile}
          >
            Guardar
          </button>
        </div>
      </div>

      <CustomModal
        displayModal={displayModal}
        closeModal={closeModal}
        acceptFunc={closeModal}
        modalTitle={modalTitle}
        modalText={modalText}
        modalIcon={modalIcon}
      />
    </div>
  );
};
