import React, { useRef } from "react";
import { CopecStepper } from "../CopecStepper";

export const Stepper = ({
  step,
  error,
  totalSteps,
  title,
  step1Title,
  step2Title,
  step3Title,
}) => {
  const isDesktopRef = useRef(window.innerWidth > process.env.REACT_APP_MOBILE_BREAK_POINT);
  return (
    <div class="abonar-en-banco__stepper-wrapper">
      <div className="abonar-en-banco__title-wrapper">
        <h2 className="abonar-en-banco__title">{title}</h2>
        <p className="abonar-en-banco__subtitle">Pago en línea</p>
      </div>
      <CopecStepper
        activeStep={step}
        error={error}
        steps={parseInt(totalSteps) === 2 ?[step1Title, step2Title]:[step1Title, step2Title, step3Title]}
        className={parseInt(totalSteps) === 2 && isDesktopRef.current ? "two-steps" : ""}
      />
    </div>
  );
};
