import React, { useState, useEffect } from "react";
import { SingleOption, customStyles, parseBudat } from "../utils/utils.js";
import Select from "react-select";
import remove from "../../assets/img/remove.svg";
import errorIcon from "../../assets/img/atencion_rojo.svg";
import closeIcon from "../../assets/img/cross-icon.svg";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import Timepicker from "../Timepicker";
import VolumenDinamoModal from "../Pedidos/VolumenDinamoModal.js";
import { allMateriales } from "../utils/materiales";
import { hideGreyBackground, showGreyBackground } from "../utils/windows.js";
import TimePickerLibrary from "../TimePickerLibrary.js";

const commonSelectProps = {
  className: "table-select",
  closeMenuOnSelect: true,
  hideSelectedOptions: false,
  options: [],
  components: { SingleOption, IndicatorSeparator: () => null },
  isClearable: false,
  autosize: false,
  placeholder: "Seleccionar",
  styles: customStyles,
};

export const AsignacionEntradas = ({ entradasManuales, setEntradasManuales, setStep, tanques, fechaMedicion, selectedEds,fechaCierre,callServiceHandler,
    horaMedicion, openPedidos, setOpenPedidos, openCloseModals, pedidos, setPedidos, viewPortHeight,setFechaCierre,pagador,getResumenCierre,
  disableEntradas,isROImpersonate,pedidosEspeciales,setPedidosEspeciales,selectedCierre,selectedEstadoCierre}) => {
  const [filteredTanques, setFilteredTanques] = useState([])
  const [materiales, setMateriales] = useState([])
  const [errores, setErrores] = useState([])
  const [erroresEspeciales, setErroresEspeciales] = useState([])
  const [checkedPedidos, setCheckedPedidos] = useState({})
  const [customModalStylesPedidos, setCustomModalStylesPedidos] = useState({})
  const [distanceToTop, setDistanceToTop] = useState(0);
  const [pedidoModal, setPedidoModal] = useState({});
  const [displayPedidoModal, setDisplayPedidoModal] = useState(false);
  const [filteredTanquesGas, setFilteredTanquesGas] = useState([])
  const [materialesGases, setMaterialesGases] = useState([])

  useEffect(() => {
    setCustomModalStylesPedidos({
      content: {
        top: "20px",
        width: "850px",
        padding: "0",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translateX(-50%)",
        maxHeight: (viewPortHeight - 150) + 'px',
        borderRadius: 9
      }
    })
  }, [])

  useEffect(() => {
    const tmpMateriales = []
    tanques.forEach(function(tanque){
      //Validacion retirada el 13 de Noviembre 2024, parta desarrollo de E. Virtuales.
     /*  if(tanque.tipo === 'camion')
        return */

      const found = tmpMateriales.find(m => m.value === tanque.idMaterial)
      if(!found)
        tmpMateriales.push({ label: tanque.material, value: tanque.idMaterial})
    })

    setMateriales(tmpMateriales)
    setMaterialesGases(tmpMateriales.filter(material=>material.label==="GAS GLP E/S"))

  }, [tanques])

  function editPedido(val, idPedido, descarga) {
    const tmp = pedidos.map((pedido) => {
      if (pedido.ID_PEDIDO === idPedido) {
        let tmpPedido = {...pedido}
        tmpPedido.DESCARGAS = tmpPedido.DESCARGAS.map((d) => {
          if (d.compartimiento === descarga.compartimiento) return {...d, tanque: val}
          return d
        })
        return tmpPedido
      }
      return pedido
    })
    setPedidos(tmp)
  }

  function editEntrada(key, parameter, value){
    if(parameter === 'volumen' && isNaN(value))
      return

    const temp = [...entradasManuales]
    temp[key][parameter] = value

    if(parameter === 'material'){
      temp[key].tanque = ''
      const newTanques = tanques.filter(t => (t.idMaterial === value.value && t.tipo == 'tanque'))
      const tempTanques = [...filteredTanques]
      tempTanques[key] = newTanques
      if(newTanques.length === 1)
        temp[key].tanque = newTanques[0]

      setFilteredTanquesGas(tempTanques)
    }
    setEntradasManuales(temp)
  }

  function editContingencia(idPedido,val){
    const tmp = pedidos.map((pedido) => {
      if (pedido.ID_PEDIDO === idPedido) {
        let tmpPedido = {...pedido}
        tmpPedido.HORA=val;
        return tmpPedido
      }
      return pedido
    })
    setPedidos(tmp)
  }

  function addEntrada(){
    const newEntrada = {
      identificador: "",
      fecha: '',
      hora: '',
      comp: '',
      volumen: '',
      material: '',
      tanque: ''
    }
    setEntradasManuales([...entradasManuales, newEntrada])
  }

  function deleteEntrada(key){
    const temp = entradasManuales.filter((item, index) => index !== key)
    setEntradasManuales(temp)
  }

  function handleCheckedPedidos(idPedido) {
    const temp = {...checkedPedidos}
    //const pedido = pedidos.find(pedido => pedido.ID_PEDIDO === idPedido)
    //temp[idPedido] = !pedido.checked

    if (idPedido in checkedPedidos) {
      temp[idPedido] = !temp[idPedido]
    }
    else {
      const pedido = pedidos.find(pedido => pedido.ID_PEDIDO === idPedido)
      temp[idPedido] = !pedido.checked
    }

    setCheckedPedidos(temp)
  }

  function confirmCheckedPedidos() {
    let tmp = pedidos.map((pedido) => {
      let newPedido = {...pedido}
      if (typeof checkedPedidos[pedido.ID_PEDIDO] !== 'undefined') {
        newPedido.checked = checkedPedidos[pedido.ID_PEDIDO]
      }
      //if(checkedPedidos[pedido.ID_PEDIDO])
      //newPedido.checked = (checkedPedidos[pedido.ID_PEDIDO] || pedido.checked) ? true : false
      return newPedido
    })
    
    setPedidos(tmp)

    openCloseModals(false, setOpenPedidos)
  }

  async function checkStep(){
    const tmpErrores = []
    const tmpErroresEspeciales = []
    let error1 = false, error2 = false, error3 = false, error4
    let errorPedidosEspeciales = false;
    /* let start_date = new Date(0)
    const endHourArr = horaMedicion.split(':')
    const end_date = new Date(fechaMedicion.getFullYear(), fechaMedicion.getMonth(), fechaMedicion.getDate(), endHourArr[0], endHourArr[1]) */

    pedidos.filter(p=>!p.used).forEach((pedido) => {
      if (pedido.checked) {
        pedido.DESCARGAS.forEach((descarga) => {
          if (!descarga.tanque) error1 = true
        })
      }
    })
    entradasManuales.forEach(function(entrada){
      const foundTanque = tanques.find(t => t.value === entrada.tanque.value)
      if(!entrada.fecha || !entrada.hora || !entrada.material || !entrada.tanque || !entrada.volumen){
        error4 = true
        return
      }

      if(!foundTanque)
        error1 = true
      else{
        if(foundTanque.capacidad < entrada.volumen)
          error2 = true
      }      
    })

    pedidosEspeciales?.forEach(pedido=>{
      pedido.DESCARGAS.forEach(descarga=>{
        const suma = descarga.TANQUES.reduce((suma,tanque)=>suma+=parseInt(tanque.volumen),0);
        if(descarga.volumen!==suma) errorPedidosEspeciales=true;
      })
    })

    if(error4)
      tmpErrores.push("Ingresa todos los datos.")
    if(error1)
      tmpErrores.push("Selecciona los materiales y tanques de todas las entradas.")
    if(error2)
      tmpErrores.push("No es posible ingresar una entrada con un volumen mayor a la capacidad del tanque.")
    if(error3)
      tmpErrores.push("Existen una o mas fechas ingresadas que están fuera del rango.")
    if(errorPedidosEspeciales)
      tmpErroresEspeciales.push("La suma de los volumenes asignados por tanque debe calzar con el Volumen Total.")

    setErrores(tmpErrores)
    setErroresEspeciales(tmpErroresEspeciales)

    if(tmpErrores.length === 0 && tmpErroresEspeciales.length === 0){

        const data={
          ID_EDS:selectedEds.value,
          pedidos:pedidos,
          pedidosEspeciales:pedidosEspeciales,
          entradasManuales:entradasManuales,
          FECHA:fechaCierre,
          PASO:1,
          ID_PAGADOR:pagador
        }
        if(isROImpersonate) data.VISUALIZAR="SI";

        const url =
          process.env.REACT_APP_FLUCTUACIONES_API +
          "/fluctuaciones/cierre-diario-ingresar-paso";
        
        let response = await callServiceHandler(url,"POST",JSON.stringify(data));

        if(response!=="error"){
          setStep(2)
        }
      
    }  
  }

  const changePedidoModal = async (e, p) => {
    const distance = e.currentTarget.getBoundingClientRect().top;
    setDistanceToTop(distance);
    const url =
        process.env.REACT_APP_FLUCTUACIONES_API +
        "/fluctuaciones/cierre-diario-obtener-detalle-pedido?FECHA="+
        fechaCierre+"&ID_PAGADOR="+pagador+"&ID_EDS="+selectedEds.value+"&ID_PEDIDO="+p.ID_PEDIDO;

    let response = await callServiceHandler(url, "GET");
    
    if(response!=="error"){
      if(response.data.PEDIDOS.length>0){
        showGreyBackground();
        setDisplayPedidoModal(true);
        setPedidoModal(response.data.PEDIDOS[0]);
      }
    }
    
  };

  function closeInfoModals() {
    setDisplayPedidoModal(false);
    hideGreyBackground();
  }

  function goBack(){
    setFechaCierre()
    getResumenCierre()
  }

  function pedidoEspecialChange (valor, pedido, descarga, tanque, idxDescarga){
    if(valor !== '-' && valor !== ''){
      valor = parseInt(valor) || 0
    }

    const tanqueNuevo = {...tanque,volumen:valor};
    const tanquesNuevos = descarga.TANQUES.map(t=>t.tanque===tanqueNuevo.tanque?tanqueNuevo:t)
    const descargasNuevas = [...pedido.DESCARGAS];
    descargasNuevas[idxDescarga].TANQUES = tanquesNuevos;
    const pedidoNuevo = {...pedido,DESCARGAS:descargasNuevas}
    setPedidosEspeciales((pedidos)=>pedidos.map(p=>p.ID_PEDIDO===pedidoNuevo.ID_PEDIDO?pedidoNuevo:p));
  }

  return (
    <div className="fluctuaciones-ingreso-mediciones-big-section">
      <p className="flutuaciones-section-title">ASIGNACIÓN DE ENTRADAS</p>
      <table className="flutuaciones-table tanks-table allocation-table">
        <thead>
          <tr>
            <th>Identificador Entrada</th>
            <th>Fecha Entrada</th>
            <th>Hora Entrada</th>
            <th>Comp.</th>
            <th>Volumen (Lts)</th>
            <th>Material</th>
            <th>Tanque</th>
          </tr>
        </thead>
        <tbody>
          {/* Horizontal  separator between head and body*/}
            <tr>
              <td className="horizontal-separator" colSpan={7} />
            </tr>
          { pedidos && pedidos.filter((pedido) => pedido.checked && !pedido.used).map((pedido, key) =>
            <>
              <tr className="row-with-border remove-row-span-border">
                <td className="cell-border-top-left cell-border-bottom-left" rowSpan={pedido.DESCARGAS.length}>
                  {/* {pedido.DETALLES.length>0?
                  <>
                  <a className="underline cursor-pointer" onClick={(e)=>changePedidoModal(e,pedido)}>
                  {pedido.ID_PEDIDO}
                  </a>
                  </>
                  :<>{pedido.ID_PEDIDO}</>} */}
                  <a className="underline cursor-pointer" onClick={(e)=>changePedidoModal(e,pedido)}>
                  {pedido.ID_PEDIDO}
                  </a>
                  
                </td>
                <td rowSpan={pedido.DESCARGAS.length}>
                  {`${pedido.FECHA.split("-")[2]}/${pedido.FECHA.split("-")[1]}/${pedido.FECHA.split("-")[0]}`}
                </td>
                {pedido.CONTINGENCIA==="X" && !pedido.blocked?<>
                {/* <td rowSpan={pedido.DESCARGAS.length}> <Timepicker 
                    value={pedido.HORA.substring(0,5)}
                    onSelect={(e) => editContingencia(pedido.ID_PEDIDO, e)}
                  /> */}
                <td rowSpan={pedido.DESCARGAS.length}> <TimePickerLibrary 
                    initialHour={pedido.HORA.substring(0,5)}
                    onSelect={(e) => editContingencia(pedido.ID_PEDIDO, e)}
                  />
                </td>
                </>:<>
                <td rowSpan={pedido.DESCARGAS.length}>{pedido.HORA.substring(0,5)}</td>
                </>}
                <td>{pedido.DESCARGAS[0].compartimiento}</td>
                <td>{pedido.DESCARGAS[0].volumen}</td>
                <td className="container-cell">
                  <div className="container-cell-content">
                    <div className={`small-camion-compartment ${allMateriales[pedido.DESCARGAS[0].material].class} cont2`}>
                        <p className="small-camion-compartment__fuel-type-number">
                          {allMateriales[pedido.DESCARGAS[0].material].title.substring(0, 2)}
                        </p>
                      </div>
                    { materiales.find(m => m.value === pedido.DESCARGAS[0].material) ?
                    <> <span>{materiales.find(m => m.value === pedido.DESCARGAS[0].material).label}</span> </>
                    : <> <span> {pedido.DESCARGAS[0].material} </span> </>
                    }
                  </div>
                </td>
                <td className="cell-border-top-right">
                  <Select
                    className="eds-select"
                    value={pedido.DESCARGAS[0].tanque}
                    onChange={(e) => editPedido(e, pedido.ID_PEDIDO, pedido.DESCARGAS[0])}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={pedido.DESCARGAS[0].tanquesOptions}
                    components={{ SingleOption, IndicatorSeparator: () => null }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStyles}
                  />
                </td>
                
              </tr>
              {pedido.DESCARGAS.slice(1).map((descarga) =>
                <> 
                  <tr className="row-with-border remove-row-span-border">
                    <td>{descarga.compartimiento}</td>
                    <td>{descarga.volumen}</td>
                    <td className="container-cell">
                      <div className="container-cell-content">
                        <div className={`small-camion-compartment ${allMateriales[descarga.material].class} cont2`}>
                            <p className="small-camion-compartment__fuel-type-number">
                              {allMateriales[descarga.material].title.substring(0, 2)}
                            </p>
                          </div>
                        { materiales.find(m => m.value === descarga.material) ?
                        <> <span>{materiales.find(m => m.value === descarga.material).label}</span> </>
                        : <> <span> {descarga.material} </span> </>
                        }
                      </div>
                    </td>
                    <td>
                      <Select
                        className="eds-select"
                        value={descarga.tanque}
                        onChange={(e) => editPedido(e, pedido.ID_PEDIDO, descarga)}
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        options={descarga.tanquesOptions}
                        components={{ SingleOption, IndicatorSeparator: () => null }}
                        isClearable={false}
                        autosize={false}
                        placeholder="Seleccionar"
                        styles={customStyles}
                      />
                    </td>
                  </tr>
                </>
              )}
            </>
          )}
          {pedidos && pedidos.filter((pedido) => pedido.checked).length === 0 &&
            <tr>
              <td colSpan="100%" className="cell-border-top-left cell-border-top-right">
                Ud. no cuenta con entradas en el día seleccionado
              </td>
            </tr>
          }

          { entradasManuales && entradasManuales.map((entrada, key) =>
            <>
              <tr>
                <td className="horizontal-separator" colSpan={7} />
              </tr>

              <tr className="tight-row entradas-manuales-gas-row">
                <td className="cell-border-top-left cell-border-bottom-left">
                  <input 
                    className="table-input table-input-gas" 
                    type="text" 
                    value={entrada.identificador} 
                    onChange={(e) => editEntrada(key, 'identificador', e.target.value)}
                  />
                </td>
                <td>
                  <DatePicker 
                    locale="es"
                    selected={entrada.fecha===""?entrada.fecha:new Date(entrada.fecha)} 
                    onChange={(e) => editEntrada(key, 'fecha', e)}
                    shouldCloseOnSelect = {true}
                    dateFormat = "dd/MM/yyyy"
                    placeholderText = "Seleccionar"
                  />
                </td>
                <td>
                  <Timepicker 
                    value={entrada.hora}
                    onSelect={(e) => editEntrada(key, 'hora', e)}
                    className="gas-timepicker"
                    placeholder="Selecc."
                  />
                </td>
                <td>
                  <input className="disabled-input" type="text" disabled />
                </td>
                <td>
                  <input 
                    className="table-input table-input-gas" 
                    type="text" 
                    value={entrada.volumen} 
                    onChange={(e) => editEntrada(key, 'volumen', e.target.value)}
                  />
                </td>
                <td>
                  <Select
                    className="eds-select eds-select-width-240"
                    value={entrada.material}
                    onChange={(e) => editEntrada(key, 'material', e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={materialesGases}
                    components={{ SingleOption, IndicatorSeparator: () => null }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStyles}
                  />
                </td>
                <td className="cell-border-top-right">
                  <Select
                    className="eds-select"
                    value={entrada.tanque}
                    onChange={(e) => editEntrada(key, 'tanque', e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={filteredTanquesGas[key]}
                    components={{ SingleOption, IndicatorSeparator: () => null }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStyles}
                  />
                  <button className="remove-row-btn">
                    <img src={remove} alt="remove" onClick={() => deleteEntrada(key)} />
                  </button>
                </td>
              </tr>
            </>
          )}

          {materialesGases.length>0 && <>
            <tr>
            <td className="horizontal-separator" colSpan={7} />
            </tr>

            <tr>
              <td
                className="cell-border-top-left cell-border-top-right"
                colSpan={7}
              >
                <button className="main-button add-new-row" onClick={addEntrada}>Agregar GLP</button>
              </td>
            </tr>
          </>}
          
        </tbody>
      </table>
      {
        errores.map((error) =>
        <div className="table-bottom-error">
          <img src={errorIcon} alt="error" />
          <p>{error}</p>
        </div>
      )}

      {pedidosEspeciales && <>
      <br></br>
        <p className="flutuaciones-section-title normalizacion-entradas">NORMALIZACIÓN DE ENTRADAS</p>
        <table className="flutuaciones-table tanks-table allocation-table">
          <thead>
              <tr>
                <th>Identificador Entrada</th>
                <th>Fecha Entrada</th>
                <th>Hora Entrada</th>
                <th>Volumen Total&nbsp;(Lts)</th>
                <th>Material</th>
                <th>Tanque</th>
                <th>Volumen</th>
              </tr>
          </thead>
          <tbody>
            <tr>
              <td className="horizontal-separator" colSpan={7} />
            </tr>
            {pedidosEspeciales.map(pedido=>{
              return (<>
              <tr className="row-with-border">
                <td className="cell-border-top-left cell-border-bottom-left" rowSpan={pedido.TANQUES_LENGTH}>
                  {pedido.ID_PEDIDO}
                </td>
                <td className="wsnw" rowSpan={pedido.TANQUES_LENGTH}>
                  {parseBudat(pedido.FECHA)}
                </td>
                <td rowSpan={pedido.TANQUES_LENGTH}>
                  {pedido.HORA}
                </td>
                <td rowSpan={pedido.DESCARGAS[0].TANQUES.length}>
                 {pedido.DESCARGAS[0].volumen}
                </td>
                <td class="container-cell" rowSpan={pedido.DESCARGAS[0].TANQUES.length}>
                  <div className="container-cell-content">
                    <div className={`small-camion-compartment ${allMateriales[pedido.DESCARGAS[0].material].class} cont2`}>
                      <p className="small-camion-compartment__fuel-type-number">
                        {allMateriales[pedido.DESCARGAS[0].material].title.substring(0, 2)}
                      </p>
                    </div>
                    { materiales.find(m => m.value === pedido.DESCARGAS[0].material) ?
                    <> <span>{materiales.find(m => m.value === pedido.DESCARGAS[0].material).label}</span> </>
                    : <> <span> {pedido.DESCARGAS[0].material} </span> </>
                    }
                  </div>
                </td>
                <td className="wsnw">{pedido.DESCARGAS[0].TANQUES[0].tipo==="tanque"?"Tanque":"Camión"} {pedido.DESCARGAS[0].TANQUES[0].tanque}</td>
                <td><input className="table-input calibraciones-table-input" type="text" onChange={(e) => pedidoEspecialChange(e.target.value, pedido,pedido.DESCARGAS[0] ,pedido.DESCARGAS[0].TANQUES[0], 0)} value={pedido.DESCARGAS[0].TANQUES[0].volumen} />
                </td>
              </tr>
              {pedido.DESCARGAS[0].TANQUES.slice(1).map(tanque=>{
                return(<>
                <tr className="row-with-border">
                  <td className="wsnw">{tanque.tipo==="tanque"?"Tanque":"Camión"} {tanque.tanque}</td>
                  <td><input className="table-input calibraciones-table-input" type="text" onChange={(e) => pedidoEspecialChange(e.target.value, pedido,pedido.DESCARGAS[0] ,tanque, 0)} value={tanque.volumen} /></td>
                </tr>
                </>)
              })}
              {pedido.DESCARGAS.slice(1).map((descarga,index)=>{
                return (<>
                  <tr className="row-with-border">
                    <td rowSpan={descarga.TANQUES.length}>
                      {descarga.volumen}
                    </td>
                    <td class="container-cell" rowSpan={descarga.TANQUES.length}>
                      <div className="container-cell-content">
                        <div className={`small-camion-compartment ${allMateriales[descarga.material].class} cont2`}>
                          <p className="small-camion-compartment__fuel-type-number">
                            {allMateriales[descarga.material].title.substring(0, 2)}
                          </p>
                        </div>
                        { materiales.find(m => m.value === descarga.material) ?
                        <> <span>{materiales.find(m => m.value === descarga.material).label}</span> </>
                        : <> <span> {descarga.material} </span> </>
                        }
                      </div>
                    </td>
                    <td className="wsnw">
                      {descarga.TANQUES[0].tipo==="tanque"? "Tanque" :"Camión"}&nbsp;{descarga.TANQUES[0]?.tanque}
                    </td>
                    <td>
                      <input className="table-input calibraciones-table-input" type="text" onChange={(e) => pedidoEspecialChange(e.target.value, pedido,descarga ,descarga.TANQUES[0],index+1)} value={descarga.TANQUES[0]?.volumen || 0} />  
                    </td>
                  </tr>
                  {descarga.TANQUES && descarga.TANQUES.slice(1).map(tanque=>{
                    return (<>
                      <tr className="row-with-border"> 
                        <td className="wsnw">{tanque.tipo==="tanque"?"Tanque":"Camión"}&nbsp;{tanque.tanque}</td>
                        <td><input className="table-input calibraciones-table-input" type="text" onChange={(e) => pedidoEspecialChange(e.target.value, pedido,descarga ,tanque,index+1)} value={tanque.volumen} /></td>
                      </tr>
                    </>)
                  })}
                </>)
              })}
              </>)
            })}

          </tbody>
        </table>
        {
          erroresEspeciales.map((error) =>
          <div className="table-bottom-error">
            <img src={errorIcon} alt="error" />
            <p>{error}</p>
          </div>
        )}
      </>}

      <div className="fluctuaciones-ingreso-mediciones-actions">
        <button className="main-button hallow" onClick={() => goBack()}>
          Volver
        </button>
        <button className="main-button" onClick={checkStep} disabled={disableEntradas || selectedEstadoCierre==="CERRADO"}>
          Confirmar
        </button>
      </div>

      <Modal isOpen={openPedidos} onRequestClose={() => openCloseModals(false, setOpenPedidos)} 
        style={customModalStylesPedidos}>
        <div className="flutuaciones-modal fim-modal">
          <img
            className="close-modal"
            onClick={() => openCloseModals(false, setOpenPedidos)}
            src={closeIcon}
            alt="Close"
          />
          <div className="modal-info">
            <p className="flutuaciones-modal__title">
              <strong>Pedidos registrados</strong>
            </p>
          </div>
          <table className="flutuaciones-table fim-table">
            <thead>
              <tr>
                <th>Identificador Pedido</th>
                <th>Fecha Entrada</th>
                <th>Hora Entrada</th>
                <th>Volumen Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {/* Horizontal  separator between head and body*/}
              <tr>
                <td className="horizontal-separator" colSpan={5} />
              </tr>
              {pedidos?.length === 0 &&
                <tr>
                  <td colSpan="100%" className="cell-border-top-left cell-border-top-right">
                    Ud. no cuenta con entradas en el día seleccionado
                  </td>
                </tr>
              }

              {pedidos?.map((pedido, key) => (
                <>
                  <tr className="row-with-border">
                    <td className={`${key===0?"cell-border-top-left":key===pedido.length-1?"cell-border-bottom-left":""}`}>
                      {pedido.ID_PEDIDO}
                    </td>
                    <td>{pedido.FECHA}</td>
                    <td>{pedido.HORA}</td>
                    <td>
                      <strong>{pedido.VOLUMEN_TOTAL}</strong>
                    </td>
                    <td className={`${key===0?"cell-border-top-right":key===pedido.length-1?"cell-border-bottom-left":""}`}>
                      <input type="checkbox" defaultChecked={pedido.checked}
                        onClick={() => handleCheckedPedidos(pedido.ID_PEDIDO)} disabled={pedido.blocked || pedido.CONTINGENCIA !== "X"} />
                    </td>
                  </tr>
                </>
              ))}

            </tbody>
          </table>
          <div className="fluctuaciones-ingreso-mediciones-actions">
            <button className="main-button hallow" onClick={() => openCloseModals(false, setOpenPedidos)}>
              Cancelar
            </button>
            <button className="main-button" onClick={() => confirmCheckedPedidos()}>
              Confirmar
            </button>
          </div>
        </div>
      </Modal>
      <VolumenDinamoModal
          displayModal={displayPedidoModal}
          closeModal={closeInfoModals}
          distanceToTop={distanceToTop}
          pedido={pedidoModal}
        />
    </div>
  );
};
