import React, { useState, useEffect } from "react"
import clienteIcon from "../assets/img/cliente.svg"
import oficinaIcon from "../assets/img/oficina.svg"
import actualizarIcon from "../assets/img/actualizar.svg"

const HeaderData = ({token, rutConcesionario, cabecera, actualizacion, actualizable, updateFunction, callServiceHandler}) => {
  const [ clientName, setClientName ] = useState('')
  const [ zone, setZone ] = useState('')

  useEffect(() => {
    if(process.env.REACT_APP_ENVIRONMENT !== 'dev'){
      if(token && rutConcesionario){
        if(typeof cabecera !== "undefined"){
          if(cabecera!==''){
            setClientName(cabecera.DATOS_CLIENTE.NOMBRE);
            setZone(cabecera.DATOS_EDS[0]?.ZONA);
          }
        }else{
          getCabecera(token);
        }
      }
    }
    else{
      if(token) {
        if(typeof cabecera !== "undefined"){
          if(cabecera!==''){
            setClientName(cabecera.DATOS_CLIENTE.NOMBRE);
            setZone(cabecera.DATOS_EDS[0].ZONA);
          }
        }else{
          getCabecera(token);
        }
        
      }
    }
  }, [token, rutConcesionario,cabecera])

  async function getCabecera(token) {
    console.log("Llamando a datos cabecera en HeaderData")
    const url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/datos-cabecera?rut_concesionario=" +
      rutConcesionario;

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      const data = result.data;
      setClientName(data.DATOS_CLIENTE.NOMBRE);
      setZone(data.DATOS_EDS[0].ZONA);
    }
  }

	return (
		<div className="header-data-container">
    	<div className="header-data">
    		<div className="left">
    			<img src={clienteIcon} />
    		</div>
    		<div className="right">
	    		<div className="title">CLIENTE</div>
	    		<div className="info">{`${rutConcesionario} ${clientName}`}</div>
	    	</div>
    	</div>

    	<div className="header-data">
    		<div className="left">
    			<img src={oficinaIcon} />
    		</div>
    		<div className="right">
	    		<div className="title">OFICINA</div>
	    		<div className="info">{ zone }</div>
	    	</div>
    	</div>

    	<div className="header-data last">
    		<div className="left">
          {actualizable?<img className="cursor-pointer" onClick={()=>updateFunction(token)} src={actualizarIcon} />:<img src={actualizarIcon} />}
    			
    		</div>
    		<div className="right">
          {actualizable?<><div className="title cursor-pointer underline" onClick={()=>updateFunction(token)}>ACTUALIZAR</div></>:<><div className="title">ACTUALIZACIÓN</div></>}
	    		<div className="info">{ actualizacion }</div>
	    	</div>
    	</div>
    </div>
  )
};

export default HeaderData;
