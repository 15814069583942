import React, { useState, useEffect, useRef } from "react"
import Select from 'react-select'
import HistorialPagosTable from './HistorialPagosTable'
import LiberarDocumentos from './LiberarDocumentos'
import HeaderData from './HeaderData'
import saldoAzul from "../assets/img/saldo-azul.svg"
import { displayLoader, hideLoader } from "./utils/windows"
import { monthNames, getAllPeriodos, getUpdateDate } from './utils/monthNames';
import {customStyles,SingleOption} from "./utils/utils.js";
import ExportExcel from "./ExportExcel";
import Terminos from "./Terminos/Terminos";
import PrintComponent from "./PrintComponent";

const LiberarPagos = ({ token, rutConcesionario, cabecera, isROImpersonate, callServiceHandler }) => {
	const [ products , setProducts ] = useState([])
  const [ productsLoaded , setProductsLoaded ] = useState(false)
	const [ updateDate, setUpdateDate ] = useState('')
  const [ filterText, setFilterText ] = useState('')
  const [ selectedPropuesta, setSelectedPropuesta ] = useState()
  const [ selectedDocumentos, setSelectedDocumentos ] = useState([])
  const [ selectedMonto, setSelectedMonto ] = useState(0)
  const [ filteredProducts, setFilteredProducts ] = useState([]) 
  const tableRef = useRef(null);
  const [isArcoprime, setIsArcoprime] = useState(false);
  const [selectedEdsArcoprime, setSelectedEdsArcoprime] = useState();
  const [allEdsArcoprime, setAllEdsArcoprime] = useState([]);
  const isDesktopRef = useRef(window.innerWidth > process.env.REACT_APP_MOBILE_BREAK_POINT);

  const labels = [
    ["Fecha Emisión", "FECHA"],
    ["Tipo Operación", "TIPO"],
    ["Banco", "BANCO"],
    ["Nº Propuesta de Pago", "GRUPO_PPTA"],
    ["Monto", "MONTO"],
    ["Estado", "ESTADO"]
  ];

	useEffect(() => {
    if(process.env.REACT_APP_ENVIRONMENT !== 'dev'){
      if(token && rutConcesionario){
        if (rutConcesionario === "77215640-5") {
          setIsArcoprime(true);
          getFiliales();
        } else {
          getPagos()
        }
      }
    }
    else{
      if(token){
        if (rutConcesionario === "77215640-5" /* || true */) {
          setIsArcoprime(true);
          getFiliales();
        } else {
          getPagos()
        }
      }
    }
  }, [token, rutConcesionario])

  useEffect(() => {
    if (selectedEdsArcoprime) {
      getPagos();
    }
  }, [selectedEdsArcoprime]);

  function liberado(){
    setSelectedDocumentos([])
    setSelectedPropuesta()
    setSelectedMonto(0)
    getPagos()
  }

  async function getPagos(){
    let url = process.env.REACT_APP_BASE_API + '/cuenta-corriente/obtener-pagos-inconclusos?rut_concesionario=' + rutConcesionario;

    if(isArcoprime){
      url += "&id_eds="+selectedEdsArcoprime.value
    }

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      const data = result.data
      setProducts(data['PROPUESTAS_PAGO'].filter((mov)=>mov.TIPO!="Abono"));
      const date = new Date()

      const day = parseInt(date.getDate()) > 9? date.getDate(): '0' + date.getDate()
      let month = parseInt(date.getMonth() + 1)
      month = month > 9? month: '0' + month.toString()

      getUpdateDate(setUpdateDate)
      setProductsLoaded(true);
    }
  }

  function liberarPagoClicked(){    
    if(selectedPropuesta){
      const selectedDocuments = products.find(e => e.GRUPO_PPTA === selectedPropuesta)
      setSelectedDocumentos(selectedDocuments.DOCUMENTOS)
      setSelectedMonto(selectedDocuments.MONTO)
    }
  }

  const goBack = () => {
    setSelectedDocumentos([]);
  }

  async function getFiliales() {
    const url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/listar-filiales?rut_concesionario=77215640-5";

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      const data = result.data;
      const eeddss = [];

      data.FILIALES.forEach(function (eds) {
        let ide = eds.ID_EDS;
        while (ide.charAt(0) === "0") {
          ide = ide.substring(1);
        }
        const newEds = {
          value: eds.ID_EDS,
          label: ide + " - " + eds.DIRECCION,
        };
        eeddss.push(newEds);
      });

      setAllEdsArcoprime(eeddss);
    }
  }

  async function changeEdsArcoprime(e) {
    setSelectedEdsArcoprime(e);
  }

  return (
    <div className="resumen">
      <div className="title-container">
        <div className="title">
          <div className="view-title">Cuenta corriente</div><div className="view-title-separator">|</div>
    	    <div className="view-subtitle">Liberar documentos</div>
        </div>
        {
          selectedDocumentos.length > 0? <><div className="volver-arrow" onClick={()=>goBack()} >
          <img></img>
          <p className="volver-text">Volver</p>
        </div></>:<></>
        }
      </div>
    	

    	<hr className="title-separator-line" />

      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        cabecera={cabecera}
        callServiceHandler={callServiceHandler}
      />

	    <hr />

      { selectedDocumentos.length === 0 && 
        <>
          <div className="liberar-options">
            <div className="filters-container">
              {
                isArcoprime && <>
                <div className="filter">
            <div className="filter-title">ESTACIÓN DE SERVICIO</div>
            <div className="filter-input">
              <Select
                className="eds-select"
                value={selectedEdsArcoprime}
                onChange={(e) => changeEdsArcoprime(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allEdsArcoprime}
                components={{ SingleOption, IndicatorSeparator: () => null }}
                isClearable={false}
                autosize={false}
                placeholder="Todas"
                styles={customStyles}
              />
            </div>
          </div>
                </>
              }
              <div className={`filter ${isArcoprime && isDesktopRef.current?'mleft':''}`}>
                <div className="filter-input">
                  <input type="search" className="search nomarginsearch" value={filterText} placeholder="Buscar..." onChange={(e) => setFilterText(e.target.value)} />
                </div>
              </div>
            </div>

            <div className="actions">
              <button onClick={liberarPagoClicked} className={`actions-buttons ${selectedPropuesta? '': ' disabled'}`}>
                Liberar
              </button>
              <PrintComponent 
                reff={tableRef}
                classes=""
                pageStyle="@page { size: 330mm 280mm; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }"
                isROImpersonate={isROImpersonate}
              />
              <ExportExcel 
                data={filteredProducts} 
                labels={labels}
                isROImpersonate={isROImpersonate} 
              />
            </div>
          </div>
    	    {
    	    	token &&
    	    	<HistorialPagosTable 
    	    		products = { products }
              setProducts={setProducts}
              filterText = { filterText }
              setSelectedComprobante = { setSelectedPropuesta }
              setSelectedPropuesta = { setSelectedPropuesta }
              selectedPropuesta = { selectedPropuesta}
              isLiberarPagos = { true }
              filteredProducts = { filteredProducts }
              setFilteredProducts = { setFilteredProducts }
              productsLoaded={productsLoaded}
              tableRef={tableRef}
    	    	/>
    	    }
        </>
      }
      { selectedDocumentos.length > 0 &&
        <LiberarDocumentos 
          selectedDocumentos = { selectedDocumentos }
          setSelectedDocumentos = { setSelectedDocumentos }
          selectedMonto = { selectedMonto }
          selectedPropuesta = { selectedPropuesta }
          token = { token }
          liberado = { liberado }
          isROImpersonate={ isROImpersonate }
          callServiceHandler={ callServiceHandler }
        />
      }
      
    </div>
  )
};

export default LiberarPagos;
