import React, {useState,useEffect} from "react";
import ReactExport from "react-export-excel";
import CustomModal from "./CustomModal";
import download from "../assets/img/DOWNLOAD.svg";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportExcelHidden = ({ data, labels, isROImpersonate, isArcoprime,requestCounter,fileName }) => {
  const [displayModal, setDisplayModal] = useState(false)

  function closeModal(){
    setDisplayModal(false)
  }

  function showIsROAlert(){
    setDisplayModal(true)
  }

  useEffect(() => {
    if(requestCounter>0){
      const boton = document.getElementById("excel-button");
      boton.click();
    }
  }, [requestCounter]);

  return (
    <>
      {isROImpersonate &&
        <img src={download} className={`cursor-pointer ${isArcoprime?"arcoprime":""}`} onClick={showIsROAlert} />
      }
      {!isROImpersonate &&
        <ExcelFile element={<button id="excel-button" className="main-button hallow iconbutton hide"><img src={download} alt="" />Detalle ventas</button>} filename={fileName}>
          <ExcelSheet data={data} name="Hoja 1">
            {
              labels.map((text,key)=><ExcelColumn key={key} label={text[0]} value={text[1]} />)
            }
          </ExcelSheet>
        </ExcelFile>
      }
      <CustomModal
        displayModal={displayModal}
        closeModal={closeModal}
        acceptFunc={closeModal}
        modalTitle='Importante'
        modalText='Por seguridad no estás autorizado para descargar y/o imprimir información del Módulo Cuenta Corriente'
        modalIcon='importante'
      />
    </>
  );
};
export default ExportExcelHidden