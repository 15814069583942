import React, {useEffect, useState, useRef} from "react";
import { orderArray, getLocaleStringNumber } from "../utils/utils";
import Flecha_down from "../../assets/img/Flecha_down.svg";
import Flecha_up from "../../assets/img/Flecha_up.svg";
import { hideGreyBackground, showGreyBackground } from "../utils/windows";
import { getPaginationItems } from "../utils/get";
import CustomHighlighter from "../CustomHighlighter";
import MantenimientoModal from "./MantenimientoModal";

const OTCobrosPorFormularTable = (props) => {
  const tableRef = props.tableRef;
  const ordenes = props.ordenes;
  const filteredOrdenes = props.filteredOrdenes;
  const filterText = props.filterText;
  const setFilteredOrdenes = props.setFilteredOrdenes;
  const [distanceToTop, setDistanceToTop] = useState(0);
  const [ordenModal, setOrdenModal] = useState({});
  const [displayOrdenModal, setDisplayOrdenModal] = useState(false);
  const [count, setCount] = useState(0)
  
  const paginationFilteredOrdenes = props.paginationFilteredOrdenes;
  const setPaginationFilteredOrdenes = props.setPaginationFilteredOrdenes;
  const setPaginationActivePage = props.setPaginationActivePage;
  const openFile = props.openFile;
  const refs = useRef([])

  const orderTable = (filterkey, order, parse) => {
    const arr = orderArray(filterkey, filteredOrdenes, order, parse);
    setFilteredOrdenes(arr);
  };

  const changeOrdenModal = (e, orden) => {
    showGreyBackground();
    setDisplayOrdenModal(true);
    setOrdenModal(orden);
    setDistanceToTop(e.currentTarget.getBoundingClientRect().top);
  };

  function closeModal() {
    setDisplayOrdenModal(false);
    hideGreyBackground();
  }

  function getActiveEllipsis(key) {
    const e = refs.current[key]
    if(e){
      const isActive = e.offsetWidth < e.scrollWidth
      return isActive
    }
    return false
  }

  useEffect(() => {
    refs.current = refs.current.slice(0, paginationFilteredOrdenes.length);
    setCount(count + 1)
  }, [paginationFilteredOrdenes]);

  useEffect(() => {
    filter();
  }, [filterText]);

  useEffect(() => {
  if (ordenes?.length>0){
    getPaginationItems(filteredOrdenes,1,setPaginationFilteredOrdenes,setPaginationActivePage)
  }
  }, [filteredOrdenes]);

  const filter = () => {

  let ordenesArr = ordenes;

  if (filterText && ordenesArr?.length > 0) {
    let filterTextLower = filterText.toLowerCase();
    filterTextLower = filterTextLower.replaceAll(".", "");

    ordenesArr = ordenesArr.filter((orden) => {
      return (
        (orden.NUMERO_ORDEN &&
          orden.NUMERO_ORDEN.toLowerCase().includes(filterTextLower)) ||
        (orden.FECHA_ORDEN &&
          orden.FECHA_ORDEN.toLowerCase().includes(filterTextLower)) ||
        (orden.FECHA_CIERRE &&
          orden.FECHA_CIERRE.toLowerCase().includes(filterTextLower)) ||
        (orden.DESCRIPCION &&
          orden.DESCRIPCION.toLowerCase().includes(filterTextLower))||
        (orden.MONTO_PLAN &&
          orden.MONTO_PLAN.toString().toLowerCase().includes(filterTextLower))||
        (orden.MONTO_REAL &&
          orden.MONTO_REAL.toString().toLowerCase().includes(filterTextLower))||
        (orden.FACTURA &&
          orden.FACTURA.toLowerCase().includes(filterTextLower))||
          (orden.ESTADO &&
            orden.ESTADO.toLowerCase().includes(filterTextLower))
      );
    });
  }

  setFilteredOrdenes(ordenesArr);
  }

  return (<>
    <div className="pedidos-activos-table-container ot">
      <table className="pedidos-activos-table ot mantenimiento-table" ref={tableRef}>
        <tr>
          <th className="no-padding-left">
            <div className="th-data">
              <div className="th-text">
                Nº de Orden
                <div className="arrows">
                  <img
                    onClick={() => orderTable("NUMERO_ORDEN", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("NUMERO_ORDEN", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Fecha de Orden
                <div className="arrows mtop">
                  <img
                    onClick={() => orderTable("FECHA_ORDEN", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("FECHA_ORDEN", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Fecha de Cierre
                <div className="arrows mtop">
                  <img
                    onClick={() => orderTable("FECHA_CIERRE", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("FECHA_CIERRE", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Estado
                <div className="arrows">
                  <img
                    onClick={() => orderTable("ESTADO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("ESTADO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data w150">
              <div className="th-text">
                Monto Plan
                <div className="arrows">
                  <img
                    onClick={() => orderTable("MONTO_PLAN", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("MONTO_PLAN", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data w150">
              <div className="th-text">
                Monto Real*
                <div className="arrows">
                  <img
                    onClick={() => orderTable("MONTO_REAL", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("MONTO_REAL", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className={`th-data w390`}>
              <div className="th-text">
                Descripción
                <div className="arrows">
                  <img
                    onClick={() => orderTable("DESCRIPCION", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("DESCRIPCION", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className={`th-data`}>
              <div className="th-text">
                Factura
                <div className="arrows">
                  <img
                    onClick={() => orderTable("FACTURA", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("FACTURA", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
        </tr>
        <tr className="table-separator-row-border-top">
          <td />
        </tr>
        <tbody>

          {typeof filteredOrdenes === 'undefined' && 
            <tr className="data">
              <td className="align-left" colSpan="100%">
                Seleciona un rango de fechas
              </td>
          </tr>}

          {filteredOrdenes && <>
            {
            filteredOrdenes.vacio? 
            <tr className="data">
              <td className="align-left" colSpan="100%">
                La búsqueda no arrojó resultados. Por favor inténtalo de nuevo.
              </td>
            </tr>:
            <>
              {paginationFilteredOrdenes.map((orden, key)=>{
                return (
                  <tr className="data" key={key}>
                    <td className="align-left">
                      {orden.TIENE_ADJUNTOS>0?<>
                        <a
                        className={`underline cursor-pointer`}
                        onClick={(e) => changeOrdenModal(e, orden)}
                        >
                          <CustomHighlighter text={filterText}>
                          {orden.NUMERO_ORDEN.substring(4)}
                          </CustomHighlighter>
                        </a>
                      </>:<><CustomHighlighter text={filterText}>{orden.NUMERO_ORDEN.substring(4)}</CustomHighlighter></>}
                    </td>
                    
                    <td className="align-left"><CustomHighlighter text={filterText}>{orden.FECHA_ORDEN}</CustomHighlighter></td>
                    <td className="align-left"><CustomHighlighter text={filterText}>{orden.FECHA_CIERRE}</CustomHighlighter></td>
                    <td className="align-left"><CustomHighlighter text={filterText}>{orden.ESTADO}</CustomHighlighter></td>
                    <td className="align-right"><CustomHighlighter text={filterText}>{"$ "+getLocaleStringNumber(orden.MONTO_PLAN)}</CustomHighlighter></td>
                    <td className="align-right"><CustomHighlighter text={filterText}>{"$ "+getLocaleStringNumber(orden.MONTO_REAL)}</CustomHighlighter></td>
                    <td className="align-left ellipsis-with-tooltip-container ellipsis-with-tooltip-container-mantenimiento">
                      <div 
                        ref={el => refs.current[key] = el} 
                        className={`count-${count} table-data-ellipsis ot ${getActiveEllipsis(key)? 'active-ellipsis': ''}`}>
                          <CustomHighlighter text={filterText}>{orden.DESCRIPCION}</CustomHighlighter>
                          <div className={'ellipsis-tooltip'}>
                            <div className="ellipsis-tooltip-title">DESCRIPCIÓN</div>
                            <div className="ellipsis-tooltip-content">
                              {orden.DESCRIPCION}
                            </div>
                          </div>
                      </div>
                    </td>
                    <td className="align-left">
                      <a
                        className="factura-link"
                        target={`${orden.FACTURA!=="" ? "_blank" : ""}`}
                        href={`${
                          orden.FACTURA!=="" ? orden.URL : "javascript:void(0);"
                        }`}
                      >
                        <CustomHighlighter text={filterText}>{orden.FACTURA}</CustomHighlighter>
                      </a>
                    </td>
                  </tr>
                )
              })}
            </>
            }
          </>}

          {/* <tr className="data">
            <td className="align-left">81367086</td>
            <td className="align-left">01/04/2022</td>
            <td className="align-left">n/a</td>
            <td className="align-left">En proceso</td>
            <td className="align-right">$1</td>
            <td className="align-right">$0</td>
            <td className="align-left">
                <div className="table-data-ellipsis ot">
                Plan Mtto Preventivo Autoservicio Lavamatico maximo plus yeah
                </div>
             </td>
            <td className="align-left">n/a</td>
          </tr>
          <tr className="data">
            <td className="align-left">81367086</td>
            <td className="align-left">01/04/2022</td>
            <td className="align-left">n/a</td>
            <td className="align-left">En proceso</td>
            <td className="align-right">$1</td>
            <td className="align-right">$0</td>
            <td className="align-left">Plan Mtto Preventivo Autoservicio</td>
            <td className="align-left">n/a</td>
          </tr> */}
        </tbody>
      </table>
    </div>
    <div className="modal-container">
      <MantenimientoModal 
      displayModal={displayOrdenModal}
      closeModal={closeModal}
      distanceToTop={distanceToTop}
      orden={ordenModal}
      tipo="orden"
      openFile={openFile}
      />
    </div>
    </>);
};

export default OTCobrosPorFormularTable;
