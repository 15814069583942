import React, {useState} from "react";
import Flecha_down from "../../assets/img/Flecha_down.svg";
import Flecha_up from "../../assets/img/Flecha_up.svg";
import Step1 from "../../assets/img/step_1.svg";
import Step2 from "../../assets/img/step_2.svg";
import Step3 from "../../assets/img/step_3.svg";
import Step4 from "../../assets/img/step_4.svg";
import Step5 from "../../assets/img/step_5.svg";
import Editar from "../../assets/img/EDITAR.svg";
import Candado from "../../assets/img/Candado.svg";
import Ver from "../../assets/img/VER.svg";
import checkgreen from "../../assets/img/Check_green.svg";
import { orderArray, parseBudat } from "../utils/utils";
import checkyellow from "../../assets/img/check_yellow.svg";
import checkred from "../../assets/img/atencion_rojo.svg";
import invisible from "../../assets/img/Check_transparent.svg"
import CustomModal from "../CustomModal";

const CierreDiarioTable = (
  {tableRef, 
    cierres, 
    setFechaCierre, 
    setCierres, 
    cierresOriginal, 
    setSelectedEstadoCierre, 
    setStep = null,
    isROImpersonate,
    setSelectedCierre,
    esPosibleCerrar, 
    isReapertura = false,
    configuracionError,
    displayModalErrorConfiguracion,
    setDisplayModalErrorConfiguracion
  }) => {

  const [displayModal, setDisplayModal] = useState(false);
  const [displayModalMesAnterior, setDisplayModalMesAnterior] = useState(false);

  const orderTable = (filterkey, order, parse) => {
    const arr = orderArray(filterkey, cierresOriginal, order, parse);
    setCierres(arr);
  };

  const changeSelectedCierre = (cierre,key) => {
    if(configuracionError){
      setDisplayModalErrorConfiguracion(true);
      return;
    }
    if(cierres.length-1===key && esPosibleCerrar==="NO"){
      setDisplayModalMesAnterior(true);
    }else{
      if(cierre.blocked && cierre.estado_cierre!=="CERRADO"){
        setDisplayModal(true)
      }else{
        setFechaCierre(cierre.fecha)
        setSelectedEstadoCierre(cierre.estado_cierre)
        setSelectedCierre(cierre);
        const pasos = [
          {step:1,estado:cierre.confirmacion_entrada},
          {step:2,estado:cierre.confirmacion_ventas},
          {step:3,estado:cierre.calibracion_bodega},
          {step:4,estado:cierre.medicion_fisica},
          {step:5,estado:cierre.cierre_operaciones}
        ]
        const primeroNoCerrado = pasos.find((paso)=>{return paso.estado!=="CERRADO"})
        if(cierre.estado_cierre==="CERRADO" && !isReapertura){
          setStep(1)
        }else if(!isReapertura){
          setStep(primeroNoCerrado.step);
        }
      }
    }
  }

  const reabrir = (cierre, key) => {
    console.log("reabriendo")    
  }

  function closeModal() {
    setDisplayModal(false)
    setDisplayModalMesAnterior(false)
    setDisplayModalErrorConfiguracion(false)
  }

  const getIcon = (paso,pasoNumber) => {
    if(paso==="ABIERTO"){
      switch (pasoNumber) {
        case 1: return <img src={Step1} /> 
        break;
        case 2: return <img src={Step2} /> 
        break;
        case 3: return <img src={Step3} /> 
        break;
        case 4: return <img src={Step4} /> 
        break;
        case 5: return <img src={Step5} /> 
        break;
        default:
          break;
      }
    }else{
      switch (paso) {
        case "CERRADO":
          return <img src={checkgreen} />
          break;
        case "EN_REVISION":
          return <img src={checkyellow} />
          break;
        case "REABIERTO":
          return <img src={checkred} />
          break;
        default:
          break;
      }
    }
    
  }

  return (
    <>
      <div className="pedidos-activos-table-container cierre-diario-table-container">
        <table className="pedidos-activos-table cierre-diario-table" ref={tableRef}>
          <tr>
            <th className="no-padding-left">
              <div className="th-data">
                <div className="th-text">
                  Fecha
                  <div className="arrows">
                    <img
                      onClick={() => orderTable("fecha", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() => orderTable("fecha", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            <th>
                <div className="th-data">
                <div className="th-text">
                    Entradas
                </div>
                </div>
            </th>
            <th>
                <div className="th-data">
                <div className="th-text">
                    Ventas
                </div>
                </div>
            </th>
            <th>
                <div className="th-data two">
                <div className="th-text">
                    Calibración y Mov. Bodega
                </div>
                </div>
            </th>
            <th>
                <div className="th-data two">
                <div className="th-text">
                    Medición Física
                </div>
                </div>
            </th>
            <th>
                <div className="th-data">
                <div className="th-text">
                    Fluctuciones
                </div>
                </div>
            </th>
            <th>
                <div className="th-data two">
                <div className="th-text">
                    Estado Cierre
                    <div className="arrows mtop">
                    
                    <img
                      onClick={() => orderTable("estado_cierre", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() => orderTable("estado_cierre", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
                </div>
            </th>
            <th>
                <div className="th-data">
                  <div className="th-text">
                    {isReapertura? 'Reabrir': 'Acción Cierre'}
                  </div>
                </div>
            </th>
          </tr>
          <tr className="table-separator-row-border-top">
          <td />
          </tr>
          <tbody>
            {cierres?
            <>
              {cierres.length>0? 
                cierres.map((cierre,key)=>{
                  return(
                      <tr className="data" key={key} >
                          <td className="align-left">{parseBudat(cierre.fecha)}</td>
                          <td className="align-left icon-container"> {getIcon(cierre.confirmacion_entrada,1)} </td>
                          <td className="align-left icon-container">  {getIcon(cierre.confirmacion_ventas,2)} </td>
                          <td className="align-left icon-container">   {getIcon(cierre.calibracion_bodega,3)}</td>
                          <td className="align-left icon-container">  {getIcon(cierre.medicion_fisica,4)} </td>
                          <td className="align-left icon-container">  {getIcon(cierre.cierre_operaciones,5)} </td>
                          {/* <td className="align-left icon-container state-container"><img src={cierre.condicion?checkyellow:invisible}/>{cierre.estado_cierre}</td> */}
                          <td className="align-left">{cierre.estado_cierre}</td>
                          <td className="align-left icon-container">
                            {!isReapertura &&
                              <a className="cursor-pointer" onClick={()=>changeSelectedCierre(cierre,key)}>
                                {cierre.estado_cierre==="CERRADO" || cierre.estado_cierre==="EN_COPEC"?<img src={Ver} />:<img src={Editar} />}
                              </a>
                            }
                            {isReapertura && cierre.estado_cierre==="CERRADO" &&
                              <a className="cursor-pointer" onClick={() => reabrir(cierre, key)}>
                                <img src={Candado} />
                              </a>
                            }
                          </td>
                      </tr>)
              }):<>
                <tr className="data">
                  <td className="align-left" colSpan="100%">
                    No hay cierres para este periodo
                  </td>
                </tr>
              </>}
            </>:<>
            <tr className="data">
              <td className="align-left" colSpan="100%">
                Busca por EDS y por Periodo.
              </td>
            </tr>
            </>
          }
            
          </tbody>


        </table>
        <CustomModal
          displayModal = { displayModal }
          closeModal = { closeModal }
          acceptFunc = { closeModal }
          acceptText= "Aceptar"
          modalTitle = "Importante"
          modalText = "No se puede cerrar un día si es que existen días anteriores que estén (RE) ABIERTOS"
          modalIcon = "importante"
        />
        <CustomModal
          displayModal = { displayModalMesAnterior }
          closeModal = { closeModal }
          acceptFunc = { closeModal }
          acceptText= "Aceptar"
          modalTitle = "Importante"
          modalText = "No se puede cerrar un día si es que existen días del mes anterior que sigan ABIERTOS."
          modalIcon = "importante"
        />
        <CustomModal
          displayModal = { displayModalErrorConfiguracion }
          closeModal = { closeModal }
          acceptFunc = { closeModal }
          acceptText= "Aceptar"
          modalTitle = "Importante"
          modalText = "No existe configuración de tanques para la estación. Contacte a telesoporte."
          modalIcon = "importante"
        />
      </div>
    </>
  );
};

export default CierreDiarioTable;
