import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import filterButton from "../../../assets/img/filtro.svg"
import Flecha_up from "../../../assets/img/Flecha_up.svg";
import Flecha_down from "../../../assets/img/Flecha_down.svg";
import Editar from "../../../assets/img/EDITAR.svg"

import { SingleOption, customStylesTctTae, getLocaleStringNumber, orderArray, parseBudat, parseDateAmerican } from "../../utils/utils.js";
import { getPaginationItems } from "../../utils/get";
import Pagination from "react-js-pagination";
import OverflowedText from "../../OverflowedText";
import CustomHighlighter from "../../CustomHighlighter";


const ConsultarGuiasManualesPorFecha = ({
  setDisplayInfo,
  setSelectedEds,
  selectedEds,
  allEds,
  numeroGuia,
  setNumeroGuia,
  setFechaAutorizacion,
  setHoraAutorizacion,
  changeEds,
  displayFechaTable,
  setDisplayFechaTable,
  callServiceHandler,
  // getGuias,
  // data
  setRutCliente,
  setNumeroTarjeta,
  setCodigoAutorizacion,
  setComuna,
  setDireccion,
  setGiroComercial,
  setProducto,
  setMonto,
  setNombreCliente,
  setPrecio,
  setUnidad,
  setFlete,
  fechaInicio,
  setFechaInicio,
  fechaFin,
  setFechaFin,
  guias,
  codigoCliente,
  setCodigoCliente,
  numeroTransaccion,
  setNumeroTransaccion,
  codigoProducto,
  setCodigoProducto,
  codigoSubProducto,
  setCodigoSubProducto,
  setGuiaEstado,
  setAccion
  // allTCT_TAE_Products,
  // selected_TCT_TAE_Product,
  // setSelected_TCT_TAE_Product,
  // allEstadoGuia,
  // selectedEstadoGuia,
  // setSelectedEstadoGuia,
}) => {

  // const info = [
  //   {
  //     nro_guia: "8954848",
  //     date: "20/08/2021",
  //     motivo: "Guía TAE",
  //     producto: "TAE",
  //     cliente: "631163 - WALSEN CORNEJO MARTIN",
  //     nro_tarjeta: "1-631163-00009-9-4",
  //     monto: "$ 203.634",
  //     estado: "Confirmada"
  //   },
  //   {
  //     nro_guia: "893 38970",
  //     date: "31/07/2021",
  //     motivo: "Guía TAE",
  //     producto: "TAE",
  //     cliente: "620527 - RIVAS SCHULZ MARIA XIMENA",
  //     nro_tarjeta: "1-620527-00048-9-6",
  //     monto: "$ 48.457",
  //     estado: "Confirmada"
  //   },

  // ]

  // const [fechaInicio, setFechaInicio] = useState();
  // const [fechaFin, setFechaFin] = useState();

  const [filterText, setFilterText] = useState('');

  const [displaySecondRow, setDisplaySecondRow] = useState(false);

  const [selected_TCT_TAE_Product, setSelected_TCT_TAE_Product] = useState();

  const [allTCT_TAE_Products, setAllTCT_TAE_Products] = useState();

  const [selectedMotivo, setSelectedMotivo] = useState();

  const [allMotivos, setAllMotivos] = useState();

  const [selectedEstadoGuia, setSelectedEstadoGuia] = useState();

  const [allEstadoGuia, setAllEstadoGuia] = useState();

  // const [guias, setGuias] = useState();

  const [filteredData, setFilteredData] = useState();
  const [activePage, setActivePage] = useState(1)

  const [paginatedData, setPaginatedData] = useState([])

  const [errorFiltros, setErrorFiltros] = useState(false);



  useEffect(() => {
    setActivePage(1)
    if(filteredData?.length <= 15)
    setPaginatedData(filteredData)
    else
    setPaginatedData(filteredData?.slice(0, 15))
    setActivePage(1)
    console.log("fd",filteredData)
  }, [filteredData])

  function handlePageChange(pageNumber){
    getPaginationItems(guias, pageNumber, setPaginatedData, setActivePage);
  }
  
  const filterr = () => {
    let dataArr
    if ((filterText !== "")) {
      let filterTextLower = filterText.toLowerCase();
      filterTextLower = filterTextLower.replaceAll(".", "");
      dataArr = guias.filter(data => {
        return (
          data.numero_guia.toLowerCase().includes(filterTextLower) ||
          data.fecha_autorizacion.toLowerCase().includes(filterTextLower) ||
          // data.codigo_autorizacion.toLowerCase().includes(filterTextLower) ||
          data.motivo.toLowerCase().includes(filterTextLower) ||
          data.producto.toLowerCase().includes(filterTextLower) ||
          data.cliente.toLowerCase().includes(filterTextLower) ||
          // data.patente.toLowerCase().includes(filterTextLower) ||
          data.numero_tarjeta.toLowerCase().includes(filterTextLower) ||
          data.monto.toLowerCase().includes(filterTextLower) ||
          data.estado.toLowerCase().includes(filterTextLower)
        )
      })
      console.log("algo se escribe")
      setFilteredData(dataArr)
    } else {
      console.log("nada se escribe")
      setFilteredData(guias)
    }
  
  }

  useEffect(() => {
    // if (filterText) {
      filterr();
      console.log("text filtr", filterText)
    // }
}, [filterText]);

useEffect(() => {
  setSelected_TCT_TAE_Product({
    value: "TOD",
    label: "Todos",
  })
  setAllTCT_TAE_Products([
    {
      value: "TOD",
      label: "Todos",
      producto: "Todos"
    },
    {
      value: "tct",
      label: "TCT",
      producto: "tct"
    },
    {
      value: "tae",
      label: "TAE",
      producto: "tae"
    }
  ])

  setSelectedEstadoGuia({
    value:"",
    label:"Todos"
  })

  setAllEstadoGuia([
    {
      value:"",
      label:"Todos",
      estado:"Todo"
    },
    {
      value:"A",
      label:"Guías Anuladas",
      estado:"Anulada"
    },
    {
      value:"P",
      label:"Guías Autorizadas",
      estado:"Autorizada"
    },
    {
      value:"C",
      label:"Guías Confirmada",
      estado:"Confirmada"
    },
  ])
}, []);

useEffect(() => {
  if (selected_TCT_TAE_Product && selectedEstadoGuia && guias) {
    handleSelector()
  }
}, []);


  useEffect(() => {
    if(selectedEds, fechaInicio, fechaFin){
      setErrorFiltros(false)
      setDisplaySecondRow(true)
      setDisplayFechaTable(true)
      // getGuias()
    }
  }, [selectedEds, fechaInicio, fechaFin]);

  useEffect(() => {
    if (selectedEds) {
      console.log(selectedEds.value)
    }
  }, [selectedEds]);

  function changeEds(e) {
    if (e !== selectedEds) {
      setSelectedEds(e);
    }
  }

  function change_TCT_TAE_Product(e) {
    if (e !== selected_TCT_TAE_Product) {
      setSelected_TCT_TAE_Product(e);
    }
  }

  function changeEstadoGuia(e) {
    if (e !== selectedEstadoGuia) {
      setSelectedEstadoGuia(e);
    }
  }

  const getDetalleGuia = async (rut, codigo, transaccion) => {
    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-detalle-guia?RUTCLIENTE=" + rut 
    +"&CODCLIENTE=" + codigo
    +"&NUMTRANSACCION=" + transaccion;

    let response = await callServiceHandler(url, "GET");

    if (response.data !== "error") {
      console.log("detalle guia", response.data)
  
      setCodigoCliente(codigo)
      setNumeroTransaccion(transaccion)
      setComuna(response.data.guia.comuna)
      setDireccion(response.data.guia.direccion)
      setGiroComercial(response.data.guia.giro_comercial)
      setMonto(response.data.guia.monto)
      setNombreCliente(response.data.guia.nombre_cliente)
      setPrecio(response.data.guia.precio)
      setUnidad(response.data.guia.unidad)
      setProducto(response.data.guia.producto)
      setCodigoProducto(response.data.guia.cod_producto)
      setCodigoSubProducto(response.data.guia.cod_subproducto)
      setFlete(response.data.guia.flete)
      setDisplayInfo(true)
    }
  }

  useEffect(() => {
    if (selected_TCT_TAE_Product && selectedEstadoGuia && guias) {
      handleSelector()
    }
  }, [selected_TCT_TAE_Product, selectedEstadoGuia]);

  const handleSelector = () =>{
    let newData

    if (selected_TCT_TAE_Product.value !== "TOD") {
      console.log(selected_TCT_TAE_Product.value)
      newData = guias.filter(data=>data.producto.toLowerCase() === selected_TCT_TAE_Product.value.toLowerCase())
    }else{
      newData = guias.filter(data=>data.producto === "TCT" || data.producto === "TAE")
    }

    if (selectedEstadoGuia.value !== "") {
      newData = newData.filter(data=>data.estado.toLowerCase() === selectedEstadoGuia.estado.toLowerCase())
    }else{
      newData = newData.filter(data=>data.estado === "Confirmada" || data.estado === "Autorizada" || data.estado === "Anulada" || data.estado === "Pendiente")
    }
    console.log("new data", newData)
    setFilteredData(newData)
  }

  const orderTable = (filterkey, order, parse) => {
    const arr = orderArray(filterkey, filteredData, order, parse);
    setFilteredData(arr);
  };

  useEffect(() => {
    if (guias) {
      setFilteredData(guias)
    }
  }, [guias]);

  // const getGuias = async () => {

  //   const url = 
  //   process.env.REACT_APP_TCT_TAE_API +
  //   "/tct-tae/consultar-guias?IDEDS=" + parseInt(selectedEds.value)
  //   + "&FECHAINICIAL=" + parseDateAmerican(fechaInicio)
  //   + "&FECHAFINAL=" + parseDateAmerican(fechaFin)

  //   let response = await callServiceHandler(url, "GET");

  //   console.log("cliente", response.data)

  //   setGuias(response.data.guias)
  //   setFilteredData(response.data.guias)
  // }

  const searchButtonHandle = () => {
    // setDisplaySecondRow((secondRowState)=>!secondRowState)
    if (fechaInicio && fechaFin) {
      setDisplaySecondRow(true)
      setErrorFiltros(false)
    }else{
      console.log("No se selecciono fechas")
      setErrorFiltros(true)
    }
    // setDisplayFechaTable(true)
    // getGuias()
  }

  const openInfo = (rut_cliente, codigo_cliente, numero_transaccion, numero_guia, numero_tarjeta, codigo_autorizacion, fecha_autorizacion, hora_autorizacion, guiaEstado, editable) => {

    getDetalleGuia(rut_cliente, codigo_cliente, numero_transaccion)

    setNumeroGuia(numero_guia)
    setFechaAutorizacion(fecha_autorizacion)
    setHoraAutorizacion(hora_autorizacion)
    setRutCliente(rut_cliente)
    setNumeroTarjeta(numero_tarjeta)
    setCodigoAutorizacion(codigo_autorizacion)
    setGuiaEstado(guiaEstado)
    setAccion(editable)
    // setDisplayInfo(true)
  }

  function getMaxDate(fechaD){
    const now = new Date();
    const minMasUno = new Date(fechaD?.getFullYear()+1,fechaD?.getMonth(),fechaD?.getDate())

    if(now>minMasUno){
      return minMasUno;
      
    }else{
      return now;
    }
  }

  function changeFechaInicio(e) {
    setFechaInicio(e);
    setFechaFin();
  }

  return (
    <div className="consultar-guias-manuales-por-fecha">
      <div className="total-filters-container">
        <div className="first-row">
          <div className="filters-container">
            <div className="filter">
              <div className="filter-title required-field">
                ESTACIÓN DE SERVICIO
              </div>
              <div className="filter-input eds-select-container">
                <Select
                  className="eds-select"
                  value={selectedEds}
                  onChange={(e) => changeEds(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allEds}
                  components={{
                    SingleOption,
                    IndicatorSeparator: () => null,
                  }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesTctTae}
                  width="192px"
                />
              </div>
            </div>
            <div className="filter">
              <div className="filter-title required-field">
                FECHA INICIO
              </div>
              <div className="filter-input">
                <ReactDatePicker
                  locale="es"
                  selected={fechaInicio}
                  onChange={(e) => changeFechaInicio(e)}
                  shouldCloseOnSelect={true}
                  dateFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  placeholderText="Seleccionar"
                />
              </div>
            </div>
            <div className="filter">
              <div className="filter-title required-field">
                FECHA FIN
              </div>
              <div className="filter-input">
                <ReactDatePicker
                  locale="es"
                  selected={fechaFin}
                  onChange={(e) => setFechaFin(e)}
                  shouldCloseOnSelect={true}
                  dateFormat="dd/MM/yyyy"
                  maxDate={getMaxDate(fechaInicio)}
                  minDate={fechaInicio}
                  placeholderText="Seleccionar"
                  disabled={fechaInicio ? false : true}
                />
              </div>
            </div>
            <div className="filter">
              <div className="filter-title">
                FORMA COMER.
              </div>
              <div className="filter-input tct-tae-producto-select">
                <Select
                  className="tct-tae-producto-select"
                  value={selected_TCT_TAE_Product}
                  onChange={(e) => change_TCT_TAE_Product(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allTCT_TAE_Products}
                  components={{
                    SingleOption,
                    IndicatorSeparator: () => null,
                  }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesTctTae}
                  width="192px"
                />
              </div>
            </div>
            <div className="filter">
              <div className="filter-title">
                ESTADO
              </div>
              <div className="filter-input estado-select">
                <Select
                  className="estado-select"
                  value={selectedEstadoGuia}
                  onChange={(e) => changeEstadoGuia(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allEstadoGuia}
                  components={{
                    SingleOption,
                    IndicatorSeparator: () => null,
                  }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesTctTae}
                  width="192px"
                />
              </div>
            </div>
            {
              paginatedData?.length >= 1 &&
                <div className="filter">
                  <div className="filter-input">
                    <input
                      type="search"
                      className={`search ${displaySecondRow?"small":"large"}`}
                      value={filterText}
                      placeholder="Buscar..."
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                  </div>
                </div>
            }
            {/* <button className="main-button thin" onClick={searchButtonHandle}>Buscar</button> */}
            {/* <button className="main-button thin" onClick={searchButtonHandle}>Buscar</button> */}
          </div>
        </div>
        {/* {
          displaySecondRow &&
            <div className="second-row">
              <div className="filters-container">
                <div className="filter">
                  <div>
                    <div className="filter-input">
                      <input
                        type="search"
                        className={`search ${displaySecondRow?"small":"large"}`}
                        value={filterText}
                        placeholder="Buscar..."
                        onChange={(e) => setFilterText(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        } */}
      </div>
      {
        errorFiltros &&
          <div className="important-message error">
            <span className="error-icon">!</span>
            <span className="text">Ingrese los filtros requeridos</span>
          </div>
      }
      {
        displayFechaTable &&
          <div className="consultar-guias-manuales-por-fecha-table-container">
            <table className="tct-tae-table">
              <tr>
                <th>
                  <div className="th-data width-100">
                    <div className="th-text">
                      N° Guía
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-100">
                    <div className="th-text">
                      Fecha Trx
                      <div className="arrows">
                        <img
                          onClick={() => orderTable("fecha_autorizacion", "asc", false)}
                          src={Flecha_up}
                        ></img>
                        <img
                          onClick={() => orderTable("fecha_autorizacion", "desc", false)}
                          src={Flecha_down}
                        ></img>
                      </div> 
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-100">
                    <div className="th-text">
                      Código A.
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-100">
                    <div className="th-text">
                      Motivo
                      <div className="arrows">
                        <img
                          onClick={() => orderTable("motivo", "asc", false)}
                          src={Flecha_up}
                        ></img>
                        <img
                          onClick={() => orderTable("motivo", "desc", false)}
                          src={Flecha_down}
                        ></img>
                      </div> 
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-100">
                    <div className="th-text two-lines">
                      Forma
                      <br />
                      Comer.
                      <div className="arrows mtop">
                        <img
                          onClick={() => orderTable("producto", "asc", false)}
                          src={Flecha_up}
                        ></img>
                        <img
                          onClick={() => orderTable("producto", "desc", false)}
                          src={Flecha_down}
                        ></img>
                      </div> 
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-222">
                    <div className="th-text">
                      Cliente
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-100">
                    <div className="th-text">
                      Patente
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-136">
                    <div className="th-text">
                      N° de Tarjeta
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-100">
                    <div className="th-text">
                      Monto ($)
                      <div className="arrows">
                        <img
                          onClick={() => orderTable("monto", "asc", false)}
                          src={Flecha_up}
                        ></img>
                        <img
                          onClick={() => orderTable("monto", "desc", false)}
                          src={Flecha_down}
                        ></img>
                      </div> 
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-100">
                    <div className="th-text">
                      Estado
                      <div className="arrows">
                        <img
                          onClick={() => orderTable("estado", "asc", false)}
                          src={Flecha_up}
                        ></img>
                        <img
                          onClick={() => orderTable("estado", "desc", false)}
                          src={Flecha_down}
                        ></img>
                      </div> 
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-58">
                    <div className="th-text">
                      Edit
                    </div>
                  </div>
                </th>
              </tr>
              <tr className="table-separator-row-border-top">
                <td colSpan="100%" />
              </tr>
              <tbody>
                {
                  paginatedData &&
                    paginatedData.length > 0 
                      ?
                      paginatedData.map(data=>{
                        return(
                          <tr className="data row-with-rounded-borders">
                            {
                              data.monto === "Autorizada"
                              ?
                                <td className="align-left">
                                  <CustomHighlighter text={filterText}>
                                    {data.numero_guia}
                                  </CustomHighlighter>
                                </td>
                              :
                                <td className={`align-left ${!data.editable?"table-link":""}`} onClick={e=>!data.editable && openInfo(data.rut_cliente, data.codigo_cliente, data.numero_transaccion, data.numero_guia, data.numero_tarjeta, data.codigo_autorizacion, data.fecha_autorizacion, data.hora_autorizacion, data.estado, data.editable)}>
                                  <CustomHighlighter text={filterText}>
                                    {data.numero_guia}
                                  </CustomHighlighter>
                                </td>
                            }
                            <td className="align-left">
                              <CustomHighlighter text={filterText}>
                                {parseBudat(data.fecha_autorizacion)}
                              </CustomHighlighter>
                            </td>
                            <td className="align-left">
                              <CustomHighlighter text={filterText}>
                                {data.codigo_autorizacion}
                              </CustomHighlighter>
                            </td>
                            <td className="align-left">
                              <OverflowedText widthContainer={100}>
                                <CustomHighlighter text={filterText}>
                                  {data.motivo}
                                </CustomHighlighter>
                              </OverflowedText>
                            </td>
                            <td className="align-left">
                              <OverflowedText widthContainer={100}>
                                <CustomHighlighter text={filterText}>
                                  {data.producto}
                                </CustomHighlighter>
                              </OverflowedText>
                            </td>
                            <td className="align-left">
                              <OverflowedText widthContainer={222}>
                                <CustomHighlighter text={filterText}>
                                  {data.cliente}
                                </CustomHighlighter>
                              </OverflowedText>
                            </td>
                            <td className="align-left">
                              <CustomHighlighter text={filterText}>
                                {data.patente}
                              </CustomHighlighter>
                            </td>
                            <td className="align-left">
                              <CustomHighlighter text={filterText}>
                                {data.numero_tarjeta}
                              </CustomHighlighter>
                            </td>
                            <td className="align-left">
                              <CustomHighlighter text={filterText}>
                                {`$ ${getLocaleStringNumber(parseInt(data.monto))}`}
                              </CustomHighlighter>
                            </td>
                            <td className="pd-left-7">
                              <div className={`table-data-ellipsis estado-elipsis
                                ${data.estado}
                              `}>
                                <CustomHighlighter text={filterText}>
                                  {data.estado}
                                </CustomHighlighter>
                              </div>
                            </td>
                            <td className={`align-left ${data.editable?"table-link":""}`}>
                              {data.editable ? <img src={Editar} alt="editar" onClick={e=>openInfo(data.rut_cliente, data.codigo_cliente, data.numero_transaccion, data.numero_guia, data.numero_tarjeta, data.codigo_autorizacion, data.fecha_autorizacion, data.hora_autorizacion, data.estado, data.editable)} />: ""}
                            </td>
                          </tr>
                        )
                      })
                      :
                      <>
                        <tr className="data">
                          <td colSpan={100}>El cliente no tiene guias manuales para el filtro de búsqueda ingresado</td>
                        </tr>
                        <tr className="data">
                          <td colSpan={100}></td>
                        </tr>
                      </>
                }
                <tr className="table-separator-row-border-top">
                  <td colSpan="100%" />
                </tr>
              </tbody>
            </table>
            <div className="pagination-container">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={15}
                totalItemsCount={filteredData?.length}
                pageRangeDisplayed={5}
                onChange={e => handlePageChange(e)}
                hideFirstLastPages={true}
                linkClassPrev='prev'
                linkClassNext='next'
                prevPageText=''
                nextPageText=''
              />
            </div>
          </div>
      }
    </div>
  );
};

export default ConsultarGuiasManualesPorFecha;
