import React, { useState, useEffect, useRef } from "react";
import HeaderData from "../HeaderData";
import Select from "react-select";
import {
  SingleOption,
  customStylesPedidos,
  getLocaleStringNumber,
  getCantidadTotal,
} from "../utils/utils";
import { initializeSelectOptions, setInstalalaciones, getCantidades, setPagadoresArcoprime } from "../utils/get";
import { displayLoader, hideLoader } from "../utils/windows";
import { allMateriales } from "../utils/materiales";
import {
  monthNamesLower,
  getAllPeriodos,
  getUpdateDate,
} from "../utils/monthNames.js";
import HistorialPedidosTable from "./HistorialPedidosTable";
import Combustible from "../../assets/img/COMBUSTIBLE.svg";
import ResumenPeriodo from "./ResumenPeriodo";
import DetallePedido from "./DetallePedido";
import PrintComponent from "../PrintComponent";
import ExportExcel from "../ExportExcel";

const HistorialPedidos = ({ rutConcesionario, token, cabecera, instalaciones, isROImpersonate, edsPortal, callServiceHandler }) => {
  const [updateDate, setUpdateDate] = useState();
  const [allEds, setAllEds] = useState([]);
  const [allPeriodos, setAllPeriodos] = useState([]);
  const [selectedEds, setSelectedEds] = useState();
  const [selectedPeriodo, setSelectedPeriodo] = useState(null);
  const [selectedFecha, setSelectedFecha] = useState("-");
  const [selectedProducto, setSelectedProducto] = useState(null);
  const [selectedForma, setSelectedForma] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [allFormas, setAllFormas] = useState([]);
  const [filterText, setFilterText] = useState("");
  const tableRef = useRef(null);
  const [displayResumenPeriodo, setDisplayResumenPeriodo] = useState(false);
  const [displayDetallePedido, setDisplayDetallePedido] = useState(false);
  const [pedidosAux, setPedidosAux] = useState([]);
  const [pedidos, setPedidos] = useState([]);
  const [filteredPedidos, setFilteredPedidos] = useState([]);
  const [pedidosPeriodo, setPedidosPeriodo] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedPedido, setSelectedPedido] = useState({});

  //Filtros
  const [allEstados, setAllEstados] = useState([]);
  const [estadoFilter, setEstadoFilter] = useState([]);
  const [allPlantas, setAllPlantas] = useState([]);
  const [plantaFilter, setPlantaFilter] = useState([]);
  const [allTipos, setAllTipos] = useState([]);
  const [tipoFilter, setTipoFilter] = useState([]);
  const [allHoras, setAllHoras] = useState([]);
  const [horaFilter, setHoraFilter] = useState([]);
  const [entregaStartDate, setEntregaStartDate] = useState(new Date());
  const [entregaEndDate, setEntregaEndDate] = useState(null);
  const [numeroPedido, setNumeroPedido] = useState("");
  const [selectedNumeroPedido, setSelectedNumeroPedido] = useState("Todos");
  const [volumen, setVolumen] = useState("");
  const [selectedVolumen, setSelectedVolumen] = useState("Todos");
  const [clientName, setClientName] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [zone, setZone] = useState("");
  const [pagador, setPagador] = useState();
  const [pedidosLoaded, setPedidosLoaded] = useState(false);
  const [isDinamo, setIsDinamo] = useState(false);

  const [volumenPlanificado, setVolumenPlanificado] = useState("");
  const [selectedVolumenPlanificado, setSelectedVolumenPlanificado] = useState("Todos");
  const [volumenEntregado, setVolumenEntregado] = useState("");
  const [selectedVolumenEntregado, setSelectedVolumenEntregado] = useState("Todos");

  //Arcoprime
  const [isArcoprime, setIsArcoprime] = useState(false);
  const [allPagadores, setAllPagadores] = useState([]);
  const [selectedPagador, setSelectedPagador] = useState();

  //Resumen Periodo
  const [totalPeriodo, setTotalPeriodo] = useState(0);

  const labels = [
    ["Nº de pedido", "ID_PEDIDO"],
    ["Planta", "PLANTA"],
    ["Tipo de Pedido", "TIPO_PEDIDO"],
    ["Fecha Preferente Entrega", "FECHA_PREFERENTE_ENTREGA"],
    ["Ventana Solicitada", "HORARIO"],
    ["Estado", "ESTADO"],
    ["Volumen solicitado (L)", "VOLUMEN"],
  ];

  const labelsDinamo = [
    ["Nº de pedido", "ID_PEDIDO"],
    ["Planta", "PLANTA"],
    ["Tipo de Pedido", "TIPO_PEDIDO"],
    ["Fecha Preferente Entrega", "FECHA_PREFERENTE_ENTREGA"],
    ["Ventana Solicitada", "HORARIO"],
    ["Estado", "ESTADO"],
    ["Volumen solicitado (L)", "VOLUMEN"],
    ["Volumen planificado (L)", "VOLUMEN_PLANIFICADO"],
    ["Volumen entregado (L)", "VOLUMEN_ENTREGADO"],
  ];

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (token && rutConcesionario) {
        const periodos = getAllPeriodos();
        setAllPeriodos(periodos);
        setSelectedPeriodo(periodos[0]);
        const selectedDate = periodos[0].value.split("%3B");
        const month = selectedDate[0];
        const year = selectedDate[1];
        setSelectedFecha(monthNamesLower[parseInt(month) - 1] + " " + year);
        if (rutConcesionario === "77215640-5") {
          setIsArcoprime(true);
        }
      }
    } else {
      if (token) {
        const periodos = getAllPeriodos();
        setAllPeriodos(periodos);
        setSelectedPeriodo(periodos[0]);
        const selectedDate = periodos[0].value.split("%3B");
        const month = selectedDate[0];
        const year = selectedDate[1];
        setSelectedFecha(monthNamesLower[parseInt(month) - 1] + " " + year);
        if (rutConcesionario === "77215640-5" /* || true */) {
          setIsArcoprime(true);
        }
      }
    }
  }, [token, rutConcesionario]);

  useEffect(() => {
    if(cabecera!==""){
      setClientName(cabecera.DATOS_CLIENTE.NOMBRE);
      setClientAddress(cabecera.DATOS_CLIENTE.DIRECCION);
      setPagador(cabecera.DATOS_CLIENTE.PAGADOR);
      setZone(cabecera.DATOS_EDS[0].ZONA);
      if(cabecera.DATOS_CLIENTE.DINAMO==="X"){
        setIsDinamo(true);
      }
    }
  }, [cabecera]);

  useEffect(() => {
    if (instalaciones!==""){
      if(!isArcoprime){
        setInstalalaciones(instalaciones,setAllEds);
      }
    }
  }, [instalaciones]);

  useEffect(async () => {
    if(selectedPagador){
      const url = process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/obtener-instalaciones-cliente?id_pagador=" + selectedPagador.value;

      let result = await callServiceHandler(url, "GET")
      if(result !== "error"){
        setInstalalaciones(result.data, setAllEds);
      }
    }
  }, [selectedPagador]);

  useEffect(() => {
    if (allPagadores.length === 1) {
      changePagador(allPagadores[0]);
    }
  }, [allPagadores]);

  useEffect(() => {
    if (allEds.length === 1) {
      changeEds(allEds[0]);
    }
  }, [allEds]);

  useEffect(async () => {
    if (selectedEds && pagador) {
      displayLoader();
      let result = await getPedidos(selectedEds);
      cleanHistorialData(result.data.PEDIDOS.filter((pedido)=>pedido.TPC===""));
      setPedidosAux(result.data.PEDIDOS.filter((pedido)=>pedido.TPC===""));

      const dinamo = (pedido) =>
      pedido.DINAMO === "X";

      if(result.data.PEDIDOS.some(dinamo)){
        setIsDinamo(true)
      }else{
        setIsDinamo(false)
      }

      hideLoader();
    }
  }, [currentPage, selectedEds, selectedPeriodo,pagador,isDinamo]);

  useEffect(() => {
    if (selectedPeriodo) {
      const periodoArr = selectedPeriodo.value.split("%3B");
      const newD = new Date(periodoArr[1], periodoArr[0] - 1, 1);
      setEntregaStartDate(newD);
    }
  }, [selectedPeriodo]);

 /*  useEffect(() => {
    if (selectedPeriodo) {
      setTotalPeriodo(getTotalPeriodo());
    }
  }, [filteredPedidos, selectedProducto, selectedForma,isDinamo,selectedPeriodo]); */

  async function changeEds(e) {
    if (e !== selectedEds) {
      setSelectedEds(e);

      //Resetear búsqueda de pedidos
      setPedidosAux([]);
      setCurrentPage(0);
      displayLoader();

      //Resetear filtros
      resetFilters();
      setTotalPeriodo(0);
    }
  }

  async function getPedidos(e) {
    const selectedDate = selectedPeriodo.value.split("%3B");
    const month = selectedDate[0];
    const year = selectedDate[1];
    const periodo = month + year;

    const pagadorFinal=isArcoprime?selectedPagador.value:pagador;

    const url =
      process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/buscar-pedidos?id_eds=" +
      e.value +
      "&periodo=" +
      periodo +
      "&nuevo=1"+
      "&id_pagador=" +
      pagadorFinal;

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      setPedidosLoaded(true);
      getUpdateDate(setUpdateDate);
      return result;
    }
  }

  function cleanHistorialData(pedidosdata) {
    const newEstadosFilter = initializeSelectOptions();
    const newPlantasFilter = initializeSelectOptions();
    const newTiposFilter = initializeSelectOptions();
    const newHorasFilter = initializeSelectOptions();
    const newPedidos = [];

    const newFormasFilter = [];
    const newProductosFilter = [];
    newFormasFilter.push({ label: "Todos", value: null });
    newProductosFilter.push({ label: "Todos", value: null });

    pedidosdata.forEach((pedido) => {

      const arr93 = [];
      const arr95 = [];
      const arr97 = [];
      const arrPd = [];
      const arrK = [];
      let existenReposiciones = false;

      if (pedido.DETALLE) {
        const detalle = pedido.DETALLE;

        detalle.forEach((item) => {
          if (allMateriales[item.ID_MATERIAL].class === "sp93") {
            arr93.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "sp95") {
            arr95.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "sp97") {
            arr97.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "pd") {
            arrPd.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "k") {
            arrK.push(item);
          }
          if (item.CATEGORIA_ITEM != "ZCRM" && existenReposiciones === false) {
            existenReposiciones = true;
          }
        });
      }

      const volumenInfo = {
        93: { litros: arr93.length > 0 ? getLitrosMaterial(arr93, "ZCRM") / 1000 : 0 },
        95: { litros: arr95.length > 0 ? getLitrosMaterial(arr95, "ZCRM") / 1000 : 0 },
        97: { litros: arr97.length > 0 ? getLitrosMaterial(arr97, "ZCRM") / 1000 : 0 },
        Pd: { litros: arrPd.length > 0 ? getLitrosMaterial(arrPd, "ZCRM") / 1000 : 0 },
        K: { litros: arrK.length > 0 ? getLitrosMaterial(arrK, "ZCRM") / 1000 : 0 },
      };

      if (
        pedido.DESCRIPCION_STATUS !== "" &&
        pedido.DESCRIPCION_STATUS !== "TOTAL" &&
        newEstadosFilter.filter((e) => e.value === pedido.DESCRIPCION_STATUS)
          .length == 0
      ) {
        const newEstado = {
          label: pedido.DESCRIPCION_STATUS,
          value: pedido.DESCRIPCION_STATUS,
        };
        newEstadosFilter.push(newEstado);
      }

      const plantaPedido = pedido.ID_PLANTA + " - " + pedido.NOMBRE_PLANTA;

      if (
        plantaPedido !== "" &&
        plantaPedido !== "TOTAL" &&
        newPlantasFilter.filter((e) => e.value === plantaPedido).length == 0
      ) {
        const newPlanta = {
          label: plantaPedido,
          value: plantaPedido,
        };
        newPlantasFilter.push(newPlanta);
      }

      if (
        pedido.TIPO_PEDIDO !== "" &&
        pedido.TIPO_PEDIDO !== "TOTAL" &&
        newTiposFilter.filter((e) => e.value === pedido.TIPO_PEDIDO).length == 0
      ) {
        const newTipo = {
          label: pedido.TIPO_PEDIDO,
          value: pedido.TIPO_PEDIDO,
        };
        newTiposFilter.push(newTipo);
      }

      if (
        pedido.HORARIO !== "TOTAL" &&
        newHorasFilter.filter((e) => e.value === pedido.HORARIO).length == 0
      ) {
        const newHora = {
          label: pedido.HORARIO,
          value: pedido.HORARIO,
        };
        newHorasFilter.push(newHora);
      }

      const newPedido = {
        ID_PEDIDO: pedido.ID_PEDIDO,
        PLANTA: pedido.ID_PLANTA + " - " + pedido.NOMBRE_PLANTA,
        TIPO_PEDIDO: pedido.TIPO_PEDIDO,
        FECHA_PREFERENTE_ENTREGA: pedido.FECHA_PREFERENTE_ENTREGA,
        HORARIO: pedido.HORARIO,
        ESTADO: pedido.DESCRIPCION_STATUS,
        VOLUMEN: isDinamo?getCantidades(pedido,"SOLICITADA"):getCantidadTotal(pedido),
        DETALLE: pedido.DETALLE,
        HORA_REAL_CLIENTE: pedido.HORA_REAL_CLIENTE,
        FECHA_REAL_CLIENTE: pedido.FECHA_REAL_CLIENTE,
        VOLUMEN_SOLICITADO: getCantidades(pedido,"SOLICITADA"),
        VOLUMEN_PLANIFICADO: getCantidades(pedido,"CONFIRMADA"),
        VOLUMEN_ENTREGADO: getCantidades(pedido,"ENTREGADA"),
        PEDIDO_LOGISTIC: pedido.PEDIDO_LOGISTIC
      };
      newPedidos.push(newPedido);
    });
    setPedidos(newPedidos);
    setPedidosPeriodo(newPedidos);
    setAllEstados(newEstadosFilter);
    setAllPlantas(newPlantasFilter);
    setAllTipos(newTiposFilter);
    setAllHoras(newHorasFilter);

    //FILTROS DE PRODUCTO Y FORMA DE COMERCIALIZACION
    const allDetalles = [];

    pedidosdata.forEach((pedido) => {
      if (pedido.DETALLE) {
        pedido.DETALLE.forEach((p) => {
          allDetalles.push(p);
        });
      }
    });

    allDetalles.forEach((pedido) => {
      if (
        pedido.ITEM_CATEG !== "ZCRM" &&
        newFormasFilter.filter((e) => e.value === pedido.ITEM_CATEG).length == 0
      ) {
        let label = "";
        if (pedido.ITEM_CATEG === "ZTAE") {
          label = "Reposición TAE";
        }
        if (pedido.ITEM_CATEG === "ZTCT") {
          label = "Reposición TCT";
        }
        if (pedido.ITEM_CATEG === "ZCUP") {
          label = "Reposición Cupón Electrónico";
        }
        if (pedido.ITEM_CATEG === "ZFFA") {
          label = "Reposición FF.AA.";
        }
        if (pedido.ITEM_CATEG === "ZCEM") {
          label = "Reposición Muevo Empresa";
        }
        if (pedido.ITEM_CATEG === "ZPAS") {
          label = "Almacenamiento Storage";
        }
        if (pedido.ITEM_CATEG === "ZPCS") {
          label = "Storage";
        }
        if (pedido.ITEM_CATEG === "ZFES" || pedido.ITEM_CATEG==="ZC08") {
          label = "Concesión";
        }
        if (pedido.ITEM_CATEG === "ZRCO" || pedido.ITEM_CATEG==="ZC07") {
          label = "Consignación";
        }
        if (pedido.ITEM_CATEG === "ZCSC") {
          label = "Constitución";
        }
        const newForma = {
          label: label,
          value: pedido.ITEM_CATEG,
        };
        newFormasFilter.push(newForma);
      }
    });

    const arr93 = [];
    const arr95 = [];
    const arr97 = [];
    const arrPd = [];
    const arrK = [];

    allDetalles.forEach((item) => {
      if (allMateriales[item.ID_MATERIAL].class === "sp93") {
        arr93.push(item);
      }
      if (allMateriales[item.ID_MATERIAL].class === "sp95") {
        arr95.push(item);
      }
      if (allMateriales[item.ID_MATERIAL].class === "sp97") {
        arr97.push(item);
      }
      if (allMateriales[item.ID_MATERIAL].class === "pd") {
        arrPd.push(item);
      }
      if (allMateriales[item.ID_MATERIAL].class === "k") {
        arrK.push(item);
      }
    });

    if (arr93.length > 0) {
      newProductosFilter.push({
        label: "Gasolina 93 Octanos NU 1202",
        value: "sp93",
      });
    }
    if (arr95.length > 0) {
      newProductosFilter.push({
        label: "Gasolina 95 Octanos NU 2102",
        value: "sp95",
      });
    }
    if (arr97.length > 0) {
      newProductosFilter.push({
        label: "Gasolina 97 Octanos Premium NU 1202",
        value: "sp97",
      });
    }
    if (arrPd.length > 0) {
      newProductosFilter.push({
        label: "Petroleo Diesel Ultra A1 NU 1203",
        value: "pd",
      });
    }
    if (arrK.length > 0) {
      newProductosFilter.push({ label: "Kerosene NU 1223", value: "k" });
    }

    setAllFormas(newFormasFilter);
    setAllProducts(newProductosFilter);
    setSelectedProducto(newProductosFilter[0]);
    setSelectedForma(newFormasFilter[0]);
  }

  const changePeriodo = (e) => {
    if(e!==selectedPeriodo){
      setSelectedPeriodo(e);

      //Resetear búsqueda de pedidos
      setPedidosAux([]);
      setCurrentPage(0);
      setTotalPeriodo(0);
  
      //Resetear filtros
      resetFilters();
      const selectedDate = e.value.split("%3B");
      const month = selectedDate[0];
      const year = selectedDate[1];
      setSelectedFecha(monthNamesLower[parseInt(month) - 1] + " " + year);
    }

  };

  const resetFilters = () => {
    setNumeroPedido("");
    setSelectedNumeroPedido("Todos");
    setAllPlantas([]);
    setPlantaFilter([]);
    setAllTipos([]);
    setTipoFilter([]);
    setEntregaStartDate(new Date());
    setEntregaEndDate(null);
    setAllHoras([]);
    setHoraFilter([]);
    setAllEstados([]);
    setEstadoFilter([]);
    setVolumen("");
    setSelectedVolumen("Todos");
    setSelectedProducto(null);
    setSelectedForma(null);
    setVolumenPlanificado("");
    setSelectedVolumenPlanificado("Todos");
    setVolumenEntregado("");
    setSelectedVolumenEntregado("Todos");
  };

  useEffect(() => {
    if(edsPortal){
      setPagadoresArcoprime(edsPortal.data.user_eds,setAllPagadores);
    }
  }, [edsPortal]);

  function changePagador(e) {
    setSelectedPagador(e);
  }

  const getTotalPeriodo = () => {
    let total = 0;
    
    filteredPedidos.forEach((pedido) => {
      if (pedido.DETALLE && getLitros(pedido).litros > 0) {
        if(isDinamo){
          total += getLitros(pedido).entregado;
        }else{
          total += getLitros(pedido).litros;
        }
        
      }
    });
    return total;
  };

  const showResumenPeriodo = () => {
    if (selectedPeriodo && pedidosPeriodo.length > 0) {
      setDisplayResumenPeriodo(true);
    }
  };

  const getLitrosMaterial = (array, forma) => {
    let litros = 0;
    array.forEach((pedido) => {
      if (pedido.CATEGORIA_ITEM === forma) {
        litros += pedido.CANTIDAD_SOLICITADA;
      }
    });
    return litros;
  };

  const getLitros = (pedido) => {
    let litros = 0;
    let filtrado = false;

    if(isDinamo){

      let planificado=0;
      let entregado=0;

      if(selectedProducto && selectedProducto.value!==null){
        filtrado=true;

        pedido.DETALLE?.map((material) => {
          if( allMateriales[material.ID_MATERIAL].class === selectedProducto.value){
            litros += material.CANTIDAD_SOLICITADA;
            planificado+= material.CANTIDAD_CONFIRMADA_LOGISTICA;
            entregado+= material.CANTIDAD_ENTREGADA;
          }
        });
      }else{
        litros=pedido.VOLUMEN;

        pedido.DETALLE?.map((material)=>{
          entregado+=material.CANTIDAD_ENTREGADA;
        })
      }

      return { litros: litros, planificado:planificado,entregado:entregado, isFiltrado: filtrado };

    }else{

      if (selectedForma && !selectedProducto) {
        if (selectedForma.value != null) {
          filtrado = true;
          if (pedido.DETALLE) {
            pedido.DETALLE.forEach((p) => {
              if (p.ITEM_CATEG === selectedForma.value) {
                litros += p.CANTIDAD_SOLICITADA;
              }
            });
          }
        }
      } else if (!selectedForma && selectedProducto) {
        if (selectedProducto.value != null) {
          filtrado = true;
          if (pedido.DETALLE) {
            pedido.DETALLE.forEach((p) => {
              if (
                allMateriales[p.ID_MATERIAL].class === selectedProducto.value &&
                p.ITEM_CATEG != "ZCRM"
              ) {
                litros += p.CANTIDAD_SOLICITADA;
              }
            });
          }
        }
      } else if (selectedForma && selectedProducto) {
        filtrado = true;
        if (selectedProducto.value != null && selectedForma.value != null) {
          if (pedido.DETALLE) {
            pedido.DETALLE.forEach((p) => {
              if (
                allMateriales[p.ID_MATERIAL].class === selectedProducto.value &&
                p.ITEM_CATEG === selectedForma.value
              ) {
                litros += p.CANTIDAD_SOLICITADA;
              }
            });
          }
        }
        if (selectedProducto.value != null && selectedForma.value === null) {
          if (pedido.DETALLE) {
            pedido.DETALLE.forEach((p) => {
              if (
                allMateriales[p.ID_MATERIAL].class === selectedProducto.value &&
                p.ITEM_CATEG != "ZCRM"
              ) {
                litros += p.CANTIDAD_SOLICITADA;
              }
            });
          }
        }
        if (selectedProducto.value === null && selectedForma.value != null) {
          if (pedido.DETALLE) {
            pedido.DETALLE.forEach((p) => {
              if (p.ITEM_CATEG === selectedForma.value) {
                litros += p.CANTIDAD_SOLICITADA;
              }
            });
          }
        }
        if (selectedProducto.value === null && selectedForma.value === null) {
          if (pedido.DETALLE) {
            litros = pedido.VOLUMEN;
          }
        }
      } else if (!selectedForma && !selectedProducto) {
        if (pedido.DETALLE) {
          litros = pedido.VOLUMEN;
        }
      }
      return { litros: litros, isFiltrado: filtrado };

    }

    
  };

  const goBack = () => {
    setDisplayResumenPeriodo(false);
    setDisplayDetallePedido(false);
  };

  function reloadHistorial(){
    setCurrentPage((old)=>old+1);
  }

  return (
    <div className="historial-pedidos">
      <div className="title-container">
        <div className="title">
          <div className="view-title">Pedidos Combustibles</div>
          <div className="view-title-separator">|</div>
          <div className="view-subtitle">Historial de Pedidos</div>
        </div>
        {displayResumenPeriodo || displayDetallePedido ? (
          <>
            <div className="volver-arrow" onClick={() => goBack()}>
              <img></img>
              <p className="volver-text">Volver</p>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <hr className="title-separator-line" />

      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        cabecera={cabecera}
        actualizable={true}
        updateFunction={reloadHistorial}
        callServiceHandler={callServiceHandler}
      />

      {displayResumenPeriodo && (
        <>
          <hr />
          <ResumenPeriodo
            rutConcesionario={rutConcesionario}
            token={token}
            pedidosPeriodo={pedidosPeriodo}
            allEds={allEds}
            selectedEds={selectedEds}
            changeEds={changeEds}
            allPeriodos={allPeriodos}
            selectedPeriodo={selectedPeriodo}
            changePeriodo={changePeriodo}
            setDisplayResumenPeriodo={setDisplayResumenPeriodo}
            isDinamo={isDinamo}
            isROImpersonate={isROImpersonate}
          />
        </>
      )}

      {displayDetallePedido && (
        <>
          <hr />
          <DetallePedido
            selectedPedido={selectedPedido}
            setDisplayDetallePedido={setDisplayDetallePedido}
            selectedEds={selectedEds}
            isDinamo={isDinamo}
            isROImpersonate={isROImpersonate}
          />
        </>
      )}

      {!displayResumenPeriodo && !displayDetallePedido && (
        <>
          <div className="pedidos-resumen">
            <div className="total">
              <img src={Combustible}></img>
              <div className="selected">
                <div className="totaltext">Total seleccionado</div>
                <div className="month">{selectedFecha}</div>
              </div>
            </div>
            <div className="separator"></div>
            <div className="liters">
              {getLocaleStringNumber(getTotalPeriodo(),true)} Lts
            </div>
            <div className="separator"></div>
            <button
              onClick={() => showResumenPeriodo()}
              className={`resumen-button ${
                pedidosPeriodo.length > 0 ? "" : "hallow"
              }`}
            >
              Resumen Período
            </button>
          </div>
          <hr />

          {isArcoprime && 
            <div className="historialp-options">
              <div className="filters-container">
                <div className="filter">
                  <div className="filter-title">ID DE PAGADOR<span className="red-asterisk">*</span></div>
                  <div className="filter-input">
                    <Select
                      className="eds-select"
                      value={selectedPagador}
                      onChange={(e) => changePagador(e)}
                      closeMenuOnSelect={true}
                      hideSelectedOptions={false}
                      options={allPagadores}
                      components={{
                        SingleOption,
                        IndicatorSeparator: () => null,
                      }}
                      isClearable={false}
                      autosize={false}
                      placeholder="Seleccionar"
                      styles={customStylesPedidos}
                    />
                  </div>
                </div>
              </div>
            </div>}

          <div className={`historialp-options ${isArcoprime && 'no-mtop'}`}>
            <div className="filters-container">
              <div className="filter">
                <div className="filter-title">
                  ESTACIÓN DE SERVICIO<span className="red-asterisk">*</span>
                </div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedEds}
                    onChange={(e) => changeEds(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allEds}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesPedidos}
                  />
                </div>
              </div>
              <div className="filter filter-with-margin-left">
                <div className="filter-title">PERÍODO</div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedPeriodo}
                    onChange={(e) => changePeriodo(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allPeriodos}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    styles={customStylesPedidos}
                  />
                </div>
              </div>
              <div className="filter filter-with-margin-left">
                <div className="filter-title">PRODUCTO</div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedProducto}
                    onChange={(e) => setSelectedProducto(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allProducts}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    styles={customStylesPedidos}
                  />
                </div>
              </div>
              {!isDinamo && 
              <div className="filter filter-with-margin-left">
                <div className="filter-title">FORMAS COMERCIALIZACIÓN</div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedForma}
                    onChange={(e) => setSelectedForma(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allFormas}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    styles={customStylesPedidos}
                  />
                </div>
              </div>
              }
            </div>

            <div className="actions">
              <div className="filter">
                <div className="filter-input">
                  <input
                    type="search"
                    className="search"
                    value={filterText}
                    placeholder="Buscar..."
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                </div>
              </div>
              <div className="buttons">
                <PrintComponent 
                  reff={tableRef}
                  classes=""
                  isROImpersonate={isROImpersonate}
                />
                <ExportExcel
                  data={filteredPedidos}
                  labels={isDinamo?labelsDinamo:labels}
                  isROImpersonate={isROImpersonate}
                />
              </div>
            </div>
          </div>

          <HistorialPedidosTable
            allEds={allEds}
            tableRef={tableRef}
            pedidos={pedidos}
            setPedidos={setPedidos}
            filteredPedidos={filteredPedidos}
            setFilteredPedidos={setFilteredPedidos}
            allEstados={allEstados}
            estadoFilter={estadoFilter}
            setEstadoFilter={setEstadoFilter}
            allPlantas={allPlantas}
            plantaFilter={plantaFilter}
            setPlantaFilter={setPlantaFilter}
            allTipos={allTipos}
            tipoFilter={tipoFilter}
            setTipoFilter={setTipoFilter}
            allHoras={allHoras}
            horaFilter={horaFilter}
            setHoraFilter={setHoraFilter}
            filterText={filterText}
            selectedPeriodo={selectedPeriodo}
            entregaStartDate={entregaStartDate}
            setEntregaStartDate={setEntregaStartDate}
            entregaEndDate={entregaEndDate}
            setEntregaEndDate={setEntregaEndDate}
            numeroPedido={numeroPedido}
            setNumeroPedido={setNumeroPedido}
            selectedNumeroPedido={selectedNumeroPedido}
            setSelectedNumeroPedido={setSelectedNumeroPedido}
            volumen={volumen}
            setVolumen={setVolumen}
            selectedVolumen={selectedVolumen}
            setSelectedVolumen={setSelectedVolumen}
            setSelectedPedido={setSelectedPedido}
            setDisplayDetallePedido={setDisplayDetallePedido}
            getLitros={getLitros}
            selectedEds={selectedEds}
            pedidosLoaded={pedidosLoaded}
            isDinamo={isDinamo}
            volumenPlanificado={volumenPlanificado}
            setVolumenPlanificado={setVolumenPlanificado}
            selectedVolumenPlanificado={selectedVolumenPlanificado}
            setSelectedVolumenPlanificado={setSelectedVolumenPlanificado}
            volumenEntregado={volumenEntregado}
            setVolumenEntregado={setVolumenEntregado}
            selectedVolumenEntregado={selectedVolumenEntregado}
            setSelectedVolumenEntregado={setSelectedVolumenEntregado}
          />
        </>
      )}
    </div>
  );
};

export default HistorialPedidos;
