import React, { useEffect, useState } from "react";
import onClickOutside from "react-onclickoutside";
import { getLocaleStringNumber } from "../utils/utils";

const VolumenTooltip = (props) => {
  const pedidoTooltip = props.pedidoTooltip;
  const detalle = props.detalle;

  VolumenTooltip["handleClickOutside_" + props.name] = () => {
    clickOutside();
  };

  const clickOutside = () => {
    props.setDocumentoKey("");
  };

  return (
    <div className={`documento-info${props.hidden ? " hidden" : ""}`}>
      <div className="title">DETALLE VOLUMEN</div>
      <div className="documento-info-modal">
        <div className="content">
          <table>
            <thead>
              <tr>
                <th>Producto</th>
                <th>Litros</th>
              </tr>
            </thead>
            <tr className="separator-table-row">
                    <th colSpan="100%" />
                  </tr>
            <tbody>
              {detalle.map((obj, key) => {
                return (
                  <>
                    {" "}
                    {obj.liters > 0 ? (
                      <>
                        <tr className="data" key={key}>
                          <td className="align-left">{obj.product}</td>
                          <td className="align-right">
                            {getLocaleStringNumber(obj.liters)}
                          </td>
                        </tr>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
              <tr className="data">
              <td className="align-left">
                <b>Total</b>
              </td>
              <td className="align-right">
                <b>
                  {pedidoTooltip
                    ? getLocaleStringNumber(
                        parseInt(pedidoTooltip.CANTIDAD_TOTAL)
                      )
                    : ""}
                </b>
              </td>
            </tr>
            </tbody>
            
          </table>
        </div>
      </div>
      <div className="footer">
        {/* <span className="important">*</span>
        <span>Observaciones: aquí pueden ir las observaciones</span> */}
      </div>
    </div>
  );
};

VolumenTooltip.prototype = {};

const clickOutsideConfig = {
  handleClickOutside: ({ props }) =>
    VolumenTooltip["handleClickOutside_" + props.name],
};

export default onClickOutside(VolumenTooltip, clickOutsideConfig);
