export const allMateriales = {
  "000000000000000001": {
    class: "sp93",
    title: "93 SP",
    titleCierre: "GAS 93",
    miniTitle: "93",
    subtitle: "NU 1203",
  },
  "000000000000000002": {
    class: "sp93",
    title: "93 SP",
    titleCierre: "GAS 93",
    miniTitle: "93",
    subtitle: "NU 1203",
  },
  "000000000000000003": {
    class: "sp95",
    title: "95 SP",
    titleCierre: "GAS 95",
    miniTitle: "95",
    subtitle: "NU 1203",
  },
  "000000000000000004": {
    class: "sp95",
    title: "95 SP",
    titleCierre: "GAS 93",
    miniTitle: "95",
    subtitle: "NU 1203",
  },
  "000000000000000005": {
    class: "sp97",
    title: "97 SP",
    titleCierre: "GAS 97",
    miniTitle: "97",
    subtitle: "NU 1203",
  },
  "000000000000000006": {
    class: "sp97",
    title: "97 SP",
    titleCierre: "GAS 97",
    miniTitle: "97",
    subtitle: "NU 1203",
  },
  "000000000000000009": {
    class: "k",
    title: "K",
    titleCierre: "KEROSENE",
    miniTitle: "K",
    subtitle: "NU 1223",
  },
  "000000000000000013": {
    class: "pd",
    title: "PD",
    titleCierre: "DIESEL",
    miniTitle: "PD",
    subtitle: "NU 1202",
  },
  "000000000000000016": {
    class: "pd",
    title: "PD",
    titleCierre: "DIESEL",
    miniTitle: "PD",
    subtitle: "NU 1202",
  },
  "000000000000000793": {
    class: "glp",
    title: "GLP",
    titleCierre: "GLP",
    miniTitle: "GLP",
    subtitle: "GLP",
  },
  "000000000000000523": {
    class: "gnc",
    title: "GNC",
    titleCierre: "GNC",
    miniTitle: "GNC",
    subtitle: "GNC",
  },
  "000000000000000075": {
    class: "gnc",
    title: "GNC",
    titleCierre: "GNC",
    miniTitle: "GNC",
    subtitle: "GNC",
  },
  error: {
    class: "-",
    title: "Error",
    titleCierre: "Error",
    miniTitle: "",
    subtitle: "-",
  },
  empty: {
    class: "empty",
    title: "Vacío",
    titleCierre: "Vacío",
    miniTitle: "",
    subtitle: "-",
  },
};

export function getMaterialClass(id){
  return allMateriales[id].class
}

export function getMaterialClassFromTitleCierre(title){
  const material = Object.keys(allMateriales).find(t => allMateriales[t].titleCierre == title.toUpperCase())
  if(material)
    return allMateriales[material].class
  return ''
}

export function getMaterialMinititle(id){
  return allMateriales[id].miniTitle
}

export function getMaterialMinititleFromTitleCierre(title){
  const material = Object.keys(allMateriales).find(t => allMateriales[t].titleCierre == title.toUpperCase())
  if(material)
    return allMateriales[material].miniTitle
  return ''
}

export function getMaterialTitleCierre(id){
  return allMateriales[id]?.titleCierre
  console.log("No se encontro el material ", id)
  return 'NOT FOUND'
}
