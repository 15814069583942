import React, { useState, useEffect } from "react"
import Select from "react-select"

import removeIcon from "../../assets/img/remove.svg"

import {validateEmail} from "../utils/utils"

const types = {
	"control_diario": "Cierre Diario", 
	"desarrollo": "Desarrollo", 
	"grupo_arcoprime": "Grupo Arcoprime",
	"interno": "Interno",
	"negocio": "Negocio",
	"prueba": "Prueba",
	"telesoporte": "Telesoporte"
}

const CorreosPorZona = ({codigosZonas, correos, setNewData}) => {
	const [newCorreos, setNewCorreos] = useState({})
	const [editedData, setEditedData] = useState()

	useEffect(() => {
		setEditedData(JSON.parse(JSON.stringify(correos)))
	}, [correos])

	function changeCorreo(key, e){
		const newCorreosTmp = {...newCorreos}
		newCorreosTmp[key] = e.target.value
		setNewCorreos(newCorreosTmp)
	}

	function addCorreo(key){
		const correosTmp = {...editedData}
		correosTmp[codigosZonas[key]].push(newCorreos[key])
		setEditedData(correosTmp)

		const newCorreosTmp = {...newCorreos}
		newCorreosTmp[key] = ""
		setNewCorreos(newCorreosTmp)
	}

	function removeCorreo(key, correo){
		const correosTmp = {...editedData}
		const index = correosTmp[codigosZonas[key]].indexOf(correo)
		if (index > -1) {
		  correosTmp[codigosZonas[key]].splice(index, 1)
		}
		setEditedData(correosTmp)
	}

	function save(){
		setNewData({correos_por_zona: editedData})
	}

  return (
    <div className="selected-filter-container">
	    { editedData &&
	    	Object.keys(codigosZonas).map((key) =>
	    		<>
	    			<div className="section-title section-title--left">{key}</div>
	    			{
	    				editedData[codigosZonas[key]].map((correo) =>
	    				<div className="info-text">
	    					{correo}
	    					<img className="remove-item" src={removeIcon} onClick={() => removeCorreo(key, correo)} />
	    				</div>
	    			)}
	    			<input placeholder="Ej: email@copec.cl" type="text" className="correo-input" value={newCorreos[key]} onChange={(e) => changeCorreo(key, e)} />
	    			<button className="nuevo-correo-button" disabled={!validateEmail(newCorreos[key])} onClick={() => addCorreo(key)}>Agregar</button>
	    			<hr />
	    		</>	    		
	    )}
	    <button className="personal-estacio-modal-button" onClick={save}>
      	Guardar
      </button>
    </div>
  )
}

export default CorreosPorZona
