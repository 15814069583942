import React, { useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import { Alert }  from 'pharedata-custom-components';
import {
  showGreyBackground,
  hideGreyBackground,
  showModalGreyBackground,
  hideModalGreyBackground,
  scrollTo,
  getPageYOffset
} from "./utils/windows";

const CustomModal = ({
  displayModal,
  closeModal,
  modalTitle,
  modalText,
  modalIcon,
  cancelFunc = null,
  acceptFunc,
  acceptText = "Aceptar"
}) => {

  const [pageYOffset, setPageYOffset] = useState(0)
  const isDesktopRef = useRef(window.innerWidth > process.env.REACT_APP_MOBILE_BREAK_POINT);

  useEffect(() => {
    if(displayModal){
      getPageYOffset()
    }
  }, [displayModal]);

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
  }, []);

  function receiveMessage(event) {
    const message = event.data;
   
    if(message.pageYOffset || message.pageYOffset === 0){
      setPageYOffset(message.pageYOffset)
    }
  }

  return (
    <Alert
      displayModal={displayModal}
      closeModal={closeModal}
      modalTitle={modalTitle}
      modalText={modalText}
      modalIcon={modalIcon}
      acceptFunc={acceptFunc}
      acceptText={acceptText}
      cancelFunc={cancelFunc}
      showGreyBackground={showModalGreyBackground}
      hideGreyBackground={hideModalGreyBackground}
      scrollTo={scrollTo}
      pageYOffset={pageYOffset}
      isDesktopRef={isDesktopRef.current}
    />
  );
};

export default CustomModal;
