import React, { useState, useEffect,useRef } from "react";
import Select from "react-select";
import { CopecStepper } from "../CopecStepper";
import HeaderData from "../HeaderData";
import { getConfiguracion, getEdsPedidosActivos, getTanquesOfPedidoEspecial, setInstalacionesDinamo, setPagadoresArcoprime } from "../utils/get";
import { getPeriodosSince, getPeriodosSinceYearAndMonth, getUpdateDate } from "../utils/monthNames";
import {
  customStyles,
  parseBudat,
  setMaterialesYTanques,
  SingleOption,
  getAproximate3FloatString,
  getLocaleStringFloat,
  get3DecimalsFloatWithDelimiter,
  getLocaleStringRoundedInt,
  parseDateString,
  getExcelWithXlsx2
} from "../utils/utils";
import {
  displayLoader,
  hideGreyBackground,
  hideLoader,
  parentRedirect,
  scrollTo,
  showGreyBackground,
} from "../utils/windows";
import { AsignacionEntradas } from "./AsignacionEntradas";
import CierreDiarioTable from "./CierreDiarioTable";
import Ventas from "./Ventas";
import { CalibracionesYMovBodega } from "./CalibracionesYMovBodega";
import { IngresoMediciones } from "./IngresoMediciones";
import { ConfirmacionFluctuaciones } from "./ConfirmacionFluctuaciones";
import download from "../../assets/img/DOWNLOAD.svg";
import PrintComponent from "../PrintComponent";
import ExportExcelHidden from "../ExportExcelHidden";
import { containerCSS } from "react-select/dist/index-fe3694ff.cjs.dev";

const CierreDiario = ({
  token,
  rutConcesionario,
  viewPortHeight,
  callServiceHandler,
  pagador,
  instalaciones,
  cabecera,
  params,
  isROImpersonate,
  edsPortal,
  setPagador
}) => {
  const [step, setStep] = useState(1);
  const [stepLoaded, setStepLoaded] = useState([]);
  const [updateDate, setUpdateDate] = useState("");
  const [allEds, setAllEds] = useState([]);
  const [selectedEds, setSelectedEds] = useState();
  const [fechaCierre, setFechaCierre] = useState();
  const [selectedEstadoCierre, setSelectedEstadoCierre] = useState();

  const [configuracion, setConfiguracion] = useState();

  const [fechaMedicion, setFechaMedicion] = useState();
  const [horaMedicion, setHoraMedicion] = useState();
  const [entradasManuales, setEntradasManuales] = useState([]);
  const [tanques, setTanques] = useState([]);
  const [pedidos, setPedidos] = useState();
  const [openPedidos, setOpenPedidos] = useState(false);
  const [lastUrlPedidos, setLastUrlPedidos] = useState("");
  const [materiales, setMateriales] = useState([]);
  const [materialesTanques, setMaterialesTanques] = useState([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState(null);
  const [allPeriodos, setAllPeriodos] = useState([]);
  const tableRef = useRef(null);
  const [cierres, setCierres] = useState();
  const [cierresOriginal, setCierresOriginal] = useState([]);
  const [ventas, setVentas] = useState(null);
  const [mediciones, setMediciones] = useState({})
  const [horas, setHoras] = useState({})
  const [selectedCierre, setSelectedCierre] = useState();

  //paso 3
  const [calibracionesEntradas, setCalibracionesEntradas] = useState({})
  const [calibracionesSalidas, setCalibracionesSalidas] = useState({})
  const [calibracionesRecomendadas, setCalibracionesRecomendadas] = useState({})
  const [movimientosBodega, setMovimientosBodega] = useState([])
  const [movimientosRecomendados, setMovimientosRecomendados] = useState([])
  const [filteredTanques, setFilteredTanques] = useState({})
  const [filteredDestinoTanques, setFilteredDestinoTanques] = useState({})
  const [lastUrlVentasCamiones, setLastUrlVentasCamiones] = useState("")
  const [lastUrlVentasTanques, setLastUrlVentasTanques] = useState("")
  const [ventasTanques, setVentasTanques] = useState({})
  const [entradasSalidas, setEntradasSalidas] = useState()

  const [openEntradas, setOpenEntradas] = useState(false)
  const [openSalidas, setOpenSalidas] = useState(false)

  const [fechaParams, setFechaParams] = useState();
  const [fechaParamsUsed, setFechaParamsUsed] = useState(false);

  const [fluctuaciones, setFluctuaciones] = useState();

  const [detalleVentas,setDetalleVentas] = useState();
  const [labelsVentas,setLabelsVentas] = useState()
  const [disableEntradas, setDisableEntradas] = useState(false)
  const [disableVentas, setDisableVentas] = useState(false)
  const [disableCalibraciones, setDisableCalibraciones] = useState(false)
  const [disableMedicion, setDisableMedicion] = useState(false)
  const [disableFluctuaciones, setDisableFluctuaciones] = useState(false)
  const [requestCounter, setRequestCounter] = useState(0)
  const [pedidosEspeciales, setPedidosEspeciales] = useState();

  const [ventasForPaso5, setVentasForPaso5] = useState();
  const [esPosibleCerrar, setEsPosibleCerrar] = useState();

  const [anioActivacion, setAnioActivacion] = useState();
  const [mesActivacion, setMesActivacion] = useState();

  const [isArcoprime, setIsArcoprime] = useState(false);
  const [allPagadores, setAllPagadores] = useState([]);
  const [selectedPagador, setSelectedPagador] = useState();
  const [configuracionError, setConfiguracionError] = useState(false);
  const [displayModalErrorConfiguracion, setDisplayModalErrorConfiguracion] = useState(false);
  const [permiteTanqueCamion, setPermiteTanqueCamion] = useState(false)

  const stepNames=[
    "Entradas",
    "Ventas",
    "Calibraciones y Mov. Bodega",
    "Medición Física",
    "Fluctuaciones"
  ]

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (token && rutConcesionario) {
        if(params){
          const paramsArr = params.split("&");
          let fecha = paramsArr[1].split("=")[1];
          setFechaParams(fecha);
        }
        if (rutConcesionario === "77215640-5") {
          setIsArcoprime(true);
        }
      }
    } else {
      if (token) {
        if(params){
          const paramsArr = params.split("&");
          let fecha = paramsArr[1].split("=")[1];
          setFechaParams(fecha);
        }
        if (rutConcesionario === "77215640-5" /* || true */) {
          setIsArcoprime(true);
        }
      }
    }
  }, [token, rutConcesionario]);

  useEffect(() => {
    if (instalaciones!==""){
      if(!isArcoprime){
        setInstalacionesDinamo(instalaciones,setAllEds)
      }
    }
  }, [instalaciones]);

  useEffect(async () => {
    if(selectedPagador){
      const url = process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/obtener-instalaciones-cliente?id_pagador=" + selectedPagador.value;

      let result = await callServiceHandler(url, "GET")
      if(result !== "error"){
        setInstalacionesDinamo(result.data, setAllEds);
      }
      setPagador(selectedPagador.value)
    }
  }, [selectedPagador]);

  useEffect(() => {
    getUpdateDate(setUpdateDate);
  }, []);

  useEffect(() => {
    if (allEds.length === 1){
      setSelectedEds(allEds[0]);
    } else{
      if(params){
        const paramsArr = params.split("&");
        const edsParam = paramsArr[0].split("=")[1];
        setSelectedEds(allEds.filter(eds=>eds.value===edsParam)[0])
      }
    }
  }, [allEds]);

  useEffect(() => {
    if(edsPortal){
      setPagadoresArcoprime(edsPortal.data.user_eds,setAllPagadores);
    }
  }, [edsPortal]);

  useEffect(() => {
    if (allPagadores.length === 1) {
      changePagador(allPagadores[0]);
    }
  }, [allPagadores]);

  function changePagador(e) {
    setSelectedPagador(e);
  }

  useEffect(() => {
    if(selectedEds){
      if(isArcoprime){
        if(pagador){
          getFechaActivacion()
        }
      }else{
        getFechaActivacion()
      }
    }
  }, [selectedEds,pagador]);

  useEffect(() => {
    if (anioActivacion && mesActivacion) {
      const newPeriodos = getPeriodosSinceYearAndMonth(anioActivacion, 
        mesActivacion);
      setAllPeriodos(newPeriodos);
      setSelectedPeriodo(newPeriodos[0]);
    }
  }, [anioActivacion, mesActivacion]);

  async function getFechaActivacion() {

    const url =
    process.env.REACT_APP_FLUCTUACIONES_API +
    "/fluctuaciones/cierre-diario-listar-periodos?ID_EDS=" +
    selectedEds.value + 
    "&ID_PAGADOR=" + pagador;

    let response = await callServiceHandler(url, "GET");    
    if (response !== "error") {
      setAnioActivacion(parseInt(response.data.año_activacion))
      setMesActivacion(parseInt(response.data.mes_activacion))
    }
  }

  async function getResumenCierre() {
    displayLoader()
    const url =
        process.env.REACT_APP_FLUCTUACIONES_API +
        "/fluctuaciones/resumen-cierre-diario?ID_EDS=" +
        selectedEds.value +
        "&PERIODO="+selectedPeriodo.value+"&ID_PAGADOR="+pagador;

    const response = await callServiceHandler(url, "GET")
    let primerAbiertoFound=false;
    const cierresOrdenadosReversos = response.data.cierres.reverse().map((cierre,index)=>{
      if(!primerAbiertoFound){
        if(cierre.estado_cierre==="ABIERTO"||cierre.estado_cierre==="RE ABIERTO"){
          primerAbiertoFound=true;
        }
      }else{
        cierre.blocked=true
      }
      return cierre
    })
    const cierresProcesados = cierresOrdenadosReversos.map(c=>c).reverse();
    setCierres(cierresProcesados);
    setCierresOriginal(cierresProcesados);
    setEsPosibleCerrar(response.data.posible_cerrar)
  }

  async function getDetalleVentas(){
    const url =
    process.env.REACT_APP_FLUCTUACIONES_API +
    "/fluctuaciones/cierre-diario-listado-transacciones?ID_EDS=" +
    selectedEds.value +
    "&FECHA="+fechaCierre+"&ID_PAGADOR="+pagador+"&TIPO=3"+"&RUT="+rutConcesionario;

    let response = await callServiceHandler(url, "GET");

    let docName = `Detalle de Ventas - ${parseInt(selectedEds.value)} - ${parseBudat(fechaCierre)}.xlsx`

    getExcelWithXlsx2(response.data.hojas, docName)
  }

  async function getDetalleVentasMensual(){

    // const cierresCerrados = cierres.filter(cierre=>cierre.estado_cierre==="CERRADO");
    const promises=[];
    const detallesVentasAux=[];
    const detallesOtrosMovimientosAux=[];
    const regularizaciones=[];
    const movimientosBodega=[];
    
    let labelsVentas = {}
    let labelOtrosMovimientos = {}
    let labelRegularizacion = {}
    let labelMovimientosBodega = {}
    let docName = "Detalle de ventas mensual-" + parseInt(selectedEds.value) + "-"+ selectedPeriodo.label +".xlsx"

    if (cierres.length>0){
      displayLoader()
      cierres.forEach(cierre=>{
        const url =
        process.env.REACT_APP_FLUCTUACIONES_API +
        "/fluctuaciones/cierre-diario-listado-transacciones?ID_EDS=" +
        selectedEds.value +
        "&FECHA="+cierre.fecha+"&ID_PAGADOR="+pagador+"&TIPO=3"+"&RUT="+rutConcesionario;
  
        promises.push(callServiceHandler(url, "GET", null, false).then(response => {
          if(response !== "error"){
            setLabelsVentas(response.data.labels);
            labelsVentas=response.data.hojas[0].labels  
            labelOtrosMovimientos=response.data.hojas[1].labels  
            labelRegularizacion=response.data.hojas[2].labels  
            labelMovimientosBodega=response.data.hojas[3].labels
            response.data.hojas.forEach(hoja=>hoja.transacciones.forEach(trx=>{
              if (hoja.nombre==="ventas") {
                detallesVentasAux.push(trx)
              }
              if (hoja.nombre==="otros movimientos") {
                detallesOtrosMovimientosAux.push(trx)
              }
              if (hoja.nombre==="regularizaciones") {
                regularizaciones.push(trx)
              }
              if (hoja.nombre==="movimientos_de_bodega") {
                movimientosBodega.push(trx)
              }
            }))
          }
        }))
      })
  
      await Promise.all(promises)

      // setRequestCounter((old)=>old+1);
      const ventasOrdenadas = detallesVentasAux.map(trx=>{
        let nuevaFecha = "";
        if(trx.D===""){
          nuevaFecha="-";
        }else{
          nuevaFecha = trx.D.split("-")[2]+"/"+trx.D.split("-")[1]+"/"+trx.D.split("-")[0]
        }
        return {...trx,D:nuevaFecha}
      }).sort((a,b)=>{
        if(a.D<b.D){
          return -1
        }else if(a.D>b.D){
          return 1
        }else{
          if(a.E<b.E){
            return -1
          }else if(a.E>b.E){
            return 1
          }else{
            return 0
          }
        }
      });

      const otrosMovimientosOrdenados = detallesOtrosMovimientosAux.map(trx=>{
        let nuevaFecha = "";
        if(trx.D===""){
          nuevaFecha="-";
        }else{
          nuevaFecha = trx.D.split("-")[2]+"/"+trx.D.split("-")[1]+"/"+trx.D.split("-")[0]
        }
        return {...trx,D:nuevaFecha}
      }).sort((a,b)=>{
        if(a.D<b.D){
          return -1
        }else if(a.D>b.D){
          return 1
        }else{
          if(a.E<b.E){
            return -1
          }else if(a.E>b.E){
            return 1
          }else{
            return 0
          }
        }
      });

      const ventasConFecha = ventasOrdenadas.filter(trx=>trx.D!=="-");
      const ventasSinFecha = ventasOrdenadas.filter(trx=>trx.D==="-");
      ventasSinFecha.forEach(trx=>ventasConFecha.push(trx));

      const otrosMovimientosConFecha = otrosMovimientosOrdenados.filter(trx=>trx.D!=="-");
      const otrosMovimientosSinFecha = otrosMovimientosOrdenados.filter(trx=>trx.D==="-");
      otrosMovimientosSinFecha.forEach(trx=>otrosMovimientosConFecha.push(trx));

      const arrToExport = [
        {
          nombre:"Ventas",
          labels: labelsVentas,
          transacciones:ventasConFecha
        },
        {
          nombre:"Otros Movimientos",
          labels: labelOtrosMovimientos,
          transacciones:otrosMovimientosConFecha
        },
        {
          nombre:"Regularización",
          labels: labelRegularizacion,
          transacciones:regularizaciones
        },
        {
          nombre:"Movimientos Bodega",
          labels: labelMovimientosBodega,
          transacciones:movimientosBodega
        },
      ]

      getExcelWithXlsx2(arrToExport, docName)
      
      hideLoader()
      // setDetalleVentas(ventasConFecha);

    }
  }

  useEffect(() => {
    setRequestCounter(0);
  }, [step,fechaCierre]);

  useEffect(() => {
    if(selectedEds&&selectedPeriodo){
      getResumenCierre();
      if(fechaParams && !fechaParamsUsed){
        setFechaCierre(fechaParams)
        setFechaParamsUsed(true);
        setStep(2);
      }
    }
  }, [selectedEds, selectedPeriodo]);

  useEffect(() => {
    if (fechaCierre && step === 1) {
      setPedidos();
      setPedidosEspeciales();
      setEntradasManuales([]);
      fetchEntradasRecomendadas();
    }
  }, [fechaCierre, step]);

  async function fetchEntradasRecomendadas() {

      const url =
      process.env.REACT_APP_FLUCTUACIONES_API +
      "/fluctuaciones/listar-entradas?ID_EDS=" +
      selectedEds.value +
      "&FECHA="+fechaCierre+"&ID_PAGADOR="+pagador;

      let response = await callServiceHandler(url, "GET");

      if (response !== "error") {
        setDisableEntradas(false);
        if(response.data.ESTADO_PASO === "CERRADO" ){
          setPedidos(response.data.pedidos);
          setPedidosEspeciales(response.data.pedidosEspeciales);
          setEntradasManuales(response.data.entradasManuales)
        }else{
          setPedidos(
            response.data?.pedidos.filter(p=>p.GRUPO_PEDIDO==="normal").map((pedido) => {
              let newPedido = { ...pedido };
                newPedido.DESCARGAS = newPedido.DESCARGAS.map((descarga) => {
                  const tmpDescarga = descarga;
                  if(newPedido["TIPO_ESPECIAL"]==="Estación Virtual"){
                    tmpDescarga.tanquesOptions = tanques.filter(
                      (t) =>
                        t.idMaterial === descarga.material
                    );
                  }else{
                    tmpDescarga.tanquesOptions = tanques.filter(
                      (t) =>
                        t.idMaterial === descarga.material && t.tipo !== "camion"
                    );
                  }
                  
                  if (tmpDescarga.tanquesOptions.length === 1)
                    tmpDescarga.tanque = tmpDescarga.tanquesOptions[0];
                  return descarga;
                });

              if(newPedido.FECHA === fechaCierre){
                newPedido.checked=true;
                newPedido.blocked=true;
              }
              if(newPedido.used) newPedido.checked=false

              newPedido.DETALLE=pedido.DETALLES;
              
              return newPedido;
            })
          );

          const pedidosEspecialesAux = response.data.pedidos.filter(p=>p.GRUPO_PEDIDO==="especial" && p.FECHA===fechaCierre).map(pedido=>{
            pedido.DESCARGAS.forEach(material=>{
              if(!("TANQUES" in material)){
                material.TANQUES = getTanquesOfPedidoEspecial(configuracion,material);
              }
            })
            pedido.TANQUES_LENGTH = pedido.DESCARGAS.reduce((suma,material)=>suma+=material.TANQUES.length,0);
            return pedido;
          });
          if (pedidosEspecialesAux.length>0) setPedidosEspeciales(pedidosEspecialesAux)

        }
        
      }else{
        setDisableEntradas(true);
      }
    
  }

  useEffect(() => {
    if(step===2){
      fetchVentas();
    }
  }, [step]);

  async function fetchVentas(){
    const url =
      process.env.REACT_APP_FLUCTUACIONES_API +
      "/fluctuaciones/cierre-diario-ventas?ID_EDS=" +
      selectedEds.value +
      "&FECHA="+fechaCierre+ "&ID_PAGADOR=" + pagador+"&RUT="+rutConcesionario;

    let response = await callServiceHandler(url, "GET");
    if(response!=="error"){
      setDisableVentas(false)
      const newDetalle = []
      const gas93 = response.data?.detalle.filter(v => v.material === "GAS 93")
      if(gas93)
        gas93.forEach(e=>newDetalle.push(e))

      const gas95 = response.data?.detalle.filter(v => v.material === "GAS 95")
      if(gas95)
        gas95.forEach(e=>newDetalle.push(e))

      const gas97 = response.data?.detalle.filter(v => v.material === "GAS 97")
      if(gas97)
        gas97.forEach(e=>newDetalle.push(e))

      const diesel = response.data?.detalle.filter(v => v.material === "DIESEL")
      if(diesel)
        diesel.forEach(e=>newDetalle.push(e))

      const kerosene = response.data?.detalle.filter(v => v.material === "KEROSENE")
      if(kerosene)
        kerosene.forEach(e=>newDetalle.push(e))

      const glp = response.data?.detalle.filter(v => v.material === "GLP")
      if(glp)
        glp.forEach(e=>newDetalle.push(e))

      const gnc = response.data?.detalle.filter(v => v.material === "GNC")
      if(gnc)
        gnc.forEach(e=>newDetalle.push(e))

      response.data.detalle = newDetalle
      setVentas(response.data)
    }
    else{
      setDisableVentas(true)
    }
  }

  useEffect(() => {
    if (fechaCierre && step === 3) {
      const tmpStepLoaded = [...stepLoaded]
      tmpStepLoaded[3] = true
      setStepLoaded(tmpStepLoaded)
      fetchVentasRecomendadas()
    }
  }, [fechaMedicion, step])

  async function fetchVentasRecomendadas() {
    setMovimientosRecomendados([])
    setCalibracionesEntradas({})
    setCalibracionesSalidas({})
    setCalibracionesRecomendadas({})
    setVentasTanques({})
    setMovimientosBodega([])
    displayLoader();
    const query_params = "?ID_EDS=" + selectedEds.value + "&fecha="+parseBudat(fechaCierre)+"&ID_PAGADOR="+pagador+"&RUT="+rutConcesionario 
    let url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/cierre-diario-calibraciones-bodegas" + query_params

    let tmpVentasTanques = {}
    let tmpMovimientosBodega = []
    let tmpCalibraciones = {}

    let response = await callServiceHandler(url, "GET")
    if(response !== "error"){
      setDisableCalibraciones(false);
      ////BEGIN TEST
      tmpMovimientosBodega = response.data.MOVIMIENTOS_BODEGA
      if(response.data.PERMITE_TANQUE_CAMION === "SI")
        setPermiteTanqueCamion(true)
      else
        setPermiteTanqueCamion(false)

      let pasoraro = response.data

      if(response.data.ESTADO_PASO === "CERRADO" || response.data.ESTADO_PASO==="REABIERTO"){
        tmpMovimientosBodega = response.data.INFORMACION_PASO.movimientosBodega
        tmpMovimientosBodega = tmpMovimientosBodega.map((mov) => {
          const tanqueOrigen = tanques.find(t => t.value === mov.tank_group)
          const tanqueDestino = tanques.find(t => t.value === mov.id_camion_preasignado)
          return {
            id:mov.id,
            material: mov.material,
            tanqueOrigen: mov.tanqueOrigen,
            tanqueDestino: tanqueDestino? tanqueDestino: '',
            volumen: mov.volumen,
            tipo: mov.tipo,
          }
        });
      }else{
        tmpMovimientosBodega = tmpMovimientosBodega.map((mov) => {
          const tanqueOrigen = tanques.find(t => t.value === mov.tank_group)
          const tanqueDestino = tanques.find(t => t.value === mov.id_camion_preasignado)
          return {
            id:mov.id,
            material: mov.material,
            tanqueOrigen: tanqueOrigen,
            tanqueDestino: tanqueDestino? tanqueDestino: '',
            volumen: mov.volume,
            tipo: mov.tipo,
          }
        });
      }
      

      setMovimientosRecomendados(tmpMovimientosBodega)
      ////END TEST

      if(response.data.ESTADO_PASO==="CERRADO" || response.data.ESTADO_PASO==="REABIERTO"){
        setCalibracionesEntradas(response.data.INFORMACION_PASO.calibracionesEntradas);
        setCalibracionesSalidas(response.data.INFORMACION_PASO.calibracionesSalidas);
        setMovimientosBodega(response.data.INFORMACION_PASO.movimientosBodega);
      }else{
        tmpVentasTanques = response.data.VENTAS
        
        tmpCalibraciones = response.data.CALIBRACIONES
        for (const tanque of configuracion.TANQUES) {
          if (tanque.TIPO.toLowerCase() === "tanque" 
            && (!(tanque.ID in tmpVentasTanques) || (tanque.ID in tmpVentasTanques && tmpVentasTanques[tanque.ID] === 0)))
          {
            tmpVentasTanques[tanque.ID] = '0'
          }
          else{
            tmpVentasTanques[tanque.ID] = getLocaleStringFloat(tmpVentasTanques[tanque.ID])
          }
        }

        setVentasTanques(tmpVentasTanques)
        setCalibracionesRecomendadas(tmpCalibraciones)
      }
      
    }
    else{
      setDisableCalibraciones(true);
    }
    // Ventas camiones
    url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/recomendar-salidas-camiones" + query_params
    if (lastUrlVentasCamiones !== url) {
      setLastUrlVentasCamiones(url)

      let tmpVentasCamiones = {}
      //NO SE NECESITAN CAMIONES EN LAS CALIBRACIONES
      /* let response = await callServiceHandler(url, "GET")
      if(response !== "error"){
        tmpVentasCamiones = response.data.VENTAS_CAMIONES
      }

      for (const tanque of configuracion.TANQUES) {
        if (tanque.TIPO.toLowerCase() === "camion" 
          && (!(tanque.ID in tmpVentasCamiones) || (tanque.ID in tmpVentasCamiones && tmpVentasCamiones[tanque.ID] === 0)))
        {
          tmpVentasCamiones[tanque.ID] = '0'
        }
      }
      setVentasCamiones(tmpVentasCamiones) */
    }
  }

  async function fetchTelemedicion(){
    let url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/cierre-diario-obtener-telemediciones?ID_EDS=" 
    + selectedEds.value +"&FECHA=" + parseBudat(fechaCierre)+"&ID_PAGADOR="+pagador+"&RUT="+rutConcesionario;

    let telemedicion = {}
    let horas = {}

    let response = await callServiceHandler(url, "GET")
    if(response !== "error"){
      setDisableMedicion(false)
      if(response.data.ESTADO_PASO === "CERRADO" || response.data.ESTADO_PASO==="REABIERTO"){
        setMediciones(response.data.INFORMACION_PASO.mediciones)
      }else{
        telemedicion = response.data.TELEMEDICIONES;
        horas = response.data.FECHA_MEDICION
        let tmpMediciones = {};

        /* telemedicion = {
          "1": "18467.7667",
          "2": "12979.0523",
          "3": "10240.1976",
          "4": "1892.8516"
        }
        horas = {
          "1": "2022-11-11 23:52:22",
          "2": "2022-11-11 23:52:00",
          "3": "2022-11-11 23:48:24",
          "4": "2022-11-11 23:52:12"
        } */

        const promises = []
        displayLoader()

        for (const tanque of configuracion.TANQUES) {
          if(!tmpMediciones[tanque.ID])
            tmpMediciones[tanque.ID] = {
              ID_MATERIAL: tanque.ID_MATERIAL,
              MATERIAL: tanque.MATERIAL,
              SIFON_1: tanque.SIFON_1,
              SIFON_2: tanque.SIFON_2
            }
          if(tanque.ID in telemedicion){
            tmpMediciones[tanque.ID].medicion = getLocaleStringRoundedInt(telemedicion[tanque.ID]);
          }
          else{
            tmpMediciones[tanque.ID].medicion = '0' 
          }
          if(tanque.ID in horas){
            //await valuesChange("hora",horas[tanque.ID].split(" ")[1].substring(0,4),tanque.ID)
            tmpMediciones[tanque.ID].hora = horas[tanque.ID].split(" ")[1].substring(0,5);
          }
          else{
            tmpMediciones[tanque.ID].hora = '23:59'
          }

          if(tanque.TIPO === 'camion'){
            tmpMediciones[tanque.ID].hora = '23:59'
            tmpMediciones[tanque.ID].entrada_pos_medicion = 0
            tmpMediciones[tanque.ID].salida_pos_medicion = 0
            tmpMediciones[tanque.ID].total = calculateTotalMedicion(0, tmpMediciones[tanque.ID].medicion, 0)
          }

          else{
            const url =
              process.env.REACT_APP_FLUCTUACIONES_API +
              "/fluctuaciones/cierre-diario-buscar-medicion-proyectada?ID_EDS=" + selectedEds.value + 
              "&FECHA=" + fechaCierre + 
              "&ID_PAGADOR=" + pagador + 
              "&TANQUE=" + tanque.ID + 
              "&MATERIAL=" + mediciones[tanque.ID].ID_MATERIAL + 
              "&HORA=" + tmpMediciones[tanque.ID].hora.replace(':','') + "00" +
              "&RUT="+rutConcesionario;          

            promises.push(callServiceHandler(url, "GET", null, false).then(response => {
              if(response !== "error"){
                tmpMediciones[tanque.ID].entrada_pos_medicion = response.data.entrada_pos_medicion
                tmpMediciones[tanque.ID].salida_pos_medicion = response.data.salida_pos_medicion
                tmpMediciones[tanque.ID].total = calculateTotalMedicion(response.data.entrada_pos_medicion, tmpMediciones[tanque.ID].medicion, response.data.salida_pos_medicion)
              }
            }))
          }
        }

        await Promise.all(promises)
        hideLoader()

        setMediciones(tmpMediciones);  
      }
    }else{
      setDisableMedicion(true)
    }
  }

  function calculateTotalMedicion(entrada, medicion, salida){
    entrada = parseFloat(entrada) || 0
    salida = parseFloat(salida) || 0
    
    if(medicion !== null && medicion !== undefined && medicion !== false){
      medicion = medicion.replace(/\./g, "")
      medicion = medicion.replace(/\,/g, '.')
    }
    
    medicion = parseFloat(medicion) || 0
    return entrada + medicion - salida
  }

  async function fetchFluctuaciones(){
    displayLoader()
    let url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/cierre-diario-fluctuaciones?"
      + "ID_EDS=" + selectedEds.value
      + "&FECHA=" + fechaCierre
      + "&ID_PAGADOR=" + pagador
      + "&RUT=" + rutConcesionario

    let response = await callServiceHandler(url, "GET")
    if(response !== "error"){
      setDisableFluctuaciones(false)
      const newFluctuaciones = []

      const gas93 = response.data?.detalle.find(v => v.material.toLowerCase() === "gas 93")
      if(gas93)
        newFluctuaciones.push(gas93)

      const gas95 = response.data?.detalle.find(v => v.material.toLowerCase() === "gas 95")
      if(gas95)
        newFluctuaciones.push(gas95)

      const gas97 = response.data?.detalle.find(v => v.material.toLowerCase() === "gas 97")
      if(gas97)
        newFluctuaciones.push(gas97)

      const diesel = response.data?.detalle.find(v => v.material.toLowerCase() === "diesel")
      if(diesel)
        newFluctuaciones.push(diesel)

      const kerosene = response.data?.detalle.find(v => v.material.toLowerCase() === "kerosene")
      if(kerosene)
        newFluctuaciones.push(kerosene)

      const glp = response.data?.detalle.find(v => v.material.toLowerCase() === "glp")
      if(glp)
        newFluctuaciones.push(glp)

      const gnc = response.data?.detalle.find(v => v.material.toLowerCase() === "gnc")
      if(gnc)
        newFluctuaciones.push(gnc)

      setFluctuaciones(newFluctuaciones)
      setEntradasSalidas(response.data?.entradas_salidas)
      setVentasForPaso5(response.data.ventas)
    }
    else{
      setDisableFluctuaciones(true)
    }
    hideLoader()
  }

  useEffect(() => {
    if (fechaCierre && step === 4) {
      const tmpStepLoaded = [...stepLoaded]
      tmpStepLoaded[4] = true
      setStepLoaded(tmpStepLoaded)
      fetchTelemedicion()
    }
    else if(fechaCierre && step === 5){
      const tmpStepLoaded = [...stepLoaded]
      tmpStepLoaded[5] = true
      setStepLoaded(tmpStepLoaded)
      fetchFluctuaciones()
    }
  }, [fechaCierre, step]);

  function openCloseModals(isOpen, modalFunc) {
    modalFunc(isOpen);
    if (isOpen) {
      showGreyBackground();
      scrollTo(0, 50);
    } else {
      hideGreyBackground();
    }
  }

  useEffect(() => {
    async function fetchConfiguracion() {
      if (selectedEds) {
        try {
          const existeConf = await getConfiguracion(callServiceHandler, selectedEds.value, setConfiguracion);
          if (!existeConf) {
            setConfiguracionError(true);
            setDisplayModalErrorConfiguracion(true);
          }
        } catch (error) {
          console.error('Failed to fetch configuration:', error);
          setConfiguracionError(true);
          setDisplayModalErrorConfiguracion(true);
        }
      }
    }
  
    fetchConfiguracion();
  }, [selectedEds]);

  useEffect(() => {
    if (configuracion) {
      setMaterialesYTanques(configuracion.TANQUES, setTanques, setMateriales, setMaterialesTanques);

      let tmpMediciones = {};

      for (const tanque of configuracion.TANQUES) {
        if(!tmpMediciones[tanque.ID])
          tmpMediciones[tanque.ID] = {
            ID_MATERIAL: tanque.ID_MATERIAL,
            MATERIAL: tanque.MATERIAL,
            SIFON_1: tanque.SIFON_1,
            SIFON_2: tanque.SIFON_2
          }
      }
      setMediciones(tmpMediciones);
    }
  }, [configuracion]);

  function changeSelectedPeriodo(e) {
    setSelectedPeriodo(e);
  }

  // useEffect(() => {
  //   const allPeriodos = getPeriodosSince(2022);
  //   setAllPeriodos(allPeriodos);
  //   setSelectedPeriodo(allPeriodos[0]);
  // }, []);

  useEffect(() => {
    let tmpEntradas = {}
    let tmpSalidas = {}
    for (const key in calibracionesRecomendadas) {
      tmpEntradas[key] = getAproximate3FloatString(calibracionesRecomendadas[key]["entrada"])
      tmpSalidas[key] = getAproximate3FloatString(calibracionesRecomendadas[key]["salida"])  
    }
    setCalibracionesEntradas(tmpEntradas)
    setCalibracionesSalidas(tmpSalidas)
  }, [calibracionesRecomendadas])

  useEffect(() => {
    const tmpMovimientosBodega = []
    const tempTanques = {}

    movimientosRecomendados.forEach(function(mov, key){
      //mov.volumen = getAproximate3FloatString(mov.volumen)
      tmpMovimientosBodega.push(mov)
      const newTanques = tanques.filter(t => t.idMaterial === mov.material.value)
      tempTanques[key] = newTanques
    })

    tmpMovimientosBodega.map((mov, key) => {
      const tanquesDestino = tempTanques[key]?.filter(t => t !== tmpMovimientosBodega[key].tanqueOrigen) || []
      if(tanquesDestino.length === 1){
        tmpMovimientosBodega[key].tanqueDestino = tanquesDestino[0]
      }
    })

    setMovimientosBodega(tmpMovimientosBodega)
    setFilteredTanques(tempTanques)

    //setFilteredDestinoTanques(tempTanques);
  }, [movimientosRecomendados]);

  useEffect(() => {
    const tempTanques = {}

    movimientosRecomendados.forEach(function(mov, key){
      //mov.volumen = getAproximate3FloatString(mov.volumen)
      const newTanques = tanques.filter(t => t.idMaterial === mov.material.value)
      tempTanques[key] = newTanques
    })

    setFilteredTanques(tempTanques)
  }, [movimientosBodega]);

  function getTanquesDestinoRecomendados(key){
    let tmpTanques = []
    if(movimientosBodega[key].tipo==="STORAGE"){
      tmpTanques = filteredTanques[key]?.filter(t => (t.value !== movimientosBodega[key].tanqueOrigen?.value && t.label === 'TAE_RE')  ) || []
      tmpTanques.push({label:"REVISAR",value:"REVISAR"})
    }else{
      tmpTanques = filteredTanques[key]?.filter(t => (t.value !== movimientosBodega[key].tanqueOrigen?.value && t.tipo === 'camion')  ) || []
    }
    return tmpTanques
  }

  const getUpdateFunction = () => {
    if(!fechaCierre){
      getResumenCierre()
    }
    else{
      if(step === 1){
        fetchEntradasRecomendadas();
      }
      if(step===2){
        fetchVentas();
      }
      if(step===3){
        fetchVentasRecomendadas()
      }
      if(step===4){
        fetchTelemedicion();
      }
      if(step===5){
        fetchFluctuaciones();
      }
    }
    
  }

  useEffect(() => {
    if(fechaCierre){
      scrollTo(0,150);
    }
  }, [step]);

  const goToHistorialFluctuaciones = () => {
    parentRedirect(process.env.REACT_APP_HISTORIAL_FLUCTUACIONES_CIERRE_DIARIO_PATH)
  }

  return (<>
    <div className="page fluctuaciones-ingreso-mediciones cierre-diario">
      <div className="view-title">Cierre de Operaciones</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">
        {/* {stepNames[step - 1]} */} Cierre Diario
      </div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandler={callServiceHandler}
        cabecera={cabecera}
        actualizable={true}
        updateFunction={getUpdateFunction}
      />
      {!fechaCierre && (
        <>
        <div className="cierre-diario-options">
          <div className="title">Cierre Diario</div>
          <hr className="title-separator"></hr>
          <div className="page-options">
            <div className="filters-container">
              {isArcoprime && 
                <div className="filter">
                <div className="filter-title required-field">
                  ID DE PAGADOR
                </div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedPagador}
                    onChange={(e) => changePagador(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allPagadores}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStyles}
                    /* isDisabled={step !== 1} */
                  />
                </div>
              </div>
              }
              <div className="filter">
                <div className="filter-title required-field">
                  ESTACIÓN DE SERVICIO
                </div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedEds}
                    onChange={(e) => setSelectedEds(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allEds}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStyles}
                    /* isDisabled={step !== 1} */
                  />
                </div>
              </div>
              <div className="filter">
                <div className="filter-title">PERÍODO</div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedPeriodo}
                    onChange={(e) => changeSelectedPeriodo(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allPeriodos}
                    components={{ SingleOption, IndicatorSeparator: () => null }}
                    isClearable={false}
                    autosize={false}
                    styles={customStyles}
                  />
                </div>
              </div>
            </div>
            <div className="actions">
              <button className="main-button hallow iconbutton no-mr" onClick={()=>getDetalleVentasMensual()}><img src={download} alt="" />Detalle ventas</button>
                {detalleVentas && 
                  <ExportExcelHidden 
                  data={detalleVentas}
                  labels={labelsVentas}
                  isROImpersonate={isROImpersonate}
                  requestCounter={requestCounter}
                  fileName={`Detalle de ventas - ${parseInt(selectedEds.value)} - ${parseBudat(fechaCierre)}`}
                  />
                
                }
              
              <button
                className={`main-button`}
                onClick={() => goToHistorialFluctuaciones()}
              >
                Ver Historial Fluctuaciones
              </button>
              <PrintComponent 
                  reff={tableRef}
                  classes=""
                  isROImpersonate={isROImpersonate}
                />
                
            </div>
            </div>
        </div>

        <div className="cierre-estados">
            <CierreDiarioTable
              tableRef={tableRef}
              cierres={cierres}
              setFechaCierre={setFechaCierre}
              setCierres={setCierres}
              cierresOriginal={cierresOriginal}
              setSelectedEstadoCierre={setSelectedEstadoCierre}
              setStep={setStep}
              isROImpersonate={isROImpersonate}
              setSelectedCierre={setSelectedCierre}
              esPosibleCerrar={esPosibleCerrar}
              configuracionError={configuracionError}
              displayModalErrorConfiguracion={displayModalErrorConfiguracion}
              setDisplayModalErrorConfiguracion={setDisplayModalErrorConfiguracion}
              />
        </div>
       
        </>
      )}
      {fechaCierre && (
        <>
          <div className="page-options">
            <div className="filters-container">
              <div className="step-title">{stepNames[step - 1]}</div>
              <div className="step-number">Paso {step}</div>
            </div>
            <CopecStepper
              steps={[
                "Entradas",
                "Ventas",
                "Calibraciones y Mov. Bodega",
                "Medición física",
                "Fluctuaciones",
              ]}
              activeStep={step}
              className="fluctuaciones-ingreso-mediciones-stepper"
              activateLinks={selectedEstadoCierre==="CERRADO"}
              setStep={setStep}
            />
          </div>

          <div className="page-options second-row-options">
            <div className="filters-container">
              <div className="filter">
                <div className="filter-title required-field">ESTACIÓN DE SERVICIO</div>
                <div className="filter-input">
                  <input
                    type="text"
                    className="filter-input-element"
                    disabled
                    value={parseInt(selectedEds.value)}
                  />
                </div>
              </div>
              <div className="filter">
                <div className="filter-title required-field">FECHA CIERRE DE OPERACIONES</div>
                <div className="filter-input">
                  <input
                    type="text"
                    className="filter-input-element"
                    disabled
                    value={parseBudat(fechaCierre)}
                  />
                </div>
              </div>
              
            </div>
            {step === 1 && (
              <div className="actions">
                <button
                  className="main-button"
                  onClick={() => openCloseModals(true, setOpenPedidos)}
                >
                  Seleccionar Pedidos
                </button>
              </div>
            )}
            {step === 2 && (
              <div className="actions">
                <button className="main-button hallow iconbutton" onClick={()=>getDetalleVentas()}><img src={download} alt="" />Detalle ventas</button>
              </div>
            )}
            {step === 5 && (
              <div className="actions">
                <button className="main-button" onClick={() => openCloseModals(true, setOpenEntradas)}>Ver entradas</button>
                <button className="main-button" onClick={() => openCloseModals(true, setOpenSalidas)}>Ver salidas</button>
              </div>
            )}
          </div>

          {step === 1 && (
            <AsignacionEntradas
              entradasManuales={entradasManuales}
              setEntradasManuales={setEntradasManuales}
              setStep={setStep}
              tanques={tanques}
              fechaMedicion={fechaMedicion}
              horaMedicion={horaMedicion}
              openPedidos={openPedidos}
              setOpenPedidos={setOpenPedidos}
              openCloseModals={openCloseModals}
              pedidos={pedidos}
              setPedidos={setPedidos}
              viewPortHeight={viewPortHeight}
              setFechaCierre={setFechaCierre}
              selectedEds={selectedEds}
              fechaCierre={fechaCierre}
              callServiceHandler={callServiceHandler}
              pagador={pagador}
              getResumenCierre={getResumenCierre}
              disableEntradas={disableEntradas}
              isROImpersonate={isROImpersonate}
              pedidosEspeciales={pedidosEspeciales}
              setPedidosEspeciales={setPedidosEspeciales}
              selectedCierre={selectedCierre}
              selectedEstadoCierre={selectedEstadoCierre}
            />
          )}
          {step===2 && 
          <Ventas 
            ventas={ventas}
            setStep={setStep}
            selectedEds={selectedEds}
            fechaCierre={fechaCierre}
            pagador={pagador}
            callServiceHandler={callServiceHandler}
            disableVentas={disableVentas}
            isROImpersonate={isROImpersonate}
            selectedCierre={selectedCierre}
            selectedEstadoCierre={selectedEstadoCierre}
          />
          }

          {step === 3 && 
          <CalibracionesYMovBodega
            /* revisarFluctuacion={revisarFluctuacion} */
            setStep={setStep} 
            configuracion={configuracion}
            calibracionesEntradas={calibracionesEntradas}
            setCalibracionesEntradas={setCalibracionesEntradas}
            calibracionesSalidas={calibracionesSalidas}
            setCalibracionesSalidas={setCalibracionesSalidas}
            materiales={materiales}
            materialesTanques={materialesTanques}
            tanques={tanques}
            movimientosBodega={movimientosBodega}
            setMovimientosBodega={setMovimientosBodega}
            filteredTanques={filteredTanques}
            setFilteredTanques={setFilteredTanques}
            filteredDestinoTanques={filteredDestinoTanques}
            setFilteredDestinoTanques={setFilteredDestinoTanques}
            fechaCierre={fechaCierre}
            pagador={pagador}
            selectedEds={selectedEds}
            callServiceHandler={callServiceHandler}
            setMovimientosRecomendados={setMovimientosRecomendados}
            disableCalibraciones={disableCalibraciones}
            isROImpersonate={isROImpersonate}
            selectedCierre={selectedCierre}
            selectedEstadoCierre={selectedEstadoCierre}
            getTanquesDestinoRecomendados={getTanquesDestinoRecomendados}
            permiteTanqueCamion={permiteTanqueCamion}
          />}
          {step === 4 && 
            <IngresoMediciones 
              setStep={setStep} 
              selectedEds={selectedEds} 
              configuracion={configuracion} 
              mediciones={mediciones}
              setMediciones={setMediciones}
              horas={horas}
              setHoras={setHoras}
              fechaCierre={fechaCierre}
              pagador={pagador}
              callServiceHandler={callServiceHandler}
              disableMedicion={disableMedicion}
              isROImpersonate={isROImpersonate}
              selectedCierre={selectedCierre}
              selectedEstadoCierre={selectedEstadoCierre}
          />}
          {step === 5 && 
            <ConfirmacionFluctuaciones 
              token={token}
              setStep={setStep} 
              selectedEds={selectedEds} 
              configuracion={configuracion} 
              mediciones={mediciones}
              setMediciones={setMediciones}
              entradasSalidas={entradasSalidas}
              tanques={tanques}
              openCloseModals={openCloseModals}
              viewPortHeight={viewPortHeight}
              callServiceHandler={callServiceHandler}
              openEntradas={openEntradas}
              setOpenEntradas={setOpenEntradas}
              openSalidas={openSalidas}
              setOpenSalidas={setOpenSalidas}
              fluctuaciones={fluctuaciones}
              pagador={pagador}
              fechaCierre={fechaCierre}
              setFechaCierre={setFechaCierre}
              getResumenCierre={getResumenCierre}
              selectedEstadoCierre={selectedEstadoCierre}
              disableFluctuaciones={disableFluctuaciones}
              isROImpersonate={isROImpersonate}
              ventas={ventasForPaso5}
              selectedCierre={selectedCierre}
            />
          }
        </>
      )}
    </div>
  </>);
};

export default CierreDiario;
