import React, {useState} from "react";
import StepContrato from "./StepContrato";
import StepError from "./StepError";
import StepTerminos from "./StepTerminos";
import { hideGreyBackground } from "../utils/windows";
import CustomModal from "../CustomModal";

const Terminos = (props) => {

  const setDisplayTerminos = props.setDisplayTerminos;
  //const [terminosOk, setTerminosOk] = useState(false);
  const [error, setError] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  //const [emails, setEmails] = useState([]);
  //const [kunnr, setKunnr] = useState('');
  const {terminosOk,setTerminosOk,emails,setEmails,kunnr,setKunnr}=props;

  const modalStyles = {
    content: {
      width: 350,
      transform: "translate(-50%,-50%)",
      padding: "38px 20px",
      left: "50%",
      top: "50%",
      right: "auto",
      bottom: "auto",
      boxSizing: "border-box",
      borderRadius: 9,
    },
  };

  function closeModal(){
    setDisplayModal(false);
    hideGreyBackground();
  }

  function aceptarModal(){
    setDisplayModal(false)
    setError(true);
    hideGreyBackground();
  }

  return (
    <>
      {error?<StepError /> : <>
      
      {!terminosOk && 
        <StepTerminos 
          setTerminosOk={setTerminosOk} 
          setError={setError} 
          newToken={props.newToken} 
          rutConcesionario={props.rutConcesionario} 
          setDisplayModal={setDisplayModal} 
          setEmails={setEmails} 
          setKunnr={setKunnr} 
          setDisplayTerminos={setDisplayTerminos} 
          getMovimientos={props.getMovimientos}
          callServiceHandler={props.callServiceHandler}
        />
      }
      {terminosOk && 
        <StepContrato 
        setDisplayTerminos={setDisplayTerminos} 
        setError={setError} 
        terminosOk={terminosOk} 
        newToken={props.newToken} 
        rutConcesionario={props.rutConcesionario} 
        setDisplayModal={setDisplayModal} 
        kunnr={kunnr} 
        emails={emails} 
        getMovimientos={props.getMovimientos}
        callServiceHandler={props.callServiceHandler}
      />}
      
      </>}

      <CustomModal
        displayModal={displayModal}
        closeModal={closeModal}
        acceptFunc={aceptarModal}
        cancelFunc={closeModal}
        modalTitle='Importante'
        modalText='Ha rechazado los términos y condiciones del Sitio Internet para pagos y/o Anexo Web, contacte a COPEC para resolver medios de pago.'
        modalIcon='error'
      />      
    </>
  );
};

export default Terminos;
