import React, { useState, useEffect } from "react";
import Flecha_down from "../../assets/img/Flecha_down.svg";
import Flecha_up from "../../assets/img/Flecha_up.svg";
import ROJO from "../../assets/img/atencion_rojo.svg";
import {
  SingleOption,
  customStylesRegularizacionTable,
  checkSelectedOptions,
  Option,
  checkNumericSelectValue,
  getLocaleStringRoundedInt,
  orderArray
} from "../utils/utils.js";
import Select from "react-select";
import NumericSelect from "../NumericSelect";
import { allMateriales, getMaterialTitleCierre } from "../utils/materiales";
import { getAlertIconVentas, getLastNCharacters, getPaginationItems } from "../utils/get";
import Pagination from "react-js-pagination";
import CustomHighlighter from "../CustomHighlighter";

const RegularizacionTable = ({
  changeSelector,
  transacciones,
  selector,
  selectedTransaccion,
  changeSelectedTransaccion,
  filteredTransacciones,
  setFilteredTransacciones,
  allHoras,
  horaFilter,
  setHoraFilter,
  allOrigen,
  origenFilter,
  setOrigenFilter,
  allFormaC,
  formaCFilter,
  setFormaCFilter,
  allFormaP,
  formaPFilter,
  setFormaPFilter,
  allDocumentos,
  documentoFilter,
  setDocumentoFilter,
  allMateriales,
  materialFilter,
  setMaterialFilter,
  filterText,
  filterBySearchText
}) => {
  const [litrosConfirmados, setLitrosConfirmados] = useState();

  const [confirmadosFilter, setConfirmadosFilter] = useState()
  const [selectedConfirmadosFilter, setSelectedConfirmadosFilter] = useState()

  const [documentadosFilter, setDocumentadosFilter] = useState()
  const [selectedDocumentadosFilter, setSelectedDocumentadosFilter] = useState()

  const [controladorFilter, setControladorFilter] = useState()
  const [selectedControladorFilter, setSelectedControladorFilter] = useState()

  const [precioFilter, setPrecioFilter] = useState()
  const [selectedPrecioFilter, setSelectedPrecioFilter] = useState()

  const [identificadorFilter, setIdentificadorFilter] = useState()
  const [selectedIdentificadorFilter, setSelectedIdentificadorFilter] = useState()

  const [paginatedTransacciones, setPaginatedTransacciones] = useState([])

  const [activePage, setActivePage] = useState(1)

  const orderTable = (filterkey, order, parse) => {
    //const arr = orderArray(filterkey, pedidos, order, parse);
    const arr = orderArray(filterkey, filteredTransacciones, order, parse);
    setFilteredTransacciones(arr);
  };

  useEffect(() => {
    setConfirmadosFilter('')
  }, [selectedConfirmadosFilter])
  
  useEffect(() => {
    setDocumentadosFilter('')
  }, [selectedDocumentadosFilter])
  
  useEffect(() => {
    setControladorFilter('')
  }, [selectedControladorFilter])
  
  useEffect(() => {
    setPrecioFilter('')
  }, [selectedPrecioFilter])

  useEffect(() => {
    setIdentificadorFilter('')
  }, [selectedIdentificadorFilter])


  useEffect(() => {
    filter();
  }, [
    horaFilter,
    origenFilter,
    formaCFilter,
    formaPFilter,
    documentoFilter,
    materialFilter,
    confirmadosFilter,
    documentadosFilter,
    controladorFilter,
    precioFilter,
    identificadorFilter,
    filterText
  ]);

  useEffect(() => {
    if(transacciones){
      setFilteredTransacciones(transacciones);
    }
  }, [transacciones]);

  useEffect(() => {
    setActivePage(1)
    if(filteredTransacciones.length <= 15)
      setPaginatedTransacciones(filteredTransacciones)
    else
      setPaginatedTransacciones(filteredTransacciones.slice(0, 15))

  }, [filteredTransacciones])

  function handlePageChange(pageNumber){
    getPaginationItems(filteredTransacciones, pageNumber, setPaginatedTransacciones, setActivePage);
  }

  function filter(){
    let trxArr = [];
    checkSelectedOptions(horaFilter, setHoraFilter, allHoras);
    checkSelectedOptions(origenFilter, setOrigenFilter, allOrigen);
    checkSelectedOptions(formaCFilter, setFormaCFilter, allFormaC);
    checkSelectedOptions(formaPFilter, setFormaPFilter, allFormaP);
    checkSelectedOptions(documentoFilter, setDocumentoFilter, allDocumentos);
    checkSelectedOptions(materialFilter, setMaterialFilter, allMateriales);

    transacciones?.forEach((trx) => {
      let found = true;

      if (horaFilter.length > 0) {
        if (!horaFilter.some((e) => e.value === trx.HORA)) found = false;
      }

      if (origenFilter.length > 0) {
        if (!origenFilter.some((e) => e.value === trx.ORIGEN_VENTA)) found = false;
      }

      if (formaCFilter.length > 0) {
        if (!formaCFilter.some((e) => e.value === trx.FORMA_COMERCIALIZACION)) found = false;
      }

      if (formaPFilter.length > 0) {
        if (!formaPFilter.some((e) => e.value === trx.FORMA_PAGO)) found = false;
      }

      if (documentoFilter.length > 0) {
        if (!documentoFilter.some((e) => e.value === trx.DOCUMENTO_VENTA)) found = false;
      }

      if (materialFilter.length > 0) {
        if (!materialFilter.some((e) => e.value === trx.MATERIAL)) found = false;
      }

      if (selectedConfirmadosFilter !== "Todos" && confirmadosFilter && confirmadosFilter !== "" && trx[selector]){
        if(!checkNumericSelectValue(confirmadosFilter, selectedConfirmadosFilter, trx[selector].confirmados)){
          found = false
        }
      }

      if (selectedDocumentadosFilter !== "Todos" && documentadosFilter && documentadosFilter !== "" && trx[selector]){
        if(!checkNumericSelectValue(documentadosFilter, selectedDocumentadosFilter, trx[selector].documentados)){
          found = false
        }
      }

      if (selectedControladorFilter !== "Todos" && controladorFilter && controladorFilter !== "" && trx[selector]){
        if(!checkNumericSelectValue(controladorFilter, selectedControladorFilter, trx[selector].controlador)){
          found = false
        }
      }

      if (selectedPrecioFilter !== "Todos" && precioFilter && precioFilter !== ""){
        if(!checkNumericSelectValue(precioFilter, selectedPrecioFilter, trx.PRECIO)){
          found = false
        }
      }

      if (selectedIdentificadorFilter !== "Todos" && identificadorFilter && identificadorFilter !== ""){
        if(!checkNumericSelectValue(identificadorFilter, selectedIdentificadorFilter, trx.ID_TRANSACCION)){
          found = false
        }
      }
      
      if (found) trxArr.push(trx);
    });

    filterBySearchText(trxArr)
  }

  useEffect(() => {
    console.log("PAGINATED",paginatedTransacciones)
  }, [paginatedTransacciones]);

  return (
    <div className="regularizacion-table-container">
      <table className="regularizacion-table regularizacion-table-with-custom-highlighter">
        <div className="table-header">
          <div className="selector">
            <div className="text">LITROS</div>
            <div className="switch" onClick={() => changeSelector()}>
              <input type="checkbox" id="table-selector" />
              <span className="slider round"></span>
            </div>
            <div className="text">PESOS</div>
          </div>
        </div>
        <tr>
          <th>
            <div className="th-data">
              <div className="th-text">
                Identificad...
                <div className="arrows">
                  <img
                    onClick={() => orderTable("ID_TRANSACCION", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("ID_TRANSACCION", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Hora
                <div className="arrows">
                  <img
                    onClick={() => orderTable("HORA", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("HORA", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Origen Venta
                <div className="arrows mtop">
                  <img
                    onClick={() => orderTable("ORIGEN_VENTA", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("ORIGEN_VENTA", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Forma comercial...
                <div className="arrows mtop">
                  <img
                    onClick={() =>
                      orderTable("FORMA_COMERCIALIZACION", "asc", false)
                    }
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() =>
                      orderTable("FORMA_COMERCIALIZACION", "desc", false)
                    }
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Forma de pago
                <div className="arrows mtop">
                  <img
                    onClick={() => orderTable("FORMA_PAGO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("FORMA_PAGO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Documento de venta
                <div className="arrows mtop">
                  <img
                    onClick={() => orderTable("DOCUMENTO_VENTA", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("DOCUMENTO_VENTA", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Material
                <div className="arrows">
                  <img
                    onClick={() => orderTable("MATERIAL", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("MATERIAL", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Precio
                <div className="arrows">
                  <img
                    onClick={() => orderTable("PRECIO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("PRECIO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Controlad...
                <div className="arrows">
                  <img
                    onClick={() => orderTable("CONTROLADOR", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("CONTROLADOR", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Document...
                <div className="arrows">
                  <img
                    onClick={() => orderTable("DOCUMENTADO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("DOCUMENTADO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Confirmad...
                <div className="arrows">
                  <img
                    onClick={() => orderTable("CONFIRMADO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("CONFIRMADO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data th-select">
              <div className="th-text">Alerta</div>
            </div>
          </th>
          <th>
            <div className="th-data th-select">
              <div className="th-text">Selecc.</div>
            </div>
          </th>
        </tr>
        <tr>
          <td>
            <NumericSelect
              name="ns-regularizacion-identificador"
              value={identificadorFilter}  
              setValue={setIdentificadorFilter}
              selected={selectedIdentificadorFilter}
              setSelected={setSelectedIdentificadorFilter}
              className={identificadorFilter === "" ? "active" : ""}
              customClass="regularizacion-table"
            />
          </td>
          <td>
            <Select
              className="cd-mb-select-last regularizacion-table-select"
              value={horaFilter}
              onChange={(e) => setHoraFilter(e)}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={allHoras}
              components={{
                Option,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              isClearable={false}
              autosize={false}
              placeholder="Seleccion"
              styles={customStylesRegularizacionTable}
              isMulti
            />
          </td>
          <td>
            <Select
              className="cd-mb-select-last regularizacion-table-select"
              value={origenFilter}
              onChange={(e) => setOrigenFilter(e)}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={allOrigen}
              components={{
                Option,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              isClearable={false}
              autosize={false}
              placeholder="Seleccion"
              styles={customStylesRegularizacionTable}
              isMulti
            />
          </td>
          <td>
            <Select
              className="cd-mb-select-last regularizacion-table-select"
              value={formaCFilter}
              onChange={(e) => setFormaCFilter(e)}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={allFormaC}
              components={{
                Option,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              isClearable={false}
              autosize={false}
              placeholder="Seleccion"
              styles={customStylesRegularizacionTable}
              isMulti
            />
          </td>
          <td>
            <Select
              className="cd-mb-select-last regularizacion-table-select"
              value={formaPFilter}
              onChange={(e) => setFormaPFilter(e)}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={allFormaP}
              components={{
                Option,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              isClearable={false}
              autosize={false}
              placeholder="Seleccion"
              styles={customStylesRegularizacionTable}
              isMulti
            />
          </td>
          <td>
            <Select
              className="cd-mb-select-last regularizacion-table-select"
              value={documentoFilter}
              onChange={(e) => setDocumentoFilter(e)}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={allDocumentos}
              components={{
                Option,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              isClearable={false}
              autosize={false}
              placeholder="Seleccion"
              styles={customStylesRegularizacionTable}
              isMulti
            />
          </td>
          <td>
            <Select
              className="cd-mb-select-last regularizacion-table-select"
              value={materialFilter}
              onChange={(e) => setMaterialFilter(e)}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              options={allMateriales}
              components={{
                Option,
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              isClearable={false}
              autosize={false}
              placeholder="Seleccion"
              styles={customStylesRegularizacionTable}
              isMulti
            />
          </td>
          <td>
            <NumericSelect
              name="ns-regularizacion-precio"
              value={precioFilter}  
              setValue={setPrecioFilter}
              selected={selectedPrecioFilter}
              setSelected={setSelectedPrecioFilter}
              className={precioFilter === "" ? "active" : ""}
              customClass="regularizacion-table"
            />
          </td>
          <td>
            <NumericSelect
              name="ns-regularizacion-controlador"
              value={controladorFilter}  
              setValue={setControladorFilter}
              selected={selectedControladorFilter}
              setSelected={setSelectedControladorFilter}
              className={controladorFilter === "" ? "active" : ""}
              customClass="regularizacion-table"
            />
          </td>
          <td>
            <NumericSelect
              name="ns-regularizacion-documentados"
              value={documentadosFilter}  
              setValue={setDocumentadosFilter}
              selected={selectedDocumentadosFilter}
              setSelected={setSelectedDocumentadosFilter}
              className={documentadosFilter === "" ? "active" : ""}
              customClass="regularizacion-table"
            />
          </td>
          <td>
            <NumericSelect
              name="ns-regularizacion-confirmados"
              value={confirmadosFilter}  
              setValue={setConfirmadosFilter}
              selected={selectedConfirmadosFilter}
              setSelected={setSelectedConfirmadosFilter}
              className={confirmadosFilter === "" ? "active" : ""}
              customClass="regularizacion-table"
            />
          </td>
        </tr>
        <tr className="table-separator-row-border-top">
          <td />
        </tr>
        <tbody>
          {transacciones ? (
            <>
              {transacciones.length > 0 ? (
                <>
                  {paginatedTransacciones.map((t) => {
                    return (
                      <tr className={`data ${t.ID_TRANSACCION === selectedTransaccion?.ID_TRANSACCION? 'selected-row': ''}`}>
                        <td className="align-left nowrap">
                                        
                          <CustomHighlighter text={filterText}> 
                            {t.ID_TRANSACCION?getLastNCharacters(t.ID_TRANSACCION,11):""}
                          </CustomHighlighter>
                        </td>
                        <td className="align-left">
                          <CustomHighlighter text={filterText}>
                            {t.HORA?t.HORA:""}
                          </CustomHighlighter>
                        </td>
                        <td className="align-left">
                          <CustomHighlighter text={filterText}>
                            {t.ORIGEN_VENTA?t.ORIGEN_VENTA:""}
                          </CustomHighlighter>
                        </td>
                        <td className="align-left">
                          <CustomHighlighter text={filterText}>
                            {t.FORMA_COMERCIALIZACION?t.FORMA_COMERCIALIZACION:""}
                          </CustomHighlighter>
                        </td>
                        <td className="align-left">
                          <CustomHighlighter text={filterText}>
                            {t.FORMA_PAGO?t.FORMA_PAGO:""}
                          </CustomHighlighter>
                        </td>
                        <td className="align-left">
                          <CustomHighlighter text={filterText}>
                            {t.DOCUMENTO_VENTA?t.DOCUMENTO_VENTA:""}
                          </CustomHighlighter>
                        </td>
                        <td className="align-left">
                          <CustomHighlighter text={filterText}>
                            {t.MATERIAL?t.MATERIAL:""}
                          </CustomHighlighter>
                        </td>
                        <td className="align-right">
                          <CustomHighlighter text={filterText}>
                            {'$ ' + getLocaleStringRoundedInt(t.PRECIO).toString()}
                          </CustomHighlighter>
                        </td>
                        <td className="align-right">
                          <CustomHighlighter text={filterText}>
                            {(selector==='PESOS'? '$ ':'') + getLocaleStringRoundedInt(t[selector]?.controlador).toString()}
                          </CustomHighlighter>
                        </td>
                        <td className="align-right">
                          <CustomHighlighter text={filterText}>
                            {(selector==='PESOS'? '$ ':'') + getLocaleStringRoundedInt(t[selector]?.documentados).toString()}
                          </CustomHighlighter>
                        </td>
                        <td className="align-right">
                          <CustomHighlighter text={filterText}>
                            {(selector==='PESOS'? '$ ':'') + getLocaleStringRoundedInt(t[selector]?.confirmados).toString()}
                          </CustomHighlighter>
                        </td>
                        <td className="align-left options alert-container-regularizacion">
                          {getAlertIconVentas(t.ALERTA)}
                          <div className="alert-tooltip">
                            <div className="content">
                            {t.INFO}
                            </div>
                          </div>
                        </td>
                        <td className="align-left options">
                          <input
                            type="radio"
                            name="selected-pedido-check"
                            value={t.ID_TRANSACCION}
                            checked={
                              t.ID_TRANSACCION ===
                              selectedTransaccion?.ID_TRANSACCION
                            }
                            disabled={t.DISABLE_REG===1}
                            onChange={(e) => changeSelectedTransaccion(t)}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <>
                  <tr className="data">
                    <td className="align-left" colSpan="100%">
                      La búsqueda no arrojó resultados. Por favor inténtalo de
                      nuevo.
                    </td>
                  </tr>
                </>
              )}
            </>
          ) : (
            <>
              <tr className="data">
                <td className="align-left" colSpan="100%">
                  Realiza una búsqueda por fecha.
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
      <div className="pagination-container">
        <Pagination 
          activePage={activePage}
          itemsCountPerPage={15}
          totalItemsCount={filteredTransacciones?.length}
          pageRangeDisplayed={5}
          onChange={e => handlePageChange(e)}
          hideFirstLastPages={true}
          linkClassPrev='prev'
          linkClassNext='next'
          prevPageText=''
          nextPageText=''
        />
      </div>
    </div>
  );
};

export default RegularizacionTable;
