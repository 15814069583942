import React, { useState, useEffect,useRef } from "react";
import Select from "react-select";
import HeaderData from "../HeaderData";
import { getConfiguracion, getEdsPedidosActivos, getTanquesOfPedidoEspecial, setInstalacionesDinamo, setPagadoresArcoprime } from "../utils/get";
import { getPeriodosSince, getPeriodosSinceYearAndMonth, getUpdateDate } from "../utils/monthNames";
import {
  customStyles,
  parseBudat,
  setMaterialesYTanques,
  SingleOption,
  getAproximate3FloatString,
  getLocaleStringFloat,
  get3DecimalsFloatWithDelimiter,
  getLocaleStringRoundedInt,
  parseDateString,
  getExcelWithXlsx2
} from "../utils/utils";
import {
  displayLoader,
  hideGreyBackground,
  hideLoader,
  parentRedirect,
  scrollTo,
  showGreyBackground,
} from "../utils/windows";
import CierreDiarioTable from "./CierreDiarioTable";

const Reapertura = ({
  token,
  rutConcesionario,
  viewPortHeight,
  callServiceHandler,
  pagador,
  instalaciones,
  cabecera,
  params,
  isROImpersonate,
  edsPortal
}) => {
  const [stepLoaded, setStepLoaded] = useState([]);
  const [updateDate, setUpdateDate] = useState("");
  const [allEds, setAllEds] = useState([]);
  const [selectedEds, setSelectedEds] = useState();
  const [fechaCierre, setFechaCierre] = useState();
  const [selectedEstadoCierre, setSelectedEstadoCierre] = useState();

  const [configuracion, setConfiguracion] = useState();

  const [fechaMedicion, setFechaMedicion] = useState();
  const [horaMedicion, setHoraMedicion] = useState();
  const [entradasManuales, setEntradasManuales] = useState([]);
  const [tanques, setTanques] = useState([]);
  const [pedidos, setPedidos] = useState();
  const [openPedidos, setOpenPedidos] = useState(false);
  const [lastUrlPedidos, setLastUrlPedidos] = useState("");
  const [materiales, setMateriales] = useState([]);
  const [materialesTanques, setMaterialesTanques] = useState([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState(null);
  const [allPeriodos, setAllPeriodos] = useState([]);
  const tableRef = useRef(null);
  const [cierres, setCierres] = useState();
  const [cierresOriginal, setCierresOriginal] = useState([]);
  const [ventas, setVentas] = useState(null);
  const [mediciones, setMediciones] = useState({})
  const [horas, setHoras] = useState({})
  const [selectedCierre, setSelectedCierre] = useState();

  //paso 3
  const [calibracionesEntradas, setCalibracionesEntradas] = useState({})
  const [calibracionesSalidas, setCalibracionesSalidas] = useState({})
  const [calibracionesRecomendadas, setCalibracionesRecomendadas] = useState({})
  const [movimientosBodega, setMovimientosBodega] = useState([])
  const [movimientosRecomendados, setMovimientosRecomendados] = useState([])
  const [filteredTanques, setFilteredTanques] = useState({})
  const [filteredDestinoTanques, setFilteredDestinoTanques] = useState({})
  const [lastUrlVentasCamiones, setLastUrlVentasCamiones] = useState("")
  const [lastUrlVentasTanques, setLastUrlVentasTanques] = useState("")
  const [ventasTanques, setVentasTanques] = useState({})
  const [entradasSalidas, setEntradasSalidas] = useState()

  const [openEntradas, setOpenEntradas] = useState(false)
  const [openSalidas, setOpenSalidas] = useState(false)

  const [fechaParams, setFechaParams] = useState();
  const [fechaParamsUsed, setFechaParamsUsed] = useState(false);

  const [fluctuaciones, setFluctuaciones] = useState();

  const [detalleVentas,setDetalleVentas] = useState();
  const [labelsVentas,setLabelsVentas] = useState()
  const [disableEntradas, setDisableEntradas] = useState(false)
  const [disableVentas, setDisableVentas] = useState(false)
  const [disableCalibraciones, setDisableCalibraciones] = useState(false)
  const [disableMedicion, setDisableMedicion] = useState(false)
  const [disableFluctuaciones, setDisableFluctuaciones] = useState(false)
  const [requestCounter, setRequestCounter] = useState(0)
  const [pedidosEspeciales, setPedidosEspeciales] = useState();

  const [ventasForPaso5, setVentasForPaso5] = useState();
  const [esPosibleCerrar, setEsPosibleCerrar] = useState();

  const [anioActivacion, setAnioActivacion] = useState();
  const [mesActivacion, setMesActivacion] = useState();

  const [isArcoprime, setIsArcoprime] = useState(false);
  const [allPagadores, setAllPagadores] = useState([]);
  const [selectedPagador, setSelectedPagador] = useState();

  const stepNames=[
    "Entradas",
    "Ventas",
    "Calibraciones y Mov. Bodega",
    "Medición Física",
    "Fluctuaciones"
  ]

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (token && rutConcesionario) {
        if(params){
          const paramsArr = params.split("&");
          let fecha = paramsArr[1].split("=")[1];
          setFechaParams(fecha);
        }
        if (rutConcesionario === "77215640-5") {
          setIsArcoprime(true);
        }
      }
    } else {
      if (token) {
        if(params){
          const paramsArr = params.split("&");
          let fecha = paramsArr[1].split("=")[1];
          setFechaParams(fecha);
        }
        if (rutConcesionario === "77215640-5" /* || true */) {
          setIsArcoprime(true);
        }
      }
    }
  }, [token, rutConcesionario]);


  useEffect(async () => {
    if (token) {
      const url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/listar-eds"

      let response = await callServiceHandler(url, "GET")
      if(response !== "error"){
        if (response.data) {
          setAllEds(response.data)
        }
      }
    }
  }, [token]);

  useEffect(() => {
    getUpdateDate(setUpdateDate);
  }, []);

  useEffect(() => {
    if (allEds.length === 1){
      setSelectedEds(allEds[0]);
    } else{
      if(params){
        const paramsArr = params.split("&");
        const edsParam = paramsArr[0].split("=")[1];
        setSelectedEds(allEds.filter(eds=>eds.value===edsParam)[0])
      }
    }
  }, [allEds]);

  useEffect(() => {
    if(edsPortal){
      setPagadoresArcoprime(edsPortal.data.user_eds,setAllPagadores);
    }
  }, [edsPortal]);

  useEffect(() => {
    if (allPagadores.length === 1) {
      changePagador(allPagadores[0]);
    }
  }, [allPagadores]);

  function changePagador(e) {
    setSelectedPagador(e);
  }

  useEffect(() => {
    if(selectedEds){
      getFechaActivacion()
    }
  }, [selectedEds]);

  useEffect(() => {
    if (anioActivacion && mesActivacion) {
      const newPeriodos = getPeriodosSinceYearAndMonth(anioActivacion, 
        mesActivacion);
      setAllPeriodos(newPeriodos);
      setSelectedPeriodo(newPeriodos[0]);
    }
  }, [anioActivacion, mesActivacion]);

  async function getFechaActivacion() {

    const url =
    process.env.REACT_APP_FLUCTUACIONES_API +
    "/fluctuaciones/cierre-diario-listar-periodos?ID_EDS=" +
    selectedEds.value + 
    "&ID_PAGADOR=" + pagador;

    let response = await callServiceHandler(url, "GET");

    if (response !== "error") {
      setAnioActivacion(parseInt(response.data.año_activacion))
      setMesActivacion(parseInt(response.data.mes_activacion))
    }
  }

  async function getResumenCierre() {
    displayLoader();
    const url =
        process.env.REACT_APP_FLUCTUACIONES_API +
        "/fluctuaciones/resumen-cierre-diario?ID_EDS=" +
        selectedEds.value +
        "&PERIODO="+selectedPeriodo.value+"&ID_PAGADOR="+pagador;
    
    let response = await callServiceHandler(url, "GET");
    if(response !== "error"){
      let primerAbierto = 0;
      let primerAbiertoFound=false;
      const cierresOrdenadosReversos = response.data.cierres.reverse().map((cierre,index)=>{
        if(!primerAbiertoFound){
          if(cierre.estado_cierre==="ABIERTO"||cierre.estado_cierre==="RE ABIERTO"){
            primerAbiertoFound=true;
          }
        }else{
          cierre.blocked=true
        }
        return cierre
      })
      const cierresProcesados = cierresOrdenadosReversos.map(c=>c).reverse();
      setCierres(cierresProcesados);
      setCierresOriginal(cierresProcesados);
      setEsPosibleCerrar(response.data.posible_cerrar)
    }
  }

  async function getDetalleVentas(){
    const url =
    process.env.REACT_APP_FLUCTUACIONES_API +
    "/fluctuaciones/cierre-diario-listado-transacciones?ID_EDS=" +
    selectedEds.value +
    "&FECHA="+fechaCierre+"&ID_PAGADOR="+pagador+"&TIPO=3"+"&RUT="+rutConcesionario;

    let response = await callServiceHandler(url, "GET");

    let docName = `Detalle de Ventas - ${parseInt(selectedEds.value)} - ${parseBudat(fechaCierre)}.xlsx`

    getExcelWithXlsx2(response.data.hojas, docName)
  }

  useEffect(() => {
    if(selectedEds && selectedPeriodo){
      getResumenCierre();
    }
  }, [selectedEds, selectedPeriodo]);

  async function fetchVentas(){
    const url =
      process.env.REACT_APP_FLUCTUACIONES_API +
      "/fluctuaciones/cierre-diario-ventas?ID_EDS=" +
      selectedEds.value +
      "&FECHA="+fechaCierre+ "&ID_PAGADOR=" + pagador+"&RUT="+rutConcesionario;

    let response = await callServiceHandler(url, "GET");
    if(response!=="error"){
      setDisableVentas(false)
      const newDetalle = []
      const gas93 = response.data?.detalle.filter(v => v.material === "GAS 93")
      if(gas93)
        gas93.forEach(e=>newDetalle.push(e))

      const gas95 = response.data?.detalle.filter(v => v.material === "GAS 95")
      if(gas95)
        gas95.forEach(e=>newDetalle.push(e))

      const gas97 = response.data?.detalle.filter(v => v.material === "GAS 97")
      if(gas97)
        gas97.forEach(e=>newDetalle.push(e))

      const diesel = response.data?.detalle.filter(v => v.material === "DIESEL")
      if(diesel)
        diesel.forEach(e=>newDetalle.push(e))

      const kerosene = response.data?.detalle.filter(v => v.material === "KEROSENE")
      if(kerosene)
        kerosene.forEach(e=>newDetalle.push(e))

      const glp = response.data?.detalle.filter(v => v.material === "GLP")
      if(glp)
        glp.forEach(e=>newDetalle.push(e))

      response.data.detalle = newDetalle
      setVentas(response.data)
    }
    else{
      setDisableVentas(true)
    }
  }

  async function fetchVentasRecomendadas() {
    setMovimientosRecomendados([])
    setCalibracionesEntradas({})
    setCalibracionesSalidas({})
    setCalibracionesRecomendadas({})
    setVentasTanques({})
    setMovimientosBodega([])
    displayLoader();
    const query_params = "?ID_EDS=" + selectedEds.value + "&fecha="+parseBudat(fechaCierre)+"&ID_PAGADOR="+pagador+"&RUT="+rutConcesionario 
    let url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/cierre-diario-calibraciones-bodegas" + query_params

      let tmpVentasTanques = {}
      let tmpMovimientosBodega = []
      let tmpCalibraciones = {}

      let response = await callServiceHandler(url, "GET")
      if(response !== "error"){
        setDisableCalibraciones(false);
        ////BEGIN TEST
        tmpMovimientosBodega = response.data.MOVIMIENTOS_BODEGA
        let pasoraro = response.data

        if(response.data.ESTADO_PASO === "CERRADO" || response.data.ESTADO_PASO==="REABIERTO"){
          tmpMovimientosBodega = response.data.INFORMACION_PASO.movimientosBodega
          tmpMovimientosBodega = tmpMovimientosBodega.map((mov) => {
            const tanqueOrigen = tanques.find(t => t.value === mov.tank_group)
            return {
              id:mov.id,
              material: mov.material,
              tanqueOrigen: mov.tanqueOrigen,
              tanqueDestino: '',
              volumen: mov.volumen,
              tipo: mov.tipo,
            }
          });
        }else{
          tmpMovimientosBodega = tmpMovimientosBodega.map((mov) => {
            const tanqueOrigen = tanques.find(t => t.value === mov.tank_group)
            return {
              id:mov.id,
              material: mov.material,
              tanqueOrigen: tanqueOrigen,
              tanqueDestino: '',
              volumen: mov.volume,
              tipo: mov.tipo,
            }
          });
        }
        

        setMovimientosRecomendados(tmpMovimientosBodega)
        ////END TEST

        if(response.data.ESTADO_PASO==="CERRADO" || response.data.ESTADO_PASO==="REABIERTO"){
          setCalibracionesEntradas(response.data.INFORMACION_PASO.calibracionesEntradas);
          setCalibracionesSalidas(response.data.INFORMACION_PASO.calibracionesSalidas);
          setMovimientosBodega(response.data.INFORMACION_PASO.movimientosBodega);
        }else{
          tmpVentasTanques = response.data.VENTAS
          
          tmpCalibraciones = response.data.CALIBRACIONES
          for (const tanque of configuracion.TANQUES) {
            if (tanque.TIPO.toLowerCase() === "tanque" 
              && (!(tanque.ID in tmpVentasTanques) || (tanque.ID in tmpVentasTanques && tmpVentasTanques[tanque.ID] === 0)))
            {
              tmpVentasTanques[tanque.ID] = '0'
            }
            else{
              tmpVentasTanques[tanque.ID] = getLocaleStringFloat(tmpVentasTanques[tanque.ID])
            }
          }

          setVentasTanques(tmpVentasTanques)
          setCalibracionesRecomendadas(tmpCalibraciones)
        }
        
      }
      else{
        setDisableCalibraciones(true);
      }
    // Ventas camiones
    url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/recomendar-salidas-camiones" + query_params
      if (lastUrlVentasCamiones !== url) {
        setLastUrlVentasCamiones(url)

        let tmpVentasCamiones = {}
      }
  }

  async function fetchTelemedicion(){
    let url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/cierre-diario-obtener-telemediciones?ID_EDS=" 
    + selectedEds.value +"&FECHA=" + parseBudat(fechaCierre)+"&ID_PAGADOR="+pagador+"&RUT="+rutConcesionario;

    let telemedicion = {}
    let horas = {}

    let response = await callServiceHandler(url, "GET")
    if(response !== "error"){
      setDisableMedicion(false)
      if(response.data.ESTADO_PASO === "CERRADO" || response.data.ESTADO_PASO==="REABIERTO"){
        setMediciones(response.data.INFORMACION_PASO.mediciones)
      }else{
        telemedicion = response.data.TELEMEDICIONES;
        horas = response.data.FECHA_MEDICION
        let tmpMediciones = {};

        const promises = []
        displayLoader()

        for (const tanque of configuracion.TANQUES) {
          if(!tmpMediciones[tanque.ID])
            tmpMediciones[tanque.ID] = {
              ID_MATERIAL: tanque.ID_MATERIAL,
              MATERIAL: tanque.MATERIAL,
              SIFON_1: tanque.SIFON_1,
              SIFON_2: tanque.SIFON_2
            }
          if(tanque.ID in telemedicion){
            tmpMediciones[tanque.ID].medicion = getLocaleStringRoundedInt(telemedicion[tanque.ID]);
          }
          else{
            tmpMediciones[tanque.ID].medicion = '0' 
          }
          if(tanque.ID in horas){
            tmpMediciones[tanque.ID].hora = horas[tanque.ID].split(" ")[1].substring(0,5);
          }
          else{
            tmpMediciones[tanque.ID].hora = '23:59'
          }

          if(tanque.TIPO === 'camion'){
            tmpMediciones[tanque.ID].hora = '23:59'
            tmpMediciones[tanque.ID].entrada_pos_medicion = 0
            tmpMediciones[tanque.ID].salida_pos_medicion = 0
            tmpMediciones[tanque.ID].total = calculateTotalMedicion(0, tmpMediciones[tanque.ID].medicion, 0)
          }

          else{
            const url =
              process.env.REACT_APP_FLUCTUACIONES_API +
              "/fluctuaciones/cierre-diario-buscar-medicion-proyectada?ID_EDS=" + selectedEds.value + 
              "&FECHA=" + fechaCierre + 
              "&ID_PAGADOR=" + pagador + 
              "&TANQUE=" + tanque.ID + 
              "&MATERIAL=" + mediciones[tanque.ID].ID_MATERIAL + 
              "&HORA=" + tmpMediciones[tanque.ID].hora.replace(':','') + "00" +
              "&RUT="+rutConcesionario;          

            promises.push(callServiceHandler(url, "GET", null, false).then(response => {
              if(response !== "error"){
                tmpMediciones[tanque.ID].entrada_pos_medicion = response.data.entrada_pos_medicion
                tmpMediciones[tanque.ID].salida_pos_medicion = response.data.salida_pos_medicion
                tmpMediciones[tanque.ID].total = calculateTotalMedicion(response.data.entrada_pos_medicion, tmpMediciones[tanque.ID].medicion, response.data.salida_pos_medicion)
              }
            }))
          }
        }

        await Promise.all(promises)
        hideLoader()

        setMediciones(tmpMediciones);  
      }
    }else{
      setDisableMedicion(true)
    }
  }

  function calculateTotalMedicion(entrada, medicion, salida){
    entrada = parseFloat(entrada) || 0
    salida = parseFloat(salida) || 0
    
    if(medicion !== null && medicion !== undefined && medicion !== false){
      medicion = medicion.replace(/\./g, "")
      medicion = medicion.replace(/\,/g, '.')
    }
    
    medicion = parseFloat(medicion) || 0
    return entrada + medicion - salida
  }

  async function fetchFluctuaciones(){
    displayLoader()
    let url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/cierre-diario-fluctuaciones?"
      + "ID_EDS=" + selectedEds.value
      + "&FECHA=" + fechaCierre
      + "&ID_PAGADOR=" + pagador
      + "&RUT=" + rutConcesionario

    let response = await callServiceHandler(url, "GET")
    if(response !== "error"){
      setDisableFluctuaciones(false)
      const newFluctuaciones = []

      const gas93 = response.data?.detalle.find(v => v.material.toLowerCase() === "gas 93")
      if(gas93)
        newFluctuaciones.push(gas93)

      const gas95 = response.data?.detalle.find(v => v.material.toLowerCase() === "gas 95")
      if(gas95)
        newFluctuaciones.push(gas95)

      const gas97 = response.data?.detalle.find(v => v.material.toLowerCase() === "gas 97")
      if(gas97)
        newFluctuaciones.push(gas97)

      const diesel = response.data?.detalle.find(v => v.material.toLowerCase() === "diesel")
      if(diesel)
        newFluctuaciones.push(diesel)

      const kerosene = response.data?.detalle.find(v => v.material.toLowerCase() === "kerosene")
      if(kerosene)
        newFluctuaciones.push(kerosene)

      const glp = response.data?.detalle.find(v => v.material.toLowerCase() === "glp")
      if(glp)
        newFluctuaciones.push(glp)

      console.log("rdv",response.data.ventas)
      setFluctuaciones(newFluctuaciones)
      setEntradasSalidas(response.data?.entradas_salidas)
      setVentasForPaso5(response.data.ventas)
    }
    else{
      setDisableFluctuaciones(true)
    }
    hideLoader()
  }

  function openCloseModals(isOpen, modalFunc) {
    modalFunc(isOpen);
    if (isOpen) {
      showGreyBackground();
      scrollTo(0, 50);
    } else {
      hideGreyBackground();
    }
  }

  useEffect(() => {
    if (selectedEds) {
      getConfiguracion(callServiceHandler, selectedEds.value, setConfiguracion);
    }
  }, [selectedEds]);

  useEffect(() => {
    if (configuracion) {
      setMaterialesYTanques(configuracion.TANQUES, setTanques, setMateriales, setMaterialesTanques);

      let tmpMediciones = {};

      for (const tanque of configuracion.TANQUES) {
        if(!tmpMediciones[tanque.ID])
          tmpMediciones[tanque.ID] = {
            ID_MATERIAL: tanque.ID_MATERIAL,
            MATERIAL: tanque.MATERIAL,
            SIFON_1: tanque.SIFON_1,
            SIFON_2: tanque.SIFON_2
          }
      }
      setMediciones(tmpMediciones);
    }
  }, [configuracion]);

  function changeSelectedPeriodo(e) {
    setSelectedPeriodo(e);
  }

  useEffect(() => {
    let tmpEntradas = {}
    let tmpSalidas = {}
    for (const key in calibracionesRecomendadas) {
      tmpEntradas[key] = getAproximate3FloatString(calibracionesRecomendadas[key]["entrada"])
      tmpSalidas[key] = getAproximate3FloatString(calibracionesRecomendadas[key]["salida"])  
    }
    setCalibracionesEntradas(tmpEntradas)
    setCalibracionesSalidas(tmpSalidas)
  }, [calibracionesRecomendadas])

  useEffect(() => {
    const tmpMovimientosBodega = []
    const tempTanques = {}

    movimientosRecomendados.forEach(function(mov, key){
      tmpMovimientosBodega.push(mov)
      const newTanques = tanques.filter(t => t.idMaterial === mov.material.value)
      tempTanques[key] = newTanques
    })

    tmpMovimientosBodega.map((mov, key) => {
      const tanquesDestino = tempTanques[key]?.filter(t => t !== tmpMovimientosBodega[key].tanqueOrigen) || []
      if(tanquesDestino.length === 1){
        tmpMovimientosBodega[key].tanqueDestino = tanquesDestino[0]
      }
    })

    setMovimientosBodega(tmpMovimientosBodega)
    setFilteredTanques(tempTanques)
  }, [movimientosRecomendados]);

  useEffect(() => {
    console.log("movimiento de bodega en cierre", movimientosBodega)
  }, [movimientosBodega]);

  useEffect(() => {
    const tempTanques = {}

    movimientosRecomendados.forEach(function(mov, key){
      const newTanques = tanques.filter(t => t.idMaterial === mov.material.value)
      tempTanques[key] = newTanques
    })

    setFilteredTanques(tempTanques)
  }, [movimientosBodega]);

  function getTanquesDestino(key){
    const tmpTanques = filteredTanques[key]?.filter(t => (t.value !== movimientosBodega[key].tanqueOrigen?.value && t.tipo === 'tanque')  ) || []
    return tmpTanques
  }

  function getTanquesDestinoRecomendados(key){
    let tmpTanques = []
    if(movimientosBodega[key].tipo==="STORAGE"){
      tmpTanques = filteredTanques[key]?.filter(t => (t.value !== movimientosBodega[key].tanqueOrigen?.value && t.label === 'TAE_RE')  ) || []
      tmpTanques.push({label:"REVISAR",value:"REVISAR"})
    }else{
      tmpTanques = filteredTanques[key]?.filter(t => (t.value !== movimientosBodega[key].tanqueOrigen?.value && t.tipo === 'camion')  ) || []
    }
    return tmpTanques
  }

  function printInfo(){
    console.log("VENTAS TANQUES",ventasTanques)
    console.log("CALIBRACIONES RECOMENDADAS",calibracionesRecomendadas);
    console.log("MOVIMIENTOS RECOMENDADOS",movimientosRecomendados);
    console.log("MOVIMIENTOS BODEGA",movimientosBodega)
    console.log("FILTERED TANQUES",filteredTanques)
    console.log("CALIBRACIONES ENTRADAS",calibracionesEntradas)
    console.log("CALIBRACIONES SALIDAS",calibracionesSalidas);
  }

  const getUpdateFunction = () => {
    getResumenCierre()
  }

  return (<>
  {/* <button onClick={()=>printInfo()}>PROBAR</button> */}
    <div className="page fluctuaciones-ingreso-mediciones cierre-diario">
      <div className="view-title">Cierre de Operaciones</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">
        Cierre Diario
      </div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandler={callServiceHandler}
        cabecera={cabecera}
        actualizable={true}
        updateFunction={getUpdateFunction}
      />
      
      <div className="cierre-diario-options">
        <div className="title">Cierre Diario</div>
        <hr className="title-separator"></hr>
        <div className="page-options">
          <div className="filters-container">
            {isArcoprime && 
              <div className="filter">
              <div className="filter-title required-field">
                ID DE PAGADOR
              </div>
              <div className="filter-input">
                <Select
                  className="eds-select"
                  value={selectedPagador}
                  onChange={(e) => changePagador(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allPagadores}
                  components={{
                    SingleOption,
                    IndicatorSeparator: () => null,
                  }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStyles}
                />
              </div>
            </div>
            }
            <div className="filter">
              <div className="filter-title required-field">
                ESTACIÓN DE SERVICIO
              </div>
              <div className="filter-input">
                <Select
                  className="eds-select"
                  value={selectedEds}
                  onChange={(e) => setSelectedEds(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allEds}
                  components={{
                    SingleOption,
                    IndicatorSeparator: () => null,
                  }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStyles}
                />
              </div>
            </div>
            <div className="filter">
              <div className="filter-title">PERÍODO</div>
              <div className="filter-input">
                <Select
                  className="eds-select"
                  value={selectedPeriodo}
                  onChange={(e) => changeSelectedPeriodo(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allPeriodos}
                  components={{ SingleOption, IndicatorSeparator: () => null }}
                  isClearable={false}
                  autosize={false}
                  styles={customStyles}
                />
              </div>
            </div>
          </div>
          </div>
      </div>

      <div className="cierre-estados">
        <CierreDiarioTable
          tableRef={tableRef}
          cierres={cierres}
          setFechaCierre={setFechaCierre}
          setCierres={setCierres}
          cierresOriginal={cierresOriginal}
          setSelectedEstadoCierre={setSelectedEstadoCierre}
          setStep="1"
          isROImpersonate={isROImpersonate}
          setSelectedCierre={setSelectedCierre}
          esPosibleCerrar={esPosibleCerrar}
          isReapertura={true}
          />
      </div>
    </div>
  </>);
};

export default Reapertura;
