import carrotBlue from "../../assets/img/caret-up-white.svg";

export const MobileFiltersOpenedChoiceOption = ({
  name,
  onClick,
  children,
  noButton,
}) => (
  <div className="mobile-filters__choice-view-option">
    {!noButton && (
      <button
        className="mobile-filters__choice-view-option-name"
        onClick={onClick}
      >
        {name}
      </button>
    )}
    {children}
  </div>
);

export const MobileFiltersOpenedChoice = ({
  itemTitle,
  children,
  closeView,
}) => {
  return (
    <div className="mobile-filters__choice-view">
      <p className="mobile-filters__choice-view-title">{itemTitle}</p>
      <div className="mobile-filters__choice-view-options">{children}</div>
      <div className="mobile-filters__choice-view-footer">
        <button
          className="mobile-filters__choice-view-close"
          onClick={closeView}
        >
          <img src={carrotBlue} alt="" />
        </button>
      </div>
    </div>
  );
};
