import React, { useState, useEffect, useRef, useContext } from "react";
import CartolaTable from "./CartolaTable";
import HeaderData from "./HeaderData";
import HeaderBox from "./HeaderBox";
import ChequeEnLinea from "./ChequeEnLinea/ChequeEnLinea";
import Compensar from "./Compensar";
import saldoAzulIcon from "../assets/img/saldo-azul.svg";
import arrowUp from "../assets/img/arrow-up-white.svg";
import abonoIcon from "../assets/img/abono.svg";
import cargoIcon from "../assets/img/cargo.svg";
import backHover from "../assets/img/back_hover.svg";
import backDefault from "../assets/img/back_default.svg";
import PagarEnBanco from "./PagarEnBanco/PagarEnBanco";
import PrintComponent from "./PrintComponent";
import {
  getLocaleStringNumber,
  useThrottleCallback,
  SingleOption,
  customStylesPedidos,
  pagosMock2,
} from "./utils/utils.js";
import { initializeSelectOptions, getEds } from "./utils/get";
import {
  displayLoader,
  showGreyBackground,
  hideGreyBackground,
  hideLoader
} from "./utils/windows";
import { getAllPeriodos, getUpdateDate } from "./utils/monthNames";
import ExportExcel from "./ExportExcel";
import Terminos from "./Terminos/Terminos";
import ErrorJudicial from "./Terminos/ErrorJudicial";
import CustomModal from "./CustomModal";
import Cookies from "universal-cookie";
import { MobileActionsModal } from "./MobileActionsModal";
import { ViewPortContext } from "../App";
import Select from "react-select";
import HeaderBoxV2 from "./HeaderBoxV2";

const Pagos = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedPeriodo, setSelectedPeriodo] = useState(null);
  const [productsFilter, setProductsFilter] = useState([]);
  const [tipoMovimientosFilter, setTipoMovimientosFilter] = useState([]);
  const [tipoMovimientoFilter, setTipoMovimientoFilter] = useState([]);

  const [movimientos, setMovimientos] = useState([]);
  const [movimientosOriginal, setMovimientosOriginal] = useState([]);
  const [movimientosLoaded, setMovimientosLoaded] = useState(false);
  const [totalCargos, setTotalCargos] = useState(0);
  const [totalAbonos, setTotalAbonos] = useState(0);
  const [totalSaldo, setTotalSaldo] = useState(0);
  const [totalAbonosSeleccionados, setTotalAbonosSeleccionados] = useState(0);
  const [totalCargosSeleccionados, setTotalCargosSeleccionados] = useState(0);
  const [totalAPagar, setTotalAPagar] = useState(0);
  const [clientName, setClientName] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [zone, setZone] = useState("");
  const [allEds, setAllEds] = useState([]);
  const [allEdsForTable, setAllEdsForTable] = useState([]);
  const [allPeriodos, setAllPeriodos] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [updateDate, setUpdateDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState();
  const [displayChequeEnLinea, setDisplayChequeEnLinea] = useState(false);
  const [displayCompensacion, setDisplayCompensacion] = useState(false);
  const [displayPagarEnBanco, setDisplayPagarEnBanco] = useState(false);
  const [mandatosActivos, setMandatosActivos] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [token, setToken] = useState();
  const [filteredMovimientos, setFilteredMovimientos] = useState([]);
  const [displayTerminos, setDisplayTerminos] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayJudicial, setDisplayJudicial] = useState(false);
  const [terminosOk, setTerminosOk] = useState(false);
  const [emails, setEmails] = useState([]);
  const [kunnr, setKunnr] = useState("");
  const tableRef = useRef(null);
  const [pagarErrors, setPagarErrors] = useState([]);
  const [chequeErrors, setChequeErrors] = useState([]);
  const [compensarErrors, setCompensarErrors] = useState([]);
  const [chequeMandatosError, setChequeMandatosError] = useState(false);

  const cookies = new Cookies();

  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [selectedTransactionsIds, setSelectedTransactionsIds] = useState([]);
  const [cuotasPagares, setCuotasPagares] = useState([]);
  const [showMobileScrollTopArrow, setShowMobileScrollTopArrow] = useState(false);
  const isDesktopRef = useRef(window.innerWidth > process.env.REACT_APP_MOBILE_BREAK_POINT);
  const mainContentRef = useRef(null);
  //const { bottomCoordinate, viewPortHeight } = useContext(ViewPortContext);
  const { viewPortHeight } = useContext(ViewPortContext);
  const originalMovimientos = useRef([]);
  const [pagador, setPagador] = useState("");
  const [isArcoprime, setIsArcoprime] = useState(false);
  const [selectedEdsArcoprime, setSelectedEdsArcoprime] = useState();
  const [allEdsArcoprime, setAllEdsArcoprime] = useState([]);
  const [banksOptions, setBankOptions] = useState([])
  const [bankOptionsMessage, setBankOptionsMessage] = useState([{
    MENSAJE: "",
    ACTIVO: "O",
  }]);
  const [displayWarningModal, setDisplayWarningModal] = useState(false);
  const warningModalTitle = "Aviso";
  const bottomCoordinate = 0;

  const labels = [
    ["Fecha Movimiento", "FECHA_MOVIMIENTO"],
    ["Fecha Vencimiento", "FECHA_VENCIMIENTO"],
    ["Días Vencidos", "DIAS_ATRASO"],
    ["Nº EDS", "NUMERO_EDS"],
    ["Línea Producto", "LINEA_PRODUCTO"],
    ["Tipo Movimiento", "TIPO_DOCUMENTO"],
    ["Nº Documento", "NUMERO_DOCUMENTO"],
    ["Cargos", "CARGO"],
    ["Abonos", "ABONO"],
  ];

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (props.token && props.rutConcesionario) {
        setToken(props.token);
       
        consultarMandato();
        getBancosAndMessage();
       
        if (props.rutConcesionario === "77215640-5") {
          setIsArcoprime(true);
          getFiliales(props.token);
        } else {
          getTerminos(props.token);
          getMovimientos(props.token, true);
        }
      }
    } else {
      if (props.token) {
        setToken(props.token);
       
        consultarMandato();
        getBancosAndMessage();
        
        if (props.rutConcesionario === "77215640-5"  /* || true */) {
          setIsArcoprime(true);
          getFiliales(props.token);
        } else {
          getTerminos(props.token);
          getMovimientos(props.token, true);
        }
      }
    }
  }, [props.token, props.rutConcesionario]);

  useEffect(() => {
    if(props.cabecera!==""){
      setClientName(props.cabecera.DATOS_CLIENTE.NOMBRE);
      setClientAddress(props.cabecera.DATOS_CLIENTE.DIRECCION);
      setPagador(props.cabecera.DATOS_CLIENTE.PAGADOR);
      setZone(props.cabecera.DATOS_EDS[0]?.ZONA);
      getEds(
        props.callServiceHandler,
        props.rutConcesionario,
        setAllEdsForTable,
        setAllEds,
        null,
        null,
        props.cabecera
      );
    }
  }, [props.cabecera]);

  useEffect(() => {
    let newCargos = 0;
    let newAbonos = 0;
    let newTotal = 0;
    let newCargosAPagar = 0;

    selectedTransactions.forEach(function (transaction) {
      newCargos += transaction.CARGO;
      newAbonos += transaction.ABONO;
      newTotal += 2;
      if (transaction.DESCUENTO_PRONTO_PAGO > 0) {
        newCargosAPagar += Math.round(
          transaction.CARGO * ((100 - transaction.DESCUENTO_PRONTO_PAGO) / 100)
        );
      } else {
        newCargosAPagar += transaction.CARGO;
      }
    });

    setTotalAbonosSeleccionados(newAbonos);
    setTotalCargosSeleccionados(newCargos);
    setTotalSaldo(newCargos - newAbonos);
    setTotalAPagar(newCargosAPagar - newAbonos);
    validar(newAbonos, newCargos, newCargos - newAbonos);
  }, [selectedTransactions]);

  useEffect(() => {
    const allPeriodos = getAllPeriodos();
    setAllPeriodos(allPeriodos);
    setSelectedPeriodo(allPeriodos[0]);
    const date = allPeriodos[0].value.split("%3B");
    setSelectedMonth(date[0]);
    setSelectedYear(date[1]);
  }, []);

  useEffect(() => {
    if (selectedEdsArcoprime) {
      getMovimientos(props.token, true);
    }
  }, [selectedEdsArcoprime]);

  function closeStepper() {
    setDisplayChequeEnLinea(false);
    setDisplayCompensacion(false);
    setDisplayPagarEnBanco(false);
    getMovimientos(token);
  }

  async function consultarMandato() {
    const url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/listar-mandatos-activos?rut_concesionario=" +
      props.rutConcesionario;

    let result = await props.callServiceHandler(url, "GET")
    if(result !== "error"){
      if (result.data.MANDATOS.length > 0) {
        setMandatosActivos(result.data.MANDATOS);
      } else {
        setChequeMandatosError(true);
        setChequeErrors(["Mandato inactivo o Mandato No Existe"]);
      }
    }
  }

  function changeSelectedOption(e) {
    setSelectedOption(e);
    getMovimientos(token);
  }

  function changeSelectedPeriodo(e) {
    setSelectedPeriodo(e);
    const date = e.value.split("%3B");
    setSelectedMonth(date[0]);
    setSelectedYear(date[1]);
    if (selectedOption) getMovimientos(token);
    else getMovimientos(token);
  }

  async function getTerminos(newToken) {
    displayLoader();
    let url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/obtener-terminos?rut_concesionario=" +
      props.rutConcesionario;

    let url2 =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/validar-contrato?rut_concesionario=" +
      props.rutConcesionario;

    let result = await props.callServiceHandler(url, "GET")
    if(result !== "error"){
      displayLoader();
      if (result.data["ERROR"] === 1) {
        setDisplayTerminos(true);
      } else {

        let result2 = await props.callServiceHandler(url2, "GET")
        if(result2 !== "error"){
          if (result2.data["E_MOSTRAR"] !== "") {
            setKunnr(result2.data["E_KUNNR"]);
            setEmails(result2.data.E_SOLICITANTES);
            setTerminosOk(true);
            setDisplayTerminos(true);
            hideLoader();
          }
        }
      }
    }     
  }

  async function getMovimientos(newToken, first = false) {
    displayLoader();

    let url = "";

    if (isArcoprime) {
      url =
        process.env.REACT_APP_BASE_API +
        "/cuenta-corriente/obtener-partidas-pago?id_eds=" +
        selectedEdsArcoprime.value;
    } else {
      url =
        process.env.REACT_APP_BASE_API +
        "/cuenta-corriente/obtener-partidas-pago?rut_concesionario=" +
        props.rutConcesionario;
    }

    let result = await props.callServiceHandler(url, "GET")
    if(result !== "error"){
      getUpdateDate(setUpdateDate);
      setMovimientosLoaded(true);
      if (result.data.JUDICIAL !== "") {
        setDisplayJudicial(true);
      }
      cleanCartolaData(
        result.data.PARTIDAS,
        result.data.SALDO_ANTERIOR,
        first
      );
    }
  }

  async function getFiliales(newToken) {

    displayLoader();
    const url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/listar-filiales?rut_concesionario=77215640-5";

    let result = await props.callServiceHandler(url, "GET")
    if(result !== "error"){
      const data = result.data;
      const eeddss = [];

      data.FILIALES.forEach(function (eds) {
        let ide = eds.ID_EDS;
        while (ide.charAt(0) === "0") {
          ide = ide.substring(1);
        }
        const newEds = {
          value: eds.ID_EDS,
          label: ide + " - " + eds.DIRECCION,
        };
        eeddss.push(newEds);
      });

      setAllEdsArcoprime(eeddss);
    }
  }

  async function changeEdsArcoprime(e) {
    setSelectedEdsArcoprime(e);
  }

  function cleanCartolaData(movimientos, saldoAnterior, first) {
    const newProductsFilter = initializeSelectOptions();
    const newTipoMovimientoFilter = initializeSelectOptions();
    const newMovimientos = [];
    let sumCargos = 0;
    let sumAbonos = 0;

    movimientos.forEach(function (movimiento) {
      if (
        movimiento.LINEA_PRODUCTO !== "" &&
        movimiento.LINEA_PRODUCTO !== "TOTAL" &&
        newProductsFilter.filter((e) => e.value === movimiento.LINEA_PRODUCTO)
          .length === 0
      ) {
        const newProd = {
          label: movimiento.LINEA_PRODUCTO,
          value: movimiento.LINEA_PRODUCTO,
        };
        newProductsFilter.push(newProd);
      }

      if (
        movimiento.TIPO_DOCUMENTO !== "" &&
        movimiento.TIPO_DOCUMENTO !== "TOTAL" &&
        newTipoMovimientoFilter.filter(
          (e) => e.value === movimiento.TIPO_DOCUMENTO
        ).length === 0
      ) {
        const newProd = {
          label: movimiento.TIPO_DOCUMENTO,
          value: movimiento.TIPO_DOCUMENTO,
        };
        newTipoMovimientoFilter.push(newProd);
      }

      const newMovimiento = {
        LINEA_PRODUCTO: movimiento.LINEA_PRODUCTO,
        TIPO_DOCUMENTO: movimiento.TIPO_DOCUMENTO,
        NUMERO_DOCUMENTO: movimiento.NUMERO_DOCUMENTO,
        NUMERO_OFICINA: movimiento.NUMERO_OFICINA,
        NUMERO_EDS:
          movimiento.NUMERO_EDS === ""
            ? "Sin Pto Vta"
            : movimiento.NUMERO_EDS.substr(movimiento.NUMERO_EDS.length - 5),
        COMUNA: movimiento.COMUNA,
        FECHA_MOVIMIENTO: movimiento.FECHA_MOVIMIENTO,
        FECHA_VENCIMIENTO: movimiento.FECHA_VENCIMIENTO,
        CARGO: parseInt(movimiento.CARGO),
        ABONO: parseInt(movimiento.ABONO),
        DIAS_ATRASO: parseInt(movimiento.DIAS_ATRASO),
        ESTADO: movimiento.ESTADO,
        DOCSAP: movimiento.DOCSAP,
        EJERCICIO: movimiento.EJERCICIO,
        POSICION: movimiento.POSICION,
        NUMERO: movimiento.NUMERO,
        SELEC: movimiento.SELEC,
        ID: movimiento.ID,
        PAGO_CHEQUE: movimiento.PAGO_CHEQUE,
        PAGO_CHEQUE_MENSAJE: movimiento.PAGO_CHEQUE_MENSAJE,
        DESCUENTO_PRONTO_PAGO: parseFloat(movimiento.DESCUENTO_PRONTO_PAGO),
        PAGARE: movimiento.PAGARE,
        FACTURA_SD: movimiento.FACTURA_SD,
      };

      newMovimientos.push(newMovimiento);
      sumCargos+=parseInt(movimiento.CARGO);
      sumAbonos+=parseInt(movimiento.ABONO);
    });

    setMovimientos(newMovimientos);
    setMovimientosOriginal(newMovimientos);
    originalMovimientos.current = newMovimientos;
    getCuotasPagares(newMovimientos);
    setProductsFilter(newProductsFilter);
    setTipoMovimientosFilter(newTipoMovimientoFilter);
    if (first && props.params) {
      selectFromParams(newMovimientos);
    }
    setTotalCargos(sumCargos);
    setTotalAbonos(sumAbonos);
  }

  function selectFromParams(newMovimientos) {
    const paramsArr = props.params.split("&");
    const type = paramsArr[0].split("=")[1];
    let eds = paramsArr[1].split("=")[1];
    eds = eds.substr(eds.length - 5);
    const newSelectedTransactions = [];
    const newSelectedTransactionsIds = [];
    if (type === "vencidos") {
      newMovimientos.forEach(function (mov, key) {
        if (mov.DIAS_ATRASO > 0 && mov.CARGO>0) {
          if (
            (eds === "null" || eds === "*" || eds === mov.NUMERO_EDS) &&
            mov.SELEC === "X"
          ) {
            newSelectedTransactions.push(mov);
            newSelectedTransactionsIds.push(mov.ID);
          }
        }
      });
    } else if (type === "vigentes") {
      newMovimientos.forEach(function (mov, key) {
        if (mov.DIAS_ATRASO === 0) {
          if (
            (eds === "null" || eds === "*" || eds === mov.NUMERO_EDS) &&
            mov.SELEC === "X"
          ) {
            newSelectedTransactions.push(mov);
            newSelectedTransactionsIds.push(mov.ID);
          }
        }
      });
    } else if (type === "abonos_no_compensados") {
      newMovimientos.forEach(function (mov, key) {
        if (mov.ABONO > 0) {
          if (
            (eds === "null" || eds === "*" || eds === mov.NUMERO_EDS) &&
            mov.SELEC === "X"
          ) {
            newSelectedTransactions.push(mov);
            newSelectedTransactionsIds.push(mov.ID);
          }
        }
      });
    } else if (type === "saldo") {
      newMovimientos.forEach(function (mov, key) {
        if (
          (eds === "null" || eds === "*" || eds === mov.NUMERO_EDS) &&
          mov.SELEC === "X"
        ) {
          newSelectedTransactions.push(mov);
          newSelectedTransactionsIds.push(mov.ID);
        }
      });
    }

    setSelectedTransactions(newSelectedTransactions);
    setSelectedTransactionsIds(newSelectedTransactionsIds);
  }

  function pagarEnBanco() {
    if (pagarErrors.length > 0) {
      setErrorMessage(pagarErrors[0]);
      return;
    }

    if (!cookies.get("popup")) {
      setDisplayModal(true);
      showGreyBackground();
      cookies.set("popup", "yamostrado", {
        path: "/",
        expires: new Date(2040, 11, 17),
      });
    }

    setDisplayPagarEnBanco(true);
    setErrorMessage();
  }

  function chequeEnLinea() {
    if (chequeErrors.length > 0) {
      setErrorMessage(chequeErrors[0]);
      return;
    }

    setDisplayChequeEnLinea(true);
    setErrorMessage();
  }

  function compensar() {
    if (compensarErrors.length > 0) {
      setErrorMessage(compensarErrors[0]);
      return;
    }

    setDisplayCompensacion(true);
    setErrorMessage();
  }

  function closeModal() {
    setDisplayModal(false);
    hideGreyBackground();
  }

  function closeWarningModal() {
    setDisplayWarningModal(false);
    hideGreyBackground();
  }

  function validar(abonos, cargos, total) {
    let pagarErrorsA = [];
    let chequeErrorsA = [];
    let compensarErrorsA = [];

    //Validaciones de Pagar
    if (selectedTransactions.length === 0) {
      pagarErrorsA.push("Debe existir al menos un documento seleccionado");
    } else if (total <= 0) {
      pagarErrorsA.push(
        "Se deben seleccionar partidas que den monto a pagar positivo"
      );
    }

    //Validaciones Cheque en Linea

    if (chequeMandatosError) {
      chequeErrorsA.push("Mandato inactivo o Mandato No Existe");
    }

    const chequesX = selectedTransactions.filter(
      (mov, key) => mov.PAGO_CHEQUE !== ""
    );

    if (chequesX.length > 0) {
      if (chequesX[0].PAGO_CHEQUE === "X") {
        chequeErrorsA.push(chequesX[0].PAGO_CHEQUE_MENSAJE);
      } else if (chequesX[0].PAGO_CHEQUE === "R") {
        chequeErrorsA.push(
          "Documento (" +
            chequesX[0].NUMERO_DOCUMENTO +
            ") con rechazo por Cheque en Línea - " +
            chequesX[0].PAGO_CHEQUE_MENSAJE
        );
      } else {
        chequeErrorsA.push(chequesX[0].PAGO_CHEQUE_MENSAJE);
      }
    }

    if (selectedTransactions.length === 0 || total <= 0) {
      chequeErrorsA.push(
        "El monto a pagar con Cheque en línea debe ser mayor a cero"
      );
    }

    //Validaciones Compensar

    if (cargos === 0 && abonos === 0) {
      compensarErrorsA.push("Debe seleccionar al menos 1 documento de pago");
    }

    if (cargos === 0) {
      compensarErrorsA.push(
        "Debe seleccionar al menos 1 documento de cargo para realizar la compensación"
      );
    }

    if (abonos === 0) {
      compensarErrorsA.push(
        "Debe seleccionar al menos 1 documento de abono para realizar la compensación"
      );
    }

    const cargosS = selectedTransactions.filter((t) => t.CARGO > 0);
    const abonosS = selectedTransactions.filter((t) => t.ABONO > 0);

    const lineasAbonos = [];
    const lineasCargos = [];

    abonosS.forEach((transaction) => {
      if (
        lineasAbonos.filter((e) => e === transaction.LINEA_PRODUCTO).length == 0
      ) {
        lineasAbonos.push(transaction.LINEA_PRODUCTO);
      }
    });

    cargosS.forEach((transaction) => {
      if (
        lineasCargos.filter((e) => e === transaction.LINEA_PRODUCTO).length == 0
      ) {
        lineasCargos.push(transaction.LINEA_PRODUCTO);
      }
    });

    const caseaplicado = (movimiento) =>
      movimiento.TIPO_DOCUMENTO === "Case Aplicado"  && movimiento.SELEC === "X" ;

    const caseabonos = abonosS.filter(
      (movimiento) => movimiento.TIPO_DOCUMENTO === "Case Aplicado"
    );
    const casecargos = cargosS.filter(
      (movimiento) => movimiento.TIPO_DOCUMENTO === "Case Aplicado"
    );

    const casesCargoTotales = movimientos.filter((movimiento)=>movimiento.TIPO_DOCUMENTO==="Case Aplicado" && movimiento.CARGO>0 && movimiento.SELEC === "X");
    const casesCargoSeleccionados = selectedTransactions.filter((t)=>t.TIPO_DOCUMENTO==="Case Aplicado" && t.CARGO>0);

    if (movimientos.some(caseaplicado)) {
      if (casesCargoTotales.length!==casesCargoSeleccionados.length) {
        compensarErrorsA.push(
          "Debe seleccionar todas las partidas de saldo (cargo) pendientes de pago"
        );
      }
    }

    const abonoWebPresente = (movimiento) => {
      const tipo = movimiento.ID.split(";")[0];
      if (
        tipo === "1W" ||
        tipo === "2W" ||
        tipo === "3W" ||
        tipo === "9W" ||
        tipo === "CW"
      ) {
        return true;
      } else {
        return false;
      }
    };

    if (abonosS.some(abonoWebPresente)) {
      if (lineasAbonos.length > 1 || lineasCargos.length > 1) {
        compensarErrorsA.push(
          "No es posible compensar con líneas de producto distintas"
        );
      } else if (lineasCargos[0] != lineasAbonos[0]) {
        compensarErrorsA.push(
          "No es posible compensar con líneas de producto distintas"
        );
      }
    }

    //Validaciones de Pagarés

    const pagaresSelected = selectedTransactions.filter(
      (mov) => mov.PAGARE != ""
    );
    const pagaresDocsaps = pagaresSelected.map((mov) => mov.DOCSAP);
    if (cuotasPagares.length > 0) {
      const pagareProximo = cuotasPagares[0];
      if (
        pagaresSelected.length > 0 &&
        !pagaresDocsaps.includes(pagareProximo)
      ) {
        pagarErrorsA.push("Debes seleccionar la Cuota Pagaré próxima a vencer");
        chequeErrorsA.push(
          "Debes seleccionar la Cuota Pagaré próxima a vencer"
        );
        compensarErrorsA.push(
          "Debes seleccionar la Cuota Pagaré próxima a vencer"
        );
      }
    }

    setPagarErrors(pagarErrorsA);
    setChequeErrors(chequeErrorsA);
    setCompensarErrors(compensarErrorsA);
  }

  function getCuotasPagares(movs) {
    let movsaux = [...movs];

    let pagares = movsaux.filter((pag) => pag.PAGARE != "");

    const pares = [];

    pagares.forEach((mov) => {
      const aux = [];
      const par = pagares.filter((m) => m.DOCSAP === mov.DOCSAP);
      if (par.length > 1) {
        if (!pares.includes(mov.DOCSAP)) {
          pares.push(mov.DOCSAP);
        }
      }
    });
    setCuotasPagares(pares);
    //vamos
  }

  const displyCartola =
    !displayChequeEnLinea && !displayCompensacion && !displayPagarEnBanco;

  const scrollToTop = () => {
    if (mainContentRef.current) {
      mainContentRef.current.scrollTop = 0;
    }
  };

  const displayMobileActionsBar =
    selectedTransactionsIds.length > 0 &&
    !isDesktopRef.current &&
    displyCartola;

  const scrollHandler = useThrottleCallback(({ target }) => {
    if (displyCartola) {
      const position = Math.floor(target.scrollTop);
      if (showMobileScrollTopArrow && position === 0) {
        setShowMobileScrollTopArrow(false);
      } else if (!showMobileScrollTopArrow && position > 0) {
        setShowMobileScrollTopArrow(true);
      }
    }
  }, 100);

  function resetMovimientos() {
    setMovimientos([...originalMovimientos.current]);
  }

  useEffect(() => {
    if (!displyCartola && showMobileScrollTopArrow) {
      setShowMobileScrollTopArrow(false);
    }
  }, [displyCartola, showMobileScrollTopArrow]);

  async function getBancosAndMessage() {
    displayLoader();

    let url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/obtener-bancos-mensaje?rut_concesionario=" +
      props.rutConcesionario;
    
    let result = await props.callServiceHandler(url, "GET");
    if(result !== "error"){
      const data = result.data;
      const banks = [];
      const message = [{
        MENSAJE: data.MENSAJES[0]["MENSAJE"],
        ACTIVO: data.MENSAJES[0]["ACTIVO"],
      }];

      data.BANCOS.forEach(function (bnk) {
        const newBank = {
          CONVENIO: bnk.CONVENIO,
          CLAVE_BANCO: bnk.CLAVE_BANCO,
        };
        banks.push(newBank);
      });

      setBankOptions(banks);
      setBankOptionsMessage(message);
      if (message[0].ACTIVO === 'X') {
        setDisplayWarningModal(true);
      }
    }
  }

  const goBack = () => {
    setDisplayCompensacion(false);
    setDisplayChequeEnLinea(false);
    setDisplayPagarEnBanco(false);
  }

  return (
    <div className="cartola page pagos">
      <div className="title-container">
        <div className="title">
          <div className="view-title">Cuenta corriente</div>
          <div className="view-title-separator">|</div>
          <div className="view-subtitle">Pagos y compensaciones</div>
        </div>
        {
          displayPagarEnBanco || displayChequeEnLinea || displayCompensacion? <><div className="volver-arrow" onClick={()=>goBack()} >
          <img></img>
          <p className="volver-text">Volver</p>
        </div></>:<></>
        }
        
      </div>

      <hr className="title-separator-line" />

      <HeaderData
        rutConcesionario={props.rutConcesionario}
        token={props.token}
        actualizacion={updateDate}
        actualizable={true}
        updateFunction={getMovimientos}
        cabecera={props.cabecera}
        callServiceHandler={props.callServiceHandler}
      />

      {displayTerminos && (
        <>
          <hr />{" "}
          <Terminos
            setDisplayTerminos={setDisplayTerminos}
            newToken={props.token}
            rutConcesionario={props.rutConcesionario}
            getMovimientos={getMovimientos}
            terminosOk={terminosOk}
            setTerminosOk={setTerminosOk}
            emails={emails}
            setEmails={setEmails}
            kunnr={kunnr}
            setKunnr={setKunnr}
            callServiceHandler={props.callServiceHandler}
          />
        </>
      )}

      {!displayTerminos && (
        <>
          {displayJudicial && <ErrorJudicial />}

          {!displayJudicial && (
            <div
              ref={mainContentRef}
              onScroll={isDesktopRef.current ? undefined : scrollHandler}
              className="page-main-content"
              style={
                viewPortHeight && !isDesktopRef.current
                  ? { height: viewPortHeight - 310 }
                  : undefined
              }
            >
              <div className="sticky-page-part">
                <div className="header-box-multiple-container pagosboxes">
                  <HeaderBoxV2 
                  icon1={cargoIcon}
                  icon2={abonoIcon}
                  cargos={totalCargos}
                  abonos={totalAbonos}
                  />
                  <HeaderBox
                    monthInfo="Cargo seleccionado"
                    month=""
                    amount={getLocaleStringNumber(totalCargosSeleccionados,true)}
                    icon={cargoIcon}
                    color="red"
                    //isFirst={true}
                  />

                  <HeaderBox
                    monthInfo="Abono seleccionado"
                    month=""
                    amount={getLocaleStringNumber(totalAbonosSeleccionados,true)}
                    icon={abonoIcon}
                    color="green"
                  />

                  <HeaderBox
                    monthInfo="Monto total seleccionado"
                    month=""
                    amount={getLocaleStringNumber(totalSaldo,true)}
                    icon={saldoAzulIcon}
                    isLast={true}
                    color={totalSaldo < 0 ? "red" : "blue"}
                  />
                </div>

                <hr />
                {displyCartola && (
                  <div className={`pagos-options ${isArcoprime?"arcoprime":""}`}>
                    <div className="filters-container">
                      {isArcoprime && (
                        <>
                          <div className="filter">
                            <div className="filter-title">
                              ESTACIÓN DE SERVICIO*
                            </div>
                            <div className="filter-input">
                              <Select
                                className="eds-select"
                                value={selectedEdsArcoprime}
                                onChange={(e) => changeEdsArcoprime(e)}
                                closeMenuOnSelect={true}
                                hideSelectedOptions={false}
                                options={allEdsArcoprime}
                                components={{
                                  SingleOption,
                                  IndicatorSeparator: () => null,
                                }}
                                isClearable={false}
                                autosize={false}
                                placeholder="Seleccionar"
                                styles={customStylesPedidos}
                                width="150px"
                                containerWidth="140px"
                                widthmenu="200px"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div className={`filter ${isArcoprime ? "mleft" : ""}`}>
                        <div className="filter-input">
                          <input
                            type="search"
                            className="search nomarginsearch"
                            value={filterText}
                            placeholder="Buscar..."
                            onChange={(e) => setFilterText(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="actions">
                      <button
                        className={`actions-buttons${
                          pagarErrors.length > 0 ? " bloqueado" : ""
                        }`}
                        onClick={pagarEnBanco}
                      >
                        Pagar en banco
                      </button>
                      <button
                        className={`actions-buttons${
                          compensarErrors.length > 0 ? " bloqueado" : ""
                        }`}
                        onClick={compensar}
                      >
                        Compensar
                      </button>
                      <button
                        className={`actions-buttons${
                          chequeErrors.length > 0 ? " bloqueado" : ""
                        }`}
                        onClick={chequeEnLinea}
                      >
                        Cheque en línea
                      </button>
                      <PrintComponent 
                        reff={tableRef}
                        classes={`${isArcoprime ? "arcoprime" : ""}`}
                        isROImpersonate={props.isROImpersonate}
                      />

                      <ExportExcel
                        data={filteredMovimientos}
                        labels={labels}
                        isROImpersonate={props.isROImpersonate}
                        isArcoprime={isArcoprime}
                      />
                    </div>
                  </div>
                )}
              </div>
              {displyCartola && (
                <>
                  {errorMessage && (
                    <div className="important-message error">
                      <span className="error-icon">!</span>
                      <span>{errorMessage}</span>
                    </div>
                  )}
                  <CartolaTable
                    movimientos={movimientos}
                    setMovimientos={setMovimientos}
                    productsFilter={productsFilter}
                    allTipoMovimientoFilter={tipoMovimientosFilter}
                    tipoMovimientoFilter={tipoMovimientoFilter}
                    setTipoMovimientoFilter={setTipoMovimientoFilter}
                    allEds={allEdsForTable}
                    selectedMonth={selectedMonth}
                    selectedYear={selectedYear}
                    filterText={filterText}
                    isPagos={true}
                    setSelectedTransactions={setSelectedTransactions}
                    filteredMovimientos={filteredMovimientos}
                    setFilteredMovimientos={setFilteredMovimientos}
                    movimientosLoaded={movimientosLoaded}
                    tableRef={tableRef}
                    newToken={props.token}
                    setErrorMessage={setErrorMessage}
                    selectedTransactionsIds={selectedTransactionsIds}
                    setSelectedTransactionsIds={setSelectedTransactionsIds}
                    cuotasPagares={cuotasPagares}
                    setFilterText={setFilterText}
                    resetMovimientos={resetMovimientos}
                    movimientosOriginal={movimientosOriginal}
                    callServiceHandler={props.callServiceHandler}
                  />
                </>
              )}

              {displayChequeEnLinea && (
                <ChequeEnLinea
                  selectedTransactions={selectedTransactions}
                  totalSaldo={totalSaldo}
                  mandatosActivos={mandatosActivos}
                  rutConcesionario={props.rutConcesionario}
                  token={token}
                  clientName={clientName}
                  clientAddress={clientAddress}
                  setDisplayChequeEnLinea={setDisplayChequeEnLinea}
                  closeStepper={closeStepper}
                  isCompensar={false}
                  totalAPagar={totalAPagar}
                  setSelectedTransactions={setSelectedTransactions}
                  isArcoprime={isArcoprime}
                  selectedEdsArcoprime={selectedEdsArcoprime}
                  isROImpersonate={props.isROImpersonate}
                  callServiceHandler={props.callServiceHandler}
                  displayCustomError={props.displayCustomError}
                />
              )}

              {displayCompensacion && (
                <Compensar
                  selectedTransactions={selectedTransactions}
                  totalSaldo={totalSaldo}
                  mandatosActivos={mandatosActivos}
                  rutConcesionario={props.rutConcesionario}
                  token={token}
                  clientName={clientName}
                  clientAddress={clientAddress}
                  setDisplayChequeEnLinea={setDisplayChequeEnLinea}
                  closeStepper={closeStepper}
                  totalAPagar={totalAPagar}
                  setSelectedTransactions={setSelectedTransactions}
                  isArcoprime={isArcoprime}
                  selectedEdsArcoprime={selectedEdsArcoprime}
                  setDisplayCompensacion={setDisplayCompensacion}
                  isROImpersonate={props.isROImpersonate}
                  callServiceHandler={props.callServiceHandler}
                  displayCustomError={props.displayCustomError}
                />
              )}

              {displayPagarEnBanco && (
                <PagarEnBanco
                  selectedTransactions={selectedTransactions}
                  totalSaldo={totalSaldo}
                  mandatosActivos={mandatosActivos}
                  rutConcesionario={props.rutConcesionario}
                  token={token}
                  clientName={clientName}
                  clientAddress={clientAddress}
                  pagador={pagador}
                  setDisplayChequeEnLinea={setDisplayChequeEnLinea}
                  closeStepper={closeStepper}
                  isCompensar={false}
                  isArcoprime={isArcoprime}
                  selectedEdsArcoprime={selectedEdsArcoprime}
                  totalAPagar={totalAPagar}
                  setDisplayPagarEnBanco={setDisplayPagarEnBanco}
                  isROImpersonate={props.isROImpersonate}
                  callServiceHandler={props.callServiceHandler}
                  isErrorAlertOpen={props.isErrorAlertOpen}
                  displayCustomError={props.displayCustomError}
                  banksList={banksOptions}
                  banksOptionsMessage={bankOptionsMessage}
                />
              )}

              <CustomModal
                displayModal={displayModal}
                closeModal={closeModal}
                acceptFunc={closeModal}
                modalTitle='Habilitar Pop Ups'
                modalText='Para realizar pagos, habilita los pop ups en tu navegador. Para habilitar pop ups, sigue las siguientes instrucciones:'
                modalIcon='success'
              />

              <CustomModal
                displayModal={displayWarningModal}
                closeModal={closeWarningModal}
                acceptFunc={closeWarningModal}
                modalTitle={warningModalTitle}
                modalText={bankOptionsMessage[0].MENSAJE}
                modalIcon='importante'
              />

              {showMobileScrollTopArrow && (
                <button
                  className="scroll-top"
                  onClick={scrollToTop}
                  style={{ bottom: bottomCoordinate + 72 }}
                >
                  <img src={arrowUp} alt="scroll to top" />
                </button>
              )}

              {displayMobileActionsBar && (
                <MobileActionsModal viewPortBottomCoordinate={bottomCoordinate}>
                  <button
                    className={`main-button${
                      pagarErrors.length > 0 ? " bloqueado" : ""
                    }`}
                    onClick={pagarEnBanco}
                  >
                    Pagar en banco
                  </button>
                  <button
                    className={`main-button${
                      compensarErrors.length > 0 ? " bloqueado" : ""
                    }`}
                    onClick={compensar}
                  >
                    Compensar
                  </button>
                  <button
                    className={`main-button${
                      chequeErrors.length > 0 ? " bloqueado" : ""
                    }`}
                    onClick={chequeEnLinea}
                  >
                    Cheque en línea
                  </button>
                  {errorMessage && (
                    <div className="important-message error">
                      <span className="error-icon">!</span>
                      <span>{errorMessage}</span>
                    </div>
                  )}
                </MobileActionsModal>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Pagos;
