import React from "react";
import {
  orderArray,
  parseBudat,
  getLocaleStringNumber,
} from "../utils/utils.js";
import Flecha_down from "../../assets/img/Flecha_down.svg";
import Flecha_up from "../../assets/img/Flecha_up.svg";

const BonificacionTable = (props) => {
  const detalle = props.detalle;
  const detalleCargado = props.detalleCargado;

  const orderTable = (filterkey, order, parse) => {
    const arr = orderArray(filterkey, detalle, order, parse);
    props.setDetalle(arr);
  };

  return (
    <div className="bonificacion-table-container">
      <table className="bonificacion-table">
        <tr>
          <th>
            <div className="th-data">
              <div className="th-text">
                Fecha Entrega
                <div className="arrows">
                  <img
                    onClick={() => orderTable("FECHA_ENTREGA", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("FECHA_ENTREGA", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                CI.Pedido
                <div className="arrows">
                  <img
                    onClick={() => orderTable("CLASE_PEDIDO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("CLASE_PEDIDO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Pedido
                <div className="arrows">
                  <img
                    onClick={() => orderTable("PEDIDO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("PEDIDO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Horario pp
                <div className="arrows">
                  <img
                    onClick={() => orderTable("HORA_PREPROGRAMA", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("HORA_PREPROGRAMA", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Tipo Bonificación
                <div className="arrows">
                  <img
                    onClick={() => orderTable("TIPO_BONIFICACION", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("TIPO_BONIFICACION", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Suma Vol. Requerido
                <div className="arrows">
                  <img
                    onClick={() => orderTable("VOLUMEN_REQUERIDO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("VOLUMEN_REQUERIDO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Suma Cant. Entr
                <div className="arrows">
                  <img
                    onClick={() => orderTable("CANTIDAD_ENTREGADA", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("CANTIDAD_ENTREGADA", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Cant. entregada como ZPPC
                <div className="arrows mtop">
                  <img
                    onClick={() => orderTable("CANTIDAD_ENTREGADA_ZPPC", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("CANTIDAD_ENTREGADA_ZPPC", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
        </tr>
        <tr className="table-separator-row-border-top"><td /></tr>
        <tbody>
          {detalle.length === 0 ? (
            <>
            
                <tr className="data">
                  <td className="align-left" colSpan="8">
                    {detalleCargado?"No se encontraron pedidos":"Seleccione una Estación de Servicio para ver la información."}
                    
                  </td>
                </tr>
                <tr className="data">
                  <td className="align-left" colSpan="8">
                    &nbsp;
                  </td>
                </tr>
              </>
          ) : (
            detalle.map((obj) => {
              return (
                <tr className="data">
                  <td className="align-left">{parseBudat(obj.FECHA_ENTREGA)}</td>
                  <td className="align-left">{obj.CLASE_PEDIDO}</td>
                  <td className="align-left">{obj.PEDIDO}</td>
                  <td className="align-left">{obj.HORA_PREPROGRAMA}</td>
                  <td className="align-left">{obj.TIPO_BONIFICACION}</td>
                  <td className="align-left">{getLocaleStringNumber(obj.VOLUMEN_REQUERIDO)}</td>
                  <td className="align-left">{getLocaleStringNumber(obj.CANTIDAD_ENTREGADA)}</td>
                  <td className="align-left">{getLocaleStringNumber(obj.CANTIDAD_ENTREGADA_ZPPC)}</td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BonificacionTable;
