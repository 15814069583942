import React from "react";
import { showGreyBackground } from "../utils/windows";
const StepTerminos = (props) => {

  async function aceptarTerminos(){
    let url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/actualizar-pago?rut_concesionario=" +
      props.rutConcesionario;

    let url2 =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/validar-contrato?rut_concesionario=" +
      props.rutConcesionario;

    let result = await props.callServiceHandler(url, "GET")
    if(result !== "error"){
      let result2 = await props.callServiceHandler(url2, "GET")
      if(result2 !== "error"){
        if (result.data["E_MOSTRAR"] !== ""){
          props.setKunnr(result.data['E_KUNNR']);
          props.setEmails(result.data.E_SOLICITANTES);
          props.setTerminosOk(true);
        }else{
          props.setDisplayTerminos(false);
          props.getMovimientos(props.newToken);
        }
      }
    }
  };

  const rechazarTerminos = () => {
    props.setDisplayModal(true);
    showGreyBackground();
  };

  return (
    <>
      <div className="terminos">
        <div className="title">Términos y Condiciones de uso</div>
        <div className="bajada">Sitio Internet para Pagos a Copec</div>
        <hr className="title-separator-line" />

        <p>
          El uso de los servicios disponibles a través del Sitio Internet para
          pagos a Copec, tiene por objeto efectuar pagos de documentos
          tributarios de ventas o servicios emitidos por Copec S.A., esto es de
          Facturas, Boletas, Notas de Débitos y Notas de crédito y de aquellos
          otros que Copec resuelva expresamente aceptar. Los servicios ofrecidos
          en este sitio web se ajustarán a las condiciones y términos que en él
          se establecen y que por el solo hecho de que el cliente o usuario
          acceda a él se entenderán plenamente conocidos y aceptados por ellos.
        </p>

        <p>
          El cliente o usuario al utilizar este sitio para efectuar pagos se
          obliga a cumplir con todas las obligaciones que ello impone.
        </p>

        <p>
          Para acceder al sistema de pagos que este sitio contempla, el cliente
          debe poseer una contraseña o clave secreta, personal e intransferible,
          que Copec le otorgará y en la medida que este sistema de pagos sea
          aceptado por los Bancos. Es de exclusiva responsabilidad del cliente
          mantener la debida diligencia y cuidado en su utilización, por lo que
          el cliente declara y asume la total responsabilidad por los perjuicios
          que el mal uso de dicha clave le acarreen o que se causen a Copec
          S.A., y/o a terceros.
        </p>

        <p>
          Copec S.A. se reserva el derecho de modificar en cualquier momento, el
          contenido, alcance, condiciones, términos y requisitos que en este
          sitio se contemplan. Estos cambios se entenderán notificados a los
          clientes o usuarios por el solo hecho de ser incorporados al sitio,
          sin perjuicio de que pudieren voluntariamente serles informados
          mediante comunicación directa. El cliente o usuario acepta lo anterior
          por el solo hecho de acceder o utilizar este sitio.
        </p>

        <p>
          El cliente está facultado para pagar el valor de los documentos antes
          mencionados que Copec emita, y que el cliente adeude; y que se le
          informen a través del Sitio Internet para pagos a Copec, por medio de
          la página Web, o por los Bancos que así lo hayan convenido con Copec y
          con el cliente.
        </p>

        <p>
          El cliente sólo podrá imputar los pagos que efectúe a través del Sitio
          Internet para pagos a Copec a los documentos tributarios ya referidos,
          debiendo hacerlo en cada caso respecto del valor total contemplado en
          cada uno de ellos, sin que pueda efectuar abonos parciales.
        </p>

        <p>
          Para el caso que el cliente no hiciere la imputación del pago a un
          determinado documento, Copec podrá hacerlo libremente entre aquellos
          documentos emitidos que sean exigibles.
        </p>

        <p>
          Si el cliente adeudare capital e intereses el pago se imputará
          primeramente a los intereses.
        </p>

        <p className="plistado">
          En todo caso deberá tenerse presente lo siguiente
        </p>
        <div className="listado">
          <p>
            a) El cliente no podrá pagar a través de este sitio Letras de Cambio
            giradas por Copec.
          </p>
          <p>
            b) El cliente solo podrá pagar cuotas de Pagarés en forma
            correlativa.
          </p>
          <p>
            c) El cliente no podrá pagar documentos emitidos por Copec que
            tengan un vencimiento de más de 30 días.
          </p>
        </div>

        <p>
          Copec S.A. solo aceptará como debidamente efectuados aquellos pagos
          que le hayan sido previamente confirmados por los Bancos que operan
          conforme al sistema de pagos a que se refiere este sitio web. Una vez
          pagado efectivamente el documento, Copec otorgará un recibo a través
          del Sitio Internet para pagos a Copec.
        </p>

        <p>
          El Sitio Internet para pagos a Copec procurará estar disponible las 24
          horas del día y los 7 días de la semana. Con todo, Copec no se
          compromete ni obliga a ello considerando que, eventualmente, y por
          diversas circunstancias, dichos servicios pueden estar temporalmente
          interrumpidos y no disponibles. Copec S.A. podrá poner término a este
          servicio en cualquier momento y sin expresión de causa.
        </p>
        <hr className="content-separator-line" />
        <div className="terminos-buttons">
          <button
            onClick={() => rechazarTerminos()}
            className="main-button hallow"
          >
            Rechazar
          </button>
          <button onClick={() => aceptarTerminos()} className="main-button red">
            Aceptar
          </button>
        </div>
      </div>
    </>
  );
};

export default StepTerminos;
