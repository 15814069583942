// REACT
import React from 'react';
import Modal from "react-modal";
import { useEffect } from 'react';

// ASSETS
import closeIcon from '../../../assets/img/cross-icon.svg'

// UTILS
import { docModalStylesNew } from '../../utils/styles';

const DatosDepartamentoModal = (
  {
    displayDatosDepartamentoModal,
    closeDatosDepartamentoModal,
    distanceToTop,
  }
) => {

  useEffect(() => {
    if (displayDatosDepartamentoModal) {
      // scrollTo(100, distanceToTop)
    }
  }, [displayDatosDepartamentoModal]);


  return (
    <div>
      <Modal
        isOpen={displayDatosDepartamentoModal}
        onRequestClose={closeDatosDepartamentoModal}
        style={docModalStylesNew(distanceToTop - 100)}
      >
        <button className="modal__close-btn" onClick={closeDatosDepartamentoModal}>
          <img src={closeIcon} alt="close modal" />
        </button>
        <div className="datos-departamento-modal-container">
          <div className="datos-departamento-modal datos-departamento">
            <div className="title">Datos Departamento</div>
            <div className="subtitle">
              TCT TRANSP CARGA YOLANDA DE LAS MER
            </div>
          </div>
          <div className="data">
            <div className="first-row">
              <div className="filters-container">
                <div className="filter">
                  <div className="filter-title">
                    EJECUTIVO DE VENTAS
                  </div>
                  <div className="filter-input">
                    <input type="text" className='disabled-input' placeholder='Moff Tarkin'/>
                  </div>
                </div>
                <div className="filter">
                  <div className="filter-title">
                    EJECUTIVO COBRANZAS
                  </div>
                  <div className="filter-input">
                    <input type="text" className='disabled-input' placeholder='Jin Djarin'/>
                  </div>
                </div>
              </div>
            </div>
            <div className="second-row">
              <div className="filters-container">
                <div className="filter">
                  <div className="filter-title">
                    CORREO
                  </div>
                  <div className="filter-input">
                    <input type="text" className='disabled-input' placeholder='correo@copec.cl'/>
                  </div>
                </div>
                <div className="filter">
                  <div className="filter-title">
                    CORREO
                  </div>
                  <div className="filter-input">
                    <input type="text" className='disabled-input' placeholder='correo@copec.cl'/>
                  </div>
                </div>
              </div>
            </div>
            <div className="third-row">
              <div className="filters-container">
                <div className="filter">
                  <div className="filter-title">
                    TELÉFONO
                  </div>
                  <div className="filter-input">
                    <input type="text" className='disabled-input' placeholder='+569 34426639'/>
                  </div>
                </div>
                <div className="filter">
                  <div className="filter-title">
                    TELÉFONO
                  </div>
                  <div className="filter-input">
                    <input type="text" className='disabled-input' placeholder='+569 34426639'/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <button className='main-button thin'>Cerrar</button>
        </div>
      </Modal>
    </div>
  );
}

export default DatosDepartamentoModal;
