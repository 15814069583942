import React, { useState } from "react";
import { CartolaTableMobileOptionsModal } from "./CartolaTableMobileOptionsModal";
import { MobileFiltersChoice } from "./MobileFilters/MobileFiltersChoice";
import {
  MobileFiltersOpenedChoice,
  MobileFiltersOpenedChoiceOption,
} from "./MobileFilters/MobileFiltersOpenedChoice";

const orders = {
  LINEA_PRODUCTO: {
    key: "LINEA_PRODUCTO",
    label: "Línea de producto",
    type: "text",
  },
  DIAS_ATRASO: {
    key: "DIAS_ATRASO",
    label: "Días vencimiento",
    type: "number",
  },
  CARGO: { key: "CARGO", label: "Cargos", type: "number" },
  ABONO: { key: "ABONO", label: "Abonos", type: "number" },
  NUMERO_DOCUMENTO: {
    key: "NUMERO_DOCUMENTO",
    label: "Nº Documento",
    type: "number",
  },
  NUMERO_EDS: { key: "NUMERO_EDS", label: "Nº EDS", type: "text" },
  TIPO_DOCUMENTO: {
    key: "TIPO_DOCUMENTO",
    label: "Tipo de movimiento",
    type: "text",
  },
  FECHA_MOVIMIENTO: {
    key: "FECHA_MOVIMIENTO",
    label: "Fecha Emisión",
    type: "number",
  },
  FECHA_VENCIMIENTO: {
    key: "FECHA_VENCIMIENTO",
    label: "Fecha vencimiento",
    type: "number",
  },
};

const DirectSelectionView = ({ itemTitle, closeView, select, type }) => {
  const ascLabel = type === "number" ? "Menor a mayor" : "A - Z";
  const descLabel = type === "number" ? "Mayor a menor" : "Z - A";
  return (
    <MobileFiltersOpenedChoice itemTitle={itemTitle} closeView={closeView}>
      <MobileFiltersOpenedChoiceOption
        name={ascLabel}
        key="asc"
        onClick={() => select({ value: "asc", label: ascLabel })}
      />
      <MobileFiltersOpenedChoiceOption
        name={descLabel}
        key="desc"
        onClick={() => select({ value: "desc", label: descLabel })}
      />
    </MobileFiltersOpenedChoice>
  );
};

export const CartolaTableMobileOrder = ({
  closeModal,
  setOrder,
  initialState = {},
  resetOrder,
}) => {
  const [openOrder, setOpenOrder] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(() => {
    if (initialState.key) {
      return { [initialState.key]: { ...initialState } };
    } else {
      return {};
    }
  });
  const hasOrder = Object.keys(selectedOrder).length > 0;

  const closeView = () => setOpenOrder("");
  const confirm = () => setOrder({ ...Object.values(selectedOrder)[0] });
  const cancelOrder = () => {
    resetOrder();
    setOrder();
    closeModal();
  };
  const selectOrder = (key) => (value) => {
    setSelectedOrder({ [key]: { ...value, key } });
    closeView();
  };

  return (
    <CartolaTableMobileOptionsModal
      title="Ordenar"
      closeModal={closeModal}
      cancel={hasOrder ? cancelOrder : closeModal}
      confirm={confirm}
      confirmLabel="Ordenar"
      disabledConfirm={!hasOrder}
      cancelLabel={hasOrder ? "Reestablecer Orden" : "Cancelar"}
    >
      {Object.keys(orders).map((key) => {
        const item = orders[key];
        return (
          <MobileFiltersChoice
            key={item.key}
            itemName={item.label}
            onClick={() => setOpenOrder(key)}
            value={selectedOrder[key]?.label}
          />
        );
      })}
      {!!openOrder && (
        <DirectSelectionView
          itemTitle={orders[openOrder].label}
          type={orders[openOrder].type}
          closeView={closeView}
          select={selectOrder(openOrder)}
        />
      )}
    </CartolaTableMobileOptionsModal>
  );
};
