import React, { useState, useEffect, useRef } from "react";
import BonificacionTable from "./BonificacionTable";
import Select from "react-select";
import {
  customStylesPedidos,
  SingleOption,
  getLocaleStringNumber,
  removeCeros,
  parseDate,
  getLocaleStringFloat
} from "../utils/utils";
import { setInstalalaciones } from "../utils/get";
import { displayLoader, hideLoader } from "../utils/windows";
import { getAllPeriodos, getUpdateDate } from "../utils/monthNames";
import HeaderData from "../HeaderData";
import PrintComponent from "../PrintComponent";
import ExportExcel from "../ExportExcel";
import logo from "../../assets/img/logo-comprobante.svg";

const Bonificacion = ({ rutConcesionario, token, cabecerae, pagador, instalaciones, isROImpersonate, callServiceHandler }) => {
  const [allEds, setAllEds] = useState([]);
  const [allPeriodos, setAllPeriodos] = useState([]);
  const [selectedEds, setSelectedEds] = useState();
  const [selectedPeriodo, setSelectedPeriodo] = useState(null);
  const [updateDate, setUpdateDate] = useState();
  const [cabecera, setCabecera] = useState();
  const [detalle, setDetalle] = useState([]);
  const [detalleCargado, setDetalleCargado] = useState(false);
  const mainRef = useRef(null);
  const date = new Date();
  const parsedDate = parseDate(date);
  const [clientName, setClientName] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [zone, setZone] = useState("");

  const [bonificaciones, setBonificaciones] = useState();

  const labels = [
    ["Fecha Entrega", "FECHA_ENTREGA"],
    ["CI. PEDIDO", "CLASE_PEDIDO"],
    ["Pedido", "PEDIDO"],
    ["Horario pp", "HORA_PREPROGRAMA"],
    ["Tipo Bonificación", "TIPO_BONIFICACION"],
    ["Suma Vol. Requerido", "VOLUMEN_REQUERIDO"],
    ["Suma Cant. Entr.", "CANTIDAD_ENTREGADA"],
    ["Cant. entregada como ZPPC", "CANTIDAD_ENTREGADA_ZPPC"],
  ];

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (token && rutConcesionario) {
        getUpdateDate(setUpdateDate);
        const periodos = getAllPeriodos();
        setAllPeriodos(periodos);
        setSelectedPeriodo(periodos[0]);
      }
    } else {
      if (token) {
        getUpdateDate(setUpdateDate);
        const periodos = getAllPeriodos();
        setAllPeriodos(periodos);
        setSelectedPeriodo(periodos[0]);
      }
    }
  }, [token, rutConcesionario]);

  useEffect(() => {
    if (cabecerae !== "") {
      setClientName(cabecerae.DATOS_CLIENTE.NOMBRE);
      setClientAddress(cabecerae.DATOS_CLIENTE.DIRECCION);
      setZone(cabecerae.DATOS_EDS[0].ZONA);
    }
  }, [cabecerae]);

  useEffect(() => {
    if (instalaciones!==""){
      setInstalalaciones(instalaciones,setAllEds);
    }
  }, [instalaciones]);

  useEffect(() => {
    if (allEds.length === 1) {
      changeEds(allEds[0]);
    }
  }, [allEds]);

  useEffect(async () => {
    if (selectedEds) {
      getDocs();
    }
  }, [selectedEds, selectedPeriodo]);

  async function changeEds(e) {
    if (e != selectedEds) {
      setSelectedEds(e);
      setDetalle([]);
      setDetalleCargado(false);
      setCabecera(null);
    }
  }

  const changePeriodo = (e) => {
    if (e != selectedPeriodo) {
      setSelectedPeriodo(e);
      setDetalle([]);
      setDetalleCargado(false);
      setCabecera(null);
    }
  };

  async function getDocs() {
    displayLoader();

    const selectedDate = selectedPeriodo.value.split("%3B");
    const month = selectedDate[0];
    const year = selectedDate[1];
    const periodo = month + year;

    let url =
      process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/balance-bonificacion?id_eds=" +
      selectedEds.value +
      "&rut_concesionario=" +
      rutConcesionario +
      "&periodo=" +
      periodo;

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      cleanBonificacionData(result.data);
    }
  }
  function cleanBonificacionData(data) {
    if(data.BONIFICACIONES){
      setDetalleCargado(true);
      setBonificaciones(data.BONIFICACIONES);
      let detalles=[];
      data.BONIFICACIONES.map((bonificacion)=>{
        bonificacion.DETALLE.map((detalle)=>detalles.push(detalle));
      })
      setDetalle(detalles);
    }
  }

  return (
    <div className="bonificacion">
      <div className="view-title">Pedidos Combustibles</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Bonificaciones de Preprograma</div>
      <hr className="title-separator-line" />

      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        cabecera={cabecerae}
        callServiceHandler={callServiceHandler}
      />

      <div className="bonificacion-options">
        <div className="filters-container">
          <div className="filter">
            <div className="filter-title">
              ESTACIÓN DE SERVICIO<span className="red-asterisk">*</span>
            </div>
            <div className="filter-input">
              <Select
                className="eds-select"
                value={selectedEds}
                onChange={(e) => changeEds(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allEds}
                components={{
                  SingleOption,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccionar"
                styles={customStylesPedidos}
              />
            </div>
          </div>
          <div className="filter filter-with-margin-left">
            <div className="filter-title">
              PERÍODO<span className="red-asterisk">*</span>
            </div>
            <div className="filter-input">
              <Select
                className="eds-select"
                value={selectedPeriodo}
                onChange={(e) => changePeriodo(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allPeriodos}
                components={{
                  SingleOption,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                autosize={false}
                styles={customStylesPedidos}
              />
            </div>
          </div>
        </div>
        <div className="actions">
          <div className="filter">
            {/* <div className="filter-input">
                  <input
                    type="search"
                    className="search"
                    value={filterText}
                    placeholder="Buscar..."
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                </div> */}
          </div>
          <div className="buttons">
            <PrintComponent 
              reff={mainRef}
              classes=""
              isROImpersonate={isROImpersonate}
            />

            <ExportExcel
              data={detalle} 
              labels={labels}
              isROImpersonate={isROImpersonate}
            />
          </div>
        </div>
      </div>

      <div className="main" ref={mainRef}>
        {bonificaciones ? (
          <>
            {bonificaciones.map((bonificacion) => {
              return (
                <>
                  <div className="datos onlyPrint">
                    <div className="infoDatos">
                      <div className="title">Balance de Preprograma</div>
                      <div className="title">Bonificaciones</div>
                      <div className="dato">Fecha: {parsedDate} </div>
                      <div className="dato">Concesionario: {clientName} </div>
                      <div className="dato">RUT: {rutConcesionario} </div>
                    </div>
                    <img src={logo} alt="" />
                  </div>
                  <div className="cabecera">
                    <div className="infob col4">
                      <div className="item">
                        <div className="title">UF</div>
                        <div className="value overflow">
                          {" "}
                          {getLocaleStringFloat(
                            bonificacion.CABECERA.UF
                          ).replace(/.([^.]*)$/, ",$1")}
                        </div>
                      </div>
                      <div className="item">
                        <div className="title">Bono Nocturno</div>
                        <div className="value overflow">
                          {" "}
                          {getLocaleStringNumber(
                            bonificacion.CABECERA.BONIFICACION_NOCHE
                          )}{" "}
                        </div>
                      </div>
                      <div className="item">
                        <div className="title">Bono Diurno</div>
                        <div className="value">
                          {" "}
                          {getLocaleStringNumber(
                            bonificacion.CABECERA.BONIFICACION_DIURNO
                          )}{" "}
                        </div>
                      </div>
                      <div className="item">
                        <div className="title">Pto. Venta</div>
                        <div className="value overflow">
                          {" "}
                          {removeCeros(bonificacion.CABECERA.PUNTO_VENTA)}{" "}
                        </div>
                      </div>
                      <div className="item">
                        <div className="title">CI. Pedido Ini.</div>
                        <div className="value overflow">
                          {" "}
                          {bonificacion.CABECERA.CODIGO_PEDIDO}{" "}
                        </div>
                      </div>
                    </div>

                    <div className={`documento-info`}>
                      {/* <div className="title">DETALLE SOLICITUD</div> */}
                      <div className="content">
                        <>
                          <table>
                            <tr className="headers">
                              <th></th>
                              <th>Volumen PP</th>
                              <th>Volumen</th>
                              <th>Bonificación</th>
                            </tr>

                            <tr className="data">
                              <td className="datatitle">Diurno</td>
                              <td>
                                {getLocaleStringNumber(
                                  bonificacion.CABECERA.VOLUMEN_PP_DIURNO
                                )}
                              </td>
                              <td>
                                {getLocaleStringNumber(
                                  bonificacion.CABECERA.VOLUMEN_DIURNO
                                )}
                              </td>
                              <td>
                                {getLocaleStringNumber(
                                  bonificacion.CABECERA.BONIFICACION_DIURNO
                                )}
                              </td>
                            </tr>
                            <tr className="data">
                              <td className="datatitle">Nocturno</td>
                              <td>
                                {getLocaleStringNumber(
                                  bonificacion.CABECERA.VOLUMEN_PP_NOCHE
                                )}
                              </td>
                              <td>
                                {getLocaleStringNumber(
                                  bonificacion.CABECERA.VOLUMEN_NOCHE
                                )}
                              </td>
                              <td>
                                {getLocaleStringNumber(
                                  bonificacion.CABECERA.BONIFICACION_NOCHE
                                )}
                              </td>
                            </tr>
                            <tr className="data">
                              <td className="datatitle">Totales</td>
                              <td>
                                {getLocaleStringNumber(
                                  bonificacion.CABECERA.VOLUMEN_PP_TOTAL
                                )}
                              </td>
                              <td>
                                {getLocaleStringNumber(
                                  bonificacion.CABECERA.VOLUMEN_TOTAL
                                )}
                              </td>
                              <td>
                                {getLocaleStringNumber(
                                  bonificacion.CABECERA.BONIFICACION_TOTAL
                                )}
                              </td>
                            </tr>
                          </table>

                          <table>
                            <tr className="headers">
                              <th></th>
                              <th></th>
                              <th>Adicional</th>
                              <th>Total</th>
                            </tr>

                            <tr className="data">
                              <td className="datatitle">Cumplimiento</td>
                              <td>
                                {getLocaleStringNumber(
                                  bonificacion.CABECERA.CUMPLIMIENTO
                                )}
                              </td>
                              <td>
                                {getLocaleStringNumber(
                                  bonificacion.CABECERA.CUMPLIMIENTO_ADICIONAL
                                )}
                              </td>
                              <td>
                                {getLocaleStringNumber(
                                  bonificacion.CABECERA.CUMPLIMIENTO_TOTAL
                                )}
                              </td>
                            </tr>
                            <tr className="data">
                              <td className="datatitle">Paga Abono</td>
                              <td>
                                {getLocaleStringNumber(
                                  bonificacion.CABECERA.PAGO_ABONO
                                )}
                              </td>
                              <td>
                                {getLocaleStringNumber(
                                  bonificacion.CABECERA.PAGO_ABONO_ADICIONAL
                                )}
                              </td>
                              <td>
                                {getLocaleStringNumber(
                                  bonificacion.CABECERA.PAGO_TOTAL_TOTAL
                                )}
                              </td>
                            </tr>
                            <tr className="data">
                              <td className="datatitle">OC</td>
                              <td colSpan="1">
                                {bonificacion.CABECERA.NUM_OC}
                              </td>
                            </tr>
                          </table>
                        </>
                      </div>
                      <div className="footer">
                        {/* <span className="important">*</span>
        <span>Observaciones: aquí pueden ir las observaciones</span> */}
                      </div>
                    </div>
                  </div>
                  <BonificacionTable
                    detalle={bonificacion.DETALLE}
                    detalleCargado={detalleCargado}
                  />
                </>
              );
            })}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Bonificacion;
