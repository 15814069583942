import React, { useContext, useState } from "react";
import CustomModal from "../CustomModal";
import { ViewPortContext } from "../../App";
import { getLocaleStringNumber, parseBudat } from '../utils/utils.js'
import { showGreyBackground, hideGreyBackground, hideLoader, scrollTo } from "../utils/windows";
import { displayLoader } from "../utils/windows";

const modalStyles = {
  content: {
    width: 350,
    transform: "translate(-50%,-50%)",
    padding: "38px 20px",
    left: "50%",
    top: "50%",
    right: "auto",
    bottom: "auto",
    boxSizing: "border-box",
    borderRadius: 9,
  },
};

const MandatosChooser = ({ 
  selectedTransactions, 
  setStep, 
  totalSaldo, 
  mandatosActivos, 
  rutConcesionario, 
  token, 
  mandato, 
  setMandato, 
  setPropuesta,
  setError,
  bancoName,
  cuenta,
  setBancoName,
  setCuenta,
  totalAPagar,
  setFechaPago,
  isArcoprime,
  selectedEdsArcoprime,
  setDisplayChequeEnLinea,
  setTotalReal,
  setTransaccionesPropuesta,
  callServiceHandler,
  displayCustomError }) => {
  //const { bottomCoordinate } = useContext(ViewPortContext);
  const bottomCoordinate = 0;

  const [ displayModal, setDisplayModal ] = useState(false);

  function closeModal() {
    setDisplayModal(false)
    hideGreyBackground()
  }

  function openModal() {
    setDisplayModal(true)
    showGreyBackground()
  }

  async function sendPayment(){
    displayLoader()

    const documentos = []

    selectedTransactions.forEach(function(transaction){
      const newDoc = {
        DOCSAP: transaction.DOCSAP,
        POSICION: transaction.POSICION,
        EJERCICIO: transaction.EJERCICIO
      }

      documentos.push(newDoc)
    })

    const debugData={
      selectedTransactions: selectedTransactions,
      totalSaldo: totalSaldo,
      banco: bancoName,
      tipo: "Cheque en Línea",
      isArcoprime: isArcoprime,
      selectedEdsArcoprime: selectedEdsArcoprime,
      totalAPagar: totalAPagar
  }

    let data = {};

    if(isArcoprime){
      data = {
        id_eds: selectedEdsArcoprime.value,
        DOCUMENTOS: documentos,
        mandato: mandatosActivos[mandato].ID_MANDATO,
        calculo:debugData
      };
    }else{
      data = {
        mandato: mandatosActivos[mandato].ID_MANDATO, 
        rut_concesionario: rutConcesionario, 
        DOCUMENTOS: documentos,
        calculo:debugData
      };
    }

    const url = process.env.REACT_APP_BASE_API + '/cuenta-corriente/crear-compensacion-cheque'

    let result = await callServiceHandler(url, "POST", JSON.stringify(data))
    closeModal()
    if(result !== "error"){
      if (result.data.GRUPO_PPTA !== "") {
        const url = process.env.REACT_APP_BASE_API +
          "/cuenta-corriente/obtener-historial-pagos?rut_concesionario=" + rutConcesionario +
          "&grupo_ppta=" + result.data.GRUPO_PPTA;

        const propuesta = await callServiceHandler(url, "GET")

        const fecha = parseBudat(propuesta.data.PROPUESTAS_PAGO[0].FECHA) + " - " + propuesta.data.PROPUESTAS_PAGO[0].HORA;
        setTransaccionesPropuesta(propuesta.data.PROPUESTAS_PAGO[0].DOCUMENTOS);
        setTotalReal(propuesta.data.TOTAL);
        setFechaPago(fecha);
        setPropuesta(result.data.GRUPO_PPTA);
        setStep(3);
        scrollTo(0,0);
      }else{
        displayCustomError("Error", "Se ha producido un error: " + result.data.MENSAJE, 'error')
        setDisplayChequeEnLinea(false);
      }
    }
    else{
      setError(true)
      setStep(3)
      setDisplayChequeEnLinea(false);
    }
  }

  function changeMandato (key,bancoN,cuentaN) {
    setMandato(key);
    setBancoName(bancoN);
    setCuenta(cuentaN);
  }

  return (
    <div className="cheque-en-linea">
      <table className="abonar-en-banco__table">
        <thead>
          <tr>
            <th>Banco</th>
            <th>Nº de cuenta</th>
            <th>Selección</th>
          </tr>
        </thead>

        <tr className="separator-table-row">
          <th colSpan="3" />
        </tr>
        <tbody>
          {mandatosActivos.map((mov, key) => (<>
            <tr key={key} className="data">
              <td className="bank-name-cell align-left">{mov.BANCO}</td>
              <td className="align-left">{mov.CUENTA}</td>
              <td>
                <input
                  type="radio"
                  name="mandato-radio"
                  value={key}
                  checked={key === mandato}
                  onChange={(e) => changeMandato(key,mov.BANCO,mov.CUENTA)}
                />
              </td>
            </tr>
            {mandatosActivos.length - 1 !== key ? (
                <>
                  <tr className="data separator">
                    <td className="trseparator align-left" colSpan="5">
                      <div className="line-sep"></div>
                    </td>
                  </tr>
                </>
              ) : (
                ""
              )}
            </>
          ))}
        </tbody>
      </table>

      <p className="abonar-en-banco__disclaimer">
        <span>Importante:</span> El cliente ha aceptado previamente los términos
        y condiciones de uso del Sitio Internet para pagos Copec S.A..
      </p>
      <hr />
      <div
        className="abonar-en-banco__step-actions"
        style={{ bottom: bottomCoordinate }}
      >
        <button
          onClick={() => setStep(1)}
          className="main-button main-button-red hallow "
        >
          Volver
        </button>
        <button
          className="main-button main-button-red"
          onClick={openModal}
          disabled={mandato === undefined}
        >
          Pagar
        </button>
      </div>

      <CustomModal
        displayModal={displayModal}
        closeModal={closeModal}
        acceptFunc={sendPayment}
        acceptText='Confirmar'
        cancelFunc={closeModal}
        modalTitle='Importante'
        modalText={`Está pagando con ${bancoName} Nº de cuenta ${cuenta}.<br />¿Desea continuar con la operación?`}
        modalIcon='error'
      />

    </div>
  );
};

export default MandatosChooser
