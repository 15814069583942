import React, { useState, useEffect,useRef } from "react";
import HeaderData from "../../HeaderData";
import { setInstalacionesDinamo, setInstalalaciones, setPagadoresArcoprime } from "../../utils/get";
import { getPeriodosSince, getPeriodosSinceYearAndMonth, getUpdateDate } from "../../utils/monthNames";
import HistorialFluctuacionesTable from "./HistorialFluctuacionesTable";
import Select from "react-select";
import { SingleOption, customStylesTctTae, parseDateString } from "../../utils/utils.js";
import ExportExcel from "../../ExportExcel";
import PrintComponent from "../../PrintComponent";
import { parentRedirect, scrollTo } from "../../utils/windows";
import ThreeAxisLineChart from "../../Charts/ThreeAxisLineChart";
import LineChart from "../../Charts/LineCharts";
import PrintFileHeader from "./PrintFileHeader";
import PrintFile from "./PrintFile";
import imprimir from "../../../assets/img/imprimir.svg";

const HistorialFluctuaciones = ({
  token,
  rutConcesionario,
  callServiceHandler,
  pagador,
  instalaciones,
  cabecera,
  isROImpersonate,
  edsPortal,
  setPagador
}) => {
  const [updateDate, setUpdateDate] = useState("");
  const [selectedEds, setSelectedEds] = useState()
  const [selectedPeriodo, setSelectedPeriodo] = useState()
  const [allPeriodos, setAllPeriodos] = useState([]);
  const [allEds, setAllEds] = useState([]);
  const [selectedTanque, setSelectedTanque] = useState()
  const [allTanques, setAllTanques] = useState([]);
  const [tanquesData, setTanquesData] = useState([]);
  const [tanqueData, setTanqueData] = useState([]);
  const tableRef = React.useRef(null);
  const [total, setTotal] = useState()
  const [graficoTripleData, setGraficoTripleData] = useState([]);
  const [graficoData, setGraficoData] = useState([]);
  const [displayTable, setDisplayTable] = useState(true);
  const [requestCounter, setRequestCounter] = useState(0);

  const [anioActivacion, setAnioActivacion] = useState();
  const [mesActivacion, setMesActivacion] = useState();

  const [isArcoprime, setIsArcoprime] = useState(false);
  const [allPagadores, setAllPagadores] = useState([]);
  const [selectedPagador, setSelectedPagador] = useState();

  const labels = [
    ["Fecha", "fecha"],
    ["Entradas", "entradas"],
    ["Calibraciones", "calibraciones"],
    ["Movimientos de Bodega", "movimientos_bodega"],
    ["Salidas", "salidas"],
    ["Salida Acumulada (lts)", "salida_acumulada_lts"],
    ["Inventario Teórico (lts)", "inventario_teorico"],
    ["Med. Física Proyectada (lts)", "medicion_fisica_proyectada"],
    ["Fluctuación Diaria (lts)", "fluctuacion_diaria_lts"],
    ["Fluctuación Acumulada (lts)", "fluctuacion_acumulada_lts"],
    ["% Fluctuacion Acumulada", "fluctuacion_porcentaje"],
  ];

  useEffect(() => {
    getUpdateDate(setUpdateDate);
    if(token && rutConcesionario && rutConcesionario === "77215640-5"){
      setIsArcoprime(true);
    }
  }, [token,rutConcesionario]);

  useEffect(() => {
    if (instalaciones !== "") {
      if(!isArcoprime){
        setInstalacionesDinamo(instalaciones, setAllEds)
      }
    }
  }, [instalaciones]);

  useEffect(() => {
    if (allEds.length === 1) {
      changeEds(allEds[0]);
    }
  }, [allEds]);

  function changeEds(e) {
    if (e !== selectedEds) {
      setSelectedEds(e);
    }
  }
  function changeSelectedPeriodo(e) {
    setSelectedPeriodo(e);
  }
  function changeSelectedTanque(e) {
    setSelectedTanque(e);
  }

  useEffect(async () => {
    if(selectedPagador){
      const url = process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/obtener-instalaciones-cliente?id_pagador=" + selectedPagador.value;

      let result = await callServiceHandler(url, "GET")
      if(result !== "error"){
        setInstalacionesDinamo(result.data, setAllEds);
      }
      setPagador(selectedPagador.value)
    }
  }, [selectedPagador]);

  useEffect(() => {
    if(edsPortal){
      setPagadoresArcoprime(edsPortal.data.user_eds,setAllPagadores);
    }
  }, [edsPortal]);

  useEffect(() => {
    if (allPagadores.length === 1) {
      changePagador(allPagadores[0]);
    }
  }, [allPagadores]);

  function changePagador(e) {
    setSelectedPagador(e);
  }

  useEffect(() => {
    if(selectedEds && selectedPeriodo){
      getHistorial()
    }
  }, [selectedEds,selectedPeriodo]);

  useEffect(() => {
    if (selectedTanque && tanquesData){
      setTanqueData(tanquesData.filter(tanque=>tanque.tanque===selectedTanque.value)[0]);
    }
  }, [selectedTanque,tanquesData]);

  useEffect(() => {
    if(tanqueData&&tanquesData){
      console.log("Tanque data",tanqueData)
      const newTotal={
        TOTAL_ENTRADAS:tanqueData.cierres?.reduce((suma,cierre)=>suma+=cierre.entradas,0),
        TOTAL_CALIBRACIONES:tanqueData.cierres?.reduce((suma,cierre)=>suma+=cierre.calibraciones,0),
        TOTAL_MOV_BODEGA:tanqueData.cierres?.reduce((suma,cierre)=>suma+=cierre.movimientos_bodega,0),
        TOTAL_SALIDAS:tanqueData.cierres?.reduce((suma,cierre)=>suma+=cierre.salidas,0)
      }
      setTotal(newTotal)

      const graficoTriplaDataAux = [];
      graficoTriplaDataAux.push(["Días","Salidas (lts)","Fluctuación Diaria (lts)"]);
      tanqueData.cierres?.forEach(cierre=>{
        graficoTriplaDataAux.push([parseDateString(cierre.fecha),cierre.salidas,cierre.fluctuacion_diaria_lts]);
      })
      setGraficoTripleData(graficoTriplaDataAux);

      const graficoDataAux = [];
      graficoDataAux.push(["Días","Entradas (lts)","Calibraciones","Mov. de Bodega","Salidas (lts)"]);
      tanqueData.cierres?.forEach(cierre=>{
        graficoDataAux.push([parseDateString(cierre.fecha),cierre.entradas,cierre.calibraciones,cierre.movimientos_bodega,cierre.salidas]);
      })
      setGraficoData(graficoDataAux);


    }
  }, [tanqueData]);

  useEffect(() => {
    if(selectedEds){
      getFechaActivacion()
    }
  }, [selectedEds]);

  useEffect(() => {
    if (anioActivacion && mesActivacion) {
      const newPeriodos = getPeriodosSinceYearAndMonth(anioActivacion, 
        mesActivacion);
      console.log("nuevito ", newPeriodos)
      setAllPeriodos(newPeriodos);
      setSelectedPeriodo(newPeriodos[0]);
    }
  }, [anioActivacion, mesActivacion]);

  /* useEffect(() => {
    console.log("triple",graficoTripleData[0][1])
  }, [graficoTripleData]); */

  async function getFechaActivacion() {

    const url =
    process.env.REACT_APP_FLUCTUACIONES_API +
    "/fluctuaciones/cierre-diario-listar-periodos?ID_EDS=" +
    selectedEds.value + 
    "&ID_PAGADOR=" + pagador;

    let response = await callServiceHandler(url, "GET");

    console.log("respuestaaa ",response)

    if (response !== "error") {
      setAnioActivacion(parseInt(response.data.año_activacion))
      setMesActivacion(parseInt(response.data.mes_activacion))
    }
  }

  async function getHistorial(){
    /* const url =
      process.env.REACT_APP_FLUCTUACIONES_API +
      "/fluctuaciones/cierre-mensual-obtener-cierre?ID_EDS=" +
      selectedEds.value +
      "&PERIODO="+selectedPeriodo.value+"&ID_PAGADOR="+pagador; */
      const url =
      process.env.REACT_APP_FLUCTUACIONES_API +
      "/fluctuaciones/cierre-diario-historial-fluctuaciones?ID_EDS=" +
      selectedEds.value +
      "&PERIODO="+selectedPeriodo.value+"&ID_PAGADOR="+pagador;

      let response = await callServiceHandler(url, "GET");
      console.log(response)
      if(response!=="error"){
        console.log(response.data)
        const tanquesAux = [];
        response.data.tanques.forEach(tanque => {
          tanquesAux.push({value:tanque.tanque,label:tanque.tanque+" - "+tanque.material})
        });
        setAllTanques(tanquesAux);
        setSelectedTanque(tanquesAux[0]);
        setTanquesData(response.data.tanques);
      }
  }

  function printFile(){
    setDisplayTable(true);
    setRequestCounter((old)=>old+1)
  }

  const goToCierreDiario = () => {
    parentRedirect(process.env.REACT_APP_CIERRE_DIARIO_PATH)
  }

  return (
    <div className="historial-fluctuaciones">
      <div className="view-title">Cierre de Operaciones</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Historial Fluctuaciones</div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandler={callServiceHandler}
        cabecera={cabecera}
        actualizable={true}
        updateFunction={getHistorial}
      />
      <div className="filters-container">
        {isArcoprime && 
          <div className="filter">
            <div className="filter-title">
              ID DE PAGADOR<span className="red-asterisk">*</span>
            </div>
            <div className="filter-input margin-right-32">
                <Select
                  className="eds-select"
                  value={selectedPagador}
                  onChange={(e) => changePagador(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allPagadores}
                  components={{
                    SingleOption,
                    IndicatorSeparator: () => null,
                  }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  width="192px"
                  styles={customStylesTctTae}
                />
              
            </div>
          </div>
        }
        <div className="filter">
          <div className="filter-title">
            ESTACIÓN DE SERVICIO<span className="red-asterisk">*</span>
          </div>
          <div className="filter-input margin-right-32">
            
              <Select
                className="eds-select"
                value={selectedEds}
                onChange={(e) => changeEds(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allEds}
                components={{
                  SingleOption,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccionar"
                width="192px"
                styles={customStylesTctTae}
              />
            
          </div>
        </div>
        <div className="filter">
          <div className="filter-title">
            PERÍODO<span className="red-asterisk">*</span>
          </div>
          <div className="filter-input margin-right-40">
            
              <Select
                className="eds-select"
                value={selectedPeriodo}
                onChange={(e) => changeSelectedPeriodo(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allPeriodos}
                components={{ SingleOption, IndicatorSeparator: () => null }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccionar"
                width="192px"
                styles={customStylesTctTae}
              />
            
          </div>
        </div>
        <div className="filter">
          <div className="filter-title">
            TANQUE<span className="red-asterisk">*</span>
          </div>
          <div className="filter-input margin-right-40">
            
              <Select
                className="eds-select"
                value={selectedTanque}
                onChange={(e) => changeSelectedTanque(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allTanques}
                components={{ SingleOption, IndicatorSeparator: () => null }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccionar"
                width="192px"
                styles={customStylesTctTae}
              />
            
          </div>
        </div>
        <div className={`filter${isArcoprime?" arcoprime":""}`}>
          <div className="filter-title">
            MEDICIÓN&nbsp;INICIAL
          </div>
          <div className={`filter-input${isArcoprime?" arcoprime":""}`}>
            <div className={`info-input${isArcoprime?" arcoprime":""}`}>
              {tanqueData?.medicion_inicial} &nbsp;
            </div>
          </div>
        </div> 

        
          <div className={`actions-container${isArcoprime?" arcoprime":""}`}>
          
          {displayTable?<>
            {/* <button className="main-button" onClick={()=>setDisplayTable((oldDisplay)=>!oldDisplay)}>
              Ver gráficos
            </button> */}
          </>:<>
            <button className={`main-button${!displayTable?" hallow":""}`} onClick={()=>setDisplayTable((oldDisplay)=>!oldDisplay)}>
              Volver
            </button>
          
          </>}
            
          {tanqueData &&
            <>
              <button className="main-button hallow" onClick={goToCierreDiario}>Ir al cierre diario</button>
              <a>
              <img src={imprimir} className="cursor-pointer" onClick={printFile} />
              </a>
              <PrintComponent 
                  reff={tableRef}
                  classes="cursor-pointer"
                  isROImpersonate={isROImpersonate}
                  requestCounter={requestCounter}
                  isHidden={true}
                />
              <ExportExcel 
              data={tanqueData?.cierres}
              labels={labels}
              isROImpersonate={isROImpersonate}
              />
            </> 
          }    
        </div>
      </div>      

      <HistorialFluctuacionesTable 
        tanqueData={tanqueData} 
        tanquesData={tanquesData} 
        total={total} 
        displayTable={displayTable} 
        tableRef={tableRef} 
        graficoData={graficoData} 
        graficoTripleData={graficoTripleData} 
        cabecera={cabecera} 
        selectedEds={selectedEds} 
        selectedPeriodo={selectedPeriodo} 
        rutConcesionario={rutConcesionario} 
      />
     
        {/* COPEC DEBE DEFINIR GRÁFICOS */}
        {/* {tanquesData.length>0 && <>
        <h3>Poner titulo al grafico</h3>
        {graficoTripleData && tanqueData?.cierres && <ThreeAxisLineChart data={graficoTripleData} />}
        <h3>Poner titulo al grafico</h3>
        {graficoData && tanqueData?.cierres && <LineChart data={graficoData} />}
        </>} */}
      
    </div>
  );
};

export default HistorialFluctuaciones;
