import React, {useEffect} from "react";
import Highlighter from 'react-highlight-words'

const CustomHighlighter = ({ text, children }) => {
	text = text.replaceAll('.', '')
	const searchArr = [text]		

	if(text.length >= 2){
		for(let i = 1; i < text.length; i++){
			let newText = text.substring(0,i) + '.' + text.substring(i)
			searchArr.push(newText)

			if(text.length >= i+3){
				newText = newText.substring(0,i+4) + '.' + newText.substring(i+4)
				searchArr.push(newText)
			}

			if(text.length >= i+6){
				newText = newText.substring(0,i+8) + '.' + newText.substring(i+8)
				searchArr.push(newText)
			}

			if(text.length >= i+9){
				newText = newText.substring(0,i+12) + '.' + newText.substring(i+12)
				searchArr.push(newText)
			}
		}
	}

  return (
  	<div className='highlighter-container'>
	    <Highlighter
		    highlightClassName="highlight"
		    searchWords={searchArr}
		    autoEscape={true}
		    textToHighlight={children.toString()}
		  />
	  </div>
  )
};

export default CustomHighlighter
