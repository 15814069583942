import React, { useState, useEffect } from "react"
import onClickOutside from "react-onclickoutside"

const NumericSelect = ({selected, setSelected, value, setValue, name, customClass = ''}) => {
  const [ openOptions, setOpenOptions ] = useState(false)

  function checkValue(v){
    setValue(parseInt(v))
  }

  NumericSelect['handleClickOutside_' + name] = () => { setOpenOptions(false) }

  return (
    <div className={`numeric-select ${customClass}`}>
      <div 
        className={`comparision-type ${selected === 'Todos' ? "all-selected" : ""}`}
        onClick={() => setOpenOptions(!openOptions)}
      >
        { selected }
      </div>
      <input
        className={`
          ${selected === '=' ? "equal-selected" : ""} 
          ${selected === '>=' || selected === '<=' ? "comparision-selected" : ""}
          ${selected === 'Todos' ? "all-selected" : ""}`
        }
        type="number" 
        value={value}
        placeholder="Todos"
        onClick={() => setOpenOptions(!openOptions)}
        onChange={(e) => checkValue(e.target.value)}
      />
      { openOptions &&
        <div className="numeric-select-options-container">
          <div className="numeric-select-option" onClick={() => setSelected('=') }>
            Igual a
          </div>
          <div className="numeric-select-option" onClick={() => setSelected('>=') }>
            Mayor igual Que...
          </div>
          <div className="numeric-select-option" onClick={() => setSelected('<=') }>
            Menor igual Que...
          </div>
          <div className="numeric-select-option" onClick={() => { setSelected('Todos'); setValue('') }}>
            Todos
          </div>
        </div>
      }
      
    </div>
  )
}

NumericSelect.prototype = {}

const clickOutsideConfig = {
  handleClickOutside: ({ props }) => NumericSelect['handleClickOutside_' + props.name]
};

export default onClickOutside(NumericSelect, clickOutsideConfig);
