import { Alert } from 'pharedata-custom-components';
import { displayLoader, hideLoader } from './windows';

export async function serviceCall(url, method, token, display403Error, displayGenericError, data = null, displayAndHideLoader = true){
    if(displayAndHideLoader){
    	displayLoader()
    }

	return await fetch(url, {
    method: method,
    headers: {token: token},
    body: data
  })
    .then((res) => {
        if(displayAndHideLoader){
        	hideLoader()
        }

    	if(res.status === 403){
    		display403Error()
    		return "error"
    	}
    	else if(res.status !== 200){
    		displayGenericError(res.status)
    		return "error"
    	}

    	return res.json()
    })
}