// REACT
import React from 'react';

// ASSETS
import greenCheck from "../../../assets/img/Check_green.svg"

const DetalleTarjeta = (
  {
    setDisplayConsultarConsumo,
    setDisplayDetalleTarjeta,
    numeroTarjeta,
    departamento,
    productoTarjeta,
    asignacionTarjeta,
    fechaVencimiento,
    maxGuia
  }
) => {

  const closeDetalleTarjeta = () => {
    setDisplayConsultarConsumo(true)
    setDisplayDetalleTarjeta(false)
  }

  return (
    <div className='detalle-tarjeta-container'>
      <div className="title-container">
        <div className="title">
          <div className="main-title">
            Detalle de Tarjeta
          </div>
          <div className="sub-title">
            {numeroTarjeta}
          </div>
        </div>
        <button className='main-button hallow thin' onClick={closeDetalleTarjeta}>Volver</button>
      </div>
      <div className="detalle-separator">
        <div class="text">DATOS CLIENTE</div>
        <div class="line"></div>
      </div>
      <div className="datos-tarjeta-container">
        <div className="first-row">
          <div className="filters-container">
            <div className="filter">
              <div className="filter-title">
                DEPARTAMENTO
              </div>
              <div className="filter-input">
                <input className='disabled-input cliente-input' type="text" placeholder={departamento} />
              </div>
            </div>
            <div className="filter">
              <div className="filter-title">
                TARJETA
              </div>
              <div className="filter-input">
                <input className='disabled-input cliente-input' type="text" placeholder={numeroTarjeta} />
              </div>
            </div>
          </div>
        </div>
        <div className="second-row">
          <div className="filters-container">
            <div className="filter">
              <div className="filter-title">
                ASIGNACIÓN TARJETA
              </div>
              <div className="filter-input">
                <input className='disabled-input cliente-input' type="text" placeholder={asignacionTarjeta} />
              </div>
            </div>
            <div className="filter">
              <div className="filter-title">
                PRODUCTO
              </div>
              <div className="filter-input">
                <input className='disabled-input cliente-input' type="text" placeholder={productoTarjeta} />
              </div>
            </div>
          </div>
        </div>
        <div className="tercer-row">
          <div className="filters-container">
            <div className="filter">
              <div className="filter-title">
                FECHA DE EMISIÓN
              </div>
              <div className="filter-input">
                <input className='disabled-input cliente-input' type="text" placeholder={fechaVencimiento} />
              </div>
            </div>
            <div className="filter">
              <div className="filter-title">
                FECHA DE VENCIMIENTO
              </div>
              <div className="filter-input">
                <input className='disabled-input cliente-input' type="text" placeholder={fechaVencimiento} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="detalle-separator">
        <div class="text">RESTRICCIONES PARA DIESEL</div>
        <div class="line"></div>
      </div>
      <div className="restricciones-disel-container">
        <div className="first-row">
          <div className="filters-container">
            <div className="filter">
              <div className="filter-title">
                VOLUMEN MÁXIMO DIARIO
              </div>
              <div className="filter-input">
                <input className='disabled-input cliente-input' type="text" placeholder={maxGuia} />
              </div>
            </div>
            <div className="filter">
              <div className="filter-title">
                VOLUMEN MÁXIMO POR COMPRA
              </div>
              <div className="filter-input">
                <input className='disabled-input cliente-input' type="text" placeholder={maxGuia} />
              </div>
            </div>
          </div>
        </div>
        <div className="second-row">
          <div className="filters-container">
            <div className="filter">
              <div className="filter-title">
                VOLUMEN MÁXIMO MENSUAL
              </div>
              <div className="filter-input">
                <input className='disabled-input cliente-input' type="text" placeholder={maxGuia} />
              </div>
            </div>
            <div className="filter">
              <div className="filter-title">
                N° DE COMPRAS DIARIAS
              </div>
              <div className="filter-input">
                <input className='disabled-input cliente-input' type="text" placeholder={maxGuia} />
              </div>
            </div>
          </div>
        </div>
        <div className="third-row">
          <div className="filters-container">
            <div className="filter">
              <div className="filter-title">
                MONTO MÁXIMO GUÍA MANUAL
              </div>
              <div className="filter-input">
                <input className='disabled-input cliente-input' type="text" placeholder={maxGuia} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="important-message success">
        <span className="success-icon"><img src={greenCheck}/></span>
        <span className="text">Usted puede consumir en toda la red de estaciones de servicio adheridas</span>
      </div>
      <hr/>
      <button className='main-button thin' onClick={closeDetalleTarjeta}>Cerrar</button>
    </div>
  );
}

export default DetalleTarjeta;
