import React, { useState, useEffect } from "react";
import HeaderData from "./HeaderData";
import { getUpdateDate } from "./utils/monthNames";
import DatePicker from "react-datepicker";
import { displayLoader, hideGreyBackground, hideLoader, showGreyBackground } from "./utils/windows";
import CustomModal from "./CustomModal";
import { parseDate } from "./utils/utils";
import ExportExcelHidden from "./ExportExcelHidden";

const ArchivoArcoprime = (props) => {
  const [updateDate, setUpdateDate] = useState("");
  const [dataArcoprime, setDataArcoprime] = useState([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModalFecha, setDisplayModalFecha] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [requestCounter, setRequestCounter] = useState(0)

  const labelsArchivo = [
    ["Clase de Documento", "CLAS_DOC"],
    ["Código Bancario", "COD_BANCARIO"],
    ["Códido de Rechazo", "COD_RECHAZO"],
    ["Documento de Pago", "DOC_PAGO"],
    ["Documento SAP de Pago", "DOC_SAP_PAGO"],
    ["Estado", "ESTADO"],
    ["Es Deudor", "ES_DEUDOR"],
    ["Fecha de Emisión", "F_EMISION"],
    ["Fecha de Pago", "F_PAGO"],
    ["Fecha de Rechazo", "F_RECHAZO"],
    ["Fecha de Vencimiento", "F_VTO"],
    ["Hora de Pago", "HORA_PAGO"],
    ["IND_CA", "IND_CA"],
    ["Mandato", "MANDATO"],
    ["Monto", "MONTO"],
    ["Nº Folio Legal", "N_FOLIO_LEGAL"],
    ["Nº Propuesta", "N_PROPUESTA"],
    ["Proceso", "PROCESO"],
    ["Rut Acreedor", "RUT_ACREEDOR"],
    ["Rut Filial", "RUT_FILIAL"],
    ["Tipo", "TIPO"],
  ];
  

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (props.token && props.rutConcesionario) {
        getUpdateDate(setUpdateDate);
      }
    } else {
      if (props.token) {
        getUpdateDate(setUpdateDate);
      }
    }
  }, [props.token, props.rutConcesionario]);

  useEffect(() => {
    if (props.cabecera!=="") {
      hideLoader();
    }
  }, [props.cabecera]);

  function openModal() {
    if(getRange()>2){
      setDisplayModalFecha(true);
    }else{
      setDisplayModal(true);
    }
    showGreyBackground();
  }

  function closeModal() {
    setDisplayModal(false);
    setDisplayModalFecha(false);
    hideGreyBackground();
  }

  async function generarArchivo () {
    displayLoader()
    setDataArcoprime([]);
    setDisplayModal(false);
    hideGreyBackground();

    const url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/datos-generar-archivo?actualizar=true";

    let result = await props.callServiceHandler(url, "GET")
    if(result !== "error"){
      if(result.data.RESPUESTA!=="Generación de archivo realizada, utilizar opción descarga para obtener archivo"){
        props.displayCustomError("Error", result.data.RESPUESTA, 'error')
      }
    }
  }

  async function descargarArchivo () {
    closeModal()
    let url =
    process.env.REACT_APP_BASE_API +
    "/cuenta-corriente/datos-generar-archivo?fecha_desde="+parseDate(startDate);

    if(endDate!==null){
      url+="&fecha_hasta="+parseDate(endDate);
    }

    let result = await props.callServiceHandler(url, "GET")
    if(result !== "error"){
      if(result.data.MENSAJE==="Archivo Generado"){

        //Antigua implementacion que exportaba un archivo de texto
        //const vals =  result.data.TABLA.map((tupla)=>Object.values(tupla));
        //downloadTxtFile(vals);

        //Nueva implementacion que exporta excel.
        setDataArcoprime(result.data.TABLA)
        setRequestCounter(old=>old=old+1);

      }else{
        props.displayCustomError("Error", result.data.MENSAJE, 'error')
      }
    }
    
  }

  const downloadTxtFile = (dataA) => {
    const element = document.createElement("a");
    let str = "";
    dataA.map((arr) => {
      let s = "";
      arr.map((val) => {
        s = s.concat(val);
        s = s.concat(";");
      });
      s = s.concat("\r\n");
      str = str.concat(s);
    });
    const file = new Blob([str], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = "myFile.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const getRange = () => {
    return Math.trunc((endDate-startDate)/(1000 * 3600 * 24));
  }

  return (
    <div className="resumen">
      {/* <button onClick={()=>getRange()} ></button> */}
      <div className="title-container">
        <div className="title">
          <div className="view-title">Cuenta corriente</div>
          <div className="view-title-separator">|</div>
          <div className="view-subtitle">Descarga Archivo de Pagos</div>
        </div>
      </div>

      <HeaderData
        rutConcesionario={props.rutConcesionario}
        token={props.token}
        actualizacion={updateDate}
        cabecera={props.cabecera}
        callServiceHandler={props.callServiceHandler}
      />

      <hr></hr>

      <div className="historial-arcoprime">
        <div className="arcoprime-title mtop">
          FECHA<span className="red-asterisk">*</span>
        </div>
        <DatePicker
          locale="es"
          className="arcoprime"
          selected={startDate}
          onChange={onDateChange}
          startDate={startDate}
          endDate={endDate}
          shouldCloseOnSelect={false}
          showDisabledMonthNavigation
          dateFormat="dd/MM/yyyy"
          selectsRange
          maxDate={new Date()}
        />
        <div className="arcoprime-title">
          <span className="smaller-title">
            Elige una fecha o un intervalo de 3 días a consultar.
          </span>
        </div>
        <hr className="sep"></hr>
        <button className="arcoprime-button" onClick={() => generarArchivo()}>
          Generar Archivo
        </button>
        {dataArcoprime.length > 0 ? <></> : ""}
        <button
          onClick={() => openModal()}
          className={`arcoprime-button ${
            getRange() > 2 ? "disabled" : ""
          }`}
        >
          Descargar
        </button>
        <ExportExcelHidden 
          data={dataArcoprime}
          labels={labelsArchivo}
          isROImpersonate={props.isROImpersonate}
          requestCounter={requestCounter}
          fileName={`Detalle de archivo ${parseDate(startDate)} ${endDate?"- "+parseDate(endDate):""}`}
        />
        
      </div>

      <CustomModal
        displayModal={displayModal}
        closeModal={closeModal}
        acceptFunc={descargarArchivo}
        acceptText='Descargar archivo'
        cancelFunc={closeModal}
        modalTitle='Importante'
        modalText='La generación del archivo para su descarga puede tardar varios segundos. Una vez generado se descargará automáticamente.'
        modalIcon='error'
      />

      <CustomModal
        displayModal={displayModalFecha}
        closeModal={closeModal}
        acceptFunc={closeModal}
        modalTitle='Advertencia'
        modalText='Por favor elija un intervalo de fecha no superior a 3 días.'
        modalIcon='error'
      />

    </div>
  );
};

export default ArchivoArcoprime;
