import React, { useState, useEffect }from "react";
import HeaderData from "../HeaderData";
import Select from "react-select";

import { SingleOption, 
  customStyles, 
  parseDate, 
  getLocaleStringNumber } from "../utils/utils.js";

import remove from "../../assets/img/remove.svg";
import errorIcon from "../../assets/img/atencion_rojo.svg";
import DatePicker from "react-datepicker";
import Timepicker from "../Timepicker";
import { allMateriales } from "../utils/materiales";
import { getUpdateDate } from "../utils/monthNames";
import { getConfiguracion, getConfiguracionTelesoporte } from "../utils/get";
import { displayLoader, hideLoader } from "../utils/windows";
import { ConfiguracionesViews } from "./ConfiguracionesViews"
import CustomModal from '../CustomModal'
import { AbonarEnBanco } from "../AbonarEnBanco/AbonarEnBanco";

const commonSelectProps = {
  className: "table-select",
  closeMenuOnSelect: true,
  hideSelectedOptions: false,
  options: [],
  components: { SingleOption, IndicatorSeparator: () => null },
  isClearable: false,
  autosize: false,
  placeholder: "Seleccionar",
  styles: customStyles
};

const commonDatePickerProps = {
  locale: "es",
  onChange: () => null,
  shouldCloseOnSelect: false,
  dateFormat: "dd/MM/yyyy",
  placeholderText: "Seleccionar",
};

const tipoOptions = [
  {
    label: 'Tanque',
    value: 'tanque'
  },
  {
    label: 'Camión',
    value: 'camion'
  }
]

export const ConfiguracionTanquesTelesoporte = ({token, rutConcesionario, callServiceHandler,cabecera}) => {
  const [step, setStep] = useState(1)
  const [updateDate, setUpdateDate] = useState('')
  const [allEds, setAllEds] = useState([])
  const [selectedEds, setSelectedEds] = useState()
  const [configuraciones, setConfiguraciones] = useState([])
  const [nuevaConfiguracion, setNuevaConfiguracion] = useState([])
  const [capacidadOptions, setCapacidadOptions] = useState([])
  const [materialesOptions, setMaterialesOptions] = useState([])
  const [errores, setErrores] = useState([])
  const [startDate, setStartDate] = useState()
  const [horaInicial, setHoraInicial] = useState()
  const [currentConfig, setCurrentConfig] = useState([])
  const [sifonesOptions, setSifonesOptions] = useState([])
  const [displayModal, setDisplayModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalText, setModalText] = useState('')
  const [modalType, setModalType] = useState()

  useEffect(() => {
    if(allEds.length === 1)
      setSelectedEds(allEds[0])
  }, [allEds])

  useEffect(() => {
    getUpdateDate(setUpdateDate)

    const capacidades = []
    for(let i = 5000; i <= 150000; i+=500){
      capacidades.push({label: getLocaleStringNumber(i), value: i})
    }
    setCapacidadOptions(capacidades)
  }, []);

  useEffect(async () => {
    if (token) {
      const url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/listar-eds"

      let response = await callServiceHandler(url, "GET")
      if(response !== "error"){
        if (response.data) {
          setAllEds(response.data)
        }
      }
    }
  }, [token]);

  useEffect(() => {
    if(selectedEds){
      getHistorial()
      getMateriales()
      getConfiguracionTelesoporte(callServiceHandler, selectedEds.value, setCurrentConfig)
    }
  }, [selectedEds]);

  function nuevaConfig(){

    getConfiguracionTelesoporte(callServiceHandler, selectedEds.value, setCurrentConfig)

    const tmpConfig = []

    if (currentConfig?.TANQUES) {
      currentConfig.TANQUES.forEach(function(c){
        const tipo = tipoOptions.find(t => t.value === c.TIPO)
        const mat = materialesOptions.find(m => m.value === c.ID_MATERIAL)
        const cap = capacidadOptions.find(cc => cc.value === c.CAPACIDAD)

        tmpConfig.push({
          tipo: tipo,
          id: c.ID,
          material: mat,
          capacidad: cap,
          sifon1: {label: c.SIFON_1 === ''? '-': c.SIFON_1, value: c.SIFON_1 === ''? null: c.SIFON_1},
          sifon2: {label: c.SIFON_2 === ''? '-': c.SIFON_2, value: c.SIFON_2 === ''? null: c.SIFON_2},
          sifon3: {label: c.SIFON_3 === ''? '-': c.SIFON_3, value: c.SIFON_3 === ''? null: c.SIFON_3},
          deshabilitado: c.DESHABILITADO
        })
      })
    }

    let copia = tmpConfig.slice();
    copia.sort(function(a, b) {
      let idA = a.id;
      let idB = b.id;
      if (typeof idA === "number" && typeof idB === "number") {
        return idA - idB;
      } else if (typeof idA === "number" && typeof idB === "string") {
        return -1;
      } else if (typeof idA === "string" && typeof idB === "number") {
        return 1;
      } else {
        return idA.localeCompare(idB);
      }
    });

    setNuevaConfiguracion(copia)
    // setNuevaConfiguracion(tmpConfig)

    setStep(2)
  }

  function displayAlert(type){
    setModalType(type)
    if(type === 'limpiar'){
      setModalTitle("¿Desea Limpiar la tabla?")
      setModalText("Se borrarán todos los datos ingresados.")
    }
    else{
      setModalTitle("¿Desea Cancelar sin guardar?")
      setModalText("Se borrarán todos los datos ingresados.")
    }
    setDisplayModal(true)
  }

  function closeModal(){
    setDisplayModal(false)
  }

  function clearTable(){
    setDisplayModal(false)
    setNuevaConfiguracion([{
      tipo: tipoOptions[0],
      id: 1,
      material: null,
      capacidad: null,
      sifon1: {label: '-', value: null},
      sifon2: {label: '-', value: null},
      sifon3: {label: '-', value: null},
      deshabilitado: "NO"
    }])
  }

  function cancel(){
    setHoraInicial()
    setStartDate()
    setDisplayModal(false)
    setStep(1)
  }

  function goBack(){
    setHoraInicial()
    setStartDate()
    setStep(2)
  }

  function addTanque(){
    const tmpConfig = [...nuevaConfiguracion]
    const count = nuevaConfiguracion.filter(c => c.tipo.value === 'tanque').length

    const newTanque = {
      tipo: tipoOptions[0],
      id: count + 1,
      material: null,
      capacidad: null,
      sifon1: null,
      sifon2: null,
      sifon3:null,
      deshabilitado: "NO"
    }
    tmpConfig.push(newTanque)
    setNuevaConfiguracion(tmpConfig)
  }

  function removeTanque(key){
    const tmpConfig = [...nuevaConfiguracion]
    tmpConfig.splice(key, 1)

    let tanquesCount = 0
    
    tmpConfig.forEach((config) => {
      if(config.tipo.value === 'tanque'){
        tanquesCount++
        config.id = tanquesCount
      }
    })

    setNuevaConfiguracion(tmpConfig)
  }

  function getSifonesOptions(num){
    const tmpOptions = [{ label: '-', value: null }]
    nuevaConfiguracion.forEach((config, key) => {
      if(num === key)
        return

      let onTheList = false
      for(let i = 1; i <= 3; i++){
        const sifonCompare = 'sifon' + i.toString()
        if(nuevaConfiguracion[num][sifonCompare] && 
          nuevaConfiguracion[num][sifonCompare].value && 
          nuevaConfiguracion[num][sifonCompare].value.toString() === config.id.toString())
          onTheList = true
      }
      if(onTheList) return

      if(config.tipo.value === 'tanque'){
        tmpOptions.push({
          label: config.id.toString(),
          value: config.id.toString()
        })
      }
    })
    return tmpOptions
  }

  function editConfiguracion(key, propName, value){
    if(propName === 'id' && value.length > 6)
      return

    const tmpConfig = [...nuevaConfiguracion]
    tmpConfig[key][propName] = value

    let tanquesCount = 0

    tmpConfig.forEach((config) => {
      if(config.tipo.value === 'tanque'){
        tanquesCount++
        config.id = tanquesCount.toString()
      }
    })

    if(propName === 'tipo' && value.value === 'camion'){
      tmpConfig[key].id = ''
      tmpConfig[key].sifon1 = null
      tmpConfig[key].sifon2 = null
      tmpConfig[key].sifon3 = null
    }

    if(propName === "deshabilitado"){
      value===true?tmpConfig[key].deshabilitado="SI":tmpConfig[key].deshabilitado="NO"
    }

    setNuevaConfiguracion(tmpConfig)
  }

  async function getHistorial(){
    displayLoader()
    const url =
      process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/historial-configuraciones?ID_EDS=" + selectedEds.value

    let response = await callServiceHandler(url, "GET")
    if(response !== "error"){
      setConfiguraciones(response.data)
    }
  }

  async function getMateriales(){
    const url =
      process.env.REACT_APP_PEDIDOS_API + "/pedidos/listar-materiales?id_eds=" + selectedEds.value + "&tipo=X"

    let response = await callServiceHandler(url, "GET")
    if(response !== "error"){
      const filteredMateriales = response.data.MATERIALES.filter((mat) => allMateriales[mat.ID_MATERIAL])
      const tmpMateriales = []

      filteredMateriales.forEach((material) => {
        tmpMateriales.push({
          label: material.NOMBRE,
          value: material.ID_MATERIAL
        })
      })

      /* const eds_sin_kerosene = [40145, 40435, 40034, 40107, 40112,10004,10006,10040,10080,10101,10223,10282,40110,40370,40504,40511,40516,40551,10397,40702,40100]

      if(eds_sin_kerosene.includes(parseInt(selectedEds.value))){
        tmpMateriales.push({
          "label": "KEROSENE",
          "value": "000000000000000009"
        })
      } */

      setMaterialesOptions(tmpMateriales)

      
    }
  }

  function checkTanques(){
    let error1 = false, error2 = false, error3 = false, error4 = false, error5 = false, error6 = false
    nuevaConfiguracion.filter(tanque=>tanque.deshabilitado==="NO").forEach((config) => {
      if(config.sifon1 && config.sifon1.value){
        const sifon = nuevaConfiguracion.find(c => c.id === config.sifon1.value)
        if(!sifon)
          error1 = true
        else{
          if(!((sifon.sifon1 && sifon.sifon1.value === config.id) || (sifon.sifon2 && sifon.sifon2.value === config.id) || (sifon.sifon3 && sifon.sifon3.value === config.id)))
            error1 = true
          else{
            if(!(config.material && sifon.material && config.material.value === sifon.material.value))
              error2 = true
          }
        }
      }
      if(config.sifon2 && config.sifon2.value){
        const sifon = nuevaConfiguracion.find(c => c.id === config.sifon2.value)
        if(!sifon)
          error1 = true

        else{
          if(!((sifon.sifon1 && sifon.sifon1.value === config.id) || (sifon.sifon2 && sifon.sifon2.value === config.id)|| (sifon.sifon3 && sifon.sifon3.value === config.id)))
            error1 = true
          else{
            if(!(config.material && sifon.material && config.material.value === sifon.material.value))
              error2 = true
          }
        }
      }
      if(config.sifon3 && config.sifon3.value){
        const sifon = nuevaConfiguracion.find(c => c.id === config.sifon3.value)
        if(!sifon)
          error1 = true

        else{
          if(!((sifon.sifon1 && sifon.sifon1.value === config.id) || (sifon.sifon2 && sifon.sifon2.value === config.id)|| (sifon.sifon3 && sifon.sifon3.value === config.id)))
            error1 = true
          else{
            if(!(config.material && sifon.material && config.material.value === sifon.material.value))
              error2 = true
          }
        }
      }
      if(config.tipo.value === 'tanque' && (!config.material || !config.capacidad))
        error3 = true

      if(config.tipo.value === 'camion' && (!config.material || !config.capacidad))
        error4 = true

      if(config.tipo.value === 'camion' && config.id.toString() === '')
        error5 = true

      if(config.tipo.value === 'camion' && config.id.toString().length  !== 6)
        error6 = true
    })

    const tmpErrores = []
    if(error1)
      tmpErrores.push("Tanques se deben sifonear en ambos sentidos.")
    if(error2)
      tmpErrores.push("Tanques sifoneados deben contener el mismo material.")
    if(error3)
      tmpErrores.push("Tanque ingresado no tiene todos sus campos seleccionados.")
    if(error4)
      tmpErrores.push("Camión ingresado no tiene todos sus campos seleccionados.")
    if(error5)
      tmpErrores.push("Todos los camiones deben tener un ID.")
    if(error6)
      tmpErrores.push("Los ID de los camiones deben tener 6 caracteres de largo.")

    setErrores(tmpErrores)
    if(tmpErrores.length === 0)
      setStep(3)
  }

  async function saveNuevaConfiguracion(){
    const tmpTanques = []

    nuevaConfiguracion.forEach((tanque) => {
      tmpTanques.push({
        TIPO: tanque.tipo.value,
        ID: tanque.id,
        MATERIAL: tanque.material?.label,
        ID_MATERIAL: tanque.material?.value,
        CAPACIDAD: tanque.capacidad?.value,
        SIFON_1: (tanque.sifon1 && tanque.sifon1.value && tanque.sifon1.value.toString()) || '',
        SIFON_2: (tanque.sifon2 && tanque.sifon2.value && tanque.sifon2.value.toString()) || '',
        SIFON_3: (tanque.sifon3 && tanque.sifon3.value && tanque.sifon3.value.toString()) || '',
        DESHABILITADO: tanque.deshabilitado
      })
    })

    const data = {
      FECHA_I: parseDate(startDate),
      HORA_I: horaInicial,
      ID_EDS: selectedEds.value,
      TANQUES: tmpTanques
    }

    const url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/insert-configuracion"
    console.log("DATA",data);
    let response = await callServiceHandler(url, "POST", JSON.stringify(data))
    if(response !== "error"){
      getHistorial()
      setStep(1)
      getConfiguracionTelesoporte(callServiceHandler, selectedEds.value, setCurrentConfig)

    }
  }

  useEffect(() => {
    console.log("NUEVA C",nuevaConfiguracion)
  }, [nuevaConfiguracion]);

  return (
    <div className="page">
      <div className="view-title">Fluctuaciones</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Configuración tanques</div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandle={callServiceHandler}
        cabecera={cabecera}
      />
      <hr />
      {step === 1 &&
        <ConfiguracionesViews 
          allEds={allEds}
          selectedEds={selectedEds}
          setSelectedEds={setSelectedEds}
          nuevaConfig={nuevaConfig}
          isTelesoporte={true}
          configuraciones={configuraciones}
        />
      }

      { step === 2 &&
        <div className="configuracion-tanques configuracion-tanques-big">
          <div className="page-options disabled-inputs-view">
            <div className="filters-container">
              <div className="filter">
                <div className="filter-title">ESTACIÓN DE SERVICIO</div>
                <div className="filter-input">
                  <input type="text" className="filter-input-element" value={selectedEds.value} disabled />
                </div>
              </div>
            </div>
          </div>
          <table className="flutuaciones-table new-configuration-table ">
            <thead>
              <tr>
                <th>Tipo</th>
                <th>ID</th>
                <th>Material</th>
                <th>Capacidad (Lts)</th>
                <th colSpan={3}>Sifoneado con</th>
                <th>No&nbsp;ope..</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="horizontal-separator" colSpan={6} />
              </tr>
              {
                nuevaConfiguracion.map((config, key) =>
                (
                <tr className={key < nuevaConfiguracion.length - 1? 'row-with-border': 'row-without-border'}>
                  
                  {config.deshabilitado === "SI"?<>
                    <td colSpan={7}>El tanque {config.id} ha sido deshabilitado pues no está operativo en la EDS</td>
                  </>:<>
                  <td className={`${key === 0? 'cell-border-top-left': ''} ${key === nuevaConfiguracion.length-1? 'cell-border-bottom-left': ''}`}>
                    <Select
                        {...commonSelectProps}
                        value={config.tipo}
                        onChange={(e) => editConfiguracion(key, 'tipo', e)}
                        options={tipoOptions}
                      />
                  </td>
                  <td>
                    <input 
                      className="table-input" 
                      type="text" 
                      value={config.id} 
                      onChange={(e) => editConfiguracion(key, 'id', e.target.value)} 
                      disabled={config.tipo.value === 'tanque'}
                    />
                  </td>
                  <td className="cell-with-large-select">
                    <Select
                      {...commonSelectProps}
                      value={config.material}
                      onChange={(e) => editConfiguracion(key, 'material', e)}
                      options={materialesOptions}
                      width="200px"
                      disabled={config.deshabilitado === "SI"}
                    />
                  </td>
                  <td>
                    <Select
                      {...commonSelectProps}
                      value={config.capacidad}
                      onChange={(e) => editConfiguracion(key, 'capacidad', e)}
                      options={capacidadOptions}
                    />
                  </td>
                  {[...Array(3)].map((x, i) =>
                    <td className="cell-with-small-select">
                      { config.tipo.value === 'tanque' &&
                        <Select
                          {...commonSelectProps}
                          value={config['sifon' + (i+1).toString()]}
                          onChange={(e) => editConfiguracion(key, 'sifon' + (i+1).toString(), e)}
                          options={getSifonesOptions(key)}
                          placeholder='-'
                        />
                      }
                      { config.tipo.value === 'camion' &&
                        <div className='fluctuaciones-disabled-select'>-</div>
                      }
                    </td>
                  )}

                  </>}
                  <td>
                    <input type={"checkbox"}
                    checked={config.tipo.value === 'camion'?false:config.deshabilitado === "SI"?true:false}
                    onChange={(e) => editConfiguracion(key, 'deshabilitado', e.target.checked)}
                    disabled={config.tipo.value === 'camion'}
                    />
                    { key !== 0 &&
                      <button className="remove-row-btn">
                        <img src={remove} alt="remove" onClick={() => removeTanque(key)} />
                      </button>
                    }
                  </td>
                  
                </tr>)
              )}
              
              <tr>
                <td className="horizontal-separator" colSpan={6} />
              </tr>
              <tr>
                <td
                  className="cell-border-top-left cell-border-top-right"
                  colSpan={8}
                >
                  <button className="main-button add-new-row" onClick={addTanque}>Agregar</button>
                </td>
              </tr>
            </tbody>
          </table>
          {
            errores.map((error) =>
            <div className="table-bottom-error">
              <img src={errorIcon} alt="error" />
              <p>{error}</p>
            </div>
          )}

          <hr />
          <div className="new-configurations-actions">
            <button className="main-button hallow" onClick={() => displayAlert('limpiar')}>Limpiar</button>
            <button className="main-button hallow" onClick={() => displayAlert('cancelar')}>Cancelar</button>
            <button className="main-button" onClick={checkTanques}>Continuar</button>
          </div>
        </div>
      }
      {step === 3 &&
        <div className="configuracion-tanques">
          <div className="page-options disabled-inputs-view">
            <div className="filters-container">
              <div className="filter">
                <div className="filter-title">ESTACIÓN DE SERVICIO</div>
                <div className="filter-input">
                  <input type="text" className="filter-input-element" value={selectedEds.value} disabled />
                </div>
              </div>
              <div className="filter">
                <div className="filter-title">CONFIGURACIÓN</div>
                <div className="filter-input">
                  <input type="text" className="filter-input-element" value={configuraciones.length + 1} disabled />
                </div>
              </div>
            </div>
          </div>
          <table className="flutuaciones-table new-configuration-duration-table">
            <thead>
              <tr>
                <th>Fecha inicio</th>
                <th>Hora inicio</th>
                <th>Fecha fin</th>
                <th>Hora fin</th>
              </tr>
            </thead>
            <tbody>
              {/* Horizontal  separator between head and body*/}
              <tr>
                <td className="horizontal-separator" colSpan={4} />
              </tr>
              <tr>
                <td className="cell-border-top-left cell-border-bottom-left">
                  <DatePicker
                    locale="es"
                    selected={startDate}
                    onChange={setStartDate}
                    shouldCloseOnSelect={true}
                    dateFormat="dd/MM/yyyy"
                    className="pedidos-datepicker"
                    placeholderText="Seleccionar"
                  />
                </td>
                <td>
                  <Timepicker 
                    onSelect={(e) => setHoraInicial(e)}
                    pickerPosition="top"
                  />
                </td>
                <td>
                  <input className="disabled-input-select" value="Indefinida" disabled/>
                </td>
                <td className="cell-border-bottom-right cell-border-top-right">
                  <input className="disabled-input-select" value="Indefinida" disabled/>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <div className="new-configurations-actions">
            <button className="main-button hallow red" onClick={goBack}>Volver</button>
            <button className="main-button hallow red" onClick={() => displayAlert('cancelar')}>Cancelar</button>
            <button className="main-button main-button-red" onClick={saveNuevaConfiguracion} disabled={!startDate || !horaInicial}>
              Guardar
            </button>
          </div>
        </div>
      }

      <CustomModal
        displayModal = { displayModal }
        closeModal = { closeModal }
        acceptFunc = { modalType === 'limpiar'? clearTable: cancel }
        cancelFunc = { closeModal }
        modalTitle = { modalTitle }
        modalText = { modalText }
        modalIcon = "error"
      />
    </div>
  );
};
