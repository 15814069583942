import React, { useState, useRef, useEffect } from "react";
import { SingleOption, 
  customStylesRegularizacion, 
  parseDate, 
  getLocaleStringRoundedInt } from "../utils/utils.js";
import Select from "react-select";
import CaretDown from "../../assets/img/caret-down-blue.svg";
import fileIcon from "../../assets/img/file.svg";
import pdfIcon from "../../assets/img/file-icon-pdf.svg";
import pptIcon from "../../assets/img/file-icon-ppt.svg";
import docIcon from "../../assets/img/file-icon-doc.svg";
import xlsIcon from "../../assets/img/file-icon-xls.svg";
import genericIcon from "../../assets/img/file-icon-generic.svg";
import crossIcon from "../../assets/img/cross-icon.svg";
import CustomModal from '../CustomModal'
import { hideGreyBackground, showGreyBackground } from "../utils/windows.js";
import { allMateriales } from "../utils/materiales.js";

const SolicitudRegularizacion = ({selectedTransaccion,setDisplaySolicitud,selectedEds,fecha,configuracion,callServiceHandler,fetchTransacciones,setSelectedTransaccion,pagador,isEditando,setIsEditando}) => {
  const [observaciones, setObservaciones] = useState("")
  const [litrosConfirmados, setLitrosConfirmados] = useState();
  const [pesosConfirmados, setPesosConfirmados] = useState()
  const [allLitrosConfirmados, setAllLitrosConfirmados] = useState([])
  const [tanque, setTanque] = useState()
  const [allTanques, setAllTanques] = useState([])
  const [uploadedFile, setUploadedFile] = useState();
  const [base64File, setBase64File] = useState();

  const [ displayModal, setDisplayModal ] = useState(false)
  const [ displayModal2, setDisplayModal2 ] = useState(false)
  const [ modalText, setModalText ] = useState('')
  const [ modalTitle, setModalTitle ] = useState('')
  const [ modalIcon, setModalIcon ] = useState("success")
  const [ modalAcceptText, setModalAcceptText ] = useState("")
  const [ acceptFunction, setAcceptFunction ] = useState('')
  const [ allTanquesForSelect, setAllTanquesForSelect ] = useState([])
  const [ idMaterialSAP, setIdMaterialSAP ] = useState("")
  const [ displayModal3, setDisplayModal3 ] = useState(false)

  useEffect(() => {
    if(selectedTransaccion){
      setAllLitrosConfirmados([{label:getLocaleStringRoundedInt(selectedTransaccion.LITROS.controlador), value:selectedTransaccion.LITROS.controlador}, {label:getLocaleStringRoundedInt(selectedTransaccion.LITROS.documentados), value:selectedTransaccion.LITROS.documentados}])
      if(selectedTransaccion.TIPO_VENTA==="combustible" || selectedTransaccion.TIPO_VENTA==="dte"){
        setLitrosConfirmados({label:getLocaleStringRoundedInt(selectedTransaccion.LITROS.controlador), value:selectedTransaccion.LITROS.controlador})
        setPesosConfirmados(selectedTransaccion.LITROS.controlador);
      }
      if(selectedTransaccion.TIPO_VENTA==="productos"){
        setLitrosConfirmados({label:getLocaleStringRoundedInt(selectedTransaccion.LITROS.documentados), value:selectedTransaccion.LITROS.documentados})
        setPesosConfirmados(selectedTransaccion.LITROS.documentados*selectedTransaccion.PRECIO);
      }
      if(selectedTransaccion.TANQUE!==""){
        setTanque(selectedTransaccion.TANQUE)
      }
      if(isEditando){
        setLitrosConfirmados({label:getLocaleStringRoundedInt(selectedTransaccion.INFO_GUARDADA.litros_confirmados), value:selectedTransaccion.INFO_GUARDADA.litros_confirmados})
        setTanque({label:selectedTransaccion.INFO_GUARDADA.tanque, value:selectedTransaccion.INFO_GUARDADA.tanque})
      }
    }    
  }, [selectedTransaccion]);

  useEffect(() => {
    if(configuracion){
      if(selectedTransaccion.MATERIAL==="GAS 95"){
        //Chequear si la configuracion tiene tanque de 95 o no
        if(configuracion.TANQUES.some(tanque=>allMateriales[tanque.ID_MATERIAL].titleCierre==="GAS 95")){
          const tanques95=configuracion.TANQUES.filter(tanque=>allMateriales[tanque.ID_MATERIAL].titleCierre==="GAS 95");
          const allTanquesAux = [];
          tanques95.forEach(tanque95=>{
            allTanquesAux.push({label:"Tanque "+tanque95.ID,value:"Tanque "+tanque95.ID})
          })
          setAllTanques(allTanquesAux);
        }else{
          const tanques93=configuracion.TANQUES.filter(tanque=>allMateriales[tanque.ID_MATERIAL].titleCierre==="GAS 93");
          const tanques97=configuracion.TANQUES.filter(tanque=>allMateriales[tanque.ID_MATERIAL].titleCierre==="GAS 97");
          const allTanquesAux = [];
          tanques93.forEach(tanque93=>{
            tanques97.forEach(tanque97=>{
              allTanquesAux.push({label:"Tanque "+tanque93.ID+"-"+tanque97.ID,value:"Tanque "+tanque93.ID+"-"+tanque97.ID})
            })
          })
          setAllTanques(allTanquesAux);
        }
        
       const ordenadosAux=[]
       configuracion.TANQUES.forEach(tanque=>{
          if(!ordenadosAux.some(t=>t.tanque.includes(tanque.ID))){
            console.log("tanque",tanque)
            if(tanque.SIFON_1==="" && tanque.SIFON_2===""){
              ordenadosAux.push({tanque:tanque.ID,id_material:tanque.ID_MATERIAL,material:tanque.MATERIAL})
              console.log("aqui")
            }else{
              let nuevoTanque=tanque.ID;
              if(tanque.SIFON_1!==""){
                nuevoTanque+="-"+tanque.SIFON_1
              }
              if(tanque.SIFON_2!==""){
                nuevoTanque+="-"+tanque.SIFON_2
              }
              ordenadosAux.push({tanque:nuevoTanque,id_material:tanque.ID_MATERIAL,material:tanque.MATERIAL})
            }
          }
        })
        console.log("Ordenados",ordenadosAux);

      }else{
        let idMatSAP=""
        const tanquesMaterial = configuracion.TANQUES.filter(tanque=>{
          if(selectedTransaccion.MATERIAL_SAP_LIST.includes(tanque.ID_MATERIAL)){
            idMatSAP=tanque.ID_MATERIAL;
          }
          return selectedTransaccion.MATERIAL_SAP_LIST.includes(tanque.ID_MATERIAL);
        });
        const allTanquesAux = [];

        console.log("TANQUES",tanquesMaterial);
  
        tanquesMaterial.forEach(tanque=>{
          const labelTipo = tanque.TIPO==="tanque"?"Tanque ":""
          allTanquesAux.push({label:labelTipo+tanque.ID,value:tanque.ID})
        })
        setAllTanques(allTanquesAux);
        setIdMaterialSAP(idMatSAP);
      }
    }
  }, [configuracion]);

  useEffect(() => {
    const tmpTanques = [{ label: 'Seleccionar', value: ''}]
    tmpTanques.push(... allTanques)
    setAllTanquesForSelect(tmpTanques)
  }, [allTanques])

  function parseHora(hora){
    if(!hora || hora.length <= 8)
      return hora

    const horaArr = hora.split("T")
    let horaStr = horaArr[1]
    if(!horaStr)
      horaStr = horaArr[0]

    return horaStr.substring(0,5)
  }

  function parseIdentificador(identificador){
    if(!identificador || identificador.length <= 14)
      return identificador

    return '...' + identificador.substring(identificador.length - 14, identificador.length)
  }

  function closeModal() {
    setDisplayModal(false)
    setDisplayModal2(false)
    setDisplayModal3(false)
    hideGreyBackground()
  }

  function exitStep(){
    if(fetchTransacciones)
      fetchTransacciones();
    if(setDisplaySolicitud)
    setDisplaySolicitud(false);
    closeModal();
    setSelectedTransaccion();
  }

  const hiddenFileInput = useRef(null);

  const handleUploadFile = (event) => {
    hiddenFileInput.current.click();
  };

  function getFileExtension(){
    const extension = uploadedFile.name.split('.')
    return extension[extension.length - 1].toUpperCase()
  }

  function getFileIcon(){
    const extension = getFileExtension()
    if(extension === 'PDF')
      return pdfIcon
    else if(extension === 'PPT')
      return pptIcon
    else if(extension === 'DOC' || extension === 'DOCX')
      return docIcon
    else if(extension === 'XLS' || extension === 'XLSX')
      return xlsIcon
    return genericIcon
  }

  function changeObservaciones(e) {
    if(observaciones.length<281){
        setObservaciones(e.target.value);
    }
  }

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(null, reader.result)
    }
    reader.onerror = function (error) {
      cb(error, null)
    }
  }

  useEffect(() => {
    if (uploadedFile){
      fileToBase64(uploadedFile, (err, result) => {
        if (result) {
          setBase64File(result)
        }
      })
    }
    else{
      setBase64File()
    }
  }, [uploadedFile]);

  function regularizar(){
    setModalTitle("Importante")
    let modalText = ""
      if(isEditando){
        modalText= "¿Esta seguro(a) que desea enviar este ajuste a la regularización?"
      }else{
        modalText = "¿Esta seguro(a) que desea enviar la regularización de esta transacción?"
      }
    setModalText(modalText)
    setModalIcon("error")
    setModalAcceptText("Sí, estoy seguro")
    setDisplayModal(true);
  }

  const enviarRegularizacion = async() => {
    //showGreyBackground();
    setDisplayModal(false);
    const url =
    process.env.REACT_APP_FLUCTUACIONES_API +
    "/fluctuaciones/ingresar-regularizacion";

    let data
    data = {
      ID_TRANSACCION: selectedTransaccion.ID_TRANSACCION,
      TRANSACCION: selectedTransaccion,
      OBSERVACIONES: observaciones,
      ARCHIVO: uploadedFile ? base64File : "",
      TIPO: uploadedFile ? uploadedFile.type : "",
      FECHA: parseDate(fecha),
      ID_EDS: selectedEds.value,
      LITROS_CONFIRMADOS: litrosConfirmados?.value,
      TANQUE: selectedTransaccion.TANQUE !== "" ? selectedTransaccion.TANQUE : tanque.value,
      ID_PAGADOR: pagador,
      ID_MATERIAL_SAP: idMaterialSAP
    };

    if(isEditando){
      data["EDITANDO"]=true
    }

    console.log(data)
    let result = await callServiceHandler(url, "POST",JSON.stringify(data));
    if (result !== "error"){
      const idRegularizacion = result.data.ID_REGULARIZACION;
      closeModal();
      setDisplayModal2(true);
      setModalTitle(isEditando?"Edición Exitosa":"Regularización Exitosa")
      let modalText = ""
      if(isEditando){
        modalText= "Ha enviado un ajuste a la regularización Nº"+idRegularizacion?.substring(idRegularizacion?.length - 35,idRegularizacion?.length)+"."
      }else{
        modalText = "Ha enviado la solicitud de regularización Nº"+idRegularizacion?.substring(idRegularizacion?.length - 35,idRegularizacion?.length)+"."
      }
      setModalText(modalText)
      setModalIcon("success")
      setModalAcceptText("Aceptar")
      
    }

    {result.data.ID_REGULARIZACION?.substring(result.data.ID_REGULARIZACION?.length - 35,result.data.ID_REGULARIZACION?.length)}
  }
  
  function getConfDocsLitros(){
    return parseInt(litrosConfirmados?litrosConfirmados.value - selectedTransaccion.LITROS.documentados:-selectedTransaccion.LITROS.documentados);
  }

  function getConfDocsPesos(){
    if(getLocaleStringRoundedInt(litrosConfirmados?.value)===getLocaleStringRoundedInt(selectedTransaccion.LITROS.documentados)){
      return 0
    }else{
      return - selectedTransaccion.PESOS.documentados
    }
    //return getLocaleStringRoundedInt(parseInt(litrosConfirmados?litrosConfirmados.value*selectedTransaccion.PRECIO - selectedTransaccion.PESOS.documentados:selectedTransaccion.PESOS.documentados));
  }

  function getValueConfirmado(){
    if(getLocaleStringRoundedInt(litrosConfirmados?.value)===getLocaleStringRoundedInt(selectedTransaccion.LITROS.documentados)){
      return selectedTransaccion.PESOS.documentados
    }else{
      return selectedTransaccion.PESOS.confirmados
    }
  }

  function checkData(){
    // if(litrosConfirmados?.value===selectedTransaccion.LITROS.documentados && tanque && observaciones && uploadedFile && tanque.value!==""){
    if(litrosConfirmados?.value===selectedTransaccion.LITROS.documentados && tanque && (observaciones || isEditando) && tanque.value!==""){
    return true
    }else {return false}
  }

  function changeUploadedFile(file){
    console.log(file.type)
    if(file.type==="application/pdf"){
      setUploadedFile(file);
    }else{
      console.log("aqui estoy")
      
      setDisplayModal3(true);
      setModalTitle("Importante")
      setModalText("Sólo se puede subir un archivo en formato .pdf. Por favor inténtelo nuevamente.")
      setModalIcon("importante")
      setModalAcceptText("Aceptar")
    }
    
  }

  function goBack(){
    setDisplaySolicitud(false)
    setIsEditando(false)
  }

  return (
    <div className="solicitud-regularizacion">
      <h1 className="title">Solicitud de regularización</h1>
      <p className="subtitle">{isEditando?"Editando regularización "+selectedTransaccion.ID_TRANSACCION:"Propuesta de regularización"}</p>

      <div className="first-row-info">
        <div className="info">
          <p className="title">ESTACIÓN DE SERVICIO</p>
          <p className="value">{parseInt(selectedEds.value)}</p>
        </div>
        <div className="info">
          <p className="title">FECHA</p>
          <p className="value">{parseDate(fecha)}</p>
        </div>
      </div>
      <div className="second-row-info">
        <div className="info">
          <p className="title">ORIGEN VENTA</p>
          <p className="value">{selectedTransaccion.ORIGEN_VENTA}</p>
        </div>
        <div className="info">
          <p className="title">FORMA COMERCIALIZ...</p>
          <p className="value">{selectedTransaccion.FORMA_COMERCIALIZACION}</p>
        </div>
        <div className="info">
          <p className="title">FORMA DE PAGO</p>
          <p className="value">{selectedTransaccion.FORMA_PAGO}</p>
        </div>
        <div className="info">
          <p className="title">DOCUMENTO DE VENTA</p>
          <p className="value">{selectedTransaccion.DOCUMENTO_VENTA}</p>
        </div>
        <div className="info">
          <p className="title">IDENTIFICADOR</p>
          <p className="value">{parseIdentificador(selectedTransaccion.ID_TRANSACCION)}</p>
        </div>
        <div className="info">
          <p className="title">HORA TRANSACCIÓN</p>
          <p className="value">{parseHora(selectedTransaccion.HORA)}</p>
        </div>
        <div className="info">
          <p className="title">MATERIAL</p>
          <p className="value">{selectedTransaccion.MATERIAL}</p>
        </div>
        <div className="info">
          <p className="title">PRECIO POR LITRO</p>
          <p className="value align-right">$ {getLocaleStringRoundedInt(selectedTransaccion.PRECIO)}</p>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>
              Litros<br></br>Controlador
            </th>
            <th>
              Litros<br></br>Documentado
            </th>
            <th>
              Litros<br></br>Confirmado
            </th>
            <th>
              (Lts) Documentado<br></br>- Controlador
            </th>
            <th>
              (Lts) Confirmado<br></br>- Documentado
            </th>
            <th>($) Controlador</th>
            <th>($) Documentado</th>
            <th>($) Confirmado</th>
            <th>
              ($) Documentado<br></br>-Controlador
            </th>
            <th>
              ($) Confirmado<br></br>-Documentado
            </th>
            <th>Tanque</th>
          </tr>
        </thead>
        <tr className="separator-table-row">
          <th colSpan="100%" />
        </tr>
        <tbody>
          <tr className="data">
            <td className="align-right">
              <div className="td-data">{getLocaleStringRoundedInt(selectedTransaccion.LITROS.controlador)}</div>
            </td>
            <td className="align-right">
              <div className="td-data">{getLocaleStringRoundedInt(selectedTransaccion.LITROS.documentados)}</div>
            </td>
            <td className="align-right">
              {/*  <div className="td-data">80</div> */}
              {selectedTransaccion?.TIPO_VENTA==="productos"?<div className="td-data">{getLocaleStringRoundedInt(litrosConfirmados?.value)}</div>:
                <Select
                className="eds-select cd-mb-select-last regularizacion-table-select"
                value={litrosConfirmados}
                onChange={(e) => setLitrosConfirmados(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allLitrosConfirmados}
                components={{ SingleOption, IndicatorSeparator: () => null }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccionar"
                styles={customStylesRegularizacion}
                />
              }
            </td>
            <td className="align-right">
              <div className="td-data">{getLocaleStringRoundedInt(selectedTransaccion.LITROS.documentados - selectedTransaccion.LITROS.controlador)}</div>
            </td>
            <td className="align-right">
              <div className={`td-data ${getConfDocsLitros()===0?"green":""}`}>{getConfDocsLitros()}</div>
            </td>
            <td className="align-right">
              <div className="td-data">{getLocaleStringRoundedInt(selectedTransaccion.PESOS.controlador)}</div>
            </td>
            <td className="align-right">
              <div className="td-data">{getLocaleStringRoundedInt(selectedTransaccion.PESOS.documentados)}</div>
            </td>
            <td className="align-right">
              <div className="td-data">{getLocaleStringRoundedInt(getValueConfirmado())}</div>
            </td>
            <td className="align-right">
              <div className="td-data">{getLocaleStringRoundedInt(selectedTransaccion.PESOS.documentados - selectedTransaccion.PESOS.controlador)}</div>
            </td>
            <td className="align-right">
              <div className={`td-data ${getConfDocsLitros()===0 && "green"}`}>{getConfDocsPesos()} </div>
            </td>
            <td className="align-right">
              {selectedTransaccion.TANQUE === ""?
                <Select
                  className="eds-select cd-mb-select-last regularizacion-table-select"
                  value={tanque}
                  onChange={(e) => setTanque(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allTanquesForSelect}
                  components={{ SingleOption, IndicatorSeparator: () => null }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesRegularizacion}
                />:<div className="td-data">{selectedTransaccion.TANQUE}</div>
              }              
            </td>
          </tr>
        </tbody>
      </table>

      <div className={`observaciones${isEditando?" edit":""}`}>
        <div className="info">
          <p className="title">OBSERVACIONES *</p>
          <textarea onChange={(e) => changeObservaciones(e)} maxLength="280" disabled={isEditando}>{isEditando?selectedTransaccion?.INFO_GUARDADA?.obs:""}</textarea>
          <p className="counter">{observaciones.length} /280</p>
        </div>
 
        {!uploadedFile && !isEditando &&
          <button className="add-file-button" onClick={handleUploadFile}>
            <img src={fileIcon} />
            <span>Adjuntar archivo</span>
          </button>
        }

        {uploadedFile &&
          <div className="uploaded-file-container">
            <div className="left-side">
              <img src={getFileIcon()} />
            </div>
            <div className="right-side">
              <div className="file-name">{uploadedFile.name}</div>
              <div className="file-type">Archivo {getFileExtension()}</div>
            </div>
            <div className="solicitud-regularizacion-cross-icon" onClick={() => setUploadedFile()}></div>
          </div>
        }
        
        <input
          className="hidden-file-input"
          type="file"
          ref={hiddenFileInput}
          accept="application/pdf"
          onChange={(e) => {
            changeUploadedFile(e.target.files[0])
          }}
          onClick={(event)=> { 
            event.target.value = null
          }}
        />

      </div>

      <div className="action-buttons-container">
        <button className="volver-button" onClick={()=>goBack()}>Volver</button>
        <button className={`regularizar-button ${checkData()? 'active': ''}`} onClick = {()=>regularizar()} disabled={!checkData()} >Regularizar</button>
      </div>
      <CustomModal
        displayModal = { displayModal }
        closeModal = { closeModal }
        acceptFunc = { enviarRegularizacion }
        cancelFunc={closeModal}
        acceptText={modalAcceptText}
        modalTitle = { modalTitle }
        modalText = { modalText }
        modalIcon = { modalIcon }
      />
      <CustomModal
        displayModal = { displayModal2 }
        closeModal = { exitStep }
        acceptFunc = { exitStep }
        acceptText={modalAcceptText}
        modalTitle = { modalTitle }
        modalText = { modalText }
        modalIcon = { modalIcon }
      />
      <CustomModal
        displayModal = { displayModal3 }
        closeModal = { closeModal }
        acceptFunc = { closeModal }
        acceptText={modalAcceptText}
        modalTitle = { modalTitle }
        modalText = { modalText }
        modalIcon = { modalIcon }
      />
    </div>
  );
};

export default SolicitudRegularizacion;
