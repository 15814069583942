import React, { useState, useEffect } from "react";

const HeaderBox = ({
  monthInfo,
  month,
  amount,
  isFirst,
  isLast,
  icon,
  color,
  addMoneySymbol = true
}) => {

  return (
    <div
      className={`header-data-container-multiple ${isFirst ? " first" : ""} ${
        isLast ? " last" : ""
      }`}
    >
      <div className="col">
        <div className="col-left">
          <div className="img-container">
            <img src={icon} />
          </div>
        </div>
      </div>
      <div className="col full">
        <div className="text-container">
          <div className="monto-total-title">{monthInfo}</div>
          <div className="monto-total-data">{month}</div>
        </div>
        <hr></hr>
        <div className={`monto ${color}`}>
          {`${addMoneySymbol? '$ ': ''}${amount}`}
        </div>
      </div>

    </div>
  );
};

export default HeaderBox;
