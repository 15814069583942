import React from "react";
import logo from "../../assets/img/logo-comprobante.svg";
import errorICon from "../../assets/img/error-icon.png";
import imprimir from "../../assets/img/imprimir.svg";
import descargar from "../../assets/img/descargar.svg";
import stamp from "../../assets/img/timbre-comprobante.svg";
import { parseDate,getLocaleStringNumber } from '../utils/utils.js'

export const ThirdStepContent = ({ error, end, abonos, bank, lineasAbono }) => {
  const date = new Date()
  const parsedDate = parseDate(date)

  function getTotal(){
    let total = 0

    abonos.map((abono) => {
      total += parseInt(abono.monto)
    })

    return getLocaleStringNumber(total)
  }

  return (
    <div className="abonar-en-banco__step-content">
      <div className="abonar-en-banco__report">
        <img src={logo} alt="" />
        <h2 className="abonar-en-banco__report__title">
          {error ? "Transacción rechazada" : "Comprobante de Abono"}
        </h2>
        <hr />
        <div className="abonar-en-banco__report__details">
          <p>
            <span>
              <strong>Cliente: </strong>
            </span>
            76.859.970-k Sociedad comercial teclube ltd
          </p>
          <p>
            <span>
              <strong>Dirección: </strong>
            </span>
            Avenida Diego portales Nº1072
          </p>
          <p>
            <span>
              <strong>Nº de Transacción: </strong>
            </span>
            XXXXXXXXXXXXX
          </p>
          <p>
            <span>
              <strong>Fecha: </strong>
            </span>
            { parsedDate }
          </p>
        </div>
        {error ? (
          <>
            <hr />
            <div className="abonar-en-banco__report__footer">
              <img src={errorICon} alt="" />
              <h3>Abono no realizado</h3>
              <p>La transacción no se ha completado</p>
            </div>
          </>
        ) : (
          <div className="abonar-en-banco__report__table-section">
            <table className="abonar-en-banco__report__table">
              <thead>
                <tr>
                  <th>Línea Producto</th>
                  <th>Fecha Abono</th>
                  <th>Monto</th>
                </tr>
              </thead>
              <tbody>
                {
                  abonos.map((abono, key) =>
                  <tr>
                    <td>{ lineasAbono.find(e => e.LINEA === abono.linea).DESCRIPCION }</td>
                    <td>{ parsedDate }</td>
                    <td>{ abono.monto }</td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="2">Total pago: { bank }</td>
                  <td>{ getTotal() }</td>
                </tr>
              </tfoot>
            </table>
            <img className="abonar-en-banco__report__stamp" src={stamp} />
          </div>
        )}
      </div>
      <div className="abonar-en-banco__report-actions">
        <button onClick={ end } className="main-button ">Finalizar</button>
        <button className="icon-btn">
          <img onClick={ window.print } src={imprimir} />
        </button>
        <button className="icon-btn">
          <img src={descargar} />
        </button>
      </div>
    </div>
  );
};
