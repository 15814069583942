import React, { useState, useEffect } from "react"
import Select from "react-select"
import removeIcon from "../../assets/img/remove.svg"

const ConfiguracionDinamo = ({configuracionDinamo, setNewData}) => {
	const [groupsFluctuaciones, setGroupsFluctuaciones] = useState([])
	const [groupsUCD, setGroupsUCD] = useState([])
	const [permiteMovimientos, setPermiteMovimientos]  = useState([])
	const [newEstacion, setNewEstacion] = useState("")
	const [newGroupFluctuaciones, setNewGroupFluctuaciones] = useState("")
	const [newGroupUCD, setNewGroupUCD] = useState("")

	useEffect(() => {
		if(configuracionDinamo){
			setGroupsFluctuaciones(configuracionDinamo.grupo_envio_alerta_eds.fluctuaciones)
			setGroupsUCD(configuracionDinamo.grupo_envio_alerta_eds.ultimo_cierre_diario)
			setPermiteMovimientos(configuracionDinamo.permite_movimientos_tanque_camion)
		}
	}, [configuracionDinamo])

	function save(){
		setNewData({
			configuracion_dinamo: {
				grupo_envio_alerta_eds: {
					fluctuaciones: groupsFluctuaciones,
					ultimo_cierre_diario: groupsUCD
				},
				permite_movimientos_tanque_camion: permiteMovimientos
			}
		})
	}

	function removeItem(constant, setter, key){

		const itemTmp = constant.slice()
		itemTmp.splice(key, 1)
		setter(itemTmp)
	}

	function changeEstacion(e){
		const value = e.target.value
		const isnum = /^\d+$/.test(value)
		if(!isnum && value.length > 0)
			return

		if(value.length <= 6)
			setNewEstacion(value)
	}

	function addEstacion(){
		const permiteMovimientosTmp = permiteMovimientos.slice()
		permiteMovimientosTmp.push(newEstacion)
		setPermiteMovimientos(permiteMovimientosTmp)
		setNewEstacion("")
	}

	function addItem(items, setItems, newItem, setNewItem){
		const itemsTmp = items.slice()
		itemsTmp.push(newItem)
		setItems(itemsTmp)
		setNewItem("")
	}

  return (
    <div className="selected-filter-container">
	    <div className="section">
	      <div className="section-title">Grupos de envios alertas EDS</div>
	      <div className="centered-container">
		      <div className="centered-container--left">
	    			<div className="section-title section-title--left">Fluctuaciones</div>
	    			{
	    				groupsFluctuaciones.map((group, key) =>
	    				<div className="info-text">
	    					{group}
	    					<img className="remove-item" src={removeIcon} onClick={() => removeItem(groupsFluctuaciones, setGroupsFluctuaciones, key)} />
	    				</div>
	    			)}
	    			<input placeholder="Grupo" type="text" className="correo-input" value={newGroupFluctuaciones} onChange={(e) => setNewGroupFluctuaciones(e.target.value)} />
	    			<button 
	    				className="nuevo-correo-button" 
	    				disabled={newGroupFluctuaciones.length === 0} 
	    				onClick={() => addItem(groupsFluctuaciones, setGroupsFluctuaciones, newGroupFluctuaciones, setNewGroupFluctuaciones)}
	    			>
	    				Agregar
	    			</button>
	    		</div>

	    		<div className="centered-container--right">
	    			<div className="section-title section-title--left">Ultimo Cierre Diario</div>
	    			{
	    				groupsUCD.map((group, key) =>
	    				<div className="info-text">
	    					{group}
	    					<img className="remove-item" src={removeIcon} onClick={() => removeItem(groupsUCD, setGroupsUCD, key)} />
	    				</div>
	    			)}
	    			<input placeholder="Grupo" type="text" className="correo-input" value={newGroupUCD} onChange={(e) => setNewGroupUCD(e.target.value)} />
	    			<button 
	    				className="nuevo-correo-button" 
	    				disabled={newGroupUCD.length === 0} 
	    				onClick={() => addItem(groupsUCD, setGroupsUCD, newGroupUCD, setNewGroupUCD)}
	    			>
	    				Agregar
	    			</button>
	    		</div>
    	

		    </div>
		  </div>
	    <hr />
	    <div className="section">
	      <div className="section-title">Permite movimientos tanque camion</div>
	      {
  				permiteMovimientos.map((eds, key) =>
  				<div className="info-text">
  					{eds}
  					<img className="remove-item" src={removeIcon} onClick={() => removeItem(permiteMovimientos, setPermiteMovimientos, key)} />
  				</div>
  			)}
  			<input placeholder="Ej: 12345" type="text" className="correo-input" value={newEstacion} onChange={(e) => changeEstacion(e)} />
	    	<button className="nuevo-correo-button" onClick={() => addItem(permiteMovimientos, setPermiteMovimientos, newEstacion, setNewEstacion)}>Agregar</button>
		  </div>
		  <hr />

		  <button className="personal-estacio-modal-button" onClick={save}>
      	Guardar
      </button>
    </div>
  )
}

export default ConfiguracionDinamo
