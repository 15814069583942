import React, { useEffect } from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import TimePickerLibrary from "../../TimePickerLibrary.js";
import { SingleOption, customStylesTctTae, parseDateAmerican, toDateFromAmerican } from "../../utils/utils.js";
import CustomModal from "../../CustomModal.js";

const ConsultarGuiasManualesInfo = (
  { 
    selectedTab, 
    setDisplayInfo,
    callServiceHandler,
    numeroGuia,
    fechaAutorizacion,
    horaAutorizacion,
    rutCliente,
    numeroTarjeta,
    codigoAutorizacion,
    comuna,
    direccion,
    giroComercial,
    producto,
    monto,
    nombreCliente,
    precio,
    unidad,
    flete,
    codigoCliente,
    numeroTransaccion,
    codigoProducto,
    codigoSubProducto,
    nombrePortal,
    rutPortal,
    getGuiasFecha,
    getGuia,
    setNumeroGuia,
    setDisplayGuiaTable,
    guiaEstado,
    accion
  }
) => {
  
  const allEntregas = [
    {
      value:"despacho-petroleo",
      label:"Despacho Petróleo"
    },
    {
      value:"retiro-otro",
      label:"Importante: Retiro/Otro"
    }
  ]
  const [selectedEntrega, setSelectedEntrega] = useState();
  const [horaVenta, setHoraVenta] = useState("0:00");
  const [fechaUsuario, setFechaUsuario] = useState();

  const [displaySuccessModal, setDisplaySuccessModal] = useState(false);
  const [displayConfirmacionEliminacionModal, setDisplayConfirmacionEliminacionModal] = useState(false);
  const [displayEliminacionModal, setDisplayEliminacionModal] = useState(false);

  const [errorExist, setErrorExist] = useState(false);
  const [mensajeError, setMensajeError] = useState();

  const [ordenCompra, setOrdenCompra] = useState("12345678");
  // const [ordenCompra, setOrdenCompra] = useState();
  const [montoOrdenCompra, setMontoOrdenCompra] = useState("324");
  // const [montoOrdenCompra, setMontoOrdenCompra] = useState();

  useEffect(() => {
    if (fechaAutorizacion) {
      console.log("fecha americana", fechaAutorizacion)
      console.log("fecha nueva", toDateFromAmerican(fechaAutorizacion))
      setFechaUsuario(toDateFromAmerican(fechaAutorizacion))
    }
  }, [fechaAutorizacion]);
  
  useEffect(() => {
    if (horaAutorizacion) {
      setHoraVenta(horaAutorizacion)
    }
  }, [horaAutorizacion]);
  
  useEffect(() => {
    if (fechaUsuario) {
      console.log("FECHA VEN",fechaUsuario)
    }
  }, [fechaUsuario]);


  const changeEntrega = (e) => {
    if (e !== selectedEntrega) {
      setSelectedEntrega(e);
    }
  }

  const openSuccessModal = () => {
    setDisplaySuccessModal(true)
    if (selectedTab === 1) {
      getGuia()
      setNumeroGuia()
      setDisplayGuiaTable(false)
    }
    if (selectedTab === 2) {
      getGuiasFecha()
      setNumeroGuia()
    }
  }

  const closeSuccessModal = () => {
    setDisplaySuccessModal(false)
  }

  const confirmarGuia = () => {
    setDisplaySuccessModal(false)
    setDisplayInfo(false)
    // console.log("holaaaa")
  }
  
  const openConfirmacionEliminacionModal = () => {
    setDisplayConfirmacionEliminacionModal(true)
  }

  const closeConfirmacionEliminacionModal = () => {
    setDisplayConfirmacionEliminacionModal(false)
  }
  
  const openEliminacionModal = () => {
    setDisplayEliminacionModal(true)
  }
  
  const closeEliminacionModal = () => {
    if (selectedTab === 1) {
      getGuia()
      setDisplayEliminacionModal(false)
      setDisplayInfo(false)
      setNumeroGuia()
    }
    if (selectedTab === 2) {
      getGuiasFecha()
      setDisplayEliminacionModal(false)
      setDisplayInfo(false)
      setNumeroGuia()
    }
  }
  
  const eliminarGuia = () => {
    anularGuiaManual()
  }

  const autorizarGuiaManual = async () => {
    let new_hora = horaVenta

    if (horaVenta.length <6) {
      new_hora = `${new_hora}:00`
    }

    console.log("hora modif", new_hora)

    let body = {
      codigo_cliente: codigoCliente,
      // sucursal_cliente: clienteSucursal, falta
      numero_tarjeta: numeroTarjeta,
      numero_transaccion: numeroTransaccion,
      fecha_transaccion: fechaAutorizacion,
      fecha_usuario: parseDateAmerican(fechaUsuario),
      hora_transaccion: new_hora,
      precio: precio,
      unidad: unidad,
      monto: (monto + flete),
      cod_producto: codigoProducto,
      cod_subproducto: codigoSubProducto
    }

    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/confirmar-guia";

    let response = await callServiceHandler(url, "POST", JSON.stringify(body) );

    console.log("confirmar", response)

    if (response.data.error === "") {
      openSuccessModal()
    }else{
      setErrorExist(true)
      setMensajeError(response.data.mensaje)
    }
  } 

  const anularGuiaManual = async () => {

    let body = {
      nombre_portal : nombrePortal,
      rut_portal : rutPortal,
      numero_tarjeta: numeroTarjeta,
      codigo_cliente: codigoCliente,
      numero_transaccion: numeroTransaccion,
    }

    console.log(body)

    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/anular-guia";
    

    let response = await callServiceHandler(url, "POST", JSON.stringify(body) );
    // let response = await callServiceHandler(url, "GET");

    console.log("anular", response)

    if (response.data.error === "") {
      openEliminacionModal()
    }else{
      setErrorExist(true)
      setMensajeError(response.data.mensaje)
    }
  }

  const handleVolver = () => {
    if (selectedTab === 2) {
      setNumeroGuia()
      setDisplayInfo(false)
    }
    if (selectedTab === 1) {
      setDisplayInfo(false)
    }
  }
  return (
    <div className="consultar-guias-manuales-info-container">
      <div className="numero-guia-container">
        <div className="numero-guia">
          <h1 className="title">Detalle Guía Manual</h1>
          <p className="subtitle">N° DE GUÍA - {numeroGuia}</p>
        </div>
        <button onClick={handleVolver}>Volver</button>
      </div>
      <div className="estacion-de-servicio">
        <div className="filters-container">
          <div className="filter">
            <div className="filter-title">INSTALACIÓN</div>
            <div className="filter-input">
              <input
                className="disabled-input"
                type="text"
                value={"1197 - 10197 RUTA 5 NORTE KM 1351"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="separator">
        <div className="text">DATOS DEL CLIENTE</div>
        <div className="line"></div>
      </div>
      <div className="datos-cliente-industrial">
        <div className="filters-container first-row">
          <div className="filter">
            <div className="filter-title">NOMBRE</div>
            <div className="filter-input">
              <input
                className="disabled-input nombre-input"
                type="text"
                value={nombreCliente}
              />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title">DIRECCIÓN</div>
            <div className="filter-input">
              <input
                className="disabled-input direccion-input"
                type="text"
                value={direccion}
              />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title">COMUNA</div>
            <div className="filter-input comuna-input">
              <input className="disabled-input" type="text" value={comuna} />
            </div>
          </div>
        </div>
        <div className="filters-container second-row">
          <div className="filter">
            <div className="filter-title">RUT</div>
            <div className="filter-input">
              <input
                className="disabled-input rut-input"
                type="text"
                value={rutCliente}
              />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title">GIRO COMERCIAL</div>
            <div className="filter-input">
              <input className="disabled-input giro-comercial-input" type="text" value={giroComercial} />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title">TARJETA</div>
            <div className="filter-input">
              <input
                className="disabled-input tarjeta-input"
                type="text"
                value={numeroTarjeta}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="separator">
        <div className="text">DATOS DE VENTA</div>
        <div className="line"></div>
      </div>
      <div className="datos-venta">
        <div className="filters-container first-row">
          <div className="filter">
            <div className="filter-title">N° DE GUÍA</div>
            <div className="filter-input">
              <input
                className="disabled-input nro-guia-input"
                type="text"
                value={"15553877"}
              />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title">FECHA DE VENTA</div>
            {/* <div className="filter-input disabled-date"> */}
            <div className={`filter-input ${guiaEstado !== "Autorizada"? "disabled-date": ""}`}>
              <ReactDatePicker
                locale="es"
                selected={fechaUsuario}
                onChange={(e) => setFechaUsuario(e)}
                shouldCloseOnSelect={true}
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()}
                placeholderText="Seleccionar"
                disabled={guiaEstado !== "Autorizada"} 
              />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title">HORA DE VENTA</div>
            {/* <div className="filter-input time-input disabled-time"> */}
            <div className={`filter-input time-input ${guiaEstado !== "Autorizada"? "disabled-time": ""}`}>
              <TimePickerLibrary
                initialHour={horaVenta}
                onSelect={(e) => setHoraVenta(e)}
                disabled={guiaEstado !== "Autorizada"}
              />
            </div>
          </div>
          <div className="filter">
            <div className="filter-title">CÓDIGO DE AUTORIZACIÓN</div>
            <div className="filter-input">
              <input className="disabled-input codigo-autorizacion-input" type="text" value={codigoAutorizacion} />
            </div>
          </div>
          {/* <div className="filter">
            <div className="filter-title">ORDEN DE COMPRA</div>
            <div className="filter-input">
              <input
                className="disabled-input nro-guia-input"
                type="text"
                value={"0"}
              />
            </div>
          </div> */}
        </div>
        <div className="filters-container second-row">
          <div className="filter">
            <div className="filter-title">RESPONSABLE G.M.</div>
            <div className="filter-input">
              <input className="disabled-input responsable-gm-input" type="text" value={"Conces"} />
            </div>
          </div>
          {
            ordenCompra &&
              <div className="filter">
                <div className="filter-title">ORDEN DE COMPRA</div>
                <div className="filter-input">
                  <input className="disabled-input codigo-autorizacion-input" type="text" value={ordenCompra} />
                </div>
              </div>
          }
          {
            montoOrdenCompra &&
              <div className="filter">
                <div className="filter-title">MONTO</div>
                <div className="filter-input">
                  <input className="disabled-input codigo-autorizacion-input" type="text" value={montoOrdenCompra} />
                </div>
              </div>

          }
        </div>
      </div>
      {/* <div className="consultar-guia-manual-info-table-container">
        <table className="solicitud-guia-manual-table">
          <thead>
            <tr>
              <th>Código</th>
              <th>Producto</th>
              <th>Litros</th>
              <th>Precio</th>
              <th>Monto</th>
            </tr>
          </thead>
          <tr className="separator-table-row">
            <th colSpan="100%" />
          </tr>
          <tbody>
            <tr className="data">
              <td className="align-left">
                <div className="td-data">A</div>
              </td>
              <td className="align-left">
                <div className="td-data">B</div>
              </td>
              <td className="align-left">
                <div className="td-data">C</div>
              </td>
              <td className="align-left">
                <div className="td-data">D</div>
              </td>
              <td className="align-left">
                <div className="td-data">E</div>
              </td>
            </tr>
          </tbody>
          <tr className="separator-table-row">
            <th colSpan="100%" />
          </tr>
          <tbody>
            <tr className="data">
              <td className="align-left">
                <div className="td-data">A</div>
              </td>
              <td className="align-left"></td>
              <td className="align-left"></td>
              <td className="align-left"></td>
              <td className="align-left">
                <div className="td-data">E</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
      <div className="table-prueba-container">
        <table >
          <thead>
            <tr>
              <th>Código</th>
              <th>Producto</th>
              <th>Litros</th>
              <th>Precio</th>
              <th>Monto</th>
            </tr>
          </thead>
          <tr className="separator-table-row">
            <th colSpan="100%" />
          </tr>
          <tbody>
            <tr>
              <td>
                  {codigoSubProducto}
              </td>
              <td>
                <div className="filters-container">
                  <div className="filter">
                    <div className="filter-input">
                      <input type="text" className='disabled-input w247' placeholder={producto}/>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="filters-container">
                  <div className="filter">
                    <div className="filter-input">
                      <input type="text" className='disabled-input w112'value={unidad}/>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="filters-container">
                  <div className="filter">
                    <div className="filter-input">
                      <input type="text" className='disabled-input w112' value={precio}/>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className="filters-container">
                  <div className="filter">
                    <div className="filter-input">
                      <input type="text" disabled="true" className='disabled-input w112' value={monto}/>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          {
            flete > 0 &&
            <>
              <tr className="separator-table-row">
                <th colSpan="100%" />
              </tr>
              <tbody>
                <tr>
                  <td colSpan={2} className="despacho">
                      Cargo por despacho extraordinario
                  </td>
                  <td></td>
                  <td></td>
                  <td>{flete}</td>
                </tr>
              </tbody>                  
            </>
          }
          <tr className="separator-table-row">
            <th colSpan="100%" />
          </tr>
          <tbody>
            <tr>
              <td>
                <div className="content w105">
                  Total Guía Manual
                </div>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td>${monto + flete}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {
        errorExist &&
          <div className="important-message error">
            <span className="error-icon">!</span>
            <span className="text">Error: {mensajeError}</span>
          </div>
      }

      <div className="button-area">
        {
          guiaEstado === "Autorizada" && accion &&
            <>
              <button className="main-button main-button-red thin hallow" onClick={openConfirmacionEliminacionModal}>Eliminar</button>
              <button className="main-button main-button-red thin" onClick={autorizarGuiaManual}>Confirmar</button>
            </>
        }
        {
          (guiaEstado === "Confirmada" || guiaEstado === "Anulada" || !accion)&&
            <button className="main-button thin hallow" onClick={handleVolver}>Volver</button>
        }
        {/* <button className="main-button thin">Guía de Despacho</button> */}
      </div>

      <CustomModal
        displayModal = { displaySuccessModal }
        closeModal = { closeSuccessModal }
        acceptFunc = { confirmarGuia }
        // cancelFunc={closeSuccessModal}
        acceptText= "Aceptar"
        modalTitle = "Éxito"
        modalText = {`
        La guía manual ha sido ingresada exitosamente
        `}
        modalIcon = "success"
      />
      
      <CustomModal
        displayModal = { displayConfirmacionEliminacionModal }
        closeModal = { closeConfirmacionEliminacionModal }
        acceptFunc = { eliminarGuia }
        cancelFunc={closeConfirmacionEliminacionModal}
        acceptText= "Aceptar"
        modalTitle = "¡Importante!"
        modalText = {`
        ¿Seguro desea eliminar autorización?
        `}
        modalIcon = "importante"
      />
      
      <CustomModal
        displayModal = { displayEliminacionModal }
        closeModal = { closeEliminacionModal }
        acceptFunc = { closeEliminacionModal }
        // cancelFunc={closeConfirmacionEliminacionModal}
        acceptText= "Aceptar"
        modalTitle = "Éxito"
        modalText = {`
        La guía manual ha sido eliminada exitosamente
        `}
        modalIcon = "success"
      />
      {/* {
        selectedTab === 1 &&
        <div className="tipo-entrega-container">
          <div className="filters-container">
            <div className="filter">
              <div className="filter-title">TIPO ENTREGA</div>
              <div className="filter-input">
                <Select
                  className="eds-select"
                  value={selectedEntrega}
                  onChange={(e) => changeEntrega(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allEntregas}
                  components={{
                    SingleOption,
                    IndicatorSeparator: () => null,
                  }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesTctTae}
                />
              </div>
            </div>
          </div>
        </div>
      } */}
      {/* {
        selectedTab == 1 &&
        <div className="important-message">
          <span className="title">Importante:&nbsp;</span>
          <span>
          Para ver esta carta de compromiso enviada hacer click acá
          </span>
        </div>
      } */}
    </div>
  );
};

export default ConsultarGuiasManualesInfo;
