/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect } from "react";
import logo from "../../assets/img/logo-comprobante.svg";
import errorICon from "../../assets/img/error-icon.png";
import successICon from "../../assets/img/success-icon.png";
import descargar from "../../assets/img/descargar.svg";
import stamp from "../../assets/img/timbre-comprobante.svg";
import {
  parseBudat,
  getLocaleStringNumber,
} from "../utils/utils.js";
import PrintComponent from "../PrintComponent";
import { ViewPortContext } from "../../App";
import { scrollTo } from "../utils/windows.js"
import html2pdf from 'html2pdf.js'

const ref = React.createRef();

const Comprobante = ({
  error,
  selectedTransactions,
  totalSaldo,
  rutConcesionario,
  banco,
  clientName,
  clientAddress,
  closeStepper,
  propuesta,
  title,
  bancoName,
  cuenta,
  totalAPagar,
  fechaPago,
  setSelectedTransactions,
  isROImpersonate
}) => {
  const today = new Date();
  const { bottomCoordinate } = useContext(ViewPortContext);

  useEffect(() => {
    scrollTo(0, 400)
  }, [])

  const finalizar = () => {
    closeStepper();
    setSelectedTransactions([]);
  }

  function printPdf(){
    const element = document.getElementById('comprobante-id')
    html2pdf(element, {filename: 'comprobante.pdf', margin: 5})
  }

  return (
    <div className="abonar-en-banco__step-content">
      <div className="abonar-en-banco__report" ref={ref} id='comprobante-id'>
        <img src={logo} alt="" />
        <h2 className="abonar-en-banco__report__title">
          {error ? "Transacción rechazada" : title}
        </h2>
        <hr />
        <div className="abonar-en-banco__report__details">
          <p>
            <span>
              <strong>Cliente: </strong>
            </span>
            {rutConcesionario} {clientName}
          </p>
          <p>
            <span>
              <strong>Dirección: </strong>
            </span>
            {clientAddress}
          </p>
          <p>
            <span>
              <strong>Nº de Transacción: </strong>
            </span>
            {propuesta}
          </p>
          <p>
            <span>
              <strong>Fecha: </strong>
            </span>
            {fechaPago}
          </p>
        </div>
        {error ? (
          <>
            <hr />
            <div className="abonar-en-banco__report__footer">
              <img src={errorICon} alt="" />
              <h3>Pago no realizado</h3>
              <p>La transacción no se ha completado</p>
            </div>
          </>
        ) : (
          <div className="abonar-en-banco__report__table-section">
            <table className="abonar-en-banco__report__table">
              <thead>
                <tr>
                  <th>
                    Fecha
                    <br />
                    Vencimiento
                  </th>
                  <th>
                    Tipo de
                    <br />
                    documento
                  </th>
                  <th>Nº Documento</th>
                  {/* <th>Descuento Pronto&nbsp;Pago&nbsp;(%)</th> */}
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                {selectedTransactions.map((mov, key) =>(<> 
                  <tr key={key} className="data">
                    <td className="align-left">
                      {parseBudat(mov.FECHA_VENCIMIENTO)}
                    </td>
                    <td className="align-left">
                      {title==="Comprobante de Compensación"?mov.TIPO_DOCUMENTO:mov.PRODUCTO}
                    </td>
                    <td className="align-left">{mov.NUMERO_DOCUMENTO}</td>
                    {/* <td className="align-left">{mov.DESCUENTO_PRONTO_PAGO}</td> */}
                    <td className="align-right">
                      ${" "}
                      {parseInt(mov.CARGO) > 0
                        ?  getLocaleStringNumber(parseInt(mov.CARGO))
                        : "-" + getLocaleStringNumber(parseInt(mov.ABONO))}
                    </td>
                  </tr>
                  <tr className="data separator">
                    <td className="trseparator align-left" colSpan="4">
                      <div className="line-sep"></div>
                    </td>
                  </tr>
                </>))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="3">
                    {title==="Comprobante de Compensación" && <><b>Saldo compensación:</b></>}
                    {title==="Comprobante Cheque en Línea" && <><b>Total pago:</b> {bancoName} {cuenta!==""?"Nº Cuenta "+cuenta:""}</>}
                  </td>
                  <td className="align-right">
                    <b>${getLocaleStringNumber(totalAPagar)}</b>
                  </td>
                </tr>
                {title==="Comprobante Cheque en Línea" && <>
                <tr>
                  <td colSpan="4" className="forceleft">
                    <b>Sujeto a confirmación del Banco</b>
                  </td>
                </tr>
                </>} 
              </tfoot>
            </table>
            {title!=="Comprobante Cheque en Línea" && <><img className="abonar-en-banco__report__stamp" src={stamp} /></>} 
          </div>
        )}
      </div>

      <div className="abonar-en-banco__report-mobile">
        <div className="abonar-en-banco__report__footer">
          {error ? (
            <>
              <img src={errorICon} alt="" />
              <h3>Pago no realizado</h3>
              <p>La transacción no se ha completado</p>
            </>
          ) : (
            <>
              <img src={successICon} alt="" />
              <h3>Transacción exitosa</h3>
              <p>
                La transacción ha sido realizada con éxito. Nº de Transacción:
                {propuesta}
              </p>
            </>
          )}

          <button
            onClick={printPdf}
            className="abonar-en-banco__report-mobile__open-voucher"
          >
            Ver comprobante
          </button>
        </div>
      </div>

      <div
        className="abonar-en-banco__report-actions"
        style={{ bottom: bottomCoordinate }}
      >
        <button className="main-button" onClick={()=>finalizar()}>
          Finalizar
        </button>
        <PrintComponent 
          reff={ref}
          classes="icon-btn cursor-pointer"
          pageStyle="@page { size: 250mm 170mm; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }"
          isROImpersonate={isROImpersonate}
        />
        <button onClick={printPdf} className="icon-btn cursor-pointer">
          <img src={descargar} />
        </button>
      </div>
    </div>
  );
};

export default Comprobante;
