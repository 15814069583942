import React, { useEffect, useState, useRef } from "react";
import HeaderData from "../HeaderData";
import TabSelector from "../TabSelector";
import { getUpdateDate } from "../utils/monthNames";
import AvisosDeMantenimientoTable from "./AvisosDeMantemientoTable";
import Select from "react-select";
import { SingleOption, customStylesPedidos, parseBudat, parseDate } from "../utils/utils";
import DatePicker from "react-datepicker";
import PrintComponent from "../PrintComponent";
import ExportExcel from "../ExportExcel";
import { getPaginationItems, openBase64NewTab, setInstalalaciones, setPagadoresArcoprime } from "../utils/get";
import Pagination from "react-js-pagination";
import MantenimientoCard from "./MantenimientoCard";
import { eachWeekOfInterval } from "date-fns";

const AvisosDeMantemiento = ({
  rutConcesionario,
  token,
  pagador,
  cabecera,
  instalaciones,
  isROImpersonate,
  edsPortal,
  callServiceHandler,
}) => {
  const [updateDate, setUpdateDate] = useState();
  const [selectedTab, setSelectedTab] = useState(1);
  const [allEds, setAllEds] = useState([]);
  const [selectedEds, setSelectedEds] = useState();
  const [fechaDesde, setFechaDesde] = useState();
  const [fechaHasta, setFechaHasta] = useState();
  const [filterText, setFilterText] = useState("");
  const tableRef = useRef(null);
  const [ordenValue, setOrdenValue] = useState("");
  const [orden, setOrden] = useState();
  const [avisoValue, setAvisoValue] = useState("");
  const [aviso, setAviso] = useState();
  const [avisos, setAvisos] = useState();
  const [filteredAvisos, setFilteredAvisos] = useState();

  const [paginationActivePage, setPaginationActivePage] = useState(1);
	const [paginationFilteredAvisos, setPaginationFilteredAvisos] = useState([]);
  const paginationItemsPerPage = parseInt(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE);

  //Arcoprime
  const [isArcoprime, setIsArcoprime] = useState(false);
  const [allPagadores, setAllPagadores] = useState([]);
  const [selectedPagador, setSelectedPagador] = useState();

  const labels = [
    ["Nº de Orden", "NUMERO_ORDEN"],
    ["Nº de Aviso", "NUMERO_AVISO"],
    ["Fecha de Aviso", "FECHA_AVISO"],
    ["Fecha de Cierre", "FECHA_CIERRE"],
    ["Descripción", "DESCRIPCION"],
    ["Prioridad", "PRIORIDAD"],
    ["Producto", "PRODUCTO"],
    ["Equipo", "EQUIPO"],
    ["Contratista", "CONTRATISTA"],
    ["Factura", "FACTURA"],
  ];

  async function changeEds(e) {
    reset()
    setSelectedEds(e);
  }

  function reset() {
    setAviso();
    setOrden();
    setFechaDesde();
    setFechaHasta();
    setAvisos();
    setFilteredAvisos()
    setPaginationFilteredAvisos([])
    setPaginationActivePage(1);
    setOrdenValue("");
    setAvisoValue("");
  }

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (token && rutConcesionario) {
        getUpdateDate(setUpdateDate);
        if (rutConcesionario === "77215640-5") {
          setIsArcoprime(true);
        }
      }
    } else {
      if (token) {
        getUpdateDate(setUpdateDate);
        if (rutConcesionario === "77215640-5" /* || true */) {
          setIsArcoprime(true);
        }
      }
    }
  }, [token, rutConcesionario]);

  useEffect(() => {
    if (instalaciones!==""){
      if(!isArcoprime){
        setInstalalaciones(instalaciones,setAllEds);
      }
    }
  }, [instalaciones]);

  useEffect(() => {
    if(edsPortal){
      setPagadoresArcoprime(edsPortal.data.user_eds,setAllPagadores);
    }
  }, [edsPortal]);

  useEffect(() => {
    if (allPagadores.length === 1) {
      changePagador(allPagadores[0]);
    }
  }, [allPagadores]);

  function changePagador(e) {
    setSelectedPagador(e);
  }

  useEffect(async () => {
    if(selectedPagador){
      const url = process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/obtener-instalaciones-cliente?id_pagador=" + selectedPagador.value;

      let result = await callServiceHandler(url, "GET")
      if(result !== "error"){
        setInstalalaciones(result.data, setAllEds);
      }
    }
  }, [selectedPagador]);

  useEffect(() => {
    if (allEds) {
      changeEds(allEds[0]);
    }
  }, [allEds]);

  function handleOrderEnter(e) {
    if (e.key === "Enter") {
      getByOrden(ordenValue);
    }
  }

  function handleAvisoEnter(e) {
    if (e.key === "Enter") {
      getByAviso(avisoValue);
    }
  }

  useEffect(() => {
    if(fechaHasta){
      getAvisos();
    }
  }, [fechaHasta]);

  useEffect(() => {
    setFilteredAvisos(avisos);
    if(avisos?.length>0){
      getPaginationItems(avisos,1,setPaginationFilteredAvisos,setPaginationActivePage)
    }
  }, [avisos]);

  function handlePageChange(pageNumber) {
		getPaginationItems(filteredAvisos, pageNumber, setPaginationFilteredAvisos, setPaginationActivePage);
  }

  useEffect(() => {
    console.log("PAGINATED",paginationFilteredAvisos)
  }, [paginationFilteredAvisos]);

  async function getByOrden(numeroOrden) {
    
    let url =
      process.env.REACT_APP_MANTENIMIENTO_API +
      "/mantenimiento/obtener-avisos?rut_concesionario=" +
      rutConcesionario +
      "&id_eds=" +
      selectedEds.value +
      "&orden=" +
      numeroOrden;

    let result = await callServiceHandler(url, "GET");
    console.log("AVISOO", result);
    if (result !== "error") {
      if (result.data.ORDENES.length === 0) {
        setOrden({ vacio: true });
      } else {
        const dataFinal = result.data.ORDENES[0];
        dataFinal["vacio"] = false;
        setOrden(dataFinal);
      }
    }
  }

  async function getByAviso(numeroAviso) {
    console.log(selectedEds);
    let valor = selectedEds.value;
    console.log(valor);
    let url =
      process.env.REACT_APP_MANTENIMIENTO_API +
      "/mantenimiento/obtener-avisos?rut_concesionario=" +
      rutConcesionario +
      "&id_eds=" +
      selectedEds.value +
      "&aviso=" +
      numeroAviso;

    let result = await callServiceHandler(url, "GET");
    console.log("AVISOO", result);
    if (result !== "error") {
      if (result.data.ORDENES.length === 0) {
        setAviso({ vacio: true });
      } else {
        const dataFinal = result.data.ORDENES[0];
        dataFinal["vacio"] = false;
        setAviso(dataFinal);
      }
    }
  }

  async function getAvisos() {
  
    let url =
      process.env.REACT_APP_MANTENIMIENTO_API +
      "/mantenimiento/obtener-avisos?rut_concesionario=" +
      rutConcesionario +
      "&id_eds=" +
      selectedEds.value + "&fecha_desde="+parseDate(fechaDesde)+"&fecha_hasta="+parseDate(fechaHasta);

    let result = await callServiceHandler(url, "GET");
    console.log(result);

    if (result !== "error") {
      if (result.data.ORDENES.length === 0) {
        setAvisos({ vacio: true });
      } else {
        const dataFinal = cleanAvisos(result.data.ORDENES);
        dataFinal["vacio"] = false;
        setAvisos(dataFinal);
      }
    }
  }

  function cleanAvisos (avisosdata) {
    const newAvisos = [];

    console.log("AVISOS DATA",avisosdata)

    avisosdata.map((aviso)=>{
      const newAviso = {
        NUMERO_ORDEN:aviso.numero_orden,
        NUMERO_AVISO:aviso.numero_aviso,
        FECHA_AVISO:parseBudat(aviso.fecha_aviso),
        FECHA_CIERRE:aviso.fecha_cierre !== "0000-00-00" ? parseBudat(aviso.fecha_cierre) : "En Progreso",
        DESCRIPCION: aviso.descripcion,
        PRIORIDAD: aviso.prioridad !== "" ? aviso.prioridad : "N/A",
        PRODUCTO:aviso.producto,
        EQUIPO:aviso.equipo !== "" ? aviso.equipo : "N/A",
        FACTURA:aviso.factura?aviso.factura:" ",
        TIENE_ADJUNTOS:aviso.tiene_adjuntos,
        ADJUNTOS:aviso.ADJUNTOS,
        CONTRATISTA:aviso.puesto_de_trabajo,
        URL:aviso.url
      }
      newAvisos.push(newAviso);
    })

    return newAvisos;

  }

  async function getAdjunto() {
    let url =
      process.env.REACT_APP_MANTENIMIENTO_API +
      "/mantenimiento/obtener-adjunto?rut_concesionario=" +
      rutConcesionario;

    let result = await callServiceHandler(url, "GET");
    console.log(result);

    if (result !== "error") {
    }
  }

  function searchOrder(numero) {
    console.log("Hice enter de este numero: " + numero);
  }

  function changeFechaDesde(e) {
    setFechaDesde(e);
    setFechaHasta();
  }

  function changeFechaHasta(e) {
    setFechaHasta(e);
  }

  async function openFile(adjunto){
    console.log("ADJUNTO:",adjunto);
    
    let url =
      process.env.REACT_APP_MANTENIMIENTO_API +
      "/mantenimiento/obtener-adjunto?id_sap=" +
      adjunto.id_sap;

    let result = await callServiceHandler(url, "GET");
    console.log(result.data);
    if (result.data.error === true) {
      return true;  
    } else {
      openBase64NewTab(result.data.body,result.data.tipo);
    }
  }

  function getMaxDate(fechaD){
    const now = new Date();
    const minMasUno = new Date(fechaD?.getFullYear()+1,fechaD?.getMonth(),fechaD?.getDate())

    if(now>minMasUno){
      return minMasUno;
      
    }else{
      return now;
    }
  }


  return (
    <div className="pedidos-activos page">
      <div className="view-title">Mantenimiento</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Avisos de Mantenimiento</div>
      <hr className="title-separator-line" />

      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        cabecera={cabecera}
        callServiceHandler={callServiceHandler}
      />

      <TabSelector
        tab1="Buscar por Nº Orden"
        tab2="Buscar por Nº Aviso"
        tab3="Buscar por Fecha"
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        reset={reset}
      />

      {selectedTab === 1 && (
        <div className="order-container">
          <div className="order-options">
            <div className="filters-container">
            {isArcoprime && 
              <div className={`filter`}>
                <div className="filter-title required-field">
                 ID DE PAGADOR
                </div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedPagador}
                    onChange={(e) => changePagador(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allPagadores}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesPedidos}
                  />
                </div>
              </div>  
              }
              <div className={`filter ${isArcoprime && "mleft"}`}>
                <div className="filter-title required-field">
                  ESTACIÓN DE SERVICIO
                </div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedEds}
                    onChange={(e) => changeEds(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allEds}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesPedidos}
                  />
                </div>
              </div>
              <div className="filter mleft">
                <div className="filter-title required-field">
                  NÚMERO DE ORDEN
                </div>
                <div className="filter-input">
                  <input
                    type="number"
                    class="orden-input"
                    id="orden-input"
                    placeholder="Ej: 546465464"
                    value={ordenValue}
                    onChange={(e) => setOrdenValue(e.target.value)}
                    onKeyDown={handleOrderEnter}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          {typeof orden === "undefined" && <>
          <h2>{/* Busca por número de orden. A la espera de si negocio define mensaje default, como en la tabla. */}</h2>
          </>}
          {orden && (
            <>{orden.vacio ? 
              <div className="order-data">
                <div className="important-message error">
                  <span className="error-icon">!</span>
                  <span>No existen datos para este Nº de orden.</span>
                </div>
              </div>
            : <>
            <MantenimientoCard orden={orden} openFile={openFile} tipo="aviso" />
            </>}
            </>
          )}
        </div>
      )}

      {selectedTab === 2 && (
        <div className="order-container">
          <div className="order-options">
            <div className="filters-container">
            {isArcoprime && 
              <div className={`filter`}>
                <div className="filter-title required-field">
                 ID DE PAGADOR
                </div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedPagador}
                    onChange={(e) => changePagador(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allPagadores}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesPedidos}
                  />
                </div>
              </div>  
              }
              <div className={`filter ${isArcoprime && "mleft"}`}>
                <div className="filter-title required-field">
                  ESTACIÓN DE SERVICIO
                </div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedEds}
                    onChange={(e) => changeEds(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allEds}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesPedidos}
                  />
                </div>
              </div>
              <div className="filter mleft">
                <div className="filter-title required-field">
                  NÚMERO DE AVISO
                </div>
                <div className="filter-input">
                  <input
                    type="number"
                    class="orden-input"
                    id="aviso-input"
                    placeholder="Ej: 546465464"
                    value={avisoValue}
                    onChange={(e) => setAvisoValue(e.target.value)}
                    onKeyDown={handleAvisoEnter}
                  ></input>
                </div>
              </div>
            </div>
          </div>
          {typeof aviso === "undefined" && <>
          <h2>{/* Busca por número de aviso. Esperar si negocio define mensaje default. */}</h2>
          </>}
          {aviso && (
            <>{aviso.vacio ? 
              <div className="order-data">
                <div className="important-message error">
                  <span className="error-icon">!</span>
                  <span>No existen datos para este Nº de aviso.</span>
                </div>
              </div>     
            : <>
            <MantenimientoCard orden={aviso} openFile={openFile} tipo="aviso" />
            </>}
            </>
          )}
        </div>
      )}

      {selectedTab === 3 && (
        <>
          <div className="fecha-options">
            <div className="filters-container">
              {isArcoprime && 
              <div className={`filter`}>
                <div className="filter-title required-field">
                 ID DE PAGADOR
                </div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedPagador}
                    onChange={(e) => changePagador(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allPagadores}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesPedidos}
                  />
                </div>
              </div>  
              }
              <div className={`filter ${isArcoprime && "mleft"}`}>
                <div className="filter-title required-field">
                  ESTACIÓN DE SERVICIO
                </div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedEds}
                    onChange={(e) => changeEds(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allEds}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesPedidos}
                  />
                </div>
              </div>
              <div className="filter mleft">
                <div className="filter-title required-field">FECHA DESDE</div>
                <div className="filter-input">
                  <DatePicker
                    locale="es"
                    selected={fechaDesde}
                    onChange={(e) => changeFechaDesde(e)}
                    shouldCloseOnSelect={true}
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    /* minDate={ultimaFluctuacionDate?ultimaFluctuacionDate:null} */
                    placeholderText="Seleccionar"
                    /* disabled={step !== 1} */
                  />
                </div>
              </div>
              <div className="filter mleft">
                <div className="filter-title required-field">FECHA HASTA</div>
                <div className="filter-input">
                  <DatePicker
                    locale="es"
                    selected={fechaHasta}
                    onChange={(e) => changeFechaHasta(e)}
                    shouldCloseOnSelect={true}
                    dateFormat="dd/MM/yyyy"
                    maxDate={getMaxDate(fechaDesde)}
                    /* minDate={new Date(fechaDesde?.getFullYear(),fechaDesde?.getMonth(),fechaDesde?.getDate()+1)} */
                    minDate={fechaDesde}
                    placeholderText="Seleccionar"
                    disabled={fechaDesde ? false : true}
                  />
                </div>
              </div>
              <div className="filter">
                <div className="filter-input">
                  <input
                    type="search"
                    className="search"
                    value={filterText}
                    placeholder="Buscar..."
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="actions">
              <PrintComponent
                reff={tableRef}
                pageStyle="@page { size: auto; margin: 3mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }"
                /* classes={`${isArcoprime ? "arcoprime" : ""}`} */
                isROImpersonate={isROImpersonate}
              />
              <ExportExcel
                data={filteredAvisos}
                labels={labels}
                isROImpersonate={isROImpersonate}
                /* isArcoprime={isArcoprime} */
              />
            </div>
          </div>
          <AvisosDeMantenimientoTable 
            tableRef={tableRef} 
            avisos={avisos} 
            setAvisos={setAvisos} 
            filteredAvisos={filteredAvisos} 
            setFilteredAvisos={setFilteredAvisos} 
            filterText={filterText} 
            paginationFilteredAvisos={paginationFilteredAvisos} 
            setPaginationActivePage={setPaginationActivePage} 
            setPaginationFilteredAvisos={setPaginationFilteredAvisos} 
            openFile={openFile}
            paginationItemsPerPage={paginationItemsPerPage}
            paginationActivePage={paginationActivePage}
          />
          <>{avisos ?
          <div className="pagination-container">
            <Pagination 
              activePage={paginationActivePage}
              itemsCountPerPage={parseInt(paginationItemsPerPage)}
              totalItemsCount={filteredAvisos?.length}
              pageRangeDisplayed={5}
              onChange={e => handlePageChange(e)}
              hideFirstLastPages={true}
              linkClassPrev='prev'
              linkClassNext='next'
              prevPageText=''
              nextPageText=''
            />
          </div>
          :<>
          </>}
          </>
        </>
      )}
    </div>
  );
};

export default AvisosDeMantemiento;
