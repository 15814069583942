import React, { useContext, useEffect } from "react";
import Modal from "react-modal";
import { spaceAboveIframe } from "./utils/utils";
import { customModalStyles } from "./utils/styles";
import { scrollTo } from "./utils/windows";
import closeIcon from "../assets/img/cross-icon.svg";
import { ViewPortContext } from "../App";

export const CartolaTableMobileOptionsModal = ({
  children,
  closeModal,
  title,
  confirm,
  confirmLabel,
  cancelLabel,
  disabledConfirm,
  cancel,
}) => {
  const { viewPortHeight } = useContext(ViewPortContext);

  const styles = customModalStyles(20);
  styles.content.padding = 0

  useEffect(() => {
    scrollTo(0, 0)
  }, [])

  return (
    <Modal isOpen={true} onRequestClose={closeModal} style={styles}>
      <div
        className="mobile-filters"
        style={{
          maxHeight: `${(viewPortHeight - spaceAboveIframe) * 0.9}px` /*90vh */,
        }}
      >
        <div className="mobile-filters__title">
          <h1>{title}</h1>
          <button className="mobile-filters__close-btn" onClick={closeModal}>
            <img src={closeIcon} alt="close modal" />
          </button>
        </div>
        <div className="mobile-filters__main-section">{children}</div>
        <div className="mobile-filters__footer">
          <button className="main-button hallow" onClick={cancel}>
            {cancelLabel}
          </button>
          <button
            disabled={disabledConfirm}
            className="main-button"
            onClick={() => {
              confirm();
              closeModal();
            }}
          >
            {confirmLabel}
          </button>
        </div>
      </div>
    </Modal>
  );
};
