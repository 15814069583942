import React, { useState, useEffect,useRef } from "react";
import Select from "react-select";
import HeaderData from "../HeaderData";
import { SingleOption, customStylesTctTae, getExcelWithXlsx, getExcelWithXlsx2 } from "../utils/utils.js";
import download from "../../assets/img/DOWNLOAD.svg";


import {
  displayLoader,
  hideGreyBackground,
  hideLoader,
  parentRedirect,
  scrollTo,
  showGreyBackground
} from "../utils/windows";
import VentasTable from "./VentasTable"
import FluctuacionesTable from "./FluctuacionesTable"
import JustificacionForm from "./JustificacionForm"
import { getPeriodosSince, getPeriodosSinceYearAndMonth, getUpdateDate } from "../utils/monthNames";
import descargar from "../../assets/img/descargar.svg"
import { setInstalacionesDinamo, setPagadoresArcoprime} from "../utils/get";
import CustomModal from "../CustomModal";
import { async } from "q";

const CierreMensual = ({
  token,
  rutConcesionario,
  callServiceHandler,
  pagador,
  instalaciones,
  cabecera, 
  edsPortal,
  setPagador
}) => {

  const [updateDate, setUpdateDate] = useState("")
  const [selectedEds, setSelectedEds] = useState()
  const [selectedPeriodo, setSelectedPeriodo] = useState()
  const [allPeriodos, setAllPeriodos] = useState([]);
  const [justificacionOpen, setJustificacionOpen] = useState(false)
  const [allEds, setAllEds] = useState([]);
  const [ventas, setVentas] = useState()
  const [totalVentas, setTotalVentas] = useState()
  const [fluctuaciones, setFluctuaciones] = useState()
  const [estadoCierre, setEstadoCierre] = useState("")
  const [estadoCierreDiario, setEstadoCierreDiario] = useState();
  const [selectedInfo, setSelectedInfo] = useState();
  const [selectedTable, setSelectedTable] = useState();
  const [displayCierreModal, setDisplayCierreModal] = useState(false)
  const [displaySuccessModal, setDisplaySuccessModal] = useState(false)
  const [selectedAction, setSelectedAction] = useState();
  const [confirmable, setConfirmable] = useState();
  const [anioActivacion, setAnioActivacion] = useState();
  const [mesActivacion, setMesActivacion] = useState();

  const [isArcoprime, setIsArcoprime] = useState(false);
  const [allPagadores, setAllPagadores] = useState([]);
  const [selectedPagador, setSelectedPagador] = useState();



  useEffect(() => {
    getUpdateDate(setUpdateDate)
    if(token && rutConcesionario && rutConcesionario === "77215640-5"){
      setIsArcoprime(true);
    }
  }, [token,rutConcesionario]);

  useEffect(() => {
    if (instalaciones !== "") {
      if(!isArcoprime){
        setInstalacionesDinamo(instalaciones, setAllEds)
      }
    }
  }, [instalaciones]);

  useEffect(() => {
    if (allEds.length === 1) {
      changeEds(allEds[0]);
    }
  }, [allEds]);

  useEffect(() => {
    if(selectedEds&&selectedPeriodo){
      getCierreMensual();
    }
  }, [selectedEds,selectedPeriodo]);

  useEffect(() => {
    if(selectedEds){
      getFechaActivacion()
    }
  }, [selectedEds]);

  useEffect(async () => {
    if(selectedPagador){
      const url = process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/obtener-instalaciones-cliente?id_pagador=" + selectedPagador.value;

      let result = await callServiceHandler(url, "GET")
      if(result !== "error"){
        setInstalacionesDinamo(result.data, setAllEds);
      }
      setPagador(selectedPagador.value)
    }
  }, [selectedPagador]);

  useEffect(() => {
    if(edsPortal){
      setPagadoresArcoprime(edsPortal.data.user_eds,setAllPagadores);
    }
  }, [edsPortal]);

  useEffect(() => {
    if (allPagadores.length === 1) {
      changePagador(allPagadores[0]);
    }
  }, [allPagadores]);

  function changePagador(e) {
    setSelectedPagador(e);
  }

  useEffect(() => {
    if (anioActivacion && mesActivacion) {
      const newPeriodos = getPeriodosSinceYearAndMonth(anioActivacion, 
        mesActivacion);
      console.log("nuevito 2", newPeriodos)
      setAllPeriodos(newPeriodos);
      setSelectedPeriodo(newPeriodos[0]);
    }
  }, [anioActivacion, mesActivacion]);
  


  function update(){
    console.log("actualizando")
  }

  function changeEds(e) {
    if (e !== selectedEds) {
      setSelectedEds(e);
    }
  }
  function changeSelectedPeriodo(e) {
    console.log(e)
    setSelectedPeriodo(e);
  }

  function openJustificacion(info){
    setJustificacionOpen(true)
    setSelectedInfo(info)
  }

  function openVentasJustificacion(info, action){
    setSelectedTable("VENTAS")
    setSelectedInfo(info)
    setSelectedAction(action)
    setJustificacionOpen(true)
  }
  
  function openFluctuacionJustificacion(info, material, action){
    info.material = material.material
    info.form_com = material.form_com
    setSelectedTable("FLUCTUACION")
    setSelectedInfo(info)
    setJustificacionOpen(true)
    setSelectedAction(action)
  }

  async function getFechaActivacion() {

    const url =
    process.env.REACT_APP_FLUCTUACIONES_API +
    "/fluctuaciones/cierre-diario-listar-periodos?ID_EDS=" +
    selectedEds.value + 
    "&ID_PAGADOR=" + pagador;

    let response = await callServiceHandler(url, "GET");

    console.log("respuestaaa ",response)

    if (response !== "error") {
      setAnioActivacion(parseInt(response.data.año_activacion))
      setMesActivacion(parseInt(response.data.mes_activacion))
    }
  }

  async function getCierreMensual(){
    const url =
      process.env.REACT_APP_FLUCTUACIONES_API +
      "/fluctuaciones/cierre-mensual-obtener-cierre?ID_EDS=" +
      selectedEds.value +
      "&PERIODO="+selectedPeriodo.value+"&ID_PAGADOR="+pagador;

      let response = await callServiceHandler(url, "GET");
      console.log(response)
      if(response!=="error"){
        cleanCierreData(response.data)
        //setVentas(response.data.litros_vendidos)
        setFluctuaciones(response.data.fluctuaciones)
        console.log("fluctuaciones",fluctuaciones)
        setEstadoCierre(response.data.estado_nombre);
        setEstadoCierreDiario(response.data.estado_cierre_diario);
        setConfirmable(response.data.confirmable)
      }
  }

  function cleanCierreData (data){
    const newVentas = [];
    const newTotalVentas = [];

    //SET VENTAS
    data.litros_vendidos.map((material)=>{
      const newMaterial={
        MATERIAL:material.material,
        LITROS_CONTROLADOR:material.litros_controlador,
        LITROS_DOCUMENTADOS:material.litros_documentados,
        LITROS_CONFIRMADOS:material.litros_confirmados,
        LITROS_DOCS_MENOS_CTRL:material.litros_documentados_menos_controlador,
        LITROS_CONF_MENOS_DOCS:material.litros_confirmados_menos_documentados,
        VALOR_CONTROLADOR:material.valor_controlador,
        VALOR_DOCUMENTADOS:material.valor_documentados,
        VALOR_CONFIRMADOS:material.valor_confirmados,
        VALOR_DOCS_MENOS_CTRL:material.valor_documentados_menos_controlador,
        VALOR_CONF_MENOS_DOCS:material.valor_confirmados_menos_documentados,
        ALERTA:material.alertas,
        FORMA_COMERCIALIZACION:material.form_com.toLowerCase(),
        JUSTIFICACION: material.justificacion,
        ID_JUSTIFICACION: material.id_justificacion
      }
      newVentas.push(newMaterial);
    })
    
    
    const newTotal={
      TOTAL_LITROS_CONTROLADOR:data.litros_vendidos.reduce((suma,material)=>suma+=material.litros_controlador,0),
      TOTAL_LITROS_DOCUMENTADOS:data.litros_vendidos.reduce((suma,material)=>suma+=material.litros_documentados,0),
      TOTAL_LITROS_CONFIRMADOS:data.litros_vendidos.reduce((suma,material)=>suma+=material.litros_confirmados,0),
      TOTAL_LITROS_DOCS_MENOS_CTRL:data.litros_vendidos.reduce((suma,material)=>suma+=material.litros_documentados_menos_controlador,0),
      TOTAL_LITROS_CONF_MENOS_DOCS:data.litros_vendidos.reduce((suma,material)=>suma+=material.litros_confirmados_menos_documentados,0),
      TOTAL_VALOR_CONTROLADOR:data.litros_vendidos.reduce((suma,material)=>suma+=material.valor_controlador,0),
      TOTAL_VALOR_DOCUMENTADOS:data.litros_vendidos.reduce((suma,material)=>suma+=material.valor_documentados,0),
      TOTAL_VALOR_CONFIRMADOS:data.litros_vendidos.reduce((suma,material)=>suma+=material.valor_confirmados,0),
      TOTAL_VALOR_DOCS_MENOS_CTRL:data.litros_vendidos.reduce((suma,material)=>suma+=material.valor_documentados_menos_controlador,0),
      TOTAL_VALOR_CONF_MENOS_DOCS:data.litros_vendidos.reduce((suma,material)=>suma+=material.valor_confirmados_menos_documentados,0)
    }
    
    setVentas(newVentas);
    setTotalVentas(newTotal);
  }


  const closeCierreModal = () => {
    setDisplayCierreModal(false)
    hideGreyBackground()
  }

  const closeSuccessModal = () => {
    setDisplaySuccessModal(false)
    hideGreyBackground()
    getCierreMensual()
  }

  async function confirmarCierre(){
    setDisplayCierreModal(false)
    const url =
    process.env.REACT_APP_FLUCTUACIONES_API +
    "/fluctuaciones/cierre-mensual-enviar-cierre?ID_EDS=" +
    selectedEds.value +
    "&PERIODO="+selectedPeriodo.value+"&ID_PAGADOR="+pagador;

    let response = await callServiceHandler(url, "GET");
    console.log(response)
    if(response!=="error"){
      console.log("Se ha enviado el cierre mensual")
      setDisplaySuccessModal(true)
    }
  }


  const goToCierreDiario = () => {
    parentRedirect(process.env.REACT_APP_CIERRE_DIARIO_PATH)
  }

  const goToHistorialFluctuaciones = () => {
    parentRedirect(process.env.REACT_APP_HISTORIAL_FLUCTUACIONES_CIERRE_DIARIO_PATH)
  }

  const getResumenCierre = async () => {
    const url =
        process.env.REACT_APP_FLUCTUACIONES_API +
        "/fluctuaciones/resumen-cierre-diario?ID_EDS=" +
        selectedEds.value +
        "&PERIODO="+selectedPeriodo.value+"&ID_PAGADOR="+pagador;

    let response = await callServiceHandler(url, "GET");
    
    console.log(response.data)

    // setCierres(response.data)

    return response.data.cierres
  }

  const getDetalleVentasMensual = async () => {

    let cierres = await getResumenCierre();

    const promises=[];
    const detallesVentasAux=[];
    const detallesOtrosMovimientosAux=[];
    const regularizaciones=[];
    const movimientosBodega=[];
    let labelsVentas = {}
    let labelOtrosMovimientos = {}
    let labelRegularizacion = {}
    let labelMovimientosBodega = {}
    let docName = "Detalle de ventas mensual-" + parseInt(selectedEds.value) + "-"+ selectedPeriodo.label +".xlsx"

    if (cierres.length>0){
      displayLoader()
      cierres.forEach(cierre=>{
        const url =
        process.env.REACT_APP_FLUCTUACIONES_API +
        "/fluctuaciones/cierre-diario-listado-transacciones?ID_EDS=" +
        selectedEds.value +
        "&FECHA="+cierre.fecha+"&ID_PAGADOR="+pagador+"&TIPO=3"+"&RUT="+rutConcesionario;
  
        promises.push(callServiceHandler(url, "GET", null, false).then(response => {
          if(response !== "error"){
            console.log("llamado "+cierre.fecha,response.data)
            // setLabelsVentas(response.data.labels);
            labelsVentas=response.data.hojas[0].labels  
            labelOtrosMovimientos=response.data.hojas[1].labels  
            labelRegularizacion=response.data.hojas[2].labels  
            labelMovimientosBodega=response.data.hojas[3].labels
            console.log("tran",response.data.hojas)
            response.data.hojas.forEach(hoja=>hoja.transacciones.forEach(trx=>{
              if (hoja.nombre==="ventas") {
                detallesVentasAux.push(trx)
              }
              if (hoja.nombre==="otros movimientos") {
                detallesOtrosMovimientosAux.push(trx)
              }
              if (hoja.nombre==="regularizaciones") {
                regularizaciones.push(trx)
              }
              if (hoja.nombre==="movimientos_de_bodega") {
                movimientosBodega.push(trx)
              }
            }))
          }
        }))
      })
  
      await Promise.all(promises)

      console.log("aux", detallesVentasAux)
      console.log("cal", detallesOtrosMovimientosAux)
      // setRequestCounter((old)=>old+1);
      const ventasOrdenadas = detallesVentasAux.map(trx=>{
        let nuevaFecha = "";
        if(trx.D===""){
          nuevaFecha="-";
        }else{
          nuevaFecha = trx.D.split("-")[2]+"/"+trx.D.split("-")[1]+"/"+trx.D.split("-")[0]
        }
        return {...trx,D:nuevaFecha}
      }).sort((a,b)=>{
        if(a.D<b.D){
          return -1
        }else if(a.D>b.D){
          return 1
        }else{
          if(a.E<b.E){
            return -1
          }else if(a.E>b.E){
            return 1
          }else{
            return 0
          }
        }
      });

      const otrosMovimientosOrdenados = detallesOtrosMovimientosAux.map(trx=>{
        let nuevaFecha = "";
        if(trx.D===""){
          nuevaFecha="-";
        }else{
          nuevaFecha = trx.D.split("-")[2]+"/"+trx.D.split("-")[1]+"/"+trx.D.split("-")[0]
        }
        return {...trx,D:nuevaFecha}
      }).sort((a,b)=>{
        if(a.D<b.D){
          return -1
        }else if(a.D>b.D){
          return 1
        }else{
          if(a.E<b.E){
            return -1
          }else if(a.E>b.E){
            return 1
          }else{
            return 0
          }
        }
      });

      const ventasConFecha = ventasOrdenadas.filter(trx=>trx.D!=="-");
      const ventasSinFecha = ventasOrdenadas.filter(trx=>trx.D==="-");
      ventasSinFecha.forEach(trx=>ventasConFecha.push(trx));

      const otrosMovimientosConFecha = otrosMovimientosOrdenados.filter(trx=>trx.D!=="-");
      const otrosMovimientosSinFecha = otrosMovimientosOrdenados.filter(trx=>trx.D==="-");
      otrosMovimientosSinFecha.forEach(trx=>otrosMovimientosConFecha.push(trx));

      console.log("vcf",ventasConFecha)
      console.log("ccf",otrosMovimientosConFecha)

      const arrToExport = [
        {
          nombre:"Ventas",
          labels: labelsVentas,
          transacciones:ventasConFecha
        },
        {
          nombre:"Otros Movimientos",
          labels: labelOtrosMovimientos,
          transacciones:otrosMovimientosConFecha
        },
        {
          nombre:"Regularización",
          labels: labelRegularizacion,
          transacciones:regularizaciones
        },
        {
          nombre:"Movimientos Bodega",
          labels: labelMovimientosBodega,
          transacciones:movimientosBodega
        },
      ]

      getExcelWithXlsx2(arrToExport, docName)
      
      hideLoader()
    }
  }



  return (
    <div className="cierre-mensual">
      <div className="view-title">Cierre de Operaciones</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">
        {/* {stepNames[step - 1]} */} Cierre Mensual
      </div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandler={callServiceHandler}
        cabecera={cabecera}
        actualizable={true}
        updateFunction={getCierreMensual}
      />
      <hr />

      {justificacionOpen && selectedTable === "VENTAS" &&
        <div className="section-title">
          <div className="title">Justificación ventas</div>
          <div className="subtitle">Consignación</div>
        </div>
      }

      {justificacionOpen && selectedTable === "FLUCTUACION" &&
        <div className="section-title">
          <div className="title">Justificación fluctuacion</div>
          <div className="subtitle">Consignación</div>
        </div>
      }

      <div className="filters-container">
        {isArcoprime && 
           <div className="filter">
           <div className="filter-title">
             ID DE PAGADOR{!justificacionOpen && <span className="red-asterisk">*</span>}
           </div>
           <div className="filter-input margin-right-32">
             {!justificacionOpen &&
               <Select
                 className="eds-select"
                 value={selectedPagador}
                 onChange={(e) => changePagador(e)}
                 closeMenuOnSelect={true}
                 hideSelectedOptions={false}
                 options={allPagadores}
                 components={{
                   SingleOption,
                   IndicatorSeparator: () => null,
                 }}
                 isClearable={false}
                 autosize={false}
                 placeholder="Seleccionar"
                 width="192px"
                 styles={customStylesTctTae}
               />
             }
             {justificacionOpen && selectedEds &&
               <div className="filter-input">
                 <div className="info-input">
                   {
                     selectedPagador.value
                   }
                 </div>
               </div>
             }
           </div>
         </div>
        }
        <div className="filter">
          <div className="filter-title">
            ESTACIÓN DE SERVICIO{!justificacionOpen && <span className="red-asterisk">*</span>}
          </div>
          <div className="filter-input margin-right-32">
            {!justificacionOpen &&
              <Select
                className="eds-select"
                value={selectedEds}
                onChange={(e) => changeEds(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allEds}
                components={{
                  SingleOption,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccionar"
                width="192px"
                styles={customStylesTctTae}
              />
            }
            {justificacionOpen && selectedEds &&
              <div className="filter-input">
                <div className="info-input">
                  {
                    selectedEds.value
                  }
                </div>
              </div>
            }
          </div>
        </div>
        <div className="filter">
          <div className="filter-title">
            PERÍODO{!justificacionOpen && <span className="red-asterisk">*</span>}
          </div>
          <div className="filter-input margin-right-40">
            {!justificacionOpen &&
              <Select
                className="eds-select"
                value={selectedPeriodo}
                onChange={(e) => changeSelectedPeriodo(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allPeriodos}
                components={{ SingleOption, IndicatorSeparator: () => null }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccionar"
                width="192px"
                styles={customStylesTctTae}
              />
            }
            {justificacionOpen && selectedPeriodo &&
              <div className="filter-input">
                <div className="info-input">
                  {
                    selectedPeriodo.label
                  }
                </div>
              </div>
            }
          </div>
        </div>
        <div className="filter">
          <div className="filter-title">
            ESTADO DE CIERRE
          </div>
          <div className="filter-input">
            <div className="info-input">
              {estadoCierre}&nbsp;
            </div>
          </div>
        </div> 

        {!justificacionOpen &&
          <div className={`actions-container${isArcoprime?" arcoprime":""}`}>
            <button className="main-button hallow" onClick={()=>goToCierreDiario()}>
              Ir a cierre diario
            </button>
            <button className="main-button hallow iconbutton" onClick={getDetalleVentasMensual}>
              <div className="iconbutton-container">
                <img src={download} alt="" /> Detalle ventas
              </div>
            </button>
          </div>
        }
      </div>

      {!justificacionOpen &&
        <>
          <VentasTable 
            openJustificacion={openVentasJustificacion}
            ventas={ventas}
            total={totalVentas}
            setSelectedTable={setSelectedTable}
          />

          <div className="filters-container fluctuaciones">
            <div className="actions-container">
              <button className="main-button hallow" onClick={()=>goToHistorialFluctuaciones()}>
                Historial fluctuaciones
              </button>
            </div>
          </div>

          <FluctuacionesTable fluctuaciones={fluctuaciones} openJustificacion={openFluctuacionJustificacion} estadoCierre={estadoCierre} />
        
          <div className="important-message">
            <span className="title">Importante:&nbsp;</span>
            <span>
            Para poder confirmar el cierre, es obligatorio justificar los valores fuera de tolerancia
            </span>
          </div>
          <div className="cierre-mensual-bottom">
            <hr />
            <button className="red-button" onClick={()=>setDisplayCierreModal(true)} disabled={confirmable==="NO"}>Confirmar cierre</button>
          </div>
        </>
      }
      {
        justificacionOpen &&
        <JustificacionForm info={selectedInfo} selectedTable={selectedTable} selectedAction={selectedAction} selectedEds={selectedEds.value} selectedPeriodo={selectedPeriodo.value} pagador={pagador} setJustificacionOpen={setJustificacionOpen} callServiceHandler={callServiceHandler} getCierreMensual={getCierreMensual} estadoCierre={estadoCierre}/>
      }
      <CustomModal
        displayModal = { displayCierreModal }
        closeModal = { closeCierreModal }
        acceptFunc = { confirmarCierre }
        cancelFunc={closeCierreModal}
        acceptText= "Sí, estoy seguro"
        modalTitle = "Importante"
        modalText = "Este procedimiento contempla el cierre Mensual de Ventas y Fluctuaciones de combustible ¿Está seguro(a) que desea confirmar?"
        modalIcon = "importante"
      />
      
      {
        selectedEds && selectedPeriodo &&
          <CustomModal
            displayModal = { displaySuccessModal }
            closeModal = { closeSuccessModal }
            acceptFunc = { closeSuccessModal }
            acceptText= "Aceptar"
            modalTitle = "Operación exitosa"
            modalText = {`
            Ha concluido de forma exitosa el cierre de operaciones mensual
            `}
            modalIcon = "success"
          />
      }
    </div>
  )
};

export default CierreMensual;
