import React, { useState, useEffect } from "react";
import { getLocaleStringNumber } from "../utils/utils.js";
import warning from "../../assets/img/Warning_on.svg";
import warningOff from "../../assets/img/Warning_off.svg";

const EstadoCrediticio = ({ token, pagador, callServiceHandler }) => {
  const [estadoCrediticio, setEstadoCrediticio] = useState({})

  useEffect(() => {
    getEstadoCrediticio()
  }, []);

  async function getEstadoCrediticio(){
    const url =
      process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/estado-crediticio?id_pagador=" + pagador

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      setEstadoCrediticio({
        CREDITO_DISPONIBLE: result.data.ESTADO_DEUDOR.GESTION_CREDITO.CREDITO_DISPONIBLE,
        CHEQUE_DEVUELTO: result.data.ESTADO_DEUDOR.PAGOS_RECIBIDOS.CHEQUE_DEVUELTO
      })
    }
  }

  return (
    <div className="dinamo-info">
      <div className="row">
        <div className="column-left">
          <div className="title">
            Crédito disponible
          </div>
          <div className="data">
            $ { getLocaleStringNumber(estadoCrediticio.CREDITO_DISPONIBLE) }
          </div>
        </div>
        <div className={`column-right`}>
          <div className="cheques-rechazados-container">
            <div className="left">
              <img src={estadoCrediticio.CHEQUE_DEVUELTO==="Si"?warning:warningOff} />
            </div>
            <div className="right">
            {estadoCrediticio.CHEQUE_DEVUELTO==="Si"?<>Cheques<br />Rechazados</>:<br></br>}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstadoCrediticio;
