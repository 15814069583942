import React, { useState, useEffect } from "react"
import Select from "react-select"
import Modal from "react-modal"

import CierreDiarioTable from "../CierreDiario/CierreDiarioTable"
import { getPeriodosSince, getPeriodosSinceYearAndMonth, getUpdateDate } from "../utils/monthNames"

import closeIcon from "../../assets/img/cross-icon.svg";
import removeIcon from "../../assets/img/remove.svg"

import {validateEmail} from "../utils/utils"
import { docModalStyles } from "../utils/styles.js";


import {
  displayLoader,
  hideGreyBackground,
  hideLoader,
  parentRedirect,
  scrollTo,
  showGreyBackground
} from "../utils/windows"


import {
  customStyles,
  parseBudat,
  setMaterialesYTanques,
  SingleOption,
  getAproximate3FloatString,
  getLocaleStringFloat,
  get3DecimalsFloatWithDelimiter,
  getLocaleStringRoundedInt,
  parseDateString,
  getExcelWithXlsx2
} from "../utils/utils";

const types = {
	"IMO": "IMO", 
	"jefe_comercial": "Jefe Comercial", 
	"jefe_operaciones": "Jefe Operaciones"
}

const EdsDinamo = ({token, callServiceHandler, pagadores, displayCustomError}) => {
	const [allEds, setAllEds]  = useState([])
	const [selectedEds, setSelectedEds] = useState()
	const [allPagadores, setAllPagadores] = useState([])
	const [selectedPagador, setSelectedPagador] = useState("")
	const [allPeriodos, setAllPeriodos] = useState()
	const [selectedPeriodo, setSelectedPeriodo] = useState()
	const [cierres, setCierres] = useState() 

	useEffect(() => {
    if(selectedEds && selectedPeriodo){
      getCierreDiarioData()
    }
  }, [selectedEds, selectedPeriodo])

  useEffect(() => {
    if(Object.keys(pagadores).length > 0){
    	const newPagadores = []
    	Object.keys(pagadores).map((key) => {
    		newPagadores.push({label: key, value: key})
    	})

    	setAllPagadores(newPagadores)
    }
  }, [pagadores])

  useEffect(() => {    
  	if(token){
	    const newPeriodos = getPeriodosSinceYearAndMonth('2020', '01')
	    setAllPeriodos(newPeriodos);
	    setSelectedPeriodo(newPeriodos[0])
	    
    }
  }, [token])

  async function getCierreDiarioData(){
    displayLoader()

    let url = process.env.REACT_APP_FLUCTUACIONES_API + '/fluctuaciones/resumen-cierre-diario?'
    url += "ID_EDS=" + selectedEds.value + "&PERIODO=" + selectedPeriodo.value + "&ID_PAGADOR=" + selectedPagador.value

    let result = await callServiceHandler(url)
    if(result !== "error")
      setCierres(result.data.cierres)

    hideLoader()
  }

	const changePagador = (e) => {
		setSelectedPagador(e)
		const tmpEds = []
		pagadores[e.value].forEach(function(eds){
			tmpEds.push({label: eds, value: eds})
		})
		if(tmpEds.length === 1)
			setSelectedEds(tmpEds[0])
		else
			setSelectedEds()
		setAllEds(tmpEds)
	}

	async function abrirEstacion(cierre, estado){
		displayLoader()
    
    const url = process.env.REACT_APP_FLUCTUACIONES_API + '/fluctuaciones/cierre-diario-reabrir-estado-token'

    const body = {
	   "transacciones": [
				{
					"eds": selectedEds.value,
					"pagador": selectedPagador.value,
					"fecha_dia": cierre.fecha,
					"estado": estado
				}
	   	]
		}

		let result = await callServiceHandler(url, "POST", JSON.stringify(body))   
    if(result !== "error"){
      getCierreDiarioData()
      displayCustomError("Exito", "Los cambios han sido guardados con éxito.", "success")
    }

    hideLoader()
	}

  return (
    <div className="selected-filter-container">
	    <div className="cierre-diario-options">
          <div className="page-options">
            <div className="filters-container">
                <div className="filter">
                <div className="filter-title required-field">
                  ID DE PAGADOR
                </div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedPagador}
                    onChange={(e) => changePagador(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allPagadores}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStyles}
                    /* isDisabled={step !== 1} */
                  />
                </div>
              </div>
  
              <div className="filter">
                <div className="filter-title required-field">
                  ESTACIÓN DE SERVICIO
                </div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedEds}
                    onChange={(e) => setSelectedEds(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allEds}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStyles}
                    /* isDisabled={step !== 1} */
                  />
                </div>
              </div>
              <div className="filter">
                <div className="filter-title">PERÍODO</div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedPeriodo}
                    onChange={(e) => setSelectedPeriodo(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allPeriodos}
                    components={{ SingleOption, IndicatorSeparator: () => null }}
                    isClearable={false}
                    autosize={false}
                    styles={customStyles}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cierre-estados">
          <table className="pedidos-activos-table cierre-diario-table">
	          <tr>
	            <th className="no-padding-left">
	              <div className="th-data">
	                <div className="th-text">
	                  Fecha
	                </div>
	              </div>
	            </th>
	            <th>
	                <div className="th-data">
	                <div className="th-text">
	                  Estado Cierre
	                </div>
	                </div>
	            </th>
	            <th>
	                <div className="th-data">
	                  <div className="th-text">
	                    Accion
	                  </div>
	                </div>
	            </th>
	          </tr>
	          <tr className="table-separator-row-border-top">
	          <td />
	          </tr>
	          <tbody>
	          { cierres &&
	          	cierres.map((cierre) => 
		          	<tr className="data">
		          		<td className="align-left">{cierre.fecha}</td>
		          		<td className="align-left">{cierre.estado_cierre}</td>
		          		<td className="align-left">
		          			{ cierre.estado_cierre === "CERRADO" &&
		          				<>
			          				<span className="link" onClick={() => abrirEstacion(cierre, '01')}>ABRIR</span>
			          				&nbsp;&nbsp;&nbsp;&nbsp;
			          				<span className="link" onClick={() => abrirEstacion(cierre, '04')}>REABRIR</span>
			          			</>
		          			}
		          			{ cierre.estado_cierre === "ABIERTO" &&
		          				<span>-</span>
		          			}
		          		</td>
		          	</tr>
	          	)
	          }
	          </tbody>
	        </table>
        </div>
    </div>
  )
}

export default EdsDinamo
