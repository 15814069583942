import React, { useState, useEffect } from "react";
import { InputWithClearBtn } from "../InputWithClearBtn";

import { SingleOption, 
  customStyles, 
  get3DecimalsFloatWithDelimiter, 
  getNumberFromLocalString, 
  getLocaleStringFloat } from "../utils/utils.js";

import { getMaterialClass, 
  getMaterialMinititle,  
} from "../utils/materiales"

import remove from "../../assets/img/remove.svg";
import errorIcon from "../../assets/img/atencion_rojo.svg";
import Select from "react-select";

const commonSelectProps = {
  className: "table-select",
  closeMenuOnSelect: true,
  hideSelectedOptions: false,
  options: [],
  components: { SingleOption, IndicatorSeparator: () => null },
  isClearable: false,
  autosize: false,
  placeholder: "Seleccionar",
  styles: customStyles,
};

export const CalibracionesYMovBodega = ({ 
  revisarFluctuacion, 
  setStep, 
  configuracion, 
  calibracionesEntradas, 
  setCalibracionesEntradas, 
  calibracionesSalidas, 
  setCalibracionesSalidas,
  materiales,
  tanques,
  movimientosBodega,
  setMovimientosBodega,
  filteredTanques,
  setFilteredTanques
}) => {
  const [errores, setErrores] = useState([])

  function agregarMovimientoBodega(){
    const newMovimiento = {
      material: '',
      tanqueOrigen: '',
      tanqueDestino: '',
      volumen: ''
    }
    setMovimientosBodega([...movimientosBodega, newMovimiento])
  }

  function movimientoBodegaChange(value, key, name){
    if(name === 'volumen'){
      value = get3DecimalsFloatWithDelimiter(value)

      if(value === false){
        return
      }
    }

    const temp = [...movimientosBodega]
    if (name == "tanqueOrigen") {
      if (temp[key]["tanqueDestino"] === value) {
        temp[key]["tanqueDestino"] = temp[key][name]
      }
    }
    
    temp[key][name] = value;

    if(name === 'material'){
      temp[key].tanqueOrigen = ''
      temp[key].tanqueDestino = ''
      const newTanques = tanques.filter(t => t.idMaterial === value.value)
      const tempTanques = {...filteredTanques}
      tempTanques[key] = newTanques
      if(tempTanques[key].length === 1){
        temp[key].tanqueOrigen = tempTanques[key][0]
      }
      setFilteredTanques(tempTanques)
    }

    setMovimientosBodega(temp)    
  }

  function eliminarMovimientoBodega(key){
    const temp = [...movimientosBodega]
    temp.splice(key, 1)
    setMovimientosBodega(temp)
  }

  function medicionesChange(value, key, type){
    value = get3DecimalsFloatWithDelimiter(value)

    if(value !== false){
      if(type === 'entradas')
        setCalibracionesEntradas({...calibracionesEntradas, [key]: value})
      else
        setCalibracionesSalidas({...calibracionesSalidas, [key]: value})
    }
  }

/*
  function medicionesChange(value, key, type){
    if(isNaN(value))
      return

    if(type === 'entradas')
      setCalibracionesEntradas({...calibracionesEntradas, [key]: value})

    else
      setCalibracionesSalidas({...calibracionesSalidas, [key]: value})
  }
*/

  function getTotal(arr){
    let total = 0
    Object.keys(arr).forEach(function(key) {
      total += parseInt(arr[key]) || 0
    });
    return total
  }

  function checkStep(){
    const tmpErrores = []
    let error1 = false, error2 = false
    movimientosBodega.forEach((movimiento) => {
      if(movimiento.material && movimiento.tanqueDestino && movimiento.tanqueOrigen && movimiento.volumen){
        const volumen = parseInt(movimiento.volumen) || 0
        if (volumen > movimiento.tanqueOrigen.capacidad || volumen > movimiento.tanqueDestino.capacidad) {
          error2 = true
        }
      }
      else {
        error1 = true
      }
    })
    
    if(error1)
      tmpErrores.push("Ingresa todos los datos.")
    if(error2)
      tmpErrores.push("No es posible ingresar una salida con un volumen mayor a la capacidad del tanque.")

    setErrores(tmpErrores)
    if(tmpErrores.length === 0)
      revisarFluctuacion()
  }

  function getTanquesCalibracion(key){
    const tanque = configuracion.TANQUES[key]
    if(parseInt(tanque.ID) > parseInt(tanque.SIFON_1) || parseInt(tanque.ID) > parseInt(tanque.SIFON_2))
      return

    let totalSifones = 1, diferencia_s0 = 0, diferencia_s1 = 0, diferencia_s2 = 0, sifonCamion
  
    if(tanque.TIPO === 'camion'){
      let isOld = false
      configuracion.TANQUES.map((t, newKey) => {
        if(t.ID.split('-')[0] === tanque.ID.split('-')[0]){
          if(newKey < key)
            isOld = true
          else if(newKey > key)
            sifonCamion = t
        }
      })
      if(isOld)
        return
    }

    let totalEntradas = getNumberFromLocalString(calibracionesEntradas[tanque.ID]) || 0
    let totalSalidas = getNumberFromLocalString(calibracionesSalidas[tanque.ID]) || 0
    diferencia_s0 = totalEntradas - totalSalidas
    diferencia_s0 = getLocaleStringFloat(diferencia_s0)

    if(sifonCamion){
      totalEntradas += getNumberFromLocalString(calibracionesEntradas[sifonCamion.ID]) || 0
      totalSalidas += getNumberFromLocalString(calibracionesSalidas[sifonCamion.ID]) || 0
      diferencia_s1 = (getNumberFromLocalString(calibracionesEntradas[sifonCamion.ID]) || 0) - (getNumberFromLocalString(calibracionesSalidas[sifonCamion.ID]) || 0)
    }

    if(tanque.SIFON_1 !== ''){
      totalSifones++
      totalEntradas += getNumberFromLocalString(calibracionesEntradas[tanque.SIFON_1]) || 0
      totalSalidas += getNumberFromLocalString(calibracionesSalidas[tanque.SIFON_1]) || 0
      diferencia_s1 = (getNumberFromLocalString(calibracionesEntradas[tanque.SIFON_1]) || 0) - (getNumberFromLocalString(calibracionesSalidas[tanque.SIFON_1]) || 0)
    }

    if(tanque.SIFON_2 !== ''){
      totalSifones++
      totalEntradas += getNumberFromLocalString(calibracionesEntradas[tanque.SIFON_2]) || 0
      totalSalidas += getNumberFromLocalString(calibracionesSalidas[tanque.SIFON_2]) || 0
      diferencia_s2 = (getNumberFromLocalString(calibracionesEntradas[tanque.SIFON_2]) || 0) - (getNumberFromLocalString(calibracionesSalidas[tanque.SIFON_2]) || 0)
    }

    diferencia_s1 = getLocaleStringFloat(diferencia_s1)
    diferencia_s2 = getLocaleStringFloat(diferencia_s2)

    let totalDiferencia = totalEntradas - totalSalidas
    totalDiferencia = getLocaleStringFloat(totalDiferencia)

    return <>
      <tr>
        <td className="horizontal-separator" colSpan={5} />
      </tr>

      <tr className={totalSifones > 1? 'only-row-span-cell-border': ''}>
        { sifonCamion &&
          <td rowSpan="2" className='fluctuaciones-column-with-border cell-border-top-left capitalize'>
            Camion<br /><span className="uppercase">{tanque.ID.split('-')[0]}</span>
          </td>
        }
        { !sifonCamion &&
          <td className={`tanque-name-column cell-border-top-left capitalize ${totalSifones === 1? 'cell-border-bottom-left': ''}`}>
            {tanque.TIPO} {tanque.TIPO === 'camion'? <br />: ''}<span className="uppercase">{tanque.ID.split('-')[0]}</span>
          </td>
        }
        <td rowSpan={totalSifones} className="container-cell">
          <div className="container-cell-content">
            <div className={`small-camion-compartment ${getMaterialClass(tanque.ID_MATERIAL)} cont2`}>
              <p className="small-camion-compartment__fuel-type-number">
                {getMaterialMinititle(tanque.ID_MATERIAL)}
              </p>
            </div>
            <span>{tanque.MATERIAL}</span>
          </div>
        </td>
        <td>
          <InputWithClearBtn className="table-input" type="text" onChange={(e) => medicionesChange(e.target.value, tanque.ID, 'salidas')} value={calibracionesSalidas[tanque.ID]} />
        </td>
        <td>
          <InputWithClearBtn className="table-input" type="text" onChange={(e) => medicionesChange(e.target.value, tanque.ID, 'entradas')} value={calibracionesEntradas[tanque.ID]} />
        </td>
        <td className={`cell-border-top-right ${(totalSifones === 1 && !sifonCamion)? 'cell-border-bottom-right':''}`}>{diferencia_s0}</td>
      </tr>
      { sifonCamion &&
        <>
          <tr>
            <td className="container-cell no-padding-left">
              <div className="container-cell-content">
                <div className={`small-camion-compartment ${getMaterialClass(sifonCamion.ID_MATERIAL)} cont2`}>
                  <p className="small-camion-compartment__fuel-type-number">
                    {getMaterialMinititle(sifonCamion.ID_MATERIAL)}
                  </p>
                </div>
                <span>{sifonCamion.MATERIAL}</span>
              </div>
            </td>
            <td>
              <InputWithClearBtn className="table-input" type="text" onChange={(e) => medicionesChange(e.target.value, sifonCamion.ID, 'salidas')} value={calibracionesSalidas[sifonCamion.ID]} />
            </td>
            <td>
              <InputWithClearBtn className="table-input" type="text" onChange={(e) => medicionesChange(e.target.value, sifonCamion.ID, 'entradas')} value={calibracionesEntradas[sifonCamion.ID]} />
            </td>
            <td>{diferencia_s1}</td>
          </tr>
          <tr className='row-with-border sifon-camiones-separator'>
            <td /><td /><td /><td /><td />
          </tr>
        </>
      }

      { totalSifones > 1 &&
        <>
          <tr className={totalSifones === 2? 'row-with-border' : ''}>
            <td className="tank-name-cell">Tanque {tanque.SIFON_1}</td>
            <td>
              <InputWithClearBtn className="table-input" type="text" onChange={(e) => medicionesChange(e.target.value, tanque.SIFON_1, 'salidas')} value={calibracionesSalidas[tanque.SIFON_1]} />
            </td>
            <td>
              <InputWithClearBtn className="table-input" type="text" onChange={(e) => medicionesChange(e.target.value, tanque.SIFON_1, 'entradas')} value={calibracionesEntradas[tanque.SIFON_1]} />
            </td>
            <td>{diferencia_s1}</td>
          </tr>
        
          { totalSifones === 3 &&
            <tr className="row-with-border">
              <td className="tank-name-cell">Tanque {tanque.SIFON_2}</td>
              <td>
                <InputWithClearBtn className="table-input" type="text" onChange={(e) => medicionesChange(e.target.value, tanque.SIFON_2, 'salidas')} value={calibracionesSalidas[tanque.SIFON_2]} />
              </td>
              <td>
                <InputWithClearBtn className="table-input" type="text" onChange={(e) => medicionesChange(e.target.value, tanque.SIFON_2, 'entradas')} value={calibracionesEntradas[tanque.SIFON_2]} />
              </td>
              <td>{diferencia_s2}</td>
            </tr>
          }
        </>
      }

      { (sifonCamion || totalSifones > 1) &&
        <tr className="bold-row">
          <td className="cell-border-bottom-left" colSpan={2}>
            { sifonCamion &&
              <>Total Camion <span className="uppercase">{tanque.ID.split('-')[0]}</span></>
            }
            { totalSifones > 1 &&
              <>Total tanques {`${tanque.ID}${totalSifones > 1? ` - ${tanque.SIFON_1}`: ''}${totalSifones > 2? ` - ${tanque.SIFON_2}`: ''}`}</>
            }
          </td>
          <td>
            {totalSalidas}
          </td>
          <td>
            {totalEntradas}
          </td>
          <td className="cell-border-bottom-right">
            {totalDiferencia}
          </td>
        </tr>
      }

    </>
  }

  function getTanquesDestino(key){
    const tmpTanques = filteredTanques[key]?.filter(t => t !== movimientosBodega[key].tanqueOrigen) || []
    tmpTanques.push({
      label: "VENTA",
      value: "VENTA",
      capacidad: 1000000000
    })
    return tmpTanques
  }

  return (
    <>
      <div className="fluctuaciones-ingreso-mediciones-medium-section">
        <p className="flutuaciones-section-title">CALIBRACIONES</p>
        <table className="flutuaciones-table tanks-table calibrations-table">
          <thead>
            <tr>
              <th>Tanque</th>
              <th className="no-padding-left-th">Material</th>
              <th>Salida<br />Volumen</th>
              <th>Entrada<br />Volumen</th>
              <th>Diferencia</th>
            </tr>
          </thead>
          <tbody>
            {/* Horizontal  separator between head and body*/}

            { configuracion && configuracion.TANQUES.map((tanque, key) =>
              getTanquesCalibracion(key)        
            )}
            <tr>
              <td className="horizontal-separator" colSpan={5} />
            </tr>
            <tr className="bold-row">
              <td
                className="cell-border-top-left cell-border-bottom-left"
                colSpan={2}
              >
                Total General
              </td>
              <td>{getTotal(calibracionesSalidas)}</td>
              <td>{getTotal(calibracionesEntradas)}</td>
              <td className="cell-border-top-right cell-border-bottom-right">
                {getTotal(calibracionesEntradas) - getTotal(calibracionesSalidas)}
              </td>
            </tr>
          </tbody>
        </table>
        <p className="flutuaciones-section-title push-down">
          MOVIMIENTOS DE BODEGA
        </p>
        <table className="flutuaciones-table cellar-movement-table">
          <thead>
            <tr>
              <th className="mov-bodega-padding-left-th-first">Material</th>
              <th className="mov-bodega-padding-left-th">Tanque origen</th>
              <th className="mov-bodega-padding-left-th">Tanque destino</th>
              <th className="mov-bodega-padding-left-th">Volumen (Lts)</th>
            </tr>
          </thead>
          <tbody>
            {/* Horizontal  separator between head and body*/}
            <tr>
              <td className="horizontal-separator" colSpan={4} />
            </tr>
            {movimientosBodega.map((mov, key) =>
              <tr className="row-with-border">
                <td className="cell-border-top-left">
                  <Select
                    className="eds-select"
                    value={movimientosBodega[key].material}
                    onChange={(e) => movimientoBodegaChange(e, key, 'material')}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={materiales}
                    components={{ SingleOption, IndicatorSeparator: () => null }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStyles}
                  />
                </td>
                <td>
                  <Select
                    className="eds-select"
                    value={movimientosBodega[key].tanqueOrigen}
                    onChange={(e) => movimientoBodegaChange(e, key, 'tanqueOrigen')}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={filteredTanques[key]}
                    components={{ SingleOption, IndicatorSeparator: () => null }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStyles}
                  />
                </td>
                <td>
                  <Select
                    className="eds-select"
                    value={movimientosBodega[key].tanqueDestino}
                    onChange={(e) => movimientoBodegaChange(e, key, 'tanqueDestino')}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={getTanquesDestino(key)}
                    components={{ SingleOption, IndicatorSeparator: () => null }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStyles}
                  />
                </td>
                <td className="cell-border-top-right">
                  <InputWithClearBtn type="text" onChange={(e) => movimientoBodegaChange(e.target.value, key, 'volumen')} value={movimientosBodega[key].volumen} />
                  <button className="remove-row-btn">
                    <img src={remove} alt="remove" onClick={() => eliminarMovimientoBodega(key)} />
                  </button>
                </td>
              </tr>
            )}
            <tr>
              <td
                className="cell-border-bottom-left cell-border-bottom-right"
                colSpan={4}
              >
                <button className="main-button add-new-row" onClick={agregarMovimientoBodega}>Agregar</button>
              </td>
            </tr>
          </tbody>
        </table>
        {
          errores.map((error) =>
          <div className="table-bottom-error">
            <img src={errorIcon} alt="error" />
            <p>{error}</p>
          </div>
        )}
        <div className="fluctuaciones-ingreso-mediciones-actions">
          <button className="main-button hallow" onClick={() => setStep(3)}>
            Volver
          </button>
          <button className="main-button" onClick={checkStep}>
            Confirmar
          </button>
        </div>
      </div>
    </>
  );
};
