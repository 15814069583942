import React from "react";
import Select from "react-select";
import { useEffect } from "react";
import { useState } from "react";
import { SingleOption, customStylesTctTae, getLocaleStringNumber } from "../../utils/utils.js";
import { setInstalalaciones } from "../../utils/get";
import Flecha_up from "../../../assets/img/Flecha_up.svg";
import Flecha_down from "../../../assets/img/Flecha_down.svg";
import OverflowedText from "../../OverflowedText.js";

const ConsultarGuiasManualesPorNumeroGuia = ({
  setDisplayInfo,
  selectedEds,
  setSelectedEds,
  allEds,
  numeroGuia,
  setNumeroGuia,
  setFechaAutorizacion,
  setHoraAutorizacion,
  changeEds,
  displayInfo,
  displayGuiaTable,
  setDisplayGuiaTable,
  // getGuias,
  callServiceHandler,
  setRutCliente,
  setNumeroTarjeta,
  setCodigoAutorizacion,
  data,
  setComuna,
  setDireccion,
  setGiroComercial,
  setProducto,
  setMonto,
  setNombreCliente,
  setPrecio,
  setUnidad,
  setFlete,
  numeroGuiaEnviado,
  setNumeroGuiaEnviado,
  guia,
  codigoCliente,
  setCodigoCliente,
  numeroTransaccion,
  setNumeroTransaccion,
  codigoProducto,
  setCodigoProducto,
  codigoSubProducto,
  setCodigoSubProducto,
  setGuiaEstado,
  setAccion
}) => {

  // const [numeroGuiaEnviado, setNumeroGuiaEnviado] = useState(false);

  const [filteredData, setFilteredData] = useState();
  
  // useEffect(() => {
  //   setNumeroGuia("")
  // }, []);

  useEffect(() => {
    if (guia) {
      setFilteredData(guia)
    }
  }, [guia]);


// useEffect(() => {
//   if(selectedEds&&numeroGuiaEnviado){
//     console.log(selectedEds)
//     console.log(numeroGuia)
//     openInfoTable()
//   }
// }, [selectedEds, numeroGuiaEnviado]);

function changeEds(e) {
  if (e !== selectedEds) {
    setSelectedEds(e);
  }
}

  function handleKeyDown(e){
    let numero
    if(e.key === "Enter"){
      // showInfo()
      console.log("el estado del numero de guía despues de enter es= ", numeroGuia)
      setNumeroGuiaEnviado(true)
    }
  }

  const handleBlur = (e) => {
    // setNumeroGuia(e.target.value)
    setNumeroGuiaEnviado(true)
  }

  // const openInfoTable = () => {
  //   getGuias()
  //   setDisplayGuiaTable(true)
  // }

  const openInfo = (rut_cliente, codigo_cliente, numero_transaccion, numero_guia, numero_tarjeta, codigo_autorizacion, fecha_autorizacion, hora_autorizacion, guiaEstado, editable) => {

    getDetalleGuia(rut_cliente, codigo_cliente, numero_transaccion)

    setNumeroGuia(numero_guia)
    setFechaAutorizacion(fecha_autorizacion)
    setHoraAutorizacion(hora_autorizacion)
    setRutCliente(rut_cliente)
    setNumeroTarjeta(numero_tarjeta)
    setCodigoAutorizacion(codigo_autorizacion)
    setGuiaEstado(guiaEstado)
    setAccion(editable)
    // setDisplayInfo(true)
  }

  // const getGuias = async () => {

  //   const url = 
  //   process.env.REACT_APP_TCT_TAE_API +
  //   "/tct-tae/consultar-guias?IDEDS=" + parseInt(selectedEds.value)
  //   + "&NUMEROGUIA=" + numeroGuia

  //   let response = await callServiceHandler(url, "GET");

  //   console.log("cliente", response.data)

  //   setFilteredData(response.data.guias)
  // }

  const getDetalleGuia = async (rut, codigo, transaccion) => {
    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-detalle-guia?RUTCLIENTE=" + rut 
    +"&CODCLIENTE=" + codigo
    +"&NUMTRANSACCION=" + transaccion;

    let response = await callServiceHandler(url, "GET");

    console.log("detalle guia", response.data)

    setCodigoCliente(codigo)
    setNumeroTransaccion(transaccion)
    setComuna(response.data.guia.comuna)
    setDireccion(response.data.guia.direccion)
    setGiroComercial(response.data.guia.giro_comercial)
    setMonto(response.data.guia.monto)
    setNombreCliente(response.data.guia.nombre_cliente)
    setPrecio(response.data.guia.precio)
    setUnidad(response.data.guia.unidad)
    setProducto(response.data.guia.producto)
    setFlete(response.data.guia.flete)
    setCodigoProducto(response.data.cod_producto)
    setCodigoSubProducto(response.data.cod_subproducto)
    setDisplayInfo(true)
  }

  return (
    <div className="consultar-guias-manuales-por-guia">
      {/* {
        !displayInfo && */}
        <div className="selector-container">
          <div className="filters-container">
            <div className="filter">
              <div className="filter-title required-field">
                ESTACIÓN DE SERVICIO
              </div>
              <div className="filter-input eds-select">
                <Select
                  className="eds-select"
                  value={selectedEds}
                  onChange={(e) => changeEds(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allEds}
                  components={{
                    SingleOption,
                    IndicatorSeparator: () => null,
                  }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesTctTae}
                />
              </div>
            </div>
            <div className="filter">
              <div className="filter-title required-field">
                NÚMERO DE GUÍA
              </div>
              <div className="filter-input">
                <input className="enabled-input nro-guia-input" type="number" onKeyDown={handleKeyDown} value={numeroGuia} onChange={(e)=>setNumeroGuia(e.target.value)} onBlur={(e)=>handleBlur(e)}/>
              </div>
            </div>
          </div>
        </div>
      {/* } */}
      {
        displayGuiaTable &&
          <div className="consultar-guias-manuales-por-numero-guia-table-container">
            <table className="tct-tae-table">
              <tr>
                <th>
                  <div className="th-data width-100">
                    <div className="th-text">
                      N° Guía
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-100">
                    <div className="th-text">
                      Fecha Trx
                      <div className="arrows">
                        <img
                          src={Flecha_up}
                        ></img>
                        <img
                          src={Flecha_down}
                        ></img>
                      </div> 
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-100">
                    <div className="th-text">
                      Código A.
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-100">
                    <div className="th-text">
                      Motivo
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-100">
                    <div className="th-text two-lines">
                      Forma
                      <br/>
                      Comer
                      <div className="arrows mtop">
                        <img
                          src={Flecha_up}
                        ></img>
                        <img
                          src={Flecha_down}
                        ></img>
                      </div> 
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-222">
                    <div className="th-text">
                      Cliente
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-100">
                    <div className="th-text">
                      Patente
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-136">
                    <div className="th-text">
                      N° de Tarjeta
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-100">
                    <div className="th-text">
                      Monto ($)
                      <div className="arrows">
                        <img
                          src={Flecha_up}
                        ></img>
                        <img
                          src={Flecha_down}
                        ></img>
                      </div> 
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-100">
                    <div className="th-text">
                      Estado
                      <div className="arrows">
                        <img
                          src={Flecha_up}
                        ></img>
                        <img
                          src={Flecha_down}
                        ></img>
                      </div> 
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data width-58">
                    <div className="th-text">
                      G.M
                    </div>
                  </div>
                </th>
              </tr>
              <tr className="table-separator-row-border-top">
                <td colSpan="100%" />
              </tr>
                  <tbody>
                    {
                      filteredData &&
                        filteredData.length > 0 
                          ? 
                            filteredData.map(data=>{
                              return(
                                <tr className="data row-with-rounded-borders">
                                  <td className={`align-left ${!data.editable?"table-link":""}`} onClick={e=>!data.editable && openInfo(data.rut_cliente, data.codigo_cliente, data.numero_transaccion, data.numero_guia, data.numero_tarjeta, data.codigo_autorizacion, data.fecha_autorizacion, data.hora_autorizacion, data.estado, data.editable)}>
                                    {data.numero_guia}
                                  </td>
                                  <td className="align-left">
                                    {data.fecha_autorizacion}
                                  </td>
                                  <td className="align-left">
                                    {data.codigo_autorizacion}
                                  </td>
                                  <td className="align-left">
                                    <OverflowedText widthContainer={100}>
                                      {data.motivo}
                                    </OverflowedText>
                                  </td>
                                  <td className="align-left">
                                    {data.producto}
                                  </td>
                                  <td className="align-left">
                                    {data.cliente}
                                  </td>
                                  <td className="align-left">
                                    {data.patente}
                                  </td>
                                  <td className="align-left">
                                    {data.numero_tarjeta}
                                  </td>
                                  <td className="align-left">
                                    {`$ ${getLocaleStringNumber(parseInt(data.monto))}`}
                                  </td>
                                  <td className="pd-left-7">
                                    <div className={`table-data-ellipsis estado-elipsis
                                      ${data.estado}
                                    `}>
                                      {data.estado}
                                    </div>
                                  </td>
                                    <td className={`align-left ${data.editable?"table-link":""}`} onClick={e=>data.editable && openInfo(data.rut_cliente, data.codigo_cliente, data.numero_transaccion, data.numero_guia, data.numero_tarjeta, data.codigo_autorizacion, data.fecha_autorizacion, data.hora_autorizacion, data.estado, data.editable)}>
                                      {data.editable?"Ver":""}
                                    </td>
                                </tr>
                              )
                            })
                          :
                            <>
                              <tr className="data">
                                <td colSpan={100}>El cliente no tiene guias manuales para el filtro de búsqueda ingresado</td>
                              </tr>
                              <tr className="data">
                                <td colSpan={100}></td>
                              </tr>
                            </>
                    }
                    <tr className="table-separator-row-border-top">
                      <td colSpan="100%" />
                    </tr>
                  </tbody>
                  {/* <tbody>
                    <tr className="data row-with-rounded-borders">
                      <td className="align-left" colSpan="100%">Ingrese Fecha de inicio y Fecha Fin</td>
                    </tr>
                    <tr className="table-separator-row-border-top">
                      <td colSpan="100%" />
                    </tr>
                  </tbody> */}
            </table>
          </div>
      }
    </div>
  );
};

export default ConsultarGuiasManualesPorNumeroGuia;
