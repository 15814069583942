import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import CustomSelect from "../CustomSelect";
import DatePicker from "react-datepicker";
import Camion from "./Camion";
import EstadoCrediticio from "./EstadoCrediticio";
import HeaderData from "../HeaderData";
import CustomModal from "../CustomModal";
import {
  SingleOption,
  customStylesPedidos,
  checkIfTomorrow,
  getCantidadTotal,
  getLocaleStringNumber
} from "../utils/utils.js";
import { setInstalalaciones, getCantidades, setPagadoresArcoprime } from "../utils/get";
import { displayLoader, hideGreyBackground, hideLoader, scrollTo, parentRedirect } from "../utils/windows";
import { allMateriales } from "../utils/materiales";
import { getUpdateDate } from "../utils/monthNames.js";
import ResumenIngresoPedido from "./ResumenIngresoPedido";
import { allPreprogramas } from "../utils/Preprogramas";
import FormularioPedidosMobile from "./FormularioPedidosMobile";
import CamionMobile from "./CamionMobile";

const tiposPedidosIngresar = [
  { label: "Estándar", value: "estandar" },
  { label: "Emergencia", value: "emergencia" }
];

const tiposPedidosModificar = [
  { label: "Estándar", value: "estandar" },
  { label: "Emergencia", value: "emergencia" },
  { label: "Preprograma", value: "preprograma" },
  { label: "Cambio de Fecha", value: "cambio de fecha" }
];

const FormularioPedidos = ({ rutConcesionario, token, params, pagador, setPagador, cabecera, instalaciones, edsPortal, callServiceHandler }) => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [updateDate, setUpdateDate] = useState();
  const [allEds, setAllEds] = useState([]);
  const [selectedEds, setSelectedEds] = useState();
  const [tiposPedidos, setTiposPedidos] = useState(tiposPedidosIngresar);
  const [tipoPedido, setTipoPedido] = useState(tiposPedidosIngresar[0]);
  const [camiones, setCamiones] = useState([]);
  const [camion, setCamion] = useState();
  const [lastAcceptedCamion, setLastAcceptedCamion] = useState();
  const [defaultCamion, setDefaultCamion] = useState();
  const [horarios, setHorarios] = useState([]);
  const [horario, setHorario] = useState();
  const [planta, setPlanta] = useState();
  const [plantas, setPlantas] = useState([]);
  const [contactos, setContactos] = useState([]);
  const [contacto, setContacto] = useState();
  const [date, setDate] = useState(tomorrow);
  const [numberOfComps, setNumberOfComps] = useState(3);
  const [disabledForm, setDisabledForm] = useState(true);
  const [displayCamionForm, setDisplayCamionForm] = useState(false);
  const [materiales, setMateriales] = useState([]);
  const [materialesNoEmpty, setMaterialesNoEmpty] = useState([]);
  const [compartimientos, setCompartimientos] = useState([]);
  const [observaciones, setObservaciones] = useState("");
  const [error, setError] = useState();
  const [displayModal, setDisplayModal] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalType, setModalType] = useState("");
  const [displayModalCamion3338, setDisplayModalCamion3338] = useState(false)
  const [modalCamion3338Text, setModalCamion3338Text] = useState("")

  const [displayResumenIngreso, setDisplayResumenIngreso] = useState(false);
  const [parametrosMix, setParametrosMix] = useState([]);
  const [idPedido, setIdPedido] = useState();
  const [idEdsPedido, setIdEdsPedido] = useState();
  const [pedidoOriginal, setPedidoOriginal] = useState();
  const [compartimientosPedido, setCompartimientosPedido] = useState();
  const [aceptarCobroFleteFalso, setAceptarCobroFleteFalso] = useState(false);
  const [capacidadCamion, setCapacidadCamion] = useState('');
  const [mixProductos, setMixProductos] = useState([])
  const isDesktopRef = useRef(window.innerWidth > process.env.REACT_APP_MOBILE_BREAK_POINT);
  const [isDinamo, setIsDinamo] = useState(false);
  const [camionPreprograma, setCamionPreprograma] = useState();

  //Arcoprime
  const [isArcoprime, setIsArcoprime] = useState(false);
  const [allPagadores, setAllPagadores] = useState([]);
  const [selectedPagador, setSelectedPagador] = useState();
  const [isSetPagadorArcoprime, setIsSetPagadorArcoprime] = useState(false)

  useEffect(() => {
    if (
      token &&
      (rutConcesionario || process.env.REACT_APP_ENVIRONMENT === "dev")
    ) {
      getUpdateDate(setUpdateDate);
      if (rutConcesionario === "77215640-5" /* || true */) {
        setIsArcoprime(true);
      }
    }
  }, [token, rutConcesionario]);

  useEffect(() => {
    if (params) {
      const paramsArr = params.split("&");
      setIdPedido(paramsArr[0].split("=")[1]);
      setIdEdsPedido(paramsArr[1].split("=")[1]);
      setTiposPedidos(tiposPedidosModificar);
    }
  }, [params]);

  useEffect(() => {
    if (cabecera!==""){
      if(cabecera.DATOS_CLIENTE.DINAMO==="X"){
        setIsDinamo(true);
      }
    }
  }, [cabecera]);

  useEffect(() => {
    if (instalaciones!==""){
      if(!isArcoprime){
        setInstalalaciones(instalaciones,setAllEds);
        setPlantas(instalaciones.INSTALACIONES);
      }
    }
  }, [instalaciones]);

  useEffect(async () => {
    if(selectedPagador){
      const url = process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/obtener-instalaciones-cliente?id_pagador=" + selectedPagador.value;

      let result = await callServiceHandler(url, "GET")
      if(result !== "error"){
        setInstalalaciones(result.data, setAllEds);
        setPlantas(result.data.INSTALACIONES);
      }

      setPagador(selectedPagador.value);
      if(isArcoprime)
        setIsSetPagadorArcoprime(true)
    }
  }, [selectedPagador]);

  useEffect(() => {
    if (allPagadores.length === 1) {
      changePagador(allPagadores[0]);
    }
  }, [allPagadores]);

  useEffect(() => {
    if(allEds && allEds.length > 0){
      if (idPedido && pagador) {
        getPedido();
      } else {
        if (allEds.length === 1) setSelectedEds(allEds[0]);
      }
    }
  }, [allEds, idPedido, pagador]);

  useEffect(() => {
    if(camiones && camiones.length > 0 && camionPreprograma){
      const tmpCamion = camiones.find((c) => c.ID === camionPreprograma);
      const preprograma = allPreprogramas.find(p => p.camion === camionPreprograma)
      if(tmpCamion){
        setCamionChange(tmpCamion)
      }
      else{
        setModalType("camionPreprogramaError");
        setModalTitle("Error");
        setModalText(
          "Volumen de preprograma no coincide con camiones disponibles en planta. Favor comunicate con el call center."
        );
        setDisplayModal(true);
      }
      if(!preprograma){
        setModalType("camionPreprogramaError");
        setModalTitle("Error");
        setModalText(
          "Camión de preprograma no existe en nuestros registros. Favor comunicate con el call center."
        );
        setDisplayModal(true);
      }
    }
  }, [camionPreprograma, camiones]);

  useEffect(() => {
    if (tipoPedido && tipoPedido.value === "emergencia") {
      if(!idPedido){
        setModalType("tipoPedidoChange");
        setModalTitle("Importante");
        setModalText(
          "Los pedidos de emergencia tienen un costo adicional de 3 UF, ¿desea continuar?"
        );
        setDisplayModal(true);
        setObservaciones("Favor ingresar horario hasta el cual tiene disponibilidad de producto: ");
      }
    } else if(tipoPedido && !idPedido) {
      setObservaciones("");
    }
  }, [tipoPedido]);

  useEffect(() => {
    if (
      pedidoOriginal &&
      idPedido &&
      compartimientosPedido &&
      camiones.length > 0 &&
      defaultCamion &&
      horarios.length > 0 &&
      contactos.length > 0 &&
      materiales.length > 0
    ) {
      if (pedidoOriginal.ID_HORARIO === "")
        setHorario(horarios.find((h) => h.NUMERO === "004"));
      else
        setHorario(horarios.find((h) => h.NUMERO === pedidoOriginal.ID_HORARIO));

      setContacto(
        contactos.find(
          (c) =>
            c.ID_SOLICITANTE === pedidoOriginal.ID_SOLICITANTE
        )
      );
    }
  }, [
    pedidoOriginal,
    compartimientosPedido,
    camiones,
    defaultCamion,
    horarios,
    contactos,
    materiales
  ]);

  useEffect(() => {
    if(!idPedido)
      setContacto(contactos[0])
  }, [contactos])

  useEffect(() => {
    if (selectedEds && planta) {
      getTipoCamion();
      getHorariosEntregas();
      getPersonaContacto();
      getMateriales();
      if (tipoPedido && planta) setDisabledForm(false);
      else setDisabledForm(true);
    } else setDisabledForm(true);
  }, [planta, tipoPedido]);

  useEffect(() => {
    if (defaultCamion && materiales.length > 0 && !camionPreprograma) {
      setCamionChange(defaultCamion);
    }
  }, [defaultCamion, materiales]);

  useEffect(() => {
    if (selectedEds && plantas.length > 0) {
      const found = plantas.find((p) => p.ID_EDS === selectedEds.value);
      setPlanta(found);
    } else setDisabledForm(true);
  }, [selectedEds, plantas]);

  useEffect(() => {
    setError()
  }, [selectedPagador, selectedEds, date, horario, contacto, observaciones, compartimientos]);

  useEffect(() => {
    if (selectedEds && pagador) {
      if (rutConcesionario !== "77215640-5" || isSetPagadorArcoprime) {
        getEstado()
      }
    }
  }, [selectedEds, pagador, isSetPagadorArcoprime]);

  async function getEstado(){
    const url =
      process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/estado-estacion?id_usuario=" +
      pagador +
      "&id_eds=" +
      selectedEds.value;

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      if (result.data.ESTADO !== 0) {
          setSelectedEds();
          setDisplayModal(true);
          setModalType("edsNoActiva");
          setModalTitle("Importante");
          setModalText(result.data.MENSAJE);
        }
    }
  }

  useEffect(() => {
    if (
      selectedEds &&
      planta &&
      tipoPedido &&
      camion &&
      date &&
      horario &&
      contacto
    )
      setDisplayCamionForm(true);
    else setDisplayCamionForm(false);
  }, [selectedEds, planta, tipoPedido, camion, date, horario, contacto]);

  useEffect(() => {
    if (
      selectedEds &&
      planta &&
      tipoPedido &&
      camion &&
      date &&
      horario &&
      contacto
    )
      setDisplayCamionForm(true);
    else setDisplayCamionForm(false);
  }, [selectedEds, planta, tipoPedido, camion, date, horario, contacto]);

  async function getPedido() {
    displayLoader()
    const url =
      process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/buscar-pedidos?nuevo=1&fecha_inicio=01-01-2020&numero_pedido=" +
      idPedido + "&id_eds=" + idEdsPedido +
      "&id_pagador=" + pagador;

    let result = await callServiceHandler(url, "GET")

    if(result !== "error"){
      const pedido = result.data.PEDIDOS[0];
      setPedidoOriginal(pedido);

      const found = allEds.find((eds) => eds.value.includes(pedido.ID_EDS));

      setSelectedEds(found);

      let tipo = pedido.TIPO_PEDIDO.normalize("NFD").replace(
        /[\u0300-\u036f]/g,
        ""
      );

      if(tipo === 'Emergencia/Express')
        tipo = 'emergencia'
      
      const tipoPedidoFound = tiposPedidosModificar.find(
        (t) => t.value === tipo.toLowerCase()
      );
      setTipoPedido(tipoPedidoFound);
      setObservaciones(pedido.TEXT_SCR)

      const dateArr = pedido.FECHA_PREFERENTE_ENTREGA.split("-");
      setDate(new Date(dateArr[0], dateArr[1] - 1, dateArr[2]));

      if(tipoPedidoFound.value == 'preprograma'){
        setCamionPreprograma(pedido.POSEX_E)
      }

      if(pedido.DINAMO !== 'X'){
        const comps = pedido.DETALLE.filter((obj) => {
          return obj.CATEGORIA_ITEM === "ZCRM";
        });

        setCompartimientosPedido(comps)
      }
      else{
        setCompartimientosPedido(pedido.DETALLE)
      }
    }
  }

  async function checkVentana() {
    if (!horario || idPedido) return;
    const today = new Date();
    const hour = today.getHours();
    const minutes = today.getMinutes();

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (tipoPedido.value === "estandar") {
      if (checkIfTomorrow(date)) {
        if (
          hour >= 14 &&
          (horario.NUMERO == "001" || horario.NUMERO === "006")
        ) {
          const url =
            process.env.REACT_APP_PEDIDOS_API +
            "/pedidos/obtener-limite-horario?tipo_pedido=" +
            tipoPedido.value +
            "&horario_entrega=" +
            horario.NUMERO;

          let result = await callServiceHandler(url, "GET")
          if(result !== "error"){
            const horaLimiteArr = result.data.HORA_LIMITE.split(":");
            if (
              parseInt(horaLimiteArr[0]) < hour ||
              (parseInt(horaLimiteArr[0]) === hour &&
                minutes > parseInt(horaLimiteArr[1]))
            ) {
              setModalType("ventanaAlert");
              setModalTitle("Importante");
              setModalText(
                "El plazo ha vencido para el ingreso del pedido en esta ventana horaria, límite de horario 14 hrs."
              );
              setDisplayModal(true);
              setHorario(horarios.find((h) => h.NUMERO === "002")); // 14:00 - 20:00
            }
          }
        }
      }
    }
  }

  function setVentana() {
    const today = new Date();
    const hour = today.getHours();
    const minutes = today.getMinutes();

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    if (tipoPedido.value === "estandar") {
      setDate(tomorrow);
      if (hour < 14) setHorario(horarios.find((h) => h.NUMERO === "001"));
      // 8:00 - 14:00
      else setHorario(horarios.find((h) => h.NUMERO === "002"));
    } else {
      setDate(today);
      setHorario(horarios.find((h) => h.NUMERO === "004")); // 00:00 - 23:59
    }
  }

  useEffect(() => {
    if (!idPedido) setVentana();
  }, [tipoPedido, horarios]);

  useEffect(() => {
    checkVentana();
  }, [horario]);

  function openModal() {
    setDisplayModal(true);
  }

  function closeModal() {
    setDisplayModal(false);
    if(modalType === "camionChange") setLastAcceptedCamion(camion);

    else if(modalType === "camionNoParametrizado"){
      setCamiones([]);
      setDefaultCamion();
      setCamion();
      setCapacidadCamion('')
    }

    else if(modalType === 'camionPreprogramaError'){
      const url = process.env.REACT_APP_PEDIDOS_ACTIVOS_PATH
      parentRedirect(url)
    }
  }

  function closeModalCamion3338(){
    setDisplayModalCamion3338(false)
  }

  function acceptCambio3338(){
    setDisplayModalCamion3338(false)
    validate()
  }

  function clearForm() {
    hideGreyBackground();
    if(idPedido){
      const url = process.env.REACT_APP_PEDIDOS_ACTIVOS_PATH
      parentRedirect(url)
    }

    if(!idPedido){
      if (allEds.length === 1) {
        setSelectedEds(allEds[0]);
        setCamionChange(defaultCamion);
      } else {
        setSelectedEds(null);
        setCamion();
        setCapacidadCamion('')
        setPlanta();
      }
    }

    setModalType();
    setError();

    if(!idPedido){
      setTipoPedido(tiposPedidosIngresar[0]);
    }

    if(!idPedido || (idPedido && tipoPedido.value === 'estandar')){
      setVentana();
      const newTomorrow = new Date();
      newTomorrow.setDate(newTomorrow.getDate() + 1);
      setDate(newTomorrow);
    }

    if(idPedido && tipoPedido.value === 'emergencia'){
      setObservaciones(
        "Favor ingresar horario hasta el cual tiene disponibilidad de producto: "
      )
    }
    else{
      setObservaciones("");
    }

    if(contactos.length > 0)
      setContacto(contactos[0])
    else
      setContacto("");
    setDisplayCamionForm(false);
    setDisplayResumenIngreso(false);
  }

  function cancelarModal() {
    if (modalType === "camionChange") {
      if (lastAcceptedCamion) setCamionChange(lastAcceptedCamion);
      else setCamionChange(defaultCamion);
    } else if (modalType === "tipoPedidoChange")
      setTipoPedido(tiposPedidosIngresar[0]);
    else if (modalType === "cobroFleteFalso") setAceptarCobroFleteFalso(false);

    setDisplayModal(false);
  }

  function acceptCobroFleteFalso() {
    setDisplayModal(false);
    validate();
  }

  async function getTipoCamion() {
    displayLoader();
    const url =
      process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/tipo-camion?id_planta=" +
      planta.CODIGO_PLANTA +
      "&id_eds=" +
      selectedEds.value;

    let result = await callServiceHandler(url, "GET")

    if(result !== "error"){
      if (result.data.ESTADO === 0) {
        setCamiones(result.data.CAMIONES);
        const initialCamion = result.data.CAMIONES.find(
          (e) => e.ID === result.data.CAMION
        );
        setDefaultCamion(initialCamion);
      } else {
        setModalType("camionNoParametrizado");
        setModalTitle("Importante");
        setModalText(
          "Camión por defecto no parametrizado. Comuníquese con su Jefe de Zona."
        );
        setDisplayModal(true);
      }
    }
  }

  async function getHorariosEntregas() {
    const url =
      process.env.REACT_APP_PEDIDOS_API + "/pedidos/horarios-entregas";

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      setHorarios(result.data['VENTANAS'])
    }
  }

  async function getPersonaContacto() {
    const url =
      process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/obtener-interlocutores?id_planta=" +
      planta.CODIGO_PLANTA +
      "&id_eds=" +
      selectedEds.value;

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      setContactos(result.data['INTERLOCUTORES'])
    }
  }

  async function getMateriales() {
    const materialesURL =
      process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/listar-materiales?id_eds=" +
      selectedEds.value;

    let result = await callServiceHandler(materialesURL, "GET")
    if(result !== "error"){
      let newMateriales = result.data.MATERIALES.filter(
        (mat) => allMateriales[mat.ID_MATERIAL]
      );
      setMaterialesNoEmpty([...newMateriales]);
      newMateriales.push({ NOMBRE: "Vacío", ID_MATERIAL: "empty" });
      setMateriales(newMateriales);
    }
  }

  function setMaterial(e, key, volumen) {
    if (e.ID_MATERIAL === "empty") volumen = 0;
    const newCompartimientos = [...compartimientos];
    compartimientos[key].material = e;
    compartimientos[key].volumen = volumen;
    setCompartimientos(newCompartimientos);
  }

  function setCamionChange(e) {
    setCamion(e);
    setCapacidadCamion(e.VOLUMEN_MAXIMO)

    const newCompartimientos = e.COMPARTIMIENTOS;

    newCompartimientos.forEach(function (comp) {
      let newMaterial = { NOMBRE: "Vacío", ID_MATERIAL: "empty" };

      if (materiales.find((e) => e.ID_MATERIAL === "000000000000000005"))
        newMaterial = materiales.find(
          (e) => e.ID_MATERIAL === "000000000000000005"
        );
      else if (materiales.find((e) => e.ID_MATERIAL === "000000000000000006"))
        newMaterial = materiales.find(
          (e) => e.ID_MATERIAL === "000000000000000006"
        );
      else if (comp.NUMERO === 1) newMaterial = materiales[0];

      if(!idPedido){
        comp.material = newMaterial;
        if (newMaterial.ID_MATERIAL === "empty") comp.volumen = 0;
        else comp.volumen = comp.VOLUMEN_MAXIMO;
      }
      else{
        comp.material = { NOMBRE: "Vacío", ID_MATERIAL: "empty" };
        comp.volumen = 0;
      }
    });

    setCompartimientos(newCompartimientos);

    if (defaultCamion && e.ID === defaultCamion.ID)
      setLastAcceptedCamion(defaultCamion);

    if (defaultCamion && e.ID != defaultCamion.ID && tipoPedido.value !== 'preprograma') {
      setDisplayModal(true);
      setModalType("camionChange");
      setModalTitle("Importante");
      setModalText(
        "Por solicitar equipo distinto a su camión tipo, quedará sujeto a disponibilidad de flota, ¿desea continuar?"
      );
    }
  }

  async function validate() {
    displayLoader();
    setError();
    let vacios = 0;
    let volumen = 0;
    let volumenDieselKerosene = 0
    let hasOnlyDieselKerosene = true;
    let hasDobleFlecha = false;
    let compVolumenMaximoIndex = null;

    if(tipoPedido.value !== 'emergencia' || !idPedido){ //Si no se esta editando un pedido de emergencia

      if(compartimientos[0].material.ID_MATERIAL === "empty"){
        setError("El primer compartimiento del camión no puede ser vacío.")
        hideLoader()
        return false
      }

      //Nueva Regla 25/04/2023

      let volMaximoComp = 0
      compartimientos.forEach(function (comp, index) {
        if(comp.VOLUMEN_MAXIMO > 0 && comp.VOLUMEN_MINIMO > 0 && comp.VOLUMEN_MINIMO < comp.VOLUMEN_MAXIMO){
          hasDobleFlecha = true
          if(comp.VOLUMEN_MAXIMO > volMaximoComp){
            volMaximoComp = comp.VOLUMEN_MAXIMO
            compVolumenMaximoIndex = index
          }
        }
        if (comp.material.ID_MATERIAL === "empty") {
          vacios++;
          hasOnlyDieselKerosene = false;
        } else {
          volumen += comp.volumen;
          if (allMateriales[comp.material.ID_MATERIAL].class !== "pd" && allMateriales[comp.material.ID_MATERIAL].class !== "k") {
            hasOnlyDieselKerosene = false;
          }
          else{
            volumenDieselKerosene += comp.volumen;
          }
        }
      });

      //Modificacion Regla 08/09. Se cambia el compartimento de doble flecha de mayor capacidad automaticamente.
      if (hasOnlyDieselKerosene && hasDobleFlecha && vacios == 0) {
        if(volumen >= capacidadCamion){
          /*
          setError(
            "Para pedidos con Diesel y/o Kerosene, es obligatorio seleccionar al menos un compartimiento con volumen mínimo"
          );
          hideLoader();
          return false;
          */

          const newCompartimientos = [...compartimientos];
          const newVolumen = volumen - newCompartimientos[compVolumenMaximoIndex].volumen + compartimientos[compVolumenMaximoIndex].VOLUMEN_MINIMO
          newCompartimientos[compVolumenMaximoIndex].volumen = compartimientos[compVolumenMaximoIndex].VOLUMEN_MINIMO;
          setCompartimientos(newCompartimientos);
          setModalCamion3338Text(`No es posible solicitar ${(volumen/1000).toString()}m3 de este o estos productos, se ha modificado el volumen a ${(newVolumen/1000).toString()}m3`)
          setDisplayModalCamion3338(true);
          hideLoader();
          return false;
        }
      }

      //Fin nueva regla 25/04/2023


      //Nueva Regla 04/05/2023. Regla especial camiones 33 y 38
      //Modificacion Regla 07/08. Se cambia el compartimento de doble flecha de mayor capacidad automaticamente.
      if((capacidadCamion === 33000 || capacidadCamion === 38000) 
        && volumen === capacidadCamion 
        && volumenDieselKerosene > 0 
        && volumenDieselKerosene < volumen){
          const maximoPosible = capacidadCamion === 33000? 24000: 23000;
          if(volumenDieselKerosene > maximoPosible){
            const newCompartimientos = [...compartimientos];
            newCompartimientos[compVolumenMaximoIndex].volumen = compartimientos[compVolumenMaximoIndex].VOLUMEN_MINIMO;
            setCompartimientos(newCompartimientos);
            setModalCamion3338Text(`No es posible solicitar ${(capacidadCamion/1000).toString()}m3 de este o estos productos, se ha modificado el volumen a ${capacidadCamion === 33000? '32m3': '37m3'}`)
            setDisplayModalCamion3338(true);
            hideLoader();
            return false;
          }
      }
      //Fin nueva regla 04/05/2023


      // Regla volumen preprograma 14/07/2022: Los preprogramas pueden tener mas de 1 compartimento vacio pero
      // no pueden tener un volumen mayor al del preprograma aun q el camion se los permita

      if(tipoPedido.value === 'preprograma'){
        const preprograma = allPreprogramas.find(p => p.camion === camionPreprograma)
        
        if(volumen > preprograma.max){
          setError("El volumen total no puede superar el volumen del preprograma")
          hideLoader();
          return false;
        }
      }

      //Nueva regla: el volumen total no puede variar mas de 1000 hacia ariba o abajo de la capacidad del camion
      //Regla eliminada el 26/04/2023
      /*
      if ((volumen < (capacidadCamion - 1000) || volumen > (capacidadCamion + 1000)) && tipoPedido.value !== 'preprograma' ) {
        setError(
          "El volumen total no puede variar en mas de 1000 lts de la capacidad del camión"
        );
        hideLoader();
        return false;
      }
      */

      //validar numero de compartimentos vacios
      if (vacios > 1 && tipoPedido.value !== 'preprograma') {
        setError(
          "No se puede ingresar un pedido con más de un compartimento vacío."
        );
        hideLoader();
        return false;
      }

      //validar volumen total
      if (volumen > camion.VOLUMEN_MAXIMO) {
        setError(
          "El volumen total ingresado excede la capacidad máxima del camión."
        );
        hideLoader();
        return false;
      }

      if (tipoPedido.value === "emergencia" && capacidadCamion < 15000) {
        setError("Un Pedido de Emergencia debe ser en Camión mayor a 15 m3.");
        hideLoader();
        return false;
      }

      if (vacios === 1 && !aceptarCobroFleteFalso) {
        setAceptarCobroFleteFalso(true);
        setModalType("cobroFleteFalso");
        setModalTitle("Importante");
        setModalText(
          "Ingresar un Pedido con un compartimento vacío puede originar el cobro de un Falso Flete. ¿Desea continuar?"
        );
        setDisplayModal(true);
        hideLoader();
        return false;
      }
    }

    //validar observaciones pedido emergencia
    if (tipoPedido.value === "emergencia" && !idPedido) {
      const valueArr = observaciones.split(": ");
      if (!valueArr[1] || (valueArr[1] && valueArr[1].length === 0)) {
        setError(
          "Se debe ingresar el horario solicitado en el campo 'observaciones'."
        );
        hideLoader();
        return false;
      }
    }

    //validar planta domingo
    if (date.getDay() === 0) {
      const isAvailable = await validarPlantaDomingo();

      if (!isAvailable) {
        setModalType("plantaDomingo");
        setModalTitle("Importante");
        setModalText("Planta no habilitada para operar el día domingo");
        setDisplayModal(true);
        hideLoader();
        return false;
      }
    }

    //validar fecha limite
    const isDateInRange = await validarFechaLimite();
    //const isDateInRange = true;
    if (!isDateInRange) {
      setModalType("fechaFuturo");
      setModalTitle("Importante");
      setModalText(
        "La fecha preferente de entrega excede el límite, favor contactar al Call Center al<br />600 200 02 02"
      );
      setDisplayModal(true);
      hideLoader();
      return false;
    }

    //validar nuevo mix no tenga mas litros que el anterior en modificar preprograma
    //REGLA RETIRADA
    /* if (tipoPedido.value === "preprograma" && idPedido) {
      let newVolumen = 0

      compartimientos.map((p, key) => {
        if(p.material.ID_MATERIAL !== 'empty'){
          newVolumen += p.volumen
        }
      })

      const cantidadOriginal = isDinamo?getCantidades(pedidoOriginal,"SOLICITADA"):getCantidadTotal(pedidoOriginal);

      if(newVolumen > cantidadOriginal){
        setModalType("")
        setModalTitle("Error")
        setModalText("El nuevo mix no puede tener más litros que el anterior.")
        setDisplayModal(true)
        hideLoader()
        return false
      }
    } */

    hideLoader();
    setError();
    setAceptarCobroFleteFalso(false);
    calculateMixProductos()
    setDisplayResumenIngreso(true);
    if(!isDesktopRef.current)
      scrollTo(0,0)
    scrollTo(0,295);
  
  }

  function calculateMixProductos(){
    const mix = []
    compartimientos.map((p, key) => {
      if(p.material.ID_MATERIAL !== 'empty'){
        const found = mix.find(m => m.ID_MATERIAL === p.material.ID_MATERIAL)
        if(found){
          found.CANTIDAD += p.volumen
        }
        else{
          mix.push({
            ID_MATERIAL: p.material.ID_MATERIAL,
            NOMBRE: p.material.NOMBRE,
            CANTIDAD: p.volumen,
            POSNR: p.posnr
          })
        }
      }
    })

    if(idPedido && tipoPedido.value === 'emergencia')
      setMixProductos(compartimientosPedido)
    else
      setMixProductos(mix)
  }

  async function validarPlantaDomingo() {
    const url =
      process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/validar-planta-domingo?id_planta=" +
      planta.CODIGO_PLANTA;

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      if (result.data.ESTADO !== "") return false;
    }
    else{
      return false
    }

    return true;
  }

  async function validarFechaLimite() {
    const url =
      process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/obtener-limite-horario?tipo_pedido=" +
      tipoPedido.value +
      "&horario_entrega=" +
      horario.NUMERO;

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      const fechaLimiteArr = result.data.FECHA_LIMITE.split("-");
      const fechaLimite = new Date(
        fechaLimiteArr[2],
        fechaLimiteArr[1] - 1,
        fechaLimiteArr[0]
      );
      const compareDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );

      if (compareDate > fechaLimite) return false;
    }
    else{
      return false;
    }

    return true;
  }

  function checkSetObservaciones(value) {
    if (tipoPedido.value === "emergencia" && !idPedido) {
      const valueArr = value.split(": ");
      if (valueArr.length < 2) return;
      if (
        valueArr[0] !==
        "Favor ingresar horario hasta el cual tiene disponibilidad de producto"
      )
        return;
    }

    setObservaciones(value);
  }

  const plantaValue =
    planta != null
      ? `${planta.CODIGO_PLANTA} - ${planta.NOMBRE_PLANTA.toLowerCase()}`
      : "-";

  const goBack = () => {
    setDisplayResumenIngreso(false);
  }

  const goBackToActivos = () => {
    const url = process.env.REACT_APP_PEDIDOS_ACTIVOS_PATH;
    parentRedirect(url);
  }

  useEffect(() => {
    if(edsPortal){
      if(!idPedido){
        setPagadoresArcoprime(edsPortal.data.user_eds, setAllPagadores);
      }
      else{
        let tmpIdEds = idEdsPedido
        if(tmpIdEds.length === 10){
          tmpIdEds = tmpIdEds.substring(5, 10)
        }
        tmpIdEds = '1' + tmpIdEds
        
        setAllPagadores([{ value: '0000' + tmpIdEds, label: tmpIdEds }])
        setSelectedPagador({ value: '0000' + tmpIdEds, label: tmpIdEds })
      }
    }
  }, [edsPortal]);

  function changePagador(e) {
    setSelectedPagador(e);
  }

  return (
    <div className="formulario-pedidos-container page">
      <div className="title-container">
        <div className="title">
          <div className="view-title">Pedidos Combustibles</div>
          <div className="view-title-separator">|</div>
          <div className="view-subtitle">
            {idPedido
              ? `Modificar pedido Nº ${idPedido}`
              : "Ingresar pedido combustible"}
          </div>
        </div>
        {
          displayResumenIngreso? <div className="volver-arrow" onClick={()=>goBack()} >
          <img></img>
          <p className="volver-text">Volver</p>
          </div>:idPedido?<div className="volver-arrow" onClick={()=>goBackToActivos()} >
          <img></img>
          <p className="volver-text">Volver</p>
          </div>:<></>
        }
      </div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        cabecera={cabecera}
        callServiceHandler={callServiceHandler}
      />
      <hr />
      <CustomModal
        displayModal={displayModal}
        closeModal={
          modalType === "camionChange" ||
          modalType === "tipoPedidoChange" ||
          modalType === "cobroFleteFalso"
            ? cancelarModal
            : closeModal
        }
        modalTitle={modalTitle}
        modalText={modalText}
        cancelFunc={
          modalType === "camionNoParametrizado" ||
          modalType === "ventanaAlert" ||
          modalType === "plantaDomingo" ||
          modalType === "fechaFuturo" ||
          modalType === "edsNoActiva" ||
          modalType === 'camionPreprogramaError'
            ? null
            : cancelarModal
        }
        acceptFunc={
          modalType === "cobroFleteFalso" ? acceptCobroFleteFalso : closeModal
        }
        modalIcon="error"
      />

      <CustomModal
        displayModal={displayModalCamion3338}
        closeModal={closeModalCamion3338}
        modalTitle="Importante"
        modalText={modalCamion3338Text}
        acceptFunc={acceptCambio3338}
        modalIcon="importante"
      />
      {displayResumenIngreso ? (
        <ResumenIngresoPedido
          setDisplayResumenIngreso={setDisplayResumenIngreso}
          selectedEds={selectedEds}
          tipoPedido={tipoPedido}
          planta={planta}
          camion={camion}
          horario={horario}
          contacto={contacto}
          observaciones={observaciones}
          date={date}
          token={token}
          clearForm={clearForm}
          idPedido={idPedido}
          compartimientosPedido={compartimientosPedido}
          codigoTipoPedidoOriginal={(pedidoOriginal && pedidoOriginal.CODIGO_TIPO_PEDIDO) || ''}
          mixProductos={mixProductos}
          callServiceHandler={callServiceHandler}
        />
      ) : (
        <>
          {isDesktopRef.current ? (
            <>
              <div className={displayResumenIngreso ? "hidden" : ""}>
                <div className="formulario-pedidos">
                  <div className="filters-container">
                    {isArcoprime && <div className="filter">
                      <div className="filter-title">ID DE PAGADOR<span className="red-asterisk">*</span></div>
                      <div className="filter-input">
                        <Select
                          className="eds-select"
                          value={selectedPagador}
                          onChange={(e) => changePagador(e)}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          options={allPagadores}
                          components={{
                            SingleOption,
                            IndicatorSeparator: () => null,
                          }}
                          isClearable={false}
                          autosize={false}
                          placeholder="Seleccionar"
                          styles={customStylesPedidos}
                          isDisabled={idPedido}
                        />
                      </div>
                    </div>}
                    <div className="filter">
                      <div className="filter-title">ESTACIÓN DE SERVICIO<span className="red-asterisk">*</span></div>
                      <div className="filter-input">
                        <Select
                          className="eds-select"
                          value={selectedEds}
                          onChange={(e) => setSelectedEds(e)}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          options={allEds}
                          components={{
                            SingleOption,
                            IndicatorSeparator: () => null,
                          }}
                          isClearable={false}
                          autosize={false}
                          placeholder="Seleccionar"
                          styles={customStylesPedidos}
                          isDisabled={idPedido}
                        />
                      </div>
                    </div>

                    <div className="filter">
                      <div className="filter-title">TIPO DE PEDIDO<span className="red-asterisk">*</span></div>
                      <div className="filter-input">
                        <Select
                          className="eds-select"
                          value={tipoPedido}
                          onChange={(e) => setTipoPedido(e)}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          options={tiposPedidos}
                          components={{
                            SingleOption,
                            IndicatorSeparator: () => null,
                          }}
                          isClearable={false}
                          autosize={false}
                          placeholder="Seleccionar"
                          styles={customStylesPedidos}
                          isDisabled={idPedido}
                        />
                      </div>
                    </div>

                    <div className="filter">
                      <div className="filter-title">PLANTA<span className="red-asterisk">*</span></div>
                      <div className="filter-input">
                        <div className="planta">{plantaValue}</div>
                      </div>
                    </div>

                    <hr className="planta-hr" />

                    <div className="filter">
                      <div className="filter-title">TIPO DE CAMIÓN<span className="red-asterisk">*</span></div>
                      <div className="filter-input">
                        <Select
                          className="eds-select"
                          value={camion}
                          onChange={(e) => setCamionChange(e)}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          options={camiones}
                          getOptionLabel={(option) => `${option.NOMBRE}`}
                          components={{
                            SingleOption,
                            IndicatorSeparator: () => null,
                          }}
                          isClearable={false}
                          autosize={false}
                          placeholder="Seleccionar"
                          styles={customStylesPedidos}
                          isDisabled={
                            disabledForm ||
                            (idPedido && tipoPedido.value === "emergencia") ||
                            tipoPedido.value === "preprograma"
                          }
                        />
                      </div>
                    </div>

                    <div className="filter">
                      <div className="filter-title">FECHA PREFERENTE<span className="red-asterisk">*</span></div>
                      <div className="filter-input">
                        <DatePicker
                          locale="es"
                          selected={date}
                          onChange={(e) => setDate(e)}
                          shouldCloseOnSelect={true}
                          minDate={
                            tipoPedido.value === "estandar"
                              ? tomorrow
                              : new Date()
                          }
                          dateFormat="dd/MM/yyyy"
                          className="pedidos-datepicker"
                          readOnly={
                            disabledForm ||
                            tipoPedido.value === "emergencia" ||
                            tipoPedido.value === "preprograma"
                          }
                          placeholderText="Seleccionar"
                        />
                      </div>
                    </div>

                    <div className="filter">
                      <div className="filter-title">VENTANA DE TIEMPO<span className="red-asterisk">*</span></div>
                      <div className="filter-input">
                        <Select
                          className="eds-select"
                          value={horario}
                          onChange={(e) => setHorario(e)}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          options={horarios}
                          getOptionLabel={(option) =>
                            `${option.HORARIO || "Seleccionar"}`
                          }
                          components={{
                            SingleOption,
                            IndicatorSeparator: () => null,
                          }}
                          isClearable={false}
                          autosize={false}
                          placeholder="Seleccionar"
                          styles={customStylesPedidos}
                          isDisabled={
                            disabledForm ||
                            tipoPedido.value === "emergencia" ||
                            tipoPedido.value === "preprograma"
                          }
                        />
                      </div>
                    </div>

                    <div className="filter">
                      <div className="filter-title">CONTACTO<span className="red-asterisk">*</span></div>
                      <div className="filter-input">
                        <Select
                          className="eds-select"
                          value={contacto}
                          onChange={(e) => setContacto(e)}
                          closeMenuOnSelect={true}
                          hideSelectedOptions={false}
                          options={contactos}
                          getOptionLabel={(option) =>
                            `${option.NOMBRE} ${option.APELLIDO}`
                          }
                          components={{
                            SingleOption,
                            IndicatorSeparator: () => null,
                          }}
                          isClearable={false}
                          autosize={false}
                          placeholder="Seleccionar"
                          styles={customStylesPedidos}
                          isDisabled={
                            disabledForm || 
                            (idPedido && 
                              (tipoPedido.value === 'emergencia' || tipoPedido.value === 'cambio de fecha')
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="camion-info-container">
                    <Camion
                      camion={camion}
                      compartimientos={compartimientos}
                      displayCamionForm={(tipoPedido.value !== "emergencia" || !idPedido)? displayCamionForm: false}
                    />
                    {displayCamionForm && (
                      <>
                        { idPedido &&
                          <div className="mix-pedidos-actual">
                            <div className="mix-pedidos-actual-title">
                              MIX DE PRODUCTOS ACTUAL
                            </div>
                            <table className="flutuaciones-table configurations-table modificar-pedidos-table">
                              <thead>
                                <tr>
                                  <th className="productos">Productos</th>
                                  <th className="litros">Litros</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="horizontal-separator" colSpan={6} />
                                </tr>
                                {compartimientosPedido.map((comp, key) =>
                                  <tr className={key < compartimientosPedido.length - 1? "row-with-border": ""}>
                                    <td className={key < compartimientosPedido.length - 1? "cell-border-top-left": ""}>
                                      {comp.NOMBRE}
                                    </td>
                                    <td className={key < compartimientosPedido.length - 1? "cell-border-top-right": ""}>
                                      {getLocaleStringNumber(comp.CANTIDAD_SOLICITADA)}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        }
                        { (!idPedido || tipoPedido.value !== "emergencia") &&
                          <>
                            <div className="camion-info-container__title">
                              <b>
                                CAMIÓN {capacidadCamion.toLocaleString("es")}{" "}
                                L
                              </b>{" "}
                              | {camion && camion.NOMBRE}
                            </div>
                            <hr className="mini-hr" />

                            <div className="comp-container">
                              {compartimientos.map((comp, key) => (
                                <div
                                  className={`comp comp${
                                    camion.NUMERO_COMPARTIMIENTOS
                                  }${
                                    camion.NUMERO_COMPARTIMIENTOS - 1 === key
                                      ? " last-comp"
                                      : ""
                                  }`}
                                  key={key}
                                >
                                  <div className="filter">
                                    <div className="filter-title">{`COMP. ${
                                      comp.NUMERO
                                    } (${comp.VOLUMEN_MAXIMO / 1000} M3)`}<span className="red-asterisk">*</span></div>
                                    <div className="filter-input">
                                      <CustomSelect
                                        name={`select-compartimiento-${key}`}
                                        options={
                                          key === 0 ? materialesNoEmpty : materiales
                                        }
                                        compartimiento={compartimientos[key]}
                                        setFunction={setMaterial}
                                        selectKey={key}
                                        volumenMaximoCamion={camion.VOLUMEN_MAXIMO}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        }

                        <div className={`observaciones ${isDinamo? 'observaciones-dinamo': ''}`}>
                          <div className="observaciones__title">
                            OBSERVACIONES
                          </div>
                          <textarea
                            value={observaciones}
                            maxLength="500"
                            onChange={(e) =>
                              checkSetObservaciones(e.target.value)
                            }
                          />
                          <div className="observaciones-cont">
                            {observaciones.length}/500
                          </div>
                        </div>
                        { isDinamo &&
                          <EstadoCrediticio 
                            token={token}
                            pagador={pagador}
                            callServiceHandler={callServiceHandler}
                          />
                        }
                      </>
                    )}
                  </div>
                </div>
                <div className="formulario-pedidos-align-right-container">
                  <div className="formulario-pedidos-align-right">
                    {error && (
                      <div className="error-pedidos">
                        <span>
                          <div className="icon" />
                        </span>
                        {error}
                      </div>
                    )}
                    <hr />
                    <div className="actions-container">
                      <button className="main-button hallow" onClick={clearForm}>
                        {idPedido ? "Cancelar" : "Limpiar"}
                      </button>
                      <button
                        className="main-button"
                        disabled={!displayCamionForm}
                        onClick={validate}
                      >
                        {idPedido ? "Modificar" : "Ingresar Pedido"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <FormularioPedidosMobile
                idPedido={idPedido}
                selectedEds={selectedEds}
                selectEds={setSelectedEds}
                eds={allEds}
                allPagadores={allPagadores}
                selectedPagador={selectedPagador}
                setSelectedPagador={setSelectedPagador}
                isArcoprime={isArcoprime}
                tipoPedido={tipoPedido}
                selectTipoPedido={setTipoPedido}
                tiposPedidos={tiposPedidos}
                planta={plantaValue}
                camion={camion}
                selectCamion={setCamionChange}
                camiones={camiones}
                disabledForm={disabledForm}
                disabledDatePickers={
                  disabledForm ||
                  tipoPedido.value === "emergencia" ||
                  tipoPedido.value === "preprograma"
                }
                date={date}
                selectDate={setDate}
                horario={horario}
                selectHorario={setHorario}
                horarios={horarios}
                contacto={contacto}
                selectContacto={setContacto}
                contactos={contactos}
                minDate={
                  tipoPedido.value === "estandar" ? tomorrow : new Date()
                }
              />
              {displayCamionForm &&
                <>
                  {idPedido &&
                    <div className="mix-pedidos-actual">
                      <div className="mix-pedidos-actual-title">
                        MIX DE PRODUCTOS ACTUAL
                      </div>
                      <table className="flutuaciones-table configurations-table modificar-pedidos-table">
                        <thead>
                          <tr>
                            <th className="productos">Productos</th>
                            <th className="litros">Litros</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="horizontal-separator" colSpan={2} />
                          </tr>
                          {compartimientosPedido.map((comp, key) =>
                            <tr className={key < compartimientosPedido.length - 1? "row-with-border": ""}>
                              <td className={key === compartimientosPedido.length - 1? "cell-border-top-left productos-col": "productos-col"}>
                                <div className="modificar-pedidos-table-content">{comp.NOMBRE}{key}</div>
                              </td>
                              <td className={key === compartimientosPedido.length - 1? "cell-border-top-right litros-col": "litros-col"}>
                                <div className="modificar-pedidos-table-content">{getLocaleStringNumber(comp.CANTIDAD_SOLICITADA)}</div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  }
                  { (!idPedido || tipoPedido.value !== 'emergencia') &&
                    <CamionMobile
                      camion={camion}
                      compartimientos={compartimientos}
                      materiales={materiales}
                      materialesNoEmpty={materialesNoEmpty}
                      setMaterial={setMaterial}
                    />
                  }
                  <div className="camion camion-mobile">
                    <div className={`container cont${camion.NUMERO_COMPARTIMIENTOS}`}>
                      <div className="observaciones">
                        <div className="observaciones__title">OBSERVACIONES</div>
                        <textarea
                          value={observaciones}
                          maxLength="500"
                          onChange={(e) => checkSetObservaciones(e.target.value)}
                        />
                        <div className="observaciones-cont">{observaciones.length}/500</div>
                      </div>
                    </div>
                    { pedidoOriginal && pedidoOriginal.DINAMO === 'X' &&
                      <EstadoCrediticio 
                        token={token}
                        pagador={pagador}
                        callServiceHandler={callServiceHandler}
                      />
                    }
                  </div>
                </>
              }
              {error && (
                <div className="error-pedidos">
                  <span>
                    <div className="icon" />
                  </span>
                  {error}
                </div>
              )}
              <div className="mobile-actions-container">
                <button className="main-button hallow" onClick={clearForm}>
                  {idPedido ? "Cancelar" : "Limpiar"}
                </button>
                <button
                  className="main-button"
                  disabled={!displayCamionForm}
                  onClick={validate}
                >
                  {idPedido ? "Modificar" : "Ingresar Pedido"}
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FormularioPedidos;
