import React, { useState, useEffect } from "react"
import Select from "react-select"

import ConfiguracionDinamo from "./ConfiguracionDinamo"
import Correos from "./Correos"
import CorreosPorZona from "./CorreosPorZona"
import PersonalEstacion from "./PersonalEstacion"
import EdsDinamo from "./EdsDinamo"

import {
  displayLoader,
  hideGreyBackground,
  hideLoader,
  parentRedirect,
  scrollTo,
  showGreyBackground
} from "../utils/windows"

const AdministracionDinamo = ({token, callServiceHandler, displayCustomError}) => {
  const [codigosZonas, setCodigosZonas] = useState()
  const [configuracionDinamo, setConfiguracionDinamo] = useState()
  const [correos, setCorreos] = useState()
  const [correosPorZonas, setCorreosPorZonas] = useState()
  const [personalEstacion, setPersonalEstacion] = useState()
  const [pagadores, setPagadores] = useState([])
  const [selector, setSelector] = useState("configuracion")
  const [newData, setNewData] = useState()

  useEffect(() => {
    if(token){
      getAdministracionDinamo()
      getListadoEstacionesDinamo()
    }
  }, [token])

  useEffect(() => {
    if(newData)
      saveChanges()
  }, [newData])

  async function saveChanges(){
    displayLoader()
    const url = process.env.REACT_APP_FLUCTUACIONES_API + '/fluctuaciones/put-administracion-dinamo'

    let response = await callServiceHandler(url, "PUT", JSON.stringify(newData))
    if(response !== "error"){
      displayCustomError("Exito", "Los cambios han sido guardados con éxito.", "success")
      setNewData()
      getAdministracionDinamo()
    }
    else{
      displayCustomError("Error", "Ha ocurrido un error, por favor intentalo de nuevo.", "error")
      hideLoader()
    }
  }

  async function getAdministracionDinamo(){
    displayLoader()
    const url = process.env.REACT_APP_FLUCTUACIONES_API + '/fluctuaciones/get-administracion-dinamo'
    let result = await callServiceHandler(url)
    if(result !== "error"){
      setCodigosZonas(result.data.codigos_de_zonas)
      setConfiguracionDinamo(result.data.configuracion_dinamo)
      setCorreos(result.data.correos)
      setCorreosPorZonas(result.data.correos_por_zona)
      setPersonalEstacion(result.data.personal_estacion)
    }
    hideLoader()
  }

  async function getListadoEstacionesDinamo(){
    displayLoader()
    const url = process.env.REACT_APP_FLUCTUACIONES_API + '/fluctuaciones/controles-diarios-dinamo-token?TIPO_REPORTE=LISTADO_ESTACIONES_ACTIVAS'
    let result = await callServiceHandler(url)

    if(result !== "error")
      setPagadores(result.data.pagador_estacion)

    hideLoader()
  }

  return (
    <div className="administracion-dinamo">
      <div className="view-title">Administracion</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Dinamo</div>
      <hr className="title-separator-line" />


      <div className="administracion-filters-section">
        <div className={`selector ${selector==='configuracion'? 'active': ''}`}>
          <div className="selector-text" onClick={() => setSelector("configuracion")}>
            Configuracion Dinamo
          </div>
        </div>
        <div className={`selector ${selector==='correos'? 'active': ''}`}>
          <div className="selector-text" onClick={() => setSelector("correos")}>
            Correos
          </div>
        </div>
        <div className={`selector ${selector==='correos_zonas'? 'active': ''}`}>
          <div className="selector-text" onClick={() => setSelector("correos_zonas")}>
            Correos Por Zona
          </div>
        </div>
        <div className={`selector ${selector==='personal'? 'active': ''}`}>
          <div className="selector-text" onClick={() => setSelector("personal")}>
            Personal Estacion
          </div>
        </div>
        <div className={`selector ${selector==='eds_dinamo'? 'active': ''}`}>
          <div className="selector-text" onClick={() => setSelector("eds_dinamo")}>
            Abrir/Reabrir EDS Dinamo
          </div>
        </div>
      </div>
      
      { selector === "configuracion" &&
        <ConfiguracionDinamo
          configuracionDinamo={configuracionDinamo}
          setNewData={setNewData}
        />
      }
      { selector === "correos" && correos &&
        <Correos 
          correos={correos}
          setNewData={setNewData}
        />
      }
      { selector === "correos_zonas" && correosPorZonas &&
        <CorreosPorZona 
          codigosZonas={codigosZonas}
          correos={correosPorZonas}
          setNewData={setNewData}
        />
      }
      { selector === "personal" && personalEstacion &&
        <PersonalEstacion 
          personal={personalEstacion}
          setNewData={setNewData}
        />
      }
      { selector === "eds_dinamo" && pagadores &&
        <EdsDinamo 
          token={token}
          callServiceHandler={callServiceHandler}
          pagadores={pagadores}
          displayCustomError={displayCustomError}
        />
      }
    </div>
  )
}

export default AdministracionDinamo
