import React, { useState, useEffect, useRef, useContext } from "react";
import Seguimiento1 from "../../assets/img/Seguimiento_1.svg";
import Seguimiento2 from "../../assets/img/Seguimiento_2.svg";
import Seguimiento3 from "../../assets/img/Seguimiento_3.svg";
import Seguimiento4 from "../../assets/img/Seguimiento_4.svg";
import Seguimiento5 from "../../assets/img/Seguimiento_5.svg";
import Seguimiento6 from "../../assets/img/Seguimiento_6.svg";
import HeaderData from "../HeaderData";
import {
  SingleOption,
  customStylesPedidos,
  parseDate,
  orderArray,
  parseBudat,
  getCantidadTotal,
  orderByHorario,
  getLitros
} from "../utils/utils";
import { getCantidades, getDate, setInstalalaciones, setPagadoresArcoprime } from "../utils/get";
import { displayLoader, hideLoader, parentRedirect, scrollTo, hideGreyBackground, showGreyBackground } from "../utils/windows";
import { allMateriales } from "../utils/materiales";
import { getUpdateDate } from "../utils/monthNames";
import { modalStyles } from "../utils/styles";
import PedidosActivosTable from "./PedidosActivosTable";
import Select from "react-select";
import RechazarPedido from "./RechazarPedido";
import { ViewPortContext } from "../../App";
import { MobileSelect } from "../MobileSelect";
import CustomModal from "../CustomModal";
import CreditoCard from "./CreditoCard";

const PedidosActivos = ({ rutConcesionario, token, pagador, cabecera, instalaciones, isROImpersonate, edsPortal, callServiceHandler }) => {
  const [allEds, setAllEds] = useState([]);
  const [updateDate, setUpdateDate] = useState();
  const [selectedEds, setSelectedEds] = useState();
  const [filterText, setFilterText] = useState("");
  const [displayRechazarPedido, setDisplayRechazarPedido] = useState(false);
  const [pedidosAux, setPedidosAux] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pedidos, setPedidos] = useState([]);
  const [selectedPedido, setSelectedPedido] = useState();
  const [disableModificar, setDisableModificar] = useState(true);
  const [disableRechazar, setDisableRechazar] = useState(true);
  const [disableSigueme, setDisableSigueme] = useState(true);
  const [openMobileFilter, setOpenMobileFilter] = useState(false);
  const isDesktopRef = useRef(window.innerWidth > process.env.REACT_APP_MOBILE_BREAK_POINT);
  const today = new Date();
  const parsedDate = parseDate(today);
  const [creadoCounter, setCreadoCounter] = useState(0);
  const [planificadoCounter, setPlanificadoCounter] = useState(0);
  const [inicioCounter, setInicioCounter] = useState(0);
  const [finCounter, setFinCounter] = useState(0);
  const [rutaCounter, setRutaCounter] = useState(0);
  const [entregadoCounter, setEntregadoCounter] = useState(0);
  const [cargaCounter, setCargaCounter] = useState(0);
  const [displayModal, setDisplayModal ] = useState(false);
  const [preprogramaPedido, setPreprogramaPedido] = useState("");
  const [preprogramaFecha, setPreprogramaFecha] = useState("");
  const [isDinamo, setIsDinamo] = useState(false);
  const [estadoCrediticio, setEstadoCrediticio] = useState({});

  const [isArcoprime, setIsArcoprime] = useState(false);
  const [allPagadores, setAllPagadores] = useState([]);
  const [selectedPagador, setSelectedPagador] = useState();

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (token && rutConcesionario) {
        getUpdateDate(setUpdateDate);
        if (rutConcesionario === "77215640-5") {
          setIsArcoprime(true);
        }
      }
    } else {
      if (token) {
        getUpdateDate(setUpdateDate);
        if (rutConcesionario === "77215640-5" /* || true */) {
          setIsArcoprime(true);
        }
      }
    }
  }, [token, rutConcesionario]);

  useEffect(() => {
    if (cabecera!=="" && pagador!==""){
      if(cabecera.DATOS_CLIENTE.DINAMO==="X"){
        setIsDinamo(true);
        getEstadoCrediticio();
      }
    }
  }, [cabecera,pagador]);

  useEffect(() => {
    if (instalaciones!==""){
      if(!isArcoprime){
        setInstalalaciones(instalaciones,setAllEds);
      }
    }
  }, [instalaciones]);

  useEffect(async () => {
    if(selectedPagador){
      const url = process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/obtener-instalaciones-cliente?id_pagador=" + selectedPagador.value;

      let result = await callServiceHandler(url, "GET")
      if(result !== "error"){
        setInstalalaciones(result.data, setAllEds);
      }

    }
  }, [selectedPagador]);

  useEffect(() => {
    if (allEds.length === 1) {
      changeEds(allEds[0]);
    }
  }, [allEds]);

  useEffect(() => {
    if (allPagadores.length === 1) {
      changePagador(allPagadores[0]);
    }
  }, [allPagadores]);

  useEffect(() => {
    checkModificar();
    checkRechazar();
    checkSigueme();
  }, [selectedPedido]);

  useEffect(async () => {
    if (selectedEds && pagador) {

      if (isDinamo){
        getEstadoCrediticio();
      }

      let result = await getPedidosActivos(selectedEds);
      const now = new Date();
      //result.data.PEDIDOS.map((pedido)=>console.log(Math.trunc((now-getDate(pedido.FECHA_PREFERENTE_ENTREGA))/(1000 * 3600 * 24))));
      const cleanArray=result.data.PEDIDOS.filter((pedido)=>(pedido.TPC!=="" || Math.trunc((now-getDate(pedido.FECHA_PREFERENTE_ENTREGA))/(1000 * 3600 * 24))<=15) && pedido.DESCRIPCION_STATUS!=="Recibí Conforme" );
      const orderedArray=orderArray("FECHA_PREFERENTE_ENTREGA",cleanArray,"asc",false);
      cleanActivosData(orderedArray);


      /* setPedidosAux(result.data.PEDIDOS.filter((pedido)=>pedido.TPC!=="")); */
      hideLoader();
    }
  }, [currentPage, selectedEds, pagador]);
  

  async function changeEds(e) {
    setSelectedEds(e);

    //Resetear búsqueda de pedidos activos
    setPedidosAux([]);
    setCurrentPage(0);
  }

  function changePagador(e) {
    setSelectedPagador(e);
  }

  async function getPedidosActivos(e) {
    displayLoader();
    setDisableModificar(true)
    setDisableRechazar(true)
    setDisableSigueme(true)
    setSelectedPedido()
    const pagadorFinal=isArcoprime?selectedPagador.value:pagador;
    const pedidosURL =
      process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/buscar-pedidos?id_eds=" +
      e.value +
      "&nuevo=1"+"&id_pagador="+pagadorFinal+"&punto_venta=1";

    let apiResult = await callServiceHandler(pedidosURL, "GET")

    if(apiResult !== "error"){
      getUpdateDate(setUpdateDate)
      return apiResult
    }
  }

  async function consultaPreprograma(pedido,fecha,eds) {
    const consultaURL =
      process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/consulta-preprograma?id_eds=" +
      eds +
      "&id_pedido="+pedido+"&fecha="+fecha;

    let apiResult = await callServiceHandler(consultaURL, "GET")
    if(apiResult !== "error"){
      return apiResult
    }
  }

  function cleanActivosData(pedidosdata) {
    const newPedidos = [];
    pedidosdata.forEach((pedido) => {
      const arr93 = [];
      const arr95 = [];
      const arr97 = [];
      const arrPd = [];
      const arrK = [];
      let existenReposiciones = false;

      if (pedido.DETALLE) {
        const detalle = pedido.DETALLE;

        detalle.forEach((item) => {
          if (allMateriales[item.ID_MATERIAL].class === "sp93") {
            arr93.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "sp95") {
            arr95.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "sp97") {
            arr97.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "pd") {
            arrPd.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "k") {
            arrK.push(item);
          }
          if (item.CATEGORIA_ITEM != "ZCRM" && existenReposiciones === false) {
            existenReposiciones = true;
          }
        });
      }

      const volumenInfo = {
        93: { litros: arr93.length > 0 ? getLitros(arr93, "ZCRM") / 1000 : 0 },
        95: { litros: arr95.length > 0 ? getLitros(arr95, "ZCRM") / 1000 : 0 },
        97: { litros: arr97.length > 0 ? getLitros(arr97, "ZCRM") / 1000 : 0 },
        Pd: { litros: arrPd.length > 0 ? getLitros(arrPd, "ZCRM") / 1000 : 0 },
        K: { litros: arrK.length > 0 ? getLitros(arrK, "ZCRM") / 1000 : 0 },
      };

      //console.log(volumenInfo["93"].litros +volumenInfo["95"].litros +volumenInfo["97"].litros +volumenInfo["Pd"].litros +volumenInfo["K"].litros)

      const newPedido = {
        ID_PEDIDO: pedido.ID_PEDIDO,
        PLANTA: pedido.NOMBRE_PLANTA,
        TIPO_PEDIDO: pedido.TIPO_PEDIDO,
        FECHA_PREFERENTE_ENTREGA: pedido.FECHA_PREFERENTE_ENTREGA,
        VENTANA: pedido.TIPO_PEDIDO==="Preprograma"?pedido.HORAPREP:pedido.HORARIO,
        ESTADO: pedido.DESCRIPCION_STATUS,
        VOLUMEN: volumenInfo["93"].litros +volumenInfo["95"].litros +volumenInfo["97"].litros +volumenInfo["Pd"].litros +volumenInfo["K"].litros +
            "m3 = (" +
            volumenInfo["93"].litros +
            "-" +
            volumenInfo["95"].litros +
            "-" +
            volumenInfo["97"].litros +
            "-" +
            volumenInfo["Pd"].litros +
            "-" +
            volumenInfo["K"].litros +
            ")",
        DETALLE: pedido.DETALLE,
        RESTANTE:
          pedido.HORAS_MOD,
        RECALCULO: existenReposiciones ? "Ver" : "-",
        CONTACTO:
          pedido.NOMBRE_SOLICITANTE +
          " " +
          pedido.APELLIDO_SOLICITANTE,
        DIRECCION: pedido.CALLE,
        DINAMO: pedido.DINAMO,
        TIPOS_CONSULTA: pedido.TPC,
        ESTADO_GTI: pedido.ESTADO_GTI,
        CONFIRMACION_MIX: pedido.CONF_MIX,
        CANTIDAD_TOTAL: isDinamo?getCantidades(pedido,"SOLICITADA"):getCantidadTotal(pedido),
        RESTANTE_PRE:pedido.HORAS_CMIX,
        VOLUMEN_SOLICITADO: getCantidades(pedido,"SOLICITADA"),
        VOLUMEN_PLANIFICADO: getCantidades(pedido,"CONFIRMADA"),
        VOLUMEN_ENTREGADO: getCantidades(pedido,"ENTREGADA"),
        ESTADO_DYNAMO:pedido.ESTADO_DYNAMO,
        CODIGO_STATUS:pedido.CODIGO_STATUS,
        PEDIDO_LOGISTIC: pedido.PEDIDO_LOGISTIC,
        FECHA_ESTIMADA_CARGA:pedido.FECHA_ESTIMADA_CARGA,
        HORA_ESTIMADA_CARGA:pedido.HORA_ESTIMADA_CARGA,
        FECHA_LLEGADA_PROGRAMADA:pedido.FECHA_LLEGADA_PROGRAMADA,
        HORA_LLEGADA_PROGRAMADA:pedido.HORA_LLEGADA_PROGRAMADA
      };
      
      newPedidos.push(newPedido);
    });

    const newPedidosOrderedByHorario = orderByHorario(newPedidos)

    setPedidos(newPedidosOrderedByHorario);

    let creadoCount = 0;
    let planificadoCount = 0;
    let inicioCount = 0;
    let finCount = 0;
    let rutaCount = 0;
    let entregadoCount = 0;
    let cargaCount = 0;

    newPedidos.forEach((pedido) => {

      if(!isDinamo){
        if (pedido.ESTADO_GTI === "Creado") {
          creadoCount++;
        }
        if (pedido.ESTADO_GTI === "Planificado") {
          planificadoCount++;
        }
        if (pedido.ESTADO_GTI === "Inicio de carga") {
          inicioCount++;
        }
        if (pedido.ESTADO_GTI === "Termino de carga") {
          finCount++;
        }
        if (pedido.ESTADO_GTI === "En ruta") {
          rutaCount++;
        }
        if (pedido.ESTADO_GTI === "Entregado") {
          entregadoCount++;
        }
      }else{
        if (pedido.CODIGO_STATUS.charAt(0)==="1") {
          creadoCount++;
        }
        if (pedido.CODIGO_STATUS.charAt(0)==="2") {
          planificadoCount++;
        }
        if (pedido.CODIGO_STATUS.charAt(0)==="3") {
          cargaCount++;
        }
        if (pedido.CODIGO_STATUS.charAt(0)==="4") {
          rutaCount++;
        }
        if (pedido.CODIGO_STATUS.charAt(0)==="5") {
          entregadoCount++;
        }
      }

    });

    setCreadoCounter(creadoCount);
    setPlanificadoCounter(planificadoCount);
    setInicioCounter(inicioCount);
    setFinCounter(finCount);
    setRutaCounter(rutaCount);
    setEntregadoCounter(entregadoCount);
    setCargaCounter(cargaCount);
  }

  async function getEstadoCrediticio() {
    displayLoader();

    let url =
      process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/estado-crediticio?id_pagador=" +
      pagador;

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      let estado = {
        CREDITO_DISPONIBLE:result.data.ESTADO_DEUDOR.GESTION_CREDITO.CREDITO_DISPONIBLE,
        ABONOS:result.data.ESTADO_DEUDOR.PAGOS_RECIBIDOS.ABONO_NO_COMPENSADO,
        TOTAL_CREDITO:result.data.ESTADO_DEUDOR.GESTION_CREDITO.LIMITE_CREDITO+result.data.ESTADO_DEUDOR.PAGOS_RECIBIDOS.ABONO_NO_COMPENSADO,
        LIMITE_CREDITO:result.data.ESTADO_DEUDOR.GESTION_CREDITO.LIMITE_CREDITO,
        PARTIDAS_VENCIDAS:result.data.ESTADO_DEUDOR.GESTION_CREDITO.PARTIDAS_ABIERTAS_VENC,
        PARTIDAS_ABIERTAS:result.data.ESTADO_DEUDOR.GESTION_CREDITO.PARTIDAS_ABIERTAS,
        PARTIDAS_EN_CURSO:result.data.ESTADO_DEUDOR.GESTION_CREDITO.PEDIDOS_VENTA_CURSO,
        STOCK_CONSIGNADO:result.data.ESTADO_DEUDOR.GESTION_CREDITO.VALOR_STOCK_CONSIGNAME,
        CHEQUES_RECHAZADOS:result.data.ESTADO_DEUDOR.PAGOS_RECIBIDOS.CHEQUE_DEVUELTO
      };
      estado.CREDITO_COMPROMETIDO=estado.PARTIDAS_ABIERTAS+estado.PARTIDAS_VENCIDAS+estado.PARTIDAS_EN_CURSO+estado.STOCK_CONSIGNADO;
      setEstadoCrediticio(estado);
    }
  }

  useEffect(() => {
    if(edsPortal){
      setPagadoresArcoprime(edsPortal.data.user_eds,setAllPagadores);
    }
  }, [edsPortal]);

  async function modificar() {
    if (selectedPedido) {
      const url =
        process.env.REACT_APP_FORMULARIO_PEDIDOS_PATH +
        "?pedido=" +
        selectedPedido.ID_PEDIDO +
        "&eds=" +
        selectedEds.value;

      if(selectedPedido.TIPO_PEDIDO==="Preprograma" && selectedPedido.CONFIRMACION_MIX==="No Confirmado"){
        displayLoader();
        const result = await consultaPreprograma(selectedPedido.ID_PEDIDO,parseBudat(selectedPedido.FECHA_PREFERENTE_ENTREGA),selectedEds.value);
        hideLoader();
  
        if(result.data.ESTADO===0){
          showGreyBackground();
          setDisplayModal(true);
          setPreprogramaPedido(result.data.ID_PEDIDO);
          setPreprogramaFecha(result.data.FECHA);
        }else{
          parentRedirect(url);
        }
      }else{
        parentRedirect(url);
      }
    }
  }

  function rechazar() {
    if (selectedPedido) {
      setDisplayRechazarPedido(true);
      scrollTo(0, 0);
    }
  }

  async function sigueme() {
    displayLoader();
    const url =
      process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/seguir-pedido?id_pedido=" +
      selectedPedido.ID_PEDIDO +
      "&fecha=" +
      parsedDate;

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      window.open(result.data.URL);
    }
  }

  function checkModificar() {
    if (
      selectedPedido &&
      selectedPedido.TIPOS_CONSULTA &&
      selectedPedido.TIPOS_CONSULTA.includes("M")
    ) {
      setDisableModificar(false);
      return;
    }

    setDisableModificar(true);
  }

  function checkRechazar() {
    if (
      selectedPedido &&
      selectedPedido.TIPOS_CONSULTA &&
      selectedPedido.TIPOS_CONSULTA.includes("R")
    ) {
      setDisableRechazar(false);
      return;
    }

    setDisableRechazar(true);
  }

  function checkSigueme() {
    if(selectedPedido){
      if(isDinamo){
        if(selectedPedido.ESTADO_GTI==="En ruta"||selectedPedido.ESTADO_GTI==="Entregado"||selectedPedido.ESTADO==="En Ruta"){
          setDisableSigueme(false);
          return;
        }
      }else{
        setDisableSigueme(false);
        return;
      }
    }
    setDisableSigueme(true);
  }

  function closeModalPreprograma() {
    setDisplayModal(false);
    hideGreyBackground();
  }

  function acceptModalPreprograma(){
    hideGreyBackground();
    setDisplayModal(false);
    const url =
        process.env.REACT_APP_FORMULARIO_PEDIDOS_PATH +
        "?pedido=" +
        selectedPedido.ID_PEDIDO +
        "&eds=" +
        selectedEds.value;
    parentRedirect(url);
  }

  function reloadActivos(){
    setCurrentPage((old)=>old+1);
  }

  return (
    <div className="pedidos-activos page">
      <div className="view-title">Pedidos Combustibles</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">{displayRechazarPedido?"Rechazar pedido Nº"+selectedPedido.ID_PEDIDO:"Pedidos activos"} </div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        cabecera={cabecera}
        actualizable={true}
        updateFunction={reloadActivos}
        callServiceHandler={callServiceHandler}
      />
      <hr />

      {displayRechazarPedido && (
        <RechazarPedido
          selectedPedido={selectedPedido}
          selectedEds={selectedEds}
          setDisplayRechazarPedido={setDisplayRechazarPedido}
          token={token}
          rutConcesionario={rutConcesionario}
          setCurrentPage={setCurrentPage}
          setPedidosAux={setPedidosAux}
          pagador={pagador}
          isROImpersonate={isROImpersonate}
          callServiceHandler={callServiceHandler}
        />
      )}
      {!isDesktopRef.current && !displayRechazarPedido && isArcoprime &&
        <MobileSelect
          options={allPagadores}
          selectItem={changePagador}
          label="ID DE PAGADOR"
          selectedItem={selectedPagador}
          onOpen={setOpenMobileFilter}
          scrollToTop
        />
      }

      {!isDesktopRef.current && !displayRechazarPedido &&
        <MobileSelect
          options={allEds}
          selectItem={changeEds}
          label="ESTACIÓN DE SERVICIO"
          selectedItem={selectedEds}
          onOpen={setOpenMobileFilter}
          scrollToTop
        />
      }

      {!displayRechazarPedido && (
        <>
        <div className={`estados-container ${isDinamo?"":"hidden"}`}>
        <div className={`icons ${isDinamo?"":""}`}>
            <div className="icon">
              <img src={Seguimiento1}></img>
              {creadoCounter>0?<><div className="badge">
                <p>{creadoCounter}</p>
              </div></>:<></>}
              <div className="description">Creado</div>
              <div className="mobile-description description">Creado</div>
            </div>

            <div className="line"></div>
            <div className="icon">
              <img src={Seguimiento2}></img>
              {planificadoCounter>0?<><div className="badge">
                <p>{planificadoCounter}</p>
              </div></>:<></>}
              <div className="description">Planificado</div>
              <div className="mobile-description description">Planif.</div>
            </div>
            <div className="line"></div>
            {!isDinamo && <>
              <div className="icon">
                <img src={Seguimiento3}></img>
                {inicioCounter>0?<><div className="badge">
                  <p>{inicioCounter}</p>
                </div></>:<></>}
                <div className="description">Inicio&nbsp;de&nbsp;Carga</div>
                <div className="mobile-description description">Inicio Carga</div>
              </div>
            <div className="line"></div>
            <div className="icon">
              <img src={Seguimiento4}></img>
              {finCounter>0?<><div className="badge">
                <p>{finCounter}</p>
              </div></>:<></>}
              <div className="description">Fin&nbsp;de&nbsp;Carga</div>
              <div className="mobile-description description">Fin Carga</div>
            </div>
            </>}
            {isDinamo && <>
              <div className="icon">
                <img src={Seguimiento3}></img>
                {cargaCounter>0?<><div className="badge">
                  <p>{cargaCounter}</p>
                </div></>:<></>}
                <div className="description">En&nbsp;Carga</div>
                <div className="mobile-description description">En&nbsp;Carga</div>
              </div>
            </>}
            <div className="line"></div>
            <div className="icon">
              <img src={Seguimiento5}></img>
              {rutaCounter>0?<><div className="badge">
                <p>{rutaCounter}</p>
              </div></>:<></>}
              <div className="description">En&nbsp;ruta</div>
              <div className="mobile-description description">En ruta</div>
            </div>
            <div className="line"></div>
            <div className="icon">
              <img src={Seguimiento6}></img>
              {entregadoCounter>0?<><div className="badge">
                <p>{entregadoCounter}</p>
              </div></>:<></>}
              <div className="description">{isDinamo?"Finalizado":"Entregado"}</div>
              <div className="mobile-description description">{isDinamo?"Finalizado":"Entregado"}</div>
            </div>
          </div>
          {isDinamo && <CreditoCard estadoCrediticio={estadoCrediticio}></CreditoCard>}
        </div>

          <hr className={isDinamo? '': 'hidden'}/>
          <div className={`pedidosa-options ${isDinamo?"dinamo":""}`}>
            <div className="filters-container">
              {isArcoprime && <div className="filter">
                <div className="filter-title">ID DE PAGADOR<span className="red-asterisk">*</span></div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedPagador}
                    onChange={(e) => changePagador(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allPagadores}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesPedidos}
                  />
                </div>
              </div>}
              <div className={`filter ${isArcoprime && "mleft"}`}>
                <div className="filter-title">ESTACIÓN DE SERVICIO<span className="red-asterisk">*</span></div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedEds}
                    onChange={(e) => changeEds(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allEds}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesPedidos}
                  />
                </div>
              </div>
            </div>

            <div className="actions">
              <button
                onClick={modificar}
                className="actions-buttons"
                disabled={disableModificar}
              >
                Modificar
              </button>
              <button
                onClick={rechazar}
                className="actions-buttons"
                disabled={disableRechazar}
              >
                Rechazar
              </button>
              <button
                onClick={sigueme}
                className="actions-buttons"
                disabled={disableSigueme}
              >
                Sígueme COPEC
              </button>
            </div>

            <div className="important-message">
                <span className="title">Importante:&nbsp;</span>
                <span>
                El estado del tiempo restante de modificar puede tardar unos segundos en actualizarse.
                </span>
              </div>
          </div>
          {!openMobileFilter && (
            <PedidosActivosTable
              pedidos={pedidos}
              setPedidos={setPedidos}
              selectedPedido={selectedPedido}
              setSelectedPedido={setSelectedPedido}
              showMobile={!isDesktopRef.current}
              isDinamo={isDinamo}
            />
          )}

          {!isDesktopRef.current && (
            <div className="mobile-actions">
              <button
                onClick={modificar}
                className="main-button"
                disabled={disableModificar}
              >
                Modificar
              </button>
              <button
                onClick={rechazar}
                className="main-button"
                disabled={disableRechazar}
              >
                Rechazar
              </button>
              <button
                onClick={sigueme}
                className="main-button"
                disabled={disableSigueme}
              >
                Sígueme COPEC
              </button>
            </div>
          )}
        </>
      )}

      <CustomModal
        displayModal={displayModal}
        closeModal={closeModalPreprograma}
        acceptFunc={acceptModalPreprograma}
        acceptText='Ok'
        cancelFunc={closeModalPreprograma}
        modalTitle='Confirmación'
        modalText={`El pedido número ${preprogramaPedido} con fecha ${parseBudat(preprogramaFecha)} se encuentra pendiente de ser confirmado. ¿Desea continuar?`}
        modalIcon='error'
      />
    </div>
  );
};

export default PedidosActivos;
