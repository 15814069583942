import React from "react";
import clear from "../assets/img/cross-icon.svg";

export const InputWithClearBtn = ({
  placeholder = " ",
  value = "",
  onChange,
  onlyText
}) => {

  const empty={
    target: {
      value: ''
    }
  }

  return (<>
    <div className={`input-with-clear-btn ${onlyText?"only-text":""}`}>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={onlyText?true:false}
      />
      {!!value && !onlyText && (
        <button className="button" onClick={() => onChange(empty)}>
          <img src={clear} alt="clear" />
        </button>
      )}
    </div>
  </>);
};
