import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { hideGreyBackground, showGreyBackground } from "./utils/windows";

const styles = {
  content: {
    width: "100%",
    padding: "24px 16px 8px",
    margin: "0 auto",
    boxSizing: "border-box",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    position: "fixed",
    left: "0%",
    top: "auto",
    right: "0%",
    overflow: "visible",
  },
};

export const MobileActionsModal = ({
  viewPortBottomCoordinate,
  displayMobileActionsBar,
  children,
}) => {
  const [showModal, setShowModal] = useState(false);

  const closeModal = () => setShowModal(false);
  const openModal = () => setShowModal(true);
  styles.content.bottom = viewPortBottomCoordinate;

  useEffect(() => {
    if (showModal) showGreyBackground();
    else hideGreyBackground();
    return () => {
      hideGreyBackground();
    };
  }, [showModal]);
  return (
    <div
      className="mobile-actions"
      style={{ bottom: viewPortBottomCoordinate }}
    >
      <button className="main-button" onClick={openModal}>
        Opciones de pago
      </button>
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        style={styles}
        portalClassName="mobile-action-modal"
      >
        {children}
        <button className="main-button hallow" onClick={closeModal}>
          Volver
        </button>
      </Modal>
    </div>
  );
};
