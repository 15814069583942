import React, { useState } from "react"
import SelectedTransactions from "./StepperComponents/SelectedTransactions"
import Comprobante from "./StepperComponents/Comprobante"
import { Stepper } from "./StepperComponents/Stepper"

const Compensar = ({ 
    selectedTransactions, 
    totalSaldo, 
    mandatosActivos, 
    rutConcesionario, 
    token, 
    clientName, 
    clientAddress, 
    setDisplayChequeEnLinea, 
    closeStepper,
    totalAPagar,
    isArcoprime,
    selectedEdsArcoprime,
    setSelectedTransactions,
    setDisplayCompensacion,
    isROImpersonate,
    callServiceHandler,
    displayCustomError
  }) => {

  const [ step, setStep ] = useState(1)
  const [ mandato, setMandato ] = useState()
  const [ propuesta, setPropuesta ] = useState()
  const [ error, setError ] = useState(false)
  const [ fechaPago, setFechaPago ] = useState('');

  return (
    <div className="abonar-en-banco">
      <div className="abonar-en-banco__content">
        <Stepper step={ step } error={ error } totalSteps='2' title='Compensar' step1Title="Compensar" step2Title="Finalizar" />
        {
          step === 1 && 
          <SelectedTransactions 
            setStep = { setStep }
            selectedTransactions = { selectedTransactions }
            totalSaldo = { totalSaldo }
            isCompensar = { true }
            rutConcesionario = { rutConcesionario }
            setPropuesta = { setPropuesta }
            token = { token }
            setError = { setError }
            closeStepper = { closeStepper }
            setFechaPago= {setFechaPago}
            isArcoprime={isArcoprime}
            selectedEdsArcoprime={selectedEdsArcoprime}
            setDisplayCompensacion={setDisplayCompensacion}
            totalAPagar={totalAPagar}
            callServiceHandler={callServiceHandler}
            displayCustomError={displayCustomError}
          />
        }
        {
          step === 2 &&
          <Comprobante 
            error = { error }
            selectedTransactions = { selectedTransactions }
            rutConcesionario = { rutConcesionario }
            totalSaldo = { totalSaldo }
            banco = "No Aplica"
            clientName = { clientName }
            clientAddress = { clientAddress }
            propuesta = { propuesta }
            closeStepper = { closeStepper }
            title = "Comprobante de Compensación"
            totalAPagar={totalAPagar}
            fechaPago={fechaPago}
            setSelectedTransactions={setSelectedTransactions}
            isROImpersonate={isROImpersonate}
          />
        }
      </div>
    </div>
  );
};

export default Compensar