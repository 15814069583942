export const allPreprogramas = [
  {
    camion: "15LES",
    max: 15000
  },
  {
    camion: "32LDS",
    max: 32000
  },
  {
    camion: "30LCS",
    max: 30000
  },
  {
    camion: "24LAS",
    max: 24000
  },
  {
    camion: "38LBS3",
    max: 37000
  },
  {
    camion: "33LDS2",
    max: 33000
  },
];