import React, { useState, useEffect }from "react";
import HeaderData from "../HeaderData";
import Select from "react-select";
import { SingleOption, customStyles } from "../utils/utils.js";
import { getUpdateDate } from "../utils/monthNames";
import { getEdsPedidosActivos } from "../utils/get";
import { ConfiguracionesViews } from "./ConfiguracionesViews"

export const ConfiguracionTanquesConcesionario = ({token, rutConcesionario, callServiceHandler}) => {
  const [updateDate, setUpdateDate] = useState('')
  const [allEds, setAllEds] = useState([])
  const [selectedEds, setSelectedEds] = useState()
  const [configuraciones, setConfiguraciones] = useState([])

  useEffect(() => {
    getUpdateDate(setUpdateDate)
  }, []);

  useEffect(() => {
    if(allEds.length === 1)
      setSelectedEds(allEds[0])
  }, [allEds])

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (token && rutConcesionario) {
        getEdsPedidosActivos(callServiceHandler, rutConcesionario, setAllEds);
      }
    } else {
      if (token) {
        getEdsPedidosActivos(callServiceHandler, rutConcesionario, setAllEds);
      }
    }
  }, [token, rutConcesionario]);

  useEffect(() => {
    if(selectedEds){
      getHistorial()
    }
  }, [selectedEds]);

  async function getHistorial(){
    const url =
      process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/historial-configuraciones?ID_EDS=" + selectedEds.value

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){ setConfiguraciones(result.data) }
  }

  return (
    <div className="page">
      <div className="view-title">Fluctuaciones</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Configuración tanques</div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandler={callServiceHandler}
      />
      <hr />
      <ConfiguracionesViews 
        allEds={allEds}
        selectedEds={selectedEds}
        setSelectedEds={setSelectedEds}
        isTelesoporte={false}
        configuraciones={configuraciones}
      />
    </div>
  );
};
