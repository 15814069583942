import React, { useState, useEffect, useRef } from "react";
import { getAlertIconVentas, openBase64NewTab } from "../utils/get";
import EditarIcon from "../../assets/img/EDITAR.svg";
import { getLocaleStringRoundedInt } from "../utils/utils";
import fileIcon from "../../assets/img/file.svg";
import pdfIcon from "../../assets/img/file-icon-pdf.svg";
import { hideGreyBackground } from "../utils/windows";
import CustomModal from "../CustomModal";

const JustificacionForm = (props) => {

  const { info, selectedTable, setJustificacionOpen, selectedAction, selectedEds, selectedPeriodo, pagador, callServiceHandler, getCierreMensual,estadoCierre } = props
  const [observacion, setObservacion] = useState("")
  const [uploadedFile, setUploadedFile] = useState();
  const [base64File, setBase64File] = useState();
  const [displaySuccessModal, setDisplaySuccessModal] = useState(false)
  const [fileExist, setFileExist] = useState(false);
  const [encodedFile, setEncodedFile] = useState();
  const [fileName, setFileName] = useState();

  useEffect(() => {
    if (selectedAction==="ver") {
      if (selectedAction==="VENTAS") {
        getJustificacion(info.ID_JUSTIFICACION)
      }else{
        getJustificacion(info.id_justificacion)
      }
    }
  }, []);
  
  useEffect(() => {
    if (uploadedFile){
      fileToBase64(uploadedFile, (err, result) => {
        if (result) {
          setBase64File(result)
        }
      })
    }
    else{
      setBase64File()
    }
  }, [uploadedFile]);

  useEffect(() => {
    if (uploadedFile) {
      setFileName(uploadedFile.name)
    }
  }, [uploadedFile]);

  useEffect(() => {
    console.log("nombre archivoo",fileName)
  }, [fileName]);

  const getJustificacion = async (id) => {
    const url = process.env.REACT_APP_FLUCTUACIONES_API + `/fluctuaciones/cierre-mensual-leer-justificacion?ID_EDS=${selectedEds}&ID_PERIODO=${selectedPeriodo}&ID_PAGADOR=${pagador}&ID_JUSTIFICACION=${id}`;
    let response = await callServiceHandler(url, "GET");
    if(response!=="error"){
      console.log("Se ha obtenido")
      setObservacion(response.data.observacion)
      if(response.data.adjunto && response.data.adjunto.length >0){
        setFileExist(true)
        setFileName(response.data.nombre_archivo)
        setEncodedFile(response.data.adjunto)
        setBase64File(`data:application/pdf;base64,${response.data.adjunto}`)
      }
      // openBase64NewTab(response.data.adjunto, "application/pdf")
      // setBase64File(response.data.adjunto)
    }
  }

  const hiddenFileInput = useRef(null);

  const handleUploadFile = (event) => {
    hiddenFileInput.current.click();
  };

  const changeObservacion = (e) => {
    if(observacion.length<251){
      setObservacion(e.target.value);
    }
  }

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(null, reader.result)
    }
    reader.onerror = function (error) {
      cb(error, null)
    }
  }


  const enviarJustificacion = async () => {
    closeSuccessModal()

    const url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/cierre-mensual-ingresar-justificacion"

    const data = {
      ID_EDS: selectedEds,
      PAGADOR:pagador,
      PERIODO: selectedPeriodo,
      INFO_JUSTIFICACION: info,
      OBSERVACIONES: observacion,
      ARCHIVO: base64File,
      NOMBRE_ARCHIVO: fileName
    }

    console.log("data", data)


    
    let result = await callServiceHandler(url, "POST", JSON.stringify(data));
    
    if (result !== "error") {
      console.log("Se envio la justificacion")
      setJustificacionOpen(false)
      getCierreMensual()
    }
  }

  const closeSuccessModal = () => {
    setDisplaySuccessModal(false)
  }

  const handleDeleteDocument = () => {
    setUploadedFile()
    setBase64File()
    setFileName()
    setFileExist(false)
  }

  useEffect(() => {
    console.log(base64File)
    console.log(uploadedFile)
  }, [base64File, uploadedFile]);

  useEffect(() => {
    console.log(selectedAction)
  }, [selectedAction]);
  useEffect(() => {
    console.log(estadoCierre)
  }, [estadoCierre]);

  return (
    <div>
      <div className="table-title">{selectedTable}</div>
      {
        selectedTable === "VENTAS" ?
          <table className="table-with-material-title">
            <div className="table-header">
              <div className="legend">
                <div className="material">
                  <div className="rect"></div>
                  <div className="text">Material Consignado</div>
                </div>
                <div className="material">
                  <div className="rect concesionado"></div>
                  <div className="text">Material Concesionado</div>
                </div>
              </div>
            </div>
            <thead>
              <tr>
                <th className="no-padding-left">
                  <div className="th-data width-116">
                    <div className="th-text">Material</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-110">
                    <div className="th-text">Litros<br />Controlador</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-110">
                    <div className="th-text">Litros<br />Documentados</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-110">
                    <div className="th-text">Litros<br />Confirmados</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-110">
                    <div className="th-text">(Lts) Docum.<br />- Controlador</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-110">
                    <div className="th-text">(Lts) Confirm.<br />- Document.</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-110">
                    <div className="th-text">($)<br />Controlador</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-110">
                    <div className="th-text">($)<br />Documentados</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-110">
                    <div className="th-text">($)<br />Confirmados</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-110">
                    <div className="th-text">($) Docum.<br />- Controlador</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-110">
                    <div className="th-text">($) Confirm.<br />- Document.</div>
                  </div>
                </th>
              </tr>
            </thead>
            <tr className="separator-table-row">
              <th colSpan="100%" />
            </tr>
            <tbody>
              {
                info &&
                <tr>
                  <td className="def-tanque-container">
                    <div className={`type ${info.FORMA_COMERCIALIZACION}`}></div>
                    {info.MATERIAL === "KEROSENE" ? "KERO" : info.MATERIAL}
                  </td>
                  <td>{getLocaleStringRoundedInt(info.LITROS_CONTROLADOR)}</td>
                  <td>{getLocaleStringRoundedInt(info.LITROS_DOCUMENTADOS)}</td>
                  <td>{getLocaleStringRoundedInt(info.LITROS_CONFIRMADOS)}</td>
                  <td>{getLocaleStringRoundedInt(info.LITROS_DOCS_MENOS_CTRL)}</td>
                  <td>{getLocaleStringRoundedInt(info.LITROS_CONF_MENOS_DOCS)}</td>
                  <td>$ {getLocaleStringRoundedInt(info.VALOR_CONTROLADOR)}</td>
                  <td>$ {getLocaleStringRoundedInt(info.VALOR_DOCUMENTADOS)}</td>
                  <td>$ {getLocaleStringRoundedInt(info.VALOR_CONFIRMADOS)}</td>
                  <td>$ {getLocaleStringRoundedInt(info.VALOR_DOCS_MENOS_CTRL)}</td>
                  <td>$ {getLocaleStringRoundedInt(info.VALOR_CONF_MENOS_DOCS)}</td>
                </tr>
              }
            </tbody>
          </table>
        :
          <table className="table-with-material-title fluctuaciones justificacion-fluctuaciones">
            <div className="table-header">
              <div className="legend">
                <div className="material">
                  <div className="rect"></div>
                  <div className="text">Material Consignado</div>
                </div>
                <div className="material">
                  <div className="rect concesionado"></div>
                  <div className="text">Material Concesionado</div>
                </div>
              </div>
            </div>
            <thead>
              <tr>
                <th className="no-padding-left" colSpan={2}>
                  <div className="th-data width-80">
                    <div className="th-text">Material</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-100">
                    <div className="th-text">Med. Física<br />Inicial</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-100">
                    <div className="th-text">(Lts)<br />Entradas</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-100">
                    <div className="th-text">(Lts)<br />Salidas</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-100">
                    <div className="th-text">(Lts)<br />Fluct. Doc</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-100">
                    <div className="th-text">(Lts)<br />Inv. Teórico</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-100">
                    <div className="th-text">Med. Física<br />Proyectada</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-100">
                    <div className="th-text">(Lts) Fluct.<br />Calculada</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-100">
                    <div className="th-text">(%) Fluct.<br />Calculada</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-100">
                    <div className="th-text">Fluct.<br />Confirmada</div>
                  </div>
                </th>
                <th>
                  <div className="th-data two-lines width-100">
                    <div className="th-text">(%) Fluct.<br />Confirmada</div>
                  </div>
                </th>
              </tr>
            </thead>
            <tr className="separator-table-row">
              <th colSpan="100%" />
            </tr>
            <tbody>
              {
                info &&
                <tr>
                  <td className="def-tanque-container" rowSpan="1">
                    <div className={`type nomarginright ${info.form_com.toLowerCase()} tanques1`}></div>
                  </td>
                  <td className="def-tanque-container" >
                    <div className="def-tanque">
                      <div className="top-row">{info.tanque}</div>
                      <div className="bottom-row">{info.material==="KEROSENE"?"KERO":info.material}</div>
                    </div>              
                  </td>
                  <td>{getLocaleStringRoundedInt(info.medicion_inicial)}</td>
                  <td>{getLocaleStringRoundedInt(info.entradas)}</td>
                  <td>{getLocaleStringRoundedInt(info.salidas)}</td>
                  <td>{getLocaleStringRoundedInt(info.fluctuacion_documentada)}</td>
                  <td>{getLocaleStringRoundedInt(info.inventario_teorico)}</td>
                  <td>{getLocaleStringRoundedInt(info.medicion_fisica_proyectada)}</td>
                  <td>{getLocaleStringRoundedInt(info.fluctuacion_calculada_lts)}</td>
                  <td>{info.fluctuacion_calculada_porcentaje.toString().replace(".", ',')}</td>
                  <td>{getLocaleStringRoundedInt(info.fluctuacion_confirmada_lts)}</td>
                  <td>{info.fluctuacion_confirmada_porcentaje.toString().replace(".", ',')}</td>
                </tr>
              }
            </tbody>
          </table>
      }
      <div className="important-message error">
        <span className="error-icon">!</span>
        <span className="text">Los valores excedieron la tolerancia permitida, favor ingresar justificación.</span>
      </div>
      <div className="cierre-mensual-bottom cierre-mensual-bottom-justificacion">
        <div className="inputs-content">
          <div className="info">
            <textarea className="cierre-mensual-justificacion-text" disabled={estadoCierre==="EN REVISIÓN" && selectedAction==="ver"} value={observacion} onChange={(e) => changeObservacion(e)} rows="4" maxLength="250" />
            <p className="counter">{observacion.length} /250</p>
          </div>

          {!uploadedFile && !fileExist &&
          <button className="main-button hallow main-button-cierre" onClick={handleUploadFile}>
            <img src={fileIcon} />
            Adjuntar archivo PDF
          </button>
          }

          {uploadedFile &&
            <div className="uploaded-file-container">
              <div className="left-side">
                <img src={pdfIcon} />
              </div>
              <div className="right-side">
                <div className="file-name">{uploadedFile.name}</div>
                <div className="file-type">Archivo PDF</div>
              </div>
                <div className="solicitud-regularizacion-cross-icon" onClick={() => handleDeleteDocument()}></div>
            </div>
          }

          <input
          className="hidden-file-input"
          type="file"
          ref={hiddenFileInput}
          accept="application/pdf"
          onChange={(e) => {
            setUploadedFile(e.target.files[0]);
          }}
          onClick={(event)=> { 
            event.target.value = null
          }}
          />

          { fileExist &&
            <div className="uploaded-file-container justification-file-container">
              <div onClick={()=>openBase64NewTab(encodedFile, "application/pdf")}>
                <div className="left-side">
                  <img src={pdfIcon} />
                </div>
                <div className="right-side">
                  <div className="file-name">{fileName}</div>
                  <div className="file-type">Archivo PDF</div>
                </div>
              </div>
              {
                (selectedAction==="editar"||estadoCierre!=="EN REVISIÓN")&&
                <div className="solicitud-regularizacion-cross-icon" onClick={() => handleDeleteDocument()}></div>
              }
            </div>
          }

        </div>
        <hr />
        {/* <button className="main-button hallow cierre-mensual-justificacion-cancelar" onClick={()=>setJustificacionOpen(false)}> */}
        <button className={(estadoCierre!=="EN REVISIÓN" || (estadoCierre === "EN REVISIÓN" && selectedAction==="editar"))?"main-button hallow cierre-mensual-justificacion-cancelar":"main-button hallow cierre-mensual-justificacion-volver"} onClick={()=>setJustificacionOpen(false)}>
          {
            selectedAction === "ver" ? "Volver": "Cancelar"
          }
        </button>
        
        {
          (selectedAction==="editar"||estadoCierre!=="EN REVISIÓN")&&
          <button className="red-button" disabled={info?.material !== "concesionado"?(observacion.length>0?false:true):false} onClick={()=>setDisplaySuccessModal(true)}>Guardar Justificación</button>
        }
        
      </div>

      <CustomModal
        displayModal = { displaySuccessModal }
        closeModal = { closeSuccessModal }
        acceptFunc = { enviarJustificacion }
        cancelFunc={closeSuccessModal}
        acceptText= "Sí, estoy seguro"
        modalTitle = "Importante"
        modalText = "Este procedimiento contempla el envío de justificación por exceder la tolerancia permitida ¿Está seguro(a) que desea enviar esta justificación?"
        modalIcon = "importante"
      />
    </div>
  )
};

export default JustificacionForm;
