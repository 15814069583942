import React, { useState, useEffect } from "react"
import Select, { components } from 'react-select'
import NumericSelect from './NumericSelect'
import CustomHighlighter from './CustomHighlighter'
import DatePicker, { registerLocale } from 'react-datepicker'
import Filtro from '../assets/img/filtro.svg'
import FiltroActivo from '../assets/img/filtro-activo.svg'
import Flecha_down from "../assets/img/Flecha_down.svg"
import Flecha_up from "../assets/img/Flecha_up.svg"
import es from "date-fns/locale/es"
import {
  Option,
  customStyles, 
  parseBudat, 
  customFilter,
  orderArray,
  getLocaleStringNumber,
  checkSelectedOptions
} from './utils/utils.js'

registerLocale("es", es)

const HistorialPagosTable = (props) => {
  
  const [ montoVigente, setMontoVigente ] = useState('')
  const [ montoVencido, setMontoVencido ] = useState('')
  const [ abonosNoCompensados, setAbonosNoCompensados ] = useState('')
  const [ saldoCuenta, setSaldoCuenta ] = useState('')
  const [ saldoDocumentado, setSaldoDocumentado ] = useState('')
  const [ selectedMontoVigente, setSelectedMontoVigente ] = useState('Todos')
  const [ selectedMontoVencido, setSelectedMontoVencido ] = useState('Todos')
  const [ selectedAbonosNoCompensados, setSelectedAbonosNoCompensados ] = useState('Todos')
  const [ selectedSaldoCuenta, setSelectedSaldoCuenta ] = useState('Todos')
  const [ selectedSaldoDocumentado, setSelectedSaldoDocumentado ] = useState('Todos')

  const [ daysInMonth, setDaysInMonth ] = useState()
  
  const allProducts = props.products
  const totals = props.totals
  const filterText = props.filterText
  const selectedYear = props.selectedYear
  const selectedMonth = props.selectedMonth
  const filteredProducts = props.filteredProducts;
  const setFilteredProducts = props.setFilteredProducts;
  const productsLoaded = props.productsLoaded;
  const tableRef = props.tableRef;

  const startDate = props.startDate;
  const setStartDate = props.setStartDate;
  const endDate = props.endDate;
  const setEndDate = props.setEndDate;
  const selectedTipoOperacion = props.selectedTipoOperacion;
  const setSelectedTipoOperacion = props.setSelectedTipoOperacion;
  const selectedBanco = props.selectedBanco;
  const setSelectedBanco = props.setSelectedBanco;
  const propuestaPago = props.propuestaPago;
  const setPropuestaPago = props.setPropuestaPago;
  const selectedPropuestaPago  = props.selectedPropuestaPago;
  const setSelectedPropuestaPago  = props.setSelectedPropuestaPago;
  const monto = props.monto;
  const setMonto = props.setMonto;
  const selectedMonto = props.selectedMonto;
  const setSelectedMonto = props.setSelectedMonto;
  const selectedEstado = props.selectedEstado;
  const setSelectedEstado = props.setSelectedEstado;

  useEffect(() => {
    setFilteredProducts(props.products)
  }, [props.products])

  useEffect(() => {
    if(props.selectedMonth && props.selectedYear){
      let year = props.selectedYear
      let month = props.selectedMonth

      let dt = new Date(year, month - 1, 1)
      month = dt.getMonth() + 1
      year = dt.getFullYear()

      setDaysInMonth(new Date(year, month, 0).getDate())
      setStartDate(new Date(year, month - 1, 1))
    }
  }, [props.selectedMonth, props.selectedYear])

  useEffect(() => {
    if(selectedTipoOperacion){
      filterr()
    }
  }, [
        selectedTipoOperacion,
        selectedBanco,
        selectedEstado,
        monto,
        propuestaPago,
        endDate,
        filterText
    ])

  function filterr(){
    let productsArr = []

    if(!props.isLiberarPagos){
      checkSelectedOptions(selectedTipoOperacion, setSelectedTipoOperacion, props.tipoOperacionFilter)
      checkSelectedOptions(selectedBanco, setSelectedBanco, props.bancosFilter)
      checkSelectedOptions(selectedEstado, setSelectedEstado, props.estadoFilter)
    }

    allProducts.forEach(function(mov){
      let found = true

      if(selectedTipoOperacion.length > 0){
        if(!selectedTipoOperacion.some(e => e.value === mov.TIPO))
          found = false
      }
      if(selectedBanco.length > 0){
        if(!selectedBanco.some(e => e.value === mov.BANCO))
          found = false
      }

      if(selectedEstado.length > 0){
        if(!selectedEstado.some(e => e.value === mov.ESTADO))
          found = false
      }

      if(endDate){
        const dateArr = mov.FECHA.split('-')
        const date = new Date(dateArr[0],dateArr[1] - 1,dateArr[2], 0, 0, 0, 0)

        const start = new Date(startDate).setHours(0, 0, 0, 0)
        const end = new Date(endDate).setHours(23, 59, 59, 59)

        if(date < start || date > end)
          found = false
      }

      if(found)
        productsArr.push(mov)
    })

    if(selectedPropuestaPago !== 'Todos' && propuestaPago !== '')
      productsArr = customFilter(productsArr, propuestaPago, selectedPropuestaPago, 'GRUPO_PPTA')

    if(selectedMonto !== 'Todos' && monto !== '')
      productsArr = customFilter(productsArr, monto, selectedMonto, 'MONTO')

    if (filterText !== '') {
      let filterTextLower = filterText.toLowerCase()
      filterTextLower = filterTextLower.replaceAll('.', '')
      productsArr = productsArr.filter((prod) => {
        return (
          parseBudat(prod.FECHA).toLowerCase().includes(filterTextLower) ||
          prod.TIPO.toString().toLowerCase().includes(filterTextLower) ||
          prod.BANCO.toString().toLowerCase().includes(filterTextLower) ||
          prod.GRUPO_PPTA.toString().toLowerCase().includes(filterTextLower) ||
          prod.MONTO.toString().toLowerCase().includes(filterTextLower)
        );
      });
    }

    setFilteredProducts(productsArr)
  }

  const onDateChange = dates => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const orderTable = (filterkey,order,parse) => {
    const arr=orderArray(filterkey,allProducts,order,parse);
    props.setProducts(arr);
  }

  return (
    <div className={`resumen-table resumenfix${props.isLiberarPagos?" liberar-table":""}`} ref={tableRef}>
      <table>
      <thead>
      	<tr>
      		<th><div className="th-data">Fecha Emisión <div className="arrows"><img onClick={()=>orderTable('FECHA','asc',false)} src={Flecha_up}></img><img onClick={()=>orderTable('FECHA','desc',false)}  src={Flecha_down}></img></div></div></th>
      		<th><div className="th-data">Tipo Operación <div className="arrows"><img onClick={()=>orderTable('TIPO','asc',false)} src={Flecha_up}></img><img onClick={()=>orderTable('TIPO','desc',false)}  src={Flecha_down}></img></div></div></th>
      		<th><div className="th-data">Banco <div className="arrows"><img onClick={()=>orderTable('BANCO','asc',false)} src={Flecha_up}></img><img onClick={()=>orderTable('BANCO','desc',false)}  src={Flecha_down}></img></div></div></th>
      		<th><div className="th-data">Nº Propuesta de Pago <div className="arrows"><img onClick={()=>orderTable('GRUPO_PPTA','asc',false)} src={Flecha_up}></img><img onClick={()=>orderTable('GRUPO_PPTA','desc',false)}  src={Flecha_down}></img></div></div></th>
      		<th><div className="th-data">Monto <div className="arrows"><img onClick={()=>orderTable('MONTO','asc',true)} src={Flecha_up}></img><img onClick={()=>orderTable('MONTO','desc',true)}  src={Flecha_down}></img></div></div></th>
          <th><div className="th-data">Estado</div></th>
          { props.isLiberarPagos &&
            <th><div className="th-data selecc-col">Selecc.</div></th>
          }
      	</tr>
        { !props.isLiberarPagos &&
          <>
            <tr className="table-separator-row-border-bottom"><td /></tr>
          	<tr className="filters-row">
          		<td>
                <div className="filter-content no-padding-left">
                  <label onClick={e => e.preventDefault()}>
                    { daysInMonth &&
                      <DatePicker
                        locale="es"
                        className={`pagos ${endDate ? " active" : ""}`}
                        selected={endDate}
                        onChange={onDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        shouldCloseOnSelect={false}
                        minDate={new Date(selectedYear, selectedMonth - 1, 1)}
                        maxDate={new Date(selectedYear, selectedMonth - 1, daysInMonth)}
                        showDisabledMonthNavigation
                        dateFormat="dd/MM/yyyy"
                        selectsRange
                        placeholderText="Doble click para ver un día"
                      />
                    }
                    { !daysInMonth &&
                      <DatePicker
                        locale="es"
                        className={`pagos ${endDate ? " active" : ""}`}
                        selected={endDate}
                        onChange={onDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        shouldCloseOnSelect={false}
                        dateFormat="dd/MM/yyyy"
                        selectsRange
                        placeholderText="Doble click para ver un día"
                      />
                    }
                  </label>
                </div>
              </td>
              <td>
                <div className="filter-content">
                  <Select
                    className={`table-select pagos${selectedTipoOperacion.length > 0 ? " active" : ""}`}
                    value={selectedTipoOperacion}
                    onChange={(e) => setSelectedTipoOperacion(e)}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    options={props.tipoOperacionFilter}
                    autocomplete="off"
                    isSearchable={false}
                    components={{ Option, DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    isClearable={false}
                    isMulti
                    placeholder="Todos"
                    styles={ customStyles }
                    width='207px'
                    border={`${selectedTipoOperacion.length > 0? "solid 1px #6cbfff" : "none"}`}
                  />
                 
                </div>
              </td>
              <td>
                <div className="filter-content">
                  <Select
                    className={`table-select pagos${selectedBanco.length > 0 ? " active" : ""}`}
                    value={selectedBanco}
                    onChange={(e) => setSelectedBanco(e)}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    options={props.bancosFilter}
                    autocomplete="off"
                    isSearchable={false}
                    components={{ Option, DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    isClearable={false}
                    isMulti
                    placeholder="Todos"
                    styles={ customStyles }
                    width='207px'
                    border={`${selectedBanco.length > 0? "solid 1px #6cbfff" : "none"}`}
                  />
                 
                </div>
              </td>
              <td>
                <div className={`filter-content${(propuestaPago === '' || selectedPropuestaPago === 'Todos') ? "" : " active"}`}>
                  <NumericSelect 
                    name = 'ns-propuesta-pago'
                    value = { propuestaPago }
                    setValue = { setPropuestaPago }
                    selected = { selectedPropuestaPago }
                    setSelected = { setSelectedPropuestaPago }
                  />
                </div>
              </td>
              <td>
                <div className={`filter-content${(monto === '' || selectedMonto === 'Todos') ? "" : " active"}`}>
                  <NumericSelect 
                    name = 'ns-monto'
                    value = { monto }
                    setValue = { setMonto }
                    selected = { selectedMonto }
                    setSelected = { setSelectedMonto }
                  />
                  
                </div>
              </td>
              <td>
                <div className="filter-content">
                  <Select
                      className="table-select"
                      value={selectedEstado}
                      onChange={(e) => setSelectedEstado(e)}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      options={props.estadoFilter}
                      autocomplete="off"
                      isSearchable={false}
                      components={{ Option, DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                      isClearable={false}
                      isMulti
                      placeholder="Todos"
                      styles={ customStyles }
                      width='207px'
                      border={`${selectedEstado.length > 0? "solid 1px #6cbfff" : "none"}`}
                    />
                </div>
              </td>
          	</tr>
          </>
        }
        </thead>
        <tr className="table-separator-row-border-top"><td /></tr>
        <tbody className="tbody-rounded-corners">
          {
            filteredProducts.map((prod, key) => 
              <tr key={ key } className="data">              
                <td className="align-left">
                  <CustomHighlighter text={filterText}>
                    { parseBudat(prod.FECHA) }
                  </CustomHighlighter>
                </td>
                <td className="align-left">
                  <CustomHighlighter text={filterText}>
                    { prod.TIPO }
                  </CustomHighlighter>
                </td>
                <td className="align-left">
                  <CustomHighlighter text={filterText}>
                    { prod.BANCO }
                  </CustomHighlighter>
                </td>
                {props.isLiberarPagos?
                 <td className="align-left">
                  <CustomHighlighter text={filterText}>
                    { prod.GRUPO_PPTA }
                  </CustomHighlighter>
                 </td>
                :
                <>
                 <td className="align-left table-link" onClick={() => props.setSelectedComprobante(prod)}>
                  <CustomHighlighter text={filterText}>
                    { prod.GRUPO_PPTA }
                  </CustomHighlighter>
                </td>
                </>}

                <td className="align-right">
                  <CustomHighlighter text={filterText}>
                    {`$ ${getLocaleStringNumber(parseInt(prod.MONTO))}`}
                  </CustomHighlighter>
                </td>
                <td className={`align-left${prod.ESTADO==="Pagada"||prod.ESTADO==="Creada"?" green":" yellow"}`}> 
                  <CustomHighlighter text={filterText}>
                    {prod.ESTADO}
                  </CustomHighlighter>
                </td>

                { props.isLiberarPagos &&
                  <td className="liberar-radio">
                    <input type="radio" name="liberar-pagos-check" value={prod.GRUPO_PPTA} checked={prod.GRUPO_PPTA === props.selectedPropuesta} onChange={e => props.setSelectedPropuesta(prod.GRUPO_PPTA)} />
                  </td>
                }
              </tr>
            )
          }
          {productsLoaded && allProducts.length===0?<>
            <tr className="data">
                <td className="align-left" colSpan="100%">
                  No hay movimientos que mostrar
                </td>
              </tr>
              <tr className="data">
                <td className="align-left" colSpan="100%">
                  &nbsp;
                </td>
              </tr>
          </>:<></>} 
        </tbody>

         

      </table>
    </div>
  )
};

export default HistorialPagosTable;
