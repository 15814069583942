import React, { useState, useEffect, useRef, useCallback } from "react";
import Select from "react-select";
import ResumenTable from "./ResumenTable";
import HeaderData from "./HeaderData";
import saldoAzul from "../assets/img/saldo-azul.svg";
import {
  SingleOption,
  customStyles,
  getLocaleStringNumber,
} from "./utils/utils.js";
import { displayLoader, hideLoader, parentRedirect } from "./utils/windows";
import { getUpdateDate } from "./utils/monthNames";
import { getEds } from "./utils/get";
import ExportExcel from "./ExportExcel";
import PrintComponent from "./PrintComponent";
import { MobileSelect } from "./MobileSelect";

const Resumen = ({ token, rutConcesionario, cabecera, isROImpersonate, callServiceHandler }) => {
  const [selectedOption, setSelectedOption] = useState({
    value: "*",
    label: "Todas",
  });
  const [products, setProducts] = useState([]);
  const [productsLoaded, setProductsLoaded] = useState(false);
  const [productsFilter, setProductsFilter] = useState([]);
  const [totals, setTotals] = useState({});
  const [updateDate, setUpdateDate] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [overdueAmount, setOverdueAmount] = useState("");
  const [allEds, setAllEds] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [exportProducts, setExportProducts] = useState([]);
  const tableRef = useRef(null);
  const isDesktopRef = useRef(window.innerWidth > process.env.REACT_APP_MOBILE_BREAK_POINT);

  const labels = [
    ["Línea producto", "LINEA_PRODUCTO"],
    ["Monto vigente", "VIGENTE"],
    ["Monto vencido", "VENCIDO"],
    ["Abonos no compensados", "ABONO_NO_COMPENSADO"],
    ["Saldo cuenta cte.", "SALDO_CTA_CTE"],
    ["Saldo documentado", "SALDO_DOCUMENTADO"],
  ];

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (token && rutConcesionario) {
        getResumen();
      }
    } else {
      if (token) {
        getResumen();
      }
    }
  }, [token, rutConcesionario]);

  useEffect(() => {
    if(cabecera!==""){
      getEds(
        callServiceHandler,
        rutConcesionario,
        null,
        setAllEds,
        null,
        null,
        cabecera
      );
    }
  }, [cabecera]);

  useEffect(() => {
    if (totals) {
      const totalsf = {
        LINEA_PRODUCTO: "Total",
        VIGENTE: totals.VIGENTE,
        VENCIDO: totals.VENCIDO,
        ABONO_NO_COMPENSADO: totals.ABONO_NO_COMPENSADO,
        SALDO_CTA_CTE: totals.SALDO_CTA_CTE,
        SALDO_DOCUMENTADO: totals.SALDO_DOCUMENTADO,
      };
      const filtrados = [...filteredProducts];
      filtrados.push(totalsf);
      setExportProducts(filtrados);
    }
  }, [filteredProducts]);

  function reloadFunction(){
    getResumen(selectedOption.value);
  }

  async function getResumen(id_eds) {
    console.log(id_eds);
    let url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/resumen-cta-cte?rut_concesionario=" +
      rutConcesionario;

    if (id_eds === undefined || id_eds === null) url += "&id_eds=*";
    else if(id_eds!=="Sin Pto Vta") url += "&id_eds=" + id_eds;

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      const data = result.data;
      cleanResumenData(data["PRODUCTOS"]);

      const date = new Date();

      const day =
        parseInt(date.getDate()) > 9
          ? date.getDate()
          : "0" + date.getDate();
      let month = parseInt(date.getMonth() + 1);
      month = month > 9 ? month : "0" + month.toString();
      getUpdateDate(setUpdateDate);

      setDueDate(day + "/" + month + "/" + date.getFullYear());
      setOverdueAmount(data.MONTO_ATRASADO);
      setProductsLoaded(true);
    }
  }

  function setNumber(num) {
    if (!isNaN(parseInt(num))) {
      let newNum = parseInt(num);

      if (num.length > 1 && num.slice(-1) === "-") newNum = -newNum;

      return newNum;
    }
    return 0;
  }

  function cleanResumenData(products) {
    const newProducts = [];
    const newProductsFilter = [];

    products.forEach(function (product) {
      if (product.LINEA_PRODUCTO !== "" && product.LINEA_PRODUCTO !== "TOTAL") {
        const newProd = {
          label: product.LINEA_PRODUCTO,
          value: product.LINEA_PRODUCTO,
        };
        newProductsFilter.push(newProd);
      }

      const newProduct = {
        LINEA_PRODUCTO: product.LINEA_PRODUCTO,
        VIGENTE: setNumber(product.VIGENTE),
        VENCIDO: setNumber(product.VENCIDO),
        ABONO_NO_COMPENSADO: setNumber(product.ABONO_NO_COMPENSADO),
        SALDO_CTA_CTE: setNumber(product.SALDO_CTA_CTE),
        SALDO_DOCUMENTADO: setNumber(product.SALDO_DOCUMENTADO),
      };

      if (newProduct.LINEA_PRODUCTO !== "TOTAL") {
        newProducts.push(newProduct);
      } else {
        setTotals(newProduct);
      }
    });

    setProductsFilter(newProductsFilter);
    setProducts(newProducts);
  }

  function changeSelectedOption(e) {
    setSelectedOption(e);
    getResumen(e.value);
  }

  function redirect(type) {
    const url =
      process.env.REACT_APP_PAGOS_PATH +
      "?type=" +
      type +
      "&eds=" +
      selectedOption.value;
    parentRedirect(url);
  }

  const onMobileSelectToggle = useCallback((opened) => {
    const mobileTable = document.querySelector(".resumen .mobile-table");
    if (mobileTable) {
      mobileTable.style.display = opened ? "none" : "";
    }
  }, []);

  return (
    <div className="resumen page">
      <div className="view-title">Cuenta corriente</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Resumen cuenta corriente</div>

      <hr className="title-separator-line" />

      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        actualizable={true}
        updateFunction={reloadFunction}
        cabecera={cabecera}
        callServiceHandler={callServiceHandler}
      />

      <div className="header-data-container-single">
        <div className="col">
          <div className="col-left">
            <div className="img-container">
              <img src={saldoAzul} />
            </div>
            <div className="text-container">
              <div className="monto-total-title">Monto Total</div>
              <div className="monto-total-data">Vencido {dueDate}</div>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="separator" />
        </div>

        <div className="col">
          <div className="col-center resumen-mobile-col-center">
            $ {overdueAmount>0?"-":""} {getLocaleStringNumber(overdueAmount,true)}
          </div>
        </div>

        <div className="col">
          <div className="separator" />
        </div>

        <div className="col">
          <div className="col-right">
            <button
              onClick={() => redirect("vencidos")}
              className="red-button resumen-mobile-red-button"
            >
              Ir a Pago
            </button>
          </div>
        </div>
      </div>

      <hr />

      <div className="resumen-options">
        <div className="filters-container">
          {isDesktopRef.current ? (
            <div className="filter">
              <div className="filter-title">ESTACIÓN DE SERVICIO</div>
              <div className="filter-input">
                <Select
                  className="eds-select"
                  defaultValue={selectedOption}
                  onChange={(e) => changeSelectedOption(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allEds}
                  components={{ SingleOption, IndicatorSeparator: () => null }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Todas"
                  styles={customStyles}
                />
              </div>
            </div>
          ) : (
            <MobileSelect
              options={allEds}
              selectItem={changeSelectedOption}
              label="ESTACIÓN DE SERVICIO"
              selectedItem={selectedOption}
              onOpen={onMobileSelectToggle}
              scrollToTop
            />
          )}
          <div className="filter">
            <div className="filter-input">
              <input
                type="search"
                className="search"
                value={filterText}
                placeholder="Buscar..."
                onChange={(e) => setFilterText(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="actions">
          <PrintComponent 
            reff={tableRef}
            classes=""
            pageStyle="@page { size: 370mm 300mm; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }"
            isROImpersonate={isROImpersonate}
          />

          <ExportExcel
            data={exportProducts}
            labels={labels}
            isROImpersonate={isROImpersonate}
          />
        </div>

        <div className="important-message">
          <span className="title">Importante:&nbsp;</span>
          <span>
            El monto vencido (incluye monto moroso), no considera los abonos pendientes de case.
          </span>
        </div>
      </div>

      {token && (
        <ResumenTable
          products={products}
          setProducts={setProducts}
          totals={totals}
          productsFilter={productsFilter}
          filterText={filterText}
          filteredProducts={filteredProducts}
          setFilteredProducts={setFilteredProducts}
          productsLoaded={productsLoaded}
          redirect={redirect}
          selectedEds={selectedOption.value}
          tableRef={tableRef}
        />
      )}
    </div>
  );
};

export default Resumen;
