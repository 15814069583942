import React, { useState } from 'react';
import { CopecStepper } from '../CopecStepper';
import { useEffect } from 'react';

const EntradaDatos = ({
  step,
  setStep,
  selectedEstadoPrefactura,
  setSelectedEstadoPrefactura
}) => {

  const [filteredData, setFilteredData] = useState();

  const mockData = [
    {
      servicio: "Autoservicio #1",
      precio_ficha: 1.900,
      numero_inicial: 98.511,
      numero_final: 0,
      total_fichas: 0,
      documentacion: "Adjuntar Imagen"
    },
    {
      servicio: "Aspirado",
      precio_ficha: 1.000,
      numero_inicial: 13.069,
      numero_final: 0,
      total_fichas: 0,
      documentacion: "Adjuntar Imagen"
    },
    {
      servicio: "Autoservicio #2",
      precio_ficha: 1.900,
      numero_inicial: 96.720,
      numero_final: 0,
      total_fichas: 0,
      documentacion: "Adjuntar Imagen"
    },
    {
      servicio: "Aspirado",
      precio_ficha: 1.000,
      numero_inicial: 27.606,
      numero_final: 0,
      total_fichas: 0,
      documentacion: "Adjuntar Imagen"
    },
    {
      servicio: "Lavado interior",
      precio_ficha: 0,
      numero_inicial: 0,
      numero_final: 0,
      total_fichas: 0,
      documentacion: "Adjuntar Imagen"
    },
  ]

  useEffect(() => {
    if (mockData) {
      let aux = mockData.map((servicio, idx)=>{
        return(
          {
            idx:idx,
            servicio: servicio.servicio,
            [`precio_ficha_${idx}`]: servicio.precio_ficha,
            [`numero_inicial_${idx}`]: servicio.numero_inicial,
            [`numero_final_${idx}`]: servicio.numero_final,
            [`total_fichas_${idx}`]: servicio.total_fichas,
            [`documentacion_${idx}`]: servicio.documentacion
          }
        )
      })
      console.log("auxxxx", aux)
      setFilteredData(aux)
    }
  }, []);

  const getTituloStep = () => {
    if (step === 1) {
      return "Entrada de datos"
    }

    if (step === 2) {
      return "Validación Prefactura"
    }

    if (step === 3) {
      return "Envío"
    }
  }

  const onChangeInput = (e,idx) => {
    
    // console.log("nombre", e.target.name)
    // console.log("idx", idx)

    // console.log("nombre compuesto", `${e.target.name}_${idx}`)

    let copiaDatos = [...filteredData]

    copiaDatos[idx][`${e.target.name}_${idx}`] = e.target.value.replace(/\./g, ',')

    
    setFilteredData(copiaDatos)

  }

  const getPrecioFichaNumeroFinal = (e, idx, servicio) => {

    if (`${e.target.name}_${idx}` === `precio_ficha_${idx}`) {
      let precioTransform = e.target.value.replace(/,/g, '.')
      let copiaDatos = [...filteredData]
      copiaDatos[idx][`numero_final_${idx}`] = Number(precioTransform) * Number(copiaDatos[idx][`numero_inicial_${idx}`])
      
      getTotalFichas(idx, copiaDatos)

    }

    if (`${e.target.name}_${idx}` === `numero_final_${idx}`) {
      let montoTransform = e.target.value.replace(/,/g, '.')

      let copiaDatos = [...filteredData]

      copiaDatos[idx][`precio_ficha_${idx}`] = Number(montoTransform) / Number(copiaDatos[idx][`numero_inicial_${idx}`])

      getTotalFichas(idx, copiaDatos)

    }

  }

  const getTotalFichas = (idx, arr) => {
    
    let copiaDatos = [...arr]

    copiaDatos[idx][`total_fichas_${idx}`] = Math.abs(Number(copiaDatos[idx][`numero_inicial_${idx}`]) - Number(copiaDatos[idx][`numero_final_${idx}`]))

    setFilteredData(copiaDatos)

  }

  return (
    <div className='entrada-datos-main-container' id="entrada-datos-main-container">
      <div className="titulo-steper-container" id="titulo-steper-container">
        <div className="titulo-container" id="titulo-container">
          <div className="titulo-step" id="titulo-step">
            {getTituloStep()}
          </div>
          <div className="step-numero" id="step-numero">
            Paso {step}
          </div>
        </div>
        <CopecStepper 
          steps={
            [
              "Entradas",
              "Validación",
              "Envío"
            ]
          }
          activeStep={step}
          className="prefactura-stepper"
          activateLinks={selectedEstadoPrefactura==="CERRADO"}
          setStep={setStep}
        />
        {/* <div className="stepper-container">
          asd
        </div> */}
      </div>
      <div className="servicio-data-container" id="servicio-data-container">
        <div className="filters-container filters-container-servicio-data" id="filters-container-servicio-data">
          <div className="filter filter-eds" id="filter-eds">
            <div className="filter-title filter-title-eds" id="filter-title-eds">
              ESTACIÓN DE SERVICIO
            </div>
            <div className="filter-input filter-input-eds" id="filter-input-eds">
              <input disabled={true} className='disabled-input' type="text" id="eds-placeholder" value={"60371 - RECOLETA N° 3805, RECOLETA"}/>
            </div>
          </div>
          <div className="filter filter-tipo-servicio" id="filter-tipo-servicio">
            <div className="filter-title filter-title-tipo-servicio" id="filter-title-tipo-servicio">
              TIPO DE SERVICIO
            </div>
            <div className="filter-input filter-input-tipo-servicio" id="filter-input-tipo-servicio">
              <input disabled={true} className='disabled-input' type="text" id="eds-placeholder" value={"Lavamax"}/>
            </div>
          </div>
          <div className="filter filter-periodo" id="filter-periodo">
            <div className="filter-title filter-title-periodo" id="filter-title-periodo">
              PERIODO
            </div>
            <div className="filter-input filter-input-periodo" id="filter-input-periodo">
              <input disabled={true} className='disabled-input' type="text" id="eds-placeholder" value={"Agosto 2023"}/>
            </div>
          </div>
          <div className="filter filter-uf-facturacion" id="filter-uf-facturacion">
            <div className="filter-title filter-title-uf-facturacion" id="filter-title-uf-facturacion">
              UF DE FACTURACIÓN
            </div>
            <div className="filter-input filter-input-uf-facturacion" id="filter-input-uf-facturacion">
              <input disabled={true} className='disabled-input' type="text" id="eds-placeholder" value={"$ 36.003"}/>
            </div>
          </div>
        </div>
      </div>
      <div className="entrada-datos-table-container" id="entrada-datos-table-container">
        <table className="entrada-datos-table" id="entrada-datos-table">
          <tr className='head-trow'>
            <th>
              <div className="th-data width-280">
                <div className="th-text">
                  Servicio
                </div>
              </div>
            </th>
            <th>
              <div className="th-data width-88">
                <div className="th-text">
                  Precio Ficha
                </div>
              </div>
            </th>
            <th>
              <div className="th-data width-88">
                <div className="th-text">
                  Numeral Inicial
                </div>
              </div>
            </th>
            <th>
              <div className="th-data width-88">
                <div className="th-text">
                  Numeral Final
                </div>
              </div>
            </th>
            <th>
              <div className="th-data width-88">
                <div className="th-text">
                  Total Fichas
                </div>
              </div>
            </th>
            <th>
              <div className="th-data width-163">
                <div className="th-text">
                  Documentación
                </div>
              </div>
            </th>
          </tr>
          <tr className="table-separator-row-border-top">
            <td colSpan="100%" />
          </tr>
          <tbody>
            {
              filteredData &&  
                filteredData.map((data, idx)=>{
                  return(
                    <>
                      <tr className='data'>
                        <td className="align-left">
                          {data.servicio}
                        </td>
                        <td className="align-center">
                          <div className={`filters-container filters-container-precio-ficha-${idx}`} id={`filters-container-precio-ficha-${idx}`}>
                            <div className={`filter filters-precio-ficha-${idx}`} id={`filter-precio-ficha-${idx}`}>
                              <input 
                                type="text"
                                name='precio_ficha' 
                                className='enabled-input' 
                                value={data[`precio_ficha_${idx}`].toString().replace(/\./g, ',')}
                                onChange={e=>onChangeInput(e, idx)}
                                onBlur={e=>getPrecioFichaNumeroFinal(e, idx, data.servicio)}
                              />
                            </div>
                          </div>
                        </td>
                        <td className="align-center">
                          <div className={`filters-container filters-container-numero-inicial-${idx}`} id={`filters-container-numero-inicial-${idx}`}>
                            <div className={`filter filters-numero-inicial-${idx}`} id={`filter-numero-inicial-${idx}`}>
                              <input 
                                type="text" 
                                name='numero_inicial'
                                className='enabled-input' 
                                value={data[`numero_inicial_${idx}`].toString().replace(/\./g, ',')} 
                                disabled={true} 
                              />
                            </div>
                          </div>
                        </td>
                        <td className="align-center">
                          <div className={`filters-container filters-container-numero-final-${idx}`} id={`filters-container-numero-final-${idx}`}>
                            <div className={`filter filters-numero-final-${idx}`} id={`filter-numero-final-${idx}`}>
                              <input 
                                type="text" 
                                name='numero_final'
                                className='enabled-input' 
                                value={data[`numero_final_${idx}`].toString().replace(/\./g, ',')}
                                onChange={e=>onChangeInput(e, idx)}
                                onBlur={e=>getPrecioFichaNumeroFinal(e, idx, data.servicio)}
                              />
                            </div>
                          </div>
                        </td>
                        <td className="align-center">
                          <div className={`filters-container filters-container-total-fichas-${idx}`} id={`filters-container-total-fichas-${idx}`}>
                            <div className={`filter filters-total-fichas-${idx}`} id={`filter-total-fichas-${idx}`}>
                              <input 
                                type="text" 
                                name='total_fichas'
                                className='enabled-input' 
                                value={data[`total_fichas_${idx}`].toString().replace(/\./g, ',')} 
                                disabled={true}
                              />
                            </div>
                          </div>
                        </td>
                        <td className="align-center table-link documentacion">
                          {data[`documentacion_${idx}`]}
                        </td>
                      </tr>
                      <tr className="table-separator-row-border-top">
                        <td colSpan="100%" />
                      </tr>
                    </>
                  )
                })
            }
            <tr className='aviso'>
              <td colSpan={100}>
                <span>Importante: </span>Este módulo solo declara la información de Autoservicio
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="observaciones-container" id="observaciones-container">
        <div className="observaciones-titulo" id="observaciones-titulo">
          OBSERVACIONES
        </div>
        <textarea name="" id="textarea-observaciones" cols="30" rows="10" className="textarea-observaciones" placeholder='Ingrese quí sus observaciones si las considera necesarias.'></textarea>
      </div>
      <hr />
      <div className="button-area" id="button-area">
        <button className="main-button thin hallow">Limpiar</button>
        <button disabled={true} className="main-button thin">Generar Prefactura</button>
      </div>
    </div>
  );
}

export default EntradaDatos;
