import React, { useState, useEffect } from "react";
import Select from "react-select";

import HeaderData from "../../HeaderData";
import HeaderBox from "../../HeaderBox";
import saldoAzulIcon from "../../../assets/img/saldo-azul.svg";
import imprimir from "../../../assets/img/imprimir.svg";
import descargar from "../../../assets/img/descargar.svg";
import { getUpdateDate } from "../../utils/monthNames";

import { setInstalalaciones } from "../../utils/get";
import { SingleOption, customStylesTctTae, parseBudat } from "../../utils/utils.js";
import ConfirmarGuiasManualesDetalle from "./ConfirmarGuiasManualesDetalle";

const ConfirmarGuiasManuales = ({
  token,
  rutConcesionario,
  callServiceHandler,
  cabecera,
  instalaciones,
  isROImpersonate,
  
}) => {

  const [updateDate, setUpdateDate] = useState("");
  const [selectedEds, setSelectedEds] = useState();
  const [allEds, setAllEds] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(
    {
    value: "TCT",
    label: "TCT",
    }
  );
  const [displayGuiaManualDetalle, setDisplayGuiaManualDetalle] = useState(false);
  const [displayMainTable, setDisplayMainTable] = useState(false);
  const [data, setData] = useState();
  

  
  const [guiaManualNumero, setGuiaManualNumero] = useState();
  
  const [instalacion, setInstalacion] = useState();
  
  const [clienteNombre, setClienteNombre] = useState();
  
  const [clienteDireccion, setClienteDireccion] = useState();
  
  const [clienteComuna, setClienteComuna] = useState();
  
  const [clienteRut, setClienteRut] = useState();
  
  const [clienteGiro, setClienteGiro] = useState();
  
  const [clienteTarjeta, setClienteTarjeta] = useState();

  const [clienteCodigo, setClienteCodigo] = useState();
  
  const [clienteSucursal, setClienteSucursal] = useState();
  
  const [ventaNumeroGuia, setVentaNumeroGuia] = useState();
  
  const [ventaFecha, setVentaFecha] = useState();

  const [ventaHora, setVentaHora] = useState();
  
  const [ventaCodigoAutorizacion, setVentaCodigoAutorizacion] = useState("1986685");

  const [ventaResponsableGuia, setVentaResponsableGuia] = useState("Conces");

  const [ventaTransaccionNumero, setVentaTransaccionNumero] = useState();

  const [codigoProducto, setCodigoProducto] = useState()

  const [codigoSubProducto, setCodigoSubProducto] = useState()

  const [tableProducto, setTableProducto] = useState();

  const [tableMonto, setTableMonto] = useState();

  const [tablePrecio, setTablePrecio] = useState();

  const [tableUnidad, setTableUnidad] = useState();

  const [flete, setFlete] = useState(0);

  const [nombrePortal, setNombrePortal] = useState();

  const [rutPortal, setRutPortal] = useState();

  useEffect(() => {
    if (cabecera) {
      console.log("cab", cabecera)
      console.log("cab dat c", cabecera.DATOS_CLIENTE)
      console.log("cab dat c NOMRBE", cabecera.DATOS_CLIENTE.NOMBRE)
      setNombrePortal(cabecera.DATOS_CLIENTE.NOMBRE)
    }
  }, [cabecera]);

  useEffect(() => {
    if (rutConcesionario) {
      console.log("ruc conse", rutConcesionario)
      setRutPortal(rutConcesionario)
    }
  }, [rutConcesionario]);

  useEffect(() => {
    getUpdateDate(setUpdateDate)
  }, [])

  useEffect(() => {
    if (instalaciones !== "") {
      setInstalalaciones(instalaciones, setAllEds);
    }
  }, [instalaciones]);

  useEffect(() => {
    if (allEds.length === 1){
      setSelectedEds(allEds[0]);
    }
  }, [allEds]);

  useEffect(() => {
    if (selectedEds&&selectedProduct) {
      setDisplayMainTable(true)
    }
  }, [selectedEds, selectedProduct]);

  function changeEds(e) {
    if (e !== selectedEds) {
      setSelectedEds(e);
    }
  }

  function changeSelectedProduct(e) {
    console.log(e);
    console.log(e.value);
    if (e !== selectedProduct) {
      setSelectedProduct(e);
    }
    // setDisplayMainTable(true)
  }
  
  const showGuiaManualDetalle = (hora, venta_numero_guia, fecha, numero_tarjeta, rut_cliente, codigo_cliente, sucursal_cliente, numero_transaccion) =>{
    setInstalacion(`${parseInt(selectedEds.value)} - ${cabecera.DATOS_CLIENTE.NOMBRE}`)
    setVentaHora(hora)
    setVentaNumeroGuia(venta_numero_guia)
    setVentaFecha(fecha)
    setVentaNumeroGuia(venta_numero_guia)
    setClienteTarjeta(numero_tarjeta)
    setClienteRut(rut_cliente)
    setClienteCodigo(codigo_cliente)
    setClienteSucursal(sucursal_cliente)
    setVentaTransaccionNumero(numero_transaccion)
    getDetalleGuia(rut_cliente, codigo_cliente, numero_transaccion)
    // setDisplayGuiaManualDetalle(true)
  }

  const getDetalleGuia = async (rut, codigo, transaccion) => {
    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-detalle-guia?RUTCLIENTE=" + rut 
    +"&CODCLIENTE=" + codigo
    +"&NUMTRANSACCION=" + transaccion;

    let response = await callServiceHandler(url, "GET");

    console.log("detalle guia", response.data)

    setClienteComuna(response.data.guia.comuna)
    setGuiaManualNumero(response.data.guia.detalle_guia)
    setClienteDireccion(response.data.guia.direccion)
    setClienteGiro(response.data.guia.giro_comercial)
    setClienteNombre(response.data.guia.nombre_cliente)
    setTableMonto(response.data.guia.monto)
    setTablePrecio(response.data.guia.precio)
    setTableUnidad(response.data.guia.unidad)
    setTableProducto(response.data.guia.producto)
    setCodigoProducto(response.data.guia.cod_producto)
    setCodigoSubProducto(response.data.guia.cod_subproducto)
    setFlete(response.data.guia.flete)
    setDisplayGuiaManualDetalle(true)
  }

  const getGuiasManuales = async (idEds, producto) => {
    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/obtener-guias-confirmacion?IDEDS="+idEds
    +"&PRODUCTO="+producto;

    let response = await callServiceHandler(url, "GET");

    console.log("guias manuales", response.data)

    setData(response.data.guias)
  }

  useEffect(() => {
    if (selectedEds && selectedProduct) {
      let eds = parseInt(selectedEds.value)
      getGuiasManuales(eds, selectedProduct.value)
    }
  }, [selectedEds, selectedProduct]);
  
  return (
    <div className="confirmar-guias-manuales-container">
      <div className="view-title">TCT TAE</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Confirmar Guías Manuales</div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandler={callServiceHandler}
        cabecera={cabecera}
        actualizable={true}
      />
      {
        !displayGuiaManualDetalle&&
        <div className="confirmar-guias-manuales-principal-container">
          <div className="filters-container">
            <div className="filter">
              <div className="filter-title required-field">
                ESTACIÓN DE SERVICIO
              </div>
              <div className="filter-input eds-select selector">
                <Select
                  className="eds-select"
                  value={selectedEds}
                  onChange={(e) => changeEds(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allEds}
                  components={{
                    SingleOption,
                    IndicatorSeparator: () => null,
                  }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesTctTae}
                  width="192px"
                />
              </div>
            </div>
            <div className="filter">
              <div className="filter-title required-field">FORMA COMER.</div>
              <div className="filter-input producto-select selector">
                <Select
                  className="producto-select"
                  value={selectedProduct}
                  onChange={(e) => changeSelectedProduct(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={[
                    {
                      value: "TCT",
                      label: "TCT",
                    },
                    {
                      value: "TAE",
                      label: "TAE",
                    },
                  ]}
                  components={{ SingleOption, IndicatorSeparator: () => null }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesTctTae}
                  width="100px"
                />
              </div>
            </div>
          </div>
          <table className="tct-tae-table">
            <tr>
              <th>
                <div className="th-data width-100">
                  <div className="th-text">N° de Guía</div>
                </div>
              </th>
              <th>
                <div className="th-data width-292">
                  <div className="th-text">Fecha/Hora Autorización</div>
                </div>
              </th>
            </tr>
            {
              displayMainTable?
                <tbody>
                    {
                      data&&
                      data.map(data=>{
                        return(
                          <tr className="data">
                            <td className={`align-left ${data.estado === "disponible" ? "table-link" : ""}`} onClick={e=>data.estado === "disponible" && showGuiaManualDetalle(data.hora_transaccion, data.numero_guia, data.fecha_transaccion, data.numero_tarjeta, data.rut_cliente, data.codigo_cliente,data.sucursal_cliente, data.numero_transaccion)}>{data.numero_guia}</td>
                            <td className="align-left">{parseBudat(data.fecha_transaccion)} {data.hora_transaccion}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
              :
                <tbody>
                  <tr className="data">
                    <td className="align-left" colSpan="100%">Ingrese Rut del cliente y Producto</td>
                  </tr>
                  <tr className="data">
                    <td className="align-left" colSpan="100%"></td>
                  </tr>
                </tbody>
            }
          </table>
        </div>
      }
      {
        displayGuiaManualDetalle&&
        <ConfirmarGuiasManualesDetalle 
          callServiceHandler={callServiceHandler}
          setDisplayGuiaManualDetalle={setDisplayGuiaManualDetalle} 
          guiaManualNumero={guiaManualNumero}
          instalacion={instalacion}
          clienteNombre={clienteNombre}
          clienteDireccion={clienteDireccion}
          clienteComuna={clienteComuna}
          clienteRut={clienteRut}
          clienteGiro={clienteGiro}
          clienteTarjeta={clienteTarjeta}
          clienteCodigo={clienteCodigo}
          clienteSucursal={clienteSucursal}
          ventaNumeroGuia={ventaNumeroGuia}
          ventaFecha={ventaFecha}
          ventaHora={ventaHora}
          ventaCodigoAutorizacion={ventaCodigoAutorizacion}
          ventaResponsableGuia={ventaResponsableGuia}
          ventaTransaccionNumero={ventaTransaccionNumero}
          tableProducto={tableProducto}
          tableMonto={tableMonto}
          tablePrecio={tablePrecio}
          tableUnidad={tableUnidad}
          flete={flete}
          codigoProducto={codigoProducto}
          codigoSubProducto={codigoSubProducto}
          token={token}
          selectedProduct={selectedProduct}
          nombrePortal={nombrePortal}
          rutPortal={rutPortal}
          getGuiasManuales={getGuiasManuales}
          selectedEds={selectedEds}
          setData={setData}
        />
      }
    </div>
  );
};

export default ConfirmarGuiasManuales;
