import React, { useState, useEffect } from "react";
import HeaderData from "../../HeaderData";
import { getAllPeriodos, getPeriodosSinceYearAndMonth, getUpdateDate } from "../../utils/monthNames";
import SolicitudRegularizacion from "../SolicitudRegularizacion";
import { getConfiguracion, initializeSelectOptions, setInstalalaciones } from "../../utils/get";
import Select from "react-select";
import { SingleOption, customStylesPedidos, customStylesHistorialRegularizacion, parseDate, customFilter, Option, checkSelectedOptions } from "../../utils/utils.js";
import DatePicker from "react-datepicker";
import Flecha_down from "../../../assets/img/Flecha_down.svg";
import Flecha_up from "../../../assets/img/Flecha_up.svg";
import NumericSelect from "../../NumericSelect";

const HistorialRegularizacion = ({
  token,
  rutConcesionario,
  cabecera,
  instalaciones,
  isROImpersonate,
  edsPortal,
  callServiceHandler,
  pagador
}) => {
  const [updateDate, setUpdateDate] = useState();
  const [allEds, setAllEds] = useState([]);
  const [selectedEds, setSelectedEds] = useState();
  const [fecha, setFecha] = useState();
  const [filterText, setFilterText] = useState("");
  const [configuracion, setConfiguracion] = useState();
  const [selector, setSelector] = useState("LITROS");
  const [formaCFilter, setFormaCFilter] = useState([]);
  const [allFormaC, setAllFormaC] = useState([]);

  const [transacciones, setTransacciones] = useState([])
  const [filteredTransacciones, setFilteredTransacciones] = useState([])

  const [controladorFilter, setControladorFilter] = useState()
  const [selectedControladorFilter, setSelectedControladorFilter] = useState()

  const [precioFilter, setPrecioFilter] = useState()
  const [selectedPrecioFilter, setSelectedPrecioFilter] = useState()

  const [identificadorFilter, setIdentificadorFilter] = useState()
  const [selectedIdentificadorFilter, setSelectedIdentificadorFilter] = useState("Todos")

  const [allDocumentos, setAllDocumentos] = useState([]);
  const [documentoFilter, setDocumentoFilter] = useState([]);

  const [formaComercializacionFilter, setFormaComercializacionFilter] = useState([])
  const [allFormasComercializacion, setAllFormasComercializacion] = useState([])

  const [documentoVentaFilter, setDocumentoVentaFilter] = useState([])
  const [allDocumentosVenta, setAllDocumentosVenta] = useState([])

  const [allMateriales, setAllMateriales] = useState([]);
  const [materialFilter, setMaterialFilter] = useState([]);

  const [estadoSolicitudFilter, setEstadoSolicitudFilter] = useState([])
  const [allEstadosSolicitud, setAllEstadosSolicitud] = useState([])

  const [confirmadosFilter, setConfirmadosFilter] = useState()
  const [selectedConfirmadosFilter, setSelectedConfirmadosFilter] = useState()

  const [documentadosFilter, setDocumentadosFilter] = useState()
  const [selectedDocumentadosFilter, setSelectedDocumentadosFilter] = useState()

  const [allPeriodos, setAllPeriodos] = useState([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState(null);

  const [selectedTransaccion, setSelectedTransaccion] = useState(null);

  const [anioActivacion, setAnioActivacion] = useState();
  const [mesActivacion, setMesActivacion] = useState();

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (token && rutConcesionario) {
        getUpdateDate(setUpdateDate);
        // setAllPeriodos(getAllPeriodos());
      }
    } else {
      if (token) {
        getUpdateDate(setUpdateDate);
        // setAllPeriodos(getAllPeriodos());
      }
    }
  }, [token, rutConcesionario]);

  useEffect(() => {
    let transaccionesArr = []
    console.log(transacciones)

    checkSelectedOptions(materialFilter, setMaterialFilter, allMateriales);

    transacciones.forEach((t) => {
      let found = true;

      if (materialFilter.length > 0) {
        if (!materialFilter.some((e) => e.value === t.MATERIAL))
          found = false;
      }

      if(found)
        transaccionesArr.push(t)
    })



    if(selectedIdentificadorFilter !== "Todos" && identificadorFilter !== ""){
      transaccionesArr = customFilter(
        transaccionesArr,
        identificadorFilter,
        selectedIdentificadorFilter,
        "IDENTIFICADOR"
      );
    }

    setFilteredTransacciones(transaccionesArr)

  }, [identificadorFilter, materialFilter])

  function setFilters(){
    const newMaterialesFilter = initializeSelectOptions()
    const newDocumentosVentaFilter = initializeSelectOptions()
    const newFormasComercializacionFilter = initializeSelectOptions()
    const newEstadosSolicitudFilter = initializeSelectOptions()

    transacciones.forEach((t) => {
      if ( t.MATERIAL !== "" && newMaterialesFilter.filter((e) => e.value === t.MATERIAL).length == 0 ) {
        const newMat = {
          label: t.MATERIAL,
          value: t.MATERIAL
        };
        newMaterialesFilter.push(newMat);
      }

      if (t.DOCUMENTO_VENTA !== "" && newDocumentosVentaFilter.filter((e) => e.value === t.DOCUMENTO_VENTA).length == 0) {
        const newDoc = {
          label: t.DOCUMENTO_VENTA,
          value: t.DOCUMENTO_VENTA
        };
        newDocumentosVentaFilter.push(newDoc);
      }

      if (t.FORMA_COMERCIALIZACION !== "" && newFormasComercializacionFilter.filter((e) => e.value === t.FORMA_COMERCIALIZACION).length == 0) {
        const newForma = {
          label: t.FORMA_COMERCIALIZACION,
          value: t.FORMA_COMERCIALIZACION
        };
        newFormasComercializacionFilter.push(newForma);
      }

      if (t.ESTADO_SOLICITUD !== "" && newEstadosSolicitudFilter.filter((e) => e.value === t.ESTADO_SOLICITUD).length == 0) {
        const newEstado = {
          label: t.ESTADO_SOLICITUD,
          value: t.ESTADO_SOLICITUD
        };
        newEstadosSolicitudFilter.push(newEstado);
      }      
    })
    setAllMateriales(newMaterialesFilter)
    setAllDocumentosVenta(newDocumentosVentaFilter)
    setAllFormasComercializacion(newFormasComercializacionFilter)
    setAllEstadosSolicitud(newEstadosSolicitudFilter)
  }

  useEffect(() => {
    if (instalaciones !== "") {
      setInstalalaciones(instalaciones, setAllEds);
    }
  }, [instalaciones]);

  useEffect(() => {
    if (selectedEds){
      getConfiguracion(callServiceHandler, selectedEds.value, setConfiguracion);
    }
  }, [selectedEds]);

  useEffect(() => {
    if(selectedEds && selectedPeriodo){
      getHistorial();
    }
  }, [selectedPeriodo]);

  const changePeriodo = (e) => {
    if(e!==selectedPeriodo){
      setSelectedPeriodo(e);
    }
  };

  const openRegularizacionDetail = (t) => {
    setSelectedTransaccion(t)
  }

  const getEstadoClass = (estado) => {
    if(estado.toLowerCase() === "aprob. usuario")
      return "estado-solicitud-green"
    else if(estado.toLowerCase() === "pend. revisión")
      return "estado-solicitud-yellow"
    else if(estado.toLowerCase() === "rechazada")
      return "estado-solicitud-red"
    return ''
  }

  const getHistorial = async() => {
    const selectedDate = selectedPeriodo.value.split("%3B");
    const month = selectedDate[0];
    const year = selectedDate[1];
    const periodo = year + month;

    let url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/cierre-diario-historial-regularizaciones?ID_EDS=" 
    + selectedEds.value +"&PERIODO="+periodo+"&ID_PAGADOR="+pagador;

    let response = await callServiceHandler(url, "GET")
    if(response !== "error"){
      clearRegularizacionesData(response.data.regularizaciones)
    }
  }

  function clearRegularizacionesData(data) {
    const newTransacciones = [];

    data.map(trx => {
      const newTransaccion = {
        NUMERO_SOLICITUD:trx.numero_solicitud,
        FECHA_SOLICITUD:trx.fecha_solicitud,
        IDENTIFICADOR:trx.identificador,
        FORMA_COMERCIALIZACION:trx.forma_comercializacion,
        DOCUMENTO_VENTA:trx.documento_venta,
        MATERIAL:trx.material,
        PRECIO:trx.precio,
        LITROS:trx.litros,
        PESOS:trx.pesos,
        ESTADO_SOLICITUD:trx.estado_solicitud
      }
      newTransacciones.push(newTransaccion);
    })
    setTransacciones(newTransacciones);
  }

  useEffect(() => {
    if(transacciones){
      setFilteredTransacciones(transacciones)
      setFilters()
    }
  }, [transacciones]);

  useEffect(() => {
    if (allEds.length === 1) {
      changeEds(allEds[0]);
    }
  }, [allEds]);

  useEffect(() => {
    if (anioActivacion && mesActivacion) {
      const newPeriodos = getPeriodosSinceYearAndMonth(anioActivacion, 
        mesActivacion);
      console.log("nuevito ", newPeriodos)
      setAllPeriodos(newPeriodos);
      setSelectedPeriodo(newPeriodos[0]);
    }
  }, [anioActivacion, mesActivacion]);

  useEffect(() => {
    if(selectedEds){
      getFechaActivacion()
    }
  }, [selectedEds]);

  async function getFechaActivacion() {

    const url =
    process.env.REACT_APP_FLUCTUACIONES_API +
    "/fluctuaciones/cierre-diario-listar-periodos?ID_EDS=" +
    selectedEds.value + 
    "&ID_PAGADOR=" + pagador;

    let response = await callServiceHandler(url, "GET");

    console.log("respuestaaa ",response)

    if (response !== "error") {
      setAnioActivacion(parseInt(response.data.año_activacion))
      setMesActivacion(parseInt(response.data.mes_activacion))
    }
  }

  async function changeEds(e) {
    if (e !== selectedEds) {
      setSelectedEds(e);
    }
  }

  function changeSelector() {
    const tselector = document.getElementById("table-selector");
    if (tselector.checked) {
      tselector.checked = false;
      setSelector("LITROS");
    } else {
      tselector.checked = true;
      setSelector("PESOS");
    }
  }

  const orderTable = (filterkey, order, parse) => {
    //const arr = orderArray(filterkey, pedidos, order, parse);
    //props.setPedidos(arr);
  };

  return (
    <div className="regularizacion">
      <div className="title-container">
        <div className="title">
          <div className="view-title">Cierre de Operaciones</div>
          <div className="view-title-separator">|</div>
          <div className="view-subtitle">Historial regularización</div>
        </div>
      </div>
      <hr className="title-separator-line" />

      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        cabecera={cabecera}
        actualizable={true}
        callServiceHandler={callServiceHandler}
      />
      <hr></hr>

      <div className={`regularizacion-options`}>
        <div className="filters-container">
          <div className="filter">
            <div className="filter-title">
              ESTACIÓN DE SERVICIO<span className="red-asterisk">*</span>
            </div>
            <div className="filter-input">
              <Select
                className="eds-select"
                value={selectedEds}
                onChange={(e) => changeEds(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allEds}
                components={{
                  SingleOption,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccionar"
                styles={customStylesPedidos}
              />
            </div>
          </div>
          <div className="filter filter-with-margin-left">
                <div className="filter-title">PERÍODO</div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedPeriodo}
                    onChange={(e) => changePeriodo(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allPeriodos}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    styles={customStylesPedidos}
                  />
                </div>
              </div>
          <div className="filter">
            <div className="filter-input">
              <input
                type="search"
                className="search"
                value={filterText}
                placeholder="Buscar..."
                onChange={(e) => setFilterText(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="regularizacion-table-container">
        <table className="regularizacion-table historial-regularizacion-table">
          <div className="table-header">
            <div className="selector">
              <div className="text">LITROS</div>
              <div className="switch" onClick={() => changeSelector()}>
                <input type="checkbox" id="table-selector" />
                <span className="slider round"></span>
              </div>
              <div className="text">PESOS</div>
            </div>
          </div>
          <tr>
            <th>
              <div className="th-data">
                <div className="th-text">
                  Nº Solicitud
                  <div className="arrows">
                    <img
                      onClick={() => orderTable("NUMERO_SOLICITUD", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() => orderTable("NUMERO_SOLICITUD", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="th-data">
                <div className="th-text">
                  Fecha<br />Solicitud
                  <div className="arrows mtop">
                    <img
                      onClick={() =>
                        orderTable("FECHA_SOLICITUD", "asc", false)
                      }
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() =>
                        orderTable("FECHA_SOLICITUD", "desc", false)
                      }
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="th-data">
                <div className="th-text">
                  Identificador
                  <div className="arrows">
                    <img
                      onClick={() => orderTable("IDENTIFICADOR", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() => orderTable("IDENTIFICADOR", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="th-data">
                <div className="th-text">
                  Forma<br />Comercializa…
                  <div className="arrows mtop">
                    <img
                      onClick={() => orderTable("FORMA_COMERCIALIZACION", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() => orderTable("FORMA_COMERCIALIZACION", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="th-data">
                <div className="th-text">
                  Documento<br />de venta
                  <div className="arrows mtop">
                    <img
                      onClick={() => orderTable("DOCUMENTO_VENTA", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() => orderTable("DOCUMENTO_VENTA", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="th-data">
                <div className="th-text">
                  Material
                  <div className="arrows">
                    <img
                      onClick={() => orderTable("MATERIAL", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() => orderTable("MATERIAL", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="th-data">
                <div className="th-text">
                  Precio
                  <div className="arrows">
                    <img
                      onClick={() => orderTable("PRECIO", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() => orderTable("PRECIO", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="th-data">
                <div className="th-text">
                  Controlad...
                  <div className="arrows">
                    <img
                      onClick={() => orderTable("CONTROLADOR", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() => orderTable("CONTROLADOR", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="th-data">
                <div className="th-text">
                  Document...
                  <div className="arrows">
                    <img
                      onClick={() => orderTable("DOCUMENTADO", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() => orderTable("DOCUMENTADO", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="th-data">
                <div className="th-text">
                  Confirmad...
                  <div className="arrows">
                    <img
                      onClick={() => orderTable("CONFIRMADO", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() => orderTable("CONFIRMADO", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="th-data estado-solicitud">
                <div className="th-text">
                  Estado<br />Solicitud
                  <div className="arrows">
                    <img
                      onClick={() => orderTable("CONFIRMADO", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() => orderTable("CONFIRMADO", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            
          </tr>
          <tr>
            <td>
              <NumericSelect
                name="ns-regularizacion-identificador"
                value={identificadorFilter}  
                setValue={setIdentificadorFilter}
                selected={selectedIdentificadorFilter}
                setSelected={setSelectedIdentificadorFilter}
                className={identificadorFilter === "" ? "active" : ""}
                customClass="regularizacion-table"
              />
            </td>
            <td>
              <Select
                className="cd-mb-select-last regularizacion-table-select"
                value={formaCFilter}
                onChange={(e) => setFormaCFilter(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allFormaC}
                components={{
                  Option,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccion"
                styles={customStylesHistorialRegularizacion}
                isMulti
              />
            </td>
            <td>
              <Select
                className="cd-mb-select-last regularizacion-table-select"
                value={documentoFilter}
                onChange={(e) => setDocumentoFilter(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allDocumentos}
                components={{
                  Option,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccion"
                styles={customStylesHistorialRegularizacion}
                isMulti
              />
            </td>
            <td>
              <Select
                className="cd-mb-select-last regularizacion-table-select"
                value={formaComercializacionFilter}
                onChange={(e) => setFormaComercializacionFilter(e)}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                options={allFormasComercializacion}
                components={{
                  Option,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccion"
                styles={customStylesHistorialRegularizacion}
                autocomplete="off"
                isSearchable={false}
                isMulti
              />
            </td>
            <td>
              <Select
                className="cd-mb-select-last regularizacion-table-select"
                value={documentoVentaFilter}
                onChange={(e) => setDocumentoVentaFilter(e)}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                options={allDocumentosVenta}
                components={{
                  Option,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccion"
                styles={customStylesHistorialRegularizacion}
                autocomplete="off"
                isSearchable={false}
                isMulti
              />
            </td>
            <td>
              <Select
                className="cd-mb-select-last regularizacion-table-select"
                value={materialFilter}
                onChange={(e) => setMaterialFilter(e)}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                options={allMateriales}
                components={{
                  Option,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccion"
                styles={customStylesHistorialRegularizacion}
                autocomplete="off"
                isSearchable={false}
                isMulti
              />
            </td>
            <td>
              <NumericSelect
                name="ns-cartola-dias-vencido"
                value={documentadosFilter}  
                setValue={setDocumentadosFilter}
                selected={selectedDocumentadosFilter}
                setSelected={setSelectedDocumentadosFilter}
                className={documentadosFilter === "" ? "active" : ""}
                customClass="regularizacion-table"
              />
            </td>
            <td>
              <NumericSelect
                name="ns-regularizacion-controlador"
                value={controladorFilter}  
                setValue={setControladorFilter}
                selected={selectedControladorFilter}
                setSelected={setSelectedControladorFilter}
                className={controladorFilter === "" ? "active" : ""}
                customClass="regularizacion-table"
              />
            </td>
            <td>
              <NumericSelect
                name="ns-regularizacion-confirmados"
                value={confirmadosFilter}  
                setValue={setConfirmadosFilter}
                selected={selectedConfirmadosFilter}
                setSelected={setSelectedConfirmadosFilter}
                className={confirmadosFilter === "" ? "active" : ""}
                customClass="regularizacion-table"
              />
            </td>
            <td>
              <NumericSelect
                name="ns-regularizacion-confirmados"
                value={confirmadosFilter}  
                setValue={setConfirmadosFilter}
                selected={selectedConfirmadosFilter}
                setSelected={setSelectedConfirmadosFilter}
                className={confirmadosFilter === "" ? "active" : ""}
                customClass="regularizacion-table"
              />
            </td>
            <td>
              <Select
                className="cd-mb-select-last regularizacion-table-select"
                value={estadoSolicitudFilter}
                onChange={(e) => setEstadoSolicitudFilter(e)}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                options={allEstadosSolicitud}
                components={{
                  Option,
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccion"
                styles={customStylesHistorialRegularizacion}
                autocomplete="off"
                isSearchable={false}
                isMulti
              />
            </td>
          </tr>
          <tr className="table-separator-row-border-top">
            <td />
          </tr>
          <tbody>
            {transacciones ? (
              <>
                {transacciones.length > 0 ? (
                  <>
                    {filteredTransacciones.map((t) => {
                      return (
                        <tr className="data">
                          <td className="align-left"><span className="link" onClick={() => openRegularizacionDetail(t)}>{t.NUMERO_SOLICITUD}</span></td>
                          <td className="align-left">{t.FECHA_SOLICITUD}</td>
                          <td className="align-left">{t.IDENTIFICADOR}</td>
                          <td className="align-left">{t.FORMA_COMERCIALIZACION}</td>
                          <td className="align-right">{t.DOCUMENTO_VENTA}</td>
                          <td className="align-right">{t.MATERIAL}</td>
                          <td className="align-right">$ {t.PRECIO}</td>
                          <td className="align-right">
                            {selector === "PESOS" && t[selector]?.controlador !== 0? '$ ': ''}{t[selector]?.controlador}
                          </td>
                          <td className="align-right">
                            {selector === "PESOS" && t[selector]?.documentados !== 0? '$ ': ''}{t[selector]?.documentados}
                          </td>
                          <td className="align-right">
                            {selector === "PESOS" && t[selector]?.confirmados !== 0? '$ ': ''}{t[selector]?.confirmados}
                          </td>
                          <td className="align-left options">
                            <span className={getEstadoClass(t.ESTADO_SOLICITUD)}>{t.ESTADO_SOLICITUD}</span>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <tr className="data">
                      <td className="align-left" colSpan="100%">
                        La búsqueda no arrojó resultados. Por favor inténtalo de
                        nuevo.
                      </td>
                    </tr>
                  </>
                )}
              </>
            ) : (
              <>
                <tr className="data">
                  <td className="align-left" colSpan="100%">
                    Realiza una búsqueda por periodo.
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      { false && selectedTransaccion &&
        <SolicitudRegularizacion 
          selectedTransaccion={selectedTransaccion}
          setSelectedTransaccion={setSelectedTransaccion}
          selectedEds={selectedEds}
          fecha={fecha}
          configuracion={configuracion}
          callServiceHandler={callServiceHandler}
          pagador={pagador}
        />
      }
    </div>
  );
};

export default HistorialRegularizacion;
