import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import CustomHighlighter from "./CustomHighlighter";
import NumericSelect from "./NumericSelect";
import {
  Option,
  parseBudat,
  customFilter,
  orderArray,
  getLocaleStringNumber,
  checkSelectedOptions,
  parseDate,
} from "./utils/utils.js";
import { customStylesCartola, customStylesCartolaTipoMovimiento, customStylesCartolaTipoMovimientoPagos } from './utils/styles';
import { showGreyBackground, hideGreyBackground } from "./utils/windows";
import Filtro from "../assets/img/filtro.svg";
import Flecha_down from "../assets/img/Flecha_down.svg";
import Flecha_up from "../assets/img/Flecha_up.svg";
import CarrotDown from "../assets/img/caret-down-blue.svg";
import es from "date-fns/locale/es";
import DocInfoModal from "./DocInfoModal";
import { CartolaTableMobileFilters } from "./CartolaTableMobileFilters";
import { CartolaTableMobileOrder } from "./CartolaTableMobileOrder";

registerLocale("es", es);

const CartolaTable = (props) => {
  const [lineaProductoFilter, setLineaProductoFilter] = useState([]);
  const [edsFilter, setEdsFilter] = useState([]);

  const [diasVencidos, setDiasVencidos] = useState("");
  const [cargos, setCargos] = useState("");
  const [abonos, setAbonos] = useState("");
  const [saldo, setSaldo] = useState("");

  const [selectedDiasVencidos, setSelectedDiasVencidos] = useState("Todos");
  const [selectedCargos, setSelectedCargos] = useState("Todos");
  const [selectedAbonos, setSelectedAbonos] = useState("Todos");
  const [selectedSaldo, setSelectedSaldo] = useState("Todos");

  const [numeroDocumento, setNumeroDocumento] = useState("");
  const [selectedNumeroDocumento, setSelectedNumeroDocumento] =
    useState("Todos");

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const [vencimientoStartDate, setVencimientoStartDate] = useState(new Date());
  const [vencimientoEndDate, setVencimientoEndDate] = useState(null);

  const [daysInMonth, setDaysInMonth] = useState(28);

  const [documentoInfo, setDocumentoInfo] = useState({});

  const [detallesDoc, setDetallesDoc] = useState([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [distanceToTop, setDistanceToTop] = useState(0);
  const [displayFiltersModal, setDisplayFiltersModal] = useState(false);
  const [displayOrderModal, setDisplayOrderModal] = useState(false);
  const [selectedMobileOrder, setSelectedMobileOrder] = useState({});

  const isDesktopRef = useRef(window.innerWidth > process.env.REACT_APP_MOBILE_BREAK_POINT);
  const movimientos = props.movimientos;
  const productsFilter = props.productsFilter;
  const allTipoMovimiento = props.allTipoMovimientoFilter;
  const tipoMovimientoFilter = props.tipoMovimientoFilter;
  const setTipoMovimientoFilter = props.setTipoMovimientoFilter;
  const allEds = props.allEds;
  const selectedYear = props.selectedYear;
  const selectedMonth = props.selectedMonth;
  const filterText = props.filterText;
  const isPagos = props.isPagos;
  const filteredMovimientos = props.filteredMovimientos;
  const setFilteredMovimientos = props.setFilteredMovimientos;
  const movimientosLoaded = props.movimientosLoaded;
  const tableRef = props.tableRef;
  const selectedTransactionsIds = props.selectedTransactionsIds;
  const setSelectedTransactionsIds = props.setSelectedTransactionsIds;
  const cuotasPagares = props.cuotasPagares;
  const movimientosOriginal = props.movimientosOriginal;
  const [detalleCargado, setDetalleCargado] = useState(false);
  const [fechaMovimientoCalendarOpen, setFechaMovimientoCalendarOpen] = useState(false)

  useEffect(() => {
    if (props.selectedMonth && props.selectedYear) {
      let year = props.selectedYear;
      let month = props.selectedMonth;

      let dt = new Date(year, month - 1, 1);
      month = dt.getMonth() + 1;
      year = dt.getFullYear();

      setDaysInMonth(new Date(year, month, 0).getDate());
      setStartDate(new Date(year, month - 1, 1));
    }
  }, [props.selectedMonth, props.selectedYear]);

  useEffect(() => {
    setFilteredMovimientos(props.movimientos);
  }, [props.movimientos]);

  useEffect(() => {
    filterr();
  }, [
    lineaProductoFilter,
    tipoMovimientoFilter,
    edsFilter,
    diasVencidos,
    cargos,
    abonos,
    saldo,
    selectedCargos,
    selectedAbonos,
    selectedNumeroDocumento,
    selectedDiasVencidos,
    selectedSaldo,
    numeroDocumento,
    filterText,
    endDate,
    vencimientoEndDate,
    props.movimientos,
  ]);

  function openFiltersModal(){
    setDisplayFiltersModal(true)
    showGreyBackground()
  }

  function closeFiltersModal(){
    setDisplayFiltersModal(false)
    hideGreyBackground()
  }

  function openOrderModal(){
    setDisplayOrderModal(true)
    showGreyBackground()
  }

  function closeOrderModal(){
    setDisplayOrderModal(false)
    hideGreyBackground()
  }

  function changeSelectedTransactions(e, id, docsap) {
    props.setErrorMessage(null);

    const selectedIds = [...selectedTransactionsIds];

    if (e.target.checked) {
      if (cuotasPagares.includes(docsap)) {
        const arraux = movimientos.filter((mov) => mov.DOCSAP === docsap);
        arraux.forEach((mov) => {
          selectedIds.push(mov.ID);
        });
      } else {
        selectedIds.push(id);
      }
    } else {
      if (cuotasPagares.includes(docsap)) {
        const arraux = movimientos.filter((mov) => mov.DOCSAP === docsap);
        arraux.forEach((mov) => {
          const index = selectedIds.indexOf(mov.ID);
          if (index > -1) {
            selectedIds.splice(index, 1);
          }
        });
      } else {
        const index = selectedIds.indexOf(id);
        if (index > -1) {
          selectedIds.splice(index, 1);
        }
      }
    }
    setSelectedTransactionsIds(selectedIds);

    const newSelectedTransactions = movimientos.filter((mov) =>
      selectedIds.includes(mov.ID)
    );
    props.setSelectedTransactions(newSelectedTransactions);
  }

  function filterr() {
    let movimientosArr = [];
    checkSelectedOptions(edsFilter, setEdsFilter, allEds);
    checkSelectedOptions(
      lineaProductoFilter,
      setLineaProductoFilter,
      productsFilter
    );
    checkSelectedOptions(
      tipoMovimientoFilter,
      setTipoMovimientoFilter,
      allTipoMovimiento
    );

    movimientos.forEach(function (mov) {
      let found = true;

      if (lineaProductoFilter.length > 0) {
        if (!lineaProductoFilter.some((e) => e.value === mov.LINEA_PRODUCTO))
          found = false;
      }
      if (tipoMovimientoFilter.length > 0) {
        if (!tipoMovimientoFilter.some((e) => e.value === mov.TIPO_DOCUMENTO))
          found = false;
      }
      if (edsFilter.length > 0) {
        if (!edsFilter.some((e) => e.value.includes(mov.NUMERO_EDS)))
          found = false;
      }

      if (endDate) {
        const dateArr = mov.FECHA_MOVIMIENTO.split("-");
        const date = new Date(
          dateArr[0],
          dateArr[1] - 1,
          dateArr[2],
          0,
          0,
          0,
          0
        );

        if (date < startDate || date > endDate) found = false;
      }

      if (vencimientoEndDate) {
        const dateArr = mov.FECHA_VENCIMIENTO.split("-");
        const date = new Date(
          dateArr[0],
          dateArr[1] - 1,
          dateArr[2],
          0,
          0,
          0,
          0
        );

        if (date < vencimientoStartDate || date > vencimientoEndDate)
          found = false;
      }

      if (found) movimientosArr.push(mov);
    });

    if (selectedDiasVencidos !== "Todos" && diasVencidos !== "")
      movimientosArr = customFilter(
        movimientosArr,
        diasVencidos,
        selectedDiasVencidos,
        "DIAS_ATRASO"
      );

    if (selectedCargos !== "Todos" && cargos !== "")
      movimientosArr = customFilter(
        movimientosArr,
        cargos,
        selectedCargos,
        "CARGO"
      );

    if (selectedAbonos !== "Todos" && abonos !== "")
      movimientosArr = customFilter(
        movimientosArr,
        abonos,
        selectedAbonos,
        "ABONO"
      );

    if (selectedSaldo !== "Todos" && saldo !== "")
      movimientosArr = customFilter(
        movimientosArr,
        saldo,
        selectedSaldo,
        "SALDO"
      );

    if (selectedNumeroDocumento !== "Todos" && numeroDocumento !== "")
      movimientosArr = customFilter(
        movimientosArr,
        numeroDocumento,
        selectedNumeroDocumento,
        "NUMERO_DOCUMENTO"
      );

    if (filterText !== "") {
      let filterTextLower = filterText.toLowerCase();
      filterTextLower = filterTextLower.replaceAll(".", "");

      movimientosArr = movimientosArr.filter((mov) => {
        return (
          (mov.LINEA_PRODUCTO &&
            mov.LINEA_PRODUCTO.toLowerCase().includes(filterTextLower)) ||
          (mov.TIPO_DOCUMENTO &&
            mov.TIPO_DOCUMENTO.toString()
              .toLowerCase()
              .includes(filterTextLower)) ||
          (mov.NUMERO_DOCUMENTO &&
            mov.NUMERO_DOCUMENTO.toString()
              .toLowerCase()
              .includes(filterTextLower)) ||
          (mov.NUMERO_OFICINA &&
            mov.NUMERO_OFICINA.toString()
              .toLowerCase()
              .includes(filterTextLower)) ||
          (mov.NUMERO_EDS &&
            mov.NUMERO_EDS.toString()
              .toLowerCase()
              .includes(filterTextLower)) ||
          (mov.COMUNA &&
            mov.COMUNA.toString().toLowerCase().includes(filterTextLower)) ||
          (mov.FECHA_MOVIMIENTO &&
            mov.FECHA_MOVIMIENTO.toString()
              .toLowerCase()
              .includes(filterTextLower)) ||
          (mov.FECHA_VENCIMIENTO &&
            mov.FECHA_VENCIMIENTO.toString()
              .toLowerCase()
              .includes(filterTextLower)) ||
          (mov.CARGO &&
            mov.CARGO.toString().toLowerCase().includes(filterTextLower)) ||
          (mov.ABONO &&
            mov.ABONO.toString().toLowerCase().includes(filterTextLower)) ||
          (mov.DIAS_ATRASO &&
            mov.DIAS_ATRASO.toString()
              .toLowerCase()
              .includes(filterTextLower)) ||
          (mov.SALDO &&
            mov.SALDO.toString().toLowerCase().includes(filterTextLower))
        );
      });
    }

    setFilteredMovimientos(movimientosArr);
  }

  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const onVencimientoDateChange = (dates) => {
    const [start, end] = dates;
    setVencimientoStartDate(start);
    setVencimientoEndDate(end);
  };

  const selectAll = (e) => {
    props.setErrorMessage(null);
    if (e.target.checked) {
      const filteredx = filteredMovimientos.filter(
        (mov, key) => mov.SELEC === "X"
      );
      setSelectedTransactionsIds(filteredx.map((mov, key) => mov.ID));
      props.setSelectedTransactions(filteredx);
    } else {
      setSelectedTransactionsIds([]);
      props.setSelectedTransactions([]);
    }
  };

  const orderTable = (filterkey, order, parse) => {
    const arr = orderArray(filterkey, movimientosOriginal, order, parse);
    props.setMovimientos(arr);
  };

  async function changeDocKey(key, mov, tipo, e){
    const infoObject = { numero: mov.NUMERO_DOCUMENTO, tipo: tipo };
    setDocumentoInfo(infoObject);
    setDisplayModal(true);
    setDistanceToTop(e.currentTarget.getBoundingClientRect().top);
    setDetalleCargado(false);

    if (tipo === "case") {
      setDetallesDoc([]);
      let url =
        process.env.REACT_APP_BASE_API +
        "/cuenta-corriente/obtener-detalles-documento?id_case=" +
        encodeURIComponent(mov.ID);

      let result = await props.callServiceHandler(url, "GET")
      if(result !== "error"){
        setDetallesDoc(result.data.TE_ESTRUCTURA);
      }
    }

    if (tipo === "factura") {
      setDetallesDoc({});
      let url =
        process.env.REACT_APP_BASE_API +
        "/cuenta-corriente/obtener-detalles-documento?factura_sd=" +
        mov.FACTURA_SD +
        "&fecha_fact=" +
        mov.FECHA_MOVIMIENTO +
        "&id_eds=" +
        mov.NUMERO_EDS;

      let result = await props.callServiceHandler(url, "GET")
      if(result !== "error"){
        setDetallesDoc(result.data);
        setDetalleCargado(true);
      }
    }
  };

  const getTipoMovimiento = (movimiento) => {
    const tipo = movimiento.ID.split(";")[0];

    if (
      tipo === "D1" ||
      tipo === "D2" ||
      tipo === "D6" ||
      tipo === "D7" ||
      tipo === "DB" ||
      tipo === "DC" ||
      tipo === "DD" ||
      tipo === "DE" ||
      tipo === "DF" ||
      tipo === "DG" ||
      tipo === "DJ" ||
      tipo === "DO" ||
      tipo === "DP" ||
      tipo === "DQ" ||
      tipo === "DR" ||
      tipo === "DT" ||
      tipo === "DU" ||
      tipo === "DV" ||
      tipo === "DW" ||
      tipo === "DX" ||
      tipo === "DY" ||
      tipo === "EA" ||
      tipo === "EB" ||
      tipo === "EC" ||
      tipo === "ED" ||
      tipo === "EE" ||
      tipo === "EF" ||
      tipo === "EH" ||
      tipo === "CR" ||
      tipo === "PV" ||
      tipo === "GR"
    ) {
      return "factura";
    } else if (
      tipo === "C1" ||
      tipo === "CE" ||
      tipo === "CT" ||
      tipo === "CV"
    ) {
      return "case";
    } else {
      return "";
    }
  };

  function closeModal() {
    setDisplayModal(false);
  }

  const toggleMobileSection = ({ currentTarget }) => {
    currentTarget.classList.toggle("expanded-section");
    currentTarget.closest(".mobile-table__item").classList.toggle("open");
  };

  const setMobileFilters = ({
    lineaProducto,
    diasVencidos,
    cargos,
    abonos,
    numeroDocumento,
    eds,
    tipoMovimiento,
    date,
    vencimientoDate,
  }) => {
    setLineaProductoFilter(lineaProducto ? [lineaProducto] : []);

    setTipoMovimientoFilter(tipoMovimiento ? [tipoMovimiento] : []);

    setEdsFilter(eds ? [eds] : []);

    setStartDate(date?.startDate || new Date());
    setEndDate(date?.endDate || null);

    setVencimientoStartDate(vencimientoDate?.startDate || new Date());
    setVencimientoEndDate(vencimientoDate?.endDate || null);

    setCargos((cargos && cargos.value) || "");
    setSelectedCargos((cargos && cargos.sign) || "Todos");

    setDiasVencidos((diasVencidos && diasVencidos.value) || "");
    setSelectedDiasVencidos((diasVencidos && diasVencidos.sign) || "Todos");

    setAbonos((abonos && abonos.value) || "");
    setSelectedAbonos((abonos && abonos.sign) || "Todos");

    setNumeroDocumento((numeroDocumento && numeroDocumento.value) || "");
    setSelectedNumeroDocumento((numeroDocumento && numeroDocumento.sign) || "Todos");
  };

  const createSelectedFiltersMobileState = () => {
    return {
      lineaProducto:
        lineaProductoFilter.length > 1
          ? { value: "todo", label: "Todos" }
          : lineaProductoFilter[0],
      diasVencidos: selectedDiasVencidos
        ? { sign: diasVencidos, value: selectedDiasVencidos }
        : undefined,
      cargos: selectedCargos
        ? { sign: cargos, value: selectedCargos }
        : undefined,
      abonos: selectedAbonos
        ? { sign: abonos, value: selectedAbonos }
        : undefined,
      numeroDocumento: selectedNumeroDocumento
        ? { sign: numeroDocumento, value: selectedNumeroDocumento }
        : undefined,
      eds:
        edsFilter.length > 1 ? { value: "todo", label: "Todos" } : edsFilter[0],
      tipoMovimiento:
        tipoMovimientoFilter.length > 1
          ? { value: "todo", label: "Todos" }
          : tipoMovimientoFilter[0],
      date: endDate ? { startDate, endDate } : undefined,
      vencimientoDate: vencimientoEndDate
        ? {
            startDate: vencimientoStartDate,
            endDate: vencimientoEndDate,
          }
        : undefined,
    };
  };

  const selectMobileOrder = (order) => {
    if (order) {
      orderTable(order.key, order.value);
    }
    setSelectedMobileOrder(order || {});
  };

  return (
    <>
      <div className="modal-container">
        <DocInfoModal
          displayModal={displayModal}
          closeModal={closeModal}
          modalTitle="titulo"
          modalText="el texto"
          detallesDoc={detallesDoc}
          info={documentoInfo}
          distanceToTop={distanceToTop}
          detalleCargado={detalleCargado}
        />
      </div>

      <div className="filters-container-mobile-wrapper">
        <div className="filters-container mobile">
          {isPagos && (
            <input
              type="checkbox"
              className="selectingInput"
              onChange={(e) => selectAll(e)}
            />
          )}
          <div className="filter">
            <div className="filter-input">
              <input
                type="search"
                className="search nomarginsearch"
                value={filterText}
                placeholder="Buscar..."
                onChange={(e) => props.setFilterText(e.target.value)}
              />
            </div>
          </div>
          <button
            className="filter-button two-icons"
            onClick={openOrderModal}
          >
            <img src={Flecha_up} alt="" />
            <img src={Flecha_down} alt="" />
          </button>
          <button
            className={`filter-button 
              ${(
                lineaProductoFilter.length > 0 ||
                !(diasVencidos === "" || selectedDiasVencidos === "Todos") ||
                !(cargos === "" || selectedCargos === "Todos") ||
                !(abonos === "" || selectedAbonos === "Todos") ||
                !(numeroDocumento === "" || selectedNumeroDocumento === "Todos") ||
                edsFilter.length > 0 ||
                tipoMovimientoFilter.length > 0 || 
                endDate ||
                vencimientoEndDate
                )? 'active': ''
              }`
            }
            onClick={openFiltersModal}
          >
            <img src={Filtro} alt="Filtro" />
          </button>
        </div>
      </div>

      <div className="cartola-container">
        {isDesktopRef.current ? (
          <table
            className={`cartola-table cartolafix${isPagos ? " pagos" : ""}`}
            ref={tableRef}
          >
            <tr>
              <th>
                <div className="th-data">
                  <div className="th-text">
                    {isPagos ? "Fecha Emisión" : "Fecha Movimiento"}
                    <div className={`arrows${isPagos ? "" : " mtop"}`}>
                      <img
                        onClick={() =>
                          orderTable("FECHA_MOVIMIENTO", "asc", false)
                        }
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() =>
                          orderTable("FECHA_MOVIMIENTO", "desc", false)
                        }
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              {isPagos && (
                <th>
                  <div className="th-data">
                    <div className="th-text">
                      Fecha Vencimiento{" "}
                      <div className="arrows mtop">
                        <img
                          onClick={() =>
                            orderTable("FECHA_VENCIMIENTO", "asc", false)
                          }
                          src={Flecha_up}
                        ></img>
                        <img
                          onClick={() =>
                            orderTable("FECHA_VENCIMIENTO", "desc", false)
                          }
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th>
              )}
              <th>
                <div className="th-data">
                  <div className="th-text">
                    Días Vencimiento{" "}
                    <div className="arrows mtop">
                      <img
                        onClick={() => orderTable("DIAS_ATRASO", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("DIAS_ATRASO", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data">
                  <div className="th-text">
                    Nº EDS{" "}
                    <div className="arrows">
                      <img
                        onClick={() => orderTable("NUMERO_EDS", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("NUMERO_EDS", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data">
                  <div className="th-text">
                    Línea Producto{" "}
                    <div className="arrows">
                      <img
                        onClick={() =>
                          orderTable("LINEA_PRODUCTO", "asc", false)
                        }
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() =>
                          orderTable("LINEA_PRODUCTO", "desc", false)
                        }
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className={`th-data th-data-tipo-movimiento${isPagos? '-pagos': ''}`}>
                  <div className="th-text">
                    Tipo Movimiento{" "}
                    <div className="arrows">
                      <img
                        onClick={() =>
                          orderTable("TIPO_DOCUMENTO", "asc", false)
                        }
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() =>
                          orderTable("TIPO_DOCUMENTO", "desc", false)
                        }
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data">
                  <div className="th-text">
                    Nº Documento{" "}
                    <div className="arrows">
                      <img
                        onClick={() =>
                          orderTable("NUMERO_DOCUMENTO", "asc", false)
                        }
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() =>
                          orderTable("NUMERO_DOCUMENTO", "desc", false)
                        }
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              {/* <th><div className="th-data"><div className='th-text'>Clasificación</div></div></th> */}
              <th>
                <div className="th-data">
                  <div className="th-text">
                    Cargos{" "}
                    <div className="arrows">
                      <img
                        onClick={() => orderTable("CARGO", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("CARGO", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data">
                  <div className="th-text">
                    Abonos{" "}
                    <div className="arrows">
                      <img
                        onClick={() => orderTable("ABONO", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("ABONO", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              {!isPagos && (
                <th>
                  <div className="th-data">
                    <div className="th-text">
                      Saldo{" "}
                      <div className="arrows">
                        <img
                          onClick={() => orderTable("SALDO", "asc", false)}
                          src={Flecha_up}
                        ></img>
                        <img
                          onClick={() => orderTable("SALDO", "desc", false)}
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th>
              )}
              {/* { !isPagos && 
            <th><div className="th-data"><div className='th-text'>Estado</div></div></th>
          } */}

              {isPagos && (
                <th>
                  <div className="th-data th-select">
                    <div className="th-text">Selecc.</div>
                  </div>
                </th>
              )}
            </tr>
            <tr className="table-separator-row-border-bottom">
              <td />
            </tr>
            <tr className="filters-row">
              <td>
                <div className="filter-content no-padding-left">
                  <label
                    onClick={(e) => e.preventDefault()}
                    className="datepicker-tooltip-container"
                  >
                    <DatePicker
                      locale="es"
                      selected={endDate}
                      onChange={onDateChange}
                      startDate={startDate}
                      endDate={endDate}
                      shouldCloseOnSelect={false}
                      minDate={ !isPagos && new Date(selectedYear, selectedMonth - 1, 1)}
                      maxDate={ !isPagos &&
                        new Date(selectedYear, selectedMonth - 1, daysInMonth)
                      }
                      placeholderText="Todos"
                      showDisabledMonthNavigation
                      dateFormat="dd/MM/yyyy"
                      selectsRange
                      className={endDate ? "active" : ""}
                      onCalendarClose={() => setFechaMovimientoCalendarOpen(false)}
                      onCalendarOpen={() => setFechaMovimientoCalendarOpen(true)}
                    />

                    <div className={`datepicker-tooltip ${fechaMovimientoCalendarOpen? 'hidden': ''}`}>
                      <div className="datepicker-tooltip-title">
                        FECHA MOVIMIENTO
                      </div>
                      <div className="datepicker-tooltip-content">
                        {parseDate(startDate)} al {parseDate(endDate)}
                      </div>
                    </div>
                  </label>
                </div>
              </td>

              {isPagos && (
                <td>
                  <div className="filter-content">
                    <label onClick={(e) => e.preventDefault()}>
                      <DatePicker
                        locale="es"
                        selected={vencimientoEndDate}
                        onChange={onVencimientoDateChange}
                        startDate={vencimientoStartDate}
                        endDate={vencimientoEndDate}
                        shouldCloseOnSelect={false}
                        showDisabledMonthNavigation
                        dateFormat="dd/MM/yyyy"
                        selectsRange
                        className={vencimientoEndDate ? "active" : ""}
                      />
                    </label>
                  </div>
                </td>
              )}
              <td>
                <div
                  className={`filter-content${
                    diasVencidos === "" || selectedDiasVencidos === "Todos"
                      ? ""
                      : " active"
                  }`}
                >
                  <NumericSelect
                    name="ns-cartola-dias-vencido"
                    value={diasVencidos}
                    setValue={setDiasVencidos}
                    selected={selectedDiasVencidos}
                    setSelected={setSelectedDiasVencidos}
                    className={diasVencidos === "" ? "active" : ""}
                  />
                </div>
              </td>
              <td>
                <div className="filter-content">
                  <Select
                    className={`table-select ${
                      edsFilter.length > 0 ? " active" : ""
                    }`}
                    value={edsFilter}
                    onChange={(e) => setEdsFilter(e)}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    options={allEds}
                    autocomplete="off"
                    isSearchable={false}
                    components={{
                      Option,
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    isMulti
                    placeholder="Todas"
                    styles={customStylesCartola}
                    width="117px"
                    border={`${
                      edsFilter.length > 0 ? "solid 1px #6cbfff" : "none"
                    }`}
                  />
                </div>
              </td>
              <td>
                <div className="filter-content">
                  <Select
                    className={`table-select ${
                      lineaProductoFilter.length > 0 ? " active" : ""
                    }`}
                    value={lineaProductoFilter}
                    onChange={(e) => setLineaProductoFilter(e)}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    options={productsFilter}
                    autocomplete="off"
                    isSearchable={false}
                    components={{
                      Option,
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    isMulti
                    placeholder="Todos"
                    styles={customStylesCartola}
                    width="117px"
                    border={`${
                      lineaProductoFilter.length > 0
                        ? "solid 1px #6cbfff"
                        : "none"
                    }`}
                  />
                </div>
              </td>
              <td>
                <div className={`filter-content tipo-movimiento${isPagos? '-pagos': ''}-table-filter`}>
                  <Select
                    className={`table-select ${
                      tipoMovimientoFilter.length > 0 ? " active" : ""
                    }`}
                    value={tipoMovimientoFilter}
                    onChange={(e) => setTipoMovimientoFilter(e)}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    options={allTipoMovimiento}
                    autocomplete="off"
                    isSearchable={false}
                    components={{
                      Option,
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    isMulti
                    placeholder="Todos"
                    styles={isPagos? customStylesCartolaTipoMovimientoPagos: customStylesCartolaTipoMovimiento }
                    width="117px"
                    border={`${
                      tipoMovimientoFilter.length > 0
                        ? "solid 1px #6cbfff"
                        : "none"
                    }`}
                  />
                </div>
              </td>
              <td>
                <div
                  className={`filter-content${
                    numeroDocumento === "" ||
                    selectedNumeroDocumento === "Todos"
                      ? ""
                      : " active"
                  }`}
                >
                  <NumericSelect
                    className={"active"}
                    name="ns-cartola-numero-documento"
                    value={numeroDocumento}
                    setValue={setNumeroDocumento}
                    selected={selectedNumeroDocumento}
                    setSelected={setSelectedNumeroDocumento}
                  />
                </div>
              </td>
              <td>
                <div
                  className={`filter-content${
                    cargos === "" || selectedCargos === "Todos" ? "" : " active"
                  }`}
                >
                  <NumericSelect
                    name="ns-cartola-cargos"
                    value={cargos}
                    setValue={setCargos}
                    selected={selectedCargos}
                    setSelected={setSelectedCargos}
                  />
                </div>
              </td>
              <td>
                <div
                  className={`filter-content${
                    abonos === "" || selectedAbonos === "Todos" ? "" : " active"
                  }`}
                >
                  <NumericSelect
                    name="ns-cartola-abonos"
                    value={abonos}
                    setValue={setAbonos}
                    selected={selectedAbonos}
                    setSelected={setSelectedAbonos}
                  />
                </div>
              </td>

              {!isPagos && (
                <>
                  <td>
                    <div
                      className={`filter-content${
                        saldo === "" || selectedSaldo === "Todos"
                          ? ""
                          : " active"
                      }`}
                    >
                      <NumericSelect
                        name="ns-cartola-saldo"
                        value={saldo}
                        setValue={setSaldo}
                        selected={selectedSaldo}
                        setSelected={setSelectedSaldo}
                      />
                    </div>
                  </td>
                </>
              )}
              {isPagos && (
                <td>
                  <label className="myCheckbox">
                    <input
                      className="myCheckbox-title"
                      type="checkbox"
                      name="test"
                      onChange={(e) => selectAll(e)}
                    />
                    <span></span>
                  </label>
                </td>
              )}
            </tr>
            <tbody>
              {filteredMovimientos.map((mov, key) => (
                <tr key={key} className={`data ${selectedTransactionsIds?.includes(mov.ID)?'selected':''}`}>
                  <td className={`align-left ${isPagos? 'td-pagos-121' : ''}`}>
                    <CustomHighlighter text={filterText}>
                      {parseBudat(mov.FECHA_MOVIMIENTO)}
                    </CustomHighlighter>
                  </td>
                  {isPagos && (
                    <td className={`align-left ${isPagos? 'td-pagos-123' : ''}`}>
                      <CustomHighlighter text={filterText}>
                        {parseBudat(mov.FECHA_VENCIMIENTO)}
                      </CustomHighlighter>
                    </td>
                  )}
                  <td className={`align-left ${isPagos? 'td-pagos-124' : ''}`}>
                    <CustomHighlighter text={filterText}>
                      {mov.DIAS_ATRASO}
                    </CustomHighlighter>
                  </td>

                  <td className={`align-left ${isPagos? 'td-pagos-123' : ''}`}>
                    <CustomHighlighter text={filterText}>
                      {mov.NUMERO_EDS}
                    </CustomHighlighter>
                  </td>

                  <td className={`align-left ${isPagos? 'td-pagos-123' : ''}`}>
                    <CustomHighlighter text={filterText}>
                      {mov.LINEA_PRODUCTO}
                    </CustomHighlighter>
                  </td>

                  <td className={`align-left cartola-table-tipo-movimiento${isPagos? '-pagos td-pagos-248': ''}`}>
                    <CustomHighlighter text={filterText}>
                      {mov.TIPO_DOCUMENTO}
                    </CustomHighlighter>
                  </td>

                  <td className={`align-left ${isPagos? 'td-pagos-124' : ''}`}>
                    {getTipoMovimiento(mov) != "" ? (
                      <a
                        className="underline cursor-pointer"
                        onClick={(e) =>
                          changeDocKey(key, mov, getTipoMovimiento(mov), e)
                        }
                      >
                        <CustomHighlighter text={filterText}>
                          {mov.NUMERO_DOCUMENTO}
                        </CustomHighlighter>
                      </a>
                    ) : (
                      <CustomHighlighter text={filterText}>
                        {mov.NUMERO_DOCUMENTO}
                      </CustomHighlighter>
                    )}
                  </td>

                  {/* <td className="align-left"></td> */}

                  <td className={`align-left ${isPagos? 'td-pagos-124' : ''}`}>
                    <CustomHighlighter text={filterText}>
                      {`${mov.CARGO>0?"$ "+getLocaleStringNumber(mov.CARGO):""}`}
                    </CustomHighlighter>
                  </td>

                  <td className={`align-left ${isPagos? 'td-pagos-124' : ''}`}>
                    <CustomHighlighter text={filterText}>
                      {`${mov.ABONO>0?"$ "+getLocaleStringNumber(mov.ABONO):""}`}
                    </CustomHighlighter>
                  </td>

                  {!isPagos && (
                    <>
                      <td className="align-left">
                        <b>
                          <CustomHighlighter text={filterText}>
                            {`$ ${getLocaleStringNumber(mov.SALDO)}`}
                          </CustomHighlighter>
                        </b>
                      </td>

                      {/* <td className="align-left">{mov.ESTADO}</td> */}
                    </>
                  )}

                  {isPagos && (
                    <td className={`align-left ${isPagos? 'td-pagos-62' : ''}`}>
                      <div className="myCheckbox-container">
                        <label class="myCheckbox">
                          <input
                            type="checkbox"
                            checked={selectedTransactionsIds.includes(mov.ID)}
                            onChange={(e) =>
                              changeSelectedTransactions(e, mov.ID, mov.DOCSAP)
                            }
                            disabled={mov.SELEC !== "X"}
                          />
                          <span></span>
                        </label>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
              {movimientosLoaded && movimientos.length === 0 ? (<>
                <tr className="data">
                  <td className="align-left-empty" colSpan="100%">
                    No hay movimientos que mostrar
                  </td>
                </tr>
                <tr className="data">
                  <td colSpan="100%">
                    &nbsp;
                  </td>
                </tr>
                </>) : (
                  <></>
              )}
            </tbody>

            

            {/* <tr className="data hidden">
          <td className="align-left">Total</td>
          <td className="align-left"></td>
          <td className="align-right">28934924</td>
          <td className="align-right">sdf</td>
          <td className="align-right"></td>
          <td className="align-right document">
            Documento!!
            <div className="documento-info">
              <div className="title">DETALLE SOLICITUD</div>
              <div className="content">
                ksdfhjkjshdf ksjfdhksjdhfksjhd lsjdfhlsdjflksajdf
                lskdfjlskdjfljskdf
              </div>
              <div className="footer">
                <span className="important">*</span>
                <span>Observaciones: lskjflsjdf fjsldkfj lk</span>
              </div>
            </div>
          </td>
          <td className="align-right"></td>
          <td className="align-right"></td>
          <td className="align-right"></td>
          <td className="align-right"></td>
          <td className="align-right"></td>
        </tr> */}
          </table>
        ) : (
          <div className="mobile-table">
            {filteredMovimientos.map((mov, key) => {
              return (
                <div className="mobile-table__item" key={key}>
                  <div className="mobile-table__item-top-section">
                    {isPagos && (
                      <input
                        type="checkbox"
                        className="selectInput"
                        disabled={mov.SELEC !== "X"}
                        checked={selectedTransactionsIds.includes(mov.ID)}
                        onChange={(e) =>
                          changeSelectedTransactions(e, mov.ID, mov.DOCSAP)
                        }
                      />
                    )}
                    <div className="mobile-table__item-name-container">
                      <div>
                        <p className="mobile-table__item-name">
                          <CustomHighlighter text={filterText}>
                            {mov.LINEA_PRODUCTO}
                          </CustomHighlighter>
                        </p>
                        <div className="mobile-table__item-content-list-item">
                          <p className="mobile-table__item-content-list-item-title">
                            Nº Documento
                          </p>
                          <p className="mobile-table__item-content-list-item-value">
                            <CustomHighlighter text={filterText}>
                              {mov.NUMERO_DOCUMENTO}
                            </CustomHighlighter>
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="mobile-table__item-name">
                          <CustomHighlighter text={filterText}>
                            {`Venc: ${mov.DIAS_ATRASO} días`}
                          </CustomHighlighter>
                        </p>
                        <div className="mobile-table__item-content-list-item">
                          {mov.CARGO !== 0 && (
                            <>
                              <p className="mobile-table__item-content-list-item-title">
                                Cargo
                              </p>
                              <p
                                className={
                                  "mobile-table__item-content-list-item-value red"
                                }
                              >
                                <CustomHighlighter text={filterText}>
                                  {`$ ${getLocaleStringNumber(mov.CARGO)}`}
                                </CustomHighlighter>
                              </p>
                            </>
                          )}

                          {mov.ABONO !== 0 && (
                            <>
                              <p className="mobile-table__item-content-list-item-title">
                                Abono
                              </p>
                              <p
                                className={
                                  "mobile-table__item-content-list-item-value green"
                                }
                              >
                                <CustomHighlighter text={filterText}>
                                  {`$ ${getLocaleStringNumber(mov.ABONO)}`}
                                </CustomHighlighter>
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <button
                      className="mobile-table__item-button"
                      onClick={toggleMobileSection}
                    >
                      <img src={CarrotDown} alt="expand" />
                    </button>
                  </div>
                  <div className="mobile-table__item-bottom-section">
                    <div className="mobile-table__item-content">
                      <div className="mobile-table__item-content-list">
                        <div className="mobile-table__item-content-list-item">
                          <p className="mobile-table__item-content-list-item-title">
                            Nº EDS
                          </p>
                          <p className="mobile-table__item-content-list-item-value">
                            <CustomHighlighter text={filterText}>
                              {mov.NUMERO_EDS}
                            </CustomHighlighter>
                          </p>
                        </div>
                        <div className="mobile-table__item-content-list-item">
                          <p className="mobile-table__item-content-list-item-title">
                            Tipo Movimiento
                          </p>
                          <p className="mobile-table__item-content-list-item-value">
                            <CustomHighlighter text={filterText}>
                              {mov.TIPO_DOCUMENTO}
                            </CustomHighlighter>
                          </p>
                        </div>

                        <div className="mobile-table__item-content-list-item">
                          <p className="mobile-table__item-content-list-item-title">
                            Fecha Emisión
                          </p>
                          <p className="mobile-table__item-content-list-item-value">
                            <CustomHighlighter text={filterText}>
                              {parseBudat(mov.FECHA_MOVIMIENTO)}
                            </CustomHighlighter>
                          </p>
                        </div>
                        <div className="mobile-table__item-content-list-item">
                          <p className="mobile-table__item-content-list-item-title">
                            Fecha Vencimiento
                          </p>
                          <p className="mobile-table__item-content-list-item-value">
                            <CustomHighlighter text={filterText}>
                              {parseBudat(mov.FECHA_VENCIMIENTO)}
                            </CustomHighlighter>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {displayFiltersModal && (
        <CartolaTableMobileFilters
          closeModal={closeFiltersModal}
          eds={allEds}
          tipoMovimiento={allTipoMovimiento}
          lineaProducto={productsFilter}
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
          daysInMonth={daysInMonth}
          setFilters={setMobileFilters}
          initialState={createSelectedFiltersMobileState()}
        />
      )}

      {displayOrderModal && (
        <CartolaTableMobileOrder
          closeModal={closeOrderModal}
          initialState={selectedMobileOrder}
          setOrder={selectMobileOrder}
          resetOrder={props.resetMovimientos}
        />
      )}
    </>
  );
};

export default CartolaTable;
