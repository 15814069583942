import React from 'react';
import Icono from '../assets/img/Check_green.svg'

const TRXBoxData = ({ icon, row1Title, row2Amount }) => {
  return (
    <div className="trx-box-data">
      <div className="left-column">
        <div className="img-container">
          <img src={icon} />
        </div>
      </div>
      <div className="right-column">
        <div className="first-row row">
          <div className="title">{row1Title}</div>
        </div>
        <hr />
        <div className="second-row row">
          <div className="amount">{row2Amount}</div>
        </div>
      </div>
    </div>
  );
}

export default TRXBoxData;
