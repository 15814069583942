import React, { useState } from 'react';
import { parseBudat, getLocaleStringNumber, cleanNroOrden } from '../utils/utils';

const MantenimientoCard = ({orden,openFile,tipo}) => {
  const [errorMsg, setErrorMsg] = useState(-1); 

  async function openFileOrDisplayError(o, key) {
    const error = await openFile(o);
    if (error) {
      setErrorMsg(key);
    }
  }
    
    return (
        <>
          {tipo==="orden" && 
          <div className="order-data">
          <div className="info">
            <div className="data-row">
              <div className="campo">
                <label>Nº DE AVISO</label>
                <div className="valor">{orden.numero_aviso}</div>
              </div>
              <div className="campo">
                <label>FACTURA</label>
                <div className="valor">
                  {orden.factura!=="" && orden.url!==""?
                      <a
                        className="mantenimiento-link"
                        target="_blank"
                        href={`${orden.url}`}
                      >
                        {orden.factura?orden.factura:<>&nbsp;</>}
                      </a>
                      :<>
                        {orden.factura?orden.factura:<>&nbsp;</>}
                      </>
                  }
                </div>
              </div>
            </div>
            <div className="data-row">
              <div className="campo">
                <label>FECHA DE AVISO</label>
                <div className="valor">{parseBudat(orden.fecha_aviso)}</div>
              </div>
              <div className="campo">
                <label>FECHA DE CIERRE</label>
                <div className="valor">{parseBudat(orden.fecha_cierre)}</div>
              </div>
            </div>
            <div className="data-row">
              <div className="campo">
                <label>DESCRIPCIÓN</label>
                <div className="valor full">{orden.descripcion}</div>
              </div>
            </div>
            <div className="data-row">
              <div className="campo">
                <label>PRIORIDAD</label>
                <div className="valor">{orden.prioridad}</div>
              </div>
            </div>
            <div className="data-row">
              <div className="campo">
                <label>PRODUCTO</label>
                <div className="valor full">{orden.producto}</div>
              </div>
            </div>
            <div className="data-row">
              <div className="campo">
                <label>EQUIPO</label>
                <div className="valor full">{orden.equipo}</div>
              </div>
            </div>
            <div className="data-row">
              <div className="campo">
                <label>CONTRATISTA</label>
                <div className="valor full">{orden.puesto_de_trabajo}</div>
              </div>
            </div>
          </div>
          {orden.tiene_adjuntos>0 && 
          <div className="table-container">
          <label>ADJUNTOS</label>
          <table>
            <thead>
              <tr>
                <th>Tipo</th>
                <th className="align-left">Nombre</th>
              </tr>
            </thead>
            <tr className="separator-table-row">
              <th colSpan="100%" />
            </tr>
            <tbody>
              {orden.ADJUNTOS.map((o, key) => {
                return (
                  <>
                    <tr className="data" key={key}>
                      <td className="align-left">{o.tipo == "O" ? "Orden" : "Aviso"}</td>
                      <td className="align-left">
                        <a className="cursor-pointer" onClick={()=>openFileOrDisplayError(o, key)}>
                          {o.nombre}
                        </a>
                        {errorMsg === key && <div className="single-option-label warning-message">Archivo adjunto no encontrado</div>}
                      </td>
                    </tr>
                    {orden.ADJUNTOS.length - 1 !== key && (
                      <>
                        <tr>
                          <td colSpan="100%" className="tdsep">
                            <hr></hr>
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                );
              })}
            </tbody>
            <tr className="separator-table-row">
              <th colSpan="100%" />
            </tr>
          </table>
        </div>
          }
          
        </div>
          }

          {tipo==="aviso" && 
          <div className="order-data">
          <div className="info">
            <div className="data-row">
              <div className="campo">
                <label>FECHA DE AVISO</label>
                <div className="valor">{parseBudat(orden.fecha_aviso)}</div>
              </div>
              <div className="campo">
                <label>FECHA DE CIERRE</label>
                <div className="valor">{parseBudat(orden.fecha_cierre)}</div>
              </div>
            </div>
            <div className="data-row">
              <div className="campo">
                <label>DESCRIPCIÓN</label>
                <div className="valor full">{orden.descripcion}</div>
              </div>
            </div>
            <div className="data-row">
              <div className="campo">
                <label>PRIORIDAD</label>
                <div className="valor">{orden.prioridad}</div>
              </div>
            </div>
            <div className="data-row">
              <div className="campo">
                <label>PRODUCTO</label>
                <div className="valor full">{orden.producto}</div>
              </div>
            </div>
            <div className="data-row">
              <div className="campo">
                <label>EQUIPO</label>
                <div className="valor full">{orden.equipo}</div>
              </div>
            </div>
            <div className="data-row">
              <div className="campo">
                <label>CONTRATISTA</label>
                <div className="valor full">{orden.puesto_de_trabajo}</div>
              </div>
            </div>
            <div className="data-row">
              <div className="campo">
                <label>FACTURA</label>
                <div className="valor">
                  {orden.factura!=="" && orden.url!==""?
                    <a
                      className="mantenimiento-link"
                      target="_blank"
                      href={`${orden.url}`}
                    >
                      {orden.factura?orden.factura:<>&nbsp;</>}
                    </a>
                  :<>
                    {orden.factura?orden.factura:<>&nbsp;</>}
                  </>
                  }
                </div>
              </div>
            </div>
          </div>
          {orden.tiene_adjuntos>0 && 
            <div className="table-container">
            <label>ADJUNTOS</label>
            <table>
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th className="align-left">Nombre</th>
                </tr>
              </thead>
              <tr className="separator-table-row">
                <th colSpan="100%" />
              </tr>
              <tbody>
                {orden.ADJUNTOS.map((a, key) => {
                  return (
                    <>
                      <tr className="data" key={key}>
                        <td className="align-left">{a.tipo == "O" ? "Orden" : "Aviso"}</td>
                        <td className="align-left">
                          <a className="cursor-pointer" onClick={()=>openFileOrDisplayError(a, key)}>
                            {a.nombre}
                          </a>
                          {errorMsg === key && <div className="single-option-label warning-message">Archivo adjunto no encontrado</div>}
                        </td>
                      </tr>
                      {orden.ADJUNTOS.length - 1 !== key && (
                        <>
                          <tr>
                            <td colSpan="100%" className="tdsep">
                              <hr></hr>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  );
                })}
  
              </tbody>
              <tr className="separator-table-row">
                <th colSpan="100%" />
              </tr>
            </table>
          </div>
          }
          
        </div>
          }

{tipo==="ordenOT" && 
          <div className="order-data">
          <div className="info">
            <div className="data-row">
              <div className="campo">
                <label>FECHA DE ORDEN</label>
                <div className="valor">{parseBudat(orden.fecha_orden)}</div>
              </div>
              <div className="campo">
                <label>FECHA DE CIERRE</label>
                <div className="valor">{parseBudat(orden.fecha_cierre)}</div>
              </div>
            </div>
            <div className="data-row">
              <div className="campo">
                <label>ESTADO</label>
                <div className="valor">{orden.estado}</div>
              </div>
            </div>
            <div className="data-row">
              <div className="campo">
                <label>DESCRIPCIÓN</label>
                <div className="valor full">{orden.descripcion}</div>
              </div>
            </div>
            <div className="data-row">
              <div className="campo">
                <label>MONTO PLAN</label>
                <div className="valor">{"$ "+getLocaleStringNumber(orden.monto_plan)}</div>
              </div>
              <div className="campo">
                <label className="required-field">MONTO REAL</label>
                <div className="valor">{"$ "+getLocaleStringNumber(orden.monto_real)}</div>
              </div>
            </div>
            <div className="data-row">
              <div className="campo">
                <label>FACTURA</label>
                <div className="valor">
                  {orden.factura!=="" && orden.url!==""?
                    <a
                      className="mantenimiento-link"
                      target="_blank"
                      href={`${orden.url}`}
                    >
                      {orden.factura?orden.factura:<>&nbsp;</>}
                    </a>
                    :<>
                      {orden.factura?orden.factura:<>&nbsp;</>}
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          {orden.tiene_adjuntos>0 && 
            <div className="table-container">
            <label>ADJUNTOS</label>
            <table>
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th className="align-left">Nombre</th>
                </tr>
              </thead>
              <tr className="separator-table-row">
                <th colSpan="100%" />
              </tr>
              <tbody>
                {orden.ADJUNTOS.map((a, key) => {
                  return (
                    <>
                      <tr className="data" key={key}>
                        <td className="align-left">{a.tipo == "O" ? "Orden" : "Aviso"}</td>
                        <td className="align-left">
                          <a className="cursor-pointer" onClick={()=>openFileOrDisplayError(a, key)}>
                            {a.nombre}
                          </a>
                          {errorMsg === key && <div className="single-option-label warning-message">Archivo adjunto no encontrado</div>}
                        </td>
                      </tr>
                      {orden.ADJUNTOS.length - 1 !== key && (
                        <>
                          <tr>
                            <td colSpan="100%" className="tdsep">
                              <hr></hr>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  );
                })}
  
              </tbody>
              <tr className="separator-table-row">
                <th colSpan="100%" />
              </tr>
            </table>
          </div>
          }
          
        </div>
          }
            
        </>
    );
}

export default MantenimientoCard;
