import React, {useState} from "react";
import ReactExport from "react-export-excel";
import CustomModal from "./CustomModal";
import descargar from "../assets/img/descargar.svg";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportExcel = ({ data, labels, isROImpersonate, isArcoprime }) => {
  const [displayModal, setDisplayModal] = useState(false)

  function closeModal(){
    setDisplayModal(false)
  }

  function showIsROAlert(){
    setDisplayModal(true)
  }

  return (
    <>
      {isROImpersonate &&
        <img src={descargar} className={`cursor-pointer ${isArcoprime?"arcoprime":""}`} onClick={showIsROAlert} />
      }
      {!isROImpersonate &&
        <ExcelFile element={<a><img src={descargar} className={`cursor-pointer ${isArcoprime?"arcoprime":""}`} /></a>} filename="Datos Exportados">
          <ExcelSheet data={data} name="Hoja 1">
            {
              labels.map((text,key)=><ExcelColumn key={key} label={text[0]} value={text[1]} />)
            }
          </ExcelSheet>
        </ExcelFile>
      }
      <CustomModal
        displayModal={displayModal}
        closeModal={closeModal}
        acceptFunc={closeModal}
        modalTitle='Importante'
        modalText='Por seguridad no estás autorizado para descargar y/o imprimir información del Módulo Cuenta Corriente'
        modalIcon='importante'
      />
    </>
  );
};
export default ExportExcel