import React, { useState, useEffect, useRef } from "react";
import { getLocaleStringNumber, parseBudat } from "./utils/utils.js";
import { showGreyBackground, hideGreyBackground, displayLoader, hideLoader } from "./utils/windows";
import CustomModal from "./CustomModal";
import ExportExcel from "./ExportExcel";
import PrintComponent from "./PrintComponent";

const labels = [
  ["Fecha de Movimiento", "FECHA_EMISION"],
  ["Línea de Producto", "TIPO_DOCUMENTO"],
  ["Tipo de Movimiento", "PRODUCTO"],
  ["Nº Documento", "NUMERO_DOCUMENTO"],
  ["Monto", "CARGO"],
];

const LiberarDocumentos = (props) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [acceptText, setAcceptText] = useState()
  const [modalTitle, setModalTitle] = useState('')
  const [modalText, setModalText] = useState('')
  const [modalIcon, setModalIcon] = useState('success')
  const tableRef = useRef(null);

  async function liberar() {
    const url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/borrar-pago?grupo_ppta=" +
      props.selectedPropuesta.toString();

    const data = {
      grupo_ppta: props.selectedPropuesta.toString(),
    };

    let result = await props.callServiceHandler(url, "DELETE")

    if(result !== "error"){
      if(result.data.DETALLE_MENSAJE.includes("La Propuesta fue eliminada con éxito")){
        setAcceptText('Finalizar')
        setModalTitle('Documento Liberado')
        setModalText(`Propuesta ${props.selectedPropuesta.toString()} ha sido eliminada. Los documentos asociados han quedado disponibles para pago.`)
        setModalIcon('success')
      }else{
        setAcceptText()
        setModalTitle('Error')
        setModalText("Ha ocurrido un error en la liberación: " + result.data.DETALLE_MENSAJE)
        setModalIcon('error')
      }  
      setDisplayModal(true)
    }
  }

  function closeModal() {
    setDisplayModal(false);
    if(modalIcon === 'success')
      props.liberado();
  }

  return (
    <div className="resumen-table liberar-documentos">
      <div className="important-message">
        <span className="title">Importante:&nbsp;</span>
        <span>
          Seleccione botón confirmar y tendrá nuevamente los documentos
          disponibles para pago, en caso contrario seleccione cancelar o volver.
        </span>
      </div>
      <table ref={tableRef}>
        <tr>
          <th>
            <div className="th-data">Fecha de Movimiento</div>
          </th>
          {/* <th><div className="th-data">Días de Vencimiento</div></th> */}
          <th>
            <div className="th-data">Línea de Producto</div>
          </th>
          <th>
            <div className="th-data">Tipo de Movimiento</div>
          </th>
          <th>
            <div className="th-data">Nº Documento</div>
          </th>
          {/* <th><div className="th-data">Clasificación</div></th> */}
          <th>
            <div className="th-data">Monto</div>
          </th>
        </tr>
        <tr className="table-separator-row-border-top"><td /></tr>
        <tbody className="tbody-rounded-corners">
          {props.selectedDocumentos.map((prod, key) => (
            <tr className="data">
              <td className="align-left">{parseBudat(prod.FECHA_EMISION)}</td>
              {/* <td className="align-left"></td> */}
              <td className="align-left">{prod.TIPO_DOCUMENTO}</td>
              <td className="align-left">{prod.PRODUCTO}</td>
              <td className="align-left">{prod.NUMERO_DOCUMENTO}</td>
              {/* <td className="align-left"></td> */}
              <td className="align-right">
                $ {getLocaleStringNumber(parseInt(prod.CARGO))}
              </td>
            </tr>
          ))}
        </tbody>
        <tr className="table-separator-row-border-bottom"><td /></tr>
        <tr className="table-separator-row-border-top"><td /></tr>
        <tbody className="tbody-rounded-corners">
          <tr className="total">
            <td className="align-left" colSpan="4">
              Total
            </td>
            <td className="align-right nodecoration" colSpan="3">
              $ {getLocaleStringNumber(parseInt(props.selectedMonto))}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="bottom-actions">
        <button
          className="back-button cancel red"
          onClick={() => props.setSelectedDocumentos([])}
        >
          Cancelar
        </button>
        <button className="back-button red" onClick={liberar}>
          Confirmar
        </button>
        {/* <img className="comprobante-action" onClick={ window.print } src={ imprimir } /> */}
        <PrintComponent 
          reff={tableRef}
          classes="comprobante-action cursor-pointer"
          isROImpersonate={props.isROImpersonate}
        />
        <ExportExcel
          data={props.selectedDocumentos}
          labels={labels}
          isROImpersonate={props.isROImpersonate}
        />
      </div>

      <CustomModal
        displayModal={displayModal}
        closeModal={closeModal}
        acceptFunc={closeModal}
        acceptText='Finalizar'
        modalTitle='Documento Liberado'
        modalText={`Propuesta ${props.selectedPropuesta.toString()} ha sido eliminada. Los documentos asociados han quedado disponibles para pago.`}
        modalIcon='success'
      />
    </div>
  );
};

export default LiberarDocumentos;
