import React from "react";
import { getLocaleStringNumber } from "../utils/utils.js";
import { allMateriales } from "../utils/materiales";

const Camion = ({ camion, displayCamionForm, compartimientos }) => {
  return (
    <>
      <div className={`camion ${displayCamionForm ? "" : "empty"}`}>
        {displayCamionForm && (
          <div className={`container cont${camion.NUMERO_COMPARTIMIENTOS}`}>
            {compartimientos.map((comp, key) => (
              <div
                key={key}
                className={`compartimiento ${
                  allMateriales[comp.material.ID_MATERIAL].class
                }`}
              >
                <div className="comp">{`COMP ${comp.NUMERO}`}</div>
                <div className="circle">
                  <div className="number">
                    {allMateriales[comp.material.ID_MATERIAL].title}
                  </div>
                  <div className="code">
                    {allMateriales[comp.material.ID_MATERIAL].subtitle}
                  </div>
                </div>
                <div className="liters">{`${getLocaleStringNumber(
                  comp.volumen
                )} L`}</div>
                <div className="compartimiento-info">
                  <div className="title">{`Compartimiento ${comp.NUMERO} (${
                    comp.volumen / 1000
                  } M3)`}</div>
                  <div className="content">
                    <div className="left">{comp.material.NOMBRE}</div>
                    <div className="right">
                      {getLocaleStringNumber(comp.volumen)} L
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Camion;
