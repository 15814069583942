import React, { useState, useEffect } from "react";
import onClickOutside from "react-onclickoutside";
import { getLocaleStringNumber } from "./utils/utils";
import { allMateriales } from "./utils/materiales";

export function getVolumenText(volumen) {
  const number = getLocaleStringNumber(volumen);
  const abvNumber = volumen / 1000;
  return `${number}L (${abvNumber}m3)`;
}

const CustomSelect = ({
  name,
  options,
  compartimiento,
  setFunction,
  selectKey,
  volumenMaximoCamion
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [selected, setSelected] = useState("Selecciona");

  CustomSelect["handleClickOutside_" + name] = () => {
    setOpenOptions(false);
  };

  function optionClick(op, selectKey, comp) {
    setOpenOptions(false);
    setFunction(op, selectKey, comp);
  }

  //Regla especial compartimentos de 11L y Camiones de 33
  //Si comprtimentos es 10/11 y es camion de 33 y NO es Diesel ni Kerosene, no mostrar volumen minimo (solo maximo 11L)
  function getSpecialRuleMin(compartimiento, op){
    //VICENTE para dejar regla activa para camiones de 33 y 38 eliminar "volumenMaximoCamion === 33000" del if
    if(compartimiento.VOLUMEN_MINIMO === 10000 && compartimiento.VOLUMEN_MAXIMO === 11000 &&
      allMateriales[op.ID_MATERIAL]?.miniTitle !== 'PD' && allMateriales[op.ID_MATERIAL]?.miniTitle !== 'K' &&
      volumenMaximoCamion === 33000)
      return false

    return true
  }

  //Regla especial compartimentos de 11L
  //Si comprtimentos es 10/11 y es Diesel o Kerosene, no mostrar volumen maximo (solo minimo 10L)
  
  //Devuelve si el camion es de 10/11 Y esta con Diesel o kerosene
  function checkCamion11PDK(compartimiento, op){
    if(compartimiento.VOLUMEN_MINIMO === 10000 && compartimiento.VOLUMEN_MAXIMO === 11000 &&
      (allMateriales[op.ID_MATERIAL]?.miniTitle === 'PD' || allMateriales[op.ID_MATERIAL]?.miniTitle === 'K'))
      return true
    return false
  }

  return (
    <div className="custom-select">
      <div
        className="custom-select-title"
        onClick={() => setOpenOptions(!openOptions)}
      >
        {compartimiento.material.NOMBRE.toLowerCase()}
      </div>
      {openOptions && (
        <div className="custom-select-dropdown">
          {options.map((op, key) => (
            <>
              {(compartimiento.VOLUMEN_MINIMO === 0 ||
                compartimiento.VOLUMEN_MINIMO === compartimiento.VOLUMEN_MAXIMO ||
                op.ID_MATERIAL === "empty") && (
                <div
                  className="option"
                  onClick={() =>
                    optionClick(op, selectKey, compartimiento.VOLUMEN_MAXIMO)
                  }
                >
                  <div className="option-title">{op.NOMBRE.toLowerCase()}</div>
                </div>
              )}

              {compartimiento.VOLUMEN_MINIMO > 0 && compartimiento.VOLUMEN_MINIMO !== compartimiento.VOLUMEN_MAXIMO && op.ID_MATERIAL !== "empty" && (
                <div className="option">
                  <div className="option-title">{op.NOMBRE.toLowerCase()}</div>
                  <div className="submenu">
                    {getSpecialRuleMin(compartimiento, op) &&
                      <div
                        className="submenu-item"
                        onClick={() =>
                          optionClick(
                            op,
                            selectKey,
                            compartimiento.VOLUMEN_MINIMO
                          )
                        }
                      >
                        {getVolumenText(compartimiento.VOLUMEN_MINIMO)}
                      </div>
                    }
                    { !checkCamion11PDK(compartimiento, op) &&
                      <div
                        className="submenu-item"
                        onClick={() =>
                          optionClick(
                            op,
                            selectKey,
                            compartimiento.VOLUMEN_MAXIMO
                          )
                        }
                      >
                        {getVolumenText(compartimiento.VOLUMEN_MAXIMO)}
                      </div>
                    }
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      )}
    </div>
  );
};

CustomSelect.prototype = {};

const clickOutsideConfig = {
  handleClickOutside: ({ props }) =>
    CustomSelect["handleClickOutside_" + props.name],
};

export default onClickOutside(CustomSelect, clickOutsideConfig);
