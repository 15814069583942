import { displayLoader, hideLoader } from "./windows";
import { parseDate, parseBudat } from "./utils";
import { getLitros, getForma } from "../utils/utils.js";
import { allMateriales } from "./materiales";
import VERDE from "../../assets/img/Check_green.svg";
import AMARILLO from "../../assets/img/check_yellow.svg";
import ROJO from "../../assets/img/atencion_rojo.svg";

const paginationItemsPerPage = parseInt(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE);

export function initializeSelectOptions() {
  return [
    { value: "todo", label: "Selec. todo" },
    { value: "borrar", label: "Borrar" },
  ];
}

export function getFilter(allData, filterName, setDataFunction) {
  const newFilter = initializeSelectOptions();

  allData.forEach(function (data) {
    if (
      data[filterName] !== "" &&
      data[filterName] !== "TOTAL" &&
      newFilter.filter((e) => e.value === data[filterName]).length === 0
    ) {
      const newProd = { label: data[filterName], value: data[filterName] };
      newFilter.push(newProd);
    }
  });
  setDataFunction(newFilter);
}

export async function getEdsArcoprime(
  callServiceHandler,
  setAllEds,
  setAllEdsForTable,
  pagador
){
  const url = process.env.REACT_APP_BASE_API +
    "/cuenta-corriente/datos-cabecera?rut_concesionario=" + pagador;

  let result = await callServiceHandler(url, "GET")
  if(result !== "error"){
    const data = result.data;
    const eeddss = [{ value: "*", label: "Todas" }];
    const eeddsst = initializeSelectOptions();

    data.DATOS_EDS.forEach(function (eds) {
     const newEds = { value: eds.ID_EDS, label: eds.NOMBRE };
     eeddss.push(newEds);
     eeddsst.push(newEds);
    });

    const sinPtoVenta = { value: "Sin Pto Vta", label: "SIN PTO VTA" };
    eeddss.push(sinPtoVenta);
    eeddsst.push(sinPtoVenta);

    if (setAllEdsForTable) {
     setAllEdsForTable(eeddsst);
    }

    setAllEds(eeddss);
  }
}

export async function getEds(
  callServiceHandler,
  rutConcesionario,
  setAllEdsForTable,
  setAllEds,
  params = null,
  setSelectedOption = null,
  cabecera
) {
  if (cabecera) {
    const eeddss = [{ value: "*", label: "Todas" }];
    const eeddsst = initializeSelectOptions();

    cabecera.DATOS_EDS.forEach(function (eds) {
      const newEds = { value: eds.ID_EDS, label: eds.NOMBRE };
      eeddss.push(newEds);
      eeddsst.push(newEds);
    });

    const sinPtoVenta = { value: "Sin Pto Vta", label: "SIN PTO VTA" };
    eeddss.push(sinPtoVenta);
    eeddsst.push(sinPtoVenta);

    if (setAllEdsForTable) {
      setAllEdsForTable(eeddsst);
    }

    setAllEds(eeddss);

    if (params) {
      const eds = params.split("=")[1];
      const found = eeddsst.find((e) => e.value === eds);
      setSelectedOption(found);
    }
  } else {
    const url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/datos-cabecera?rut_concesionario=" +
      rutConcesionario;

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      const data = result.data;
      const eeddss = [{ value: "*", label: "Todas" }];
      const eeddsst = initializeSelectOptions();

      data.DATOS_EDS.forEach(function (eds) {
        const newEds = { value: eds.ID_EDS, label: eds.NOMBRE };
        eeddss.push(newEds);
        eeddsst.push(newEds);
      });

      const sinPtoVenta = { value: "Sin Pto Vta", label: "SIN PTO VTA" };
      eeddss.push(sinPtoVenta);
      eeddsst.push(sinPtoVenta);

      if (setAllEdsForTable) {
        setAllEdsForTable(eeddsst);
      }

      setAllEds(eeddss);

      if (params) {
        const eds = params.split("=")[1];
        const found = eeddsst.find((e) => e.value === eds);
        setSelectedOption(found);
      }

    }
  }
}

export async function setInstalalaciones(instalaciones, setAllEds) {
  const eeddss = [];

  instalaciones.INSTALACIONES.forEach(function (eds) {
    const newEds = { value: eds.ID_EDS, label: eds.CODIGO_DIRECCION };
    eeddss.push(newEds);
  });

  setAllEds(eeddss);
}

export async function setInstalacionesDinamo(instalaciones, setAllEds) {
  const eeddss = [];

  instalaciones.INSTALACIONES_DINAMO.forEach(function (eds) {
    const newEds = { value: eds.ID_EDS, label: eds.CODIGO_DIRECCION };
    eeddss.push(newEds);
  });

  setAllEds(eeddss);
}

export function setPagadoresArcoprime(pagadores, setAllPagadores) {
  const pagadoresOptions = [];

  pagadores.forEach(function (pagador) {
    const newPagador = { value: "00001"+ parseInt(pagador), label: "1"+parseInt(pagador)};
    pagadoresOptions.push(newPagador);
  });

  setAllPagadores(pagadoresOptions);
}

export async function getEdsPedidosActivos(
  callServiceHandler,
  rutConcesionario,
  setAllEds,
  pagador
) {

  let idpagador;
  if (pagador) {
    idpagador = pagador;
  } else {
    const url1 = process.env.REACT_APP_BASE_API +
    "/cuenta-corriente/datos-cabecera?rut_concesionario=" + rutConcesionario;

    let idpagadorResult = await callServiceHandler(url1, "GET")
    if(idpagadorResult !== "error"){
      idpagador = idpagadorResult.data.DATOS_CLIENTE.PAGADOR
    }
  }
  if (!idpagador) return;

  const url =
    process.env.REACT_APP_PEDIDOS_API +
    "/pedidos/obtener-instalaciones-cliente?id_pagador=" +
    idpagador;

  let result = await callServiceHandler(url, "GET")
  if(result !== "error"){
    const data = result.data;
    const eeddss = [];

    data.INSTALACIONES.forEach(function (eds) {
      const newEds = { value: eds.ID_EDS, label: eds.CODIGO_DIRECCION };
      eeddss.push(newEds);
    });

    setAllEds(eeddss);
  }
}

export async function getPeriodosEdsActivos(
  token,
  rutConcesionario,
  setPeriodos
) {
  // MOCK
  return ["Agosto 2021"];
}

export async function getConfiguracion(callServiceHandler, eds, setFunction) {
  const urld =
    process.env.REACT_APP_FLUCTUACIONES_API +
    "/fluctuaciones/get-configuracion?ID_EDS=" +
    eds;

  let result = await callServiceHandler(urld, "GET")

  if(result !== "error"){
    if (result.data?.metadata?.TANQUES) {
      let tmpMetadata = result.data.metadata;
      /*
      tmpMetadata.TANQUES = tmpMetadata.TANQUES.sort((t1, t2) => {
        if (t1.TIPO > t2.TIPO) {
          return -1;
        }
        if (t1.TIPO < t2.TIPO) {
          return 1;
        }
        return 0;
      });
      */

      tmpMetadata.TANQUES = tmpMetadata.TANQUES.sort((t1, t2) => {
        if(t1.ID_MATERIAL === '000000000000000009')
          return 1
        if(t2.ID_MATERIAL === '000000000000000009')
          return -1
        if (t1.ID_MATERIAL < t2.ID_MATERIAL) {
          return -1;
        }
        if (t1.ID_MATERIAL > t2.ID_MATERIAL) {
          return 1;
        }
        return 0;
      });
      setFunction(tmpMetadata);
      return true //Hay configuracion
    }else{
      if(result.data?.ERROR === "Item not found"){
        return false //No hay configuracion
      }
    }
  }
}

export async function getConfiguracionTelesoporte(callServiceHandler, eds, setFunction) {
  
  setFunction([])
  
  const urld =
    process.env.REACT_APP_FLUCTUACIONES_API +
    "/fluctuaciones/get-configuracion?ID_EDS=" +
    eds+"&MODULO=CONF";

  let result = await callServiceHandler(urld, "GET")
  if(result !== "error"){
    if (result.data?.metadata?.TANQUES) {
      let tmpMetadata = result.data.metadata;
      /*
      tmpMetadata.TANQUES = tmpMetadata.TANQUES.sort((t1, t2) => {
        if (t1.TIPO > t2.TIPO) {
          return -1;
        }
        if (t1.TIPO < t2.TIPO) {
          return 1;
        }
        return 0;
      });
      */

      tmpMetadata.TANQUES = tmpMetadata.TANQUES.sort((t1, t2) => {
        if(t1.ID_MATERIAL === '000000000000000009')
          return 1
        if(t2.ID_MATERIAL === '000000000000000009')
          return -1
        if (t1.ID_MATERIAL < t2.ID_MATERIAL) {
          return -1;
        }
        if (t1.ID_MATERIAL > t2.ID_MATERIAL) {
          return 1;
        }
        return 0;
      });
      setFunction(tmpMetadata);
    }
  }
}

export function setDetalleVolumen(pedido, setDetalle) {
  const arr93 = [];
  const arr95 = [];
  const arr97 = [];
  const arrPd = [];
  const arrK = [];

  if (pedido.DETALLE) {
    const detalle = pedido.DETALLE;
    detalle.forEach((item) => {
      if (allMateriales[item.ID_MATERIAL].class === "sp93") {
        arr93.push(item);
      }
      if (allMateriales[item.ID_MATERIAL].class === "sp95") {
        arr95.push(item);
      }
      if (allMateriales[item.ID_MATERIAL].class === "sp97") {
        arr97.push(item);
      }
      if (allMateriales[item.ID_MATERIAL].class === "pd") {
        arrPd.push(item);
      }
      if (allMateriales[item.ID_MATERIAL].class === "k") {
        arrK.push(item);
      }
    });
  }

  const sp93object = {
    product: "Gasolina 93 Octanos NU 1202",
    liters: getLitros(arr93, "ZCRM"),
  };
  const sp95object = {
    product: "Gasolina 95 Octanos NU 1202",
    liters: getLitros(arr95, "ZCRM"),
  };
  const sp97object = {
    product: "Gasolina 97 Octanos Premium NU 1202",
    liters: getLitros(arr97, "ZCRM"),
  };
  const pdobject = {
    product: "Petroleo Diesel Ultra A1 NU 1203",
    liters: getLitros(arrPd, "ZCRM"),
  };
  const kobject = {
    product: "Kerosene NU 1223",
    liters: getLitros(arrK, "ZCRM"),
  };

  const detalles = [];
  detalles.push(sp93object);
  detalles.push(sp95object);
  detalles.push(sp97object);
  detalles.push(pdobject);
  detalles.push(kobject);
  setDetalle(detalles);
}

export function setDetalleReposicion(
  pedido,
  setReposicion,
  setCons,
  setTotalAPagar,
  setPrecioPorLitro,
  getDataForMobileReposicion
) {
  const arr93 = [];
  const arr95 = [];
  const arr97 = [];
  const arrPd = [];
  const arrK = [];

  if (pedido.DETALLE) {
    const detalle = pedido.DETALLE;
    getDataForMobileReposicion(detalle);
    detalle.forEach((item) => {
      if (allMateriales[item.ID_MATERIAL].class === "sp93") {
        arr93.push(item);
      }
      if (allMateriales[item.ID_MATERIAL].class === "sp95") {
        arr95.push(item);
      }
      if (allMateriales[item.ID_MATERIAL].class === "sp97") {
        arr97.push(item);
      }
      if (allMateriales[item.ID_MATERIAL].class === "pd") {
        arrPd.push(item);
      }
      if (allMateriales[item.ID_MATERIAL].class === "k") {
        arrK.push(item);
      }
    });
  }

  const tctAux = getForma(
    arr93,
    arr95,
    arr97,
    arrPd,
    arrK,
    "ZTCT",
    "Reposición TCT"
  );
  const taeAux = getForma(
    arr93,
    arr95,
    arr97,
    arrPd,
    arrK,
    "ZTAE",
    "Reposición TAE"
  );
  const cuponAux = getForma(
    arr93,
    arr95,
    arr97,
    arrPd,
    arrK,
    "ZCUP",
    "Reposición Cupón Electrónico"
  );
  const ffaaAux = getForma(
    arr93,
    arr95,
    arr97,
    arrPd,
    arrK,
    "ZFFA",
    "Reposición FF.AA."
  );
  const muevoAux = getForma(
    arr93,
    arr95,
    arr97,
    arrPd,
    arrK,
    "ZCEM",
    "Reposición Muevo Empresa"
  );

  const astorageAux = getForma(
    arr93,
    arr95,
    arr97,
    arrPd,
    arrK,
    "ZPAS",
    "Almacenamiento Storage"
  );
  const storageAux = getForma(
    arr93,
    arr95,
    arr97,
    arrPd,
    arrK,
    "ZPCS",
    "Storage"
  );

  const consigAux = getForma(
    arr93,
    arr95,
    arr97,
    arrPd,
    arrK,
    "ZRCO",
    "Consignación"
  );
  const conceAux = getForma(
    arr93,
    arr95,
    arr97,
    arrPd,
    arrK,
    "ZFES",
    "Concesión"
  );
  const constAux = getForma(
    arr93,
    arr95,
    arr97,
    arrPd,
    arrK,
    "ZCSC",
    "Constitución"
  );

  const repos = [
    tctAux,
    taeAux,
    cuponAux,
    ffaaAux,
    muevoAux,
    astorageAux,
    storageAux,
  ];
  const con = [consigAux, conceAux, constAux];

  setReposicion(repos);
  setCons(con);
  setTotalAPagar({
    g93: getTotal(arr93),
    g95: getTotal(arr95),
    g97: getTotal(arr97),
    pd: getTotal(arrPd),
    k: getTotal(arrK),
    total:
      getTotal(arr93) +
      getTotal(arr95) +
      getTotal(arr97) +
      getTotal(arrPd) +
      getTotal(arrK),
  });

  let precio93 = "-";
  let precio95 = "-";
  let precio97 = "-";
  let precioPd = "-";
  let precioK = "-";

  if (parseInt(getTotal(arr93)) > 0) {
    arr93.forEach((p) => {
      if (p.CATEGORIA_ITEM === "ZFES" || p.CATEGORIA_ITEM === "ZRCO") {
        precio93 = p.PRECIO_MATERIAL;
      }
    });
  }

  if (parseInt(getTotal(arr95)) > 0) {
    arr95.forEach((p) => {
      if (p.CATEGORIA_ITEM === "ZFES" || p.CATEGORIA_ITEM === "ZRCO") {
        precio95 = p.PRECIO_MATERIAL;
      }
    });
  }

  if (parseInt(getTotal(arr97)) > 0) {
    arr97.forEach((p) => {
      if (p.CATEGORIA_ITEM === "ZFES" || p.CATEGORIA_ITEM === "ZRCO") {
        precio97 = p.PRECIO_MATERIAL;
      }
    });
  }

  if (parseInt(getTotal(arrPd)) > 0) {
    arrPd.forEach((p) => {
      if (p.CATEGORIA_ITEM === "ZFES" || p.CATEGORIA_ITEM === "ZRCO") {
        precioPd = p.PRECIO_MATERIAL;
      }
    });
  }

  if (parseInt(getTotal(arrK)) > 0) {
    arrK.forEach((p) => {
      if (p.CATEGORIA_ITEM === "ZFES" || p.CATEGORIA_ITEM === "ZRCO") {
        precioK = p.PRECIO_MATERIAL;
      }
    });
  }

  setPrecioPorLitro({
    g93: precio93,
    g95: precio95,
    g97: precio97,
    pd: precioPd,
    k: precioK,
  });
}

export function getTotal(arreglo) {
  let total = 0;
  arreglo.forEach((p) => {
    if (p.CATEGORIA_ITEM === "ZFES" || p.CATEGORIA_ITEM === "ZRCO") {
      total += p.PRECIO_TOTAL_MATERIAL;
    }
  });
  return total;
}

export function getDate(fecha) {
  const arr = fecha.split("-");
  return new Date(arr[0], arr[1] - 1, arr[2]);
}

export function getCantidades(pedido, tipo) {
  let cantidad = 0;

  //OJO, estan llegando pedidos sin DETALLE, revisar.
  if (tipo === "SOLICITADA") {
    pedido.DETALLE?.map((material) => {
      cantidad += material.CANTIDAD_SOLICITADA;
    });
  }

  if (tipo === "CONFIRMADA") {
    pedido.DETALLE?.map((material) => {
      cantidad += material.CANTIDAD_CONFIRMADA_LOGISTICA;
    });
  }

  if (tipo === "ENTREGADA") {
    pedido.DETALLE?.map((material) => {
      cantidad += material.CANTIDAD_ENTREGADA;
    });
  }

  return cantidad;
}

export function getDinamoDetalle (pedido) {
  let detalle = [];

  pedido.DETALLE?.map((material)=>{
    let obj = {};
    obj.NOMBRE=material.NOMBRE;
    obj.SOLICITADO=material.CANTIDAD_SOLICITADA;
    obj.PLANIFICADO=material.CANTIDAD_CONFIRMADA_LOGISTICA;
    obj.ENTREGADO=material.CANTIDAD_ENTREGADA;
    obj.PRECIO=material.PRECIO_MATERIAL;
    obj.TOTAL_MATERIAL=material.PRECIO_TOTAL_MATERIAL;
    obj.TOTAL_MATERIAL_ENTREGADO=material.PRECIO_TOTAL_MATERIAL_ENTREGADO;
    detalle.push(obj);
  })
  return detalle;
}


export function getPaginationItems(filtered, page, setItemsFunction, setActivePageFunction){
  setActivePageFunction(page);
  if(filtered.length <= paginationItemsPerPage){
    setItemsFunction(filtered);
  }
  else{
    const start_index = (page - 1) * paginationItemsPerPage;
    const end_index = page * paginationItemsPerPage;
    setItemsFunction(filtered.slice(start_index, end_index)); 
  }
}

export function openBase64NewTab(base64Pdf,tipo){
  var blob = base64toBlob(base64Pdf,tipo);
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, "pdfBase64.pdf");
  } else {
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  }
}

export function base64toBlob(base64Data,tipo) {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  const blob = new Blob(byteArrays, { type: tipo })
  blob.name="prueba.png"
  return blob;
}

export function getMaterialClassVentas(material){
  if(material.includes("GAS")){
    return "sp"+material.split(" ")[1]
  }
  if(material==="DIESEL"){
    return "pd";
  }
  if(material==="KEROSENE"){
    return "k";
  }
}

export function getMaterialIconNameVentas(material){
  if(material.includes("GAS")){
    return material.split(" ")[1]
  }
  if(material==="DIESEL"){
    return "PD";
  }
  if(material==="KEROSENE"){
    return "K";
  }
  if(material==="GLP"){
    return "GLP"
  }
  if(material==="GNC"){
    return "GNC"
  }
}

export function getAlertIconVentas(alert){
  if(alert==="VERDE"){
    return <img src={VERDE} />
  }
  if(alert==="AMARILLA" || alert==="AMARILLO"){
    return <img src={AMARILLO} />
  }
  if(alert==="ROJA" || alert==="ROJO"){
    return <img src={ROJO} />
  }
}

export function getLastNCharacters (word,n) {
  if(word.length<n){
    return word
  }else{
    return "..."+word?.substring(
      word?.length - n,
      word?.length)
  }
}

export function getTanquesOfPedidoEspecial(configuracion,material){
  const ordenadosAux=[]
  configuracion.TANQUES.filter(t=>t.ID!=="TAE_RE").forEach(tanque=>{    
    if(!ordenadosAux.some(t => t.tanque === tanque.ID)){
      if(tanque.SIFON_1==="" && tanque.SIFON_2===""){
        ordenadosAux.push({tanque:tanque.ID,id_material:tanque.ID_MATERIAL,material:tanque.MATERIAL,volumen:0,tipo:tanque.TIPO})
      }else if((parseInt(tanque.ID) < parseInt(tanque.SIFON_1)) || (parseInt(tanque.ID) < parseInt(tanque.SIFON_2))){
        let nuevoTanque=tanque.ID;
        if(tanque.SIFON_1!==""){
          nuevoTanque+="-"+tanque.SIFON_1
        }
        if(tanque.SIFON_2!==""){
          nuevoTanque+="-"+tanque.SIFON_2
        }
        ordenadosAux.push({tanque:nuevoTanque,id_material:tanque.ID_MATERIAL,material:tanque.MATERIAL,volumen:0,tipo:tanque.TIPO})
      }
    }
  })
  return ordenadosAux.filter(tanque=>tanque.id_material===material.material)
}
