import React, { useState } from "react";
import { CartolaTableMobileOptionsModal } from "./CartolaTableMobileOptionsModal";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { MobileFiltersChoice } from "./MobileFilters/MobileFiltersChoice";
import {
  MobileFiltersOpenedChoice,
  MobileFiltersOpenedChoiceOption,
} from "./MobileFilters/MobileFiltersOpenedChoice";

const filters = {
  lineaProducto: {
    key: "lineaProducto",
    type: "direct",
    label: "Línea de producto",
  },
  diasVencidos: {
    key: "diasVencidos",
    type: "range",
    label: "Días vencimiento",
  },
  cargos: { key: "cargos", type: "range", label: "Cargos" },
  abonos: { key: "abonos", type: "range", label: "Abonos" },
  numeroDocumento: {
    key: "numeroDocumento",
    type: "range",
    label: "Nº Documento",
  },
  eds: { key: "eds", type: "direct", label: "Nº EDS" },
  tipoMovimiento: {
    key: "tipoMovimiento",
    type: "direct",
    label: "Tipo de movimiento",
  },
  date: { key: "date", type: "date", label: "Fecha emisión" },
  vencimientoDate: {
    key: "vencimientoDate",
    type: "date",
    label: "Fecha vencimiento",
  },
};

const DirectSelectionView = ({
  itemTitle,
  closeView,
  select,
  options = [],
}) => {
  return (
    <MobileFiltersOpenedChoice itemTitle={itemTitle} closeView={closeView}>
      <MobileFiltersOpenedChoiceOption
        name="Todos"
        key="todo"
        onClick={() => select({ value: "todo", label: "Todos" })}
      />
      {options.map((option) => {
        const { label, value } = option;
        if (value === "todo" || value === "borrar") {
          return null;
        }
        return (
          <MobileFiltersOpenedChoiceOption
            name={label}
            key={value}
            onClick={() => select(option)}
          />
        );
      })}
    </MobileFiltersOpenedChoice>
  );
};

const DateSelectionView = ({
  itemTitle,
  closeView,
  select,
  selectedYear,
  selectedMonth,
  daysInMonth,
  value = [null, null],
}) => {
  const [selectedDate, setSelectedDate] = useState(value);
  const [startDate, endDate] = selectedDate;

  const selectAndCloseView = (e) => {
    return startDate && endDate ? select({ startDate, endDate }) : select();
  };

  return (
    <MobileFiltersOpenedChoice itemTitle={itemTitle} closeView={closeView}>
      <MobileFiltersOpenedChoiceOption noButton>
        <DatePicker
          onChange={(date) => setSelectedDate(date || [null, null])}
          isClearable
          locale="es"
          selected={endDate}
          startDate={startDate}
          endDate={endDate}
          shouldCloseOnSelect={false}
          showDisabledMonthNavigation
          dateFormat="dd/MM/yyyy"
          selectsRange
          onCalendarClose={selectAndCloseView}
        />
      </MobileFiltersOpenedChoiceOption>
    </MobileFiltersOpenedChoice>
  );
};

const RangeSelectionView = ({ itemTitle, closeView, select }) => {
  const [selectedSign, setSelectedSign] = useState("");
  const [selectedValue, setSelectedValue] = useState("");

  const setFinalRangeSelection = () => {
    if (selectedValue) {
      select({ sign: selectedSign, value: selectedValue });
    }
  };

  const selectSign = (sign) => () => {
    setSelectedSign(sign);
    setSelectedValue("");
  };

  const commonInputProps = {
    type: "number",
    className: "mobile-filters__choice-view-option-input",
    value: selectedValue,
    onBlur: setFinalRangeSelection,
    onChange: (e) => setSelectedValue(e.target.value),
    autoFocus: true,
  };

  return (
    <MobileFiltersOpenedChoice itemTitle={itemTitle} closeView={closeView}>
      <MobileFiltersOpenedChoiceOption
        name="Mayor o igual Que…"
        onClick={selectSign(">=")}
      >
        {selectedSign === ">=" && <input {...commonInputProps} />}
      </MobileFiltersOpenedChoiceOption>
      <MobileFiltersOpenedChoiceOption
        name="Menor o igual Que…"
        onClick={selectSign("<=")}
      >
        {selectedSign === "<=" && <input {...commonInputProps} />}
      </MobileFiltersOpenedChoiceOption>
      <MobileFiltersOpenedChoiceOption
        name="Igual Que…"
        onClick={selectSign("=")}
      >
        {selectedSign === "=" && <input {...commonInputProps} />}
      </MobileFiltersOpenedChoiceOption>
      <MobileFiltersOpenedChoiceOption
        name="Todos"
        key="todo"
        onClick={() => select({ value: "Todo" })}
      />
    </MobileFiltersOpenedChoice>
  );
};

const createRangeLabel = (state) => {
  if (state && state.sign && state.value && state.value !== "Todos") {
    return `${state.sign} ${state.value}`;
  }
  else{
    return "Todos"
  }
};

const viewsMap = {
  direct: DirectSelectionView,
  range: RangeSelectionView,
  date: DateSelectionView,
};

export const CartolaTableMobileFilters = ({
  closeModal,
  eds,
  tipoMovimiento,
  lineaProducto,
  selectedYear,
  selectedMonth,
  daysInMonth,
  setFilters,
  initialState = {},
}) => {
  const [openFilter, setOpenFilter] = useState("");
  const [selectedFilters, setSelectedFilters] = useState(initialState);
  const hasFilters = Object.keys(selectedFilters).length > 0;

  const confirm = () => setFilters(selectedFilters);
  const cancelFilters = () => {
    setFilters({});
    closeModal();
  };
  const closeView = () => setOpenFilter("");

  const selectFilter = (filter) => (newValue) => {
    setSelectedFilters((oldFilters) => ({ ...oldFilters, [filter]: newValue }));
    closeView();
  };
  const createValueForChoice = (type, key) => {
    if (type === "direct") {
      return (selectedFilters[key] && selectedFilters[key].label)? selectedFilters[key].label: '';
    }

    if (type === "range") {
      return createRangeLabel(selectedFilters[key]);
    }

    if (type === "date") {
      return !!selectedFilters[key]
        ? format(selectedFilters[key].endDate, "dd/MM/yyyy")
        : undefined;
    }
  };

  const renderOpenedView = () => {
    const { type, key, label } = filters[openFilter];
    const optionsMap = {
      [filters.lineaProducto.key]: lineaProducto,
      [filters.tipoMovimiento.key]: tipoMovimiento,
      [filters.eds.key]: eds,
    };
    const props = {
      itemTitle: label,
      closeView: closeView,
      select: selectFilter(filters[key].key),
    };
    if (type === "direct") {
      props.options = optionsMap[key];
    }
    if (type === "date") {
      props.selectedYear = selectedYear;
      props.selectedMonth = selectedMonth;
      props.daysInMonth = daysInMonth;
      const selectedDate = selectedFilters[key];
      if (selectedDate) {
        props.value = [selectedDate.startDate, selectedDate.endDate];
      }
    }

    const Component = viewsMap[type];

    return <Component {...props} />;
  };

  return (
    <CartolaTableMobileOptionsModal
      title="Seleccionar Filtro"
      closeModal={closeModal}
      cancel={hasFilters ? cancelFilters : closeModal}
      confirm={confirm}
      confirmLabel="Aplicar Filtros"
      disabledConfirm={!hasFilters}
      cancelLabel={hasFilters ? "Borrar Filtros" : "Cancelar"}
    >
      {Object.values(filters).map(({ label, key, type }) => {
        return (
          <MobileFiltersChoice
            key={key}
            itemName={label}
            onClick={() => setOpenFilter(key)}
            value={createValueForChoice(type, key)}
          />
        );
      })}
      {!!openFilter && renderOpenedView()}
    </CartolaTableMobileOptionsModal>
  );
};
