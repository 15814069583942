import React, { useContext, useRef } from "react";
import {
  parseBudat,
  getLocaleStringNumber,
} from "../utils/utils.js";
import { ViewPortContext } from "../../App";
import { displayLoader, hideLoader, scrollTo } from "../utils/windows.js";

const SelectedTransactions = ({
  selectedTransactions,
  setStep,
  totalSaldo,
  isCompensar,
  rutConcesionario,
  setPropuesta,
  token,
  setError,
  closeStepper,
  setFechaPago,
  isArcoprime,
  selectedEdsArcoprime,
  setDisplayCompensacion,
  totalAPagar,
  callServiceHandler,
  displayCustomError
}) => {
  const isDesktopRef = useRef(window.innerWidth > process.env.REACT_APP_MOBILE_BREAK_POINT);
  //const { bottomCoordinate } = useContext(ViewPortContext);
  const bottomCoordinate = 0;

  async function compensar() {
    const documentos = [];

    selectedTransactions.forEach(function (transaction) {
      const newDoc = {
        DOCSAP: transaction.DOCSAP,
        POSICION: transaction.POSICION,
        EJERCICIO: transaction.EJERCICIO,
      };

      documentos.push(newDoc);
    });

    const debugData={
      selectedTransactions: selectedTransactions,
      totalSaldo: totalSaldo,
      tipo: "Compensar",
      isArcoprime: isArcoprime,
      selectedEdsArcoprime: selectedEdsArcoprime,
      totalAPagar: totalAPagar
  }

    let data = {};

    if(isArcoprime){
      data = {
        id_eds: selectedEdsArcoprime.value,
        DOCUMENTOS: documentos,
        calculo:debugData
      };
    }else{
      data = {
        rut_concesionario: rutConcesionario,
        DOCUMENTOS: documentos,
        calculo:debugData
      };
    }

    const url = process.env.REACT_APP_BASE_API + "/cuenta-corriente/crear-compensacion";

    let result = await callServiceHandler(url, "POST", JSON.stringify(data))
    if(result !== "error"){
      if (result.data.GRUPO_PPTA !== "") {
        setPropuesta(result.data.GRUPO_PPTA);
        const url2 = process.env.REACT_APP_BASE_API +
        "/cuenta-corriente/obtener-historial-pagos?rut_concesionario=" + rutConcesionario +
        "&grupo_ppta=" + result.data.GRUPO_PPTA

        let fecha
        let result2 = await callServiceHandler(url2, "GET")
        if(result2 !== "error"){
          fecha = parseBudat(result2.data.PROPUESTAS_PAGO[0].FECHA);
          fecha = fecha + " - " + result2.data.PROPUESTAS_PAGO[0].HORA;
        }
        setFechaPago(fecha);
        setStep(2);
        scrollTo(0,0);
      } else {
        displayCustomError("Error", "Se ha producido un error: " + result.data.MENSAJE, 'error')
        setDisplayCompensacion(false);
      }
    }
    else{
      setError(true);
      setStep(2);
      setDisplayCompensacion(false);
    }
  }

  return (
    <div className="cheque-en-linea transactions">
      <table className="abonar-en-banco__table">
        <thead>
          <tr>
            <th className="hide-in-mobile">Fecha&nbsp;Vencimiento</th>
            <th className="border-top-left-radius border-bottom-left-radius">Tipo de movimiento</th>
            <th className="hide-in-mobile">Nº Documento</th>
            <th className="hide-in-mobile">Descuento&nbsp;Pronto&nbsp;Pago&nbsp;(%)</th>
            <th className="align-right">Valor</th>
          </tr>
        </thead>

        <tr className="separator-table-row">
          <th colSpan={isDesktopRef.current? 4:1} />
        </tr>
        <tbody>
          {selectedTransactions.map((mov, key) => (
            <>
              <tr key={key} className="data">
                <td className="align-left hide-in-mobile">
                  {parseBudat(mov.FECHA_VENCIMIENTO)}
                </td>
                <td 
                  className={`
                    align-left 
                    ${key===0? 'border-top-left-radius': ''} 
                    ${key===(selectedTransactions.length - 1)? 'border-bottom-left-radius': ''}
                  `}
                >
                  {mov.CARGO > 0 ? "Cargo" : "Abono"}
                </td>
                <td className="align-left hide-in-mobile">{mov.NUMERO_DOCUMENTO}</td>
                <td className="align-left hide-in-mobile">{mov.DESCUENTO_PRONTO_PAGO}</td>
                <td
                  className={`align-right ${mov.CARGO > 0 ? "negative" : "green"}`}
                >
                  $
                  {mov.CARGO > 0
                    ? getLocaleStringNumber(mov.CARGO)
                    : getLocaleStringNumber(mov.ABONO)}
                </td>
              </tr>
              {selectedTransactions.length - 1 !== key ? (
                <>
                  <tr className="data separator">
                    <td className="trseparator align-left" colSpan={isDesktopRef.current? 5:2}>
                      <div className="line-sep"></div>
                    </td>
                  </tr>
                </>
              ) : (
                ""
              )}
            </>
          ))}
        </tbody>
        <tr className="separator-table-row">
          <th colSpan="4" />
        </tr>
        <tfoot>
          <tr>
            <td colSpan="4">Total</td>
            <td>$ {getLocaleStringNumber(totalSaldo)}</td>
          </tr>
        </tfoot>
      </table>

      <p className="abonar-en-banco__disclaimer">
        <span>Importante:</span> El cliente ha aceptado previamente los términos
        y condiciones de uso del Sitio Internet para pagos Copec S.A..
      </p>
      <hr />
      <div
        className="abonar-en-banco__step-actions"
        style={{ bottom: bottomCoordinate }}
      >
        <button onClick={closeStepper} className="main-button hallow">
          Cancelar
        </button>
        {!isCompensar && (
          <button className="main-button" onClick={() => setStep(2)}>
            Confirmar
          </button>
        )}
        {isCompensar && (
          <button className="main-button" onClick={compensar}>
            Confirmar
          </button>
        )}
      </div>
    </div>
  );
};

export default SelectedTransactions;
