import React from 'react';
import errorICon from "../../assets/img/error-icon.png";

const ErrorJudicial = () => {
    return (
        <div className="terminos">
            <div className="terminos-error">
              <img src={errorICon} alt="" />
              <h3>Importante</h3>
              <p>Deudor tiene documentos en cartera judicial</p>
              <p>Por favor, contacte a su Ejecutivo Administrativo</p>
            </div>
        </div>
    );
}

export default ErrorJudicial;
