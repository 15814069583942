import React, { useEffect, useRef, useState } from "react";
import reactSelectCjsDev from "react-select/dist/react-select.cjs.dev";
import carrot from "../assets/img/caret-down-blue.svg";
import arrow from "../assets/img/Flecha_blue.svg";
import CustomModal from "./CustomModal";

function formatNumber(number) {
  return `0${number}`.slice(-2);
}

const Timepicker = ({
  className = "",
  pickerPosition = "bottom",
  value = "",
  placeholder = "Seleccionar",
  disabled = false,
  onSelect = () => {},
  validate = null,
  fechaMedicion = null,
  setHoraMedicion,
  ultimaFluctuacionDate,
}) => {
  const initialValues = value.split(":");
  const initialHours = initialValues[0] || 0;
  const initialMinutes = initialValues[1] || 0;
  const [hour, setHour] = useState(Number(initialHours));
  const [minutes, setMinutes] = useState(Number(initialMinutes));
  const [selectedValue, setSelectedValue] = useState(value);
  const [pickerOpen, setPickerOpen] = useState(false);
  const rootRef = useRef();
  const [displayHoraModal, setDisplayHoraModal] = useState(false)
  const nowDate = new Date();

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  function increaseMinutes() {
    setMinutes((prevMinutes) => {
      if (prevMinutes === 59) {
        return 0;
      } else {
        return prevMinutes + 1;
      }
    });
  }
  function decreaseMinutes() {
    setMinutes((prevMinutes) => {
      if (prevMinutes === 0) {
        return 59;
      } else {
        return prevMinutes - 1;
      }
    });
  }
  function increaseHours() {
    setHour((prevHour) => {
      if (prevHour === 23) {
        return 0;
      } else {
        return prevHour + 1;
      }
    });
  }
  function decreaseHours() {
    setHour((prevHours) => {
      if (prevHours === 0) {
        return 23;
      } else {
        return prevHours - 1;
      }
    });
  }

  // close modal on click outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (rootRef.current && !rootRef.current.contains(event.target)) {
        setPickerOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [rootRef]);

  function closeModal(){
    setDisplayHoraModal(false)
  }

  function validateTime(){
    let validated = true;

    const medicionDate=new Date(fechaMedicion.getFullYear(), fechaMedicion.getMonth(), fechaMedicion.getDate(), hour, minutes)

    if(ultimaFluctuacionDate){
      if(medicionDate>nowDate || medicionDate<ultimaFluctuacionDate){
        validated=false;
      }
    }else{
      if(medicionDate>nowDate){
        validated=false;
      }
    }

    return validated;
  }

  useEffect(() => {
    if(fechaMedicion){
      if(!validateTime()){
        setSelectedValue("");
        setHoraMedicion("");
      }
    }
  }, [fechaMedicion]);

  return (
    <div className={`timepicker ${className}`} ref={rootRef}>
      <input
        type="text"
        className="timepicker__input"
        value={selectedValue}
        readOnly
        onClick={() => setPickerOpen((open) => !open)}
        placeholder={placeholder}
        disabled={disabled}
      />
      {pickerOpen && (
        <div
          className={`timepicker__selector ${
            pickerPosition === "top" ? "top-picker" : ""
          }`}
        >
          <p className="timepicker__selector__title">Seleccionar</p>
          <hr />
          <div className="timepicker__selector__toggles">
            <div className="timepicker__selector__toggle">
              <button
                className="timepicker__selector__btn increase-btn"
                onClick={increaseHours}
              >
                <img src={arrow} alt="increase" />
              </button>
              <p className="timepicker__selector__toggle-value">
                {formatNumber(hour)}
              </p>
              <button
                className="timepicker__selector__btn decrease-btn"
                onClick={decreaseHours}
              >
                <img src={arrow} alt="decrease" />
              </button>
            </div>
            <p className="timepicker__selector__separator">:</p>
            <div className="timepicker__selector__toggle">
              <button
                className="timepicker__selector__btn increase-btn"
                onClick={increaseMinutes}
              >
                <img src={arrow} alt="increase" />
              </button>
              <p className="timepicker__selector__toggle-value">
                {formatNumber(minutes)}
              </p>
              <button
                className="timepicker__selector__btn decrease-btn"
                onClick={decreaseMinutes}
              >
                <img src={arrow} alt="decrease" />
              </button>
            </div>
          </div>
          <button
            className="main-button"
            onClick={() => {
              if(validate){
                if(validateTime()){
                  const value = `${formatNumber(hour)}:${formatNumber(minutes)}`;
                  setSelectedValue(value);
                  onSelect(value);
                  setPickerOpen(false);
                }else{
                  setPickerOpen(false);
                  setDisplayHoraModal(true);
                }
              }else{
                const value = `${formatNumber(hour)}:${formatNumber(minutes)}`;
                setSelectedValue(value);
                onSelect(value);
                setPickerOpen(false);
              }
            }}
          >
            Aceptar
          </button>
        </div>
      )}
      <img src={carrot} alt="" className="timepicker__carrot" />
      <CustomModal
        displayModal = { displayHoraModal }
        closeModal = { closeModal }
        acceptFunc = { closeModal }
        acceptText = "Aceptar"
        modalTitle = "Error"
        modalText = "La fecha u hora escogidas están fuera de rango"
        modalIcon = "error"
      />
    </div>
  );
};
export default Timepicker;
