
export const modalStyles = {
  content: {
    width: 350,
    transform: "translate(-50%,-50%)",
    padding: "38px 20px",
    left: "50%",
    top: "50%",
    right: "auto",
    bottom: "auto",
    boxSizing: "border-box",
    borderRadius: 9
  },
};

export const customModalStyles = (top) => {
  return {
    content: {
      position: 'absolute',
      width: '350px',
      transform: "translateX(-50%)",
      padding: "38px 20px",
      left: "50%",
      top: top,
      bottom: 'auto',
      right: "auto",
      boxSizing: "border-box",
      borderRadius: 9
    }
  }
};

export const docModalStylesNew = (top) => {
  const c = {content: {
    transform: "translateX(-50%)",
    //fontSmoothing: "subpixel-antialiased",
    padding: "10px",
    margin: "0 auto",
    left: "50%",
    top: top,
    right: "auto",
    bottom: "auto",
    boxSizing: "border-box",
    borderRadius: 9,
    position: "fixed"
  }}

  return c
}

export const docModalStyles = (top) => {
  const c = {content: {
    transform: "translate(-50%,-50%)",
    //fontSmoothing: "subpixel-antialiased",
    padding: "10px",
    margin: "0 auto",
    left: "50%",
    top: top,
    right: "auto",
    bottom: "auto",
    boxSizing: "border-box",
    borderRadius: 9,
    position: "fixed"
  }}

  return c
}

export const customStylesCartola = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    minHeight: 35,
    border: "none",
    width: state.selectProps.width,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "none",
    color: "#003965",
    background: "none",
    padding: "12px 15px",
    fontSize: "12px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
        background: "#f1f5f8"
      }
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "-1px",
    border: "1px solid #809cb2",
    boxShadow: "none",
    boxSizing: "content-box",
    maxMenuHeight: "100px",
    width: state.selectProps.width,

  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "normal",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    background: "white",
    fontSize: "12px",
    fontWeight: "500",
    color: "#003965",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    background: "white",
    display: "none",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "500",
    fontSize: "12px",
  }),
};

export const customStylesCartolaTipoMovimiento = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    minHeight: 35,
    border: "none",
    width: '302px'
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "none",
    color: "#003965",
    background: "none",
    padding: "12px 15px",
    fontSize: "12px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
        background: "#f1f5f8"
      }
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "-1px",
    border: "1px solid #809cb2",
    boxShadow: "none",
    boxSizing: "content-box",
    maxMenuHeight: "100px",
    width: '302px',

  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "normal",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    background: "white",
    fontSize: "12px",
    fontWeight: "500",
    color: "#003965",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    background: "white",
    display: "none",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "500",
    fontSize: "12px",
  }),
};

export const customStylesCartolaTipoMovimientoPagos = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    minHeight: 35,
    border: "none",
    width: '240px'
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "none",
    color: "#003965",
    background: "none",
    padding: "12px 15px",
    fontSize: "12px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
        background: "#f1f5f8"
      }
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "-1px",
    border: "1px solid #809cb2",
    boxShadow: "none",
    boxSizing: "content-box",
    maxMenuHeight: "100px",
    width: '223px',

  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "normal",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    background: "white",
    fontSize: "12px",
    fontWeight: "500",
    color: "#003965",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    background: "white",
    display: "none",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "500",
    fontSize: "12px",
  }),
};

export const customStylesCierreDiario = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
    border: "1px solid #7c97ab",
    width: state.selectProps.width,
    minHeight: "0",
    height: "34px",
    paddingTop: "2px"
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "none",
    color: "#003965",
    background: "none",
    padding: "12px 15px",
    fontSize: "12px",
    fontWeight: "500",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
        background: "#f1f5f8"
      }
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: "-1px",
    border: "1px solid #809cb2",
    boxShadow: "none",
    boxSizing: "content-box",
    maxMenuHeight: "100px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "normal",
    marginTop: "-3px"
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    background: "white",
    fontSize: "12px",
    fontWeight: "500",
    color: "#003965",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    marginTop: "-5px"
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    background: "white",
    display: "none",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#003965",
    fontWeight: "500",
    fontSize: "12px",
  }),
};