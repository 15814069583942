import React,{ useState } from "react";
import { hideGreyBackground, showGreyBackground } from "../utils/windows";
import checkgreen from "../../assets/img/Check_green.svg";
import CustomModal from "../CustomModal";

const StepContrato = (props) => {

  const [displayModal, setDisplayModal] = useState(false);

  const modalStyles = {
    content: {
      width: 350,
      transform: "translate(-50%,-50%)",
      padding: "38px 20px",
      left: "50%",
      top: "50%",
      right: "auto",
      bottom: "auto",
      boxSizing: "border-box",
      borderRadius: 9,
    },
  };

  function closeModal(){
    setDisplayModal(false);
    hideGreyBackground();
    props.setDisplayTerminos(false);
    props.getMovimientos(props.newToken);
  }

  function aceptarModal(){
    setDisplayModal(false);
    hideGreyBackground();
    props.setDisplayTerminos(false);
    props.getMovimientos(props.newToken);
  }

  async function aceptarContrato(){
    let url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/aceptar-contrato?kunnr=" +
      props.kunnr+"&aceptar=1";

    let result = await props.callServiceHandler(url, "GET")
    if(result !== "error"){
      props.setDisplayTerminos(false);
      props.getMovimientos(props.newToken);
    }
  };

  const masTarde = () => {
    props.setDisplayTerminos(false);
    props.getMovimientos(props.newToken);
  };

  async function rechazarContrato(){
    let url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/aceptar-contrato?kunnr=" +
      props.kunnr+"&aceptar=0";

    let result = await props.callServiceHandler(url, "GET")
    if(result !== "error"){
      setDisplayModal(true);
      showGreyBackground();
    }
  };

  return (
    <>
      <div className="terminos">
        <div className="title">Contrato de Concesión</div>
        <div className="bajada">Anexo WEB</div>
        <hr className="title-separator-line" />

        <p>
          COPEC celebró con la concesionaria, un contrato de Concesión o
          Licencia, Arrendamiento y Otras Estipulaciones, mediante el cual le
          otorgó al concesionario la concesión o la franquicia de licencia,
          consistentes en el uso que podrá efectuar de la marca comercial Copec,
          de los distintivos de esta marca, de la publicidad, promoción,
          estudios de asesoría técnica para que los aproveche en la instalación
          expendedora.
        </p>

        <p>
          Además se han suscrito entre las partes los contratos de Comodato,
          Consignación o Depósito y Mandato; convenios accesorios, contratos de
          arriendo y de servicios de mantención calibrador de aire electrónico;
          convenio para compra en estaciones de Servicios con tarjetas de
          crédito Presto, Ripley, Cencosud, etc. Por lo anterior, se generan
          diversas obligaciones del concesionario con Copec, las cuales por
          regla general se solucionan mediante el pago que el concesionario
          realiza de determinada obligación.
        </p>

        <p>
          No obstante lo anterior, en ocasiones se realizan abonos a
          obligaciones pendientes de pago, sin que el concesionario especifique
          la obligación que desea extinguir o pagar, por lo que las partes
          acuerdan las siguientes normas de imputación del pago:
        </p>

        <p>
          El concesionario autoriza expresamente a Copec la imputación
          automática de los abonos pendientes de pago producidos por cualquier
          transacción que exista entre ellas, y derivadas del contrato antes
          señalado, y que sea producto de un pago no especificado o bien, que
          ellas resulten de abonos efectuados por la venta mediante tarjetas
          comerciales, o provengan de la emisión de notas de crédito por
          concepto de descuentos o normalizaciones y de saldos de pagos
          parciales.
        </p>

        <p>
          Esta imputación automática, se hará efectiva dos veces al mes siendo
          la ultima aplicación efectuada antes del cierre de mes, si dentro de
          dicho plazo el concesionario o consignatario no imputa el abono a una
          obligación determinada.
        </p>

        <p>
          La imputación automática se aplicará primeramente a los documentos que
          tengan notas de créditos emitidas y que estas hagan referencia a un
          documento específico no compensado. En el caso que el documento este
          compensado y les quede un saldo por aplicar, exista un abono pendiente
          de case o las notas de crédito no hagan referencia a un documento, se
          aplicara la regla general que se especifica a continuación:
        </p>

        <p>La imputación automática se aplicará a la obligación más antigua.</p>

        <p>
          Si existieren facturas vencidas por conceptos de combustibles, guías
          de despachos de consignación, facturas de lubricantes u otros, la
          imputación automática se realizará en primer término a las facturas de
          combustibles, luego a las guías de despachos de consignación, facturas
          de lubricantes, facturas de obligaciones de otros orígenes, notas de
          debito y cuotas de pagares respectivamente, en orden descendentes
          desde la fecha de vencimiento más antigua.
        </p>

        <p>
          Nota: El abono aplicado automáticamente se efectuará hasta la
          extinción del monto total de cada factura.
        </p>

        <div className="listadocorreos">
          <div className="title">Listado de correos por solicitante</div>
          <div className="lista">
            { props.emails.map((correo,key)=>props.emails.length-1===key
              ? (
                <>
                  <div className="item">
                    <div className="mail">
                      {correo.KUNNR} - {correo.SMTP_ADDR}
                    </div>
                    <img alt="correoimg" src={checkgreen}/>
                  </div>
                </>
              ) : (
                <>
                  <div className="item">
                    <div className="mail">
                      {correo.KUNNR} - {correo.SMTP_ADDR}
                    </div>
                    <img alt="correoimg" src={checkgreen}/>
                  </div>
                  <hr/>
                </>
              )
            )}
          </div>
        </div>

        <hr className="content-separator-line" />
        <div className="terminos-buttons">
          <button onClick={rechazarContrato} className="main-button hallow">Rechazar</button>
          <button  onClick={() => masTarde()} className="main-button marginm hallow">Responder más tarde</button>
          <button
            onClick={aceptarContrato}
            className="main-button red"
          >
            Aceptar
          </button>
        </div>
      </div>

      <CustomModal
        displayModal={displayModal}
        closeModal={closeModal}
        acceptFunc={aceptarModal}
        cancelFunc={closeModal}
        modalTitle='Importante'
        modalText='Ha rechazado los términos y condiciones del Sitio Internet para pagos y/o Anexo Web, contacte a COPEC para resolver medios de pago.'
        modalIcon='error'
      />
    </>
  );
};

export default StepContrato;
