export const monthNames = [
  "ENERO",
  "FEBRERO",
  "MARZO",
  "ABRIL",
  "MAYO",
  "JUNIO",
  "JULIO",
  "AGOSTO",
  "SEPTIEMBRE",
  "OCTUBRE",
  "NOVIEMBRE",
  "DICIEMBRE",
];


export const monthNamesLower = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];

export function getPreviousMonth(thisMonth) {
  const monthArr = thisMonth.split(" ");
  const month = monthArr[0].toUpperCase();

  const index = monthNames.indexOf(month);

  if (index > 0)
    return (
      monthNames[index - 1][0] +
      monthNames[index - 1].toLowerCase().substring(1) +
      " " +
      monthArr[1]
    );

  const newYear = parseInt(monthArr[1]) - 1;
  return "Diciembre " + newYear.toString();
}

export function getAllPeriodos() {
  const allPeriodos = [];

  const date = new Date();
  let currentMonth = parseInt(date.getMonth());
  if (currentMonth < 0) currentMonth = 11;
  const currentYear = parseInt(date.getFullYear());

  for (let i = 0; i < 24; i++) {
    let monthIndex = currentMonth - i;
    let year = currentYear;

    if (monthIndex < -12) {
      monthIndex = 24 - (i - currentMonth);
      year -= 2;
    }

    if (monthIndex < 0) {
      monthIndex = 12 - (i - currentMonth);
      year--;
    }

    let monthValue = monthIndex + 1;
    if (monthValue < 10) monthValue = "0" + monthValue;

    const newPeriodo = {
      label:
        monthNames[monthIndex][0] +
        monthNames[monthIndex].toLowerCase().substring(1) +
        " " +
        year,
      value: monthValue + "%3B" + year,
    };

    allPeriodos.push(newPeriodo);
  }

  return allPeriodos;
}

export function getPeriodosSince(fromYear) {
  const allPeriodos = []

  const date = new Date()
  let currentMonth = parseInt(date.getMonth())
  const currentYear = parseInt(date.getFullYear())

  for(let y = fromYear; y <= currentYear; y++){
    for(let m = 0; m < 12; m++){
      if(y < currentYear || m <= currentMonth){
        let monthValue = m + 1;
        if (monthValue < 10) monthValue = "0" + monthValue

        const newPeriodo = {
          label: monthNamesLower[m] + " " + y,
          value: monthValue + "%3B" + y
        }

        allPeriodos.push(newPeriodo)
      }
    }
  }
  
  allPeriodos.reverse()
  return allPeriodos;
}

export function getUpdateDate(setFunction) {
  const date = new Date();
  const minutes =
    parseInt(date.getMinutes()) > 9
      ? date.getMinutes()
      : "0" + date.getMinutes();
  const hour =
    parseInt(date.getHours()) > 9 ? date.getHours() : "0" + date.getHours();
  const day =
    parseInt(date.getDate()) > 9 ? date.getDate() : "0" + date.getDate();
  let month = parseInt(date.getMonth() + 1);
  month = month > 9 ? month : "0" + month.toString();

  let update = hour + ":" + minutes + ", ";

  if(window.innerWidth > process.env.REACT_APP_MOBILE_BREAK_POINT){ //IF DESKTOP
    update += day + " DE " + monthNames[date.getMonth()] + " " + date.getFullYear();
  }
  else{ //IF MOBILE
    update += day + '/' + month + '/' + date.getFullYear();
  }
  setFunction(update);
}

// export function getPeriodosSinceYearAndMonth(sinceYear, sinceMonth) {

//   const meses = [
//     {
//       label: "Diciembre",
//       value: 12
//     },
//     {
//       label: "Noviembre",
//       value: 11
//     },
//     {
//       label: "Octubre",
//       value: 10
//     },
//     {
//       label: "Septiembre",
//       value: 9
//     },
//     {
//       label: "Agosto",
//       value: 8
//     },
//     {
//       label: "Julio",
//       value: 7
//     },
//     {
//       label: "Junio",
//       value: 6
//     },
//     {
//       label: "Mayo",
//       value: 5
//     },
//     {
//       label: "Abril",
//       value: 4
//     },
//     {
//       label: "Marzo",
//       value: 3
//     },
//     {
//       label: "Febrero",
//       value: 2
//     },
//     {
//       label: "Enero",
//       value: 1
//     }
//   ]

//   const date = new Date()
//   let currentMonth = parseInt(parseInt(date.getMonth()) + 1)
//   const currentYear = parseInt(date.getFullYear())

//   console.log("mesanio " + currentMonth+ " " + currentYear)

//   let allPeriodos = [];

//   for (let i = currentYear; i >= sinceYear; i--) {
//     if (i !== sinceYear) {
//       if (i !== currentYear) {
//         meses.map(mes=>{
//           if (mes.value < 10) mes.value = "0" + mes.value
//           return(
//             // arr.push({y:i, m:mes.label})
//             allPeriodos.push(
//               {
//                 label: `${mes.label} ${i}`,
//                 value: `${mes.value}%3B${i}`
//               }
//             )
//           )
//         })
//       }else{
//         meses.slice(meses.length - currentMonth).map(mes=>{
//           if (mes.value < 10) mes.value = "0" + mes.value
//           return(
//             allPeriodos.push(
//               {
//                 label: `${mes.label} ${i}`,
//                 value: `${mes.value}%3B${i}`
//               }
//             )
//           )
//         })
//       }
//     }else{
//       let newMeses = meses.filter(mes=>{
//         if (currentYear === sinceYear) {
//           return mes.value >= sinceMonth && mes.value <= currentMonth
//         }else{
//           return mes.value >= sinceMonth
//         }
//       })
//       console.log("new meses", newMeses)
//       newMeses.map(mes=>{
//         if (mes.value < 10) mes.value = "0" + mes.value
//         return(
//           allPeriodos.push(
//             {
//               label: `${mes.label} ${i}`,
//               value: `${mes.value}%3B${i}`
//             }
//           )
//         )
//       })
//     }
//   }

//   console.log("Arreglo nuevecito", allPeriodos)
  
//   return allPeriodos;
// }

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getPeriodosSinceYearAndMonth(year, month) {
  const currentDate = new Date();
  const periods = [];

  // Create a new date starting from the provided year and month
  const startDate = new Date(year, month - 1);

  // Iterate over each month until the current date
  while (startDate <= currentDate) {
    const monthLabel = capitalizeFirstLetter(startDate.toLocaleString('es-ES', { month: 'long' }));
    const label = `${monthLabel} ${startDate.getFullYear()}`;
    const value = `${(startDate.getMonth() + 1).toString().padStart(2, '0')}%3B${startDate.getFullYear()}`;

    periods.push({ label, value });

    // Move to the next month
    startDate.setMonth(startDate.getMonth() + 1);
  }

  return periods.reverse();
}

export function validarRut(rut, codigo){
  const cadena = rut.toString();
    const arreglo = cadena.split("");
    const arregloInvertido = arreglo.reverse();
    const cadenaInvertida = arregloInvertido.join("");
    const rutInvertido = cadenaInvertida;

    // console.log("rutn invertido", rutInvertido)

    let firstSuma = 0;
    let factor = 2;
    for (let i = 0; i < rutInvertido.length; i++) {
      // console.log(`Lo que se hara ${rutInvertido[i]} x ${factor}`)
      firstSuma = firstSuma + parseInt(rutInvertido[i]) * factor;
      factor = factor === 7 ? 2 : factor+1; 
    }
    
    // console.log(firstSuma)

    let firstDivision = Math.floor(firstSuma / 11);

    // console.log(firstDivision)

    let firstMulti = firstDivision * 11

    // console.log(firstMulti)

    let firsResta = firstSuma - firstMulti

    // console.log("firs-res", firsResta)

    let secondResta = (11 - firsResta).toString()

    // console.log("segunda", secondResta)
    
    codigo = codigo.toString()

    // console.log("first codigo", codigo)

    if (codigo === "0") {
      codigo = "11"
    }

    if (codigo === "k" || codigo === "K") {
      codigo = "10"
    }

    // console.log("segundo codigo", codigo)

    if (codigo === secondResta) {
      // console.log("CORRECTO")
      // setRutVerificado(true)
      // setDisplayRUTErroneoMessage(false)
      return true
    }else{
      return false
      // console.log("INCORRECTO")
      // setRutVerificado(false)
      // setDisplayRUTErroneoMessage(true)
      // setIsRutGiven(false)
      // setDisplayConsultTable(false)
      // setDisplayMainTable(false)
    }
}