import React, { useState, useEffect,useRef } from "react";
import { getAlertIconVentas } from "../utils/get";
import { getTanquesSifoneadosFluctuacionesCierreMensual } from "../utils/tanquesSifoneados";

const FluctuacionesTable = ({fluctuaciones, openJustificacion, estadoCierre}) => {

  return (
    <div>
      <div className="table-title">FLUCTUACIONES</div>
      <table className="table-with-material-title fluctuaciones">
        <div className="table-header">
          <div className="legend">
            <div className="material">
              <div className="rect"></div>
              <div className="text">Material Consignado</div>
            </div>
            <div className="material">
              <div className="rect concesionado"></div>
              <div className="text">Material Concesionado</div>
            </div>
          </div>
        </div>
        <thead>
          <tr>
            <th className="no-padding-left" colSpan={2}>
              <div className="th-data width-80">
                <div className="th-text">Material</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">Med. Física<br />Inicial</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">(Lts)<br />Entradas</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">(Lts)<br />Salidas</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">(Lts)<br />Fluct. Doc</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">(Lts)<br />Inv. Teórico</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">Med. Física<br />Proyectada</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">(Lts) Fluct.<br />Calculada</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">(%) Fluct.<br />Calculada</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">Fluct.<br />Confirmada</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">(%) Fluct.<br />Confirmada</div>
              </div>
            </th>
            <th>
              <div className="th-data width-60">
                <div className="th-text">Alerta</div>
              </div>
            </th>
            <th>
              <div className="th-data width-60">
                <div className="th-text">Justifi...</div>
              </div>
            </th>
          </tr>
        </thead>
        <tr className="separator-table-row">
          <th colSpan="100%" />
        </tr>
        <tbody>
          { fluctuaciones && fluctuaciones?.map((tanque, key) =>
            getTanquesSifoneadosFluctuacionesCierreMensual(tanque, key, openJustificacion, estadoCierre)
          )}
        </tbody>
      </table>
    </div>
  )
};

export default FluctuacionesTable;
