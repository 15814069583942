import React, { useState } from "react";
import { getLocaleStringNumber, getLocaleStringRoundedInt, mockDataNewVentasTable } from "../utils/utils";
import { getMaterialClass, getMaterialMinititle } from "../utils/materiales";

import { getAlertIconVentas, getMaterialClassVentas, getMaterialIconNameVentas } from "../utils/get";
import { hideGreyBackground, parentRedirect } from "../utils/windows";
import download from "../../assets/img/descargar-arrow-white.svg";
import EditarIcon from "../../assets/img/EDITAR.svg";
import CustomModal from "../CustomModal";
import { useEffect } from "react";

const Ventas = (
  {ventas, 
    setStep, 
    selectedEds, 
    fechaCierre, 
    pagador, 
    callServiceHandler,
    disableVentas,
    isROImpersonate,
    selectedCierre,
    selectedEstadoCierre
  }) => {

    let ventas2 = mockDataNewVentasTable.ventas;

    let total2 = mockDataNewVentasTable.total

  const [selector, setSelector] = useState("litros");
  const [displayModal, setDisplayModal] = useState(false)

  function changeSelector() {
    const tselector = document.getElementById("table-selector");
    if(tselector.checked){
      tselector.checked = false;
      setSelector("litros");
    }else{
      tselector.checked = true;
      setSelector("pesos");
    }
  }

  function irARegularizacion(){
    const url = process.env.REACT_APP_REGULARIZACION_PATH+"?eds="+selectedEds.value+"&fecha="+fechaCierre;
    parentRedirect(url);
  }

  function checkRegularizadas(){
    const rojas = ventas?.detalle.filter((trx)=>trx.venta_propia_alerta==="ROJA" || trx.producto_copec_alerta==="ROJA");
    if(rojas?.length>0){
      return false
    }else{
      return true;
    }
  }

  async function confirmar(){
    //setStep(3)
    if(checkRegularizadas()){
      if(ventas.total.litros.documentados_menos_controlador!==0){
        setDisplayModal(true)
      }else{
        ingresarPaso()
      }
      
    }
  }

  async function ingresarPaso(){
    setDisplayModal(false)

      const data={
        ID_EDS:selectedEds.value,
        FECHA:fechaCierre,
        PASO:2,
        ID_PAGADOR:pagador,
        ventas:ventas
      }
      if(isROImpersonate) data.VISUALIZAR="SI";
  
      const url =
        process.env.REACT_APP_FLUCTUACIONES_API +
        "/fluctuaciones/cierre-diario-ingresar-paso";
  
      let response = await callServiceHandler(url,"POST",JSON.stringify(data));
      
      if(response!=="error"){
        setStep(3)
      }

  }

  function regularizar(){
    if(!checkRegularizadas()){
      irARegularizacion()
    }
  }

  function closeModal() {
    setDisplayModal(false)
    hideGreyBackground()
  }

  useEffect(() => {
    console.log("VENTAAAAS",ventas)
  }, [ventas]);

  function getMaterialTipo(material){
    if(parseInt(selectedEds.value)===60348){
      return "concesionado"
    }
    if(parseInt(selectedEds.value)===60403)
      if(material.material==="KEROSENE"){
        return "concesionado"
      }else{
        return "consignado"
      }

    // const nuevasEstaciones= []

    // nuevasEstaciones.forEach(estacion => {
    //   if(parseInt(selectedEds.value)===estacion)
    //     if(material.material==="KEROSENE"){
    //       return "concesionado"
    //     }else{
    //       return "consignado"
    //     }
    //   return ""
    // });
  }

  // let arr = [1,2,3]

  function noEnPiloto(){
    return !(parseInt(selectedEds.value)===60348 
    || parseInt(selectedEds.value)===60403
    || parseInt(selectedEds.value)===60097
    || parseInt(selectedEds.value)===60332
    || parseInt(selectedEds.value)===60336
    || parseInt(selectedEds.value)===60359
    || parseInt(selectedEds.value)===60838
    );
  }

  return (
    <div className="ventas-container">
      <table className="ventas">
        <div className="table-header">
          <div className="legend">
            <div className="material">
              <div className="rect"></div>
              <div className="text">Material consignado</div>
            </div>
            <div className="material">
              <div className="rect concesionado"></div>
              <div className="text">Material concesionado</div>
            </div>
          </div>{" "}
          <div className="selector">
            <div className="text">LITROS</div>
            <div className="switch" onClick={() => changeSelector()}>
              <input type="checkbox" id="table-selector" />
              <span className="slider round"></span>
            </div>
            <div className="text">PESOS</div>
          </div>
        </div>
        <thead>
          <tr>
            <th>Material</th>
            <th className="align-left"></th>
            <th className="align-right alert-container-cierre-diario">Controlador
              {selector==="pesos" && <>
                <div className="alert-tooltip">
                  <div className="content">
                    <b>Monto Controlador ($) : Monto Total ($) - Descuentos ($)</b> 
                  </div>
                </div>
              </>}
            </th>
            <th className="align-right">Documentados</th>
            <th className="align-right">Confirmados</th>
            <th className="align-right">
              Documentados <br />- Controlador
            </th>
            <th className="align-right">
              Confirmados <br />- Documentados
            </th>
            <th className="align-left">Alertas</th>
            <th className="cierre-diario-ventas-last-column"></th>
          </tr>
        </thead>
        <tr className="separator-table-row">
          <th colSpan="100%" />
        </tr>
        {ventas?.detalle.map((material)=>{
          return(<>
          <tbody>
            <tr className="data">
              <td rowSpan={3} className="container-cell align-left">
                <div className="container-cell-content">
                  <div className={`type ${material.tipo_material}`}></div>
                  <div className="material">
                    <div className={`small-camion-compartment ${getMaterialClassVentas(material.material)} cont2`}>
                      <p className={`small-camion-compartment__fuel-type-number${["GLP","GNC"].includes(getMaterialIconNameVentas(material.material))?" gas":""}`}>
                        {getMaterialIconNameVentas(material.material)}
                      </p>
                    </div>
                    <span>{material.material}</span>
                  </div>
                </div>
              </td>
              <td className="align-left small-padding-left">Venta Propia</td>
              <td className="align-right">{getLocaleStringNumber(Math.round(material.venta_propia[selector].controlador))}</td>
              <td className="align-right">{getLocaleStringNumber(Math.round(material.venta_propia[selector].documentados))}</td>
              <td className="align-right">{getLocaleStringNumber(Math.round(material.venta_propia[selector].confirmados))}</td>
              <td className="align-right">{getLocaleStringNumber(Math.round(material.venta_propia[selector].documentados_menos_controlador))}</td>
              <td className="align-right">{getLocaleStringNumber(Math.round(material.venta_propia[selector].confirmados_menos_documentados))}</td>
              <td className="align-right alert-container-cierre-diario">{getAlertIconVentas(material.venta_propia_alerta)}
                <div className="alert-tooltip">
                    <div className="content">
                    {material.venta_propia_alerta_desc}
                    </div>
                </div>
              </td>
              <td className="cierre-diario-ventas-last-column"></td>
            </tr>
            <tr className="data">
              <td className="align-left small-padding-left">Producto Copec</td>
              <td className="align-right">{getLocaleStringNumber(Math.round(material.producto_copec[selector].controlador))}</td>
              <td className="align-right">{getLocaleStringNumber(Math.round(material.producto_copec[selector].documentados))}</td>
              <td className="align-right">{getLocaleStringNumber(Math.round(material.producto_copec[selector].confirmados))}</td>
              <td className="align-right">{getLocaleStringNumber(Math.round(material.producto_copec[selector].documentados_menos_controlador))}</td>
              <td className="align-right">{getLocaleStringNumber(Math.round(material.producto_copec[selector].confirmados_menos_documentados))}</td>
              <td className="align-right alert-container-cierre-diario">{getAlertIconVentas(material.producto_copec_alerta)}
                <div className="alert-tooltip">
                    <div className="content">
                    {material.producto_copec_alerta_desc}
                    </div>
                </div>
              </td>
              <td className="cierre-diario-ventas-last-column"></td>
            </tr>
            <tr className="data">
              <td className="align-left small-padding-left">Total</td>
              <td className="align-right no-mr"><div className="pill">{getLocaleStringNumber(Math.round(material.total[selector].controlador))}</div></td>
              <td className="align-right no-mr"><div className="pill">{getLocaleStringNumber(Math.round(material.total[selector].documentados))}</div></td>
              <td className="align-right no-mr"><div className="pill">{getLocaleStringNumber(Math.round(material.total[selector].confirmados))}</div></td>
              <td className="align-right no-mr"><div className="pill">{getLocaleStringNumber(Math.round(material.total[selector].documentados_menos_controlador))}</div></td>
              <td className="align-right no-mr"><div className="pill">{getLocaleStringNumber(Math.round(material.total[selector].confirmados_menos_documentados))}</div></td>
              <td className="align-right">&nbsp;</td>
              <td className="cierre-diario-ventas-last-column"></td>
            </tr>
          </tbody>
          <tr className="separator-table-row">
              <th colSpan="100%" />
            </tr>
          </>)
        })}
        <tbody>
          <tr className="data">
            <td className="align-left total">
              TOTAL
            </td>
            <td className="align-left"></td>
            <td className="align-right">{getLocaleStringNumber(Math.round(ventas?.total[selector].controlador))}</td>
            <td className="align-right">{getLocaleStringNumber(Math.round(ventas?.total[selector].documentados))}</td>
            <td className="align-right">{getLocaleStringNumber(Math.round(ventas?.total[selector].confirmados))}</td>
            <td className="align-right">{getLocaleStringNumber(Math.round(ventas?.total[selector].documentados_menos_controlador))}</td>
            <td className="align-right">{getLocaleStringNumber(Math.round(ventas?.total[selector].confirmados_menos_documentados))}</td>
            <td className="align-right"></td>
            <td className="cierre-diario-ventas-last-column"></td>
          </tr>
        </tbody>
        
      </table>
      {/* <table className="table-with-material-title2">
        <div className="table-header">
          <div className="legend">
            <div className="material">
              <div className="rect"></div>
              <div className="text">Material Consignado</div>
            </div>
            <div className="material">
              <div className="rect concesionado"></div>
              <div className="text">Material Concesionado</div>
            </div>
          </div>
        </div>
        <thead>
          <tr>
            <th className="no-padding-left">
              <div className="th-data width-89 material">
                <div className="th-text">Material</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 material">
                <div className="th-text">Tipo de<br />Venta</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">Litros<br />Controlador</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">Litros<br />Document...</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">Litros<br />Confirmad...</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">(Lts) Docu…<br />- Controla...</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">(Lts) Confirm...<br />- Documen…</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">($)<br />Controlador</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">($)<br />Document...</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">($)<br />Confirmad...</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">($) Documen…<br />- Controla...</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">($) Confirm.<br />- Documen…</div>
              </div>
            </th>
            <th>
              <div className="th-data width-62 alerta">
                <div className="th-text">Alerta</div>
              </div>
            </th>
            <th>
              <div className="th-data width-62 ">
                <div className="th-text">Justifi...</div>
              </div>
            </th>
          </tr>
        </thead>
        <tr className="separator-table-row">
          <th colSpan="100%" />
        </tr>
        <tbody>
          {ventas2 ?
            <>
              {ventas2.length > 0 ? <>
                {
                  ventas2.map((venta, index) => (
                    <>
                      <tr>
                        <td className="def-tanque-container" rowSpan={2}>
                          <div className={`type consignado`}></div>
                          {venta.material}
                        </td>
                        <td className="align-left">Propia</td>
                        <td>{venta.venta_propia.litros_controlador}</td>
                        <td>{venta.venta_propia.litros_documentados}</td>
                        <td>{venta.venta_propia.litros_confirmados}</td>
                        <td>{venta.venta_propia.litros_documentados_menos_controlador}</td>
                        <td>{venta.venta_propia.litros_confirmados_menos_documentados}</td>
                        <td>{venta.venta_propia.pesos_controlador}</td>
                        <td>{venta.venta_propia.pesos_documentados}</td>
                        <td>{venta.venta_propia.pesos_confirmados}</td>
                        <td>{venta.venta_propia.pesos_documentados_menos_controlador}</td>
                        <td>{venta.venta_propia.pesos_documentados_menos_controlador}</td>
                        <td className="align-center alerta-container alert-container-cierre-mensual">{getAlertIconVentas("VERDE")}
                          <div className="alert-tooltip">
                            <div className="content">
                              {venta.producto_copec_alerta_desc}
                            </div>
                          </div>
                        </td>
                        <td className="align-center">
                            <img className="cierre-mensual-justificar-icon" src={EditarIcon} />
                        </td>
                      </tr>
                      <tr>
                        <td className="align-left">Prod. Copec</td>
                        <td>{venta.producto_copec.litros_controlador}</td>
                        <td>{venta.producto_copec.litros_documentados}</td>
                        <td>{venta.producto_copec.litros_confirmados}</td>
                        <td>{venta.producto_copec.litros_documentados_menos_controlador}</td>
                        <td>{venta.producto_copec.litros_confirmados_menos_documentados}</td>
                        <td>{venta.producto_copec.pesos_controlador}</td>
                        <td>{venta.producto_copec.pesos_documentados}</td>
                        <td>{venta.producto_copec.pesos_confirmados}</td>
                        <td>{venta.producto_copec.pesos_documentados_menos_controlador}</td>
                        <td>{venta.producto_copec.pesos_documentados_menos_controlador}</td>
                        <td className="align-center alerta-container alert-container-cierre-mensual">{getAlertIconVentas("VERDE")}
                          <div className="alert-tooltip">
                            <div className="content">
                              {venta.producto_copec_alerta_desc}
                            </div>
                          </div>
                        </td>
                        <td className="align-center">
                            <img className="cierre-mensual-justificar-icon" src={EditarIcon} />
                        </td>
                      </tr>
                      <tr>
                        <td className="def-tanque-container">
                          Total
                        </td>
                        <td className="total-border-top"></td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                              <span>
                                {venta.total.litros_controlador}
                              </span>
                          </div> 
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                              <span>
                                {venta.total.litros_documentados}
                              </span>
                          </div> 
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                              <span>
                                {venta.total.litros_confirmados}
                              </span>
                          </div> 
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                              <span>
                                {venta.total.pesos_documentados_menos_controlador}
                              </span>
                          </div> 
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                              <span>
                                {venta.total.litros_confirmados_menos_documentados}
                              </span>
                          </div> 
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                              <span>
                                {venta.total.pesos_controlador}
                              </span>
                          </div> 
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                              <span>
                                {venta.total.pesos_documentados}
                              </span>
                          </div> 
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                            <span>
                              {venta.total.pesos_confirmados}
                            </span>
                          </div>
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                            <span>
                              {venta.total.pesos_documentados_menos_controlador}
                            </span>
                          </div>
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                            <span>
                              {venta.total.pesos_confirmados_menos_documentados}
                            </span>
                          </div> 
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </>
                  ))
                }
              </> :
                <tr className="data">
                  <td className="align-left" colSpan="100%">
                    No se encontró información de ventas del cierre.
                  </td>
                </tr>}
            </> :
            <tr>
              <td className="align-left" colSpan="100%">
                Busca por EDS y por Periodo.
              </td>
            </tr>}

        </tbody>
        {ventas2 && ventas2.length > 0 && <>
          <tr className="separator-table-row">
            <th colSpan="100%" />
          </tr>
          <tr className="total-row align-center">
            <td>TOTAL</td>
            <td></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.litros_controlador)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.litros_documentados)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.litros_confirmados)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.litros_documentados_menos_controlador)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.litros_confirmados_menos_documentados)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.pesos_controlador)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.pesos_documentados)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.pesos_confirmados)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.pesos_documentados_menos_controlador)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.pesos_confirmados_menos_documentados)}</div></td>
            <td colSpan="2" />
          </tr>
        </>}

      </table> */}
      {!checkRegularizadas() && 
        <div className="important-message error">
          <span className="error-icon">!</span>
          <span className="text">Para confirmar las ventas todas las Transacciones (Litros/Pesos) deben estar regularizadas.</span>
        </div>
      }
      <hr className="ventas-separator"></hr>
      <div className="ventas-actions">
        <button className="main-button hallow" onClick={()=>setStep(1)}>
          Volver
        </button>
        <button className={`main-button ${checkRegularizadas()?"disabled":""}`} onClick={()=>regularizar()} disabled={checkRegularizadas()}>
          Regularizar ventas
        </button>
        <button className={`main-button ${checkRegularizadas()?"":"disabled"}`}  onClick={()=>confirmar()} disabled={!checkRegularizadas() || disableVentas || selectedEstadoCierre==="CERRADO"}>
          Confirmar
        </button>
      </div>
      {/* {noEnPiloto() && 
      <button onClick={()=>setStep(3)} style={{marginTop:10}} >SALTAR A PASO 3 SIN VALIDACIONES</button>} */}
      <CustomModal
        displayModal = {displayModal}
        closeModal = {closeModal}
        acceptFunc = {ingresarPaso}
        cancelFunc={closeModal}
        acceptText={"Sí, estoy seguro"}
        modalTitle = {"Importante"}
        modalText = {"Existen diferencias entre los litros controlador y los documentados. ¿Está seguro que desea confirmar?"}
        modalIcon = {"importante"}
      />
    </div>
  );
};

export default Ventas;
