import React, { useEffect } from 'react';
import { getUpdateDate } from '../utils/monthNames';
import { setInstalalaciones } from '../utils/get';
import HeaderData from '../HeaderData';
import { useState } from 'react';
import Select from "react-select";
import { SingleOption, customStylesTctTae } from "../utils/utils.js";
import EntradaDatos from './EntradaDatos';

const Prefactura = ({
  token,
  rutConcesionario,
  callServiceHandler,
  cabecera,
  instalaciones,
  isROImpersonate,
}) => {

  const [updateDate, setUpdateDate] = useState("");

  const [selectedEds, setSelectedEds] = useState();
  const [allEds, setAllEds] = useState([]);

  const [selectedTipoServicio, setSelectedTipoServicio] = useState();
  const [allTiposServicio, setAllTiposServicio] = useState();

  const [cargaDatos, setCargaDatos] = useState(false);

  const [step, setStep] = useState(1);
  const [selectedEstadoPrefactura, setSelectedEstadoPrefactura] = useState();

  const tiposDeServicio = [
    {
      value: "Lavamax",
      label: "Lavamax"
    },
    {
      value: "Punto",
      label: "Punto"
    },
    {
      value: "Lavado de Mascotas",
      label: "Lavado de Mascotas"
    },
  ]

  useEffect(() => {
    getUpdateDate(setUpdateDate)
    setAllTiposServicio(tiposDeServicio)
  }, []);

  useEffect(() => {
    if (allEds.length === 1){
      setSelectedEds(allEds[0]);
    }
  }, [allEds]);

  useEffect(() => {
    if (instalaciones !== "") {
      setInstalalaciones(instalaciones, setAllEds);
    }
  }, [instalaciones]);

  function changeEds(e) {
    if (e !== selectedEds) {
      setSelectedEds(e);
    }
  }

  function changeTipoServicio(e) {
    if (e !== selectedTipoServicio) {
      setSelectedTipoServicio(e);
    }
  }

  const cargarDatos = () => {
    setCargaDatos(true)
  }

  return (
    <div className='prefactura-main-container'>
      <div className="view-title">Prefactura</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Lavamax</div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandler={callServiceHandler}
        cabecera={cabecera}
        actualizable={true}
      />
      <hr/>
      {
        !cargaDatos &&
          <div className="prefactura-container" id='prefactura-container'>
            <div className="filters-container" id="filters-container">
              <div className="filter" id="filter-estacion-servicio">
                <div className="filter-title required-field" id="filter-title-estacion-servicio">
                  ESTACIÓN DE SERVICIO
                </div>
                <div className="filter-input" id="filter-input-estacion-servicio">
                  <Select
                    id="eds-select"
                    className="eds-select"
                    value={selectedEds}
                    onChange={(e) => changeEds(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allEds}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesTctTae}
                    width={"192px"}
                  />
                </div>
              </div>
              <div id="filter-tipo-servicio" className="filter">
                <div id="filter-title-tipo-servicio" className="filter-title required-field">
                  TIPO DE SERVICIO
                </div>
                <div id="filter-input-tipo-servicio" className="filter-input">
                  <Select
                    id="tipo-servicio-select"
                    className="tipo-servicio-select"
                    value={selectedTipoServicio}
                    onChange={(e) => changeTipoServicio(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allTiposServicio}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesTctTae}
                    width={"192px"}
                  />
                </div>
              </div>
            </div>
            <hr />
            <div id="button-area" className="button-area">
              <button disabled={!(selectedEds || selectedTipoServicio)} className='limpiar-button main-button hallow thin ' id='limpiar-button'>
                Limpiar
              </button>
              <button disabled={!(selectedEds && selectedTipoServicio)} onClick={cargarDatos}className='cargar-button main-button thin' id='cargar-button'>
                Cargar Datos
              </button>
            </div>
          </div>
      }
      {
        cargaDatos &&
        <EntradaDatos 
          step={step}
          setStep={setStep}
          selectedEstadoPrefactura={selectedEstadoPrefactura}
          setSelectedEstadoPrefactura={setSelectedEstadoPrefactura}
        />
      }
    </div>
  );
}

export default Prefactura;
