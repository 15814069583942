import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { getLocaleStringNumber } from "../utils/utils.js";
import { scrollTo } from "../utils/windows";
import { docModalStylesNew } from "../utils/styles.js";
import closeIcon from "../../assets/img/cross-icon.svg";
import { getDinamoDetalle } from "../utils/get.js";

const VolumenDinamoModal = (props) => {
  const displayModal = props.displayModal;
  const closeModal = props.closeModal;
  const distanceToTop = props.distanceToTop;
  const pedido = props.pedido;
  const [detalle, setDetalle] = useState([]);
  const [total, setTotal] = useState({});

  useEffect(() => {
    if (displayModal) {
      scrollTo(0, distanceToTop)
    }
  }, [displayModal]);

  useEffect(() => {
    if (pedido) {
      if (pedido.DETALLE) {
        setDetalle(getDinamoDetalle(pedido));

        const det=getDinamoDetalle(pedido);
        let solicitadoLitros=0;
        let planificadoLitros=0;
        let entregadoLitros=0;
        let solicitadoMonto=0;
        let planificadoMonto=0;
        let entregadoMonto=0;

        det.map((material)=>{
            solicitadoLitros+=material.SOLICITADO;
            planificadoLitros+=material.PLANIFICADO;
            entregadoLitros+=material.ENTREGADO;
            solicitadoMonto+=material.TOTAL_MATERIAL;
            planificadoMonto+=material.PRECIO*material.PLANIFICADO;
            entregadoMonto+=material.TOTAL_MATERIAL_ENTREGADO;
        })

        setTotal({SOLICITADO_LITROS:solicitadoLitros,SOLICITADO_MONTO:solicitadoMonto,ENTREGADO_LITROS:entregadoLitros,ENTREGADO_MONTO:entregadoMonto,PLANIFICADO_LITROS:planificadoLitros,PLANIFICADO_MONTO:planificadoMonto});

      }
    }
  }, [pedido]);

  const showContent = () => {
    return (
      <>
        <div className={`documento-info-modal`}>
          <div className="title">Detalle Volúmenes </div>
          <div className="subtitle">
            {pedido.ID_PEDIDO} - {pedido.TIPO_PEDIDO}
          </div>
          <div className="content">
            <table>
              <thead>
                <tr>
                  <th>Material</th>
                  <th className="align-right">Solicitado</th>
                  <th className="align-right">Planificado</th>
                  <th className="align-right">Entregado</th>
                </tr>
              </thead>
              <tr className="separator-table-row">
                <th colSpan="100%" />
              </tr>
              <tbody>
                {detalle?.map((obj, key) => {
                  return (
                    <>
                      <tr className="data" key={key}>
                        <td className="align-left">{obj.NOMBRE}</td>
                        <td className="align-right">
                            <div>{obj.SOLICITADO/1000+" m3"}</div>
                            <div>{"$ "+getLocaleStringNumber(obj.TOTAL_MATERIAL,true)}</div>
                        </td>
                        <td className="align-right">
                            <div>{obj.PLANIFICADO/1000+" m3"}</div>
                            {/* <div>{"$ "+getLocaleStringNumber(obj.PLANIFICADO*obj.PRECIO,true)}</div> */}
                            <div>&nbsp;</div>
                        </td>
                        <td className="align-right">
                            <div>{obj.ENTREGADO/1000+" m3"}</div>
                            <div>{"$ "+getLocaleStringNumber(obj.TOTAL_MATERIAL_ENTREGADO,true)}</div>
                        </td>
                      </tr>
                      {detalle.length-1!==key && <>
                        <tr>
                        <td colSpan="100%" className="tdsep">
                        <hr></hr>
                        </td>
                      </tr>
                      </>}
                    </>
                  );
                })}
              </tbody>
              <tr className="separator-table-row">
                <th colSpan="100%" />
              </tr>
              <tbody>
                <tr className="data">
                  <td className="align-left">
                    TOTAL
                  </td>
                  <td className="align-right">
                    <div>{total.SOLICITADO_LITROS/1000+" m3"}</div>
                    <div>{"$ "+getLocaleStringNumber(total.SOLICITADO_MONTO?total.SOLICITADO_MONTO:0,true)} </div>
                  </td>
                  <td className="align-right">
                    <div>{total.PLANIFICADO_LITROS/1000+" m3"}</div>
                    {/* <div>{"$ "+getLocaleStringNumber(total.PLANIFICADO_MONTO?total.PLANIFICADO_MONTO:0,true)} </div> */}
                    <div>&nbsp;</div>
                  </td>
                  <td className="align-right">
                    <div>{total.ENTREGADO_LITROS/1000+" m3"}</div>
                    <div>{"$ "+getLocaleStringNumber(total.ENTREGADO_MONTO?total.ENTREGADO_MONTO:0,true)} </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <Modal
        isOpen={displayModal}
        onRequestClose={closeModal}
        style={docModalStylesNew(distanceToTop - 100)}
      >
        <button className="modal__close-btn" onClick={closeModal}>
          <img src={closeIcon} alt="close modal" />
        </button>
        {showContent()}
      </Modal>
    </div>
  );
};

export default VolumenDinamoModal;
