import React, { useState, useEffect, useRef } from "react";
import { getAlertIconVentas } from "../utils/get";
import EditarIcon from "../../assets/img/EDITAR.svg";
import VerIcon from "../../assets/img/VER.svg"
import { getLocaleStringRoundedInt, mockDataNewVentasTable } from "../utils/utils";

const VentasTable = ({ openJustificacion, ventas, total }) => {

  let ventas2 = mockDataNewVentasTable.ventas;

  let total2 = mockDataNewVentasTable.total

  useEffect(() => {
    console.log("ven", ventas2)
  }, []);

  return (
    <div>
      <div className="table-title">VENTAS</div>
      <table className="table-with-material-title">
        <div className="table-header">
          <div className="legend">
            <div className="material">
              <div className="rect"></div>
              <div className="text">Material Consignado</div>
            </div>
            <div className="material">
              <div className="rect concesionado"></div>
              <div className="text">Material Concesionado</div>
            </div>
          </div>
        </div>
        <thead>
          <tr>
            <th className="no-padding-left">
              <div className="th-data width-80">
                <div className="th-text">Material</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">Litros<br />Controlador</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">Litros<br />Documentados</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">Litros<br />Confirmados</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">(Lts) Docume…<br />- Controlador</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">(Lts) Confirm.<br />- Document…</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">($)<br />Controlador</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">($)<br />Documentado</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">($)<br />Confirmados</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">($) Documen…<br />- Controlador</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-100">
                <div className="th-text">($) Confirm.<br />- Documenta…</div>
              </div>
            </th>
            <th>
              <div className="th-data width-60">
                <div className="th-text">Alerta</div>
              </div>
            </th>
            <th>
              <div className="th-data width-60">
                <div className="th-text">Justifi...</div>
              </div>
            </th>
          </tr>
        </thead>
        <tr className="separator-table-row">
          <th colSpan="100%" />
        </tr>
        <tbody>
          {ventas ?
            <>
              {ventas.length > 0 ? <>
                {
                  ventas.map((material, index) => (
                    <tr>
                      <td className="def-tanque-container">
                        <div className={`type ${material.FORMA_COMERCIALIZACION}`}></div>
                        {material.MATERIAL === "KEROSENE" ? "KERO" : material.MATERIAL}
                      </td>
                      <td>{getLocaleStringRoundedInt(material.LITROS_CONTROLADOR)}</td>
                      <td>{getLocaleStringRoundedInt(material.LITROS_DOCUMENTADOS)}</td>
                      <td>{getLocaleStringRoundedInt(material.LITROS_CONFIRMADOS)}</td>
                      <td>{getLocaleStringRoundedInt(material.LITROS_DOCS_MENOS_CTRL)}</td>
                      <td>{getLocaleStringRoundedInt(material.LITROS_CONF_MENOS_DOCS)}</td>
                      <td>$ {getLocaleStringRoundedInt(material.VALOR_CONTROLADOR)}</td>
                      <td>$ {getLocaleStringRoundedInt(material.VALOR_DOCUMENTADOS)}</td>
                      <td>$ {getLocaleStringRoundedInt(material.VALOR_CONFIRMADOS)}</td>
                      <td>$ {getLocaleStringRoundedInt(material.VALOR_DOCS_MENOS_CTRL)}</td>
                      <td>$ {getLocaleStringRoundedInt(material.VALOR_CONF_MENOS_DOCS)}</td>
                      <td className="align-center alerta-container alert-container-cierre-mensual">{getAlertIconVentas(material.ALERTA)}
                        <div className="alert-tooltip">
                          <div className="content">
                            {
                              material.ALERTA === "VERDE" && "alerta verde"
                            }
                            {
                              material.ALERTA === "AMARILLA" && "alerta amarilla"
                            }
                            {
                              material.ALERTA === "ROJA" && "alerta roja"
                            }
                          </div>
                        </div>
                      </td>
                      <td className="align-center">
                        {
                          material.JUSTIFICACION === "INGRESAR" &&
                          <img className="cierre-mensual-justificar-icon" onClick={() => openJustificacion(material, "editar")} src={EditarIcon} />
                        }
                        {
                          material.JUSTIFICACION === "VER" &&
                          <img className="cierre-mensual-justificar-icon" onClick={() => openJustificacion(material, "ver")} src={VerIcon} />
                        }
                        {
                          material.JUSTIFICACION === "" &&
                          "-"
                        }
                      </td>
                    </tr>
                  ))
                }
              </> :
                <tr className="data">
                  <td className="align-left" colSpan="100%">
                    No se encontró información de ventas del cierre.
                  </td>
                </tr>}
            </> :
            <tr>
              <td className="align-left" colSpan="100%">
                Busca por EDS y por Periodo.
              </td>
            </tr>}

        </tbody>
        {ventas && ventas.length > 0 && <>
          <tr className="separator-table-row">
            <th colSpan="100%" />
          </tr>
          <tr className="total-row align-center">
            <td>TOTAL</td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total?.TOTAL_LITROS_CONTROLADOR)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total?.TOTAL_LITROS_DOCUMENTADOS)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total?.TOTAL_LITROS_CONFIRMADOS)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total?.TOTAL_LITROS_DOCS_MENOS_CTRL)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total?.TOTAL_LITROS_CONF_MENOS_DOCS)}</div></td>
            <td><div className="total-data">$ {getLocaleStringRoundedInt(total?.TOTAL_VALOR_CONTROLADOR)}</div></td>
            <td><div className="total-data">$ {getLocaleStringRoundedInt(total?.TOTAL_VALOR_DOCUMENTADOS)}</div></td>
            <td><div className="total-data">$ {getLocaleStringRoundedInt(total?.TOTAL_VALOR_CONFIRMADOS)}</div></td>
            <td><div className="total-data">$ {getLocaleStringRoundedInt(total?.TOTAL_VALOR_DOCS_MENOS_CTRL)}</div></td>
            <td><div className="total-data">$ {getLocaleStringRoundedInt(total?.TOTAL_VALOR_CONF_MENOS_DOCS)}</div></td>
            <td colSpan="2" />
          </tr>
        </>}

      </table>
      {/* <table className="table-with-material-title2">
        <div className="table-header">
          <div className="legend">
            <div className="material">
              <div className="rect"></div>
              <div className="text">Material Consignado</div>
            </div>
            <div className="material">
              <div className="rect concesionado"></div>
              <div className="text">Material Concesionado</div>
            </div>
          </div>
        </div>
        <thead>
          <tr>
            <th className="no-padding-left">
              <div className="th-data width-89 material">
                <div className="th-text">Material</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 material">
                <div className="th-text">Tipo de<br />Venta</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">Litros<br />Controlador</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">Litros<br />Document...</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">Litros<br />Confirmad...</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">(Lts) Docu…<br />- Controla...</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">(Lts) Confirm...<br />- Documen…</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">($)<br />Controlador</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">($)<br />Document...</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">($)<br />Confirmad...</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">($) Documen…<br />- Controla...</div>
              </div>
            </th>
            <th>
              <div className="th-data two-lines width-89 litros-controlador">
                <div className="th-text">($) Confirm.<br />- Documen…</div>
              </div>
            </th>
            <th>
              <div className="th-data width-62 alerta">
                <div className="th-text">Alerta</div>
              </div>
            </th>
            <th>
              <div className="th-data width-62 ">
                <div className="th-text">Justifi...</div>
              </div>
            </th>
          </tr>
        </thead>
        <tr className="separator-table-row">
          <th colSpan="100%" />
        </tr>
        <tbody>
          {ventas2 ?
            <>
              {ventas2.length > 0 ? <>
                {
                  ventas2.map((venta, index) => (
                    <>
                      <tr>
                        <td className="def-tanque-container" rowSpan={2}>
                          <div className={`type consignado`}></div>
                          {venta.material}
                        </td>
                        <td className="align-left">Propia</td>
                        <td>{venta.venta_propia.litros_controlador}</td>
                        <td>{venta.venta_propia.litros_documentados}</td>
                        <td>{venta.venta_propia.litros_confirmados}</td>
                        <td>{venta.venta_propia.litros_documentados_menos_controlador}</td>
                        <td>{venta.venta_propia.litros_confirmados_menos_documentados}</td>
                        <td>{venta.venta_propia.pesos_controlador}</td>
                        <td>{venta.venta_propia.pesos_documentados}</td>
                        <td>{venta.venta_propia.pesos_confirmados}</td>
                        <td>{venta.venta_propia.pesos_documentados_menos_controlador}</td>
                        <td>{venta.venta_propia.pesos_documentados_menos_controlador}</td>
                        <td className="align-center alerta-container alert-container-cierre-mensual">{getAlertIconVentas("VERDE")}
                          <div className="alert-tooltip">
                            <div className="content">
                              {venta.producto_copec_alerta_desc}
                            </div>
                          </div>
                        </td>
                        <td className="align-center">
                            <img className="cierre-mensual-justificar-icon" onClick={() => openJustificacion(venta, "editar")} src={EditarIcon} />
                        </td>
                      </tr>
                      <tr>
                        <td className="align-left">Prod. Copec</td>
                        <td>{venta.producto_copec.litros_controlador}</td>
                        <td>{venta.producto_copec.litros_documentados}</td>
                        <td>{venta.producto_copec.litros_confirmados}</td>
                        <td>{venta.producto_copec.litros_documentados_menos_controlador}</td>
                        <td>{venta.producto_copec.litros_confirmados_menos_documentados}</td>
                        <td>{venta.producto_copec.pesos_controlador}</td>
                        <td>{venta.producto_copec.pesos_documentados}</td>
                        <td>{venta.producto_copec.pesos_confirmados}</td>
                        <td>{venta.producto_copec.pesos_documentados_menos_controlador}</td>
                        <td>{venta.producto_copec.pesos_documentados_menos_controlador}</td>
                        <td className="align-center alerta-container alert-container-cierre-mensual">{getAlertIconVentas("VERDE")}
                          <div className="alert-tooltip">
                            <div className="content">
                              {venta.producto_copec_alerta_desc}
                            </div>
                          </div>
                        </td>
                        <td className="align-center">
                            <img className="cierre-mensual-justificar-icon" onClick={() => openJustificacion(venta, "editar")} src={EditarIcon} />
                        </td>
                      </tr>
                      <tr>
                        <td className="def-tanque-container">
                          Total
                        </td>
                        <td className="total-border-top"></td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                              <span>
                                {venta.total.litros_controlador}
                              </span>
                          </div> 
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                              <span>
                                {venta.total.litros_documentados}
                              </span>
                          </div> 
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                              <span>
                                {venta.total.litros_confirmados}
                              </span>
                          </div> 
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                              <span>
                                {venta.total.pesos_documentados_menos_controlador}
                              </span>
                          </div> 
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                              <span>
                                {venta.total.litros_confirmados_menos_documentados}
                              </span>
                          </div> 
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                              <span>
                                {venta.total.pesos_controlador}
                              </span>
                          </div> 
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                              <span>
                                {venta.total.pesos_documentados}
                              </span>
                          </div> 
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                            <span>
                              {venta.total.pesos_confirmados}
                            </span>
                          </div>
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                            <span>
                              {venta.total.pesos_documentados_menos_controlador}
                            </span>
                          </div>
                        </td>
                        <td  className="total-border-top"> 
                          <div className="total-container">
                            <span>
                              {venta.total.pesos_confirmados_menos_documentados}
                            </span>
                          </div> 
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </>
                  ))
                }
              </> :
                <tr className="data">
                  <td className="align-left" colSpan="100%">
                    No se encontró información de ventas del cierre.
                  </td>
                </tr>}
            </> :
            <tr>
              <td className="align-left" colSpan="100%">
                Busca por EDS y por Periodo.
              </td>
            </tr>}

        </tbody>
        {ventas2 && ventas2.length > 0 && <>
          <tr className="separator-table-row">
            <th colSpan="100%" />
          </tr>
          <tr className="total-row align-center">
            <td>TOTAL</td>
            <td></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.litros_controlador)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.litros_documentados)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.litros_confirmados)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.litros_documentados_menos_controlador)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.litros_confirmados_menos_documentados)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.pesos_controlador)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.pesos_documentados)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.pesos_confirmados)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.pesos_documentados_menos_controlador)}</div></td>
            <td><div className="total-data">{getLocaleStringRoundedInt(total2?.pesos_confirmados_menos_documentados)}</div></td>
            <td colSpan="2" />
          </tr>
        </>}

      </table> */}
    </div>
  )
};

export default VentasTable;