import React, { useState, useEffect } from "react";
import Flecha_down from "../../assets/img/Flecha_down.svg";
import Flecha_up from "../../assets/img/Flecha_up.svg";
import {
  parseBudat,
  getLocaleStringNumber,
  getLitros,
  getForma,
  orderArray,
} from "../utils/utils.js";
import { allMateriales } from "../utils/materiales";
import VolumenTooltip from "./VolumenTooltip";
import CarrotDown from "../../assets/img/caret-down-blue.svg";
import Warning from "../../assets/img/atencion_amarillo.svg";
import {
  PedidosActivosTableMobileRecalculoModal,
  PedidosActivosTableMobileVolumenModal,
  PedidosActivosTableMobileFechaModal
} from "./PedidosActivosTableMobileModals";
import RecalculoTooltip from "./RecalculoTooltip";
import checkgreen from "../../assets/img/Check_green.svg";
import erroryellow from "../../assets/img/BADGE_ATTENTION_YELLOW.svg";
import checktransparent from "../../assets/img/Check_transparent.svg";
import VolumenInfoModal from "./VolumenInfoModal";
import { hideGreyBackground, showGreyBackground } from "../utils/windows";
import { getDinamoDetalle, setDetalleReposicion, setDetalleVolumen } from "../utils/get";
import ReposicionInfoModal from "./ReposicionInfoModal";
import VolumenDinamoModal from "./VolumenDinamoModal";
import FechaModal from "./FechaModal";
import CustomModal from "../CustomModal";

const PedidosActivosTable = ({
  pedidos,
  setPedidos,
  selectedPedido,
  setSelectedPedido,
  showMobile = false,
  isDinamo,
}) => {
  const [documentoKey, setDocumentoKey] = useState("");
  const [pedidoTooltip, setPedidoTooltip] = useState({});
  const [detalle, setDetalle] = useState([]);

  const [documentoRKey, setDocumentoRKey] = useState("");
  const [pedidoRTooltip, setPedidoRTooltip] = useState({});
  const [detalleR, setDetalleR] = useState([]);
  
  const [documentoFKey, setDocumentoFKey] = useState("");
  const [pedidoFTooltip, setPedidoFTooltip] = useState({});

  const [reposicion, setReposicion] = useState([]);
  const [cons, setCons] = useState([]);
  const [totalAPagar, setTotalAPagar] = useState({});
  const [precioPorLitro, setPrecioPorLitro] = useState({});
  const [dataForMobileReposicion, setDataForMobileReposicion] = useState([]);
  const [totalForMobileReposicion, setTotalForMobileReposicion] = useState(0);
  const [displayVolumenModal, setDisplayVolumenModal] = useState(false);
  const [displayReposicionModal, setDisplayReposicionModal] = useState(false);
  const [displayDinamoModal, setDisplayDinamoModal] = useState(false);
  const [displayFechaModal, setDisplayFechaModal] = useState(false);
  const [pedidoVolumen, setPedidoVolumen] = useState({});
  const [pedidoReposicion, setPedidoReposicion] = useState({});
  const [pedidoDinamo, setPedidoDinamo] = useState({});
  const [pedidoFecha, setPedidoFecha] = useState({});
  const [distanceToTop, setDistanceToTop] = useState(0);
  const [tiempoText, setTiempoText] = useState("");
  const [displayTiempoModal, setDisplayTiempoModal] = useState(false);

  const orderTable = (filterkey, order, parse) => {
    const arr = orderArray(filterkey, pedidos, order, parse);
    setPedidos(arr);
  };

  const changeDocKey = (key, p) => {
    setDocumentoKey(key);
    setPedidoTooltip(p);
  };

  const changeRDocKey = (key, p) => {
    setDocumentoRKey(key);
    setPedidoRTooltip(p);
  };

  const changeFDocKey = (key, p) => {
    setDocumentoFKey(key);
    setPedidoFTooltip(p);
  };

  const changeVolumenPedido = (e, p) => {
    showGreyBackground();
    setDisplayVolumenModal(true);
    setPedidoVolumen(p);
    setDistanceToTop(e.currentTarget.getBoundingClientRect().top);
  };

  const changeReposicionPedido = (e, p) => {
    showGreyBackground();
    setDisplayReposicionModal(true);
    setPedidoReposicion(p);
    setDistanceToTop(e.currentTarget.getBoundingClientRect().top);
  };

  const changeDinamoPedido = (e, p) => {
    showGreyBackground();
    setDisplayDinamoModal(true);
    setPedidoDinamo(p);
    setDistanceToTop(e.currentTarget.getBoundingClientRect().top);
  };

  const changeFechaPedido = (e, p) => {
    showGreyBackground();
    setDisplayFechaModal(true);
    setPedidoFecha(p);
    setDistanceToTop(e.currentTarget.getBoundingClientRect().top);
  };

  const getTotal = (arreglo) => {
    let total = 0;
    arreglo.forEach((p) => {
      if (p.CATEGORIA_ITEM === "ZFES" || p.CATEGORIA_ITEM === "ZRCO") {
        total += p.PRECIO_TOTAL_MATERIAL;
      }
    });
    return total;
  };

  useEffect(() => {
    if (pedidoRTooltip) {
      const arr93 = [];
      const arr95 = [];
      const arr97 = [];
      const arrPd = [];
      const arrK = [];

      if (pedidoRTooltip.DETALLE) {
        const detalle = pedidoRTooltip.DETALLE;
        getDataForMobileReposicion(detalle);
        detalle.forEach((item) => {
          if (allMateriales[item.ID_MATERIAL].class === "sp93") {
            arr93.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "sp95") {
            arr95.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "sp97") {
            arr97.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "pd") {
            arrPd.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "k") {
            arrK.push(item);
          }
        });
      }

      const tctAux = getForma(
        arr93,
        arr95,
        arr97,
        arrPd,
        arrK,
        "ZTCT",
        "Reposición TCT"
      );
      const taeAux = getForma(
        arr93,
        arr95,
        arr97,
        arrPd,
        arrK,
        "ZTAE",
        "Reposición TAE"
      );
      const cuponAux = getForma(
        arr93,
        arr95,
        arr97,
        arrPd,
        arrK,
        "ZCUP",
        "Reposición Cupón Electrónico"
      );
      const ffaaAux = getForma(
        arr93,
        arr95,
        arr97,
        arrPd,
        arrK,
        "ZFFA",
        "Reposición FF.AA."
      );
      const muevoAux = getForma(
        arr93,
        arr95,
        arr97,
        arrPd,
        arrK,
        "ZCEM",
        "Reposición Muevo Empresa"
      );

      const astorageAux = getForma(
        arr93,
        arr95,
        arr97,
        arrPd,
        arrK,
        "ZPAS",
        "Almacenamiento Storage"
      );
      const storageAux = getForma(
        arr93,
        arr95,
        arr97,
        arrPd,
        arrK,
        "ZPCS",
        "Storage"
      );

      const consigAux = getForma(
        arr93,
        arr95,
        arr97,
        arrPd,
        arrK,
        "ZRCO",
        "Consignación"
      );
      const conceAux = getForma(
        arr93,
        arr95,
        arr97,
        arrPd,
        arrK,
        "ZFES",
        "Concesión"
      );
      const constAux = getForma(
        arr93,
        arr95,
        arr97,
        arrPd,
        arrK,
        "ZCSC",
        "Constitución"
      );

      const repos = [
        tctAux,
        taeAux,
        cuponAux,
        ffaaAux,
        muevoAux,
        astorageAux,
        storageAux,
      ];
      const con = [consigAux, conceAux, constAux];

      setReposicion(repos);
      setCons(con);
      setTotalAPagar({
        g93: getTotal(arr93),
        g95: getTotal(arr95),
        g97: getTotal(arr97),
        pd: getTotal(arrPd),
        k: getTotal(arrK),
        total:
          getTotal(arr93) +
          getTotal(arr95) +
          getTotal(arr97) +
          getTotal(arrPd) +
          getTotal(arrK),
      });

      let precio93 = "-";
      let precio95 = "-";
      let precio97 = "-";
      let precioPd = "-";
      let precioK = "-";

      if (parseInt(getTotal(arr93)) > 0) {
        arr93.forEach((p) => {
          if (p.CATEGORIA_ITEM === "ZFES" || p.CATEGORIA_ITEM === "ZRCO") {
            precio93 = p.PRECIO_MATERIAL;
          }
        });
      }

      if (parseInt(getTotal(arr95)) > 0) {
        arr95.forEach((p) => {
          if (p.CATEGORIA_ITEM === "ZFES" || p.CATEGORIA_ITEM === "ZRCO") {
            precio95 = p.PRECIO_MATERIAL;
          }
        });
      }

      if (parseInt(getTotal(arr97)) > 0) {
        arr97.forEach((p) => {
          if (p.CATEGORIA_ITEM === "ZFES" || p.CATEGORIA_ITEM === "ZRCO") {
            precio97 = p.PRECIO_MATERIAL;
          }
        });
      }

      if (parseInt(getTotal(arrPd)) > 0) {
        arrPd.forEach((p) => {
          if (p.CATEGORIA_ITEM === "ZFES" || p.CATEGORIA_ITEM === "ZRCO") {
            precioPd = p.PRECIO_MATERIAL;
          }
        });
      }

      if (parseInt(getTotal(arrK)) > 0) {
        arrK.forEach((p) => {
          if (p.CATEGORIA_ITEM === "ZFES" || p.CATEGORIA_ITEM === "ZRCO") {
            precioK = p.PRECIO_MATERIAL;
          }
        });
      }

      setPrecioPorLitro({
        g93: precio93,
        g95: precio95,
        g97: precio97,
        pd: precioPd,
        k: precioK,
      });
    }
  }, [pedidoRTooltip]);

  useEffect(() => {
    if (pedidoTooltip) {

      if(isDinamo){
        setDetalle(getDinamoDetalle(pedidoTooltip));
      }else{
        const arr93 = [];
        const arr95 = [];
        const arr97 = [];
        const arrPd = [];
        const arrK = [];
  
        if (pedidoTooltip.DETALLE) {
          const detalle = pedidoTooltip.DETALLE;
          detalle.forEach((item) => {
            if (allMateriales[item.ID_MATERIAL].class === "sp93") {
              arr93.push(item);
            }
            if (allMateriales[item.ID_MATERIAL].class === "sp95") {
              arr95.push(item);
            }
            if (allMateriales[item.ID_MATERIAL].class === "sp97") {
              arr97.push(item);
            }
            if (allMateriales[item.ID_MATERIAL].class === "pd") {
              arrPd.push(item);
            }
            if (allMateriales[item.ID_MATERIAL].class === "k") {
              arrK.push(item);
            }
          });
        }
  
        const sp93object = {
          product: "Gasolina 93 Octanos NU 1202",
          liters: getLitros(arr93, "ZCRM"),
        };
        const sp95object = {
          product: "Gasolina 95 Octanos NU 1202",
          liters: getLitros(arr95, "ZCRM"),
        };
        const sp97object = {
          product: "Gasolina 97 Octanos Premium NU 1202",
          liters: getLitros(arr97, "ZCRM"),
        };
        const pdobject = {
          product: "Petroleo Diesel Ultra A1 NU 1203",
          liters: getLitros(arrPd, "ZCRM"),
        };
        const kobject = {
          product: "Kerosene NU 1223",
          liters: getLitros(arrK, "ZCRM"),
        };
  
        const detalles = [];
        detalles.push(sp93object);
        detalles.push(sp95object);
        detalles.push(sp97object);
        detalles.push(pdobject);
        detalles.push(kobject);
        setDetalle(detalles);
      }
      
    }
  }, [pedidoTooltip]);

  useEffect(() => {
    if (pedidoVolumen) {
      setDetalleVolumen(pedidoVolumen, setDetalle);
    }
  }, [pedidoVolumen]);

  useEffect(() => {
    if (pedidoReposicion) {
      setDetalleReposicion(
        pedidoReposicion,
        setReposicion,
        setCons,
        setTotalAPagar,
        setPrecioPorLitro,
        getDataForMobileReposicion
      );
    }
  }, [pedidoReposicion]);

  const toggleMobileSection = ({ currentTarget }) => {
    currentTarget.classList.toggle("expanded-section");
    currentTarget.closest(".mobile-table__item").classList.toggle("open");
  };

  function getDataForMobileReposicion(detalle) {
    const returnData = [];
    let total = 0;

    detalle.map((data, key) => {
      if (data.CANTIDAD_SOLICITADA > 0 && data.PRECIO_TOTAL_MATERIAL>0) {
        total += data.PRECIO_TOTAL_MATERIAL;
        returnData.push({
          name: data.NOMBRE,
          description: data.VTEXT,
          volume: data.CANTIDAD_SOLICITADA,
          pricePerLiter: data.PRECIO_MATERIAL,
          total: data.PRECIO_TOTAL_MATERIAL,
        });
      }
    });

    setDataForMobileReposicion(returnData);
    setTotalForMobileReposicion(total);
  }

  function closeInfoModals() {
    setDisplayVolumenModal(false);
    setDisplayReposicionModal(false);
    setDisplayDinamoModal(false);
    setDisplayFechaModal(false);
    hideGreyBackground();
  }

  function getTipoPedidoShort(tipo){
    if(tipo.toLowerCase().includes('emergencia'))
      tipo = "EME"
    else if(tipo.toLowerCase().includes('preprograma'))
      tipo = "PRE"
    else if(tipo.toLowerCase().includes('cambio'))
      tipo = "CAM"
    else if(tipo.toLowerCase().includes('estándar'))
      tipo = "EST"
    return tipo
  }

  function openTiemposModal(pedido){
    const tipo_pedido=pedido.TIPO_PEDIDO;
    const tiempo = tipo_pedido === "Preprograma"? pedido.CONFIRMACION_MIX === "Confirmado"? pedido.RESTANTE : pedido.RESTANTE_PRE : pedido.RESTANTE;
    setDisplayTiempoModal(true);
    if(tipo_pedido.toLowerCase().includes('emergencia')){
      setTiempoText('Para el pedido <b>'+pedido.ID_PEDIDO+'</b> de emergencia no se permiten ajustes de cambio de camion, modificacion de mix o rechazo de pedido. Solo se permite cambiar las observaciones.')
    }
    if(tipo_pedido.toLowerCase().includes('preprograma')){
      setTiempoText('Los tiempos para el pedido <b>'+pedido.ID_PEDIDO+'</b> de preprograma son los sgtes <b>('+tiempo+')</b>: <br> • Rechazar pedido: tiempo es >= 4:00. <br> • Confirmar Mix: tiempo es >= 3:00. <br> • Modificar mix: tiempo es >= 0:00.')
    }
    if(tipo_pedido.toLowerCase().includes('estándar')){
      setTiempoText('Los tiempos para el pedido <b>'+pedido.ID_PEDIDO+'</b> estándar son los siguientes <b>('+tiempo+')</b>: <br> • Rechazar pedido: tiempo es >= 4:00. <br> • Cambio camión: tiempo es >= 4:00. <br> • Modificar mix: tiempo es >= 0:00.')
    }
    if(tipo_pedido.toLowerCase().includes('cambio')){
      setTiempoText('Los tiempos para el pedido <b>'+pedido.ID_PEDIDO+'</b> son los siguientes <b>('+tiempo+')</b>: <br> • Rechazar pedido: tiempo es >= 4:00. <br> • Cambio camión: tiempo es >= 4:00. <br> • Modificar mix: tiempo es >= 0:30.')
    }
  }

  function closeModal(){
    setDisplayTiempoModal(false)
  }

  return showMobile ? (
    <>
      {pedidos.length === 0 ? (
        <p className="pedidos-activos-mobile-empty-state">
          Seleccione una Estación de Servicio para ver la información.
        </p>
      ) : (
        <div className="mobile-table">
          {pedidos.map((p, key) => {
            return (
              <div className={`mobile-table__item ${p.ESTADO_DYNAMO && p.ESTADO_DYNAMO !== ''? 'has-estado-dynamo': ''}`} key={p.ID_PEDIDO}>
                <div className="mobile-table__item-top-section">
                  <input
                    type="radio"
                    name="selected-pedido-check selectInput"
                    value={p.ID_PEDIDO}
                    checked={p.ID_PEDIDO === selectedPedido?.ID_PEDIDO}
                    onChange={() => setSelectedPedido(p)}
                  />
                  <div className="mobile-table__item-name-container">
                    <div className="mobile-table__item-name-full-width">
                      <div 
                        className={`mobile-table__item-name 
                          mobile-pedidos-activos-tipo-pedido
                          ${getTipoPedidoShort(p.TIPO_PEDIDO)==='EME'? 'mobile-pedidos-activos-tipo-pedido-emergencia': ''}
                          ${getTipoPedidoShort(p.TIPO_PEDIDO)==='PRE'? 'mobile-pedidos-activos-tipo-pedido-preprograma': ''}
                      `}>
                        {getTipoPedidoShort(p.TIPO_PEDIDO)}
                      </div>

                      <p className={`mobile-table__item-name ${!isDinamo? '': ''}`}>{p.ESTADO}</p>

                      {isDinamo &&
                        <div className="mobile-table__estado-dynamo">
                          {p.ESTADO_DYNAMO}
                        </div>
                      }

                      {!isDinamo &&
                        <p className="mobile-table__item-name mobile-pedidos-activos-cantidad">
                          {p.CANTIDAD_TOTAL / 1000 + "m3"}
                        </p>
                      }

                    </div>
                    <div>
                      <div className="mobile-table__item-content-list-item">
                        <p className="mobile-table__item-content-list-item-title">
                          Fecha Pref. de Entrega
                        </p>
                        <p className="mobile-table__item-content-list-item-value">
                          {parseBudat(p.FECHA_PREFERENTE_ENTREGA)}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div className="mobile-table__item-content-list-item">
                        <p className="mobile-table__item-content-list-item-title">
                          Horario
                        </p>
                        <p
                          className={
                            "mobile-table__item-content-list-item-value"
                          }
                        >
                          {p.ESTADO === "Rechazado"?<>{p.VENTANA}</>:
                          <a className="cursor-pointer underline"
                          onClick={() => changeFDocKey(key, p)}>
                          {p.VENTANA}
                          </a>}
                        </p>
                      </div>
                    </div>
                  </div>
                  <button
                    className="mobile-table__item-button"
                    onClick={toggleMobileSection}
                  >
                    <img src={CarrotDown} alt="expand" />
                  </button>
                </div>
                <div className="mobile-table__item-bottom-section">
                  <div className="mobile-table__item-content">
                    <div className="mobile-table__item-content-list">
                      <div className="mobile-table__item-content-list-item">
                        <p className="mobile-table__item-content-list-item-title">
                          Tipo de pedido
                        </p>
                        <div 
                          className={`pedidos-activos-table-tipo-pedido
                            ${p.TIPO_PEDIDO.toLowerCase().includes('emergencia')? 'pedidos-activos-table-tipo-pedido-emergencia': ''}
                            ${p.TIPO_PEDIDO.toLowerCase().includes('preprograma')? 'pedidos-activos-table-tipo-pedido-preprograma': ''}
                          `}
                        >
                          {p.TIPO_PEDIDO.toUpperCase()}
                        </div>
                      </div>
                      <div className="mobile-table__item-content-list-item">
                        <p className="mobile-table__item-content-list-item-title">
                          Tiempo Rest. Modificar
                        </p>
                        <a className="underline cursor-pointer" onClick={()=>openTiemposModal(p)}>
                          <p className="mobile-table__item-content-list-item-value">
                              {p.TIPO_PEDIDO === "Preprograma" ? (
                              p.CONFIRMACION_MIX === "Confirmado" ? (
                                <>
                                  {p.RESTANTE}
                                </>
                              ) : (
                                <>
                                  {p.RESTANTE_PRE}
                                </>
                              )
                            ) : (
                              <>
                                {p.RESTANTE}
                              </>
                            )}
                          </p>
                        </a>
                      </div>

                      <div className="mobile-table__item-content-list-item hidden">
                        <p className="mobile-table__item-content-list-item-title">
                          Estado del pedido
                        </p>
                        <p className="mobile-table__item-content-list-item-value">
                          {p.ESTADO_GTI}
                        </p>
                      </div>

                      <div className="mobile-table__item-content-list-item">
                        <p className="mobile-table__item-content-list-item-title">
                          Planta
                        </p>
                        <p className="mobile-table__item-content-list-item-value">
                          {p.PLANTA}
                        </p>
                      </div>

                      <div className="mobile-table__item-content-list-item">
                        <p className="mobile-table__item-content-list-item-title">
                          Nº Pedido
                        </p>
                        <p className="mobile-table__item-content-list-item-value">
                          Nº {p.ID_PEDIDO}
                        </p>
                      </div>




                      {/*isDinamo && <>
                        <div className="mobile-table__item-content-list-item-3-columns">
                        <p className="mobile-table__item-content-list-item-title">
                          Solicitado
                        </p>
                        <p className="mobile-table__item-content-list-item-value">
                          {p.VOLUMEN_SOLICITADO/1000} m3
                        </p>
                        </div>
                        <div className="mobile-table__item-content-list-item-3-columns">
                          <p className="mobile-table__item-content-list-item-title">
                            Planificado
                          </p>
                          <p className="mobile-table__item-content-list-item-value">
                            {p.VOLUMEN_PLANIFICADO/1000} m3
                          </p>
                        </div>
                        <div className="mobile-table__item-content-list-item-3-columns">
                          <p className="mobile-table__item-content-list-item-title">
                            Entregado
                          </p>
                          <p className="mobile-table__item-content-list-item-value">
                            {p.VOLUMEN_ENTREGADO/1000} m3
                          </p>
                        </div>
                      </>*/}
  
                      <div className="mobile-table__item-content-list-item">
                        <p className="mobile-table__item-content-list-item-title">
                          {/*  Nº Pedido */}
                        </p>
                        <p className="mobile-table__item-content-list-item-value">
                          {/*  Nº {p.ID_PEDIDO} */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`mobile-table__item-bottom-actions ${
                    isDinamo ? "dinamo" : ""
                  }`}
                >
                  <button
                    className="main-button hallow"
                    onClick={() => changeDocKey(key, p)}
                  >
                    Detalle Volumen
                  </button>
                  {!isDinamo && (
                    <button
                      className="main-button hallow"
                      onClick={() => changeRDocKey(key, p)}
                    >
                      Ver Reposición
                    </button>
                  )}
                </div>
              </div>
            );
          })}
          <PedidosActivosTableMobileVolumenModal
            showModal={documentoKey !== "" && !!pedidoTooltip}
            closeModal={() => setDocumentoKey("")}
            total={pedidoTooltip?.CANTIDAD_TOTAL}
            description={`${pedidoTooltip?.ID_PEDIDO} - ${pedidoTooltip?.TIPO_PEDIDO}`}
            data={detalle}
            isDinamo={isDinamo}
          />
          <PedidosActivosTableMobileRecalculoModal
            showModal={documentoRKey !== "" && !!pedidoRTooltip}
            closeModal={() => setDocumentoRKey("")}
            total={totalForMobileReposicion}
            description={
              <>
                <span>
                  {pedidoRTooltip?.ID_PEDIDO} - {pedidoRTooltip?.TIPO_PEDIDO},
                </span>
                <br />
                <span>
                  {parseBudat(pedidoRTooltip?.FECHA_PREFERENTE_ENTREGA)} -{" "}
                  {pedidoRTooltip?.VENTANA}
                </span>
              </>
            }
            data={dataForMobileReposicion}
          />
          <PedidosActivosTableMobileFechaModal
            showModal={documentoFKey !== "" && !!pedidoFTooltip}
            closeModal={() => setDocumentoFKey("")}
            description={`${pedidoFTooltip?.ID_PEDIDO} - ${pedidoFTooltip?.TIPO_PEDIDO}`}
            data={pedidoFTooltip}
          />
        </div>
      )}
      <CustomModal
        displayModal={displayTiempoModal}
        closeModal={closeModal}
        acceptFunc={closeModal}
        modalTitle='Advertencia'
        modalText={tiempoText}
        modalIcon='importante'
      />
    </>
  ) : (
    <>
      <div className="modal-container">
        <VolumenInfoModal
          displayModal={displayVolumenModal}
          closeModal={closeInfoModals}
          distanceToTop={distanceToTop}
          pedido={pedidoVolumen}
          detalle={detalle}
        />
        <ReposicionInfoModal
          displayModal={displayReposicionModal}
          closeModal={closeInfoModals}
          distanceToTop={distanceToTop}
          pedido={pedidoReposicion}
          detalleR={detalleR}
          reposicion={reposicion}
          cons={cons}
          totalAPagar={totalAPagar}
          precioPorLitro={precioPorLitro}
        />
        <VolumenDinamoModal
          displayModal={displayDinamoModal}
          closeModal={closeInfoModals}
          distanceToTop={distanceToTop}
          pedido={pedidoDinamo}
          /* detalle={detalle} */
        />
         <FechaModal
          displayModal={displayFechaModal}
          closeModal={closeInfoModals}
          distanceToTop={distanceToTop}
          pedido={pedidoFecha}
          /* detalle={detalle} */
        />
      </div>
      <div className="pedidos-activos-table-container">
        <table className={`pedidos-activos-table ${isDinamo ? "dinamo" : ""}`}>
          <tr>
            <th className="no-padding-left">
              <div className="th-data">
                <div className="th-text">
                  Nº de pedido
                  <div className="arrows">
                    <img
                      // onClick={() => orderTable("ID_PEDIDO", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      // onClick={() => orderTable("ID_PEDIDO", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="th-data">
                <div className="th-text">
                  Tipo de pedido
                  <div className="arrows">
                    <img
                      onClick={() => orderTable("TIPO_PEDIDO", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() => orderTable("TIPO_PEDIDO", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="th-data">
                <div className="th-text">
                  Planta
                  <div className="arrows">
                    <img
                      onClick={() => orderTable("NOMBRE_PLANTA", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() => orderTable("NOMBRE_PLANTA", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="th-data">
                <div className="th-text">
                  Fecha Pref. de Entrega
                  <div className="arrows mtop">
                    <img
                      onClick={() =>
                        orderTable("FECHA_PREFERENTE_ENTREGA", "asc", false)
                      }
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() =>
                        orderTable("FECHA_PREFERENTE_ENTREGA", "desc", false)
                      }
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="th-data">
                <div className="th-text">
                  Horario
                  <div className="arrows">
                    <img
                      onClick={() => orderTable("VENTANA", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() => orderTable("VENTANA", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            {!isDinamo && (
              <>
                <th>
                  <div className="th-data">
                    <div className="th-text">
                      Volumen Total (93-95-97-D-K)
                      <div className="arrows mtop">
                        <img
                          onClick={() => orderTable("VOLUMEN", "asc", false)}
                          src={Flecha_up}
                        ></img>
                        <img
                          onClick={() => orderTable("VOLUMEN", "desc", false)}
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th>
              </>
            )}
            {isDinamo && (
              <>
                <th>
                  <div className="th-data">
                    <div className="th-text">
                      Volumen Solicitado
                      <div className="arrows mtop">
                        <img
                          onClick={() =>
                            orderTable("VOLUMEN_SOLICITADO", "asc", false)
                          }
                          src={Flecha_up}
                        ></img>
                        <img
                          onClick={() =>
                            orderTable("VOLUMEN_SOLICITADO", "desc", false)
                          }
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="th-data">
                    <div className="th-text">
                      Volumen Planificado
                      <div className="arrows mtop">
                        <img
                          onClick={() =>
                            orderTable("VOLUMEN_PLANIFICADO", "asc", false)
                          }
                          src={Flecha_up}
                        ></img>
                        <img
                          onClick={() =>
                            orderTable("VOLUMEN_PLANIFICADO", "desc", false)
                          }
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th>
                {/* <th>
                  <div className="th-data">
                    <div className="th-text">
                      Volumen Entregado
                      <div className="arrows mtop">
                        <img
                          onClick={() =>
                            orderTable("VOLUMEN_ENTREGADO", "asc", false)
                          }
                          src={Flecha_up}
                        ></img>
                        <img
                          onClick={() =>
                            orderTable("VOLUMEN_ENTREGADO", "desc", false)
                          }
                          src={Flecha_down}
                        ></img>
                      </div>
                    </div>
                  </div>
                </th> */}
              </>
            )}

            <th>
              <div className="th-data">
                <div className="th-text">
                  Tiempo Rest. Modificar
                  <div className="arrows mtop">
                    <img
                      onClick={() => orderTable("RESTANTE", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() => orderTable("RESTANTE", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className={`th-data ${isDinamo?"dinamo":""}`}>
                <div className="th-text">
                  Estado del Pedido
                  <div className="arrows">
                    <img
                      onClick={() => orderTable(isDinamo?"ESTADO":"ESTADO_GTI", "asc", false)}
                      src={Flecha_up}
                    ></img>
                    <img
                      onClick={() => orderTable(isDinamo?"ESTADO":"ESTADO_GTI", "desc", false)}
                      src={Flecha_down}
                    ></img>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="th-data th-select">
                <div className="th-text">Selec.</div>
              </div>
            </th>
          </tr>
          <tr className="table-separator-row-border-top">
            <td />
          </tr>
          <tbody>
            {pedidos.length === 0 ? (
              <>
                <tr className="data">
                  <td className="align-left" colSpan="100%">
                    Seleccione una Estación de Servicio para ver la información.
                  </td>
                </tr>
                <tr className="data">
                  <td className="align-left" colSpan="100%">
                    &nbsp;
                  </td>
                </tr>
              </>
            ) : (
              pedidos.map((p, key) => (
                <tr key={key} className="data">
                  <td className={`align-left`}>
                    {" "}
                    {p.RECALCULO === "Ver" ? (
                      <>
                        {isDinamo ? (
                          <>{p.ID_PEDIDO}</>
                        ) : (
                          <>
                            <a
                              className={`underline cursor-pointer`}
                              onClick={(e) => changeReposicionPedido(e, p)}
                            >
                              {p.ID_PEDIDO}
                            </a>
                          </>
                        )}
                        {/* <RecalculoTooltip
                          setDocumentoRKey={setDocumentoRKey}
                          hidden={documentoRKey === key ? false : true}
                          detalleR={detalleR}
                          reposicion={reposicion}
                          cons={cons}
                          totalAPagar={totalAPagar}
                          precioPorLitro={precioPorLitro}
                        ></RecalculoTooltip> */}
                      </>
                    ) : (
                      <>{p.ID_PEDIDO}</>
                    )}{" "}
                  </td>
                  <td className="align-left">
                    <div 
                      className={`table-data-ellipsis 
                        pedidos-activos-table-tipo-pedido 
                        ${p.TIPO_PEDIDO.toLowerCase().includes('emergencia')? 'pedidos-activos-table-tipo-pedido-emergencia': ''}
                        ${p.TIPO_PEDIDO.toLowerCase().includes('preprograma')? 'pedidos-activos-table-tipo-pedido-preprograma': ''}
                        ${p.TIPO_PEDIDO.toLowerCase().includes('cambio')? 'pedidos-activos-table-tipo-pedido-cambio-fecha': ''}
                      `}
                    >{p.TIPO_PEDIDO.toUpperCase()}</div>
                  </td>
                  <td className="align-left estado">
                    {p.PLANTA}
                    {p.PEDIDO_LOGISTIC!=="" && isDinamo && <div className="sap">Creado por planta</div>}
                  </td>
                  
                  <td className="align-left">
                      {parseBudat(p.FECHA_PREFERENTE_ENTREGA)}
                    
                  </td>
                  {p.ESTADO === "Rechazado"?<><td className="align-left">{p.VENTANA}</td></>:<td className="align-left underline cursor-pointer" onClick={(e) => changeFechaPedido(e, p)}>{p.VENTANA}</td>}

                  {!isDinamo && (
                    <>
                      <td className="align-left">
                        <a
                          className={`underline cursor-pointer`}
                          onClick={(e) => changeVolumenPedido(e, p)}
                        >
                          {getLocaleStringNumber(p.VOLUMEN)}
                        </a>
                      </td>
                    </>
                  )}
                  {isDinamo && (
                    <>
                      <td className="align-left">
                        <a
                          className={`underline cursor-pointer`}
                          onClick={(e) => changeDinamoPedido(e, p)}
                        >
                          {p.VOLUMEN_SOLICITADO / 1000 + " m3"}
                        </a>
                      </td>
                      <td className="align-left">
                        <a
                          className={`underline cursor-pointer`}
                          onClick={(e) => changeDinamoPedido(e, p)}
                        >
                          {p.VOLUMEN_PLANIFICADO / 1000 + " m3"}
                        </a>
                      </td>
                      {/* <td className="align-left">
                        <a
                          className={`underline cursor-pointer`}
                          onClick={(e) => changeDinamoPedido(e, p)}
                        >
                          {p.VOLUMEN_ENTREGADO / 1000 + " m3"}
                        </a>
                      </td> */}
                    </>
                  )}

                  <td className="align-left">
                    <a className="underline cursor-pointer" onClick={()=>openTiemposModal(p)}>
                      <div className="restante">
                        
                        {p.TIPO_PEDIDO === "Preprograma" ? (
                          p.CONFIRMACION_MIX === "Confirmado" ? (
                            <>
                              <img src={checkgreen} />
                              <div>{p.RESTANTE}</div>
                            </>
                          ) : (
                            <>
                              <img src={erroryellow} />
                              <div>{p.RESTANTE_PRE}</div>
                            </>
                          )
                        ) : (
                          <>
                            <img src={checktransparent} />
                            <div>{p.RESTANTE}</div>
                          </>
                        )}
                        
                      </div>
                    </a>
                  </td>
                  {isDinamo && (
                    <>
                      <td className="align-left estado">
                        <div className="gti">
                          <b>{p.ESTADO}</b>
                        </div>
                        <div className="sap">{p.ESTADO_DYNAMO}</div>
                      </td>
                    </>
                  )}
                  {!isDinamo && (
                    <>
                      <td className="align-left estado">
                        <div className="gti">
                          {/* <b>{p.ESTADO_GTI}</b> */}
                          <b>{p.ESTADO}</b>
                        </div>
                        {/* <div className="sap">{p.ESTADO}</div> */}
                      </td>
                    </>
                  )}
                  <td className="align-left">
                    <input
                      type="radio"
                      name="selected-pedido-check"
                      value={p.ID_PEDIDO}
                      checked={p.ID_PEDIDO === selectedPedido?.ID_PEDIDO}
                      onChange={(e) => setSelectedPedido(p)}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <CustomModal
        displayModal={displayTiempoModal}
        closeModal={closeModal}
        acceptFunc={closeModal}
        modalTitle='Advertencia'
        modalText={tiempoText}
        modalIcon='importante'
      />
    </>
  );
};

export default PedidosActivosTable;
