import React, { useState, useRef } from "react";
import { parseDate, getLocaleStringNumber } from '../utils/utils.js';
import { hideGreyBackground, showGreyBackground } from "../utils/windows";
import CustomModal from "../CustomModal";

export const FirstStepContent = ({ setStep, setAbonos, rutConcesionario, lineasAbono, selectedEdsArcoprime, isArcoprime }) => {
  const [ valores, setValores ] = useState({})
  const [ displayModal, setDisplayModal ] = useState(false)
  const tableRef = useRef(null);

  const date = new Date()
  const parsedDate = parseDate(date)

  function openModal() {
    setDisplayModal(true);
    showGreyBackground();
  }
  
  function closeModal() {
    setDisplayModal(false);
    hideGreyBackground();
  }

  function getTotal(){
    let total = 0

    Object.keys(valores).map((key) => {
      if(valores[key])
        total += parseInt(valores[key])
    })

    return getLocaleStringNumber(total)
  }

  function getTotalNumber(){
    let total = 0

    Object.keys(valores).map((key) => {
      if(valores[key])
        total += parseInt(valores[key])
    })

    return total
  }

  function confirm(){
    closeModal()
    const abonos = []

    Object.keys(valores).map((key) => {
      if(valores[key]){
        const newAbono = {
          linea: key,
          monto: valores[key]
        }
        abonos.push(newAbono)
      }
    })

    setAbonos(abonos)
    setStep(2)
  }

  function changeValor(linea, value){
    if(value < 0)
      value = ''

    setValores({...valores, [linea]: value})
  }

  function cleanForm(){
    setValores({});
    tableRef.current
        .querySelectorAll("input")
        .forEach((input, key) => {
          input.value = '';
        });
  }

  return (
    <div className="abonar-en-banco__step-content">
      <table className="abonar-en-banco__table" ref={tableRef}>
        <thead>
          <tr>
            <th>Línea Producto</th>
            <th>Fecha Abono</th>
            <th>Monto</th>
          </tr>
        </thead>
        <tr>
          <th colSpan="3" />
        </tr>
        <tbody>
        {
          lineasAbono.map((linea, key) => 
          <>
          <tr>
            <td>{ linea.DESCRIPCION }</td>
            <td>{ parsedDate }</td>
            <td>
              <div className={`input-with-unit ${isArcoprime && !selectedEdsArcoprime?"blocked":""}`}>
                <p>$</p>
                <input type="number" value={ valores[linea.LINEA] } onChange={(e) => changeValor(linea.LINEA, e.target.value)} disabled={isArcoprime && !selectedEdsArcoprime} />
              </div>
            </td>
          </tr>
          {lineasAbono.length - 1 != key ? (
                <>
                  <tr className="data separator">
                    <td className="trseparator align-left" colSpan="5">
                      <div className="line-sep"></div>
                    </td>
                  </tr>
                </>
              ) : (
                ""
              )}
          </>
        )}
        </tbody>
        <tr>
          <th colSpan="3" />
        </tr>
        <tfoot>
          <tr>
            <td colSpan="2">Total</td>
            <td>$ { getTotal() }</td>
          </tr>
        </tfoot>
      </table>
      <p className="abonar-en-banco__disclaimer">
        <span>Importante:</span> El cliente ha aceptado previamente los términos
        y condiciones de uso del Sitio Internet para pagos Copec S.A..
      </p>
      <hr />
      <div className="abonar-en-banco__step-actions">
        <button className={`main-button hallow${getTotalNumber()>0?'':' disabled'}`} onClick={cleanForm} disabled={getTotalNumber() <= 0}>
          Limpiar
        </button>
        <button className={`main-button${getTotalNumber()>0?'':' disabled'}`} onClick={openModal} disabled={getTotalNumber() <= 0}>
          Confirmar
        </button>
      </div>

      <CustomModal
        displayModal={displayModal}
        closeModal={closeModal}
        acceptFunc={confirm}
        acceptText='Confirmar'
        cancelFunc={closeModal}
        modalTitle='Importante'
        modalText='El abono se podrá utilizar para compensar documentos con la misma Linea de Productos.'
        modalIcon='error'
      />
    </div>
  );
};
