import React from "react";
import finished from "../assets/img/check.svg";

export const finishedIcon = <img src={finished} alt="finished" />;
export const whiteCross = (
  <svg
    width="10"
    height="10"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      transform="translate(2.000000, 2.000000)"
      stroke="#fff"
      stroke-width="3.1"
    >
      <line
        x1="0"
        y1="0"
        x2="14"
        y2="14"
        transform="translate(7.000000, 7.000000) scale(-1, 1) translate(-7.000000, -7.000000) "
      />

      <line x1="0" y1="0" x2="14" y2="14" />
    </g>
  </svg>
);
export const CopecStepper = ({
  steps = [],
  className = "",
  activeStep = 1,
  error = false,
  setStep,
  activateLinks
}) => {

  function goToStep(index){
    if(activateLinks)setStep(index)
  }

  return (
    <div className={`copec-stepper ${className}`}>
      {steps.map((step, index) => {
        const stepIndex = index + 1;
        const currentStep = stepIndex === activeStep;
        const upcomingStep = stepIndex > activeStep;
        const finishedStep = activeStep > stepIndex;
        const finalStep = stepIndex === steps.length;

        const stepClassNames = [
          "copec-stepper__step",
          !upcomingStep && "active",
          error && currentStep && "error",
          activateLinks && "cursor-pointer"
        ];

        function createStepCircleContent() {
          if (error && currentStep) {
            return whiteCross;
          }

          if (finishedStep) {
            return finishedIcon;
          }

          return stepIndex;
        }
        return (
          <>
            <div onClick={()=>goToStep(index+1)} className={stepClassNames.filter(Boolean).join(" ")}>
              <span>{createStepCircleContent()}</span>
              <div className="copec-stepper__step-name">{step}</div>
            </div>
            {!finalStep && (
              <div
                className={`copec-stepper__line ${
                  finishedStep ? "active" : ""
                }`}
              />
            )}
          </>
        );
      })}
    </div>
  );
};
