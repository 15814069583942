import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import closeIcon from "../../assets/img/cross-icon.svg";
import { getLocaleStringNumber,parseBudat } from "../utils/utils";
import { 
  hideGreyBackground, 
  showGreyBackground,
  scrollTo,
  getPageYOffset
} from "../utils/windows";
import { customModalStyles } from "../utils/styles";

const CustomModal = ({ showModal, closeModal, children, acceptFunc }) => {
  const [pageYOffset, setPageYOffset] = useState(15)
  const [styles, setStyles] = useState({})

  useEffect(() => {
    if (showModal){
      showGreyBackground();
      getPageYOffset()
    }
    else hideGreyBackground();
  }, [showModal]);

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
  }, []);

  function receiveMessage(event) {
    const message = event.data;
   
    if(message.pageYOffset || message.pageYOffset === 0){
      let newPageYOffset = message.pageYOffset

      if((newPageYOffset + 600) > window.innerHeight){
        newPageYOffset = window.innerHeight - 600
        scrollTo(0, window.innerHeight - 600)
      }
      else if(newPageYOffset > 45 && newPageYOffset <= 80){
        newPageYOffset = 35
      }
      else if(newPageYOffset > 80 && newPageYOffset <= 100){
        newPageYOffset = 70
      }
      else if(newPageYOffset > 100){
        newPageYOffset = newPageYOffset - 76
        scrollTo(0, newPageYOffset)
      }

      const newStyles = customModalStyles(newPageYOffset);
      newStyles.content.paddingTop = 8;
      setStyles(newStyles)
      setPageYOffset(newPageYOffset);
    }
  }

  return (
    <Modal isOpen={showModal} onRequestClose={closeModal} style={styles}>
      <button className="modal__close-btn" onClick={closeModal}>
        <img src={closeIcon} alt="close modal" />
      </button>
      {children}
      <div className="modal__actions">
        <button className="main-button" onClick={acceptFunc || closeModal}>
          Aceptar
        </button>
      </div>
    </Modal>
  );
};

export const PedidosActivosTableMobileVolumenModal = ({
  data,
  description,
  total,
  isDinamo,
  ...props
}) => {
  function checkIfLast(key) {
    if (key + 1 === data.length) return true;

    for (let i = key + 1; i < data.length; i++) {
      if (data[i].liters > 0) return false;
    }

    return true;
  }

  return (
    <CustomModal {...props}>
      <div className="pedidos-activos-mobile-data-modal volumen">
        <p className="pedidos-activos-mobile-data-modal__title">
          Detalle Volúmenes
        </p>
        <p className="pedidos-activos-mobile-data-modal__info">{description}</p>

        {isDinamo && <>
          <div className="volumen-dinamo-container">
          <div className="content">
            <div className="title">Material</div>

            <div className="dbody">
              {data.map((material, key) => {
                return (
                  <>
                    <div className="material" key={key}>
                      <div className="nombre">{material.NOMBRE}</div>
                      <div className="entrada">
                        <div className="tipo">Solicitado</div>
                        <div className="volumen1">
                          {material.SOLICITADO / 1000 + " m3"}
                        </div>
                        <div className="precio">
                          {"$ " +
                            getLocaleStringNumber(
                              material.TOTAL_MATERIAL
                            )}
                        </div>
                      </div>
                      <div className="entrada">
                        <div className="tipo">Planificado</div>
                        <div className="volumen2">
                          {material.PLANIFICADO / 1000 + " m3"}
                        </div>
                        <div className="precio">
                          {/* {"$ " +
                            getLocaleStringNumber(
                              material.PLANIFICADO * material.PRECIO
                            )} */}
                            &nbsp;
                        </div>
                      </div>
                      <div className="entrada">
                        <div className="tipo">Entregado</div>
                        <div className="volumen3">
                          {material.ENTREGADO / 1000 + " m3"}
                        </div>
                        <div className="precio">
                          {"$ " +
                            getLocaleStringNumber(
                              material.TOTAL_MATERIAL_ENTREGADO
                            ,true)}
                        </div>
                      </div>
                    </div>
                    {key !== data.length - 1 ? <hr className="msep"></hr> : ""}
                  </>
                );
              })}
            </div>
          </div>
        </div>
        </>}

        {!isDinamo && (
          <table className="abonar-en-banco__table">
            <thead>
              <tr>
                <th>Mix de Productos</th>
                <th>Litros</th>
              </tr>
            </thead>
            <tr>
              <th className="separator-table-row-mobile" colSpan="2" />
            </tr>
            <tbody>
              {data.map((obj, key) => {
                return obj.liters > 0 ? (
                  <>
                    <tr className="data" key={key}>
                      <td>{obj.product}</td>
                      <td>{getLocaleStringNumber(obj.liters)}</td>
                    </tr>
                    {!checkIfLast(key) && (
                      <tr className="data separator">
                        <td className="trseparator align-left" colSpan="2">
                          <div className="line-sep"></div>
                        </td>
                      </tr>
                    )}
                  </>
                ) : null;
              })}
            </tbody>
            <tr>
              <th className="separator-table-row-mobile" colSpan="2" />
            </tr>
            <tfoot>
              <tr>
                <td>Total</td>
                <td>{total ? getLocaleStringNumber(total) : ""}</td>
              </tr>
            </tfoot>
          </table>
        )}
      </div>
    </CustomModal>
  );
};

export const PedidosActivosTableMobileRecalculoModal = ({
  data,
  description,
  total,
  ...props
}) => {
  return (
    <CustomModal {...props}>
      <div className="pedidos-activos-mobile-data-modal recalculo">
        <p className="pedidos-activos-mobile-data-modal__title">
          Recalculo Mix Reposición
        </p>
        <p className="pedidos-activos-mobile-data-modal__info">{description}</p>
        <table className="abonar-en-banco__table">
          <thead>
            <tr>
              <th>Producto</th>
              <th>Total a Pagar ($)</th>
            </tr>
          </thead>
          <tr>
            <th className="separator-table-row-mobile" colSpan="2" />
          </tr>
          <tbody>
            {data.map((obj, key) => {
              return (
                <>
                  <tr className="data" key={key}>
                    <td className="recalculo-data">
                      <span className="recalculo-name">{obj.name}</span>
                      <span className="recalculo-description">
                        {obj.description}
                      </span>
                      <span className="recalculo-info">
                        <span className="recalculo-volume">
                          {getLocaleStringNumber(obj.volume)} LTS.
                        </span>
                        <span className="recalculo-price">
                          $ {getLocaleStringNumber(obj.pricePerLiter)} / Lts.
                        </span>
                      </span>
                    </td>
                    <td>$ {getLocaleStringNumber(obj.total)}</td>
                  </tr>
                  {key + 1 < data.length && (
                    <tr>
                      <td className="mobile-tbody-row-separator" colSpan="2">
                        <div className="mobile-tbody-row-separator-inner" />
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
          <tr>
            <th className="separator-table-row-mobile" colSpan="2" />
          </tr>
          <tfoot>
            <tr>
              <td>Total</td>
              <td>{total ? getLocaleStringNumber(total) : ""}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </CustomModal>
  );
};

export const PedidosActivosTableMobileFechaModal = ({
  data,
  description,
  ...props
}) => {
  return (
    <CustomModal {...props}>
      <div className="pedidos-activos-mobile-data-modal recalculo">
        <p className="pedidos-activos-mobile-data-modal__title">
          Detalle
        </p>
        <p className="pedidos-activos-mobile-data-modal__info">{description}</p>
        <table className="abonar-en-banco__table">
          <thead>
            <tr>
              <th>Programación</th>
              <th>Fecha - Hora</th>
            </tr>
          </thead>
          <tr>
            <th className="separator-table-row-mobile" colSpan="2" />
          </tr>
          <tbody>
            <tr className="data">
              <td className="align-left">Inicio de carga<br></br>planificada</td>
              <td className="align-right">{parseBudat(data.FECHA_ESTIMADA_CARGA)}&nbsp;-&nbsp;{data.HORA_ESTIMADA_CARGA} </td>
            </tr>
            <tr>
                      <td className="mobile-tbody-row-separator" colSpan="2">
                        <div className="mobile-tbody-row-separator-inner" />
                      </td>
                    </tr>
            
                <tr className="data">
                  <td className="align-left">Fecha de llegada<br></br>programada</td>
                  <td className="align-right">{parseBudat(data.FECHA_LLEGADA_PROGRAMADA)}&nbsp;-&nbsp;{data.HORA_LLEGADA_PROGRAMADA}</td>
                </tr>
          </tbody>
        </table>
      </div>
    </CustomModal>
  );
};
