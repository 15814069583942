import React, { useState, useEffect } from "react";
import closeIcon from "../../assets/img/cross-icon.svg";
import Modal from "react-modal";
import CustomModal from "../CustomModal"
import { getTanquesSifoneadosConfirmacion, getResumenTanques } from '../utils/tanquesSifoneados';
import { hideGreyBackground, parentRedirect } from '../utils/windows'

export const ConfirmacionFluctuaciones = ({ 
  token,
  setStep,
  resetForm,
  configuracion, 
  tanques, 
  revision, 
  openEntradas, 
  setOpenEntradas, 
  openSalidas, 
  setOpenSalidas,
  openCloseModals,
  ultimaFluctuacion,
  viewPortHeight,
  callServiceHandler
}) => {
  const [observaciones, setObservaciones] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalText, setModalText] = useState('')
  const [modalIcon, setModalIcon] = useState('success')
  const [customModalStyles, setCustomModalStyles] = useState({})

  useEffect(() => {
    setCustomModalStyles({
      content: {
        top: "20px",
        padding: "0",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        boxSizing: "border-box",
        transform: "translateX(-50%)",
        maxHeight: (viewPortHeight - 150) + 'px',
        borderRadius: 9
      }
    })
  }, [])

  function closeSuccessModal(){
    hideGreyBackground()
    setShowSuccess(false)
    if(modalIcon === 'success')
      parentRedirect(process.env.REACT_APP_HISTORIAL_FLUCTUACIONES_PATH)
      //resetForm()
  }

  async function insertFluctuacion(){
    const tmpRevision = {...revision}
    tmpRevision.OBSERVACIONES = observaciones

    const url = process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/insert-fluctuacion"
    let result = await callServiceHandler(url, "POST", JSON.stringify(tmpRevision))
    if(result !== "error"){
      if(result.data.ESTADO === 200){
        setShowSuccess(true)
        setModalTitle("Fluctuación ingresada exitosamente")
        setModalText(`La Fluctuación desde ${revision.FECHA_DESDE} - ${revision.HORA_DESDE} hasta ${revision.FECHA_HASTA} - ${revision.HORA_HASTA} ha sido ingresada exitosamente`)
        setModalIcon("success")
      }
      else{
        setModalTitle("Error")
        setModalText(result.userMessage)
        setModalIcon("error")
      }
    }
    else{
      console.log("mostrando error")
    }
  }

  return (
    <div className="fluctuaciones-ingreso-mediciones-big-section">
      <p className="flutuaciones-section-title">
        CONFIRMACIÓN DE FLUCTUACIONES
      </p>
      <table className="flutuaciones-table tanks-table confirmation-table">
        <thead>
          <tr>
            <th>Tanque</th>
            <th>Material</th>
            <th>Medición anterior</th>
            <th>Entradas</th>
            <th>Salidas</th>
            <th>Volumen teórico</th>
            <th>Volumen medición</th>
            <th>Fluctuación (Lts)</th>
          </tr>
        </thead>
        <tbody>
          { configuracion && configuracion.TANQUES.map((tanque, key) =>
            getTanquesSifoneadosConfirmacion(tanque, key, revision.RESUMENES, revision.MEDICIONES, ultimaFluctuacion.MEDICIONES, configuracion.TANQUES)
          )}
        </tbody>
      </table>
      <p className="flutuaciones-section-title push-down">OBSERVACIONES</p>
      <textarea
        value={observaciones}
        rows="6"
        className="flutuaciones-textarea"
        maxLength="280"
        onChange={(e) =>
          setObservaciones(e.target.value)
        }
      />
      <p className="flutuaciones-textarea-count">{observaciones.length}/280</p>
      <div className="fluctuaciones-ingreso-mediciones-actions">
        <button className="main-button hallow red" onClick={() => setStep(4)}>
          Volver
        </button>
        <button className="main-button red" onClick={insertFluctuacion}>
          Confirmar
        </button>
      </div>

      <Modal isOpen={openEntradas} onRequestClose={() => openCloseModals(false, setOpenEntradas)} style={customModalStyles}>
        <div className="flutuaciones-modal fim-modal">
          <img
            className="close-modal"
            onClick={() => openCloseModals(false, setOpenEntradas)}
            src={closeIcon}
            alt="Close"
          />
          <div className="modal-info">
            <p className="flutuaciones-modal__title">
              <strong>Entradas</strong>
            </p>
          </div>
          <table className="flutuaciones-table tanks-table confirmation-table fim-table">
            <thead>
              <tr>
                <th>Tanque</th>
                <th>Material</th>
                <th>Camión<br />desde planta</th>
                <th>Calibraciones</th>
                <th>Movimientos<br />Bodega</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
            { configuracion && configuracion.TANQUES.map((tanque, key) =>
              getResumenTanques(tanque, key, revision.RESUMENES, 'entradas', configuracion.TANQUES)
            )}
            </tbody>
          </table>
        </div>
      </Modal>

      <Modal isOpen={openSalidas} onRequestClose={() => openCloseModals(false, setOpenSalidas)} style={customModalStyles}>
        <div className="flutuaciones-modal fim-modal">
          <img
            className="close-modal"
            onClick={() => openCloseModals(false, setOpenSalidas)}
            src={closeIcon}
            alt="Close"
          />
          <div className="modal-info">
            <p className="flutuaciones-modal__title">
              <strong>Salidas</strong>
            </p>
          </div>
          <table className="flutuaciones-table tanks-table confirmation-table fim-table">
            <thead>
              <tr>
                <th>Tanque</th>
                <th>Material</th>
                <th>Ventas</th>
                <th>Calibraciones</th>
                <th>Movimientos<br />de Bodega</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              { configuracion && configuracion.TANQUES.map((tanque, key) =>
                getResumenTanques(tanque, key, revision.RESUMENES, 'salidas', configuracion.TANQUES)
              )}
            </tbody>
          </table>
        </div>
      </Modal>

      <CustomModal
        displayModal = { showSuccess }
        closeModal = { closeSuccessModal }
        acceptFunc = { closeSuccessModal }
        modalTitle = { modalTitle }
        modalText = { modalText }
        modalIcon = { modalIcon }
      />

    </div>
  );
};
