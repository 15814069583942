import React, { useState, useEffect } from "react"
import Select from "react-select";

import HeaderData from "../HeaderData"
import HeaderBox from "../HeaderBox"
import saldoAzulIcon from "../../assets/img/saldo-azul.svg"
import imprimir from "../../assets/img/imprimir.svg"
import descargar from "../../assets/img/descargar.svg"
import { getPeriodosSince, getUpdateDate } from "../utils/monthNames"
import { SingleOption, customStylesTctTae } from "../utils/utils.js";
import { setInstalalaciones } from "../utils/get";


const LubricantesBluemax = ({ token, rutConcesionario, callServiceHandler, cabecera, showDetail, instalaciones }) => {

  const blue = {
    tipo:"bluemax",
    data:[
      {
        codigo:"TCT",
        descripcion:"BLUEMAX/10",
        saldo_inicial:0,
        despacho:1,
        ventas:10,
        saldo_final:100,
        stock:1000
      },
      {
        codigo:"TCT",
        descripcion:"BLUEMAX/20",
        saldo_inicial:0,
        despacho:2,
        ventas:20,
        saldo_final:200,
        stock:2000
      },
      {
        codigo:"TCT",
        descripcion:"BLUEMAX/30",
        saldo_inicial:0,
        despacho:3,
        ventas:30,
        saldo_final:300,
        stock:3000
      },
      {
        codigo:"TCT",
        descripcion:"BLUEMAX/40",
        saldo_inicial:0,
        despacho:4,
        ventas:40,
        saldo_final:400,
        stock:4000
      },
    ]
  }

  const lub = {
    tipo:"lubricante",
    data:[
      {
        codigo:"005",
        descripcion:"dvac 1440/5",
        saldo_inicial:0,
        despacho:5,
        ventas:50,
        saldo_final:500,
        stock:5000
      },
      {
        codigo:"006",
        descripcion:"dvac 1440/6",
        saldo_inicial:0,
        despacho:6,
        ventas:60,
        saldo_final:600,
        stock:6000
      },
      {
        codigo:"007",
        descripcion:"dvac 1440/7",
        saldo_inicial:0,
        despacho:7,
        ventas:70,
        saldo_final:700,
        stock:7000
      },
      {
        codigo:"008",
        descripcion:"dvac 1440/8",
        saldo_inicial:0,
        despacho:8,
        ventas:80,
        saldo_final:800,
        stock:8000
      },
    ]
  }

  const [filterText, setFilterText] = useState('')
  const [allPeriodos, setAllPeriodos] = useState([]);
  const [allEds, setAllEds] = useState([]);
  const [selectedEds, setSelectedEds] = useState()
  const [selectedPeriodo, setSelectedPeriodo] = useState()
  const [selectedFuel, setSelectedFuel] = useState({
    value: "bluemax",
    label: "Bluemax",
  });
  const [data, setData] = useState();
  

  useEffect(() => {
    const allPeriodos = getPeriodosSince(2022);
    setAllPeriodos(allPeriodos);
    setSelectedPeriodo(allPeriodos[0]);
  }, [token]);

  useEffect(() => {
    if (instalaciones !== "") {
      setInstalalaciones(instalaciones, setAllEds);
    }
  }, [instalaciones]);

  useEffect(() => {
    if (selectedFuel?.label === "Bluemax") {
      console.log(selectedFuel.label)
      setData(blue)
    }else{
      console.log(selectedFuel.label)
      setData(lub)
    }
  }, [selectedFuel]);

  function changeEds(e) {
    if (e !== selectedEds) {
      setSelectedEds(e);
    }
  }
  function changeSelectedPeriodo(e) {
    console.log(e)
    setSelectedPeriodo(e);
  }
  function changeSelectedFuel(e) {
    console.log(e)
    console.log(e.value)
    if (e !== selectedFuel) {
      setSelectedFuel(e)
    }
  }

  

  return (
    <>
      <div className="tct-tae-table-container bluemax-principal">
        <div className="filters-container">

          <div className="filter">
            <div className="filter-title">
              ESTACIÓN DE SERVICIO<span className="red-asterisk">*</span>
            </div>
            <div className="filter-input">
              <Select
                className="eds-select"
                value={selectedEds}
                onChange={(e) => changeEds(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allEds}
                components={{
                  SingleOption,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccionar"
                styles={customStylesTctTae}
              />
            </div>
          </div>
          <div className="filter mleft">
            <div className="filter-title">PERÍODO</div>
            <div className="filter-input">
              <Select
                className="eds-select"
                value={selectedPeriodo}
                onChange={(e) => changeSelectedPeriodo(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allPeriodos}
                components={{ SingleOption, IndicatorSeparator: () => null }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccionar"
                styles={customStylesTctTae}
              />
            </div>
          </div>
          {
            selectedFuel&&
              <div className="filter mleft">
                <div className="filter-title">COMBUSTIBLE</div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedFuel}
                    onChange={(e) => changeSelectedFuel(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={[
                      {
                        value: "bluemax",
                        label: "Bluemax",
                      },
                      {
                        value: "lubricante",
                        label: "Lubricante",
                      }
                    ]}
                    components={{ SingleOption, IndicatorSeparator: () => null }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesTctTae}
                  />
                </div>
              </div>
          }

          <div className="actions-container">
            <button className="icon-btn">
              <img src={descargar} />
            </button>
            <button className="icon-btn left-button">
              <img src={imprimir} />
            </button>
          </div>
        </div>

        <table className="tct-tae-table">
          <tr>
            <th>
              <div className="th-data width-86">
                <div className="th-text two-lines">Código<br />Producto</div>
              </div>
            </th>
            <th>
              <div className="th-data width-169">
                <div className="th-text">Descripción</div>
              </div>
            </th>
            <th>
              <div className="th-data width-105">
                <div className="th-text two-lines">Saldo inicial<br />(cantidad)</div>
              </div>
            </th>
            <th>
              <div className="th-data width-105">
                <div className="th-text two-lines">Despacho<br />(cantidad)</div>
              </div>
            </th>
            <th>
              <div className="th-data width-105">
                <div className="th-text two-lines">Ventas<br />(cantidad)</div>
              </div>
            </th>
            <th>
              <div className="th-data width-105">
                <div className="th-text two-lines">Saldo Final<br />(cantidad)</div>
              </div>
            </th>
            <th>
              <div className="th-data width-125">
                <div className="th-text two-lines">Stock Asignado<br />(cantidad)</div>
              </div>
            </th>
          </tr>
          <tr className="table-separator-row-border-top">
            <td colSpan="100%" />
          </tr>
          <tbody>
            {
              data&&
                data.data.map(info=>{
                  return(
                    <>
                      <tr className="data row-with-rounded-borders">
                        <td className="align-left">{info.codigo}</td>
                        {
                          data.tipo==="bluemax"?
                            <td className="align-left table-link" onClick={() => showDetail('BLUEMAX/10')}>{info.descripcion}</td>
                          :
                            <td className="align-left">{info.descripcion} s</td>
                        }
                        <td className="align-left">{info.saldo_inicial} Un.</td>
                        <td className="align-left">{info.despacho} Un.</td>
                        <td className="align-left">{info.ventas} Un.</td>
                        <td className="align-left">{info.saldo_final} Un.</td>
                        <td className="align-left">{info.stock} Un.</td>
                      </tr>
                      <tr className="table-separator-row-border-top">
                        <td colSpan="100%" />
                      </tr>
                    </>
                  )
                })
            }
          </tbody>
        </table>
      </div>
    </>
  );
}

export default LubricantesBluemax;
