import React, { useRef, useState, useEffect } from 'react';
import TimePicker from 'react-time-picker';

const TimePickerLibrary = ({initialHour, onSelect, disabled = false}) => {

  const [inputHour, setInputHour] = useState();
  const [visible, setVisible] = useState(false);
  const nodeRef = useRef(null);
  const [hour, setHour] = useState();

  useEffect(() => {
    setHour(initialHour)
  }, [initialHour])

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!nodeRef.current.contains(e.target)) {
        setVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [nodeRef]);

  const handleButton = () => {
    if (!disabled) {
      if (inputHour) {
        setHour(inputHour)
        onSelect(inputHour)
        setVisible(false)
      }else{
        setHour(initialHour)
        onSelect(initialHour)
        setVisible(false)
      }
    }
  }

  const displayInput = () =>{
    if (!disabled) {
      setVisible(true)
    }
  }

  return (
    <div className={`time-picker-library-container ${disabled? 'time-picker-library-container-disabled': ''}`} ref={nodeRef}>
      {/* <h1>La hora es: {hour}</h1> */}
      <div className='time-container' onClick={displayInput}>
        <p>{hour?hour:"00:00"}</p>
      </div>
      {visible && 
      <div className='time-picker'>
        <TimePicker onChange={setInputHour} value={hour} disabled={disabled} disableClock={true} clearIcon={null}/>
        <button className='time-picker-accept' disabled={disabled} onClick={handleButton} style={disabled?{cursor:"default"}:{cursor:"pointer"}}>Aceptar</button>
      </div>
      }
    </div>
  );
}

export default TimePickerLibrary;
