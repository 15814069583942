import React, {useEffect, useState} from "react";
import { getTanquesSifoneadosWithLocalNumberCierreDiario } from '../utils/tanquesSifoneados';
import { get3DecimalsFloatWithDelimiter } from '../utils/utils.js'
import errorIcon from "../../assets/img/atencion_rojo.svg";

export const IngresoMediciones = ({ setStep, 
  selectedEds, 
  configuracion, 
  mediciones, 
  setMediciones, 
  horas, 
  setHoras,
  fechaCierre,
  pagador,
  callServiceHandler,
  disableMedicion,
  isROImpersonate,
  selectedCierre,
  selectedEstadoCierre
 }) => {
  
  const [errores, setErrores] = useState([])
  const [confirmarEnabled, setConfirmarEnabled] = useState(false)

  function limpiar(){
    const tmpMediciones = {}

    Object.keys(mediciones).map((key) => {
      tmpMediciones[key] = {
        ID_MATERIAL: mediciones[key].ID_MATERIAL,
        MATERIAL: mediciones[key].MATERIAL,
        SIFON_1: mediciones[key].SIFON_1,
        SIFON_2: mediciones[key].SIFON_2
      }
    })
    setMediciones(tmpMediciones)    
  }

  function calculateTotal(entrada, medicion, salida){
    entrada = parseFloat(entrada) || 0
    salida = parseFloat(salida) || 0
    
    if(medicion !== null && medicion !== undefined && medicion !== false){
      medicion = medicion.replace(/\./g, "")
      medicion = medicion.replace(/\,/g, '.')
    }
    
    medicion = parseFloat(medicion) || 0
    return entrada + medicion - salida
  }

  async function valuesChange(name, value, key){
    let tmpMedicion = mediciones[key]
    if(!tmpMedicion)
      tmpMedicion = {}
    
    if(name === 'medicion'){
      value = value.replace(/\./g, "")
      value = value.replace(/\,/g, '')
      value = get3DecimalsFloatWithDelimiter(value)
      if(tmpMedicion.total !== null && tmpMedicion.total !== undefined){
        tmpMedicion.total = calculateTotal(tmpMedicion.entrada_pos_medicion, value, tmpMedicion.salida_pos_medicion)
      }
    }

    else if(name === 'hora'){
      const url =
        process.env.REACT_APP_FLUCTUACIONES_API +
        "/fluctuaciones/cierre-diario-buscar-medicion-proyectada?ID_EDS=" + selectedEds.value + 
        "&FECHA=" + fechaCierre + 
        "&ID_PAGADOR=" + pagador + 
        "&TANQUE=" + key + 
        "&MATERIAL=" + mediciones[key].ID_MATERIAL + 
        "&HORA=" + value.replace(':','') + "00";

      let response = await callServiceHandler(url, "GET");

      if(response !== "error"){
        tmpMedicion.entrada_pos_medicion = response.data.entrada_pos_medicion
        tmpMedicion.salida_pos_medicion = response.data.salida_pos_medicion
        tmpMedicion.total = calculateTotal(response.data.entrada_pos_medicion, tmpMedicion.medicion, response.data.salida_pos_medicion)
      } 
    }

    if(value !== false){
      tmpMedicion[name] = value
      setMediciones({...mediciones, [key]: tmpMedicion})
    }
  }

  async function checkStep(){
    const tmpErrores = []
    let error1 = false, error2 = false
    configuracion.TANQUES.forEach((tanque) => {
      if(mediciones[tanque.ID]){
        const volumen = parseInt(mediciones[tanque.ID]) || 0
        const capacidad = tanque.CAPACIDAD
        if (volumen > capacidad) error2 = true
      }
      else {
        error1 = true
      }
    })
    
    if(error1)
      tmpErrores.push("Ingresa todos los datos.")
    if(error2)
      tmpErrores.push("No es posible ingresar una medición con un volumen mayor a la capacidad del tanque.")

    setErrores(tmpErrores)
    if(tmpErrores.length === 0){
        const data={
          ID_EDS:selectedEds.value,
          FECHA:fechaCierre,
          PASO:4,
          ID_PAGADOR:pagador,
          mediciones:mediciones
        }
        if(isROImpersonate) data.VISUALIZAR="SI";
  
        const url =
          process.env.REACT_APP_FLUCTUACIONES_API +
          "/fluctuaciones/cierre-diario-ingresar-paso";
  
        let response = await callServiceHandler(url,"POST",JSON.stringify(data));
        if(response!=="error"){
          setStep(5)
        }
    }
  }

  useEffect(() => {
    if(mediciones && Object.keys(mediciones).length > 0){
      let tmpEnabled = true
      Object.keys(mediciones).map((key) => {
        if(mediciones[key].hora === null || mediciones[key].hora === undefined || mediciones[key].hora === '')
          tmpEnabled = false
        if(mediciones[key].medicion === null || mediciones[key].medicion === undefined || mediciones[key].medicion === '')
          tmpEnabled = false
      })
      setConfirmarEnabled(tmpEnabled)
    }
  }, [mediciones]);

  return (
    <div className="cierre-diario-ingreso-mediciones">
      <p className="flutuaciones-section-title">INGRESO DE MEDICIONES</p>
      <table className="flutuaciones-table tanks-table">
        <thead>
          <tr>
            <th>Tanque</th>
            <th className="no-padding-left-th">Material</th>
            <th>Hora</th>
            <th className="cierre-diario-table-align-right">Medición (Lts)</th>
            <th className="cierre-diario-table-align-right">Entradas post<br />medición</th>
            <th className="cierre-diario-table-align-right">Salidas post<br />medición</th>
            <th className="cierre-diario-table-grey-border-header"></th>
            <th className="measurement-column cierre-diario-table-align-right last-column">
              Medición física (Lts)<br />Proyectada a las 23:59
            </th>
          </tr>
        </thead>
        <tbody>
          { !selectedEds &&
            <>
              <tr>
                <td className="horizontal-separator" colSpan="8" />
              </tr>
              <tr>
                <td colspan="8" 
                  className="cell-border-top-left 
                    cell-border-top-right 
                    cell-border-bottom-left 
                    cell-border-bottom-right"
                >
                  Seleccione una Estación de Servicio para ver la información.
                </td>
              </tr>
            </>
          }
          { selectedEds && configuracion && configuracion.TANQUES.filter(tanque=>tanque.ID!=="TAE_RE").map((tanque, key) =>
            getTanquesSifoneadosWithLocalNumberCierreDiario(tanque, mediciones, key, valuesChange, configuracion.TANQUES)
          )}
        </tbody>
      </table>

      <div className="important-message">
        <span className="title">Importante:&nbsp;</span>
        <span>
          El dato de la medición física proyectada a las 23:59, será considerado para el calculo de Fluctuaciones
        </span>
      </div>

      {
        errores.map((error) =>
        <div className="table-bottom-error">
          <img src={errorIcon} alt="error" />
          <p>{error}</p>
        </div>
      )}

      <div className="fluctuaciones-ingreso-mediciones-actions">
        <button className="main-button hallow" onClick={() => setStep(3)}>
          Volver
        </button>
        <button className="main-button hallow" onClick={limpiar}>
          Limpiar
        </button>
        <button className="main-button cierre-diario-main-buttons" onClick={ checkStep } disabled={!confirmarEnabled || disableMedicion || selectedEstadoCierre==="CERRADO"}>
          Confirmar
        </button>
      </div>
    </div>
  );
};
