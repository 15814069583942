import React, { useState, useEffect, useRef } from "react";
import CustomHighlighter from "./CustomHighlighter";
import Flecha_down from "../assets/img/Flecha_down.svg";
import Flecha_up from "../assets/img/Flecha_up.svg";
import CarrotDown from "../assets/img/caret-down-blue.svg";
import {
  customFilter,
  orderArray,
  getLocaleStringNumber,
} from "./utils/utils.js";
import { parentRedirect } from "./utils/windows";

const ResumenTable = (props) => {
  const [lineaProductoFilter, setLineaProductoFilter] = useState("Todos");
  const [isLineaProductoFilterActive, setIsLineaProductoFilterActive] =
    useState(false);
  const [montoVigente, setMontoVigente] = useState("");
  const [montoVencido, setMontoVencido] = useState("");
  const [abonosNoCompensados, setAbonosNoCompensados] = useState("");
  const [saldoCuenta, setSaldoCuenta] = useState("");
  const [saldoDocumentado, setSaldoDocumentado] = useState("");

  const [selectedMontoVigente, setSelectedMontoVigente] = useState("Todos");
  const [selectedMontoVencido, setSelectedMontoVencido] = useState("Todos");
  const [selectedAbonosNoCompensados, setSelectedAbonosNoCompensados] =
    useState("Todos");
  const [selectedSaldoCuenta, setSelectedSaldoCuenta] = useState("Todos");
  const [selectedSaldoDocumentado, setSelectedSaldoDocumentado] =
    useState("Todos");
  const [updateTableDummy, setUpdateTableDummy] = useState(0);

  const allProducts = props.products;
  const productsFilter = props.productsFilter;
  const totals = props.totals;
  const filterText = props.filterText;
  const filteredProducts = props.filteredProducts;
  const setFilteredProducts = props.setFilteredProducts;
  const productsLoaded = props.productsLoaded;
  const redirect = props.redirect;
  const selectedEds = props.selectedEds;
  const tableRef = props.tableRef;
  const isDesktopRef = useRef(window.innerWidth > process.env.REACT_APP_MOBILE_BREAK_POINT);

  useEffect(() => {
    setFilteredProducts(props.products);
  }, [props.products]);

  useEffect(() => {
    setUpdateTableDummy(updateTableDummy + 1);
  }, [filteredProducts]);

  useEffect(() => {
    filterr();
  }, [
    lineaProductoFilter,
    montoVigente,
    selectedMontoVigente,
    montoVencido,
    selectedMontoVencido,
    abonosNoCompensados,
    selectedAbonosNoCompensados,
    saldoCuenta,
    selectedSaldoCuenta,
    saldoDocumentado,
    selectedSaldoDocumentado,
    filterText,
  ]);

  function filterr() {
    let productsArr;

    if (lineaProductoFilter.length === 0 || lineaProductoFilter === "Todos")
      productsArr = allProducts;
    else {
      const values = [];
      lineaProductoFilter.forEach(function (filter) {
        values.push(filter.value);
      });

      productsArr = [];

      allProducts.forEach(function (prod) {
        if (values.includes(prod.LINEA_PRODUCTO)) productsArr.push(prod);
      });
    }

    if (selectedMontoVigente !== "Todos" && montoVigente !== "")
      productsArr = customFilter(
        productsArr,
        montoVigente,
        selectedMontoVigente,
        "VIGENTE"
      );

    if (selectedMontoVencido !== "Todos" && montoVencido !== "")
      productsArr = customFilter(
        productsArr,
        montoVencido,
        selectedMontoVencido,
        "VENCIDO"
      );

    if (selectedAbonosNoCompensados !== "Todos" && abonosNoCompensados !== "")
      productsArr = customFilter(
        productsArr,
        abonosNoCompensados,
        selectedAbonosNoCompensados,
        "ABONO_NO_COMPENSADO"
      );

    if (selectedSaldoCuenta !== "Todos" && saldoCuenta !== "")
      productsArr = customFilter(
        productsArr,
        saldoCuenta,
        selectedSaldoCuenta,
        "SALDO_CTA_CTE"
      );

    if (selectedSaldoDocumentado !== "Todos" && saldoDocumentado !== "")
      productsArr = customFilter(
        productsArr,
        saldoDocumentado,
        selectedSaldoDocumentado,
        "SALDO_DOCUMENTADO"
      );

    if (filterText !== "") {
      let filterTextLower = filterText.toLowerCase();
      filterTextLower = filterTextLower.replaceAll(".", "");
      productsArr = productsArr.filter((prod) => {
        return (
          prod.LINEA_PRODUCTO.toLowerCase().includes(filterTextLower) ||
          prod.VIGENTE.toString().toLowerCase().includes(filterTextLower) ||
          prod.VENCIDO.toString().toLowerCase().includes(filterTextLower) ||
          prod.ABONO_NO_COMPENSADO.toString()
            .toLowerCase()
            .includes(filterTextLower) ||
          prod.SALDO_CTA_CTE.toString()
            .toLowerCase()
            .includes(filterTextLower) ||
          prod.SALDO_DOCUMENTADO.toString()
            .toLowerCase()
            .includes(filterTextLower)
        );
      });
    }

    setFilteredProducts(productsArr);
  }

  function lineaProductoFilterChange(e) {
    setLineaProductoFilter(e);

    if (e.length === 0) {
      setIsLineaProductoFilterActive(false);
    } else {
      setIsLineaProductoFilterActive(true);
    }
  }

  const orderTable = (filterkey, order, parse) => {
    const arr = orderArray(filterkey, allProducts, order, parse);
    props.setProducts(arr);
  };

  const toggleMobileSection = ({ currentTarget }) => {
    currentTarget.classList.toggle("expanded-section");
  };

  const totalVigentesValue = `$ ${
    totals.VIGENTE && getLocaleStringNumber(totals.VIGENTE)
  }`;
  const totalVencidoValue = `$ ${
    totals.VENCIDO && getLocaleStringNumber(totals.VENCIDO)
  }`;
  const totalAbonoValue = `$ ${
    totals.ABONO_NO_COMPENSADO &&
    getLocaleStringNumber(totals.ABONO_NO_COMPENSADO)
  }`;
  const totalSaldoCtaValue = `$ ${
    totals.SALDO_CTA_CTE && getLocaleStringNumber(totals.SALDO_CTA_CTE)
  }`;
  const totalSaldoDocumentadoValue = `$ ${
    totals.SALDO_DOCUMENTADO && getLocaleStringNumber(totals.SALDO_DOCUMENTADO)
  }`;

  const redirectBasedOnRoute = (route) => () => redirect(route);

  const totalSaldoCtaRedirect = () =>
    parentRedirect(
      `${process.env.REACT_APP_CARTOLAS_PATH}?eds=${selectedEds}&type=saldo`
    );
  const totalSaldoDocumentadoRedirect = () =>
    parentRedirect(`${process.env.REACT_APP_CARTOLAS_PATH}?eds=${selectedEds}`);

  return isDesktopRef.current ? (
    <div className="resumen-table" ref={tableRef}>
      <table>
        <tr>
          <th>
            <div className="th-data">
              Línea Producto{" "}
              <div className="arrows">
                <img
                  onClick={() => orderTable("LINEA_PRODUCTO", "asc", false)}
                  src={Flecha_up}
                ></img>
                <img
                  onClick={() => orderTable("LINEA_PRODUCTO", "desc", false)}
                  src={Flecha_down}
                ></img>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              Monto vigente{" "}
              <div className="arrows">
                <img
                  onClick={() => orderTable("VIGENTE", "asc", false)}
                  src={Flecha_up}
                ></img>
                <img
                  onClick={() => orderTable("VIGENTE", "desc", false)}
                  src={Flecha_down}
                ></img>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              Monto vencido{" "}
              <div className="arrows">
                <img
                  onClick={() => orderTable("VENCIDO", "asc", false)}
                  src={Flecha_up}
                ></img>
                <img
                  onClick={() => orderTable("VENCIDO", "desc", false)}
                  src={Flecha_down}
                ></img>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              Abonos No Compensados{" "}
              <div className="arrows">
                <img
                  onClick={() =>
                    orderTable("ABONO_NO_COMPENSADO", "asc", false)
                  }
                  src={Flecha_up}
                ></img>
                <img
                  onClick={() =>
                    orderTable("ABONO_NO_COMPENSADO", "desc", false)
                  }
                  src={Flecha_down}
                ></img>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              Saldo Cuenta Cte.{" "}
              <div className="arrows">
                <img
                  onClick={() => orderTable("SALDO_CTA_CTE", "asc", false)}
                  src={Flecha_up}
                ></img>
                <img
                  onClick={() => orderTable("SALDO_CTA_CTE", "desc", false)}
                  src={Flecha_down}
                ></img>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              Saldo Documentado{" "}
              <div className="arrows">
                <img
                  onClick={() => orderTable("SALDO_DOCUMENTADO", "asc", false)}
                  src={Flecha_up}
                ></img>
                <img
                  onClick={() => orderTable("SALDO_DOCUMENTADO", "desc", false)}
                  src={Flecha_down}
                ></img>
              </div>
            </div>
          </th>
        </tr>
        <tr className="table-separator-row-border-top"><td /></tr>
        <tbody key={`tablebody-${updateTableDummy}`}>
          {filteredProducts.map((prod, key) => (
            <tr key={key} className="data">
              <td className="align-left">
                <CustomHighlighter text={filterText}>
                  {prod.LINEA_PRODUCTO}
                </CustomHighlighter>
              </td>
              <td
                className="align-right negative"
              >
                <CustomHighlighter text={filterText}>
                  {`$ ${getLocaleStringNumber(prod.VIGENTE,true)}`}
                </CustomHighlighter>
              </td>

              <td
                className="align-right negative"
              >
                <CustomHighlighter text={filterText}>
                  {`$ ${getLocaleStringNumber(prod.VENCIDO,true)}`}
                </CustomHighlighter>
              </td>

              <td
                className="align-right abonos-no-compensados"
              >
                <CustomHighlighter text={filterText}>
                  {`$ ${getLocaleStringNumber(prod.ABONO_NO_COMPENSADO,true)}`}
                </CustomHighlighter>
              </td>

              <td
                className="align-right"
              >
                <CustomHighlighter text={filterText}>
                  {`$ ${getLocaleStringNumber(prod.SALDO_CTA_CTE,true)}`}
                </CustomHighlighter>
              </td>
              <td
                className="align-right"
              >
                <CustomHighlighter text={filterText}>
                  {`$ ${getLocaleStringNumber(prod.SALDO_DOCUMENTADO,true)}`}
                </CustomHighlighter>
              </td>
            </tr>
          ))}
          {productsLoaded && allProducts.length === 0 ? (
            <tr className="align-center">
              <p className="align-center">No hay movimientos que mostrar</p>
            </tr>
          ) : (
            <></>
          )}
          </tbody>
          <tr className="table-separator-row-border-bottom"><td /></tr>
          <tr className="table-separator-row-border-top"><td /></tr>
          <tbody>
          <tr className="total">
            <td className="align-left">Total</td>

            <td
              className="align-right negative"
            >
              <span
                className="cursor-pointer"
                onClick={redirectBasedOnRoute("vigentes")}
              >
                 {totals.VIGENTE||totals.VIGENTE===0?totalVigentesValue:"Cargando..."}
              </span>
            </td>

            <td
              className="align-right negative"
            >
              <span
                className="cursor-pointer"
                onClick={redirectBasedOnRoute("vencidos")}
              >
                {totals.VENCIDO||totals.VENCIDO===0?totalVencidoValue:"Cargando..."}
              </span>
            </td>

            <td
              className="align-right abonos-no-compensados"
            >
              <span
                className="cursor-pointer"
                onClick={redirectBasedOnRoute("abonos_no_compensados")}
              >
                {totals.ABONO_NO_COMPENSADO||totals.ABONO_NO_COMPENSADO===0?totalAbonoValue:"Cargando..."}
              </span>
            </td>

            <td
              className="align-right"
            >
              <span className="cursor-pointer" onClick={totalSaldoCtaRedirect}>
                {totals.SALDO_CTA_CTE||totals.SALDO_CTA_CTE===0?totalSaldoCtaValue:"Cargando..."}
              </span>
            </td>

            <td
              className="align-right"
            >
              <span
                className="cursor-pointer"
                onClick={totalSaldoDocumentadoRedirect}
              >
                {totals.SALDO_DOCUMENTADO||totals.SALDO_DOCUMENTADO===0?totalSaldoDocumentadoValue:"Cargando..."}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <div className="mobile-table">
      {filteredProducts.map((prod, key) => (
        <div className="mobile-table__item" key={key}>
          <p className="mobile-table__item-name">{prod.LINEA_PRODUCTO}</p>
          <div className="mobile-table__item-content">
            <button
              className="mobile-table__item-button"
              onClick={toggleMobileSection}
            >
              <img src={CarrotDown} alt="expand" />
            </button>
            <div className="mobile-table__item-content-list">
              <div className="mobile-table__item-content-list-item">
                <p className="mobile-table__item-content-list-item-title">
                  Monto vencido
                </p>
                <p className="mobile-table__item-content-list-item-value red">{`$ ${getLocaleStringNumber(
                  prod.VENCIDO,true
                )}`}</p>
              </div>
              <div className="mobile-table__item-content-list-item">
                <p className="mobile-table__item-content-list-item-title">
                  Monto vigente
                </p>
                <p className="mobile-table__item-content-list-item-value green">
                  {`$ ${getLocaleStringNumber(prod.VIGENTE,true)}`}
                </p>
              </div>
              <div className="mobile-table__item-content-list-item">
                <p className="mobile-table__item-content-list-item-title">
                  Abonos No Compensados
                </p>
                <p className="mobile-table__item-content-list-item-value red">
                  {`$ ${getLocaleStringNumber(prod.ABONO_NO_COMPENSADO,true)}`}
                </p>
              </div>
              <div className="mobile-table__item-content-list-item">
                <p className="mobile-table__item-content-list-item-title">
                  Saldo Cuenta Cte.
                </p>
                <p className="mobile-table__item-content-list-item-value">
                  {`$ ${getLocaleStringNumber(prod.SALDO_CTA_CTE,true)}`}
                </p>
              </div>
              <div className="mobile-table__item-content-list-item">
                <p className="mobile-table__item-content-list-item-title">
                  Saldo Documentado
                </p>
                <p className="mobile-table__item-content-list-item-value">
                  {`$ ${getLocaleStringNumber(prod.SALDO_DOCUMENTADO,true)}`}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="mobile-table__item">
        <p className="mobile-table__item-name">Total</p>

        <div className="mobile-table__item-content">
          <button
            className="mobile-table__item-button"
            onClick={toggleMobileSection}
          >
            <img src={CarrotDown} alt="expand" />
          </button>
          <div className="mobile-table__item-content-list">
            <div className="mobile-table__item-content-list-item">
              <p className="mobile-table__item-content-list-item-title">
                Monto vencido
              </p>
              <p
                className="mobile-table__item-content-list-item-value link red"
                onClick={redirectBasedOnRoute("vencidos")}
              >
              {totalVencidoValue}
              </p>
            </div>
            <div className="mobile-table__item-content-list-item">
              <p className="mobile-table__item-content-list-item-title">
                Monto vigente
              </p>
              <p
                className="mobile-table__item-content-list-item-value link green"
                onClick={redirectBasedOnRoute("vigentes")}
              >
              {totalVigentesValue}
              </p>
            </div>
            <div className="mobile-table__item-content-list-item">
              <p className="mobile-table__item-content-list-item-title">
                Abonos No Compensados
              </p>
              <p
                className="mobile-table__item-content-list-item-value link red"
                onClick={redirectBasedOnRoute("abonos_no_compensados")}
              >
                {totalAbonoValue}
              </p>
            </div>
            <div className="mobile-table__item-content-list-item">
              <p className="mobile-table__item-content-list-item-title">
                Saldo Cuenta Cte.
              </p>
              <p
                className="mobile-table__item-content-list-item-value"
                /* onClick={totalSaldoCtaRedirect} */
              >
                {totalSaldoCtaValue}
              </p>
            </div>
            <div className="mobile-table__item-content-list-item">
              <p className="mobile-table__item-content-list-item-title">
                Saldo Documentado
              </p>
              <p
                className="mobile-table__item-content-list-item-value"
                /* onClick={totalSaldoDocumentadoRedirect} */
              >
                {totalSaldoDocumentadoValue}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumenTable;
