import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import {
  SingleOption,
  customStylesPedidos,
  getLocaleStringNumber,
  getForma
} from "../utils/utils.js";
import { allMateriales } from "../utils/materiales";
import Pdf from "react-to-pdf";
import PrintComponent from "../PrintComponent";
import descargar from "../../assets/img/descargar.svg";

const ResumenPeriodo = (props) => {
  const rutConcesionario = props.rutConcesionario;
  const token = props.token;

  const allEds = props.allEds;
  const selectedEds = props.selectedEds;
  const changeEds = props.changeEds;
  const allPeriodos = props.allPeriodos;
  const selectedPeriodo = props.selectedPeriodo;
  const changePeriodo = props.changePeriodo;
  const pedidosPeriodo = props.pedidosPeriodo;
  const isDinamo = props.isDinamo;

  const [g93, setG93] = useState();
  const [g95, setG95] = useState();
  const [g97, setG97] = useState();
  const [pd, setPd] = useState();
  const [k, setK] = useState();

  const [reposicion, setReposicion] = useState([]);
  const [cons, setCons] = useState([]);
  const [totalAPagar, setTotalAPagar] = useState({});

  const ref = useRef(null);

  useEffect(() => {
    const arr93 = [];
    const arr95 = [];
    const arr97 = [];
    const arrPd = [];
    const arrK = [];
    pedidosPeriodo.forEach((pedido) => {
      if (pedido.DETALLE) {
        const detalle = pedido.DETALLE;
        detalle.forEach((item) => {
          if (allMateriales[item.ID_MATERIAL].class === "sp93") {
            arr93.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "sp95") {
            arr95.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "sp97") {
            arr97.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "pd") {
            arrPd.push(item);
          }
          if (allMateriales[item.ID_MATERIAL].class === "k") {
            arrK.push(item);
          }
        });
      }
    });
    setG93(arr93);
    setG95(arr95);
    setG95(arr97);
    setPd(arrPd);
    setK(arrK);
    console.log(arr93);
    console.log(arr95);
    console.log(arr97);
    console.log(arrPd);
    console.log(arrK);

    const tctAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZTCT",
      "Reposición TCT"
    );
    const taeAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZTAE",
      "Reposición TAE"
    );
    const cuponAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZCUP",
      "Reposición Cupón Electrónico"
    );
    const ffaaAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZFFA",
      "Reposición FF.AA."
    );
    const muevoAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZCEM",
      "Reposición Muevo Empresa"
    );
    const astorageAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZPAS",
      "Almacenamiento Storage"
    );
    const storageAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZPCS",
      "Storage"
    );

    const consigAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZRCO",
      "Consignación"
    );

     //solo dinamo
     const consigAuxD = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZC07",
      "Consignación"
    );

    const conceAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZFES",
      "Concesión"
    );

    //solo dinamo
    const conceAuxD = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZC08",
      "Concesión"
    );

    const constAux = getForma(
      arr93,
      arr95,
      arr97,
      arrPd,
      arrK,
      "ZCSC",
      "Constitución"
    );

    const repos = [
      tctAux,
      taeAux,
      cuponAux,
      ffaaAux,
      muevoAux,
      astorageAux,
      storageAux,
    ];
    const con = [consigAux, conceAux, constAux, consigAuxD, conceAuxD];

    setReposicion(repos);
    setCons(con);
    setTotalAPagar({
      g93: getTotal(arr93),
      g95: getTotal(arr95),
      g97: getTotal(arr97),
      pd: getTotal(arrPd),
      k: getTotal(arrK),
      total:
        getTotal(arr93) +
        getTotal(arr95) +
        getTotal(arr97) +
        getTotal(arrPd) +
        getTotal(arrK),
    });
  }, [pedidosPeriodo]);

  const getLitros = (array, forma) => {
    let litros = 0;
    array.forEach((pedido) => {
      if (pedido.ITEM_CATEG === forma) {
        if(isDinamo){
          litros += pedido.CANTIDAD_ENTREGADA;
        }else{
          litros += pedido.CANTIDAD_SOLICITADA;
        }
      }
    });
    return litros;
  };

  const getLitrosProductoR = (producto) => {
    let litros = 0;
    reposicion.forEach((forma) => {
      if (forma[producto] > 0) {
        litros += forma[producto];
      }
    });
    return litros;
  };

  const getLitrosProductoC = (producto) => {
    let litros = 0;
    cons.forEach((forma) => {
      if (forma[producto] > 0) {
        litros += forma[producto];
      }
    });
    return litros;
  };

  const getTotal = (arreglo) => {
    let total = 0;

    arreglo.forEach((p) => {
      if (p.ITEM_CATEG === "ZFES" || p.ITEM_CATEG === "ZRCO" || p.ITEM_CATEG === "ZC08") {
        if(isDinamo){
          total += p.PRECIO_TOTAL_MATERIAL_ENTREGADO;
        }else{
          total += p.PRECIO_TOTAL_MATERIAL;
        }
        
      }
    });
    return total;
  };



  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (token && rutConcesionario) {
      }
    } else {
      if (token) {
      }
    }
  }, [token, rutConcesionario]);

  const closeResumen = () => {
    props.setDisplayResumenPeriodo(false);
  };

  return (
    <>
      <div className="resumen-periodo-pedidos" ref={ref}>
        <h1>Resumen período</h1>
        <hr className="title-separator"></hr>

        <div className="historialp-options">
          <div className="filters-container">
            <div className="filter">
              <div className="filter-title">ESTACIÓN DE SERVICIO</div>
              <div className="filter-input">
                <Select
                  className="eds-select"
                  value={selectedEds}
                  onChange={(e) => changeEds(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allEds}
                  components={{
                    SingleOption,
                    IndicatorSeparator: () => null,
                  }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesPedidos}
                />
              </div>
            </div>
            <div className="filter filter-with-margin-left">
              <div className="filter-title">PERÍODO</div>
              <div className="filter-input">
                <Select
                  className="eds-select"
                  value={selectedPeriodo}
                  onChange={(e) => changePeriodo(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allPeriodos}
                  components={{
                    SingleOption,
                    IndicatorSeparator: () => null,
                  }}
                  isClearable={false}
                  autosize={false}
                  styles={customStylesPedidos}
                />
              </div>
            </div>
          </div>
        </div>

        {selectedPeriodo && <table>
          <thead>
            <tr>
              <th>Forma&nbsp;Comercialización</th>
              <th className="align-right">G93RM</th>
              <th className="align-right">G95RM</th>
              <th className="align-right">G97RM</th>
              <th className="align-right">PDUA1</th>
              <th className="align-right">Kerosene</th>
              <th className="align-right">Total</th>
            </tr>
          </thead>
          <tr className="separator-table-row">
            <th colSpan="4" />
          </tr>
          {!isDinamo && <>
              <tbody>
              {reposicion.filter((forma)=>forma.total>0).map((forma, key) =>
                (<>
                  <tr key={key} className="data">
                    <td className="align-left">{forma.name}</td>
                    <td className="align-right">{getLocaleStringNumber(forma.g93)}</td>
                    <td className="align-right">{getLocaleStringNumber(forma.g95)}</td>
                    <td className="align-right">{getLocaleStringNumber(forma.g97)}</td>
                    <td className="align-right">{getLocaleStringNumber(forma.pd)}</td>
                    <td className="align-right">{getLocaleStringNumber(forma.k)}</td>
                    <td className="align-right">{getLocaleStringNumber(forma.total)}</td>
                  </tr>
                  {reposicion.filter((forma)=>forma.total>0).length-1!==key && <>
                    <tr>
                    <td colSpan="100%" className="tdsep">
                    <hr></hr>
                    </td>
                  </tr>
                  </>}
                </>)
              )}
            </tbody>
            <tr className="separator-table-row">
              <th colSpan="4" />
            </tr>
            <tbody>
              <tr className="data subtotal">
                <td className="align-right">
                  Volumen&nbsp;productos&nbsp;Copec&nbsp;(Lts)
                </td>
                <td className="align-right">{getLocaleStringNumber(getLitrosProductoR("g93"))}</td>
                <td className="align-right">{getLocaleStringNumber(getLitrosProductoR("g95"))}</td>
                <td className="align-right">{getLocaleStringNumber(getLitrosProductoR("g97"))}</td>
                <td className="align-right">{getLocaleStringNumber(getLitrosProductoR("pd"))}</td>
                <td className="align-right">{getLocaleStringNumber(getLitrosProductoR("k"))}</td>
                <td className="align-right">{getLocaleStringNumber(getLitrosProductoR("total"))}</td>
              </tr>
            </tbody>
            <tr className="separator-table-row">
              <th colSpan="4" />
            </tr>
          </>}
          
          <tbody>
            {cons.filter((forma)=>forma.total>0).map((forma, key) =>
              (<>
                <tr key={key} className="data">
                  <td className="align-left">{forma.name}</td>
                  <td className="align-right">{getLocaleStringNumber(forma.g93)}</td>
                  <td className="align-right">{getLocaleStringNumber(forma.g95)}</td>
                  <td className="align-right">{getLocaleStringNumber(forma.g97)}</td>
                  <td className="align-right">{getLocaleStringNumber(forma.pd)}</td>
                  <td className="align-right">{getLocaleStringNumber(forma.k)}</td>
                  <td className="align-right">{getLocaleStringNumber(forma.total)}</td>
                </tr>
                {cons.filter((forma)=>forma.total>0).length-1!==key && <>
                  <tr>
                  <td colSpan="100%" className="tdsep">
                   <hr></hr>
                  </td>
                </tr>
                </>}
              </>)
            )}
          </tbody>
          <tr className="separator-table-row">
            <th colSpan="4" />
          </tr>
          <tbody>
            <tr className="data subtotal">
              <td className="align-left">
                Total&nbsp;volumen&nbsp;entregado&nbsp;(Lts)
              </td>
              <td className="align-right">
                {getLocaleStringNumber(getLitrosProductoR("g93") + getLitrosProductoC("g93"))}
              </td>
              <td className="align-right">
                {getLocaleStringNumber(getLitrosProductoR("g95") + getLitrosProductoC("g95"))}
              </td>
              <td className="align-right">
                {getLocaleStringNumber(getLitrosProductoR("g97") + getLitrosProductoC("g97"))}
              </td>
              <td className="align-right">
                {getLocaleStringNumber(getLitrosProductoR("pd") + getLitrosProductoC("pd"))}
              </td>
              <td className="align-right">
                {getLocaleStringNumber(getLitrosProductoR("k") + getLitrosProductoC("k"))}
              </td>
              <td className="align-right">
                {getLocaleStringNumber(getLitrosProductoR("total") + getLitrosProductoC("total"))}
              </td>
            </tr>
          </tbody>
          <tr className="separator-table-row">
            <th colSpan="4" />
          </tr>
          <tbody>
            <tr className="data subtotal">
              <td className="align-left">Total&nbsp;a&nbsp;pagar</td>
              <td className="align-right"> {`$ ${getLocaleStringNumber(parseInt(totalAPagar['g93']),true)}`} </td>
              <td className="align-right">{`$ ${getLocaleStringNumber(parseInt(totalAPagar['g95']),true)}`} </td>
              <td className="align-right">{`$ ${getLocaleStringNumber(parseInt(totalAPagar['g97']),true)}`}</td>
              <td className="align-right">{`$ ${getLocaleStringNumber(parseInt(totalAPagar['pd']),true)}`}</td>
              <td className="align-right">{`$ ${getLocaleStringNumber(parseInt(totalAPagar['k']),true)}`}</td>
              <td className="align-right">{`$ ${getLocaleStringNumber(parseInt(totalAPagar['total']),true)}`}</td>
            </tr>
          </tbody>
        </table>}

        {!selectedPeriodo && <h4 className="copecblue">Por favor seleccionar un periodo</h4>}

        
      </div>
      <div className="resumen-periodo-pedidos">
        <hr></hr>
        <div className="actions">
          <button onClick={() => closeResumen()} className="actions-buttons">
            Volver
          </button>
          <PrintComponent 
            reff={ref}
            classes="print-button cursor-pointer"
            pageStyle="@page { size: 320mm 250mm; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }"
            isROImpersonate={props.isROImpersonate}
          />
          <Pdf
            targetRef={ref}
            filename="comprobante.pdf"
            x={0.3}
            y={0.3}
            scale={0.8}
          >
            {({ toPdf }) => (
              <img
                onClick={toPdf}
                className="print-button cursor-pointer"
                src={descargar}
              />
            )}
          </Pdf>
        </div>
      </div>
    </>
  );
};

export default ResumenPeriodo;
