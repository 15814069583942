import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { getLocaleStringNumber } from "../utils/utils.js";
import { scrollTo } from "../utils/windows";
import { docModalStylesNew } from "../utils/styles.js";
import closeIcon from "../../assets/img/cross-icon.svg";

const VolumenInfoModal = (props) => {
  const displayModal = props.displayModal;
  const closeModal = props.closeModal;
  const distanceToTop = props.distanceToTop;
  const pedido = props.pedido;
  const detalle = props.detalle;

  useEffect(() => {
    if (displayModal) {
      console.log('scrollTo en VolumenInfoModal')
      scrollTo(0, distanceToTop)
    }
  }, [displayModal]);

  const showContent = () => {
    return (
      <>
        <div className={`documento-info-modal`}>
          <div className="title">Detalle Volúmenes </div>
          <div className="subtitle">{pedido.ID_PEDIDO} - {pedido.TIPO_PEDIDO}</div>
          <div className="content">
            <table>
              <thead>
                <tr>
                  <th>Mix de Productos</th>
                  <th className="align-right">Litros</th>
                </tr>
              </thead>
              <tr className="separator-table-row">
                <th colSpan="100%" />
              </tr>
              <tbody>
                {detalle?.map((obj, key) => {
                  return (
                    <>
                      {" "}
                      {obj.liters > 0 ? (
                        <>
                          <tr className="data" key={key}>
                            <td className="align-left">{obj.product}</td>
                            <td className="align-right">
                              {getLocaleStringNumber(obj.liters)}
                            </td>
                          </tr>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
                </tbody>
                <tr className="separator-table-row">
                  <th colSpan="100%" />
                </tr>
                <tbody>
                <tr className="data">
                  <td className="align-left">
                    <b>Total Litros</b>
                  </td>
                  <td className="align-right">
                    <b>
                      {pedido
                        ? getLocaleStringNumber(parseInt(pedido.CANTIDAD_TOTAL))
                        : ""}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <Modal
        isOpen={displayModal}
        onRequestClose={closeModal}
        style={docModalStylesNew(distanceToTop - 100)}
      >
        <button className="modal__close-btn" onClick={closeModal}>
          <img src={closeIcon} alt="close modal" />
        </button>
        {showContent()}
      </Modal>
    </div>
  );
};

export default VolumenInfoModal;
