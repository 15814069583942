import React from "react";
import {getLocaleStringNumber } from "./utils/utils"

const HeaderBoxV2 = ({ icon1, icon2,cargos,abonos }) => {
  return (
    <div className="header-box-two">
      <div className="info mbottom">
        <img src={icon1} />
        <div className="title">Total Cargos</div>
        <div className="monto cargos">{`$ ${getLocaleStringNumber(cargos,true)}`}</div>
      </div>
      <hr></hr>
      <div className="info mtop">
        <img src={icon2} />
        <div className="title">Total Abonos</div>
        <div className="monto abonos">{`$ ${getLocaleStringNumber(abonos,true)}`}</div>
      </div>
    </div>
  );
};

export default HeaderBoxV2;
