import React, { useState, useEffect } from "react"
import Select from "react-select";

import HeaderData from "../HeaderData"
import HeaderBox from "../HeaderBox"
import saldoAzulIcon from "../../assets/img/saldo-azul.svg"
import imprimir from "../../assets/img/imprimir.svg"
import descargar from "../../assets/img/descargar.svg"
import { getUpdateDate } from "../utils/monthNames"
import { SingleOption, customStylesTctTae } from "../utils/utils.js";

const Lubricantes = ({token, rutConcesionario, callServiceHandler, cabecera}) => {
  const [updateDate, setUpdateDate] = useState('')
  const [filterText, setFilterText] = useState('')
  const [selectedEds, setSelectedEds] = useState()
  const [selectedPeriodo, setSelectedPeriodo] = useState()

  useEffect(() => {
    getUpdateDate(setUpdateDate)
  }, [])

  function changeEds(e){}
  function changeSelectedPeriodo(e){}

  return (
    <div className="tct-tae lubricantes">
      <div className="view-title">TCT TAE</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Bluemax</div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandler={callServiceHandler}
        cabecera={cabecera}
        actualizable={true}
      />
      <hr />
      <div className="tct-tae-table-container bluemax-principal">
        <div className="filters-container">
          <div className="filter">
            <div className="filter-title">
              ESTACIÓN DE SERVICIO<span className="red-asterisk">*</span>
            </div>
            <div className="filter-input">
              <Select
                className="eds-select"
                value={selectedEds}
                onChange={(e) => changeEds(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={[]}
                components={{
                  SingleOption,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccionar"
                styles={customStylesTctTae}
              />
            </div>
          </div>
          <div className="filter mleft">
            <div className="filter-title">PERÍODO</div>
              <div className="filter-input">
                <Select
                  className="eds-select"
                  value={selectedPeriodo}
                  onChange={(e) => changeSelectedPeriodo(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={[]}
                  components={{ SingleOption, IndicatorSeparator: () => null }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesTctTae}
                />
              </div>
          </div>
          <div className="filter search-input">
            <div className="filter-input">
              <input
                type="search"
                className="search"
                value={filterText}
                placeholder="Buscar..."
                onChange={(e) => setFilterText(e.target.value)}
              />
            </div>
          </div>

          <div className="actions-container">
            <button className="icon-btn">
              <img src={descargar} />
            </button>
            <button className="icon-btn left-button">
              <img src={imprimir} />
            </button>
          </div>
        </div>

        <table className="tct-tae-table">
          <tr>
            <th>
              <div className="th-data width-90">
                <div className="th-text two-lines">Código<br />Producto</div>
              </div>
            </th>
            <th>
              <div className="th-data width-185">
                <div className="th-text">Descripción</div>
              </div>
            </th>
            <th>
              <div className="th-data width-105">
                <div className="th-text two-lines">Saldo inicial<br />(unidades)</div>
              </div>
            </th>
            <th>
              <div className="th-data width-105">
                <div className="th-text two-lines">Despacho<br />(unidades)</div>
              </div>
            </th>
            <th>
              <div className="th-data width-105">
                <div className="th-text two-lines">Ventas<br />(unidades)</div>
              </div>
            </th>
            <th>
              <div className="th-data width-105">
                <div className="th-text two-lines">Saldo Final<br />(unidades)</div>
              </div>
            </th>
            <th>
              <div className="th-data width-105">
                <div className="th-text two-lines">Stock Asig.<br />(unidades)</div>
              </div>
            </th>
          </tr>
          <tr className="table-separator-row-border-top">
            <td colSpan="100%" />
          </tr>
          <tbody>
            <tr className="data">
              <td className="align-left">00002</td>
              <td className="align-left">dvac 1440/1</td>
              <td className="align-left">7</td>
              <td className="align-left">0</td>
              <td className="align-left">0</td>
              <td className="align-left">0</td>
              <td className="align-left">12</td>
            </tr>
            <tr className="data">
              <td className="align-left">00002</td>
              <td className="align-left">dvac 1440/1</td>
              <td className="align-left">7</td>
              <td className="align-left">0</td>
              <td className="align-left">0</td>
              <td className="align-left">0</td>
              <td className="align-left">12</td>
            </tr>
            <tr className="data">
              <td className="align-left">00002</td>
              <td className="align-left">dvac 1440/1</td>
              <td className="align-left">7</td>
              <td className="align-left">0</td>
              <td className="align-left">0</td>
              <td className="align-left">0</td>
              <td className="align-left">12</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Lubricantes
