import React, { useState, useRef } from "react";
import CustomModal from "../CustomModal";
import { parseDate, parseBudat, getLocaleStringNumber } from "../utils/utils.js";
import {
  hideLoader,
  displayLoader,
  showGreyBackground,
  hideGreyBackground,
  scrollTo
} from "../utils/windows";
import Pdf from "react-to-pdf";
import PrintComponent from "../PrintComponent";
import logo from "../../assets/img/logo-comprobante.svg";
import descargar from "../../assets/img/descargar.svg";
import timbre from "../../assets/img/timbre-comprobante.svg";
const ref = React.createRef();

const ResumenRechazoPedido = (props) => {
  const selectedPedido = props.selectedPedido;
  const selectedEds = props.selectedEds;
  const detalles = props.detalles;
  const selectedRechazo = props.selectedRechazo;
  const razonesRechazo = props.razonesRechazo;
  const pref = useRef(null);
  const date = new Date()
  const parsedDate = parseDate(date);
  const camion = props.camion;
  const isDesktopRef = useRef(window.innerWidth > process.env.REACT_APP_MOBILE_BREAK_POINT);

  const [displayModal, setDisplayModal] = useState(false);
  const [displayComprobante, setDisplayComprobante] = useState(false);

  function closeModal() {
    setDisplayModal(false);
    hideGreyBackground();
    if(!isDesktopRef.current)
      scrollTo(0, 0)
  }

  function acceptModal() {
    setDisplayModal(false);
    setDisplayComprobante(true);
    hideGreyBackground();
    if(!isDesktopRef.current)
      scrollTo(0, 0)
  }

  function closeComprobante() {
    setDisplayComprobante(false);
    props.setDisplayResumenRechazar(false);
    props.setDisplayRechazarPedido(false);
    props.setCurrentPage((old)=>old+1);
    displayLoader();
  }

  const customStyles = {
    content: {
      top: "400px",
      width: "340px",
      padding: "0",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -70%)",
    },
  };

  async function rechazarPedido() {
    displayLoader();

    let url =
      process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/rechazar-pedido?id_pedido=" +
      selectedPedido.ID_PEDIDO +
      "&id_motivo=" +
      selectedRechazo.value;

    let result = await props.callServiceHandler(url, "DELETE")
    if(result !== "error"){
      setDisplayModal(true);
      showGreyBackground();
      if(!isDesktopRef.current)
        scrollTo(0, 50)

    }
  }

  return (
    <>
    {!displayComprobante && <div className="resumen-rechazar-pedido">
      <h1>Resumen de rechazo de pedido</h1>
      <hr className="title-separator"></hr>
      <div className="info">
        <div className="item shared-line left">
          <div className="title">ESTACIÓN DE SERVICIO</div>
          <div className="value force-one-liner">{selectedEds.label}</div>
        </div>
        <div className="item shared-line">
          <div className="title">TIPO DE PEDIDO</div>
          <div className="value">{selectedPedido.TIPO_PEDIDO}</div>
        </div>
        <div className="item">
          <div className="title">PLANTA</div>
          <div className="value overflow">{selectedPedido.PLANTA}</div>
        </div>
      </div>
      <div className="info col4">
        <div className="item shared-line left">
          <div className="title">TIPO DE CAMIÓN</div>
          <div className="value overflow">{camion?camion.NOMBRE:'-'}</div>
        </div>
        <div className="item shared-line">
          <div className="title">FECHA PREFERENTE</div>
          <div className="value">{parseBudat(selectedPedido.FECHA_PREFERENTE_ENTREGA)}</div>
        </div>
        <div className="item shared-line left">
          <div className="title">VENTANA SOLICITADA</div>
          <div className="value overflow">{selectedPedido.VENTANA}</div>
        </div>
        <div className="item shared-line">
          <div className="title">CONTACTO</div>
          <div className="value overflow">{selectedPedido.CONTACTO}</div>
        </div>
      </div>
      <div className="productos">
        <div className="header">
          <div className="title">Mix de productos</div>
          <div className="liters">Litros</div>
        </div>
        <div className="items">
          {detalles.map((p, key) => (
            <>
              <div className="producto">
                <div className="title">{p.nombre}</div>
                <div className="liters">{getLocaleStringNumber(p.litros)}</div>
              </div>
              {key === detalles.length - 1 ? (
                <></>
              ) : (
                <hr className="separator"></hr>
              )}
            </>
          ))}
        </div>
        <div className="total">
          <div className="title">Total</div>
          <div className="liters">{getLocaleStringNumber(selectedPedido.CANTIDAD_TOTAL)}</div>
        </div>
      </div>
      <div className="item mtop">
        <div className="title">MOTIVO DEL RECHAZO</div>
        <div className="value full">{selectedRechazo.label}</div>
      </div>
      <hr className="mtop"></hr>
      <div className="actions">
        <button
          onClick={() => props.setDisplayResumenRechazar(false)}
          className="actions-buttons hallow red-border"
        >
          Cancelar
        </button>
        <button onClick={rechazarPedido} className="actions-buttons red">
          Confirmar
        </button>
      </div>

      <CustomModal
        displayModal={displayModal}
        closeModal={closeModal}
        acceptFunc={acceptModal}
        modalTitle='Pedido rechazado'
        modalText={`El pedido Nº ${selectedPedido.ID_PEDIDO} ha sido rechazado con éxito.`}
        modalIcon='success'
      />
    </div>}
    { displayComprobante && <>
    
      <div className="comprobante-container" ref={ref}>
        <div className="comprobante" ref={pref}>
          <div className="logo">
            <img src={logo} />
          </div>
          <div className="comprobante-title">
            Comprobante Rechazo de Pedido
            
          </div>
          <hr />
          <div className="header-data">
            <div className="data">
              <span className="title">Cliente:&nbsp;</span>
              <span>
                {props.clientName}
              </span>
            </div>
            <div className="data">
              <span className="title">Dirección:&nbsp;</span>
              <span className="data">{selectedPedido.DIRECCION}</span>
            </div>
            <div className="data">
              <span className="title">Nº&nbsp;de&nbsp;Pedido:&nbsp;</span>
              <span className="data">{selectedPedido.ID_PEDIDO}</span>
            </div>
            <div className="data">
              <span className="title">Fecha:&nbsp;</span>
              <span className="data"> {parsedDate} </span>
            </div>
          </div>

          <table>
            <thead>
              <tr>
                <th>
                  Mix de Productos
                </th>
                
                <th>Litros</th>
              </tr>
            </thead>
            <tbody>
              {detalles.map((obj, key) => (
                <tr key={key}>
                  <td>{obj.nombre}</td>
                  <td>{getLocaleStringNumber(obj.litros)}</td>
                </tr>
              ))}
              <tr className="total-saldo">
                <td>
                  Total (Litros)
                </td>
                <td>
                  {getLocaleStringNumber(selectedPedido.CANTIDAD_TOTAL)}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="bottom-logo">
            <img src={timbre} />
          </div>
        </div>
      </div>
      <div className="comprobante-container nomargintop">
        <div className="bottom-actions">
          { isDesktopRef.current &&
            <button
              className="back-button"
              onClick={() => closeComprobante()}
            >
              Finalizar
            </button>
          }
          <PrintComponent 
            reff={ref}
            classes="comprobante-action cursor-pointer"
            pageStyle="@page { size: 270mm 250mm; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }"
            isROImpersonate={props.isROImpersonate}
          />
          
          <Pdf targetRef={pref} filename="comprobante.pdf" >
            {({toPdf})=><img onClick={toPdf} className="comprobante-action cursor-pointer" src={descargar} />}
          </Pdf>
          { !isDesktopRef.current &&
            <div className="back-button-container">
              <button
                className="back-button"
                onClick={() => closeComprobante()}
              >
                Finalizar
              </button>
            </div>
          }
        </div>
      </div>

    </>}
    
  </>
  );
};

export default ResumenRechazoPedido;
