import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { getLocaleStringNumber } from "../utils/utils.js";
import { showGreyBackground, hideGreyBackground } from "../utils/windows";
import { docModalStyles } from "../utils/styles.js";
import closeIcon from "../../assets/img/cross-icon.svg";

const ReposicionInfoModal = (props) => {
  const displayModal = props.displayModal;
  const closeModal = props.closeModal;
  const distanceToTop = props.distanceToTop;
  const pedido = props.pedido;
  const reposicion = props.reposicion;
  const cons = props.cons;
  const totalAPagar = props.totalAPagar;
  const precioPorLitro = props.precioPorLitro;

  const getLitrosProductoR = (producto) => {
    let litros = 0;
    reposicion.forEach((forma) => {
      if (forma[producto] > 0) {
        litros += forma[producto];
      }
    });
    return litros;
  };

  const getLitrosProductoC = (producto) => {
    let litros = 0;
    cons.forEach((forma) => {
      if (forma[producto] > 0) {
        litros += forma[producto];
      }
    });
    return litros;
  };

  const isVacioReposicion = () => {
    const arr = reposicion.filter((forma) => forma.total > 0);
    if (arr.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const showContent = () => {
    return (
      <>
        <div className={`documento-info-modal`}>
          <div className="title">Detalle Reposiciones</div>
          <div className="subtitle">
            {pedido.ID_PEDIDO} - {pedido.TIPO_PEDIDO}
          </div>
          <div className="content reposicion">
            <table>
              <thead>
                <tr>
                  <th>Forma&nbsp;Comercialización</th>
                  <th className="align-right">G93RM</th>
                  <th className="align-right">G95RM</th>
                  <th className="align-right">G97RM</th>
                  <th className="align-right">PDUA1</th>
                  <th className="align-right">Kerosene</th>
                  <th className="align-right">Total</th>
                </tr>
              </thead>
              
                {isVacioReposicion() ? (
                  <>
                    <tr className="separator-table-row">
                      <th colSpan="100%" />
                    </tr>
                    <tbody>
                    <tr className="data">
                      <td className="align-left" colSpan="100%">
                        No hay reposición para el pedido seleccionado.
                      </td>
                    </tr>
                    </tbody>
                  </>
                ) : (
                  <>
                  <tr className="separator-table-row">
                    <th colSpan="100%" />
                  </tr>
                  <tbody>
                   {reposicion.filter((forma)=>forma.total>0).map((forma, key) =>
                    (<>
                      <tr key={key} className="data">
                        <td className="align-left">{forma.name}</td>
                        <td className="align-right">{getLocaleStringNumber(forma.g93)}</td>
                        <td className="align-right">{getLocaleStringNumber(forma.g95)}</td>
                        <td className="align-right">{getLocaleStringNumber(forma.g97)}</td>
                        <td className="align-right">{getLocaleStringNumber(forma.pd)}</td>
                        <td className="align-right">{getLocaleStringNumber(forma.k)}</td>
                        <td className="align-right">{getLocaleStringNumber(forma.total)}</td>
                      </tr>
                      {reposicion.filter((forma)=>forma.total>0).length-1!==key && <>
                        <tr>
                        <td colSpan="100%" className="tdsep">
                        <hr></hr>
                        </td>
                      </tr>
                      </>}
                    </>) 
                  )}
                    </tbody>
                  </>
                )}
              

              <tr className="separator-table-row">
                <th colSpan="7" />
              </tr>
              <tbody>
                <tr className="data subtotal">
                  <td className="align-left">
                    <b>Volumen&nbsp;productos&nbsp;Copec&nbsp;(Lts)</b>
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(getLitrosProductoR("g93"))}
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(getLitrosProductoR("g95"))}
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(getLitrosProductoR("g97"))}
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(getLitrosProductoR("pd"))}
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(getLitrosProductoR("k"))}
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(getLitrosProductoR("total"))}
                  </td>
                </tr>
              </tbody>
              <tr className="separator-table-row">
                <th colSpan="7" />
              </tr>
              <tbody>
                {cons.filter((forma)=>forma.total>0).map((forma, key) =>
                (<>
                  <tr key={key} className="data">
                    <td className="align-left">{forma.name}</td>
                    <td className="align-right">{getLocaleStringNumber(forma.g93)}</td>
                    <td className="align-right">{getLocaleStringNumber(forma.g95)}</td>
                    <td className="align-right">{getLocaleStringNumber(forma.g97)}</td>
                    <td className="align-right">{getLocaleStringNumber(forma.pd)}</td>
                    <td className="align-right">{getLocaleStringNumber(forma.k)}</td>
                    <td className="align-right">{getLocaleStringNumber(forma.total)}</td>
                  </tr>
                  {cons.filter((forma)=>forma.total>0).length-1!==key && <>
                    <tr>
                    <td colSpan="100%" className="tdsep">
                    <hr></hr>
                    </td>
                  </tr>
                  </>}
                </>)
              )}
              </tbody>
              <tr className="separator-table-row">
                <th colSpan="7" />
              </tr>
              <tbody>
                <tr className="data subtotal">
                  <td className="align-left">
                    <b>Total&nbsp;volumen&nbsp;entregado&nbsp;(Lts)</b>
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(
                      getLitrosProductoR("g93") + getLitrosProductoC("g93")
                    )}
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(
                      getLitrosProductoR("g95") + getLitrosProductoC("g95")
                    )}
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(
                      getLitrosProductoR("g97") + getLitrosProductoC("g97")
                    )}
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(
                      getLitrosProductoR("pd") + getLitrosProductoC("pd")
                    )}
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(
                      getLitrosProductoR("k") + getLitrosProductoC("k")
                    )}
                  </td>
                  <td className="align-right">
                    {getLocaleStringNumber(
                      getLitrosProductoR("total") + getLitrosProductoC("total")
                    )}
                  </td>
                </tr>
              </tbody>
              <tr className="separator-table-row">
                <th colSpan="7" />
              </tr>
              <tbody>
                <tr className="data">
                  <td className="align-left">Precio&nbsp;por&nbsp;litro</td>
                  <td className="align-right">
                    {precioPorLitro["g93"] === "-"
                      ? "-"
                      : `$ ${getLocaleStringNumber(
                          parseInt(precioPorLitro["g93"])
                        )}`}
                  </td>
                  <td className="align-right">
                    {precioPorLitro["g95"] === "-"
                      ? "-"
                      : `$ ${getLocaleStringNumber(
                          parseInt(precioPorLitro["g95"])
                        )}`}
                  </td>
                  <td className="align-right">
                    {precioPorLitro["g97"] === "-"
                      ? "-"
                      : `$ ${getLocaleStringNumber(
                          parseInt(precioPorLitro["g97"])
                        )}`}
                  </td>
                  <td className="align-right">
                    {precioPorLitro["pd"] === "-"
                      ? "-"
                      : `$ ${getLocaleStringNumber(
                          parseInt(precioPorLitro["pd"])
                        )}`}
                  </td>
                  <td className="align-right">
                    {precioPorLitro["k"] === "-"
                      ? "-"
                      : `$ ${getLocaleStringNumber(
                          parseInt(precioPorLitro["k"])
                        )}`}
                  </td>
                  <td className="align-right"></td>
                </tr>
              </tbody>
              <tr className="separator-table-row">
                <th colSpan="7" />
              </tr>
              <tbody>
                <tr className="data subtotal">
                  <td className="align-left">
                    <b>Total&nbsp;a&nbsp;pagar</b>
                  </td>
                  <td className="align-right">
                    {" "}
                    {`$ ${getLocaleStringNumber(
                      parseInt(totalAPagar["g93"])
                    )}`}{" "}
                  </td>
                  <td className="align-right">{`$ ${getLocaleStringNumber(
                    parseInt(totalAPagar["g95"])
                  )}`}</td>
                  <td className="align-right">{`$ ${getLocaleStringNumber(
                    parseInt(totalAPagar["g97"])
                  )}`}</td>
                  <td className="align-right">{`$ ${getLocaleStringNumber(
                    parseInt(totalAPagar["pd"])
                  )}`}</td>
                  <td className="align-right">{`$ ${getLocaleStringNumber(
                    parseInt(totalAPagar["k"])
                  )}`}</td>
                  <td className="align-right">
                    <b>{`$ ${getLocaleStringNumber(
                      parseInt(totalAPagar["total"])
                    )}`}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <Modal
        isOpen={displayModal}
        onRequestClose={closeModal}
        style={docModalStyles(distanceToTop)}
      >
        <button className="modal__close-btn" onClick={closeModal}>
          <img src={closeIcon} alt="close modal" />
        </button>
        {showContent()}
      </Modal>
    </div>
  );
};

export default ReposicionInfoModal;
