import React, { useEffect, useState, useRef } from "react";
import HeaderData from "../HeaderData";
import TabSelector from "../TabSelector";
import { getUpdateDate } from "../utils/monthNames";
import OTCobrosPorFormularTable from "./OTCobrosPorFormularTable";
import Select from "react-select";
import { SingleOption, customStylesPedidos, parseDate, parseBudat } from "../utils/utils";
import DatePicker from "react-datepicker";
import PrintComponent from "../PrintComponent";
import ExportExcel from "../ExportExcel";
import { getPaginationItems, openBase64NewTab, setInstalalaciones, setPagadoresArcoprime } from "../utils/get";
import MantenimientoCard from "./MantenimientoCard";
import Pagination from "react-js-pagination";

const OTCobrosPorFormular = ({
  rutConcesionario,
  token,
  pagador,
  cabecera,
  instalaciones,
  isROImpersonate,
  edsPortal,
  callServiceHandler
}) => {
  const [updateDate, setUpdateDate] = useState();
  const [selectedTab, setSelectedTab] = useState(1);
  const [allEds, setAllEds] = useState([]);
  const [selectedEds, setSelectedEds] = useState();
  const [fechaDesde, setFechaDesde] = useState();
  const [fechaHasta, setFechaHasta] = useState();
  const [filterText, setFilterText] = useState("");
  const tableRef = useRef(null);
  const [ordenValue, setOrdenValue] = useState("");
  const [orden, setOrden] = useState();
  const [ordenes, setOrdenes] = useState();
  const [filteredOrdenes, setFilteredOrdenes] = useState();
  const [paginationActivePage, setPaginationActivePage] = useState(1);
	const [paginationFilteredOrdenes, setPaginationFilteredOrdenes] = useState([]);
  const paginationItemsPerPage = parseInt(process.env.REACT_APP_PAGINATION_ITEMS_PER_PAGE);

   //Arcoprime
   const [isArcoprime, setIsArcoprime] = useState(false);
   const [allPagadores, setAllPagadores] = useState([]);
   const [selectedPagador, setSelectedPagador] = useState();

  const labels = [
    ["Nº de Orden", "NUMERO_ORDEN"],
    ["Fecha de Orden", "FECHA_ORDEN"],
    ["Fecha de Cierre", "FECHA_CIERRE"],
    ["Estado", "ESTADO"],
    ["Monto Plan", "MONTO_PLAN"],
    ["Monto Real", "MONTO_REAL"],
    ["Descripción", "DESCRIPCION"],
    ["Factura", "FACTURA"],
  ];

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (token && rutConcesionario) {
        getUpdateDate(setUpdateDate);
        if (rutConcesionario === "77215640-5") {
          setIsArcoprime(true);
        }
      }
    } else {
      if (token) {
        getUpdateDate(setUpdateDate);
        if (rutConcesionario === "77215640-5" /* || true */) {
          setIsArcoprime(true);
        }
      }
    }
  }, [token, rutConcesionario]);

  useEffect(() => {
    if (instalaciones!==""){
      if(!isArcoprime){
        setInstalalaciones(instalaciones,setAllEds);
      }
    }
  }, [instalaciones]);

  useEffect(() => {
    if(edsPortal){
      setPagadoresArcoprime(edsPortal.data.user_eds,setAllPagadores);
    }
  }, [edsPortal]);

  useEffect(() => {
    if (allPagadores.length === 1) {
      changePagador(allPagadores[0]);
    }
  }, [allPagadores]);

  function changePagador(e) {
    setSelectedPagador(e);
  }

  useEffect(async () => {
    if(selectedPagador){
      const url = process.env.REACT_APP_PEDIDOS_API +
      "/pedidos/obtener-instalaciones-cliente?id_pagador=" + selectedPagador.value;

      let result = await callServiceHandler(url, "GET")
      if(result !== "error"){
        setInstalalaciones(result.data, setAllEds);
      }
    }
  }, [selectedPagador]);

  useEffect(() => {
    if (allEds) {
      changeEds(allEds[0]);
    }
  }, [allEds]);

  function reset() {
    setOrden();
    setFechaDesde();
    setFechaHasta();
    setOrdenes();
    setFilteredOrdenes()
    setPaginationFilteredOrdenes([])
    setPaginationActivePage(1);
    setOrdenValue("");
  }
  async function changeEds(e) {
    reset()
    setSelectedEds(e);
  }

  function handleOrderEnter(e) {
    if (e.key === "Enter") {
      getOrden(ordenValue);
    }
  }

  async function getOrden(numeroOrden) {
    let url =
      process.env.REACT_APP_MANTENIMIENTO_API +
      "/mantenimiento/obtener-ordenes?rut_concesionario=" +
      rutConcesionario +
      "&id_eds=" +
      selectedEds.value +
      "&orden=" +
      numeroOrden;

    let result = await callServiceHandler(url, "GET");
    console.log("AVISOO", result);
    if (result !== "error") {
      if (result.data.ORDENES.length === 0) {
        setOrden({ vacio: true });
      } else {
        const dataFinal = result.data.ORDENES[0];
        dataFinal["vacio"] = false;
        setOrden(dataFinal);
      }
    }
  }

  useEffect(() => {
    if(fechaHasta){
      getOrdenes();
    }
  }, [fechaHasta]);

  useEffect(() => {
    setFilteredOrdenes(ordenes);
    if(ordenes?.length>0){
      getPaginationItems(ordenes,1,setPaginationFilteredOrdenes,setPaginationActivePage)
    }
  }, [ordenes]);

  function handlePageChange(pageNumber) {
		getPaginationItems(filteredOrdenes, pageNumber, setPaginationFilteredOrdenes, setPaginationActivePage);
  }

  async function getOrdenes() {
  
    let url =
      process.env.REACT_APP_MANTENIMIENTO_API +
      "/mantenimiento/obtener-ordenes?rut_concesionario=" +
      rutConcesionario +
      "&id_eds=" +
      selectedEds.value + "&fecha_desde="+parseDate(fechaDesde)+"&fecha_hasta="+parseDate(fechaHasta);

    let result = await callServiceHandler(url, "GET");
    console.log(result);

    if (result !== "error") {
      if (result.data.ORDENES.length === 0) {
        setOrdenes({ vacio: true });
      } else {
        const dataFinal = cleanOrdenes(result.data.ORDENES);
        dataFinal["vacio"] = false;
        setOrdenes(dataFinal);
        console.log("ORDENES",dataFinal)
      }
    }
  }

  function cleanOrdenes (ordenesdata) {
    const newOrdenes = [];

    console.log("ORDENES DATA",ordenesdata)

    ordenesdata.map((orden)=>{
      const newOrden = {
        NUMERO_ORDEN:orden.numero_orden,
        FECHA_ORDEN:parseBudat(orden.fecha_orden),
        FECHA_CIERRE:orden.fecha_cierre !== "0000-00-00" ? parseBudat(orden.fecha_cierre) : "En Progreso",
        DESCRIPCION: orden.descripcion,
        FACTURA:orden.factura?orden.factura:" ",
        TIENE_ADJUNTOS:orden.tiene_adjuntos,
        ADJUNTOS:orden.ADJUNTOS,
        MONTO_PLAN:orden.monto_plan,
        MONTO_REAL:orden.monto_real,
        ESTADO:orden.estado,
        URL:orden.url
      }
      newOrdenes.push(newOrden);
    })

    return newOrdenes;

  }


  function searchOrder(numero) {
    console.log("Hice enter de este numero: " + numero);
  }

  function changeFechaDesde (e) {
    setFechaDesde(e)
    setFechaHasta();
  }

  function changeFechaHasta (e) {
    setFechaHasta(e)
  }

  async function openFile(adjunto){
    console.log("ADJUNTO:",adjunto);
    
    let url =
      process.env.REACT_APP_MANTENIMIENTO_API +
      "/mantenimiento/obtener-adjunto?id_sap=" +
      adjunto.id_sap;

    let result = await callServiceHandler(url, "GET");
    console.log(result);
    if (result.data.error === true) {
      return true;  
    } else {
      openBase64NewTab(result.data.body,result.data.tipo);
    }
  }

  function getMaxDate(fechaD){
    const now = new Date();
    const minMasUno = new Date(fechaD?.getFullYear()+1,fechaD?.getMonth(),fechaD?.getDate())

    if(now>minMasUno){
      return minMasUno;
      
    }else{
      return now;
    }
  }

  return (
    <div className="pedidos-activos page">
      <div className="view-title">Mantenimiento</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">OT Cobros por Formular</div>
      <hr className="title-separator-line" />

      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        cabecera={cabecera}
        callServiceHandler={callServiceHandler}
      />

      <TabSelector
        tab1="Buscar por Nº Orden"
        tab2="Buscar por Fecha"
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        reset={reset}
      />

      {selectedTab === 1 && (
        <div className="order-container">
          <div className="order-options">
            <div className="filters-container">
            {isArcoprime && 
              <div className={`filter`}>
                <div className="filter-title required-field">
                 ID DE PAGADOR
                </div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedPagador}
                    onChange={(e) => changePagador(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allPagadores}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesPedidos}
                  />
                </div>
              </div>  
              }
              <div className={`filter ${isArcoprime && "mleft"}`}>
                <div className="filter-title required-field">
                  ESTACIÓN DE SERVICIO
                </div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedEds}
                    onChange={(e) => changeEds(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allEds}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesPedidos}
                  />
                </div>
              </div>
              <div className="filter mleft">
                <div className="filter-title required-field">
                  NÚMERO DE ORDEN
                </div>
                <div className="filter-input">
                  <input
                    type="text"
                    className="orden-input"
                    id="orden-input"
                    placeholder="Ej: 546465464"
                    value={ordenValue}
                    onChange={(e) => setOrdenValue(e.target.value)}
                    onKeyDown={handleOrderEnter}
                  ></input>
                </div>
              </div>
            </div>
          </div>

          {typeof orden === "undefined" && <>
          <h2>{/* Busca por número de orden. A la espera de si negocio define mensaje default, como en la tabla. */}</h2>
          </>}
          {orden && (
            <>{orden.vacio ? 
              <div className="order-data">
                <div className="important-message error">
                  <span className="error-icon">!</span>
                  <span>No existen datos para este Nº de orden.</span>
                </div>
              </div>
            : <>
            <MantenimientoCard orden={orden} openFile={openFile} tipo="ordenOT" />
            <div className="order-container">
              <hr className="orden-separator"></hr>
              <div className="important-message">
                <span className="title">Importante:&nbsp;</span>
                <span>
                Mientras la OT no se encuentre <span className="aceptada">"Aceptada"</span> el monto real podría cambiar.
                </span>
              </div>
            </div>
            </>}
            </>
          )}
        </div>
      )}

      {selectedTab === 2 && (
        <>
          <div className="fecha-options">
            <div className="filters-container">
            {isArcoprime && 
              <div className={`filter`}>
                <div className="filter-title required-field">
                 ID DE PAGADOR
                </div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedPagador}
                    onChange={(e) => changePagador(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allPagadores}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesPedidos}
                  />
                </div>
              </div>  
              }
              <div className={`filter ${isArcoprime && "mleft"}`}>
                <div className="filter-title required-field">
                  ESTACIÓN DE SERVICIO
                </div>
                <div className="filter-input">
                  <Select
                    className="eds-select"
                    value={selectedEds}
                    onChange={(e) => changeEds(e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={allEds}
                    components={{
                      SingleOption,
                      IndicatorSeparator: () => null,
                    }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStylesPedidos}
                  />
                </div>
              </div>
              <div className="filter mleft">
                <div className="filter-title required-field">FECHA DESDE</div>
                <div className="filter-input">
                  <DatePicker
                    locale="es"
                    selected={fechaDesde}
                    onChange={(e) => changeFechaDesde(e)}
                    shouldCloseOnSelect={true}
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    /* minDate={ultimaFluctuacionDate?ultimaFluctuacionDate:null} */
                    placeholderText="Seleccionar"
                    /* disabled={step !== 1} */
                  />
                </div>
              </div>
              <div className="filter mleft">
                <div className="filter-title required-field">FECHA HASTA</div>
                <div className="filter-input">
                  <DatePicker
                    locale="es"
                    selected={fechaHasta}
                    onChange={(e) => changeFechaHasta(e)}
                    shouldCloseOnSelect={true}
                    dateFormat="dd/MM/yyyy"
                    maxDate={getMaxDate(fechaDesde)}
                    minDate={fechaDesde}
                    placeholderText="Seleccionar"
                    disabled={fechaDesde?false:true}
                  />
                </div>
              </div>
              <div className="filter">
                <div className="filter-input">
                  <input
                    type="search"
                    className="search"
                    value={filterText}
                    placeholder="Buscar..."
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="actions">
              <PrintComponent
                reff={tableRef}
                pageStyle="@page { size: auto; margin: 3mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }"
                /* classes={`${isArcoprime ? "arcoprime" : ""}`} */
                isROImpersonate={isROImpersonate}
              />

                <ExportExcel
                data={filteredOrdenes}
                labels={labels}
                isROImpersonate={isROImpersonate}
                /* isArcoprime={isArcoprime} */
              />
            </div>
          </div>
          <div className="important-message">
                <span className="title">Importante:&nbsp;</span>
                <span>
                Mientras la OT no se encuentre <b>"Aceptada"</b> el monto real podría cambiar.
                </span>
              </div>
          <OTCobrosPorFormularTable tableRef={tableRef} ordenes={ordenes} filteredOrdenes={filteredOrdenes} setFilteredOrdenes={setFilteredOrdenes} filterText={filterText} paginationFilteredOrdenes={paginationFilteredOrdenes} setPaginationActivePage={setPaginationActivePage} setPaginationFilteredOrdenes={setPaginationFilteredOrdenes} openFile={openFile} />
          <>{filteredOrdenes?.length > 0?
          <div className="pagination-container">
            <Pagination 
              activePage={paginationActivePage}
              itemsCountPerPage={parseInt(paginationItemsPerPage)}
              totalItemsCount={filteredOrdenes?.length}
              pageRangeDisplayed={5}
              onChange={e => handlePageChange(e)}
              hideFirstLastPages={true}
              linkClassPrev='prev'
              linkClassNext='next'
              prevPageText=''
              nextPageText=''
            />
          </div>
          :<>
          </>}
          </>
        </>
      )}
    </div>
  );
};

export default OTCobrosPorFormular;
