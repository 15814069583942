import React from 'react';
import Select from "react-select";
import HeaderData from '../HeaderData';
import { getUpdateDate } from '../utils/monthNames';
import { useState } from 'react';
import { useEffect } from 'react';
import TRXBoxData from '../TRXBoxData';

import montoVerde from "../../assets/img/monto_verde.svg";
import porcentajeRojo from "../../assets/img/porcentaje_rojo.svg";
import sumatoriaAzul from "../../assets/img/sumatoria_azul.svg";
import detalle from "../../assets/img/DOWNLOAD.svg";
import { getPaginationItems, setInstalalaciones } from '../utils/get';

import { SingleOption, customStylesTctTae, getExcelWithXlsx2, getLocaleStringNumber, orderArray, parseBudat, parseDate, parseDateAmerican } from "../utils/utils.js";
import ReactDatePicker from 'react-datepicker';
import Pagination from 'react-js-pagination';
import CustomHighlighter from '../CustomHighlighter';

import Flecha_up from "../../assets/img/Flecha_up.svg";
import Flecha_down from "../../assets/img/Flecha_down.svg";
import OverflowedText from '../OverflowedText';






const InformeFacturacion = ({
  token,
  rutConcesionario,
  callServiceHandler,
  cabecera,
  instalaciones,
  isROImpersonate,
}) => {
  
  let tipoFecha = [
    {
      value: "3",
      label: "Fecha Ejecución"
    },
    {
      value: "1",
      label: "Fecha Emisión"
    },
    {
      value: "2",
      label: "Fecha Pago"
    },
  ]

  

  const [updateDate, setUpdateDate] = useState("");

  const [selectedEds, setSelectedEds] = useState();
  const [allEds, setAllEds] = useState([]);

  const [allTipoFecha, setAllTipoFecha] = useState([]);
  const [selectedTipoFecha, setSelectedTipoFecha] = useState();

  const [fechaInicio, setFechaInicio] = useState();
  const [fechaFin, setFechaFin] = useState();

  const [allTipos, setAllTipos] = useState([]);
  const [selectedTipo, setSelectedTipo] = useState();

  const [filterText, setFilterText] = useState("");

  const [facturas, setFacturas] = useState();

  const [filteredData, setFilteredData] = useState();

  const [activePage, setActivePage] = useState(1)

  const [paginatedData, setPaginatedData] = useState([])

  const [totalGeneral, setTotalGeneral] = useState("-");
  const [ivaGeneral, setIvaGeneral] = useState("-");
  const [netoGeneral, setNetoGeneral] = useState("-");

  const [facturasForExcel, setFacturasForExcel] = useState();

  const [infoForExcel, setInfoForExcel] = useState();

  useEffect(() => {
    if (filteredData) {
      let facturasData = filteredData.map(factura=>{
        return(
          {
            A: factura.numero_factura,
            B: factura.tipo,
            C: factura.grupo_articulo,
            D: factura.fecha_ejecucion,
            E: factura.fecha_emision,
            F: factura.fecha_pago,
            G: factura.neto,
            H: factura.iva,
            I: factura.total,
          }
        )
      }) 
      setFacturasForExcel(facturasData)
    }
  }, [filteredData]);

  

  useEffect(() => {
    if (facturasForExcel) {
      let info = [
        {
          nombre: "Facturas",
          labels: {
            A: "N° Factura",
            B: "Tipo",
            C: "Grupo de Artículos",
            D: "Fecha Ejecución",
            E: "Fecha Emisión",
            F: "Fecha Pago",
            G: "Neto",
            H: "I.V.A.",
            I: "Total",
          },
          transacciones: facturasForExcel
        }
      ]

      setInfoForExcel(info)
    }
  }, [facturasForExcel]);


  useEffect(() => {
    setAllTipoFecha(tipoFecha)
    setSelectedTipo({
      value: "TOD",
      label: "Todos"
    })
    setSelectedTipoFecha(tipoFecha[1])
  }, []);

  useEffect(() => {
    getUpdateDate(setUpdateDate);
  }, []);

  useEffect(() => {
    if (allEds.length === 1){
      setSelectedEds(allEds[0]);
    }
  }, [allEds]);

  useEffect(() => {
    if (instalaciones !== "") {
      setInstalalaciones(instalaciones, setAllEds);
    }
  }, [instalaciones]);

  useEffect(() => {
      filterr();
      console.log("text filtr", filterText)
  }, [filterText]);

  function handlePageChange(pageNumber){
    getPaginationItems(filteredData, pageNumber, setPaginatedData, setActivePage);
  }

  useEffect(() => {
    // setActivePage(1)
    // if(filteredData?.length <= 15)
    // setPaginatedData(filteredData)
    // else
    // setPaginatedData(filteredData?.slice(0, 15))
    // setActivePage(1)
    // console.log("fd",filteredData)
    if (filteredData?.length > 0) {
      getPaginationItems(filteredData, 1, setPaginatedData, setActivePage)
    }else{
      getPaginationItems([], 1, setPaginatedData, setActivePage)
    }
  }, [filteredData])

  useEffect(() => {
    if (selectedEds && selectedTipoFecha && fechaInicio && fechaFin && selectedTipo) {
      console.log("se eligio todo")
      getFacturas()
    }
  }, [selectedEds, selectedTipoFecha, fechaInicio, fechaFin]);

  useEffect(() => {
    if (selectedTipo && facturas) {
      filterTipo(selectedTipo.value)
    }
  }, [selectedTipo, facturas]);

  const abrirEnlace = (enlace) =>{
    let url = enlace;
    
    window.open(url, "_blank");
  }

  function changeEds(e) {
    if (e !== selectedEds) {
      setSelectedEds(e);
    }
  }

  function changeTipoFecha(e) {
    if (e !== selectedTipoFecha) {
      setSelectedTipoFecha(e);
    }
  }
  
  function changeGrupoArticulos(e) {
    if (e !== selectedTipo) {
      setSelectedTipo(e);
    }
  }

  function getMaxDate(fechaD){
    const now = new Date();
    console.log("ahora", now)
    console.log("fecha de select", fechaD)
    let fechaEnSeisMeses = new Date(fechaD)

    fechaEnSeisMeses.setMonth(fechaEnSeisMeses.getMonth() + 6);

    console.log("fecha en 6", fechaEnSeisMeses)
    const minMasUno = new Date(fechaD?.getFullYear()+1,fechaD?.getMonth(),fechaD?.getDate())
    console.log("nose :v ", minMasUno)

    if (now>fechaEnSeisMeses) {
      return fechaEnSeisMeses
    }else{
      if(now>minMasUno){
        return minMasUno;
        
      }else{
        return now;
      }
    }

  }

  function changeFechaInicio(e) {
    setFechaInicio(e);
    setFechaFin();
  }

  const getFacturas = async () => {

    const url =
    process.env.REACT_APP_BASE_API +
    "/cuenta-corriente/consulta-reporte-facturas?id_eds=" + selectedEds.value
    + "&rut_concesionario=" + rutConcesionario
    + "&tipo_fecha=" + selectedTipoFecha.value
    + "&fecha_inicio="+ parseDate(fechaInicio)
    + "&fecha_fin=" + parseDate(fechaFin)
    + "&id_arcoprime=" + ""
    // + "&id_pagador=" + cabecera.DATOS_CLIENTE.PAGADOR
    ;
    // const url=
    // process.env.REACT_APP_BASE_API +
    // "/cuenta-corriente/consulta-reporte-facturas"
    // ;
    let response = await callServiceHandler(url, "GET");

    console.log("facturas", response.data)

    console.log("facturas 2", response.data.facturas)

    let tipos_tot = Array.from(new Set(response.data.facturas.map(fact=>fact.tipo))).sort()

    // console.log("los tipos sin sort", tipos_tot)
    // console.log("los tipos CON sort", tipos_tot.sort())
    // console.log("los tipos2", Array.from(new Set(tipos_tot)))
    
    let newTipos = tipos_tot.map(tipo=>({value: tipo, label:tipo}))

    newTipos.unshift({
      value: "TOD",
      label: "Todos"
    })

    
    console.log("los tipos2 sin sort", newTipos)
    setAllTipos(newTipos)
    
    let factu = response.data.facturas

    factu = [...factu]
    // setFacturas(response.data.facturas)

    let factuAux = []
    factu.forEach(factura => {
      const [day_e, month_e, year_e] = factura.fecha_emision.split('.');
      factura.fecha_emision_date = new Date(year_e, month_e - 1, day_e);

      const [day_p, month_p, year_p] = factura.fecha_pago.split('.');
      factura.fecha_pago_date = new Date(year_p, month_p - 1, day_p);

      const [day_ej, month_ej, year_ej] = factura.fecha_ejecucion.split('.');
      factura.fecha_ejecucion_date = new Date(year_ej, month_ej - 1, day_ej);

      factuAux.push(factura)
    });

    console.log("aux de factura", factuAux)

    setFacturas(factuAux)
    // setFilteredData(response.data.facturas)
    setFilteredData(factuAux)
  }

  const filterr = () => {
    let dataArr
    if ((filterText !== "")) {
      let filterTextLower = filterText.toLowerCase();
      filterTextLower = filterTextLower.replaceAll(".", "");
      dataArr = facturas.filter(data => {
        return (
          data.numero_factura.toLowerCase().includes(filterTextLower) ||
          data.tipo.toLowerCase().includes(filterTextLower) ||
          data.grupo_articulo.toLowerCase().includes(filterTextLower) ||
          data.fecha_ejecucion.toLowerCase().includes(filterTextLower) ||
          data.fecha_emision.toLowerCase().includes(filterTextLower) ||
          data.fecha_pago.toLowerCase().includes(filterTextLower) ||
          toString(data.neto).toLowerCase().includes(filterTextLower) ||
          toString(data.iva).toLowerCase().includes(filterTextLower) ||
          toString(data.total).toLowerCase().includes(filterTextLower)
        )
      })
      console.log("algo se escribe")
      setFilteredData(dataArr)
    } else {
      console.log("nada se escribe")
      setFilteredData(facturas)
    }
  
  }

  

  const orderTable = (filterkey, order, parse) => {
    const arr = orderArray(filterkey, filteredData, order, parse);
    setFilteredData(arr);
  };

  const filterTipo = (tipo_seleccionado) => {
    if (tipo_seleccionado === "TOD") {
      setFilteredData(facturas)
      let total_general = facturas.reduce((acumulador, factura)=>{return acumulador + factura.total}, 0)
      // console.log("EL TOTAL GENERAL ES,", total_general)
      setTotalGeneral(total_general)

      let iva_general = facturas.reduce((acumulador, factura)=>{return acumulador + factura.iva}, 0)
      // console.log("EL IVA GENERAL ES,", iva_general)
      setIvaGeneral(iva_general)

      let neto_general = facturas.reduce((acumulador, factura)=>{return acumulador + factura.neto}, 0)
      // console.log("EL NETO GENERAL ES,", neto_general)
      setNetoGeneral(neto_general)
    }else{
      let arregloFiltradoByTipo = facturas.filter(factura=>factura.tipo===tipo_seleccionado)
      // console.log("el arreglo con un filtro nuevo es", arregloFiltradoByTipo)
      setFilteredData(arregloFiltradoByTipo)
      
      let total_general = arregloFiltradoByTipo.reduce((acumulador, factura)=>{return acumulador + factura.total}, 0)
      // console.log("EL TOTAL GENERAL ES,", total_general)
      setTotalGeneral(total_general)
      
      let iva_general = arregloFiltradoByTipo.reduce((acumulador, factura)=>{return acumulador + factura.iva}, 0)
      // console.log("EL IVA GENERAL ES,", iva_general)
      setIvaGeneral(iva_general)

      let neto_general = arregloFiltradoByTipo.reduce((acumulador, factura)=>{return acumulador + factura.neto}, 0)
      // console.log("EL NETO GENERAL ES,", neto_general)
      setNetoGeneral(neto_general)
    }
  }

  

  const handleDetalle = () => {
    getExcelWithXlsx2(infoForExcel, `Detalle Facturas ${parseInt(selectedEds.value)} ${parseDate(fechaInicio)}-${parseDate(fechaFin)}.xlsx`)
  }

  return (
    <div className='informe-facturacion-container'>
      <div className="view-title">Facturación y Flujo</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Detalle Documentos Tributarios</div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandler={callServiceHandler}
        cabecera={cabecera}
        actualizable={true}
      />
      <div className="cards-container">
        <TRXBoxData
          icon={montoVerde}
          row1Title={"Monto Neto"}
          row2Amount={`$ ${getLocaleStringNumber(netoGeneral)}`}
        />
        <TRXBoxData
          icon={porcentajeRojo}
          row1Title={"IVA"}
          row2Amount={`$ ${getLocaleStringNumber(ivaGeneral)}`}
        />
        <TRXBoxData
          icon={sumatoriaAzul}
          row1Title={"Monto Total"}
          row2Amount={`$ ${getLocaleStringNumber(totalGeneral)}`}
        />
      </div>
      <hr />
      <div className="informe-facturacion-main-container">
        <div className="total-filters-container">
          <div className="filters-container">
            <div className="filter">
              <div className="filter-title required-field">
                ESTACIÓN DE SERVICIO
              </div>
              <div className="filter-input">
                <Select
                  className="eds-select"
                  value={selectedEds}
                  onChange={(e) => changeEds(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allEds}
                  components={{
                    SingleOption,
                    IndicatorSeparator: () => null,
                  }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesTctTae}
                  width={"168px"}
                />
              </div>
            </div>
            <div className="filter">
              <div className="filter-title required-field">
                TIPO DE FECHA
              </div>
              <div className="filter-input">
                <Select
                  className="eds-select"
                  value={selectedTipoFecha}
                  onChange={(e) => changeTipoFecha(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allTipoFecha}
                  components={{
                    SingleOption,
                    IndicatorSeparator: () => null,
                  }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesTctTae}
                  width={"168px"}
                  isDisabled={true}
                />
              </div>
            </div>
            <div className="filter">
              <div className="filter-title required-field">
                FECHA INICIO
              </div>
              <div className="filter-input fecha">
                <ReactDatePicker
                  locale="es"
                  selected={fechaInicio}
                  onChange={(e) => changeFechaInicio(e)}
                  shouldCloseOnSelect={true}
                  dateFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  minDate={new Date(2023, 0, 1)}
                  placeholderText="Seleccionar"
                />
              </div>
            </div>
            <div className="filter">
              <div className="filter-title required-field">
                FECHA FIN
              </div>
              <div className="filter-input fecha">
                <ReactDatePicker
                  locale="es"
                  selected={fechaFin}
                  onChange={(e) => setFechaFin(e)}
                  shouldCloseOnSelect={true}
                  dateFormat="dd/MM/yyyy"
                  minDate={fechaInicio}
                  maxDate={getMaxDate(fechaInicio)}
                  placeholderText="Seleccionar"
                  disabled={fechaInicio ? false : true}
                />
              </div>
            </div>
            <div className="filter">
              <div className="filter-title">
                TIPO
              </div>
              <div className="filter-input">
                <Select
                  className="grupo-articulos-select"
                  value={selectedTipo}
                  onChange={(e) => changeGrupoArticulos(e)}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  options={allTipos}
                  components={{
                    SingleOption,
                    IndicatorSeparator: () => null,
                  }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStylesTctTae}
                  width={"168px"}
                />
              </div>
            </div>
          </div>
          <div className="search-bar-container">
            <div className="filters-container">
              <div className="filter">
                <div className="filter-input">
                  <input
                    type="search"
                    className="search"
                    value={filterText}
                    placeholder="Buscar..."
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="detalle-container">
            <div className="detalle-button" onClick={facturas&&handleDetalle}>
              <img src={detalle} />
              <p>Detalle</p>
            </div>
          </div>
        </div>
        <div className="informe-facturacion-table-container">
          <table className="informe-facturacion-table">
            <tr>
              <th>
                <div className="th-data width-120">
                  <div className="th-text">
                    N° Documento
                    <div className="arrows">
                      <img
                        onClick={() => orderTable("numero_factura", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("numero_factura", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data width-196">
                  <div className="th-text">
                    Tipo
                    <div className="arrows">
                      <img
                        onClick={() => orderTable("tipo", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("tipo", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data width-197">
                  <div className="th-text">
                    Grupo de Artículos
                    <div className="arrows">
                      <img
                        onClick={() => orderTable("grupo_articulo", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("grupo_articulo", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data width-120">
                  <div className="th-text two-lines">
                    Fecha
                    <br />
                    Ejecución
                    <div className="arrows mtop">
                      <img
                        onClick={() => orderTable("fecha_ejecucion_date", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("fecha_ejecucion_date", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data width-120">
                  <div className="th-text two-lines">
                    Fecha
                    <br/>
                    Emisión
                  
                    <div className="arrows mtop">
                      <img
                        onClick={() => orderTable("fecha_emision_date", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("fecha_emision_date", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data width-120">
                  <div className="th-text two-lines">
                    Fecha 
                    <br/>
                    Pago
                    <div className="arrows mtop">
                      <img
                        onClick={() => orderTable("fecha_pago_date", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("fecha_pago_date", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data width-120">
                  <div className="th-text">
                    Neto
                    <div className="arrows">
                      <img
                        onClick={() => orderTable("neto", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("neto", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data width-120">
                  <div className="th-text">
                    I.V.A.
                    <div className="arrows">
                      <img
                        onClick={() => orderTable("iva", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("iva", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
              <th>
                <div className="th-data width-120">
                  <div className="th-text">
                    Total
                    <div className="arrows">
                      <img
                        onClick={() => orderTable("total", "asc", false)}
                        src={Flecha_up}
                      ></img>
                      <img
                        onClick={() => orderTable("total", "desc", false)}
                        src={Flecha_down}
                      ></img>
                    </div>
                  </div>
                </div>
              </th>
            </tr>
            <tr className="table-separator-row-border-top">
              <td colSpan="100%" />
            </tr>
            <tbody>
              {
                paginatedData 
                ?
                paginatedData.length > 0 
                  ?
                    paginatedData.map(factura=>{
                      return(
                        <tr className="data">
                          <td className="align-left table-link" onClick={e=>abrirEnlace(factura.url)}>
                            <CustomHighlighter text={filterText}>
                              {factura.numero_factura}
                            </CustomHighlighter>
                          </td>
                          <td className="align-left">
                            <OverflowedText widthContainer={196}>
                              <CustomHighlighter text={filterText}>
                                {factura.tipo}
                              </CustomHighlighter>
                            </OverflowedText>
                          </td>
                          <td className="align-left">
                            <CustomHighlighter text={filterText}>
                              {factura.grupo_articulo}
                            </CustomHighlighter>
                          </td>
                          <td className="align-center">
                            <CustomHighlighter text={filterText}>
                              {factura.fecha_ejecucion === ""?"-": factura.fecha_ejecucion.replace(/\./g, '/')}
                            </CustomHighlighter>
                          </td>
                          <td className="align-center">
                            <CustomHighlighter text={filterText}>
                              {factura.fecha_emision === ""?"-": factura.fecha_emision.replace(/\./g, '/')}
                            </CustomHighlighter>
                          </td>
                          <td className="align-center">
                            <CustomHighlighter text={filterText}>
                              {factura.fecha_pago === ""?"-": factura.fecha_pago.replace(/\./g, '/')}
                            </CustomHighlighter>
                          </td>
                          <td className="align-right">
                            <CustomHighlighter text={filterText}>
                              {`$ ${getLocaleStringNumber(factura.neto)}`}
                            </CustomHighlighter>
                          </td>
                          <td className="align-right"> 
                            <CustomHighlighter text={filterText}>
                              {`$ ${getLocaleStringNumber(factura.iva)}`}
                            </CustomHighlighter>
                          </td>
                          <td className="align-right"> 
                            <CustomHighlighter text={filterText}>
                              {`$ ${getLocaleStringNumber(factura.total)}`}
                            </CustomHighlighter>
                          </td>
                        </tr>
                      )
                    })
                  :
                    <>
                      <tr className="data">
                        <td colSpan={100} className='no-data'>No existe facturas para la selección de filtros elegidos</td>
                      </tr>
                      <tr className="data">
                        <td colSpan={100}></td>
                      </tr>
                    </>
                :
                  <>
                    <tr className="data">
                      <td colSpan={100} className='no-data'>Seleccionar filtros para obtener facturas</td>
                    </tr>
                    <tr className="data">
                      <td colSpan={100}></td>
                    </tr>
                  </>
              }
            </tbody>
          </table>
          <div className="pagination-container">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={15}
              totalItemsCount={filteredData?.length}
              pageRangeDisplayed={5}
              onChange={e => handlePageChange(e)}
              hideFirstLastPages={true}
              linkClassPrev='prev'
              linkClassNext='next'
              prevPageText=''
              nextPageText=''
            />
            </div>
        </div>
      </div>
    </div>
  );
}

export default InformeFacturacion;
