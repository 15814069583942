/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../assets/img/logo-comprobante.svg";
import errorICon from "../../assets/img/error-icon.png";
import successICon from "../../assets/img/success-icon.png";
import descargar from "../../assets/img/descargar.svg";
import stamp from "../../assets/img/timbre-comprobante.svg";
import {
  parseBudat,
  getLocaleStringNumber,
  parseDate,
} from "../utils/utils.js";
import { hideLoader, parentRedirect } from "../utils/windows";
import { getUpdateDate } from "../utils/monthNames";
import html2pdf from 'html2pdf.js'
import PrintComponent from "../PrintComponent";
import { ViewPortContext } from "../../App";
import HeaderData from "../HeaderData";
import { Stepper } from "../StepperComponents/Stepper";
import CustomModal from "../CustomModal";

const ref = React.createRef();

const ComprobanteTrx = ({ token, params, isROImpersonate, callServiceHandler }) => {
  const [displayPagar, setDisplayPagar] = useState(false);
  const [displayAbonar, setDisplayAbonar] = useState(false);
  const [displayContingencia, setDisplayContingencia] = useState(false);
  const [displayModal, setDisplayModal] = useState(false)
  const [modalText, setModalText] = useState("")

  const today = new Date();
  const parsedDate = parseDate(today);
  const { bottomCoordinate } = useContext(ViewPortContext);

  const location = useLocation();

  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [totalSaldo, setTotalSaldo] = useState(0);
  const [totalAPagar, setTotalAPagar] = useState(0);
  const [rutConcesionario, setRutConcesionario] = useState("");
  const [banco, setBanco] = useState("");
  const [bancoId, setBancoId] = useState();
  const [clientName, setClientName] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [title, setTitle] = useState("");
  const [propuesta, setPropuesta] = useState();
  const [error, setError] = useState(false);
  const [contabilizado, setContabilizado] = useState(false);

  const [abonos, setAbonos] = useState();
  const [lineasAbono, setLineasAbono] = useState([]);
  const [fechaPago, setFechaPago] = useState("");
  const [updateDate, setUpdateDate] = useState("");
  const [tipoPago, setTipoPago] = useState("");

  useEffect(() => {
    if (params) {
      console.log(token);
      getUpdateDate(setUpdateDate);
      const numPropuesta = new URLSearchParams(params).get("id-propuesta");
      const rawPropuesta = window.localStorage.getItem(numPropuesta);
      const infoPropuesta = JSON.parse(rawPropuesta);
      console.log(infoPropuesta);
      console.log(location.pathname);
      console.log(numPropuesta);
      if (infoPropuesta) {
        if (infoPropuesta.tipo === "pago") {
          setTitle(infoPropuesta.title);
          setSelectedTransactions(infoPropuesta.selectedTransactions);
          setDisplayPagar(true);
          setTotalAPagar(infoPropuesta.totalAPagar);
          setTipoPago("pago");
        } else if (infoPropuesta.tipo === "abono") {
          setAbonos(infoPropuesta.abonos);
          setLineasAbono(infoPropuesta.lineasAbono);
          setDisplayAbonar(true);
          setTipoPago("abono");
        }
        setTotalSaldo(infoPropuesta.totalSaldo);
        setRutConcesionario(infoPropuesta.rutConcesionario);
        setBanco(infoPropuesta.banco);
        setClientName(infoPropuesta.clientName);
        setClientAddress(infoPropuesta.clientAddress);
        setPropuesta(infoPropuesta.propuesta);
        setBancoId(infoPropuesta.bancoId);
        if (location.pathname === "/fracaso-trx") {
          setError(true);
          console.log("aqui2");
        }
        if (location.pathname === "/contingencia-trx") {
          setDisplayContingencia(true);
          console.log("aqui");
        }
      }
    }
  }, [params]);

  useEffect(async () => {
    if (token && propuesta && bancoId && totalSaldo) {
      if (!contabilizado) {
        if (location.pathname === "/exito-trx") {
          if (displayPagar) {
            contabilizarPago(propuesta, totalAPagar);
            setContabilizado(true);
          }
          if (displayAbonar) {
            contabilizarAbono(propuesta, totalSaldo);
            setContabilizado(true);
          }
        }
      }

      const url = process.env.REACT_APP_BASE_API +
        "/cuenta-corriente/obtener-historial-pagos?rut_concesionario=" + rutConcesionario +
        "&grupo_ppta=" + propuesta

      let fecha
      let result = await callServiceHandler(url, "GET")
      if(result !== "error"){
        fecha = parseBudat(result.data.PROPUESTAS_PAGO[0].FECHA);
        fecha = fecha + " - " + result.data.PROPUESTAS_PAGO[0].HORA;
      }

      setFechaPago(fecha);
    }
  }, [token, propuesta, bancoId, totalSaldo]);

  async function contabilizarPago(propuesta, monto) {
    const url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/contabilizar-pago?grupo_ppta=" +
      propuesta +
      "&monto=" +
      monto;

    let result = await callServiceHandler(url, "POST")
    if(result !== "error"){
      if (result.data.MENSAJE !== "") {
        setDisplayModal(true)
        setModalText(result.data.MENSAJE)
      }
    }
  }

  async function contabilizarAbono(propuesta, monto) {
    const url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/contabilizar-abono?grupo_ppta=" +
      propuesta +
      "&monto=" +
      monto;

    let result = await callServiceHandler(url, "POST")
    if(result !== "error"){
      if (result.data.MENSAJE !== "") {
        setDisplayModal(true)
        setModalText(result.data.MENSAJE)
      }
    }
  }

  const irAPagos = () => {
    const url = process.env.REACT_APP_PAGOS_PATH;
    parentRedirect(url);
  };

  function closeModal(){
    setDisplayModal(false)
  }

  function printPdf(){
    const element = document.getElementById('comprobante-trx-id')
    html2pdf(element, {filename: 'comprobante.pdf', margin: 5})
  }

  return (
    <>
      <div className="title">
        <div className="view-title">Cuenta corriente</div>
        <div className="view-title-separator">|</div>
        <div className="view-subtitle">
          {tipoPago === "pago"
            ? "Pagos y compensaciones"
            : tipoPago === "abono"
            ? "Realizar abonos"
            : ""}
        </div>
      </div>
      <hr className="title-separator-line" />

      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandler={callServiceHandler}
      />

      <hr />

      {displayContingencia && (
        <>
          <div className="abonar-en-banco">
            <div className="abonar-en-banco__content">
              <Stepper
                step={3}
                error={error}
                totalSteps="3"
                title={`${displayPagar ? "Pagar en banco" : "Abono en cuenta"}`}
                step1Title="Confirmar"
                step2Title={`${displayPagar ? "Pagar" : "Abonar"}`}
                step3Title="Finalizar"
              />
              <div className="abonar-en-banco__step-content">
                <div className="contingencia">
                  Transaccion {propuesta}. No hemos recibido información por
                  parte del banco, por favor revisar su pago en 10 minutos mas
                  en Historial de Pagos
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {displayPagar && !displayContingencia && (
        <>
          <div className="abonar-en-banco">
            <div className="abonar-en-banco__content">
              <Stepper
                step={3}
                error={error}
                totalSteps="3"
                title="Pagar en banco"
                step1Title="Confirmar"
                step2Title="Pagar"
                step3Title="Finalizar"
              />
              <div className="abonar-en-banco__step-content">
                <div className="abonar-en-banco__report" ref={ref} id="comprobante-trx-id">
                  <img src={logo} alt="" />
                  <h2 className="abonar-en-banco__report__title">
                    {error ? "Transacción rechazada" : title}
                  </h2>
                  <hr />
                  <div className="abonar-en-banco__report__details">
                    <p>
                      <span>
                        <strong>Cliente: </strong>
                      </span>
                      {rutConcesionario} {clientName}
                    </p>
                    <p>
                      <span>
                        <strong>Dirección: </strong>
                      </span>
                      {clientAddress}
                    </p>
                    <p>
                      <span>
                        <strong>Nº de Transacción: </strong>
                      </span>
                      {propuesta}
                    </p>
                    <p>
                      <span>
                        <strong>Fecha: </strong>
                      </span>
                      {fechaPago}
                    </p>
                  </div>
                  {error ? (
                    <>
                      <hr />
                      <div className="abonar-en-banco__report__footer">
                        <img src={errorICon} alt="" />
                        <h3>Pago no realizado</h3>
                        <p>La transacción no se ha completado</p>
                      </div>
                    </>
                  ) : (
                    <div className="abonar-en-banco__report__table-section">
                      <table className="abonar-en-banco__report__table">
                        <thead>
                          <tr>
                            <th>
                              Fecha
                              <br />
                              Vencimiento
                            </th>
                            <th>
                              Tipo de
                              <br />
                              documento
                            </th>
                            <th>Nº Documento</th>
                            {/* <th>Descuento Pronto&nbsp;Pago&nbsp;(%)</th> */}
                            <th>Valor</th>
                          </tr>
                        </thead>
                        <tbody>
                          {selectedTransactions.map((mov, key) => (
                            <>
                              <tr key={key} className="data">
                                <td className="align-left">
                                  {parseBudat(mov.FECHA_VENCIMIENTO)}
                                </td>
                                <td className="align-left">
                                  {mov.TIPO_DOCUMENTO}
                                </td>
                                <td className="align-left">
                                  {mov.NUMERO_DOCUMENTO}
                                </td>
                                <td className="align-right">
                                  ${" "}
                                  {parseInt(mov.CARGO) > 0
                                    ?  getLocaleStringNumber(parseInt(mov.CARGO))
                                    : "-" + getLocaleStringNumber(parseInt(mov.ABONO))}
                                </td>
                              </tr>
                              <tr className="data separator">
                                <td
                                  className="trseparator align-left"
                                  colSpan="4"
                                >
                                  <div className="line-sep"></div>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan="3">
                              <b>Total pago:</b> {banco}
                            </td>
                            <td className="align-right">
                              <b>${getLocaleStringNumber(totalAPagar)}</b>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                      <img
                        className="abonar-en-banco__report__stamp"
                        src={stamp}
                      />
                    </div>
                  )}
                </div>

                <div className="abonar-en-banco__report-mobile">
                  <div className="abonar-en-banco__report__footer">
                    {error ? (
                      <>
                        <img src={errorICon} alt="" />
                        <h3>Pago no realizado</h3>
                        <p>La transacción no se ha completado</p>
                      </>
                    ) : (
                      <>
                        <img src={successICon} alt="" />
                        <h3>Transacción exitosa</h3>
                        <p>
                          La transacción ha sido realizada con éxito. Nº de
                          Transacción:
                          {propuesta}
                        </p>
                      </>
                    )}
                    <button
                      onClick={printPdf}
                      className="abonar-en-banco__report-mobile__open-voucher"
                    >
                      Ver comprobante
                    </button>
                  </div>
                </div>

                <div
                  className="abonar-en-banco__report-actions"
                  style={{ bottom: bottomCoordinate }}
                >
                  <button className="main-button" onClick={() => irAPagos()}>
                    Finalizar
                  </button>
                  <PrintComponent 
                    reff={ref}
                    classes="icon-btn cursor-pointer"
                    pageStyle="@page { size: 250mm 170mm; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }"
                    isROImpersonate={isROImpersonate}
                  />

                  <button
                    onClick={printPdf}
                    className="icon-btn cursor-pointer"
                  >
                    <img src={descargar} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {displayAbonar && !displayContingencia && (
        <>
          <div className="abonar-en-banco">
            <div className="abonar-en-banco__content">
              <Stepper
                step={3}
                error={error}
                totalSteps="3"
                title="Abono en Cuenta"
                step1Title="Confirmar"
                step2Title="Abonar"
                step3Title="Finalizar"
              />
              <div className="abonar-en-banco__step-content">
                <div className="abonar-en-banco__report" ref={ref}>
                  <img src={logo} alt="" />
                  <h2 className="abonar-en-banco__report__title">
                    {error ? "Transacción rechazada" : "Comprobante de Abono"}
                  </h2>
                  <hr />
                  <div className="abonar-en-banco__report__details">
                    <p>
                      <span>
                        <strong>Cliente: </strong>
                      </span>
                      {clientName}
                    </p>
                    <p>
                      <span>
                        <strong>Dirección: </strong>
                      </span>
                      {clientAddress}
                    </p>
                    <p>
                      <span>
                        <strong>Nº de Transacción: </strong>
                      </span>
                      {propuesta}
                    </p>
                    <p>
                      <span>
                        <strong>Fecha: </strong>
                      </span>
                      {fechaPago}
                    </p>
                  </div>
                  {error ? (
                    <>
                      <hr />
                      <div className="abonar-en-banco__report__footer">
                        <img src={errorICon} alt="" />
                        <h3>Abono no realizado</h3>
                        <p>La transacción no se ha completado</p>
                      </div>
                    </>
                  ) : (
                    <div className="abonar-en-banco__report__table-section">
                      <table className="abonar-en-banco__report__table">
                        <thead>
                          <tr>
                            <th>Línea Producto</th>
                            <th>Fecha Abono</th>
                            <th>Monto</th>
                          </tr>
                        </thead>
                        <tbody>
                          {abonos.map((abono, key) => (
                            <>
                              <tr>
                                <td>
                                  {
                                    lineasAbono.find(
                                      (e) => e.LINEA === abono.linea
                                    ).DESCRIPCION
                                  }
                                </td>
                                <td>{parsedDate}</td>
                                <td>{`$ ${getLocaleStringNumber(
                                  parseInt(abono.monto)
                                )}`}</td>
                              </tr>
                              <tr className="data separator">
                                <td
                                  className="trseparator align-left"
                                  colSpan="5"
                                >
                                  <div className="line-sep"></div>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan="2">Total pago: {banco}</td>
                            <td>{`$ ${getLocaleStringNumber(totalSaldo)}`}</td>
                          </tr>
                        </tfoot>
                      </table>
                      <img
                        className="abonar-en-banco__report__stamp"
                        src={stamp}
                      />
                    </div>
                  )}
                </div>

                <div className="abonar-en-banco__report-mobile">
                  <div className="abonar-en-banco__report__footer">
                    {error ? (
                      <>
                        <img src={errorICon} alt="" />
                        <h3>Pago no realizado</h3>
                        <p>La transacción no se ha completado</p>
                      </>
                    ) : (
                      <>
                        <img src={successICon} alt="" />
                        <h3>Transacción exitosa</h3>
                        <p>
                          La transacción ha sido realizada con éxito. Nº de
                          Transacción:
                          {propuesta}
                        </p>
                      </>
                    )}
                    <button
                      onClick={printPdf}
                      className="abonar-en-banco__report-mobile__open-voucher"
                    >
                      Ver comprobante
                    </button>
                  </div>
                </div>

                <div className="abonar-en-banco__report-actions">
                  <button onClick={() => irAPagos()} className="main-button ">
                    Finalizar
                  </button>
                  <PrintComponent 
                    reff={ref}
                    classes="icon-btn cursor-pointer"
                    pageStyle="@page { size: 250mm 200mm; margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; padding: 0px !important; } }"
                    isROImpersonate={isROImpersonate}
                  />
                  <button
                    onClick={printPdf}
                    className="icon-btn cursor-pointer"
                  >
                    <img src={descargar} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <CustomModal
        displayModal={displayModal}
        closeModal={closeModal}
        acceptFunc={closeModal}
        modalTitle="Error"
        modalText={modalText}
        modalIcon='error'
      />
    </>
  );
};

export default ComprobanteTrx;
