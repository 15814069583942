import React, { useState, useEffect } from "react";
import Select from "react-select";

import HeaderData from "../../HeaderData";
import HeaderBox from "../../HeaderBox";
import saldoAzulIcon from "../../../assets/img/saldo-azul.svg";
import imprimir from "../../../assets/img/imprimir.svg";
import descargar from "../../../assets/img/descargar.svg";
import { getUpdateDate } from "../../utils/monthNames";

import { setInstalalaciones } from "../../utils/get";
import { SingleOption, customStylesTctTae, parseDateAmerican } from "../../utils/utils.js";
import TabSelector from "../../TabSelector";
import ConsultarGuiasManualesPorNumeroGuia from "./ConsultarGuiasManualesPorNumeroGuia";
import ConsultarGuiasManualesInfo from "./ConsultarGuiasManualesInfo";
import ConsultarGuiasManualesPorFecha from "./ConsultarGuiasManualesPorNumeroFecha";

const ConsultarGuiasManuales = ({
  token,
  rutConcesionario,
  callServiceHandler,
  cabecera,
  instalaciones,
  isROImpersonate,
}) => {

  // let info = [
  //   {
  //     nro_guia: "15964141",
  //     fecha_autorizacion: "06/03/2023",
  //     codigo_autorizacion: "1314912",
  //     motivo: "Guía TAE",
  //     producto: "TAE Diesel",
  //     cliente: "124364 - austin ingenieros ltda.",
  //     patente: "AUSTIN ING.",
  //     nro_tarjeta: "1-124364-00083-9-1",
  //     monto: "$ 2.285.800",
  //     estado: "autorizada"
  //   },
  //   {
  //     nro_guia: "15964160",
  //     fecha_autorizacion: "06/03/2023",
  //     codigo_autorizacion: "1136465",
  //     motivo: "Terminal Malo",
  //     producto: "TCT Diesel",
  //     cliente: "629325 - nazar distribucion lt…",
  //     patente: "TAE",
  //     nro_tarjeta: "1-772131-00001-9-8",
  //     monto: "$ 1.428.600",
  //     estado: "confirmada"
  //   },
  //   {
  //     nro_guia: "15963967",
  //     fecha_autorizacion: "06/03/2023",
  //     codigo_autorizacion: "1203575",
  //     motivo: "Guía TAE",
  //     producto: "TCT Lubrica…",
  //     cliente: "243296 - transportes huahum …",
  //     patente: "TAE",
  //     nro_tarjeta: "1-243296-00114-3-7",
  //     monto: "$ 3.848.800",
  //     estado: "anulada"
  //   },
  // ]

  const [updateDate, setUpdateDate] = useState("");
  const [selectedTab, setSelectedTab] = useState(2);
  const [selectedEds, setSelectedEds] = useState();
  const [allEds, setAllEds] = useState([]);
  const [numeroGuia, setNumeroGuia] = useState();
  const [fechaAutorizacion, setFechaAutorizacion] = useState();
  const [horaAutorizacion, setHoraAutorizacion] = useState();
  const [rutCliente, setRutCliente] = useState();
  const [numeroTarjeta, setNumeroTarjeta] = useState();
  const [codigoAutorizacion, setCodigoAutorizacion] = useState();
  const [displayInfo, setDisplayInfo] = useState(false);
  const [producto, setProducto] = useState();
  const [displayFechaTable, setDisplayFechaTable] = useState(false);
  const [displayGuiaTable, setDisplayGuiaTable] = useState(false);

  const [comuna, setComuna] = useState();
  const [direccion, setDireccion] = useState();
  const [giroComercial, setGiroComercial] = useState();
  const [monto, setMonto] = useState(0);
  const [nombreCliente, setNombreCliente] = useState();
  const [precio, setPrecio] = useState(0);
  const [unidad, setUnidad] = useState(0);
  const [flete, setFlete] = useState(0);

  const [fechaInicio, setFechaInicio] = useState();
  const [fechaFin, setFechaFin] = useState();

  const [guia, setGuia] = useState();
  const [numeroGuiaEnviado, setNumeroGuiaEnviado] = useState(false);

  const [codigoCliente, setCodigoCliente] = useState();
  const [numeroTransaccion, setNumeroTransaccion] = useState();

  const [codigoProducto, setCodigoProducto] = useState();
  const [codigoSubProducto, setCodigoSubProducto] = useState();
  
  const [nombrePortal, setNombrePortal] = useState();
  const [rutPortal, setRutPortal] = useState();
  
  const [guiaEstado, setGuiaEstado] = useState();

  const [accion, setAccion] = useState();
  
  // const [allTCT_TAE_Products, setAllTCT_TAE_Products] = useState();
  // const [selected_TCT_TAE_Product, setSelected_TCT_TAE_Product] = useState();

  // const [allEstadoGuia, setAllEstadoGuia] = useState();
  // const [selectedEstadoGuia, setSelectedEstadoGuia] = useState();



  const [data, setData] = useState();

  const [guias, setGuias] = useState();

  useEffect(() => {
    if (codigoSubProducto) {
      console.log("el codigo subproduicto es", codigoSubProducto)
    }
  }, [codigoSubProducto]);

  useEffect(() => {
    if (codigoProducto) {
      console.log("el producto codigo es", codigoProducto)
    }
  }, [codigoProducto]);

  useEffect(() => {
    if (cabecera) {
      console.log("cab", cabecera)
      console.log("cab dat c", cabecera.DATOS_CLIENTE)
      console.log("cab dat c NOMRBE", cabecera.DATOS_CLIENTE.NOMBRE)
      setNombrePortal(cabecera.DATOS_CLIENTE.NOMBRE)
    }
  }, [cabecera]);

  useEffect(() => {
    if (rutConcesionario) {
      console.log("ruc conse", rutConcesionario)
      setRutPortal(rutConcesionario)
    }
  }, [rutConcesionario]);


  useEffect(() => {
    getUpdateDate(setUpdateDate);
  }, []);

  useEffect(() => {
    if (instalaciones !== "") {
      setInstalalaciones(instalaciones, setAllEds);
    }
  }, [instalaciones]);

  useEffect(() => {
    if (allEds.length === 1){
      setSelectedEds(allEds[0]);
    }
  }, [allEds]);

  const openInfoTable = () => {
    getGuia()
    setDisplayGuiaTable(true)
  }

  useEffect(() => {
    if(selectedEds&&numeroGuiaEnviado){
      console.log(selectedEds)
      console.log(numeroGuia)
      openInfoTable()
    }
  }, [selectedEds, numeroGuiaEnviado]);



  const reset = () => {
    setInstalalaciones(instalaciones, setAllEds);
    // setNumeroGuia()
    setDisplayInfo(false)
    setDisplayFechaTable(false)
    setDisplayGuiaTable(false)
    console.log("cambio");
  };

  const getGuias = () => {
    // setData(info)
  }

  useEffect(() => {
    if(selectedEds, fechaInicio, fechaFin){
      // setDisplaySecondRow(true)
      // setDisplayFechaTable(true)
      getGuiasFecha()
    }
  }, [selectedEds, fechaInicio, fechaFin]);

  const getGuiasFecha = async () => {

    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/consultar-guias?IDEDS=" + parseInt(selectedEds.value)
    + "&FECHAINICIAL=" + parseDateAmerican(fechaInicio)
    + "&FECHAFINAL=" + parseDateAmerican(fechaFin)

    let response = await callServiceHandler(url, "GET");

    console.log("cliente", response.data)

    setGuias(response.data.guias)
    // setFilteredData(response.data.guias)
  }

  const getGuia = async () => {

    const url = 
    process.env.REACT_APP_TCT_TAE_API +
    "/tct-tae/consultar-guias?IDEDS=" + parseInt(selectedEds.value)
    + "&NUMEROGUIA=" + numeroGuia

    let response = await callServiceHandler(url, "GET");

    console.log("cliente", response.data)

    setGuia(response.data.guias)
  }

  return (
    <div className="consultar-guias-manuales-container">
      <div className="view-title">TCT TAE</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">{displayInfo?"Confirmar Guías Manuales" : "Consulta Guías Manuales"}</div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandler={callServiceHandler}
        cabecera={cabecera}
        actualizable={true}
      />
      {
        !displayInfo &&
          <div>
            <TabSelector
              tab1="Buscar por N° de Guía"
              tab2="Buscar por Fecha"
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              reset={reset}
            />
          </div>
      }
      {
        selectedTab === 1 && !displayInfo &&
        <ConsultarGuiasManualesPorNumeroGuia 
          instalaciones={instalaciones} 
          setDisplayInfo={setDisplayInfo} 
          allEds={allEds} 
          setAllEds={setAllEds} 
          selectedEds={selectedEds} 
          setSelectedEds={setSelectedEds} 
          numeroGuia={numeroGuia} 
          setNumeroGuia={setNumeroGuia} 
          setFechaAutorizacion={setFechaAutorizacion}
          setHoraAutorizacion={setHoraAutorizacion}
          displayInfo={displayInfo} 
          displayGuiaTable={displayGuiaTable} 
          setDisplayGuiaTable={setDisplayGuiaTable} 
          getGuias={getGuias}
          data={data} 
          callServiceHandler={callServiceHandler}
          setRutCliente={setRutCliente}
          setNumeroTarjeta={setNumeroTarjeta}
          setCodigoAutorizacion={setCodigoAutorizacion}
          setComuna={setComuna}
          setDireccion={setDireccion}
          setGiroComercial={setGiroComercial}
          setProducto={setProducto}
          setMonto={setMonto}
          setNombreCliente={setNombreCliente}
          setPrecio={setPrecio}
          setUnidad={setUnidad}
          setFlete={setFlete}
          numeroGuiaEnviado={numeroGuiaEnviado}
          setNumeroGuiaEnviado={setNumeroGuiaEnviado}
          guia={guia}
          codigoCliente={codigoCliente}
          setCodigoCliente={setCodigoCliente}
          numeroTransaccion={numeroTransaccion}
          setNumeroTransaccion={setNumeroTransaccion}
          codigoProducto={codigoProducto}
          setCodigoProducto={setCodigoProducto}
          codigoSubProducto={codigoSubProducto}
          setCodigoSubProducto={setCodigoSubProducto}
          setGuiaEstado={setGuiaEstado}
          setAccion={setAccion}
        />
      }
      {
        selectedTab === 2  && !displayInfo &&
        <ConsultarGuiasManualesPorFecha 
          instalaciones={instalaciones} 
          setDisplayInfo={setDisplayInfo} 
          allEds={allEds} 
          setAllEds={setAllEds} 
          selectedEds={selectedEds} 
          setSelectedEds={setSelectedEds} 
          numeroGuia={numeroGuia} 
          setFechaAutorizacion={setFechaAutorizacion}
          setHoraAutorizacion={setHoraAutorizacion}
          setNumeroGuia={setNumeroGuia} 
          setCodigoAutorizacion={setCodigoAutorizacion}
          displayInfo={displayInfo} 
          displayFechaTable={displayFechaTable} 
          setDisplayFechaTable={setDisplayFechaTable} 
          getGuias={getGuias} 
          data={data} 
          callServiceHandler={callServiceHandler}
          setRutCliente={setRutCliente}
          setNumeroTarjeta={setNumeroTarjeta}
          setComuna={setComuna}
          setDireccion={setDireccion}
          setGiroComercial={setGiroComercial}
          setProducto={setProducto}
          setMonto={setMonto}
          setNombreCliente={setNombreCliente}
          setPrecio={setPrecio}
          setUnidad={setUnidad}
          setFlete={setFlete}
          fechaInicio={fechaInicio}
          setFechaInicio={setFechaInicio}
          fechaFin={fechaFin}
          setFechaFin={setFechaFin}
          guias={guias}
          codigoCliente={codigoCliente}
          setCodigoCliente={setCodigoCliente}
          numeroTransaccion={numeroTransaccion}
          setNumeroTransaccion={setNumeroTransaccion}
          codigoProducto={codigoProducto}
          setCodigoProducto={setCodigoProducto}
          codigoSubProducto={codigoSubProducto}
          setCodigoSubProducto={setCodigoSubProducto}
          setGuiaEstado={setGuiaEstado}
          setAccion={setAccion}
        />
      }
      {
        displayInfo &&
        <ConsultarGuiasManualesInfo 
          selectedTab={selectedTab} 
          setDisplayInfo={setDisplayInfo}
          callServiceHandler={callServiceHandler}
          numeroGuia={numeroGuia}
          fechaAutorizacion={fechaAutorizacion}
          horaAutorizacion={horaAutorizacion}
          rutCliente={rutCliente}
          numeroTarjeta={numeroTarjeta}
          codigoAutorizacion={codigoAutorizacion}
          comuna={comuna}
          direccion={direccion}
          giroComercial={giroComercial}
          producto={producto}
          monto={monto}
          nombreCliente={nombreCliente}
          precio={precio}
          unidad={unidad}
          flete={flete}
          codigoCliente={codigoCliente}
          numeroTransaccion={numeroTransaccion}
          codigoProducto={codigoProducto}
          codigoSubProducto={codigoSubProducto}
          nombrePortal={nombrePortal}
          rutPortal={rutPortal}
          getGuiasFecha={getGuiasFecha}
          getGuia={getGuia}
          setNumeroGuia={setNumeroGuia}
          setDisplayGuiaTable={setDisplayGuiaTable}
          guiaEstado={guiaEstado}
          accion={accion}
        />
      }
    </div>
  );
};

export default ConsultarGuiasManuales;
