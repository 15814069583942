export function displayLoader() {
  window.parent.postMessage({ showLoader: true }, "*");
}

export function hideLoader() {
  window.parent.postMessage({ hideLoader: true }, "*");
}

export function showGreyBackground() {
  window.parent.postMessage({ showGreyBackground: true }, "*");
}

export function hideGreyBackground() {
  window.parent.postMessage({ hideGreyBackground: true }, "*");
}

export function showModalGreyBackground() {
  window.parent.postMessage({ showModalGreyBackground: true }, "*");
}

export function hideModalGreyBackground() {
  window.parent.postMessage({ hideModalGreyBackground: true }, "*");
}

export function parentRedirect(url) {
  window.parent.postMessage({ redirect: url }, "*");
}

export function scrollTo(x, y) {
  window.parent.postMessage({ scrollTo: { x: x, y: y } }, "*");
}

export function parentGoBack() {
  window.parent.postMessage({ goBack: true }, "*");
}

export function getPageYOffset(){
  window.parent.postMessage({ getPageYOffset: true }, "*");
}

export function disableScrolling(){
  window.parent.postMessage({ disableScrolling: true }, "*");
}

export function enableScrolling(){
  window.parent.postMessage({ enableScrolling: true }, "*");
}

export function openWindowWithPost(url, xml,rutConcesionario,pagador,GRUPO_PPTA,bank) {
  var form = document.createElement("form");
  form.target = "_blank";
  form.method = "POST";
  form.action = url;
  form.style.display = "none";

  var inputEmpresa = document.createElement("input");
  inputEmpresa.type = "hidden";
  inputEmpresa.name="rutEmpresa";
  inputEmpresa.value=rutConcesionario;
  form.appendChild(inputEmpresa);

  var inputPagador = document.createElement("input");
  inputPagador.type = "hidden";
  inputPagador.name="usuarioId";
  inputPagador.value=pagador;
  form.appendChild(inputPagador);

  var inputPropuesta = document.createElement("input");
  inputPropuesta.type = "hidden";
  inputPropuesta.name="idPropuestaAWS";
  inputPropuesta.value=GRUPO_PPTA;
  form.appendChild(inputPropuesta);

  var inputBanco = document.createElement("input");
  inputBanco.type = "hidden";
  inputBanco.name="idBanco";
  inputBanco.value=bank;
  form.appendChild(inputBanco);

  var inputXml = document.createElement("input");
  inputXml.type = "hidden";
  inputXml.name="dataPropuesta";
  inputXml.value=xml;
  form.appendChild(inputXml);

  var inputOrigen = document.createElement("input");
  inputOrigen.type = "hidden";
  inputOrigen.name="origen";
  inputOrigen.value=1;
  form.appendChild(inputOrigen);

  console.log(form);
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}
