import React, { useState,useEffect } from "react";

const TabSelector = ({tab1,tab2,tab3,selectedTab,setSelectedTab, reset}) => {
  function changeSelectedTab(tabNum) {
    setSelectedTab(tabNum);
    reset();
  }

  return (
    <div>
      <div className="tab-selector">
        <div className="sections">
          <div className={`section ${selectedTab===1?"selected":""}`} onClick={()=>changeSelectedTab(1)} >{tab1}</div>
          <div className={`section ${selectedTab===2?"selected":""}`} onClick={()=>changeSelectedTab(2)}>{tab2}</div>
          {tab3 &&  <div className={`section ${selectedTab===3?"selected":""}`} onClick={()=>changeSelectedTab(3)}>{tab3}</div>}
        </div>
        <div className="lines">
          <div className={`line ${selectedTab===1?"selected":""}`}></div>
          <div className={`line ${selectedTab===2?"selected":""}`}></div>
          {tab3 && <div className={`line ${selectedTab===3?"selected":""}`}></div>}
        </div>
      </div>
    </div>
  );
};

export default TabSelector;
