import React, { useContext, useEffect, useRef, useState } from "react";
import { ViewPortContext } from "../App";
import { MobileFiltersChoice } from "./MobileFilters/MobileFiltersChoice";
import {
  MobileFiltersOpenedChoice,
  MobileFiltersOpenedChoiceOption,
} from "./MobileFilters/MobileFiltersOpenedChoice";
import { spaceAboveIframe } from "./utils/utils";
import { scrollTo } from "./utils/windows";

export const MobileSelect = ({
  label,
  selectedItem,
  options,
  selectItem,
  onOpen,
  scrollToTop
}) => {
  const [selectOpened, setSelectOpened] = useState();
  const { viewPortHeight, iframeHeight } = useContext(ViewPortContext);
  const lastOpenedPosition = useRef(0);

  useEffect(() => {
    if (onOpen) {
      onOpen(selectOpened);
    }
    if (selectOpened) {
      if (scrollToTop) {
        scrollTo(0, 0);
      }
    } else {
      if (scrollToTop) {
        if (lastOpenedPosition.current > 0) {
          setTimeout(() => {
            scrollTo(0, lastOpenedPosition.current - spaceAboveIframe);
            lastOpenedPosition.current = 0;
          }, 0);
        } else {
          lastOpenedPosition.current = 0;
        }
      }
    }
  }, [onOpen, scrollToTop, selectOpened]);

  return (
    <div className="mobile-select">
      <MobileFiltersChoice
        itemName={label}
        onClick={(e) => {
          lastOpenedPosition.current = e.clientY;
          setSelectOpened(true);
        }}
        value={selectedItem?.label}
      />
      {selectOpened && (
        <div
          className="mobile-select__opened-view"
          style={{
            height: Math.min(iframeHeight, viewPortHeight - spaceAboveIframe),
          }}
        >
          <MobileFiltersOpenedChoice
            itemTitle={label}
            closeView={() => setSelectOpened(false)}
          >
            {options.map((item) => (
              <MobileFiltersOpenedChoiceOption
                name={item.label}
                key={item.value}
                onClick={() => {
                  selectItem(item);
                  setSelectOpened(false);
                }}
              />
            ))}
          </MobileFiltersOpenedChoice>
        </div>
      )}
    </div>
  );
};
