import React, { useState, useEffect } from "react";
import { SingleOption, customStyles } from "../utils/utils.js";
import Select from "react-select";
import remove from "../../assets/img/remove.svg";
import errorIcon from "../../assets/img/atencion_rojo.svg";
import closeIcon from "../../assets/img/cross-icon.svg";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import Timepicker from "../Timepicker";
import { allMateriales } from "../utils/materiales";

const commonSelectProps = {
  className: "table-select",
  closeMenuOnSelect: true,
  hideSelectedOptions: false,
  options: [],
  components: { SingleOption, IndicatorSeparator: () => null },
  isClearable: false,
  autosize: false,
  placeholder: "Seleccionar",
  styles: customStyles,
};

export const AsignacionEntradas = ({ entradas, setEntradas, setStep, tanques, ultimaFluctuacion, fechaMedicion, 
    horaMedicion, openPedidos, setOpenPedidos, openCloseModals, pedidos, setPedidos, ultimaFluctuacionDate, viewPortHeight }) => {
  const [filteredTanques, setFilteredTanques] = useState([])
  const [materiales, setMateriales] = useState([])
  const [errores, setErrores] = useState([])
  const [checkedPedidos, setCheckedPedidos] = useState({})
  const [customModalStylesPedidos, setCustomModalStylesPedidos] = useState({})

  useEffect(() => {
    setCustomModalStylesPedidos({
      content: {
        top: "20px",
        width: "850px",
        padding: "0",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translateX(-50%)",
        maxHeight: (viewPortHeight - 150) + 'px',
        borderRadius: 9
      }
    })
  }, [])

  useEffect(() => {
    const tmpMateriales = []
    tanques.forEach(function(tanque){
      if(tanque.tipo === 'camion')
        return

      const found = tmpMateriales.find(m => m.value === tanque.idMaterial)
      if(!found)
        tmpMateriales.push({ label: tanque.material, value: tanque.idMaterial})
    })

    setMateriales(tmpMateriales)

  }, [tanques])

  
  function editPedido(val, idPedido, descarga) {
    const tmp = pedidos.map((pedido) => {
      if (pedido.ID_PEDIDO === idPedido) {
        let tmpPedido = {...pedido}
        tmpPedido.DESCARGAS = tmpPedido.DESCARGAS.map((d) => {
          if (d.compartimiento === descarga.compartimiento) return {...d, tanque: val}
          return d
        })
        return tmpPedido
      }
      return pedido
    })
    setPedidos(tmp)
  }

  function editEntrada(key, parameter, value){
    if(parameter === 'volumen' && isNaN(value))
      return

    const temp = [...entradas]
    temp[key][parameter] = value

    if(parameter === 'material'){
      temp[key].tanque = ''
      const newTanques = tanques.filter(t => (t.idMaterial === value.value && t.tipo == 'tanque'))
      const tempTanques = [...filteredTanques]
      tempTanques[key] = newTanques
      if(newTanques.length === 1)
        temp[key].tanque = newTanques[0]

      setFilteredTanques(tempTanques)
    }
    setEntradas(temp)
  }

  function addEntrada(){
    const newEntrada = {
      identificador: "",
      fecha: '',
      hora: '',
      comp: '',
      volumen: '',
      material: '',
      tanque: ''
    }
    setEntradas([...entradas, newEntrada])
  }

  function deleteEntrada(key){
    const temp = entradas.filter((item, index) => index !== key)
    setEntradas(temp)
  }

  function handleCheckedPedidos(idPedido) {
    const temp = {...checkedPedidos}
    //const pedido = pedidos.find(pedido => pedido.ID_PEDIDO === idPedido)
    //temp[idPedido] = !pedido.checked

    if (idPedido in checkedPedidos) {
      temp[idPedido] = !temp[idPedido]
    }
    else {
      const pedido = pedidos.find(pedido => pedido.ID_PEDIDO === idPedido)
      temp[idPedido] = !pedido.checked
    }

    console.log(temp)
    setCheckedPedidos(temp)
  }

  function confirmCheckedPedidos() {
    console.log("confirm pedidos")
    console.log(pedidos)
    console.log(checkedPedidos)

    let tmp = pedidos.map((pedido) => {
      let newPedido = {...pedido}
      if (typeof checkedPedidos[pedido.ID_PEDIDO] !== 'undefined') {
        newPedido.checked = checkedPedidos[pedido.ID_PEDIDO]
      }
      //if(checkedPedidos[pedido.ID_PEDIDO])
      //newPedido.checked = (checkedPedidos[pedido.ID_PEDIDO] || pedido.checked) ? true : false
      return newPedido
    })
    
    setPedidos(tmp)

    openCloseModals(false, setOpenPedidos)
  }

  function checkStep(){
    const tmpErrores = []
    let error1 = false, error2 = false, error3 = false, error4
    let start_date = new Date(0)
    if (ultimaFluctuacion?.FECHA_HASTA) {
      const startDateArr = ultimaFluctuacion.FECHA_HASTA.split('/')
      const startHourArr = ultimaFluctuacion.HORA_HASTA.split(':')
      start_date = new Date(startDateArr[2], parseInt(startDateArr[1]) - 1, startDateArr[0], startHourArr[0], startHourArr[1])
    }
    const endHourArr = horaMedicion.split(':')
    const end_date = new Date(fechaMedicion.getFullYear(), fechaMedicion.getMonth(), fechaMedicion.getDate(), endHourArr[0], endHourArr[1])

    pedidos.forEach((pedido) => {
      if (pedido.checked) {
        pedido.DESCARGAS.forEach((descarga) => {
          if (!descarga.tanque) error1 = true
        })
      }
    })
    entradas.forEach(function(entrada){
      const foundTanque = tanques.find(t => t.value === entrada.tanque.value)
      if(!entrada.fecha || !entrada.hora || !entrada.material || !entrada.tanque || !entrada.volumen){
        error4 = true
        return
      }

      if(!foundTanque)
        error1 = true
      else{
        if(foundTanque.capacidad < entrada.volumen)
          error2 = true
      }

      const entradaHourArr = entrada.hora.split(':')
      const entradaDate = new Date(entrada.fecha.getFullYear(), entrada.fecha.getMonth(), entrada.fecha.getDate(), entradaHourArr[0], entradaHourArr[1])

      if(ultimaFluctuacion){
        if(entradaDate < start_date || entradaDate > end_date)
        error3 = true
      }else{
        if(entradaDate > end_date)
        error3 = true
      }
      
    })

    if(error4)
      tmpErrores.push("Ingresa todos los datos.")
    if(error1)
      tmpErrores.push("Selecciona los materiales y tanques de todas las entradas.")
    if(error2)
      tmpErrores.push("No es posible ingresar una entrada con un volumen mayor a la capacidad del tanque.")
    if(error3)
      tmpErrores.push("Existen una o mas fechas ingresadas que están fuera del rango.")

    setErrores(tmpErrores)

    if(tmpErrores.length === 0)
      setStep(3)
  }

  return (
    <div className="fluctuaciones-ingreso-mediciones-big-section">
      <p className="flutuaciones-section-title">ASIGNACIÓN DE ENTRADAS</p>
      <table className="flutuaciones-table tanks-table allocation-table">
        <thead>
          <tr>
            <th>Identificador Entrada</th>
            <th>Fecha Entrada</th>
            <th>Hora Entrada</th>
            <th>Comp.</th>
            <th>Volumen (Lts)</th>
            <th>Material</th>
            <th>Tanque</th>
          </tr>
        </thead>
        <tbody>
          {/* Horizontal  separator between head and body*/}
            <tr>
              <td className="horizontal-separator" colSpan={7} />
            </tr>
          { pedidos.filter((pedido) => pedido.checked).map((pedido, key) =>
            <>
              <tr className="row-with-border remove-row-span-border">
                <td className="cell-border-top-left cell-border-bottom-left" rowSpan={pedido.DESCARGAS.length}>
                  {pedido.ID_PEDIDO}
                </td>
                <td rowSpan={pedido.DESCARGAS.length}>
                  {`${pedido.FECHA.split("-")[2]}/${pedido.FECHA.split("-")[1]}/${pedido.FECHA.split("-")[0]}`}
                </td>
                <td rowSpan={pedido.DESCARGAS.length}>{pedido.HORA.substring(0,5)}</td>
                <td>{pedido.DESCARGAS[0].compartimiento}</td>
                <td>{pedido.DESCARGAS[0].volumen}</td>
                <td className="container-cell">
                  <div className="container-cell-content">
                    <div className={`small-camion-compartment ${allMateriales[pedido.DESCARGAS[0].material].class} cont2`}>
                        <p className="small-camion-compartment__fuel-type-number">
                          {allMateriales[pedido.DESCARGAS[0].material].title.substring(0, 2)}
                        </p>
                      </div>
                    { materiales.find(m => m.value === pedido.DESCARGAS[0].material) ?
                    <> <span>{materiales.find(m => m.value === pedido.DESCARGAS[0].material).label}</span> </>
                    : <> <span> {pedido.DESCARGAS[0].material} </span> </>
                    }
                  </div>
                </td>
                <td className="cell-border-top-right">
                  <Select
                    className="eds-select"
                    value={pedido.DESCARGAS[0].tanque}
                    onChange={(e) => editPedido(e, pedido.ID_PEDIDO, pedido.DESCARGAS[0])}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={pedido.DESCARGAS[0].tanquesOptions}
                    components={{ SingleOption, IndicatorSeparator: () => null }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStyles}
                  />
                </td>
                
              </tr>
              {pedido.DESCARGAS.slice(1).map((descarga) =>
                <> 
                  <tr className="row-with-border remove-row-span-border">
                    <td>{descarga.compartimiento}</td>
                    <td>{descarga.volumen}</td>
                    <td className="container-cell">
                      <div className="container-cell-content">
                        <div className={`small-camion-compartment ${allMateriales[descarga.material].class} cont2`}>
                            <p className="small-camion-compartment__fuel-type-number">
                              {allMateriales[descarga.material].title.substring(0, 2)}
                            </p>
                          </div>
                        { materiales.find(m => m.value === descarga.material) ?
                        <> <span>{materiales.find(m => m.value === descarga.material).label}</span> </>
                        : <> <span> {descarga.material} </span> </>
                        }
                      </div>
                    </td>
                    <td>
                      <Select
                        className="eds-select"
                        value={descarga.tanque}
                        onChange={(e) => editPedido(e, pedido.ID_PEDIDO, descarga)}
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        options={descarga.tanquesOptions}
                        components={{ SingleOption, IndicatorSeparator: () => null }}
                        isClearable={false}
                        autosize={false}
                        placeholder="Seleccionar"
                        styles={customStyles}
                      />
                    </td>
                  </tr>
                </>
              )}
            </>
          )}

          { entradas.map((entrada, key) =>
            <>
              <tr>
                <td className="horizontal-separator" colSpan={7} />
              </tr>

              <tr className="tight-row">
                <td className="cell-border-top-left cell-border-bottom-left">
                  <input 
                    className="table-input" 
                    type="text" 
                    value={entrada.identificador} 
                    onChange={(e) => editEntrada(key, 'identificador', e.target.value)}
                  />
                </td>
                <td>
                  <DatePicker 
                    locale="es"
                    selected={entrada.fecha} 
                    onChange={(e) => editEntrada(key, 'fecha', e)}
                    shouldCloseOnSelect = {true}
                    dateFormat = "dd/MM/yyyy"
                    placeholderText = "Seleccionar"
                    maxDate={fechaMedicion}
                    minDate={ultimaFluctuacionDate?ultimaFluctuacionDate:null}
                  />
                </td>
                <td>
                  <Timepicker 
                    value={entrada.hora}
                    onSelect={(e) => editEntrada(key, 'hora', e)}
                  />
                </td>
                <td>
                  <input className="disabled-input" type="text" disabled />
                </td>
                <td>
                  <input 
                    className="table-input" 
                    type="text" 
                    value={entrada.volumen} 
                    onChange={(e) => editEntrada(key, 'volumen', e.target.value)}
                  />
                </td>
                <td>
                  <Select
                    className="eds-select"
                    value={entrada.material}
                    onChange={(e) => editEntrada(key, 'material', e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={materiales}
                    components={{ SingleOption, IndicatorSeparator: () => null }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStyles}
                  />
                </td>
                <td className="cell-border-top-right">
                  <Select
                    className="eds-select"
                    value={entrada.tanque}
                    onChange={(e) => editEntrada(key, 'tanque', e)}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    options={filteredTanques[key]}
                    components={{ SingleOption, IndicatorSeparator: () => null }}
                    isClearable={false}
                    autosize={false}
                    placeholder="Seleccionar"
                    styles={customStyles}
                  />
                  <button className="remove-row-btn">
                    <img src={remove} alt="remove" onClick={() => deleteEntrada(key)} />
                  </button>
                </td>
              </tr>
            </>
          )}

          <tr>
            <td className="horizontal-separator" colSpan={7} />
          </tr>

          <tr>
            <td
              className="cell-border-top-left cell-border-top-right"
              colSpan={7}
            >
              <button className="main-button add-new-row" onClick={addEntrada}>Agregar</button>
            </td>
          </tr>
        </tbody>
      </table>
      {
        errores.map((error) =>
        <div className="table-bottom-error">
          <img src={errorIcon} alt="error" />
          <p>{error}</p>
        </div>
      )}
      <div className="fluctuaciones-ingreso-mediciones-actions">
        <button className="main-button hallow" onClick={() => setStep(1)}>
          Volver
        </button>
        <button className="main-button" onClick={checkStep}>
          Confirmar
        </button>
      </div>

      <Modal isOpen={openPedidos} onRequestClose={() => openCloseModals(false, setOpenPedidos)} 
        style={customModalStylesPedidos}>
        <div className="flutuaciones-modal fim-modal">
          <img
            className="close-modal"
            onClick={() => openCloseModals(false, setOpenPedidos)}
            src={closeIcon}
            alt="Close"
          />
          <div className="modal-info">
            <p className="flutuaciones-modal__title">
              <strong>Pedidos registrados</strong>
            </p>
          </div>
          <table className="flutuaciones-table fim-table">
            <thead>
              <tr>
                <th>Identificador Pedido</th>
                <th>Fecha Entrada</th>
                <th>Hora Entrada</th>
                <th>Volumen Total</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {/* Horizontal  separator between head and body*/}
              <tr>
                <td className="horizontal-separator" colSpan={5} />
              </tr>

              {pedidos.map((pedido, key) => (
                <>
                  <tr className="row-with-border">
                    <td className={`${key===0?"cell-border-top-left":key===pedido.length-1?"cell-border-bottom-left":""}`}>
                      {pedido.ID_PEDIDO}
                    </td>
                    <td>{pedido.FECHA}</td>
                    <td>{pedido.HORA}</td>
                    <td>
                      <strong>{pedido.VOLUMEN_TOTAL}</strong>
                    </td>
                    <td className={`${key===0?"cell-border-top-right":key===pedido.length-1?"cell-border-bottom-left":""}`}>
                      <input type="checkbox" defaultChecked={pedido.checked}
                        onClick={() => handleCheckedPedidos(pedido.ID_PEDIDO)}/>
                    </td>
                  </tr>
                </>
              ))}

            </tbody>
          </table>
          <div className="fluctuaciones-ingreso-mediciones-actions">
            <button className="main-button hallow" onClick={() => openCloseModals(false, setOpenPedidos)}>
              Cancelar
            </button>
            <button className="main-button" onClick={() => confirmCheckedPedidos()}>
              Confirmar
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
