import React, { useState } from "react";
import { InputWithClearBtn } from "../InputWithClearBtn";
import { getTanquesSifoneadosWithLocalNumber } from "../utils/tanquesSifoneados";
import { get3DecimalsFloatWithDelimiter } from '../utils/utils.js'
import errorIcon from "../../assets/img/atencion_rojo.svg";

export const ConfirmacionSalidas = ({ setStep, configuracion, salidas, setSalidas }) => {
  const [errores, setErrores] = useState([])

  function salidasChange(value, key){
    value = get3DecimalsFloatWithDelimiter(value)

    if(value !== false)
      setSalidas({...salidas, [key]: value})
  }

  function checkStep(){
    const tmpErrores = []
    let error1 = false, error2 = false
    configuracion.TANQUES.forEach((tanque) => {
      if(salidas[tanque.ID]){
        const volumen = parseFloat(salidas[tanque.ID]) || 0
        const capacidad = tanque.CAPACIDAD
        //if (volumen > 2 * capacidad) error2 = true (esto si podria ocurrir si se recargan los tanques o camiones)
      }
      else {
        error1 = true
      }
    })
    
    if(error1)
      tmpErrores.push("Ingresa todos los datos.")
    if(error2)
      tmpErrores.push("No es posible ingresar una salida con un volumen mayor a la capacidad del tanque.")

    setErrores(tmpErrores)
    if(tmpErrores.length === 0)
      setStep(4)
  }

  return (
    <div className="fluctuaciones-ingreso-mediciones-small-section">
      <p className="flutuaciones-section-title">CONFIRMACIÓN DE SALIDAS</p>
      <table className="flutuaciones-table tanks-table">
        <thead>
          <tr>
            <th>Tanque</th>
            <th className="no-padding-left-th">Material</th>
            <th className="measurement-column">Salidas por tanque</th>
          </tr>
        </thead>
        <tbody>
          { configuracion && configuracion.TANQUES.map((tanque, key) =>
            getTanquesSifoneadosWithLocalNumber(tanque, salidas, key, salidasChange, configuracion.TANQUES)
          )}
        </tbody>
      </table>
      {
        errores.map((error) =>
        <div className="table-bottom-error">
          <img src={errorIcon} alt="error" />
          <p>{error}</p>
        </div>
      )}
      <div className="fluctuaciones-ingreso-mediciones-actions">
        <button className="main-button hallow" onClick={() => setStep(2)}>
          Volver
        </button>
        <button className="main-button" onClick={checkStep}>
          Confirmar
        </button>
      </div>
    </div>
  );
};
