import React, { useState, useEffect } from "react";
import HeaderData from "../HeaderData";
import { FirstStepContent } from "./FirstStepContent";
import { BankChooser } from "../StepperComponents/BankChooser";
import { Stepper } from "../StepperComponents/Stepper";
import { ThirdStepContent } from "./ThirdStepContent";
import {
  SingleOption,
  customStyles,
} from "../utils/utils.js";
import {
  showGreyBackground,
  hideGreyBackground,
  displayLoader,
  hideLoader,
  openWindowWithPost
} from "../utils/windows";
import { getUpdateDate } from "../utils/monthNames";
import CustomModal from "../CustomModal";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import Select from "react-select";

export const AbonarEnBanco = ({ rutConcesionario, token, cabecera, pageYOffset, callServiceHandler }) => {
  const [step, setStep] = useState(1);
  const [lineasAbono, setLineasAbono] = useState([]);
  const [error, setError] = useState(false);
  const [bank, setBank] = useState();
  const [convenio, setBankConvenio] = useState();
  const [bankName, setBankName] = useState("");
  const [abonos, setAbonos] = useState();
  const [updateDate, setUpdateDate] = useState("");
  const [displayModal, setDisplayModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('')
  const [modalText, setModalText] = useState('')
  const cookies = new Cookies();

  const [pagador, setPagador] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const history = useHistory();
  const [isArcoprime, setIsArcoprime] = useState(false);
  const [selectedEdsArcoprime, setSelectedEdsArcoprime] = useState();
  const [allEdsArcoprime, setAllEdsArcoprime] = useState([]);
  const [banksOptions, setBankOptions] = useState([])
  const [bankOptionsMessage, setBankOptionsMessage] = useState([{
    MENSAJE: "",
    ACTIVO: "O",
  }]);
  const [displayWarningModal, setDisplayWarningModal] = useState(false);
  const warningModalTitle = "Aviso";

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (token && rutConcesionario) {
        getLineasAbono();
        getUpdateDate(setUpdateDate);
        getBancosAndMessage();
        if (rutConcesionario === "77215640-5") {
          setIsArcoprime(true);
          getFiliales();
        }
      }
    } else {
      if (token) {
        getLineasAbono();
        getUpdateDate(setUpdateDate);
        getBancosAndMessage();

        if (rutConcesionario === "77215640-5" /* || true */) {
          setIsArcoprime(true);
          getFiliales();
        }
      }
    }
  }, [token, rutConcesionario]);

  useEffect(() => {
    if(cabecera!==""){
      setClientName(cabecera.DATOS_CLIENTE.NOMBRE);
      setClientAddress(cabecera.DATOS_CLIENTE.DIRECCION);
      setPagador(cabecera.DATOS_CLIENTE.PAGADOR);
    }
  }, [cabecera]);

  async function getLineasAbono() {
    displayLoader();
    const url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/listar-linea-abono?rut_concesionario=" +
      rutConcesionario;

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      setLineasAbono(result.data.LINEAS);
      if (!cookies.get("popup")) {
        setModalTitle('Habilitar Pop Ups')
        setModalText('Para realizar pagos, habilita los pop ups en tu navegador. Para habilitar pop ups, sigue las siguientes instrucciones:')
        setDisplayModal(true);

        showGreyBackground();
        cookies.set("popup", "yamostrado", {
          path: "/",
          expires: new Date(2040, 11, 17),
        });
      }
    }
  }

  async function confirmAbono(bankName) {
    let shortBank = "";
    console.log("name",bankName)

    if (bankName === "BANCO DE CREDITO E INVERSIONES") {
      shortBank = "BCI";
    }
    if (bankName === "BANCO SANTANDER") {
      shortBank = "SANTA";
    }
    if (bankName === "BANCO DE CHILE") {
      shortBank = "CHILE";
    }
    if (bankName === "BANCO DEL ESTADO DE CHILE") {
      shortBank = "ESTAD";
    }
    if (bankName === "SCOTIABANK") {
      shortBank = "SCOTI";
    }

    let totald = 0;
      abonos.map((abono) => {
        totald += parseInt(abono.monto);
      });

    const debugData = {
        abonos: abonos,
        lineasAbono: lineasAbono,
        totalSaldo: totald,
        rutConcesionario: rutConcesionario,
        banco: bankName,
        bancoId: bank,
        clientName: clientName,
        clientAddress: clientAddress,
        tipo: "abono",
    }

    let data = {};

    if (isArcoprime) {
      data = {
        id_eds: selectedEdsArcoprime.value,
        abonos: abonos,
        via_pago: shortBank,
        calculo:debugData
      };
    } else {
      data = {
        abonos: abonos,
        rut_concesionario: rutConcesionario,
        via_pago: shortBank,
        calculo:debugData
      };
    }

    const url =
      process.env.REACT_APP_BASE_API + "/cuenta-corriente/crear-abono";
    console.log("DATAAAA",data)
    let result = await callServiceHandler(url, "POST", JSON.stringify(data))
    if(result !== "error"){
      if (result.data.GRUPOPPTA !== "") {
        let total = 0;

        abonos.map((abono) => {
          total += parseInt(abono.monto);
        });

        let url2 =
          process.env.REACT_APP_BASE_API + "/cuenta-corriente/obtener-url-banco?"
          + "rut_concesionario=" + rutConcesionario
          + "&monto=" + total
          + "&banco=" + bank
          + "&grupo_ppta=" + result.data.GRUPOPPTA

        let result2 = await callServiceHandler(url2, "GET")
        if(result2 !== "error"){
          if (result2.data.url_method === "GET") {
            window.open(result2.data.url + "&origen=1");
          } else {
            const data = {};
            data['xml'] = result2.data.parameter;
            openWindowWithPost(result2.data.url, result2.data.xml, rutConcesionario, pagador, result.data.GRUPOPPTA, bank);
          }
        }

        saveData(result.data.GRUPOPPTA, total);
        
        setModalTitle('Redireccionando')
        setModalText('Ha sido redirigido al medio de pago seleccionado. Si ya termino de pagar, por favor revise en Historial de Pagos.')
        setDisplayModal(true);
        
        hideLoader();
        showGreyBackground();
        setError(false);
      }
    }
    else{
      setError(true);
      setStep(3);
    }
  }

  function end() {
    setStep(1);
    setAbonos();
  }

  function closeModal() {
    setDisplayModal(false);
    hideLoader();
    hideGreyBackground();

    if(modalTitle === 'Redireccionando'){
      history.go(0);
    }
  }

  function closeWarningModal() {
    setDisplayWarningModal(false);
    hideLoader();
    hideGreyBackground();

    if(modalTitle === 'Redireccionando'){
      history.go(0);
    }
  }

  async function changeEdsArcoprime(e) {
    setSelectedEdsArcoprime(e);
  }

  const saveData = (propuesta, totalS) => {
    const data = {
      propuesta: propuesta,
      abonos: abonos,
      lineasAbono: lineasAbono,
      totalSaldo: totalS,
      rutConcesionario: rutConcesionario,
      banco: bankName,
      bancoId: bank,
      clientName: clientName,
      clientAddress: clientAddress,
      tipo: "abono",
    };

    window.localStorage.setItem(propuesta, JSON.stringify(data));
  };

  const goBack = () => {
    setStep(1);
  };

  async function getFiliales() {
    displayLoader();
    const url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/listar-filiales?rut_concesionario=77215640-5";

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      const data = result.data;
      const eeddss = [];

      data.FILIALES.forEach(function (eds) {
        let ide = eds.ID_EDS;
        while (ide.charAt(0) === "0") {
          ide = ide.substring(1);
        }
        const newEds = {
          value: eds.ID_EDS,
          label: ide + " - " + eds.DIRECCION,
        };
        eeddss.push(newEds);
      });

      setAllEdsArcoprime(eeddss);
    }
  }

  async function getBancosAndMessage() {
    displayLoader();

    let url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/obtener-bancos-mensaje?rut_concesionario=" +
      rutConcesionario;
    
    let result = await callServiceHandler(url, "GET");
    if(result !== "error"){
      const data = result.data;
      const banks = [];
      const message = [{
        MENSAJE: data.MENSAJES[0]["MENSAJE"],
        ACTIVO: data.MENSAJES[0]["ACTIVO"],
      }];

      data.BANCOS.forEach(function (bnk) {
        const newBank = {
          CONVENIO: bnk.CONVENIO,
          CLAVE_BANCO: bnk.CLAVE_BANCO,
        };
        banks.push(newBank);
      });

      setBankOptions(banks);
      setBankOptionsMessage(message);
      if (message[0].ACTIVO === 'X') {
        setDisplayWarningModal(true);
      }
    }
  }

  return (
    <div className="abonar-en-banco">
      <div className="title-container">
        <div className="title">
          <div className="view-title">Cuenta corriente</div>
          <div className="view-title-separator">|</div>
          <div className="view-subtitle">Realizar abonos</div>
        </div>
        {step === 2 ? (
          <>
            <div className="volver-arrow" onClick={() => goBack()}>
              <img></img>
              <p className="volver-text">Volver</p>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <hr className="title-separator-line" />

      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        cabecera={cabecera}
        callServiceHandler={callServiceHandler}
      />

      <hr />
      {isArcoprime && (
        <div className="filters-container arcoprime">
          <div className="filter">
            <div className="filter-title">ESTACIÓN DE SERVICIO</div>
            <div className="filter-input">
              <Select
                className="eds-select"
                value={selectedEdsArcoprime}
                onChange={(e) => changeEdsArcoprime(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allEdsArcoprime}
                components={{ SingleOption, IndicatorSeparator: () => null }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccionar"
                styles={customStyles}
                isDisabled={step != 1}
              />
            </div>
          </div>
        </div>
      )}

      <div
        className={`abonar-en-banco__content ${isArcoprime ? "arcoprime" : ""}`}
      >
        <Stepper
          step={step}
          error={error}
          totalSteps={3}
          title="Abono en Cuenta"
          step1Title="Confirmar"
          step2Title="Abonar"
          step3Title="Finalizar"
        />

        <CustomModal
          displayModal={displayWarningModal}
          closeModal={closeWarningModal}
          acceptFunc={closeWarningModal}
          modalTitle={warningModalTitle}
          modalText={bankOptionsMessage[0].MENSAJE}
          modalIcon='importante'
        />
        {step === 1 && (
          <FirstStepContent
            setStep={setStep}
            setAbonos={setAbonos}
            rutConcesionario={rutConcesionario}
            lineasAbono={lineasAbono}
            selectedEdsArcoprime={selectedEdsArcoprime}
            isArcoprime={isArcoprime}
            pageYOffset={pageYOffset}
          />
        )}
        {step === 2 && (
          <BankChooser
            setStep={setStep}
            confirmAbono={confirmAbono}
            bank={bank}
            convenio={convenio}
            setBank={setBank}
            setBankName={setBankName}
            bankName={bankName}
            setBankConvenio={setBankConvenio}
            closeStepper={setStep}
            closeWithParameter={true}
            pageYOffset={pageYOffset}
            banksList={banksOptions}
          />
        )}
        {step === 3 && (
          <ThirdStepContent
            error={error}
            end={end}
            abonos={abonos}
            bank={bank}
            lineasAbono={lineasAbono}
          />
        )}

        <CustomModal
          displayModal={displayModal}
          closeModal={closeModal}
          acceptFunc={closeModal}
          modalTitle={modalTitle}
          modalText={modalText}
          modalIcon='success'
        />

      </div>
    </div>
  );
};
