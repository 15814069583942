import React, { useState, useEffect, useRef } from "react";
import CustomModal from '../CustomModal'
import { getLocaleStringNumber, parseDate, getCleanEdsNumber } from "../utils/utils.js";
import { displayLoader, hideLoader, parentRedirect, scrollTo } from "../utils/windows";

const ResumenIngresoPedido = ({ 
    clearForm,
    setDisplayResumenIngreso,
    selectedEds, 
    tipoPedido, 
    planta,
    camion,
    horario,
    contacto,
    observaciones,
    date,
    token,
    idPedido,
    compartimientosPedido,
    codigoTipoPedidoOriginal,
    mixProductos,
    callServiceHandler
  }) => {

  const [ displayModal, setDisplayModal ] = useState(false)
  const [ modalText, setModalText ] = useState('')
  const [ modalTitle, setModalTitle ] = useState('')
  const [ modalIcon, setModalIcon ] = useState("success")
  const isDesktopRef = useRef(window.innerWidth > process.env.REACT_APP_MOBILE_BREAK_POINT);

  function closeModal() {
    setDisplayModal(false)
    if(modalIcon === "success" && !idPedido)
      clearForm()
    else if(modalIcon === "success" && idPedido){
      const url = process.env.REACT_APP_PEDIDOS_ACTIVOS_PATH
      parentRedirect(url)
    }
  }

  function getContacto(){
    const nombre = contacto.NOMBRE? contacto.NOMBRE.toString().toLowerCase(): ''
    const apellido = contacto.APELLIDO? contacto.APELLIDO.toString().toLowerCase(): ''
    return nombre + ' ' + apellido
  }

  function calculateTotal(isOriginal = false){
    let total = 0

    if(isOriginal){
      compartimientosPedido.forEach(function(comp){
        if(comp.ID_MATERIAL !== 'empty'){
          total += comp.CANTIDAD_SOLICITADA
        }
      })
    }

    else{
      mixProductos.forEach(function(comp){
        total += comp.CANTIDAD
      })
    }

    return getLocaleStringNumber(total)
  }

  async function ingresarPedido(){
    displayLoader()

    const productosArr = []

    mixProductos.forEach(function(comp){  
      const newComp = {
        cantidad_total: comp.CANTIDAD,
        id: comp.ID_MATERIAL
      }
      if(comp.POSNR){
        newComp.posnr = comp.POSNR
      }
      productosArr.push(newComp)
    })

    const data = {
      tipo_pedido: tipoPedido.value,
      observaciones: observaciones,
      productos: productosArr,
      id_eds: selectedEds.value,
      id_solicitante: contacto.ID_SOLICITANTE
    }

    if(tipoPedido.value !== 'preprograma'){
      data.horario_entrega = horario.NUMERO
      data.fecha_entrega = parseDate(date)
    }

    let url

    if(idPedido){
      data.id_pedido = idPedido
      data.tipo_pedido = codigoTipoPedidoOriginal
      url = process.env.REACT_APP_PEDIDOS_API + "/pedidos/modificar-pedido?nuevo=1"
    }
    else{
      url = process.env.REACT_APP_PEDIDOS_API + "/pedidos/crear-pedido-web"
    }

    const method = idPedido? "PUT": "POST"

    let result = await callServiceHandler(url, method, JSON.stringify(data))
    if(result !== "error"){
      if(result.code === 200 
        && (result.data.ESTADO === 0 || (idPedido && result.data.ESTADO !== 1)) 
        && !(result.data.E_SUBRC && result.data.E_SUBRC !== 0 && result.data.E_SUBRC !== "" && result.data.E_SUBRC !== "0")
      ){
        setModalTitle(`Pedido ${idPedido? 'modificado': 'ingresado'}`)
        if(idPedido)
          setModalText(`El pedido Nº ${idPedido} ha sido<br />modificado con éxito.`)
        else
          setModalText(`El pedido ha sido ingresado con éxito.<br />El Nº de pedido es ${result.data.NUMERO_DOCUMENTO}`)
        setModalIcon("success")
        setDisplayModal(true)
        if(!isDesktopRef.current)
          scrollTo(0, 420)
      }
      else if(result.data && result.data.E_SUBRC && result.data.E_SUBRC !== 0 && result.data.E_SUBRC !== "" && result.data.E_SUBRC !== "0"){
        setModalTitle("Error")
        setModalText(result.data.MENSAJE)
        setModalIcon("error")
        setDisplayModal(true)
      }
      else{
        setModalTitle("Error")
        setModalText(`No hemos podido ${idPedido? 'modificar': 'crear'} tu pedido. Si el error persiste por favor contáctate con el administrador del sitio.`)
        setModalIcon("error")
        setDisplayModal(true)
      }
    }
  }

  return (
    <div className="resumen-ingreso-pedido">
      <h1>Resumen {idPedido? 'modificación': 'ingreso'} de pedido</h1>
      <hr className="title-separator"></hr>
      <div className="info">
        <div className="item">
          <div className="title">ESTACIÓN DE SERVICIO</div>
          <div className="value">{getCleanEdsNumber(selectedEds.value)}</div>
        </div>
        <div className="item">
          <div className="title">TIPO DE PEDIDO</div>
          <div className="value">{tipoPedido.label}</div>
        </div>
        <div className="item">
          <div className="title">PLANTA</div>
          <div className="value overflow capitalize">
            {`${planta.CODIGO_PLANTA} - ${planta.NOMBRE_PLANTA.toLowerCase()}`}
          </div>
        </div>
      </div>
      <div className="info col4">
        <div className="item">
          <div className="title">TIPO DE CAMIÓN</div>
          <div className="value overflow">{camion.NOMBRE}</div>
        </div>
        <div className="item">
          <div className="title">FECHA PREFERENTE</div>
          <div className="value">{ parseDate(date) }</div>
        </div>
        <div className="item">
          <div className="title">VENTANA SOLICITADA</div>
          <div className="value overflow">{ horario.HORARIO }</div>
        </div>
        <div className="item">
          <div className="title">CONTACTO</div>
          <div className="value overflow capitalize">{getContacto()}</div>
        </div>
      </div>
      {idPedido &&
        <div className={`productos ${idPedido? 'original' : ''}`}>
          <div className="header">
            <div className="title">Mix de original</div>
            <div className="liters">Litros</div>
          </div>
          <div className="items">

          {compartimientosPedido.map((p, key) => 
            <>
              <div className="producto">
                <div className="title">{p.NOMBRE.toLowerCase()}</div>
                <div className="liters">{getLocaleStringNumber(p.CANTIDAD_SOLICITADA)}</div>
              </div>
              <hr className="separator"></hr>
            </>
          )}

          <hr className="separator-last"></hr>

          </div>
          <div className="total">
            <div className="title">Total</div>
            <div className="liters">{calculateTotal(true)}</div>
          </div>
        </div>
      }
      <div className={`productos ${idPedido? 'modificacion' : ''}`}>
        <div className="header">
          <div className="title">Mix de productos{idPedido? ' final': ''}</div>
          <div className="liters">Litros</div>
        </div>
        <div className="items">

          {(tipoPedido.value !== 'emergencia' || !idPedido) && mixProductos.map((p, key) => 
            <>
              <div className="producto">
                <div className="title">{p.NOMBRE.toLowerCase()}</div>
                <div className="liters">{getLocaleStringNumber(p.CANTIDAD)}</div>
              </div>
              <hr className="separator"></hr>
            </>
          )}

          {tipoPedido.value === 'emergencia' && idPedido && compartimientosPedido.map((p, key) => 
            <>
              <div className="producto">
                <div className="title">{p.NOMBRE.toLowerCase()}</div>
                <div className="liters">{getLocaleStringNumber(p.CANTIDAD_SOLICITADA)}</div>
              </div>
              <hr className="separator"></hr>
            </>
          )}

          <hr className="separator-last"></hr>
        </div>
        <div className="total">
          <div className="title">Total</div>
          <div className="liters">{(tipoPedido.value !== 'emergencia' || !idPedido)? calculateTotal(): calculateTotal(true)}</div>
        </div>
      </div>

      <div className="item mtop">
        <div className="title">OBSERVACIONES</div>
        <div className="value full obs">{ observaciones }</div>
      </div>
      <hr className="msep" />
      <div className="actions">
        <button className="actions-buttons hallow red-border" onClick={() => setDisplayResumenIngreso(false)}>Volver</button>
        <button className="actions-buttons red" onClick={ingresarPedido}>Confirmar</button>
      </div>

      <CustomModal
        displayModal = { displayModal }
        closeModal = { closeModal }
        acceptFunc = { closeModal }
        modalTitle = { modalTitle }
        modalText = { modalText }
        modalIcon = { modalIcon }
      />
    </div>
  );
};

export default ResumenIngresoPedido;
