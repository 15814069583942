import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import CartolaTable from "./CartolaTable";
import HeaderData from "./HeaderData";
import HeaderBox from "./HeaderBox";
import saldoAmarilloIcon from "../assets/img/saldo-amarillo.svg";
import saldoAzulIcon from "../assets/img/saldo-azul.svg";
import abonoIcon from "../assets/img/abono.svg";
import cargoIcon from "../assets/img/cargo.svg";
import ExportExcel from "./ExportExcel";
import PrintComponent from "./PrintComponent";

import {
  SingleOption,
  customStyles,
  getLocaleStringNumber,
} from "./utils/utils.js";

import { displayLoader, hideLoader } from "./utils/windows";
import { initializeSelectOptions, getEds, setPagadoresArcoprime, getEdsArcoprime } from "./utils/get";
import {
  getPreviousMonth,
  getPeriodosSince,
  getUpdateDate,
} from "./utils/monthNames";

const Cartola = (props) => {
  const [selectedOption, setSelectedOption] = useState();
  const [selectedPeriodo, setSelectedPeriodo] = useState(null);
  const [productsFilter, setProductsFilter] = useState([]);
  const [tipoMovimientosFilter, setTipoMovimientosFilter] = useState([]);
  const [tipoMovimientoFilter, setTipoMovimientoFilter] = useState([]);

  const [movimientos, setMovimientos] = useState([]);
  const [movimientosOriginal, setMovimientosOriginal] = useState([]);
  const [movimientosLoaded, setMovimientosLoaded] = useState(false);
  const [previousMonth, setPreviousMonth] = useState("");
  const [previousSaldo, setPreviousSaldo] = useState("");
  const [thisMonth, setThisMonth] = useState("");
  const [totalCargos, setTotalCargos] = useState("");
  const [totalAbonos, setTotalAbonos] = useState("");
  const [totalSaldo, setTotalSaldo] = useState("");
  const [allEds, setAllEds] = useState([]);
  const [allEdsForTable, setAllEdsForTable] = useState([]);
  const [allPeriodos, setAllPeriodos] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [updateDate, setUpdateDate] = useState("");
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [token, setToken] = useState();
  const [filteredMovimientos, setFilteredMovimientos] = useState([]);
  const tableRef = useRef(null);
  const originalMovimientos = useRef([]);

  const [isArcoprime, setIsArcoprime] = useState(false);

  const [allPagadores, setAllPagadores] = useState([]);
  const [selectedPagador, setSelectedPagador] = useState();

  const labels = [
    ["Fecha Movimiento", "FECHA_MOVIMIENTO"],
    ["Días Vencidos", "DIAS_ATRASO"],
    ["Nº EDS", "NUMERO_EDS"],
    ["Línea Producto", "LINEA_PRODUCTO"],
    ["Tipo Movimiento", "TIPO_DOCUMENTO"],
    ["Nº Documento", "NUMERO_DOCUMENTO"],
    ["Cargos", "CARGO"],
    ["Abonos", "ABONO"],
    ["Saldo", "SALDO"],
  ];

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (props.token && props.rutConcesionario) {
        setToken(props.token);
        
        if (props.params) {
          const params = props.params.split("&");
          const eds = params[0].split("=")[1];
          getMovimientos(props.token, eds);
        } else {
          if (props.rutConcesionario === "77215640-5") {
            setIsArcoprime(true);
            checkIfIsAdminConcesionario();
          }else{
            getMovimientos(props.token);
          }
        }

      }
    } else {
      if (props.token) {
        console.log("ES DEV!")
        setToken(props.token);
        
        if (props.params) {
          const params = props.params.split("&");
          const eds = params[0].split("=")[1];
          getMovimientos(props.token, eds);
        } else {
          if (props.rutConcesionario === "77215640-5" /* || true */) {
            setIsArcoprime(true);
            checkIfIsAdminConcesionario();
          }else{
            getMovimientos(props.token);
          }
        } 
      }
    }
  }, [props.token, props.rutConcesionario]);

  useEffect(() => {
    if(props.cabecera!==""){
      if(!isArcoprime){
        getEds(
          props.callServiceHandler,
          props.rutConcesionario,
          setAllEdsForTable,
          setAllEds,
          props.params,
          setSelectedOption,
          props.cabecera
        );
      }
    }
  }, [props.cabecera]);

  useEffect(() => {
    if(selectedPagador){
      getEdsArcoprime(props.callServiceHandler, setAllEds, setAllEdsForTable, parseInt(selectedPagador.value));
      getMovimientos(props.token);
    }
  }, [selectedPagador]);

  useEffect(() => {
    if (allPagadores.length === 1) {
      changePagador(allPagadores[0]);
    }
  }, [allPagadores]);

  useEffect(() => {
    const allPeriodos = getPeriodosSince(2007);
    setAllPeriodos(allPeriodos);
    setSelectedPeriodo(allPeriodos[0]);
    const date = allPeriodos[0].value.split("%3B");
    setSelectedMonth(date[0]);
    setSelectedYear(date[1]);
  }, []);

  async function checkIfIsAdminConcesionario(){
    const email = await getUserEmail()

    const url = process.env.REACT_APP_IDENTITIES_URL + '/users?id=' + email
    let user = []
    let result = await props.callServiceHandler(url, "GET")

    if(result !== "error"){
      if(result.data){
        user = result.data
      }
    }
  }

  function decodeToken(){
    const base64Url = props.token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload)
  }

  async function getUserEmail(){
    const decodedToken = decodeToken()
    let email

    let url = process.env.REACT_APP_PORTAL_URL + 'identities/impersonate'

    let result = await props.callServiceHandler(url, "GET")
    if(result !== "error" && result.data[0]){
      email = result.data[0].email
    }
    else{
      email = decodedToken.email;
    }
    return email
  }

  useEffect(() => {
    if(props.edsPortal){
      setPagadoresArcoprime(props.edsPortal.data.user_eds,setAllPagadores)
      hideLoader()
    }
  }, [props.edsPortal]);

  function changeSelectedOption(e) {
    setSelectedOption(e);
    getMovimientos(token, e.value, selectedPeriodo.value);
  }

  function changeSelectedPeriodo(e) {
    setSelectedPeriodo(e);
    const date = e.value.split("%3B");
    setSelectedMonth(date[0]);
    setSelectedYear(date[1]);
    if (selectedOption) getMovimientos(token, selectedOption.value, e.value);
    else getMovimientos(token, null, e.value);
  }

  function changePagador(e) {
    setSelectedPagador(e);
  }

  function updateMovimientos(){
    if (selectedOption) getMovimientos(token, selectedOption.value, selectedPeriodo.value);
    else getMovimientos(token, null, selectedPeriodo.value);
  }

  async function getMovimientos(newToken, id_eds, periodo = null) {
    displayLoader();

    const idConcesionario = isArcoprime?parseInt(selectedPagador.value): props.rutConcesionario;

    let url =
      process.env.REACT_APP_BASE_API +
      "/cuenta-corriente/movimientos-cartola?rut_concesionario=" +
      idConcesionario;
    if (periodo) {
      url += "&periodo=" + periodo;
    }

    if (id_eds === undefined || id_eds === null) url += "&id_eds=*";
    else if(id_eds!=="Sin Pto Vta") url += "&id_eds=" + id_eds;

    let result = await props.callServiceHandler(url, "GET")
    if(result !== "error"){
      getUpdateDate(setUpdateDate);
      cleanCartolaData(result.data.MOVIMIENTOS, result.data.SALDO_ANTERIOR);
      setThisMonth(result.data.PERIODO);
      setPreviousMonth(getPreviousMonth(result.data.PERIODO));
      let pre = parseInt(result.data.SALDO_ANTERIOR) || 0;
      if (pre > 0) pre = pre * -1;
      setPreviousSaldo(pre);
      setMovimientosLoaded(true);
    }
  }

  function cleanCartolaData(movimientos, saldoAnterior) {
    const newProductsFilter = initializeSelectOptions();
    const newTipoMovimientoFilter = initializeSelectOptions();
    const newMovimientos = [];
    let newTotalCargos = 0;
    let newTotalAbonos = 0;

    let dailySaldo = parseInt(saldoAnterior) || 0;
    dailySaldo = -dailySaldo;

    movimientos.forEach(function (movimiento) {
      if (
        movimiento.LINEA_PRODUCTO !== "" &&
        movimiento.LINEA_PRODUCTO !== "TOTAL" &&
        newProductsFilter.filter((e) => e.value === movimiento.LINEA_PRODUCTO)
          .length == 0
      ) {
        const newProd = {
          label: movimiento.LINEA_PRODUCTO,
          value: movimiento.LINEA_PRODUCTO,
        };
        newProductsFilter.push(newProd);
      }

      if (
        movimiento.TIPO_DOCUMENTO !== "" &&
        movimiento.TIPO_DOCUMENTO !== "TOTAL" &&
        newTipoMovimientoFilter.filter(
          (e) => e.value === movimiento.TIPO_DOCUMENTO
        ).length == 0
      ) {
        const newProd = {
          label: movimiento.TIPO_DOCUMENTO,
          value: movimiento.TIPO_DOCUMENTO,
        };
        newTipoMovimientoFilter.push(newProd);
      }

      dailySaldo += movimiento.ABONO;
      dailySaldo -= movimiento.CARGO;

      const newMovimiento = {
        LINEA_PRODUCTO: movimiento.LINEA_PRODUCTO,
        TIPO_DOCUMENTO: movimiento.TIPO_DOCUMENTO,
        ESTADO: movimientos.ESTADO,
        CLASIFICACION: movimientos.CLASIFICACION,
        NUMERO_DOCUMENTO: movimiento.NUMERO_DOCUMENTO,
        NUMERO_OFICINA: movimiento.NUMERO_OFICINA,
        NUMERO_EDS:
          movimiento.NUMERO_EDS === "" ? "Sin Pto Vta" : movimiento.NUMERO_EDS,
        COMUNA: movimiento.COMUNA,
        FECHA_MOVIMIENTO: movimiento.FECHA_MOVIMIENTO,
        FECHA_VENCIMIENTO: movimiento.FECHA_VENCIMIENTO,
        CARGO: movimiento.CARGO,
        ABONO: movimiento.ABONO,
        DIAS_ATRASO: movimiento.DIAS_ATRASO,
        SALDO: dailySaldo,
        ID: movimiento.ID,
        FACTURA_SD: movimiento.FACTURA_SD,
      };

      newMovimientos.push(newMovimiento);
      newTotalCargos += movimiento.CARGO;
      newTotalAbonos += movimiento.ABONO;
    });

    setMovimientos(newMovimientos);
    setMovimientosOriginal(newMovimientos);
    originalMovimientos.current = newMovimientos;
    setTotalCargos(-newTotalCargos);
    setTotalAbonos(newTotalAbonos);
    setTotalSaldo(dailySaldo);

    setProductsFilter(newProductsFilter);
    setTipoMovimientosFilter(newTipoMovimientoFilter);

    if (props.params && !props.params.includes("saldo")) {
      const newFilter = [];
      newTipoMovimientoFilter.forEach(function (mov) {
        if (mov.label !== "Factura") newFilter.push(mov);
      });
      setTipoMovimientoFilter(newFilter);
    }
  }

  function resetMovimientos() {
    setMovimientos([...originalMovimientos.current]);
  }

  return (
    <div className="cartola page">
      <div className="view-title">Cuenta corriente</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Cartola</div>

      <hr className="title-separator-line" />

      <HeaderData
        rutConcesionario={props.rutConcesionario}
        token={token}
        actualizacion={updateDate}
        cabecera={props.cabecera}
        actualizable={true}
        updateFunction={()=>updateMovimientos()}
        callServiceHandler={props.callServiceHandler}
      />

      <div className="header-box-multiple-container cartolaboxes">
        <HeaderBox
          monthInfo="Saldo mes anterior"
          month={previousMonth}
          amount={getLocaleStringNumber(previousSaldo)}
          icon={saldoAmarilloIcon}
          color="yellow"
          isFirst={true}
        />

        <HeaderBox
          monthInfo="Total Cargo"
          month={thisMonth}
          amount={getLocaleStringNumber(totalCargos)}
          icon={cargoIcon}
          color="red"
        />

        <HeaderBox
          monthInfo="Total Abono"
          month={thisMonth}
          amount={getLocaleStringNumber(totalAbonos)}
          icon={abonoIcon}
          color="green"
        />

        <HeaderBox
          monthInfo="Saldo"
          month={thisMonth}
          amount={getLocaleStringNumber(totalSaldo)}
          icon={saldoAzulIcon}
          isLast={true}
          color="blue"
        />
      </div>

      <hr />

      <div className="cartola-options">
        <div className="filters-container">

          {isArcoprime && <div className="filter">
            <div className="filter-title">ID DE PAGADOR<span className="red-asterisk">*</span></div>
            <div className="filter-input">
              <Select
                className="eds-select"
                value={selectedPagador}
                onChange={(e) => changePagador(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allPagadores}
                components={{
                  SingleOption,
                  IndicatorSeparator: () => null,
                }}
                isClearable={false}
                autosize={false}
                placeholder="Seleccionar"
                styles={customStyles}
              />
            </div>
          </div>}

          <div className={`filter ${isArcoprime?"filter-with-margin-left":""}`}>
            <div className="filter-title">ESTACIÓN DE SERVICIO</div>
            <div className="filter-input">
              <Select
                className="eds-select"
                value={selectedOption}
                onChange={(e) => changeSelectedOption(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allEds}
                components={{ SingleOption, IndicatorSeparator: () => null }}
                isClearable={false}
                autosize={false}
                placeholder="Todas"
                styles={customStyles}
              />
            </div>
          </div>

          <div className="filter filter-with-margin-left">
            <div className="filter-title">PERÍODO</div>
            <div className="filter-input">
              <Select
                className="eds-select"
                value={selectedPeriodo}
                onChange={(e) => changeSelectedPeriodo(e)}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={allPeriodos}
                components={{ SingleOption, IndicatorSeparator: () => null }}
                isClearable={false}
                autosize={false}
                styles={customStyles}
              />
            </div>
          </div>

          <div className="filter">
            <div className="filter-input">
              <input
                type="search"
                className="search"
                value={filterText}
                placeholder="Buscar..."
                onChange={(e) => setFilterText(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="actions">
          <PrintComponent 
            reff={tableRef}
            classes=""
            isROImpersonate={props.isROImpersonate}
          />

          <ExportExcel
            data={filteredMovimientos}
            labels={labels}
            isROImpersonate={props.isROImpersonate}
          />
        </div>
      </div>

      <CartolaTable
        movimientos={movimientos}
        setMovimientos={setMovimientos}
        productsFilter={productsFilter}
        allTipoMovimientoFilter={tipoMovimientosFilter}
        tipoMovimientoFilter={tipoMovimientoFilter}
        setTipoMovimientoFilter={setTipoMovimientoFilter}
        allEds={allEdsForTable}
        selectedMonth={selectedMonth}
        selectedYear={selectedYear}
        filterText={filterText}
        isPagos={false}
        filteredMovimientos={filteredMovimientos}
        setFilteredMovimientos={setFilteredMovimientos}
        movimientosLoaded={movimientosLoaded}
        tableRef={tableRef}
        newToken={props.token}
        setFilterText={setFilterText}
        resetMovimientos={resetMovimientos}
        movimientosOriginal={movimientosOriginal}
        callServiceHandler={props.callServiceHandler}
      />
    </div>
  );
};

export default Cartola;
