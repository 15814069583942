import React, {useEffect} from 'react';
import logo from "../../../assets/img/logo-comprobante.svg";
import { getTanquesSifoneadosFluctuacionesCierreMensual } from '../../utils/tanquesSifoneados';

const PrintFileHeader = ({cabecera,selectedEds,selectedPeriodo,tanqueData,rutConcesionario}) => {

    return (
        <div className='print-file-header print-file-historial-fluctuaciones-fix'>
            <img src={logo} />
            <div className='title'>Historial de Fluctuaciones</div>
            <hr className='title-separator' />
            <div className='info'>
                <div className='col'>
                    <div className='info-item'>
                        <div className='info-label'>Cliente</div>
                        <div className='info-value'>{cabecera?.DATOS_CLIENTE?.NOMBRE} {rutConcesionario} </div>
                    </div>
                    <div className='info-item'>
                        <div className='info-label'>Periodo</div>
                        <div className='info-value'>{selectedPeriodo?.label}</div>
                    </div>
                    <div className='info-item'>
                        <div className='info-label'>Tanque</div>
                        <div className='info-value'>{tanqueData?.tanque} - {tanqueData?.material} </div>
                    </div>
                </div>
                <div className='col'>
                    <div className='info-item'>
                        <div className='info-label'>Estación</div>
                        <div className='info-value'>{selectedEds?.label}</div>
                    </div>
                    <div className='info-item'>
                        <div className='info-label'>Medición&nbsp;Inicial</div>
                        <div className='info-value'>{tanqueData?.medicion_inicial} (lts)</div>
                    </div>
                </div>
            </div>
            <hr className="content-separator" />
        </div>
    );
}

export default PrintFileHeader;
