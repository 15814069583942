import React from 'react';
import errorICon from "../../assets/img/error-icon.png";

const StepError = () => {
    return (
        <div className="terminos">
            <div className="terminos-error">
              <img src={errorICon} alt="" />
              <h3>Importante</h3>
              <p>Ha rechazado los términos y condiciones del Sitio Internet</p>
              <p>para pagos, contacte a COPEC para resolver medios de pago.</p>
            </div>
        </div>
    );
}

export default StepError;
