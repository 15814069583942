import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { parseBudat, getLocaleStringNumber } from "./utils/utils.js";
import { 
  showGreyBackground, 
  hideGreyBackground, 
  scrollTo
} from "./utils/windows";
import { docModalStyles } from "./utils/styles.js";
import closeIcon from "../assets/img/cross-icon.svg";

const DocInfoModal = (props) => {
  const displayModal = props.displayModal;
  const closeModal = props.closeModal;
  const modalTitle = props.modalTitle;
  const modalText = props.modalText;
  const detallesDoc = props.detallesDoc;
  const info = props.info;
  const distanceToTop = props.distanceToTop;
  const detalleCargado = props.detalleCargado;

  useEffect(() => {
    if (displayModal){
      showGreyBackground();
      scrollTo(0, (distanceToTop - 150));
    }
    else{
      hideGreyBackground();
    }
  }, [displayModal]);

  const detallesDoc2 = [
    {
      NAME1: "",
      STCD1: "",
      FEING: "0000-00-00",
      BELNR: "0640000155",
      SGTXT: "Case Aplicado",
      XBLNR: "0640000162",
      SINUSO: "",
      BLDAT: "2021-03-25",
      ZFBDT: "2020-08-06",
      CARGO: "",
      ABONO: 53921,
      ES_SALDO: "",
    },
    {
      NAME1: "",
      STCD1: "",
      FEING: "0000-00-00",
      BELNR: "0210245170",
      SGTXT: "Factura",
      XBLNR: 10230936,
      SINUSO: "",
      BLDAT: "2020-07-07",
      ZFBDT: "2020-08-06",
      CARGO: 33773,
      ABONO: "",
      ES_SALDO: "",
    },
    {
      NAME1: "",
      STCD1: "",
      FEING: "0000-00-00",
      BELNR: "0630398895",
      SGTXT: "Comisiones",
      XBLNR: "0630398895",
      SINUSO: "",
      BLDAT: "2020-08-31",
      ZFBDT: "2020-08-31",
      CARGO: "",
      ABONO: 12436,
      ES_SALDO: "",
    },
    {
      NAME1: "",
      STCD1: "",
      FEING: "0000-00-00",
      BELNR: "0640000065",
      SGTXT: "Case Aplicado",
      XBLNR: "0640000155",
      SINUSO: "",
      BLDAT: "2021-02-26",
      ZFBDT: "2020-08-28",
      CARGO: 32584,
      ABONO: "",
      ES_SALDO: "",
    },
    {
      NAME1: "",
      STCD1: "",
      FEING: "0000-00-00",
      BELNR: "0640000065",
      SGTXT: "Case Aplicado",
      XBLNR: "0640000155",
      SINUSO: "",
      BLDAT: "2021-02-26",
      ZFBDT: "2020-08-28",
      CARGO: 32584,
      ABONO: "",
      ES_SALDO: "",
    },
    {
      NAME1: "",
      STCD1: "",
      FEING: "0000-00-00",
      BELNR: "0640000065",
      SGTXT: "Case Aplicado",
      XBLNR: "0640000155",
      SINUSO: "",
      BLDAT: "2021-02-26",
      ZFBDT: "2020-08-28",
      CARGO: 32584,
      ABONO: "",
      ES_SALDO: "",
    },
    {
      NAME1: "",
      STCD1: "",
      FEING: "0000-00-00",
      BELNR: "0640000065",
      SGTXT: "Case Aplicado",
      XBLNR: "0640000155",
      SINUSO: "",
      BLDAT: "2021-02-26",
      ZFBDT: "2020-08-28",
      CARGO: 32584,
      ABONO: "",
      ES_SALDO: "",
    },
    {
      NAME1: "",
      STCD1: "",
      FEING: "0000-00-00",
      BELNR: "0640000065",
      SGTXT: "Case Aplicado",
      XBLNR: "0640000155",
      SINUSO: "",
      BLDAT: "2021-02-26",
      ZFBDT: "2020-08-28",
      CARGO: 32584,
      ABONO: "",
      ES_SALDO: "",
    },
    {
      NAME1: "",
      STCD1: "",
      FEING: "0000-00-00",
      BELNR: "0640000065",
      SGTXT: "Case Aplicado",
      XBLNR: "0640000155",
      SINUSO: "",
      BLDAT: "2021-02-26",
      ZFBDT: "2020-08-28",
      CARGO: 32584,
      ABONO: "",
      ES_SALDO: "",
    },
    {
      NAME1: "",
      STCD1: "",
      FEING: "0000-00-00",
      BELNR: "0640000065",
      SGTXT: "Case Aplicado",
      XBLNR: "0640000155",
      SINUSO: "",
      BLDAT: "2021-02-26",
      ZFBDT: "2020-08-28",
      CARGO: 32584,
      ABONO: "",
      ES_SALDO: "",
    },
    {
      NAME1: "",
      STCD1: "",
      FEING: "0000-00-00",
      BELNR: "0640000065",
      SGTXT: "Case Aplicado",
      XBLNR: "0640000155",
      SINUSO: "",
      BLDAT: "2021-02-26",
      ZFBDT: "2020-08-28",
      CARGO: 32584,
      ABONO: "",
      ES_SALDO: "",
    },
    {
      NAME1: "",
      STCD1: "",
      FEING: "0000-00-00",
      BELNR: "0640000065",
      SGTXT: "Case Aplicado",
      XBLNR: "0640000155",
      SINUSO: "",
      BLDAT: "2021-02-26",
      ZFBDT: "2020-08-28",
      CARGO: 32584,
      ABONO: "",
      ES_SALDO: "",
    },
    {
      NAME1: "",
      STCD1: "",
      FEING: "0000-00-00",
      BELNR: "0640000065",
      SGTXT: "Case Aplicado",
      XBLNR: "0640000155",
      SINUSO: "",
      BLDAT: "2021-02-26",
      ZFBDT: "2020-08-28",
      CARGO: 32584,
      ABONO: "",
      ES_SALDO: "",
    },
    {
      NAME1: "",
      STCD1: "",
      FEING: "0000-00-00",
      BELNR: "0640000065",
      SGTXT: "Case Aplicado",
      XBLNR: "0640000155",
      SINUSO: "",
      BLDAT: "2021-02-26",
      ZFBDT: "2020-08-28",
      CARGO: 32584,
      ABONO: "",
      ES_SALDO: "",
    },
    {
      NAME1: "",
      STCD1: "",
      FEING: "0000-00-00",
      BELNR: "0640000065",
      SGTXT: "Case Aplicado",
      XBLNR: "0640000155",
      SINUSO: "",
      BLDAT: "2021-02-26",
      ZFBDT: "2020-08-28",
      CARGO: 32584,
      ABONO: "",
      ES_SALDO: "",
    },
    {
      NAME1: "",
      STCD1: "",
      FEING: "0000-00-00",
      BELNR: "0640000065",
      SGTXT: "Case Aplicado",
      XBLNR: "0640000155",
      SINUSO: "",
      BLDAT: "2021-02-26",
      ZFBDT: "2020-08-28",
      CARGO: 32584,
      ABONO: "",
      ES_SALDO: "",
    },
    {
      NAME1: "",
      STCD1: "",
      FEING: "0000-00-00",
      BELNR: "0640000065",
      SGTXT: "Case Aplicado",
      XBLNR: "0640000155",
      SINUSO: "",
      BLDAT: "2021-02-26",
      ZFBDT: "2020-08-28",
      CARGO: 32584,
      ABONO: "",
      ES_SALDO: "",
    },
  ];

  const showCaseInfo = () => {
    return (
      <>
        <div className={`documento-info-modal`}>
          <div className="title">Documento Nº {info.numero}</div>
          <div className="subtitle">Detalle solicitud</div>
          <div className="content">
            {props.detallesDoc.length === 0 ? (
              <div className="loading">Cargando ...</div>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Descripcion</th>
                      <th className="align-right">Numero</th>
                      <th className="align-right">Emision</th>
                      <th className="align-right">Vencimiento</th>
                      <th className="align-right">Cargo</th>
                      <th className="align-right">Abono</th>
                    </tr>
                  </thead>
                  <tr className="separator-table-row">
                    <th colSpan="100%" />
                  </tr>
                  <tbody>
                    {detallesDoc.map((mov, key) => (
                      <>
                        <tr className="data" key={key}>
                          <td className="align-left">
                            {mov.SGTXT != "" ? mov.SGTXT : ""}
                          </td>
                          <td className="align-right">
                            {mov.XBLNR != "" ? mov.XBLNR : ""}
                          </td>
                          <td className="align-right">
                            {mov.BLDAT != "" ? parseBudat(mov.BLDAT) : ""}
                          </td>
                          <td className="align-right">
                            {mov.ZFBDT != "" ? parseBudat(mov.ZFBDT) : ""}
                          </td>
                          <td className="align-right">
                            {mov.CARGO != ""
                              ? `$ ${getLocaleStringNumber(mov.CARGO)}`
                              : ""}
                          </td>
                          <td className="align-right">
                            {mov.ABONO != ""
                              ? `$ ${getLocaleStringNumber(mov.ABONO)}`
                              : ""}
                          </td>
                        </tr>
                        {detallesDoc.length - 1 !== key && (
                          <>
                            <tr>
                              <td colSpan="100%" className="tdsep">
                                <hr></hr>
                              </td>
                            </tr>
                          </>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
          <div className="footer">
            {/* <span className="important">*</span>
        <span>Observaciones: aquí pueden ir las observaciones</span> */}
          </div>
        </div>
      </>
    );
  };

  const showFacturaInfo = () => {
    return (
      <>
        <div className={`documento-info-modal`}>
          <div className="title">Documento Nº {info.numero}</div>
          <div className="subtitle">Detalle solicitud</div>
          <div className="content">
            {detallesDoc.detalle?<>
              {Object.keys(props.detallesDoc).length === 0 ? (
              <div className="loading">Cargando ...</div>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      {detallesDoc.detalle?.map((item, key) => {
                        return (
                          <th className={`${key !== 0 ? "align-right" : ""}`}>
                            {item}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tr className="separator-table-row">
                    <th colSpan="100%" />
                  </tr>
                  <tbody>
                    {detallesDoc.detalle_valores?.map((item, key) => (
                      <>
                        <tr className="data" key={key}>
                          {detallesDoc.detalle?.map((description, keyd) => {
                            return (
                              <td
                                className={`${
                                  keyd !== 0 ? "align-right" : "align-left"
                                }`}
                              >
                                {getLocaleStringNumber(item[description])}
                              </td>
                            );
                          })}
                        </tr>
                        <tr>
                          <td colSpan="100%" className="tdsep">
                            <hr></hr>
                          </td>
                        </tr>
                      </>
                    ))}
                    {/*  <tr className="data">
                      <td colSpan={detallesDoc.detalle.length}>&nbsp;</td>
                    </tr>
                    <tr>
                      <td colSpan="100%" className="tdsep">
                        <hr></hr>
                      </td>
                    </tr> */}

                    {detallesDoc.cabecera?.map((description, key) => {
                      return (
                        <>
                          <tr className="data">
                            {detallesDoc.detalle?.length > 2 ? (
                              <>
                                <td colSpan={detallesDoc.detalle?.length - 2}>
                                  &nbsp;
                                </td>
                              </>
                            ) : (
                              ""
                            )}
                            <td className="align-right">{description}</td>
                            <td className="align-right">{`$ ${getLocaleStringNumber(
                              detallesDoc.cabecera_valores[description]
                            )}`}</td>
                          </tr>
                          {detallesDoc.cabecera?.length - 1 !== key && (
                            <>
                              <tr>
                                <td colSpan="100%" className="tdsep">
                                  <hr></hr>
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      );
                    })}
                  </tbody>

                  {/* {detallesDoc.EZFFI && (
                    <>
                      <tr className="data">
                        <td colSpan="3">&nbsp;</td>
                        <td colSpan="2" className="align-right">
                          Valor Neto
                        </td>
                        <td>{`$ ${getLocaleStringNumber(
                          detallesDoc.EZFFI.C_SUMA_TOTAL
                        )}`}</td>
                      </tr>
                      <tr className="data">
                        <td colSpan="3">&nbsp;</td>
                        <td colSpan="2" className="align-right">
                          IVA (19%)
                        </td>
                        <td>{`$ ${getLocaleStringNumber(
                          detallesDoc.EZFFI.C_IVA
                        )}`}</td>
                      </tr>
                      <tr className="data">
                        <td colSpan="3">&nbsp;</td>
                        <td colSpan="2" className="align-right">
                          <b>Total</b>
                        </td>
                        <td>
                          <b>{`$ ${getLocaleStringNumber(
                            detallesDoc.EZFFI.C_TOTAL
                          )}`}</b>
                        </td>
                      </tr>
                    </>
                  )} */}
                </table>
              </>
            )}
            </>:<>
            {detalleCargado?<h5>No existen datos para el documento</h5>:<h5>Cargando...</h5>}
            </>}
            
          </div>
          <div className="footer">
            {/* <span className="important">*</span>
        <span>Observaciones: aquí pueden ir las observaciones</span> */}
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <Modal
        isOpen={displayModal}
        onRequestClose={closeModal}
        style={docModalStyles(distanceToTop)}
      >
        <button className="modal__close-btn" onClick={closeModal}>
          <img src={closeIcon} alt="close modal" />
        </button>
        {/* <h1 className="modal__title ">Documento Nº {info.numero} </h1> */}
        {info.tipo === "case" ? showCaseInfo() : ""}
        {info.tipo === "factura" ? showFacturaInfo() : ""}
        <hr />
        <div className="modal__actions">
          {info.tipo === "factura" && (
            <>
              <button className="main-button">
                <a
                  target={`${detallesDoc.url ? "_blank" : ""}`}
                  href={`${
                    detallesDoc.url ? detallesDoc.url : "javascript:void(0);"
                  }`}
                >
                  Ver Documento
                </a>
              </button>
            </>
          )}
          <button className="main-button ml" onClick={closeModal}>
            Volver
          </button>
        </div>
      </Modal>
    </div>
  );
};

Modal.setAppElement("body");

export default DocInfoModal;
