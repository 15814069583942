import React, {useState, useEffect, useRef} from "react";
import { orderArray } from "../utils/utils";
import Flecha_down from "../../assets/img/Flecha_down.svg";
import Flecha_up from "../../assets/img/Flecha_up.svg";
import { showGreyBackground,hideGreyBackground } from "../utils/windows";
import MantenimientoModalModal from "./MantenimientoModal";
import MantenimientoModal from "./MantenimientoModal";
import CustomHighlighter from "../CustomHighlighter";
import { getPaginationItems } from "../utils/get";

const AvisosDeMantenimientoTable = (props) => {
  
  const tableRef = props.tableRef;
  const avisos = props.avisos;
  const setAvisos = props.setAvisos;
  const filteredAvisos = props.filteredAvisos;
  const filterText = props.filterText;
  const setFilteredAvisos = props.setFilteredAvisos;
  const [distanceToTop, setDistanceToTop] = useState(0);
  const [ordenModal, setOrdenModal] = useState({});
  const [displayOrdenModal, setDisplayOrdenModal] = useState(false);
  const [count, setCount] = useState(0);
  const paginationFilteredAvisos = props.paginationFilteredAvisos;
  const setPaginationFilteredAvisos = props.setPaginationFilteredAvisos;
  const setPaginationActivePage = props.setPaginationActivePage;
  const openFile = props.openFile;

  const descripcionRefs = useRef([])
  const productoRefs = useRef([])
  const equipoRefs = useRef([])
  const contratistaRefs = useRef([])

  const orderTable = (filterkey, order, parse) => {
    const arr = orderArray(filterkey, filteredAvisos, order, parse);
    setFilteredAvisos(arr);
  };

  const changeOrdenModal = (e, orden) => {
    showGreyBackground();
    setDisplayOrdenModal(true);
    setOrdenModal(orden);
    setDistanceToTop(e.currentTarget.getBoundingClientRect().top);
  };

  function closeModal() {
    setDisplayOrdenModal(false);
    hideGreyBackground();
  }

  function getActiveEllipsis(col, key) {
    let e
    if(col === 'descripcion'){
      e = descripcionRefs.current[key]
    }
    else if(col === 'producto'){
      e = productoRefs.current[key]
    }
    else if(col === 'equipo'){
      e = equipoRefs.current[key]
    }
    else if(col === 'contratista'){
      e = contratistaRefs.current[key]
    }
  
    if(e){
      const isActive = e.offsetWidth < e.scrollWidth
      return isActive
    }
    return false
  }

  useEffect(() => {
      filter();
  }, [filterText]);

  useEffect(() => {
    if (avisos?.length>0){
      getPaginationItems(filteredAvisos,1,setPaginationFilteredAvisos,setPaginationActivePage)
    }
  }, [filteredAvisos]);

  useEffect(() => {
    console.log("FILTERED",filteredAvisos)
  }, [filteredAvisos]);

  useEffect(() => {
    descripcionRefs.current = descripcionRefs.current.slice(0, paginationFilteredAvisos.length);
    productoRefs.current = productoRefs.current.slice(0, paginationFilteredAvisos.length);
    equipoRefs.current = equipoRefs.current.slice(0, paginationFilteredAvisos.length);
    contratistaRefs.current = contratistaRefs.current.slice(0, paginationFilteredAvisos.length);
    setCount(count + 1)
  }, [paginationFilteredAvisos]);

  const filter = () => {

    let avisosArr = avisos;

    if (filterText && avisosArr?.length>0) {
      let filterTextLower = filterText.toLowerCase();
      filterTextLower = filterTextLower.replaceAll(".", "");

      avisosArr = avisosArr.filter((aviso) => {
        return (
          (aviso.NUMERO_ORDEN &&
            aviso.NUMERO_ORDEN.toLowerCase().includes(filterTextLower)) ||
          (aviso.NUMERO_AVISO &&
            aviso.NUMERO_AVISO.toLowerCase().includes(filterTextLower)) ||
          (aviso.FECHA_AVISO &&
            aviso.FECHA_AVISO.toLowerCase().includes(filterTextLower)) ||
          (aviso.FECHA_CIERRE &&
            aviso.FECHA_CIERRE.toLowerCase().includes(filterTextLower)) ||
          (aviso.DESCRIPCION &&
            aviso.DESCRIPCION.toLowerCase().includes(filterTextLower))||
          (aviso.PRIORIDAD &&
            aviso.PRIORIDAD.toLowerCase().includes(filterTextLower)) ||
          (aviso.PRODUCTO &&
            aviso.PRODUCTO.toLowerCase().includes(filterTextLower)) ||
          (aviso.EQUIPO &&
            aviso.EQUIPO.toLowerCase().includes(filterTextLower)) ||
          (aviso.FACTURA &&
            aviso.FACTURA.toLowerCase().includes(filterTextLower))||
          (aviso.CONTRATISTA &&
            aviso.CONTRATISTA.toLowerCase().includes(filterTextLower))
        );
      });
    }
    setFilteredAvisos(avisosArr);
  }

  return (<>
    <div className="pedidos-activos-table-container avisos">
      <table className="pedidos-activos-table avisos mantenimiento-table" ref={tableRef}>
        <tr>
          
          <th className="no-padding-left">
            <div className="th-data w80">
              <div className="th-text">
                Nº de Aviso
                <div className="arrows mtop">
                  <img
                    onClick={() => orderTable("NUMERO_AVISO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("NUMERO_AVISO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Fecha de Aviso
                <div className="arrows mtop">
                  <img
                    onClick={() => orderTable("FECHA_AVISO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("FECHA_AVISO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Fecha de Cierre
                <div className="arrows mtop">
                  <img
                    onClick={() => orderTable("FECHA_CIERRE", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("FECHA_CIERRE", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className={`th-data w176`}>
              <div className="th-text">
                Descripción
                <div className="arrows">
                  <img
                    onClick={() => orderTable("DESCRIPCION", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("DESCRIPCION", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data">
              <div className="th-text">
                Prioridad
                <div className="arrows">
                  <img
                    onClick={() => orderTable("PRIORIDAD", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("PRIORIDAD", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data w176">
              <div className="th-text">
                Producto
                <div className="arrows">
                  <img
                    onClick={() => orderTable("PRODUCTO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("PRODUCTO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data w176">
              <div className="th-text">
                Equipo
                <div className="arrows">
                  <img
                    onClick={() => orderTable("EQUIPO", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("EQUIPO", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className="th-data w176">
              <div className="th-text">
                Contratista
                <div className="arrows">
                  <img
                    onClick={() => orderTable("CONTRATISTA", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("CONTRATISTA", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th className="no-padding-left">
            <div className="th-data w80">
              <div className="th-text">
                Nº de Orden
                <div className="arrows mtop">
                  <img
                    onClick={() => orderTable("NUMERO_ORDEN", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("NUMERO_ORDEN", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div className={`th-data`}>
              <div className="th-text">
                Factura
                <div className="arrows">
                  <img
                    onClick={() => orderTable("FACTURA", "asc", false)}
                    src={Flecha_up}
                  ></img>
                  <img
                    onClick={() => orderTable("FACTURA", "desc", false)}
                    src={Flecha_down}
                  ></img>
                </div>
              </div>
            </div>
          </th>
        </tr>
        <tr className="table-separator-row-border-top">
          <td />
        </tr>
        <tbody>
          {typeof filteredAvisos === 'undefined' && 
            <tr className="data">
              <td className="align-left" colSpan="100%">
                Seleciona un rango de fechas
              </td>
            </tr>}
          {filteredAvisos && <>
            {
            filteredAvisos.vacio? 
            <tr className="data">
              <td className="align-left" colSpan="100%">
                La búsqueda no arrojó resultados. Por favor inténtalo de nuevo.
              </td>
            </tr>:
            <>
              {paginationFilteredAvisos.map((aviso,key)=>{
                return (
                  <tr className="data" key={key}>
                    <td className="align-left">
                      {aviso.TIENE_ADJUNTOS>0?<>
                        <a
                        className={`underline cursor-pointer`}
                        onClick={(e) => changeOrdenModal(e, aviso)}
                        >
                          <CustomHighlighter text={filterText}>
                          {aviso.NUMERO_AVISO.substring(4)}
                          </CustomHighlighter>
                        </a>
                      </>:<><CustomHighlighter text={filterText}>{aviso.NUMERO_AVISO.substring(4)}</CustomHighlighter></>}
                    </td>
                    <td className="align-left"><CustomHighlighter text={filterText}>{aviso.FECHA_AVISO}</CustomHighlighter></td>
                    <td className="align-left"><CustomHighlighter text={filterText}>{aviso.FECHA_CIERRE}</CustomHighlighter></td>
                    <td className="align-left ellipsis-with-tooltip-container ellipsis-with-tooltip-container-avisos-mantenimiento-descripcion">
                      <div 
                        ref={el => descripcionRefs.current[key] = el} 
                        className={`count-${count} table-data-ellipsis ot ${getActiveEllipsis('descripcion', key)? 'active-ellipsis': ''}`}>
                          <CustomHighlighter text={filterText}>{aviso.DESCRIPCION}</CustomHighlighter>
                          <div className={'ellipsis-tooltip'}>
                            <div className="ellipsis-tooltip-title">DESCRIPCIÓN</div>
                            <div className="ellipsis-tooltip-content">
                              {aviso.DESCRIPCION}
                            </div>
                          </div>
                      </div>
                    </td>
                    <td className="align-left"><CustomHighlighter text={filterText}>{aviso.PRIORIDAD}</CustomHighlighter></td>
                    <td className="align-left ellipsis-with-tooltip-container ellipsis-with-tooltip-container-avisos-mantenimiento-descripcion">
                      <div 
                        ref={el => productoRefs.current[key] = el} 
                        className={`count-${count} table-data-ellipsis ot ${getActiveEllipsis('producto', key)? 'active-ellipsis': ''}`}>
                          <CustomHighlighter text={filterText}>{aviso.PRODUCTO}</CustomHighlighter>
                          <div className={'ellipsis-tooltip'}>
                            <div className="ellipsis-tooltip-title">PRODUCTO</div>
                            <div className="ellipsis-tooltip-content">
                              {aviso.PRODUCTO}
                            </div>
                          </div>
                      </div>
                    </td>

                    <td className="align-left ellipsis-with-tooltip-container ellipsis-with-tooltip-container-avisos-mantenimiento-descripcion">
                      <div 
                        ref={el => equipoRefs.current[key] = el} 
                        className={`count-${count} table-data-ellipsis ot ${getActiveEllipsis('equipo', key)? 'active-ellipsis': ''}`}>
                          <CustomHighlighter text={filterText}>{aviso.EQUIPO}</CustomHighlighter>
                          <div className={'ellipsis-tooltip'}>
                            <div className="ellipsis-tooltip-title">EQUIPO</div>
                            <div className="ellipsis-tooltip-content">
                              {aviso.EQUIPO}
                            </div>
                          </div>
                      </div>
                    </td>

                    <td className="align-left ellipsis-with-tooltip-container ellipsis-with-tooltip-container-avisos-mantenimiento-descripcion">
                      <div 
                        ref={el => contratistaRefs.current[key] = el} 
                        className={`count-${count} table-data-ellipsis ot ${getActiveEllipsis('contratista', key)? 'active-ellipsis': ''}`}>
                          <CustomHighlighter text={filterText}>{aviso.CONTRATISTA}</CustomHighlighter>
                          <div className={'ellipsis-tooltip'}>
                            <div className="ellipsis-tooltip-title">CONTRATISTA</div>
                            <div className="ellipsis-tooltip-content">
                              {aviso.CONTRATISTA}
                            </div>
                          </div>
                      </div>
                    </td>

                    <td className="align-left">
                      <CustomHighlighter text={filterText}>
                      {aviso.NUMERO_ORDEN.substring(4)}
                      </CustomHighlighter>
                    </td>
                    <td className="align-left">
                      <a
                        className="factura-link"
                        target={`${aviso.FACTURA!=="" ? "_blank" : ""}`}
                        href={`${
                          aviso.FACTURA!=="" ? aviso.URL : "javascript:void(0);"
                        }`}
                      >
                        <CustomHighlighter text={filterText}>
                          {aviso.FACTURA}
                        </CustomHighlighter>
                      </a>
                    </td>
                  </tr>
                )
              })}
            </>
            }
          </>}
         
        </tbody>
      </table>
    </div>
    <div className="modal-container">
      <MantenimientoModal 
      displayModal={displayOrdenModal}
      closeModal={closeModal}
      distanceToTop={distanceToTop}
      orden={ordenModal}
      tipo="aviso"
      openFile={openFile}
      />
    </div>
    </>);
};

export default AvisosDeMantenimientoTable;
