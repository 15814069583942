import React, { useRef, useState, useEffect } from "react";
import PrintComponent from "./PrintComponent";
import HeaderData from "./HeaderData";
import ExportExcel from "./ExportExcel";
import closeIcon from "../assets/img/cross-icon.svg";
import Select from "react-select";
import { SingleOption, customStyles } from "./utils/utils.js";
import Modal from "react-modal";
import { getAllPeriodos, getUpdateDate } from './utils/monthNames';
import { getEdsPedidosActivos } from "./utils/get";
import {
  showGreyBackground,
  hideGreyBackground,
  displayLoader,
  hideLoader,
  scrollTo
} from "./utils/windows";

const excelLabels = [
  ["Fecha", "FECHA"],
  ["Hora", "HORA"],
  ["Tipo Alarma", "tipo_alarma"],
  ["Tanque", "tanques"],
  ["Gatillador", "gatillador"],
  ["Límite", "limite"]
];

const customModalStyles = {
  content: {
    top: "400px",
    width: "495px",
    padding: "0",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -70%)",
    borderRadius: 9
  }
};

export const HistorialDeAlarmas = ({rutConcesionario, token, isROImpersonate, callServiceHandler}) => {
  const [updateDate, setUpdateDate] = useState('')
  const [allEds, setAllEds] = useState([])
  const [selectedEds, setSelectedEds] = useState()
  const [periodo, setPeriodo] = useState()
  const [alarmas, setAlarmas] = useState([])
  const [resumen, setResumen] = useState({})
  const [showResumen, setShowResumen] = useState(false)

  const periodos = getAllPeriodos()
  const tableRef = useRef();

  useEffect(() => {
    getUpdateDate(setUpdateDate)
  }, []);

  useEffect(() => {
    setPeriodo(periodos[0])
  }, []);

  useEffect(() => {
    if(allEds.length === 1)
      setSelectedEds(allEds[0])
  }, [allEds])

  useEffect(() => {
    if(selectedEds)
      getAlarmas()
  }, [selectedEds, periodo]);

  function openModal(){
    setShowResumen(true);
    scrollTo(0, 300)
    showGreyBackground()
  }

  function closeModal(){
    setShowResumen(false)
    hideGreyBackground()
  }

  useEffect(() => {
    const tmpResumen = {}
    alarmas.forEach(function(alarma){
      if(!tmpResumen[alarma.tipo_alarma])
        tmpResumen[alarma.tipo_alarma] = 0

      tmpResumen[alarma.tipo_alarma]++
    })

    setResumen(tmpResumen)

  }, [alarmas])

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      if (token && rutConcesionario) {
        getEdsPedidosActivos(callServiceHandler, rutConcesionario, setAllEds);
      }
    } else {
      if (token) {
        getEdsPedidosActivos(callServiceHandler, rutConcesionario, setAllEds);
      }
    }
  }, [token, rutConcesionario]);

  async function getAlarmas(){
    displayLoader()
    let url =
      process.env.REACT_APP_FLUCTUACIONES_API + "/fluctuaciones/historial-alarmas?ID_EDS=" + selectedEds.value

    if(periodo)
      url += "&PERIODO=" + periodo.value

    let result = await callServiceHandler(url, "GET")
    if(result !== "error"){
      setAlarmas(result.data)
    }
  }

  function getAlarmasForExcel(){
    const tmpAlarmas = []
    alarmas.forEach(function(alarma){
      alarma.tanques = alarma.tanques.toString()
      tmpAlarmas.push(alarma)
    })

    return tmpAlarmas
  }

  return (
    <div className="page historial-de-alarmas">
      <div className="view-title">Fluctuaciones</div>
      <div className="view-title-separator">|</div>
      <div className="view-subtitle">Historial de alarmas</div>
      <hr className="title-separator-line" />
      <HeaderData
        rutConcesionario={rutConcesionario}
        token={token}
        actualizacion={updateDate}
        callServiceHandler={callServiceHandler}
      />
      <hr />
      <div>
        <div className="page-options">
          <div className="filters-container">
            <div className="filter">
              <div className="filter-title">ESTACIÓN DE SERVICIO</div>
              <div className="filter-input">
                <Select
                  className="eds-select"
                  value={selectedEds}
                  onChange={(e) => setSelectedEds(e)}
                  options={allEds}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  components={{ SingleOption, IndicatorSeparator: () => null }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStyles}
                />
              </div>
            </div>
            <div className="filter">
              <div className="filter-title">PERIODO</div>
              <div className="filter-input">
                <Select
                  className="eds-select"
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  value={periodo}
                  onChange={(e) => setPeriodo(e)}
                  options={periodos}
                  components={{ SingleOption, IndicatorSeparator: () => null }}
                  isClearable={false}
                  autosize={false}
                  placeholder="Seleccionar"
                  styles={customStyles}
                />
              </div>
            </div>
            <button className="main-button main-button-small" onClick={openModal} disabled={!selectedEds}>Ver Resumen</button>
          </div>

          <div className="actions">
            <PrintComponent 
              reff={tableRef}
              classes=""
              isROImpersonate={isROImpersonate}
            />
            
            <ExportExcel 
              data={getAlarmasForExcel}
              labels={excelLabels}
              isROImpersonate={isROImpersonate}
            />
          </div>
        </div>
        <table ref={tableRef} className="flutuaciones-table">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Tipo Alarma</th>
              <th>Tanque</th>
              <th>Gatillador</th>
              <th>Límite</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="horizontal-separator" colSpan={6} />
            </tr>
            { alarmas.map((alarma, key) => 
              <tr className={key === alarmas.length - 1? "cell-border-bottom-left": "row-with-border"}>
                <td className={key === 0? "cell-border-top-left": ""}>{alarma.FECHA}</td>
                <td>{alarma.HORA}</td>
                <td>{alarma.tipo_alarma}</td>
                <td>{alarma.tanques.toString()}</td>
                <td>{alarma.gatillador}</td>
                <td 
                  className={`${key === 0? "cell-border-top-right": ""} 
                  ${key === alarmas.length - 1? "cell-border-bottom-right" : ""}`}
                >{alarma.limite}</td>
              </tr>            
            )}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={showResumen}
        onRequestClose={closeModal}
        style={customModalStyles}
        contentLabel="Example Modal"
      >
        <div className="flutuaciones-modal">
          <img className="close-modal" onClick={closeModal} src={closeIcon} />
          <div className="modal-info">
            <p className="flutuaciones-modal__title">
              <strong>Resumen Historial de alarmas</strong>
            </p>
            <p className="flutuaciones-modal__description">
              EDS: {selectedEds && selectedEds.value} - Periodo: {periodo && periodo.label || '-'}
            </p>
            <table
              ref={tableRef}
              className="flutuaciones-table hda-resumen__table"
            >
              <thead>
                <tr>
                  <th>Tipo Alarma</th>
                  <th>Alarmas</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="horizontal-separator" colSpan={2} />
                </tr>
                {
                  Object.entries(resumen).map(([key, value], index) =>
                    <tr className="row-with-border">
                      <td className={index===0? "cell-border-top-left": ""}>
                        {key}
                      </td>
                      <td className={index===0? "cell-border-top-right": ""}>{value}</td>
                    </tr>   
                )}
                <tr>
                  <td className="cell-border-bottom-left">
                    Material asociado a Trx’s no coincide con la configuración
                  </td>
                  <td className="cell-border-bottom-right">4</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
};
